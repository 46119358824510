import { Icon } from '@iconify/react/dist/iconify.js';
import { useClipBoard } from '../contexts/ClipboardContext';
import { useEffect, useState } from 'react';
import { useSelection } from '../contexts/SelectionContext';
import { useLayout } from '../contexts/LayoutContext';
import { useFileNavigation } from '../contexts/FileNavigationContext';
import { duplicateNameHandler } from '../utils/duplicateNameHandler';
import { validateApiCallback } from '../utils/validateApiCallback';

const useFileList = (onRefresh, enableFilePreview, triggerAction) => {
  const [selectedFileIndexes, setSelectedFileIndexes] = useState([]);
  const [visible, setVisible] = useState(false);
  const [isSelectionCtx, setIsSelectionCtx] = useState(false);
  const [clickPosition, setClickPosition] = useState({ clickX: 0, clickY: 0 });
  const [lastSelectedFile, setLastSelectedFile] = useState(null);

  const { clipBoard, setClipBoard, handleCutCopy, handlePasting } =
    useClipBoard();
  const { selectedFiles, setSelectedFiles, handleDownload } = useSelection();
  const { currentPath, setCurrentPath, currentPathFiles, setCurrentPathFiles } =
    useFileNavigation();
  const { activeLayout, setActiveLayout } = useLayout();

  // Context Menu
  const handleFileOpen = () => {
    if (lastSelectedFile.isDirectory) {
      setCurrentPath(lastSelectedFile.path);
      setSelectedFileIndexes([]);
      setSelectedFiles([]);
    } else {
      enableFilePreview && triggerAction.show('previewFile');
    }
    setVisible(false);
  };

  const handleMoveOrCopyItems = (isMoving) => {
    handleCutCopy(isMoving);
    setVisible(false);
  };

  const handleFilePasting = () => {
    handlePasting(lastSelectedFile);
    setVisible(false);
  };

  const handleRenaming = () => {
    setVisible(false);
    triggerAction.show('rename');
  };

  const handleDownloadItems = () => {
    handleDownload();
    setVisible(false);
  };

  const handleDelete = () => {
    setVisible(false);
    triggerAction.show('delete');
  };

  const handleRefresh = () => {
    setVisible(false);
    validateApiCallback(onRefresh, 'onRefresh');
    setClipBoard(null);
  };

  const handleCreateNewFolder = () => {
    triggerAction.show('createFolder');
    setVisible(false);
  };

  const handleUpload = () => {
    setVisible(false);
    triggerAction.show('uploadFile');
  };

  const handleselectAllFiles = () => {
    setSelectedFiles(currentPathFiles);
    setVisible(false);
  };

  const emptySelecCtxItems = [
    {
      title: 'View',
      icon:
        activeLayout === 'grid' ? (
          <Icon icon='mdi-light:grid' width='24' height='24' />
        ) : (
          <Icon icon='material-symbols:list' width='24' height='24' />
        ),
      onClick: () => {},
      children: [
        {
          title: 'Grid',
          icon: <Icon icon='mdi-light:grid' width='24' height='24' />,
          selected: activeLayout === 'grid',
          onClick: () => {
            setActiveLayout('grid');
            setVisible(false);
          },
        },
        {
          title: 'List',
          icon: <Icon icon='material-symbols:list' width='24' height='24' />,
          selected: activeLayout === 'list',
          onClick: () => {
            setActiveLayout('list');
            setVisible(false);
          },
        },
      ],
    },
    {
      title: 'Refresh',
      icon: <Icon icon='ic:sharp-refresh' width='24' height='24' />,
      onClick: handleRefresh,
      divider: true,
    },
    // {
    //   title: 'New folder',
    //   icon: <Icon icon='mdi:folder-plus' width='24' height='24' />,
    //   onClick: handleCreateNewFolder,
    // },
    // {
    //   title: 'Upload',
    //   icon: (
    //     <Icon icon='material-symbols:upload-rounded' width='24' height='24' />
    //   ),
    //   onClick: handleUpload,
    //   divider: true,
    // },
    // {
    //   title: 'Select all',
    //   icon: <Icon icon='mynaui:select-multiple' width='24' height='24' />,
    //   onClick: handleselectAllFiles,
    // },
  ];

  const selecCtxItems = [
    {
      title: 'Open',
      icon: lastSelectedFile?.isDirectory ? (
        <Icon icon='icon-park-outline:add-picture' width='20' height='20' />
      ) : (
        <Icon icon='bx:file' width='24' height='24' />
      ),
      onClick: handleFileOpen,
      divider: true,
    },
    // {
    //   title: 'Cut',
    //   icon: <Icon icon='tabler:cut' width='24' height='24' />,
    //   onClick: () => handleMoveOrCopyItems(true),
    // },
    // {
    //   title: 'Copy',
    //   icon: <Icon icon='cil:copy' width='20' height='20' />,
    //   onClick: () => handleMoveOrCopyItems(false),
    //   divider: !lastSelectedFile?.isDirectory,
    // },
    // {
    //   title: 'Paste',
    //   icon: <Icon icon='lsicon:paste-outline' width='16' height='16' />,
    //   onClick: handleFilePasting,
    //   className: `${clipBoard ? '' : 'disable-paste'}`,
    //   hidden: !lastSelectedFile?.isDirectory,
    //   divider: true,
    // },
    // {
    //   title: 'Rename',
    //   icon: <Icon icon='mdi:rename-outline' width='24' height='24' />,
    //   onClick: handleRenaming,
    //   hidden: selectedFiles.length > 1,
    // },
    {
      title: 'Download',
      icon: <Icon icon='material-symbols:download' width='24' height='24' />,
      onClick: handleDownloadItems,
      hidden: lastSelectedFile?.isDirectory,
    },
    // {
    //   title: 'Delete',
    //   icon: (
    //     <Icon icon='material-symbols:delete-outline' width='24' height='24' />
    //   ),
    //   onClick: handleDelete,
    // },
  ];
  //

  const handleFolderCreating = () => {
    setCurrentPathFiles((prev) => {
      return [
        ...prev,
        {
          name: duplicateNameHandler('New Folder', true, prev),
          isDirectory: true,
          path: currentPath,
          isEditing: true,
          key: new Date().valueOf(),
        },
      ];
    });
  };

  const handleItemRenaming = () => {
    setCurrentPathFiles((prev) => {
      if (prev[selectedFileIndexes.at(-1)]) {
        prev[selectedFileIndexes.at(-1)].isEditing = true;
      }
      return prev;
    });

    setSelectedFileIndexes([]);
    setSelectedFiles([]);
  };

  const unselectFiles = () => {
    setSelectedFileIndexes([]);
    setSelectedFiles((prev) => (prev.length > 0 ? [] : prev));
  };

  const handleContextMenu = (e, isSelection = false) => {
    e.preventDefault();
    setClickPosition({ clickX: e.clientX, clickY: e.clientY });
    setIsSelectionCtx(isSelection);
    !isSelection && unselectFiles();
    setVisible(true);
  };

  useEffect(() => {
    if (triggerAction.isActive) {
      switch (triggerAction.actionType) {
        case 'createFolder':
          handleFolderCreating();
          break;
        case 'rename':
          handleItemRenaming();
          break;
      }
    }
  }, [triggerAction.isActive]);

  useEffect(() => {
    setSelectedFileIndexes([]);
    setSelectedFiles([]);
  }, [currentPath]);

  useEffect(() => {
    if (selectedFiles.length > 0) {
      setSelectedFileIndexes(() => {
        return selectedFiles.map((selectedFile) => {
          return currentPathFiles.findIndex(
            (f) => f.path === selectedFile.path
          );
        });
      });
    } else {
      setSelectedFileIndexes([]);
    }
  }, [selectedFiles, currentPathFiles]);

  return {
    emptySelecCtxItems,
    selecCtxItems,
    handleContextMenu,
    unselectFiles,
    visible,
    setVisible,
    setLastSelectedFile,
    selectedFileIndexes,
    clickPosition,
    isSelectionCtx,
  };
};

export default useFileList;
