import { Box, Button, FormControlLabel, Grid, Switch } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import Crud_Service from '../../../../apis/CrudService.jsx';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import FmMRTDataTable from '../../../../components/FmMRTDataTable.tsx';
import useTableLogic from '../../../../components/helpers/MRTUseTableLogic.tsx';
import TableActionButtons from '../../../../components/_form/TableActionButtons.jsx';
import MRTSaveAndCancel from '../../../../components/table/MRTSaveAndCancel.tsx';
import { Icon } from '@iconify/react';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import { CreateButton } from '../../../../components/small/Buttons.jsx';
import MuiDialogOne from '../../../../components/_mui/MuiDialogOne.jsx';
import ActionButtons from '../../../../components/_form/ActionButtons.tsx';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { passwordValidationSchema } from '../../../../utils/CommonvalidationSchemas.tsx';

const CreateUser = () => {
  const crud = new Crud_Service();
  const { control, handleSubmit, getValues, reset } = useForm({
    mode: 'onChange',
    resolver: yupResolver(passwordValidationSchema),
  });
  const { control: roleControl, setValue } = useForm();
  const [isOpen, setIsOpen] = useState(false);
  const {
    rows,
    isLoading,
    pagination,
    setRows,
    sorting,
    setSearchKeyword,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setGlobalFilter,
    searchKeyword,
    setColumnFilters,
    fetchData,
  } = useTableLogic(`loginusers`, ['employeeId', 'cadreId'], ['0', '0']);
  const [editingRowId, setEditingRowId] = useState(null);
  const [editingRowData, setEditingRowData] = useState(null);
  const { startCreateLoading, stopCreateLoading, createLoading } = useLoader();
  const [selectedRole, setSelectedRole] = useState(null);

  const handleSaveUser = async (values) => {
    startCreateLoading();
    await crud.create('loginusers/add-user-with-role', values, (_err, res) => {
      if (res?.status === 201) {
        toast.success('User Role Created Successfully.');
        stopCreateLoading();
        setIsOpen(false);
        reset();
      } else {
        stopCreateLoading();
      }
    });
  };
  const handleEdit = async (row) => {
    setEditingRowId(row?.loginUserRoleId);
    setEditingRowData({ ...row });

    Object.entries({
      ...row,
    }).forEach(([key, value]) => {
      setValue(key, value);
    });
  };
  const handleDeleteClick = async (props) => {
    await crud.remove('loginuserroles', props?.loginUserRoleId, (err, res) => {
      if (res?.status === 204) {
        toast.success('User Role Deleted Successfully');
        fetchData();
      } else {
      }
    });
  };
  const [rowState, setRowState] = useState({
    newRowId: null,
    isCreatingRow: false,
  });

  const ActionData = [
    {
      name: 'Edit',
      icon: <Icon icon='solar:pen-new-round-linear' />,
      onClick: (props) => {
        handleEdit(props);
      },
    },
    {
      name: 'Delete',
      icon: <Icon icon='mi:delete' />,
      onClick: (props) => handleDeleteClick(props),
      danger: true,
    },
  ];

  const handleCancel = (row) => {
    if (row?.original?.loginUserRoleId === rowState?.newRowId) {
      setRows((prevRows) =>
        prevRows.filter((r) => r?.loginUserRoleId !== rowState?.newRowId)
      );
      setRowState((prevState) => ({
        ...prevState,
        newRowId: null,
        isCreatingRow: false,
      }));
    } else {
      setEditingRowId(null);
    }
    reset();
  };

  const handleEditRole = async () => {
    await crud.update(
      `loginuserroles`,
      editingRowData?.loginUserRoleId,
      {
        roleId: selectedRole?.roleId,
        loginUserId: editingRowData?.loginUserId,
        status: 2,
      },
      (err, res) => {
        if (err) {
          stopCreateLoading();
          toast.error(err);
        }
        if (res?.status === 200) {
          stopCreateLoading();
          setEditingRowId(null);
          fetchData();
          toast.success('User Role Updated Successfully');
        } else {
          stopCreateLoading();
        }
      }
    );
  };
  const columns = [
    {
      header: 'Actions',
      enableColumnPinning: true,
      enableEditing: false,
      enableSorting: false,
      size: 80,
      Cell: ({ row }) => {
        const isNewRow = row?.original?.loginUserRoleId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.loginUserRoleId || isNewRow;

        return (
          <Box className='flex gap-2'>
            {isEditing ? (
              <MRTSaveAndCancel
                onClickCancel={() => handleCancel(row)}
                onClickSave={handleEditRole}
              />
            ) : (
              <TableActionButtons
                Actions={ActionData?.map((action) => ({
                  ...action,
                  onClick: () => action.onClick(row?.original),
                }))}
              />
            )}
          </Box>
        );
      },
    },

    {
      accessorKey: 'loginUserName',
      header: 'User',
      enableEditing: true,
      Cell: ({ row }) => {
        return row?.original?.loginUserName || '';
      },
    },
    {
      accessorKey: 'role',
      header: 'Role',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow = row?.original?.loginUserRoleId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.loginUserRoleId || isNewRow;
        return isEditing ? (
          <FmSearchableSelect
            name='roleId'
            label='Role'
            apiUrl='roles'
            control={roleControl}
            onChangeProp={setSelectedRole}
            defaultValue={{
              roleId: row?.original?.roleId,
              roleName: row?.original?.role,
            }}
            valueField='role'
            labelField={['roleName']}
            showField={['roleName']}
          />
        ) : (
          row?.original?.role || ''
        );
      },
    },

    {
      accessorKey: 'createdDate',
      header: 'Created Date',
    },
    {
      accessorKey: 'updateDate',
      header: 'Updated Date',
    },
  ];

  return (
    <>
      <DashboardLayout
        title='Role Management'
        hasSubmenu
        menu={[
          {
            menuName: 'Create Role Permissions',
            url: '/fm/role-management',
          },
          {
            menuName: 'Create Roles',
            url: '/fm/role-management/Create-Roles',
          },
          {
            menuName: 'Create Permission',
            url: '/fm/role-management/Create-Permissions',
          },
          {
            menuName: 'Create User',
            url: '/fm/role-management/create-user',
          },
        ]}
        actionButtons={
          <CreateButton
            name='Create'
            onClick={() => {
              setIsOpen(true);
              reset();
            }}
          />
        }
      >
        <GlassCard className='mt-3'>
          <FmMRTDataTable
            columns={columns}
            enableRowSelection={false}
            rows={rows || []}
            rowCount={tableRecordCounts}
            editingRow={editingRowId}
            pagination={pagination}
            setSearchKeyword={setSearchKeyword}
            sorting={sorting}
            columnFilters={columnFilters}
            globalFilter={globalFilter}
            setPagination={setPagination}
            setSorting={setSorting}
            isLoading={isLoading}
            searchKeyword={searchKeyword}
            fetchData={fetchData}
            muiTableContainerProps={{
              sx: {
                maxHeight: 'calc(100vh - 285px)',
              },
            }}
          />
        </GlassCard>

        <MuiDialogOne
          title='Create User'
          open={isOpen}
          onClose={() => setIsOpen(false)}
        >
          <form onSubmit={handleSubmit(handleSaveUser)}>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <FmTextField
                  name='loginUserName'
                  control={control}
                  label='User Name'
                />
              </Grid>
              <Grid item md={6}>
                <FmTextField
                  name='loginUserPassword'
                  control={control}
                  label='Password'
                  type='password'
                />
              </Grid>
              <Grid item md={6}>
                <FmTextField
                  name='confirmPassword'
                  control={control}
                  label='Confirm Password'
                  type='password'
                />
              </Grid>

              <Grid item md={6}>
                <FmSearchableSelect
                  name='roleId'
                  label='Role'
                  apiUrl='roles'
                  control={control}
                  valueField='roleId'
                  labelField={['roleCode', 'roleName']}
                  showField={['roleCode', 'roleName']}
                />
              </Grid>
              <Grid item md={6}>
                <FmSearchableSelect
                  name='employeeId'
                  label='Employees'
                  apiUrl='employees'
                  control={control}
                  valueField='employeeId'
                  labelField={['employeeCode', 'firstName']}
                  showField={['employeeCode', 'firstName']}
                />
              </Grid>
            </Grid>
            <ActionButtons
              onSubmit={handleSubmit(handleSaveUser)}
              onCancel={() => setIsOpen(false)}
              submitLoading={createLoading}
              submitText='Create'
            />
          </form>
        </MuiDialogOne>
      </DashboardLayout>
    </>
  );
};

export default CreateUser;
