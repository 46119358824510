import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import Crud_Service from '../../../apis/CrudService.jsx';
import { toast } from 'react-toastify';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { useFieldArray, useForm } from 'react-hook-form';
import { Box, Button, CircularProgress, Grid } from '@mui/material';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import { Icon } from '@iconify/react';
import ActionButtons from '../../../components/_form/ActionButtons.tsx';
import { useNavigate } from 'react-router-dom';
import FmSearchableSelect from '../../../components/_mui/FmSearchableSelect.tsx';
import FmDatePicker from '../../../components/_mui/FmDatePicker.tsx';
import FmTimePicker from '../../../components/_mui/FmTimePicker.tsx';
import dayjs from 'dayjs';
import ConfirmationPopoverBox from '../../../components/_form/ConfirmationPopoverBox.tsx';

function CreateAttendanceForm() {
  const { createLoading, startCreateLoading, stopCreateLoading } = useLoader();
  const [selectedSalesOrders, setSelectedSalesOrders] = useState({});
  const [formIndex, setFormIndex] = useState(0);
  const [responseDate, setResponseDate] = useState([]);
  const navigate = useNavigate();
  const { control, handleSubmit, reset, setValue } = useForm();

  const {
    fields,
    append,
    remove,
    update: uodateField,
  } = useFieldArray({
    control,
    name: 'employeemonthlyattendances',
  });

  useEffect(() => {
    if (fields.length === 0) {
      append({}); // Adds an initial empty field.
    }
  }, [fields, append]);

  const crud = new Crud_Service();

  const onSubmit = async (values) => {
    startCreateLoading();
    const combinedDate = {
      employeeId: values?.fields?.[formIndex]?.employeeId,
      contractId: values?.fields?.[formIndex]?.contractId,
      divisionId: values?.fields?.[formIndex]?.divisionId,
      projectId: values?.fields?.[formIndex]?.projectId,
      salesOrderId: values?.fields?.[formIndex]?.salesOrderId,
      startTime: dayjs(values?.fields?.[formIndex]?.startTime).isValid()
        ? dayjs(values?.fields?.[formIndex]?.startTime).format('HH:mm:ss')
        : values?.fields?.[formIndex]?.startTime,
      endTime: dayjs(values?.fields?.[formIndex]?.endTime).isValid()
        ? dayjs(values?.fields?.[formIndex]?.endTime).format('HH:mm:ss')
        : values?.fields?.[formIndex]?.endTime,
      attendanceDate: dayjs(values?.fields?.[formIndex]?.date).isValid()
        ? dayjs(values?.fields?.[formIndex]?.date).format('YYYY-MM-DD')
        : values?.fields?.[formIndex]?.date,
    };

    await crud.create(
      'employeemonthlyattendances',
      combinedDate,
      (err, res) => {
        stopCreateLoading();

        if (err) {
          toast.error(err);
          return;
        }

        if (res?.status === 201) {
          uodateField(formIndex, res?.data);
          toast.success('Attendance Created Successfully!');
          setFormIndex((prevIndex) => prevIndex + 1);
          append({});
        }
      }
    );
  };

  const handleRemove = (id,index) => {
    if (id) {
      crud.remove('employeemonthlyattendances', id, (_err, res) => {
        if (res?.status === 204) {
          toast.success('Attendance Deleted Successfully!');
           // reomve id  items from fiels array
          remove(index);
          setFormIndex((prevIndex) => prevIndex - 1);
        }
      });
    } else {
      setSelectedSalesOrders((prev) => {
        const updated = { ...prev };
        delete updated[index];
        return updated;
      });
      remove(index);
     
      setFormIndex((prevIndex) => prevIndex - 1);
    }
  };

  const handleSalesOrderChange = (index, salesOrder) => {
    setSelectedSalesOrders((prev) => ({
      ...prev,
      [index]: salesOrder,
    }));

    // Set dependent values
    setValue(`fields[${index}].divisionId`, salesOrder.divisionId || '');
    setValue(`fields[${index}].projectId`, salesOrder.projectId || '');
    setValue(`fields[${index}].contractId`, salesOrder.contractId || '');
  };

  return (
    <DashboardLayout title='Create New Final Settlement'>
      <GlassCard>
        <form onSubmit={handleSubmit(onSubmit)}>
          {fields.map((field, index) => (
            <GlassCard
              key={field.id}
              className='p-4 mb-3 flex items-center gap-5'
            >
              <Grid container spacing={2}>
                {/* Employee Name */}
                <Grid item md={3}>
                  <FmSearchableSelect
                    control={control}
                    name={`fields[${index}].employeeId`}
                    label='Employee Name'
                    apiUrl='employees'
                    valueField='employeeId'
                    headerField={['Employee Code', 'Employee Name']}
                    labelField={['employeeCode', 'firstName']}
                    defaultValue={{
                      employeeId: fields[index]?.employeeId,
                      firstName: fields[index]?.employeeName,
                    }}
                    showField={['employeeCode', 'firstName']}
                    required
                  />
                </Grid>

                {/* Sales Order */}
                <Grid item md={3}>
                  <FmSearchableSelect
                    control={control}
                    name={`fields[${index}].salesOrderId`}
                    label='Sales Order'
                    apiUrl='salesorders'
                    valueField='salesOrderId'
                    defaultValue={{
                      salesOrderId: fields[index]?.salesOrderId,
                      salesOrderName: fields[index]?.salesOrderName,
                    }}
                    headerField={['SalesOrder Code', 'SalesOrder Name']}
                    labelField={['autoSalesOrderCode', 'salesOrderName']}
                    showField={['autoSalesOrderCode', 'salesOrderName']}
                    onChangeProp={(ev) => handleSalesOrderChange(index, ev)}
                    required
                  />
                </Grid>

                {/* Division */}
                <Grid item md={3}>
                  <FmSearchableSelect
                    control={control}
                    name={`fields[${index}].divisionId`}
                    label='Division'
                    apiUrl='divisions'
                    valueField='divisionId'
                    headerField={['Division Code', 'Division Name']}
                    labelField={['divisionCode', 'divisionName']}
                    showField={['divisionCode', 'divisionName']}
                    defaultValue={{
                      divisionId: selectedSalesOrders[index]?.divisionId,
                      divisionName: selectedSalesOrders[index]?.divisionName,
                    }}
                    disabled={!selectedSalesOrders[index]?.divisionId}
                  />
                </Grid>

                {/* Project */}
                <Grid item md={3}>
                  <FmSearchableSelect
                    control={control}
                    name={`fields[${index}].projectId`}
                    label='Project'
                    apiUrl='projects'
                    valueField='projectId'
                    headerField={['Project Code', 'Project Name']}
                    labelField={['projectCode', 'projectName']}
                    showField={['projectCode', 'projectName']}
                    defaultValue={{
                      projectId: selectedSalesOrders[index]?.projectId,
                      projectName: selectedSalesOrders[index]?.projectName,
                    }}
                    disabled={!selectedSalesOrders[index]?.projectId}
                  />
                </Grid>

                {/* Contract */}
                <Grid item md={3}>
                  <FmSearchableSelect
                    control={control}
                    name={`fields[${index}].contractId`}
                    label='Contract'
                    apiUrl='contracts'
                    valueField='contractId'
                    headerField={['Contract Code', 'Contract Name']}
                    labelField={['contractCode', 'contractName']}
                    showField={['contractCode', 'contractName']}
                    defaultValue={{
                      contractId: selectedSalesOrders[index]?.contractId,
                      contractName: selectedSalesOrders[index]?.contractName,
                    }}
                    disabled={!selectedSalesOrders[index]?.contractId}
                  />
                </Grid>

                {/* Date */}
                <Grid item md={3}>
                  <FmDatePicker
                    control={control}
                    name={`fields[${index}].date`}
                    label='Date'
                    required
                  />
                </Grid>

                {/* Start Time */}
                <Grid item md={3}>
                  <FmTimePicker
                    control={control}
                    name={`fields[${index}].startTime`}
                    label='Start Time'
                    required
                  />
                </Grid>

                {/* End Time */}
                <Grid item md={3}>
                  <FmTimePicker
                    control={control}
                    name={`fields[${index}].endTime`}
                    label='End Time'
                    required
                  />
                </Grid>
              </Grid>
              {index !== fields.length - 1 && (
                <ConfirmationPopoverBox
                  icon={<Icon icon='tabler:trash' />}
                  onConfirm={() =>
                    handleRemove(field?.employeeMonthlyAttendanceId,index)
                  }
                  title='Delete'
                />
              )}
            </GlassCard>
          ))}
          <Box className='flex justify-end'>
            {createLoading ? (
              <CircularProgress size={24} />
            ) : (
              <Button
                variant='text'
                type='submit'
                className='uppercase font-bold text-orange-500'
                startIcon={<Icon icon='ic:baseline-plus' />}
              >
                Add
              </Button>
            )}
          </Box>
        </form>
      </GlassCard>
    </DashboardLayout>
  );
}

export default CreateAttendanceForm;
