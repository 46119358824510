import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import Crud_Service from '../../../apis/CrudService';
import { ActionButtons } from '../../../components/_form';
import FmAutoComplete from '../../../components/_mui/FmAutoComplete.tsx';
import FmDatePicker from '../../../components/_mui/FmDatePicker.tsx';
import FmSearchableSelect from '../../../components/_mui/FmSearchableSelect.tsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { CommonTrueFalse } from '../../../utils/CommonVariables.tsx';

const Createclient = () => {
  const navigate = useNavigate();
  const crud = new Crud_Service();
  const validationSchema = Yup.object().shape({
    clientName: Yup.string()
      .required('Client Name is required')
      .min(2, 'Client Name must be at least 2 characters')
      .max(100, 'Client Name must not exceed 100 characters'),
    contactEmailId: Yup.string()
      .required('Contact email ID is required')
      .test(
        'is-valid-email',
        'Contact email ID must be a valid email address',
        (value) =>
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)
      ),
    clientIndustry: Yup.string().required('Client Industry is required'),
    contactPerson: Yup.string().required('Contact Person is required'),
    contactNumber: Yup.string()
      .required('Contact Number is required')
      .min(8, 'Contact Number cannot be less than 8')
      .max(13, 'Contact Number cannot exceed 13'),
    headOfficeAddress: Yup.string().required('Head Office Address is required'),
  });

  const { handleSubmit, control, reset, setValue } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });
  const { createLoading, startCreateLoading, stopCreateLoading } = useLoader();
  const { t } = useTranslation();
  const [COA, setCOA] = React.useState(false);
  const [ICV, setICV] = React.useState(false);

  useEffect(() => {
    !COA && setValue('advanceAccountNeededInChartOfAccount', null);
    !COA && setValue('retentionAccountNeededInChartOfAccount', null);
    !ICV && setValue('icvCertifiedScore', '');
  }, [COA, ICV]);

  const handleSubmitClients = async (values) => {
    startCreateLoading();

    const combinedData = {
      ...values,
      icvCertifiedScore: values?.icvCertifiedScore || 0,
      ownername: values?.Ownername || 'test',
      advanceAccountNeededInChartOfAccount:
        values?.advanceAccountNeededInChartOfAccount || false,
      retentionAccountNeededInChartOfAccount:
        values?.retentionAccountNeededInChartOfAccount || false,
      tradeLicenseStartDate: dayjs(values?.tradeLicenseStartDate).format(
        'YYYY-MM-DD'
      ),
      tradeLicenseEndDate: dayjs(values?.tradeLicenseEndDate).format(
        'YYYY-MM-DD'
      ),
    };

    await crud.create(`clients`, combinedData, (_err, res) => {
      if (res?.status === 201) {
        toast.success(t('client Created Successfully'));
        navigate(-1);
        stopCreateLoading();
      } else {
        stopCreateLoading();
      }
    });
  };

  return (
    <DashboardLayout title='Create New Client'>
      <Box className='mx-auto' sx={{ maxWidth: '800px' }}>
        <GlassCard className='p-4'>
          <form onSubmit={handleSubmit(handleSubmitClients)}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <FmTextField
                    name='clientName'
                    control={control}
                    label='Name'
                    required
                  />
                </Grid>
                <Grid item md={6}>
                  <FmTextField
                    name='manualClientCode'
                    control={control}
                    label='Manual Client Code'
                  />
                </Grid>
                <Grid item md={6}>
                  <FmSearchableSelect
                    name='parentClientId'
                    control={control}
                    apiUrl='clients'
                    valueField='clientId'
                    headerField={['Code', 'Name']}
                    labelField={['clientCode', 'clientName']}
                    showField={['clientName']}
                    label='Parent Client'
                  />
                </Grid>
                <Grid item md={6}>
                  <FmTextField
                    name='clientIndustry'
                    control={control}
                    label='Client Industry'
                    required
                  />
                </Grid>
                <Grid item md={12}>
                  <FmTextField
                    name='headOfficeAddress'
                    control={control}
                    label='Head Office Address'
                    multiline
                    rows={3}
                    maxRows={4}
                    required
                  />
                </Grid>

                <Grid item md={6}>
                  <FmTextField
                    name='contactPerson'
                    control={control}
                    label='Contact Person'
                    required
                  />
                </Grid>

                <Grid item md={6}>
                  <FmTextField
                    name='contactNumber'
                    control={control}
                    label='Contact Number'
                    pattern='Number'
                    required
                  />
                </Grid>
                <Grid item md={6}>
                  <FmTextField
                    name='contactEmailId'
                    control={control}
                    label='Contact Email'
                    type='email'
                    required
                  />
                </Grid>
                <Grid item md={6}>
                  <FmTextField
                    name='ownerName'
                    control={control}
                    label='Owner Name'
                  />
                </Grid>
                <Grid item md={6}>
                  <FmTextField
                    name='trnNumber'
                    control={control}
                    label='TRN Number'
                  />
                </Grid>
                <Grid item md={6}>
                  <FmAutoComplete
                    name='accountNeededInChartOfAccount'
                    control={control}
                    options={CommonTrueFalse}
                    label='Account Needed In Chart Of Account'
                    displayField='label'
                    onChange={(e) => setCOA(e)}
                    optionFields={['label']}
                    valueKey='value'
                  />
                </Grid>
                {COA && (
                  <>
                    <Grid item md={6}>
                      <FmAutoComplete
                        name='advanceAccountNeededInChartOfAccount'
                        control={control}
                        options={CommonTrueFalse}
                        label='Advance Account Needed In Chart Of Account'
                        displayField='label'
                        optionFields={['label']}
                        valueKey='value'
                      />
                    </Grid>
                    <Grid item md={6}>
                      <FmAutoComplete
                        name='retentionAccountNeededInChartOfAccount'
                        control={control}
                        options={CommonTrueFalse}
                        label='Retention Account Needed In Chart Of Account'
                        displayField='label'
                        optionFields={['label']}
                        valueKey='value'
                      />
                    </Grid>
                  </>
                )}
                <Grid item md={6}>
                  <FmAutoComplete
                    name='icvCertified'
                    control={control}
                    options={CommonTrueFalse}
                    label='ICV Certified'
                    displayField='label'
                    onChange={(e) => setICV(e)}
                    optionFields={['label']}
                    valueKey='value'
                  />
                </Grid>
                {ICV && (
                  <Grid item md={6}>
                    <FmTextField
                      name='icvCertifiedScore'
                      control={control}
                      label='ICV Certified Score'
                    />
                  </Grid>
                )}
                <Grid item md={6}>
                  <FmDatePicker
                    name='tradeLicenseStartDate'
                    control={control}
                    label='Trade License Start Date'
                  />
                </Grid>
                <Grid item md={6}>
                  <FmDatePicker
                    name='tradeLicenseEndDate'
                    control={control}
                    label='Trade License End Date'
                  />
                </Grid>
              </Grid>
              <ActionButtons
                onSubmit={handleSubmit(handleSubmitClients)}
                onCancel={() => navigate(-1)}
                onReset={reset}
                submitLoading={createLoading}
                cancelLoading={false}
                submitText='Create'
              />
            </LocalizationProvider>
          </form>
        </GlassCard>
      </Box>
    </DashboardLayout>
  );
};

export default Createclient;
