import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import ActionButtons from '../../../components/_form/ActionButtons.tsx';
import FmDatePicker from '../../../components/_mui/FmDatePicker.tsx';
import FmSearchableSelect from '../../../components/_mui/FmSearchableSelect.tsx';
import FmTimePicker from '../../../components/_mui/FmTimePicker.tsx';
import MuiDialogOne from '../../../components/_mui/MuiDialogOne.jsx';
import { employeeAttendenceSchema } from '../../../utils/CommonvalidationSchemas.tsx';

const AttendanceTimePicker = (props) => {
  const {
    selectedDayData,
    handleSubmitAttendance,
    dialogData,
    setDialogOpen,
    setDialogData,
    dialogOpen,
    isNew,
    createLoading,
    handleDialogClose,
  } = props;
  const { control, handleSubmit, reset, setValue, trigger } = useForm({
    mode: 'onChange',
    resolver: yupResolver(employeeAttendenceSchema(isNew)),
  });

  const [selectedSO, setSelectedSO] = useState({});
  useEffect(() => {
    reset();

    if (dialogData) {
      Object.entries(dialogData).forEach(([key, value]) => {
        if (key === 'startTime' || key === 'endTime') {
          setValue(key, value ? dayjs(value, 'HH:mm') : null);
        } else {
          setValue(key, value);
        }
      });
      setDialogOpen(true);
    } else if (selectedDayData) {
      Object.entries(selectedDayData).forEach(([key, value]) => {
        if (key === 'startTime' || key === 'endTime') {
          setValue(key, value ? dayjs(value, 'HH:mm') : null);
        } else {
          setValue(key, value);
        }
      });
      setDialogOpen(true);
    } else {
      setDialogOpen(false);
    }
  }, [selectedDayData, setValue, reset, setDialogOpen]);

  const handleDialogCloseWithReset = () => {
    handleDialogClose();
    reset();
  };

  return (
    <form onSubmit={handleSubmit(handleSubmitAttendance)}>
      <MuiDialogOne
        open={dialogOpen || isNew}
        onClose={handleDialogCloseWithReset}
        title={
          !selectedDayData || selectedDayData?.employeeMonthlyAttendanceId === 0
            ? 'Edit Attendance'
            : 'Update Attendance'
        }
      >
        <Grid container spacing={2}>
          <Grid item md={6}>
            <FmSearchableSelect
              control={control}
              name='employeeId'
              label='Employee Name'
              apiUrl='employees'
              valueField='employeeId'
              headerField={['Employee Code', 'Employee Name']}
              labelField={['employeeCode', 'firstName']}
              showField={['employeeCode', 'firstName']}
              required
              defaultValue={{
                firstName: dialogData?.employeeName,
              }}
              disabled={!isNew}
            />
          </Grid>
          <Grid item md={6}>
            <FmSearchableSelect
              name='salesOrderId'
              control={control}
              apiUrl='salesorders'
              valueField='salesOrderId'
              defaultValue={{
                salesOrderCode: dialogData?.salesOrderCode,
                autoSalesOrderCode: dialogData?.salesOrderCode,
              }}
              onChangeProp={(ev) => {
                setSelectedSO(ev);
                setDialogData((prev) => ({
                  ...prev,
                  divisionId: null,
                  projectId: null,
                  contractId: null,
                }));
                setValue('divisionId', ev?.divisionId);
                setValue('projectId', ev?.projectId);
                setValue('contractId', ev?.contractId);
              }}
              headerField={['SalesOrder Code', 'SalesOrder Name']}
              labelField={['autoSalesOrderCode', 'salesOrderName']}
              showField={['autoSalesOrderCode', 'salesOrderName']}
              label='Sales Order'
            />
          </Grid>
          <Grid item md={6}>
            <FmSearchableSelect
              name='divisionId'
              control={control}
              apiUrl='divisions'
              valueField='divisionId'
              headerField={['Division Code', 'Division Name']}
              labelField={['divisionCode', 'divisionName']}
              showField={['divisionCode', 'divisionName']}
              label='Division'
              defaultValue={{
                divisionId: dialogData?.divisionId || selectedSO?.divisionId,
                divisionName:
                  dialogData?.divisionName || selectedSO?.divisionName,
              }}
            />
          </Grid>
          <Grid item md={6}>
            <FmSearchableSelect
              name='projectId'
              control={control}
              apiUrl='projects'
              valueField='projectId'
              headerField={['Project Code', 'Project Name']}
              labelField={['projectCode', 'projectName']}
              showField={['projectCode', 'projectName']}
              label='Project'
              defaultValue={{
                projectId: dialogData?.projectId || selectedSO?.projectId,
                projectName: dialogData?.projectName || selectedSO?.projectName,
              }}
            />
          </Grid>
          <Grid item md={6}>
            <FmSearchableSelect
              name='contractId'
              control={control}
              apiUrl='contracts'
              valueField='contractId'
              headerField={['Contract Code', 'Contract Name']}
              labelField={['contractCode', 'contractName']}
              showField={['contractCode', 'contractName']}
              label='Contract'
              defaultValue={{
                contractId: dialogData?.contractId || selectedSO?.contractId,
                contractName:
                  dialogData?.contractName || selectedSO?.contractName,
              }}
            />
          </Grid>
          <Grid item md={6}>
            <FmDatePicker
              control={control}
              name='date'
              label='Date'
              defaultValue={dialogData?.date}
              required={true}
            />
          </Grid>
          <Grid item md={6}>
            <FmTimePicker
              control={control}
              name='startTime'
              label='Start Time'
              required={true}
              defaultValue={dayjs(dialogData?.startTime)}
              onChangeProp={() => {
                trigger('endTime');
              }}
            />
          </Grid>
          <Grid item md={6}>
            <FmTimePicker
              control={control}
              name='endTime'
              label='End Time'
              defaultValue={dayjs(dialogData?.endTime)}
              required={true}
            />
          </Grid>
          <Grid item md={12}>
            <ActionButtons
              onSubmit={handleSubmit(handleSubmitAttendance)}
              onReset={reset}
              submitLoading={createLoading}
              submitText={isNew ? 'Create' : 'Update'}
            />
          </Grid>
        </Grid>
      </MuiDialogOne>
    </form>
  );
};

export default AttendanceTimePicker;
