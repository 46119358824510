import React from 'react';
import FileViewer from '../elements/FileViewer.tsx';
import MuiDialogFullscreen from '../_mui/MuiDialogFullscreen.tsx';

const UploadModalFileViewer = ({
  documents,
  open,
  onClose,
  titleEnable = 'false',
}) => {
  return (
    <MuiDialogFullscreen
      title={!titleEnable ? false : titleEnable}
      open={open}
      onClose={onClose}
    >
      <FileViewer documents={documents} onClose={onClose} name='Preview' />
    </MuiDialogFullscreen>
  );
};

export default UploadModalFileViewer;
