import { Box, Grid } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Icons from '../../../utils/Icon.jsx';
import { useSelector } from 'react-redux';
import apiInstance from '../../../apis/ApiService.jsx';
import Crud_Service from '../../../apis/CrudService';
import ActionButtons from '../../../components/_form/ActionButtons.tsx';
import { StatusBadge, ViewField } from '../../../components/_form/FormElements';
import TableActionButtons from '../../../components/_form/TableActionButtons';
import TableSearch from '../../../components/_form/TableSearch.jsx';
import FmAutoComplete from '../../../components/_mui/FmAutoComplete.tsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import MuiDialogOne from '../../../components/_mui/MuiDialogOne';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import DataTable from '../../../components/DataTable.tsx';
import DataTableToolbarButtons from '../../../components/helpers/DataTableToolbarButtons.tsx';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import useTableLogic from '../../../components/helpers/UseTableLogic.tsx';
import CommonLoader from '../../../components/page/CommonLoader';
import PageHeader from '../../../components/PageHeader';
import { CreateButton } from '../../../components/small/Buttons';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { Location } from '../../../interfaces/EmployeeMasterInterfaces';
import { toast } from 'react-toastify';
import { statusOptions } from '../../../utils/CommonVariables.tsx';
import { findNavByName } from '../../../utils/navUtils.ts';

const LocationMasters = (props) => {
  const { type } = props;
  const [openCreate, setOpenCreate] = React.useState(false);
  const [openView, setOpenView] = React.useState(false);
  const [loading, setLoader] = useState(false);
  const crud = new Crud_Service();
  const [locationTypes, setLocationTypes] = useState([]);
  const [selectedRow, setSelectedRow] = useState<Location>();
  const [openEdit, setOpenEdit] = React.useState(false);
  const [editData, setEditData] = useState(null);
  const menu = useSelector((state) => state?.menu?.items);
  const {
    createLoading,
    startCreateLoading,
    stopCreateLoading,
    editLoading,
    startEditLoading,
    stopEditLoading,
  } = useLoader();
  const {
    rows,
    pageSize,
    pageNumber,
    setSearchKeyword,
    isLoading,
    tableRecordCounts,
    searchKeyword,
    handlePaginationModelChange,
    handleSortModelChange,
    fetchData,
  } = useTableLogic('locations');
  const { handleSubmit, setValue, control, reset } = useForm<Location>();
  useTranslation();
  useEffect(() => {
    document.title = `HRMS-Location`;
    getLocationsTypes();
  }, []);

  //Location Types
  const getLocationsTypes = () => {
    crud.getAll('locationtypes', '', (err, res) => {
      if (err) {
        toast.error('Error fetching locations Types:', err);
        return;
      }
      if (res?.status === 200) {
        setLocationTypes(res?.data?.data);
      }
    });
  };

  const downloadDesignations = async () => {
    const downloadUrl = await apiInstance.getFiles('locations/exportexcel');

    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', 'location.xlsx');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleSubmitLocations = async (values) => {
    startCreateLoading();
    await crud.create('locations', values, (_err, res) => {
      if (res?.status === 201) {
        toast.success('Location Created Successfully');
        stopCreateLoading();
        setOpenCreate(false);
        fetchData();
        reset();
      } else {
        stopCreateLoading();
        setOpenCreate(true);
      }
    });
  };

  const handleCreateClickOpen = () => {
    reset();
    setOpenCreate(true);
  };
  const handleCreateClose = () => {
    reset();
    setOpenCreate(false);
  };
  const handleViewClickOpen = async (props) => {
    setOpenView(true);
    setLoader(true);
    await crud.getSingle('locations', props?.locationId, (_err, res) => {
      if (res?.status === 200) {
        setSelectedRow(res?.data);
        setLoader(false);
      } else {
      }
    });
  };
  const handleViewClose = () => {
    setOpenView(false);
  };

  const handleEditOpen = async (props) => {
    setOpenEdit(true);
    setLoader(true);
    await crud.getSingle('locations', props?.locationId, (_err, res) => {
      if (res?.status === 200) {
        Object.entries({
          ...res?.data,
        }).forEach(([key, value]) => {
          setValue(key, value);
        });
        setEditData(res?.data);
        setLoader(false);
      } else {
        setLoader(true);
      }
    });
  };
  const handleEditLocations = async (values) => {
    startEditLoading();
    await crud.update(
      'locations',
      editData?.locationId,
      values,
      (_err, res) => {
        if (res?.status === 200) {
          toast.success('Location Updated Successfully');
          stopEditLoading();
          setOpenEdit(false);
          fetchData();
        } else {
          stopEditLoading();
          setOpenEdit(true);
        }
      }
    );
  };

  const handleDelete = async (props) => {
    await crud.remove('locations', props?.locationId, (_err, res) => {
      if (res?.status === 204) {
        toast.success('Location Deleted Successfully');
        fetchData();
      } else {
      }
    });
  };

  const counters = [
    {
      name: 'All',
      count: tableRecordCounts?.total || 0,
    },
    {
      name: 'Active',
      count: tableRecordCounts?.active || 0,
    },
    {
      name: 'Deactivated',
      count: tableRecordCounts?.inActive || 0,
    },
  ];
  const ActionData = [
    {
      name: 'View',
      icon: Icons.view,
      onClick: (props) => handleViewClickOpen(props),
    },
    {
      name: 'Edit',
      icon: Icons.edit,
      onClick: (props) => handleEditOpen(props),
    },
    {
      name: 'Delete',
      icon: Icons.delete,
      onClick: (props) => handleDelete(props),
      danger: true,
    },
  ];

  const columns = [
    {
      field: 'action',
      headerName: '',
      width: 140,
      sortable: false,
      disableClickEventBubbling: false,

      renderCell: (params) => {
        return (
          <TableActionButtons
            Actions={ActionData?.map((action) => ({
              ...action,
              onClick: () => action.onClick(params.row),
            }))}
            selectedRow={params.row}
            setSelectedRow={setSelectedRow}
          />
        );
      },
    },
    {
      field: 'locationCode',
      headerName: 'Code',
      flex: 1,
    },
    {
      field: 'locationName',
      headerName: 'Name',
      flex: 1,
    },
    {
      field: 'locationTypeName',
      headerName: 'Location Type Name',
      flex: 1,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderCell: (params) => {
        return (
          <StatusBadge
            title={params.value === 2 ? 'Active' : 'Inactive'}
            type={params.value === 2 ? 'green' : 'red'}
          />
        );
      },
    },
  ];
  const SubMenu = useMemo(() => {
    return menu?.map((item) => item?.menus?.flat()).flat() || [];
  }, [menu]);

  const resultHRMS = useMemo(
    () => findNavByName(SubMenu, 'HRMS', 'All Masters'),
    [SubMenu]
  );
  const resultPresales = useMemo(
    () => findNavByName(SubMenu, 'PreSales', 'All master'),
    [SubMenu]
  );
  const resultProcurement = useMemo(
    () => findNavByName(SubMenu, 'Procurement', 'All Masters'),
    [SubMenu]
  );

  return (
    <DashboardLayout
      title='Location'
      actionButtons={
        <>
          <PageHeader counters={counters} />
        </>
      }
      hasSubmenu
      menu={
        type === 'presales'
          ? resultPresales
          : type === 'procurement'
            ? resultProcurement
            : resultHRMS
      }
    >
      <GlassCard className='h-full-css'>
        <Box className='h-full-css'>
          <DataTable
            rows={rows}
            columns={columns}
            loading={isLoading}
            getRowClassName={(params) =>
              `${params.row.status === 0 && 'bg-red-50'}`
            }
            sortingMode='server'
            paginationMode='server'
            onPaginationModelChange={(model) =>
              handlePaginationModelChange(model)
            }
            onSortModelChange={(model) => handleSortModelChange(model)}
            page={pageNumber - 1}
            pageSize={pageSize}
            rowCount={tableRecordCounts?.total}
            slots={{
              toolbar: () => (
                <Box
                  className='p-2 w-full flex justify-between items-center'
                  sx={{
                    borderBottom: '1px solid',
                    borderColor: 'border.main',
                  }}
                >
                  <Box sx={{ maxWidth: '250px' }}>
                    <TableSearch
                      placeholder='Search'
                      fullWidth
                      setSearchKeyword={setSearchKeyword}
                      searchValue={searchKeyword}
                    />
                  </Box>

                  <Box className='flex items-center gap-4 justify-end'>
                    <CreateButton
                      name='Create'
                      onClick={handleCreateClickOpen}
                    />
                    <DataTableToolbarButtons
                      disableColumn
                      handleExcelExport={downloadDesignations}
                    />
                  </Box>
                </Box>
              ),
            }}
          />
        </Box>
      </GlassCard>

      {/* create edit */}

      <MuiDialogOne
        title='Create'
        open={openCreate}
        onClose={handleCreateClose}
      >
        <form onSubmit={handleSubmit(handleSubmitLocations)}>
          <Grid container spacing={3}>
            <Grid item md={12}>
              <FmTextField
                name='locationName'
                control={control}
                label='Location Name'
                rules={{
                  required: 'Location Name is required',
                }}
              />
            </Grid>
            <Grid item md={12}>
              <FmAutoComplete
                name='locationTypeId'
                control={control}
                options={locationTypes}
                label='Location Types'
                displayField='locationTypeName'
                optionFields={['locationTypeName']}
                valueKey='locationTypeId'
                rules={{
                  required: 'Location Type is required',
                }}
              />
            </Grid>
            <Grid item md={12}>
              <ActionButtons
                onSubmit={handleSubmit(handleSubmitLocations)}
                onReset={reset}
                onCancel={handleCreateClose}
                submitLoading={createLoading}
                cancelLoading={false}
              />
            </Grid>
          </Grid>
        </form>
      </MuiDialogOne>

      {/* create edit */}

      <MuiDialogOne
        title='View Details'
        open={openView}
        onClose={handleViewClose}
      >
        {loading ? (
          <CommonLoader />
        ) : (
          <Grid container spacing={2}>
            <Grid item md={6}>
              <ViewField
                label='Location Code'
                title={selectedRow?.locationCode}
              />
            </Grid>
            <Grid item md={6}>
              <ViewField
                label='Location Name'
                title={selectedRow?.locationName}
              />
            </Grid>
            <Grid item md={6}>
              <ViewField
                label='Location Type Name'
                title={selectedRow?.locationTypeName}
              />
            </Grid>
            <Grid item md={6}>
              <ViewField label='Status' title={selectedRow?.statusName} />
            </Grid>
          </Grid>
        )}
      </MuiDialogOne>

      {/*EDIT*/}
      <MuiDialogOne
        title='Edit'
        open={openEdit}
        onClose={() => setOpenEdit(false)}
      >
        {loading ? (
          <CommonLoader />
        ) : (
          <form onSubmit={handleSubmit(handleEditLocations)}>
            <Grid container spacing={3}>
              <Grid item md={12}>
                <FmTextField
                  name='locationName'
                  control={control}
                  label='Location Name'
                  rules={{
                    required: 'Location Name is required',
                  }}
                />
              </Grid>

              <Grid item md={12}>
                <FmAutoComplete
                  name='locationTypeId'
                  control={control}
                  options={locationTypes}
                  label='Location Types'
                  displayField='locationTypeName'
                  optionFields={['locationTypeName']}
                  valueKey='locationTypeId'
                />
              </Grid>
              <Grid item md={12}>
                <FmAutoComplete
                  name='status'
                  control={control}
                  options={statusOptions}
                  label='Status'
                  displayField='statusName'
                  optionFields={['statusName']}
                  valueKey='statusId'
                />
              </Grid>
              <Grid item md={12}>
                <ActionButtons
                  onSubmit={handleSubmit(handleEditLocations)}
                  onReset={reset}
                  onCancel={() => setOpenEdit(false)}
                  submitLoading={editLoading}
                  cancelLoading={false}
                  submitText='Update'
                />
              </Grid>
            </Grid>
          </form>
        )}
      </MuiDialogOne>
    </DashboardLayout>
  );
};

export default LocationMasters;
