import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Crud_Service from '../../../../apis/CrudService.jsx';
import { ActionButtons } from '../../../../components/_form/index.jsx';
import FmRadioButton from '../../../../components/_mui/FmRadioButton.tsx';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import { vehicelsSchema } from '../../../../utils/CommonvalidationSchemas.tsx';
import useCommonFetchApi from '../../../../components/helpers/useCommonFetchApi.tsx';
import FmAutoComplete from '../../../../components/_mui/FmAutoComplete.tsx';

const GridSize = 2.4;

const AddOwnVehicle = (props) => {
  const { setGetData } = props;
  const crud = new Crud_Service();
  const [adRequired, setAdRequired] = React.useState(false);
  const { startCreateLoading, stopCreateLoading, createLoading } = useLoader();
  const { control, handleSubmit, reset, setValue } = useForm({
    resolver: yupResolver(vehicelsSchema),
    mode: 'onChange',
    defaultValues: {
      advertisement: false,
    },
  });
  const navigate = useNavigate();

  const handleReset = () => {
    reset();
  };
  const { data: drivers } = useCommonFetchApi('drivers') || {
    data: [],
  };

  const submitVehicle = async (values) => {
    startCreateLoading();

    const combinedData = {
      ...values,
      advertisementId: values?.advertisementId || null,
    };

    await crud.create('ownvehicles', combinedData, (_err, res) => {
      if (res?.status === 201) {
        toast.success('Own Vehicle Added Successfully');
        stopCreateLoading();
        navigate(-1);
        reset();
        setGetData(true);
      } else {
        stopCreateLoading();
      }
    });
  };

  return (
    <DashboardLayout title='Create Own vehicles'>
      <GlassCard className='p-3'>
        <form onSubmit={handleSubmit(submitVehicle)}>
          <Grid container spacing={2}>
            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='vehicleId'
                label='Vehicle'
                apiUrl={'vehicles'}
                valueField='vehicleId'
                required
                labelField={['vehicleCode', 'vehicleName']}
                showField={['vehicleCode', 'vehicleName']}
                control={control}
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmTextField
                name='trafficCodeNo'
                required
                label='Traffic Code No'
                control={control}
              />
            </Grid>

            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='insuranceId'
                label='Insurance'
                required
                apiUrl={'vehicleinsurances'}
                valueField='vehicleInsuranceId'
                headerField={['Code', 'Policy Number', 'Premium Amount']}
                labelField={[
                  'vehicleInsuranceCode',
                  'policyNumber',
                  'premiumAmount',
                ]}
                showField={[
                  'vehicleInsuranceCode',
                  'policyNumber',
                  'premiumAmount',
                ]}
                control={control}
              />
            </Grid>

            <Grid item md={GridSize}>
              <FmAutoComplete
                name='driverId'
                control={control}
                options={drivers}
                label='Driver'
                displayField='driverName'
                optionFields={['driverCode', 'driverName']}
                valueKey='driverId'
                required
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='divisionId'
                label='Division'
                apiUrl={'divisions'}
                required
                valueField='divisionId'
                labelField={['divisionCode', 'divisionName']}
                showField={['divisionCode', 'divisionName']}
                control={control}
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmRadioButton
                name='advertisement'
                control={control}
                options={[
                  { value: false, label: 'No' },
                  { value: true, label: 'Yes' },
                ]}
                label='Advertisement'
                onValueChange={(value) => {
                  setAdRequired(value === 'true');
                }}
              />
            </Grid>
            {adRequired && (
              <Grid item md={GridSize}>
                <FmSearchableSelect
                  name='advertisementId'
                  label='Advertisement'
                  apiUrl={'vehicleadvertisements'}
                  required
                  valueField='vehicleAdvertisementId'
                  labelField={['vehicleAdvertisementCode', 'permitNumber']}
                  showField={['vehicleAdvertisementCode', 'permitNumber']}
                  control={control}
                />
              </Grid>
            )}

            <Grid item md={12}>
              <ActionButtons
                onSubmit={handleSubmit(submitVehicle)}
                onReset={handleReset}
                submitLoading={createLoading}
                submitText='Create'
                cancelText='Cancel'
                onCancel={() => navigate(-1)}
              />
            </Grid>
          </Grid>
        </form>
      </GlassCard>
    </DashboardLayout>
  );
};

export default AddOwnVehicle;
