import {
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Crud_Service from '../../../apis/CrudService.jsx';
import ActionButtons from '../../../components/_form/ActionButtons.tsx';
import FmSearchableSelect from '../../../components/_mui/FmSearchableSelect.tsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import BackToButton from '../../../components/small/BackToButton.jsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import FmDatePicker from '../../../components/_mui/FmDatePicker.tsx';
import dayjs from 'dayjs';
import apiInstance from '../../../apis/ApiService.jsx';
import CommonLoader from '../../../components/page/CommonLoader.jsx';
import UploadSquare from '../../../components/upload/UploadSquare.tsx';
import { Icon } from '@iconify/react/dist/iconify.js';
import MuiDialogOne from '../../../components/_mui/MuiDialogOne.jsx';
import FilePreviewUpload from '../../../components/modules/FilePreviewUpload.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import ConfirmationPopoverBox from '../../../components/_form/ConfirmationPopoverBox.tsx';

const GridSize = 2;

const GridInsideSize = 1.5;

const schema = Yup.object().shape({
  invoiceNumber: Yup.string()
    .max(32, 'Invoice Number must be at most 32 characters')
    .test(
      'is-valid',
      'Invoice Number must be at most 32 characters',
      (value) => value?.trim()?.length <= 32
    ),

  localPurchaseOrderNo: Yup.string()
    .max(128, 'Local Purchase Order Number must be at most 128 characters')
    .test(
      'is-valid',
      'Local Purchase Order Number must be at most 128 characters',
      (value) => value?.trim()?.length <= 128
    ),
  documentTypeId: Yup.string().required('Document Type ID is required'),
  supplierId: Yup.string().required('Supplier ID is required'),
  responseDate: Yup.date().required('Response Date is required'),
});

const EditVendorDocuments = () => {
  const navigation = useNavigate();
  const crud = new Crud_Service();
  const { id } = useParams();
  const { startEditLoading, stopEditLoading, editLoading } = useLoader();
  const navigate = useNavigate();
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editData, setEditData] = useState(null);
  const [previewdData, setPreviewData] = useState([]);
  const [removedFiles, setRemovedFiles] = useState([]);
  const { handleSubmit, control, setValue, getValues, setFocus, watch } =
    useForm({
      resolver: yupResolver(schema),
      mode: 'onChange',
      defaultValues: {
        createDeliveryNotes: [{ quantity: '', rate: '', amount: '' }],
      },
    });
  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: 'createDeliveryNotes',
  });

  const [open, setOpen] = useState(false);
  const [disable, setDisable] = useState(false);
  const [fileLoader, setFileLoader] = useState(false);
  const [loadingFiles, setLoadingFiles] = useState(false);
  const [file, setFile] = useState(null);

  const notEditable =
    getValues('vendorDocumentStatusName') === 'Acknowledged' ||
    getValues('vendorDocumentStatusName') === 'Upload Document' ||
    getValues('vendorDocumentStatusName') === 'Delivery Notes Submitted' ||
    getValues('vendorDocumentStatusName') === 'Verified';
  const [docDeliveryNotes, setDocDeliveryNotes] = useState([]);

  useEffect(() => {
    document.title = 'Edit Vendor Document | Financial';
    getVendorDocuments();
  }, []);

  const getVendorDocuments = async () => {
    setLoading(true);

    await crud.getSingle('vendordocuments', id, (_err, res) => {
      if (res?.status === 200) {
        Object.entries({
          ...res?.data,
        }).forEach(([key, value]) => {
          setValue(key, value);
        });
        setEditData(res?.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });

    !notEditable &&
      (await crud.getAll(
        'vendordocumentdeliverynotes',
        { vendorDocumentId: id },
        (_err, res) => {
          if (res?.status === 200) {
            const deliveryNotes = res?.data?.data || [];
            // Convert API response into expected field structure
            const formattedNotes = deliveryNotes.map((item) => ({
              vendorDocumentDeliveryNoteId: item.vendorDocumentDeliveryNoteId, // Keeping ID for reference
              contractId: item.contractId,
              contractName: item.contractName || '',
              projectId: item.projectId,
              projectName: item.projectName || '',
              description: item.description || '',
              quantity: item.quantity || 0,
              rate: item.rate || 0,
              amount: item.amount || 0,
              vat: item.vat || 0,
            }));

            // Update useFieldArray fields
            replace(formattedNotes);

            setDocDeliveryNotes(formattedNotes);
          }
        }
      ));
  };

  useEffect(() => {
    if (editData && editData?.vendorDocumentAttachments) {
      editData?.vendorDocumentAttachments?.forEach((image) => {
        handleDownloadFile(image?.fileId, image?.attachmentTypeId === 2);
        if (image?.attachmentTypeId === 2) setFile(image);
      });
    }
  }, [editData]);

  const handleDownloadFile = async (fileId, deliveryAttachment) => {
    if (!fileId) return;

    setLoadingFiles(true); // Set loading to true before the API call

    try {
      const imageUrl = await apiInstance.getFiles(`files/download/${fileId}`);
      setPreviewData((prevData) => [
        ...prevData,
        { fileId, fileUrl: imageUrl },
      ]);
    } catch (err) {
      console.error('Error downloading file:', err);
    } finally {
      setLoadingFiles(false); // Set loading to false after API call completion
    }
  };

  const handleFileUpload = async () => {
    const fileIds = [];

    for (const file of files) {
      if (file?.apiCallNo === false) {
        continue;
      }
      const formData = new FormData();
      formData.append('file', file);
      formData.append('fileTypeId', 50);

      try {
        const fileId = await new Promise((resolve, reject) => {
          crud.create('/files/upload', formData, (_err, res) => {
            if (res?.status === 200) {
              const newFile = {
                fileName: res?.data?.originalFileName,
                fileId: res?.data?.fileId,
              };
              resolve(newFile.fileId); // Resolve with fileId
              toast.success('File uploaded successfully');
            } else {
              toast.error('Failed to upload file.');
              reject(new Error('Failed to upload file.'));
            }
          });
        });

        if (fileId) {
          fileIds.push(fileId);
        }
      } catch (error) {
        console.error(`Error uploading file ${file.name}:`, error);
      }
    }

    return fileIds;
  };

  const handleDeliveryNote = (values) => {
    const combinedData = {
      vendorDocumentId: values.vendorDocumentId,
      createDeliveryNotes: [...values.createDeliveryNotes],
      status: 2,
    };
    if (docDeliveryNotes.length > 0) {
      crud.update(
        'vendordocumentdeliverynotes',
        docDeliveryNotes[0]?.vendorDocumentDeliveryNoteId,
        combinedData,
        (_err, res) => {
          if (res?.status === 200) {
            toast.success('Delivery Note Updated Successfully');
            setOpen(false);
            getVendorDocuments();
          }
        }
      );
    } else {
      crud.create('vendordocumentdeliverynotes', combinedData, (_err, res) => {
        if (res?.status === 201) {
          toast.success('Delivery Note Created Successfully');
          setOpen(false);
          getVendorDocuments();
        }
      });
    }
  };

  const handleEditVendorDocument = async (values) => {
    if (getValues('vendorDocumentStatusName') === 'Acknowledged') return;
    startEditLoading();
    try {
      const uploadedFileIds = await handleFileUpload();
      setDisable(true);
      const matchingAttachments = values?.vendorDocumentAttachments?.filter(
        (attachment) =>
          removedFiles?.some(
            (removedFileId) => removedFileId?.fileId === attachment?.fileId
          )
      );

      const newAttachments = uploadedFileIds?.map((fileId) => ({
        vendorDocumentAttachmentId: 0, // New attachment
        fileId: fileId,
        status: 2,
      }));

      // Combine existing and new attachments
      const updateVendorDocumentAttachments = [
        ...matchingAttachments,
        ...newAttachments,
      ];
      const combinedData = {
        ...values,
        status: 2,
        vendorDocumentStatusId:
          editData?.documentTransferredTo === null &&
          values?.documentTransferredTo
            ? 2
            : getValues('vendorDocumentStatusName') ===
              'Transferred to Coordinator'
            ? 3
            : getValues('vendorDocumentStatusName') === 'Acknowledged'
            ? 4
            : getValues('vendorDocumentStatusName') === 'Upload Document'
            ? 5
            : getValues('vendorDocumentStatusName') ===
              'Delivery Notes Submitted'
            ? 6
            : 7,
        responseDate: dayjs(values?.responseDate).format('YYYY-MM-DD'),
        updateVendorDocumentAttachments: updateVendorDocumentAttachments,
      };

      const response = await new Promise((resolve, reject) => {
        crud.update(`vendordocuments`, id, combinedData, (_err, res) => {
          if (res?.status === 200) {
            resolve(res);
            setOpen(false);
          } else {
            reject(new Error('Failed to Update Vendor Document.'));
          }
        });
      });

      toast.success('Vendor Documents Updated Successfully');
      stopEditLoading();
      navigation(-1);
    } catch (error) {
      toast.error(error.message || 'An error occurred');
    } finally {
      stopEditLoading();
    }
  };
  const [selectedFiles, setSelectedFiles] = React.useState(null);

  const handleUpdatedItems = (e) => {
    setSelectedFiles(e);
    const fileFormData = new FormData();
    fileFormData.append('file', e);
    fileFormData.append('fileTypeId', 4);
    setFileLoader(true);
    crud.create('files/upload', fileFormData, (_err, res) => {
      if (res?.status === 200) {
        const data = {
          vendorDocumentId: id,
          fileIds: [res?.data?.fileId],
        };

        crud.create(
          'vendordocuments/vendordocumentattachments',
          data,
          (_err, res) => {
            if (res?.status === 200) {
              toast.success('File uploaded successfully');
              setDisable(false);
              getVendorDocuments();
            }
          }
        );
      }
    });
    append({});
  };
  const handleKeyDown = (e, index) => {
    if (e.key === 'Tab') {
      e.preventDefault();
      append({
        projectId: '',
        contractId: '',
        description: '',
        quantity: '',
        rate: '',
        vat: '',
        amount: '',
      });

      setTimeout(() => {
        requestAnimationFrame(() => {
          setFocus(`createDeliveryNotes[${index + 1}].projectId`);
        });
      });
    }
  };

  const watchedFields =
    useWatch({ control, name: 'createDeliveryNotes' }) || [];

  const prevFieldsRef = useRef([]);
  useEffect(() => {
    const prevFields = prevFieldsRef.current;

    const updates = [];
    watchedFields?.forEach((field, index) => {
      if (!field) return;

      const quantity = parseFloat(field.quantity) || 0;
      const rate = parseFloat(field.rate) || 0;
      const amount =
        quantity * rate +
          (quantity * rate * (field.vat ? parseFloat(field.vat) : 0)) / 100 ||
        0;

      if (!isNaN(amount) && prevFields[index]?.amount !== amount.toFixed(2)) {
        updates.push({ index, amount: amount.toFixed(2) });
      }
    });

    // Batch updates to avoid multiple re-renders
    if (updates.length > 0) {
      updates.forEach(({ index, amount }) => {
        setValue(`createDeliveryNotes.${index}.amount`, amount);
      });
      prevFieldsRef.current = watchedFields; // Update previous values
    }
  }, [watchedFields]);

  return (
    <>
      <DashboardLayout
        title='Edit Vendor Document'
        actionButtons={
          <BackToButton
            title='Back to List'
            onClick={() => navigation(-1)}
            className='font-bold px-1 py-0'
          />
        }
        titleReverse
      >
        {loading ? (
          <CommonLoader />
        ) : (
          <>
            {' '}
            <GlassCard className='p-3'>
              <form onSubmit={handleSubmit(handleEditVendorDocument)}>
                <Grid container spacing={2}>
                  <Grid item md={GridSize}>
                    <FmSearchableSelect
                      name={`supplierId`}
                      control={control}
                      apiUrl='suppliers'
                      valueField='supplierId'
                      required
                      headerField={['Supplier Code', 'Supplier Name']}
                      labelField={[
                        'supplierInvitationCode',
                        'officialSupplierName',
                      ]}
                      sortBy={'officialSupplierName'}
                      showField={[
                        'supplierInvitationCode',
                        'officialSupplierName',
                      ]}
                      disabled={notEditable}
                      label='Supplier'
                      defaultValue={{
                        supplierId: editData?.supplierId,
                        officialSupplierName: editData?.supplierName,
                      }}
                    />
                  </Grid>
                  <Grid item md={GridSize}>
                    <FmSearchableSelect
                      name={`documentTypeId`}
                      control={control}
                      apiUrl='documenttypes'
                      valueField='documentTypeId'
                      required
                      queryparam={'documentTypeCategoryId'}
                      queryparamValue={11}
                      disabled={notEditable}
                      headerField={['Document Type']}
                      labelField={['documentTypeName']}
                      showField={['documentTypeName']}
                      label='Document Type'
                      defaultValue={{
                        documentTypeId: editData?.documentTypeId,
                        documentTypeName: editData?.documentTypeName,
                      }}
                    />
                  </Grid>
                  <Grid item md={GridSize}>
                    <FmTextField
                      name={`localPurchaseOrderNo`}
                      label='Local Purchase Order No.'
                      control={control}
                      disabled={notEditable}
                      type='string'
                    />
                  </Grid>
                  <Grid item md={GridSize}>
                    <FmTextField
                      name={`invoiceNumber`}
                      label='Document Ref. No./Invoice No.'
                      control={control}
                      disabled={notEditable}
                      type='string'
                      required
                    />
                  </Grid>
                  <Grid item md={GridSize}>
                    <FmDatePicker
                      name={`responseDate`}
                      disabled={notEditable}
                      control={control}
                      label='Response Date'
                    />
                  </Grid>
                  <Grid item md={GridSize}>
                    <FmSearchableSelect
                      name={`documentTransferredTo`}
                      control={control}
                      apiUrl='loginusers'
                      valueField='loginUserId'
                      queryparam='isCoordinator'
                      disabled={notEditable}
                      queryparamValue={true}
                      labelField={['employeeCode', 'loginUserName']}
                      showField={['employeeCode', 'loginUserName']}
                      label='Document Transferred To'
                      defaultValue={{
                        loginUserId: editData?.documentTransferredTo,
                        loginUserName: editData?.documentTransferredToUserName,
                      }}
                    />
                  </Grid>
                  <Grid item md={GridSize}>
                    <FmTextField
                      readOnly
                      label='Status'
                      name={`vendorDocumentStatusName`}
                      control={control}
                      disabled
                    />
                  </Grid>
                  <Grid item md={12}>
                    <UploadSquare
                      files={files}
                      setFiles={setFiles}
                      onFilesSelected={setFiles}
                      previewdData={previewdData}
                      disableUpload={!notEditable}
                      glassCard={false}
                      loadingFiles={loadingFiles}
                      setRemovedFiles={setRemovedFiles}
                    />
                  </Grid>
                </Grid>
                <Box className='mt-4'></Box>

                <Grid
                  container
                  spacing={2}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-end',
                  }}
                >
                  <Grid item md={GridSize * 3}>
                    <FmTextField
                      name={`remarks`}
                      label='Remarks'
                      disabled={notEditable}
                      control={control}
                      multiline={true}
                      rows={2}
                      maxRows={5}
                    />
                  </Grid>

                  {getValues('vendorDocumentStatusName') !== 'Verified' && (
                    <Grid item md={GridSize}>
                      <Box className='justify-end w-full flex'>
                        <Button
                          variant='contained'
                          color='secondary'
                          type='submit'
                          disabled={editLoading}
                          onClick={() => {
                            getValues('vendorDocumentStatusName') ===
                            'Acknowledged'
                              ? setOpen(true)
                              : handleSubmit(handleEditVendorDocument);
                          }}
                          startIcon={<Icon icon='mdi:content-save' />}
                        >
                          {getValues('vendorDocumentStatusName') === 'Submitted'
                            ? 'Update'
                            : getValues('vendorDocumentStatusName') ===
                              'Transferred to Coordinator'
                            ? 'Acknowledge'
                            : getValues('vendorDocumentStatusName') ===
                              'Acknowledged'
                            ? 'Upload Document'
                            : getValues('vendorDocumentStatusName') !==
                              'Verified'
                            ? 'Verify'
                            : ''}
                        </Button>
                      </Box>
                    </Grid>
                  )}
                  {getValues('vendorDocumentStatusName') === 'Verified' && (
                    <Grid item md={GridSize}>
                      <Box className='justify-end w-full flex'>
                        <Button
                          variant='contained'
                          color='success'
                          type='submit'
                          disabled={editLoading}
                          onClick={() => {}}
                          startIcon={<Icon icon='mdi:content-save' />}
                        >
                          {'Approve'}
                        </Button>
                        <Button
                          variant='contained'
                          color='warning'
                          type='submit'
                          disabled={editLoading}
                          onClick={() => {}}
                          startIcon={<Icon icon='mdi:content-save' />}
                        >
                          {'Reject'}
                        </Button>
                      </Box>
                    </Grid>
                  )}
                </Grid>

                {
                  <MuiDialogOne
                    title={'Upload Documents'}
                    open={open}
                    onClose={() => setOpen(false)}
                    aria-labelledby='alert-dialog-title'
                    aria-describedby='alert-dialog-description'
                    width={'100%'}
                  >
                    <Grid container spacing={2}>
                      <Grid item md={4}>
                        <FilePreviewUpload
                          outerFile={handleUpdatedItems}
                          name={'Upload Files'}
                          desc={'Upload Document for vendor'}
                          file={file}
                        />
                      </Grid>
                      <Grid item md={8}>
                        <Box>
                          {fields?.map((item, index) => (
                            <GlassCard className='p-3 mb-3'>
                              <Grid container spacing={2} key={item?.id}>
                                <Grid item md={GridSize}>
                                  <FmSearchableSelect
                                    name={`createDeliveryNotes[${index}].projectId`}
                                    control={control}
                                    apiUrl='projects'
                                    valueField='projectId'
                                    headerField={[
                                      'Project Code',
                                      'Project Name',
                                    ]}
                                    defaultValue={{
                                      projectId: item?.projectId,
                                      projectName: item?.projectName,
                                    }}
                                    required
                                    labelField={['projectCode', 'projectName']}
                                    label='Project'
                                    showField={['projectCode', 'projectName']}
                                  />
                                </Grid>
                                <Grid item md={GridSize}>
                                  <FmSearchableSelect
                                    name={`createDeliveryNotes[${index}].contractId`}
                                    control={control}
                                    apiUrl='contracts'
                                    valueField='contractId'
                                    headerField={[
                                      'Contract Code',
                                      'Contract Name',
                                    ]}
                                    labelField={[
                                      'contractCode',
                                      'contractName',
                                    ]}
                                    defaultValue={{
                                      contractId: item?.contractId,
                                      contractName: item?.contractName,
                                    }}
                                    label='Contract'
                                    showField={['contractCode', 'contractName']}
                                  />
                                </Grid>
                                <Grid item md={GridSize * 3}>
                                  <FmTextField
                                    name={`createDeliveryNotes[${index}].description`}
                                    label='Description'
                                    control={control}
                                    required
                                    type='string'
                                  />
                                </Grid>
                                <Grid item md={GridSize}>
                                  <FmTextField
                                    name={`createDeliveryNotes.${index}.quantity`}
                                    label='Quantity'
                                    control={control}
                                    type='string'
                                  />
                                </Grid>
                                <Grid item md={GridSize}>
                                  <FmTextField
                                    name={`createDeliveryNotes.${index}.rate`}
                                    label='Rate'
                                    control={control}
                                    type='string'
                                  />
                                </Grid>
                                <Grid item md={GridSize}>
                                  <FmTextField
                                    name={`createDeliveryNotes.${index}.vat`}
                                    label='VAT'
                                    control={control}
                                    onKeyDown={(e) => handleKeyDown(e, index)}
                                    type='string'
                                  />
                                </Grid>
                                <Grid item md={GridSize}>
                                  <FmTextField
                                    name={`createDeliveryNotes.${index}.amount`}
                                    label='Amount'
                                    control={control}
                                    disabled
                                    type='string'
                                  />
                                </Grid>

                                <Grid item md={GridSize}>
                                  <Box className='flex justify-end'>
                                    {/* <IconButton
                                      onClick={() => remove(index)}
                                      disabled={fields?.length === 1}
                                      sx={{
                                        padding: '1px',
                                        marginLeft: 'auto',
                                      }}
                                      color='secondary'
                                    >
                                      <Icon icon='ic:baseline-clear' />
                                    </IconButton> */}
                                    <ConfirmationPopoverBox
                                      title='Remove File'
                                      icon={
                                        <Icon
                                          icon='material-symbols:close'
                                          width='10'
                                          height='10'
                                        />
                                      }
                                      onConfirm={() => {
                                        remove(index);
                                      }}
                                    />
                                  </Box>
                                </Grid>
                              </Grid>
                            </GlassCard>
                          ))}

                          <Box className='mt-5 w-full flex justify-end gap-3'>
                            {fields?.length > 0 && (
                              <Button
                                variant='contained'
                                onClick={handleSubmit(handleDeliveryNote)}
                                disabled={disable}
                                className='font-semibold min-w-28'
                              >
                                Submit
                              </Button>
                            )}
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </MuiDialogOne>
                }
              </form>
            </GlassCard>
            {docDeliveryNotes?.length > 0 && (
              <TextField value='Delivery Notes' disabled variant='standard' />
            )}
            {docDeliveryNotes?.length > 0 &&
              docDeliveryNotes?.map((item, index) => (
                <GlassCard className='p-3 mb-3'>
                  <Grid container spacing={2} key={item?.id}>
                    <Grid item md={GridSize}>
                      <TextField
                        label='Project'
                        value={item?.projectName}
                        disabled
                      />
                    </Grid>
                    <Grid item md={GridSize}>
                      <TextField
                        label='Contract'
                        value={item?.contractName}
                        disabled
                      />
                    </Grid>
                    <Grid item md={GridSize * 3}>
                      <TextField
                        label='Description'
                        disabled
                        value={item?.description}
                      />
                    </Grid>
                    <Grid item md={GridSize}>
                      <TextField
                        label='Quantity'
                        disabled
                        value={item?.quantity}
                      />
                    </Grid>
                    <Grid item md={GridSize}>
                      <TextField label='Rate' disabled value={item?.rate} />
                    </Grid>
                    <Grid item md={GridSize}>
                      <TextField label='VAT' disabled value={item?.vat} />
                    </Grid>
                    <Grid item md={GridSize}>
                      <TextField label='Amount' disabled value={item?.amount} />
                    </Grid>
                  </Grid>
                  <Grid item md={GridSize}>
                    <Box className='flex justify-end'>
                      <IconButton
                        onClick={() => setOpen(true)}
                        sx={{
                          padding: '1px',
                          marginLeft: 'auto',
                        }}
                        color='secondary'
                      >
                        <Icon icon='mdi:pencil-outline' />
                      </IconButton>
                    </Box>
                  </Grid>
                </GlassCard>
              ))}
          </>
        )}
      </DashboardLayout>
    </>
  );
};

export default EditVendorDocuments;
