import React, { useEffect, useRef, useState } from 'react'
import DashboardLayout from '../../../components/DashboardLayout.tsx'
import { Box, Button, Grid } from '@mui/material'
import GlassCard from '../../../components/small/GlassCard.tsx'
import FmSearchableSelect from '../../../components/_mui/FmSearchableSelect.tsx'
import { Controller, useForm } from 'react-hook-form'
import FmDatePicker from '../../../components/_mui/FmDatePicker.tsx'
import FmTextField from '../../../components/_mui/FmTextField.tsx'
import MuiSignModal from '../../../components/_mui/MuiSignModal.tsx'
import { toast } from 'react-toastify'
import Crud_Service from '../../../apis/CrudService.jsx'
import useLoader from '../../../components/helpers/UseLoader.tsx'
import ActionButtons from '../../../components/_form/ActionButtons.tsx'
import { useLocation, useNavigate } from 'react-router-dom'
import FmAutoComplete from '../../../components/_mui/FmAutoComplete.tsx'
import { passportSubmissionStatus } from '../../../utils/CommonVariables.tsx'
import dayjs from 'dayjs'
import UploadSquareBoxes from '../../../components/_form/UploadSquareBoxes.tsx'
import { yupResolver } from '@hookform/resolvers/yup'
import { passportRequestSubmission } from '../../../utils/CommonvalidationSchemas.tsx'

function SubmissionCreate() {
    const { control, setValue, trigger, reset, handleSubmit, watch, formState: { errors }, setError, clearErrors, getValues } = useForm({
        mode: 'onChange',
        resolver: yupResolver(passportRequestSubmission),
    });
    const { createLoading, stopEditLoading, startEditLoading } = useLoader();
    const sigCanvas = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();
    const { employeePassportRequestId, employeeName, employeeId } = location.state || {};

    const [signatureImage, setSignatureImage] = useState(null);
    const [signatureId, setSignatureId] = useState(null);
    const [submissionStatus, setSubmissionStatus] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const crud = new Crud_Service();
    const [components, setComponents] = useState([]);
    const [imageUrl, setImageUrl] = useState([]);
    const [counter, setCounter] = useState(1);
    const [scopeDoc, setScopeDoc] = useState([]);
    const passportIssueDate = watch('passportIssueDate');
    const passportExpiryDate = watch('passportExpiryDate');
    const [imageLoading, setImageLoading] = useState(
        components.reduce((acc, component) => {
            acc[component.id] = false; // Initialize loading state for each component
            return acc;
        }, {})
    );
    const handleSignatureUpload = (fileId) => {
        setSignatureId(fileId);
    };
    const handleSaveSignature = async () => {
        if (sigCanvas.current && !sigCanvas.current.isEmpty()) {
            const signatureImage = sigCanvas.current.toDataURL("image/png");

            await uploadSignature(signatureImage);
        }
        setShowModal(false);
    };
    const uploadSignature = async (base64Image) => {
        const blob = await fetch(base64Image).then((res) => res.blob());
        const fileFormData = new FormData();
        fileFormData.append("file", blob, "signature.png");
        fileFormData.append("fileTypeId", 71);

        await crud.create(`/files/upload`, fileFormData, (_err, res) => {
            if (res?.status === 200) {
                setSignatureId(res?.data?.fileId);
                setSignatureImage(null);
                setValue("signatureImage", res?.data?.fileId);
                trigger("signatureImage");
                toast.success("signature upload successful");
            } else {
                toast.error("something_Went_Wrong");
            }
        });
    };


    const handleSubmitPassportRequestSubmission = async (values) => {

        startEditLoading();
        const employeePassport =
            values?.passportRenewedOrNormalSubmission === 2
                ? {
                    employeeId: employeeId,
                    passportNumber: values?.passportNumber,
                    passportIssuePlace: values?.passportIssuePlace,
                    passportIssueDate: dayjs(values?.passportIssueDate).format('YYYY-MM-DD'),
                    passportExpiryDate: dayjs(values?.passportExpiryDate).format('YYYY-MM-DD'),
                    passportFrontSideFileId: Array.isArray(values?.fileUploadIds) ? values.fileUploadIds[0] || '' : '',
                    passportBackSideFileId: Array.isArray(values?.fileUploadIds) ? values.fileUploadIds[1] || '' : '',
                }
                : null;

        const CombinedData = {
            passportSubmittedById: Number(values?.passportSubmittedById),
            passportSubmitterSignatureFileId: signatureId ? Number(signatureId) : 0,
            submissionRemarks: values?.submissionRemarks,
            passportSubmittedOn: dayjs(values?.passportSubmittedOn).format('YYYY-MM-DD'),
            passportRenewedOrNormalSubmission: values?.passportRenewedOrNormalSubmission,
            // Include employeePassport only when it's not null
            ...(employeePassport ? { employeePassport } : {}),
        };
        crud.update(
            `employee-passport-requests/${employeePassportRequestId}/submission`,
            '',
            CombinedData,
            (err, res) => {
                if (res?.status === 200) {
                    toast.success('Passport Submission Updated Successfully');
                    navigate(-1);
                    stopEditLoading();
                } else {
                    stopEditLoading();
                }
            }
        );
    };
    const handlestatusChange = (e) => {
        setSubmissionStatus(e?.value);
        if (submissionStatus === 1) {
            setValue('passportNumber', '');
            setValue('passportIssuePlace', '');
            setValue('employeeId', '');
        }
    };

    const handleAddComponent = () => {
        const currentFileIds = getValues('fileUploadIdsFront') || [];
        if (currentFileIds.length >= 1) {
            setError('fileUploadIdsFront', {
                type: 'manual',
                message: 'You can upload only one file for Passport Front.',
            });
            return;
        } else {
            clearErrors('fileUploadIdsFront');
        }
        setComponents([{ id: counter }]); // Ensure only one component for front
        setCounter(counter + 1);
    };

    const handleAddScopeComponent = () => {
        const currentFileIds = getValues('fileUploadIdsBack') || [];
        if (currentFileIds.length >= 1) {
            setError('fileUploadIdsBack', {
                type: 'manual',
                message: 'You can upload only one file for Passport Back.',
            });
            return;
        } else {
            clearErrors('fileUploadIdsBack');
        }
        setScopeDoc([{ id: counter }]); // Ensure only one component for back
        setCounter(counter + 1);
    };

    const handleFileChange = (id, fileId) => {
        if (typeof fileId === 'number') {
            const currentFileIds = getValues('fileUploadIds') || [];
            const updatedFileIds = [...currentFileIds, fileId];
            setValue('fileUploadIds', updatedFileIds);
        }
    };



    const handleRemoveComponent = (id, type) => {
        if (type === 'front') {
            setComponents(components.filter((component) => component.id !== id));
            setValue('fileUploadIdsFront', []); // Clear the uploaded file ID
            clearErrors('fileUploadIdsFront');
        } else {
            setScopeDoc(scopeDoc.filter((scopeDoc) => scopeDoc.id !== id));
            setValue('fileUploadIdsBack', []); // Clear the uploaded file ID
            clearErrors('fileUploadIdsBack');
        }
    };

    return (
        <DashboardLayout title='Create New Submission'>
            <Box className='my-5 mx-auto' sx={{ maxWidth: '500px' }}>
                <GlassCard className='p-4'>
                    <form onSubmit={handleSubmit(handleSubmitPassportRequestSubmission)}>
                        <Grid container spacing={2}>
                            <Grid item md={6}>
                                <FmAutoComplete
                                    name='passportRenewedOrNormalSubmission'
                                    control={control}
                                    options={passportSubmissionStatus}
                                    label='Submission Type'
                                    displayField='label'
                                    optionFields={['label']}
                                    valueKey='value'
                                    onChange={handlestatusChange}
                                    onChangeValue={true}
                                />
                            </Grid>
                            {submissionStatus === 2 && (
                                <>
                                    <Grid item md={6}>
                                        <FmSearchableSelect
                                            name='employeeId'
                                            control={control}
                                            apiUrl='employees'
                                            defaultValue={{
                                                firstName: employeeName
                                            }}
                                            valueField='employeeId'
                                            labelField={['employeeCode', 'firstName']}
                                            showField={['employeeCode', 'firstName']}
                                            label={'Employees'}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item md={6}>
                                        <FmTextField
                                            name='passportNumber'
                                            control={control}
                                            label='Passport Number'
                                        />
                                    </Grid>
                                    <Grid item md={6}>
                                        <FmSearchableSelect
                                            name='passportIssuePlace'
                                            control={control}
                                            apiUrl='locations'
                                            valueField='locationId'
                                            labelField={['locationCode', 'locationName']}
                                            showField={['locationCode', 'locationName']}
                                            label={'Passport Issue Place'}

                                        />
                                    </Grid>
                                    <Grid item md={6}>
                                        <FmDatePicker
                                            name='passportIssueDate'
                                            label='Passport Issue Date'
                                            control={control}
                                            minDate={passportExpiryDate ? dayjs(passportExpiryDate) : null} //remainder
                                        />
                                    </Grid>
                                    <Grid item md={6}>
                                        <FmDatePicker
                                            name='passportExpiryDate'
                                            label='Passport Expire Date'
                                            control={control}
                                            minDate={passportIssueDate ? dayjs(passportIssueDate) : null} // sche
                                        />
                                    </Grid>
                                    <Grid item md={6}>
                                        <Box>
                                            <Box className="flex gap-5 flex-wrap">
                                                <Button
                                                    onClick={handleAddComponent}
                                                    variant="outlined"
                                                    className="px-1 w-full"
                                                    disabled={components.length >= 1}
                                                >
                                                    <span>Passport Front</span>
                                                </Button>

                                                {components.map((component) => (
                                                    <UploadSquareBoxes
                                                        key={component.id}
                                                        imageUrl={component.path}
                                                        setImageUrl={setImageUrl}
                                                        handleRemove={() => handleRemoveComponent(component.id, 'front')}
                                                        setComponents={setComponents}
                                                        id={component.id}
                                                        setLoading={setImageLoading}
                                                        isLoading={imageLoading[component.id]}
                                                        onChange={(fileId) => handleFileChange(component.id, fileId, 'front')}
                                                        fileTypeId={38}
                                                    />
                                                ))}
                                            </Box>
                                            {errors.fileUploadIdsFront && (
                                                <span style={{ color: 'red' }}>{errors.fileUploadIdsFront.message}</span>
                                            )}
                                        </Box>
                                    </Grid>

                                    <Grid item md={6}>
                                        <Box>
                                            <Box className="flex gap-5 flex-wrap">
                                                <Button
                                                    onClick={handleAddScopeComponent}
                                                    variant="outlined"
                                                    className="px-1 w-full"
                                                    disabled={scopeDoc.length >= 1}
                                                >
                                                    <span>Passport Back</span>
                                                </Button>

                                                {scopeDoc.map((scopeDoc) => (
                                                    <UploadSquareBoxes
                                                        key={scopeDoc.id}
                                                        imageUrl={scopeDoc.path}
                                                        setImageUrl={setImageUrl}
                                                        handleRemove={() => handleRemoveComponent(scopeDoc.id, 'back')}
                                                        setComponents={setScopeDoc}
                                                        id={scopeDoc.id}
                                                        setLoading={setImageLoading}
                                                        isLoading={imageLoading[scopeDoc.id]}
                                                        onChange={(fileId) => handleFileChange(scopeDoc.id, fileId, 'back')}
                                                        fileTypeId={38}
                                                    />
                                                ))}
                                            </Box>
                                            {errors.fileUploadIdsBack && (
                                                <span style={{ color: 'red' }}>{errors.fileUploadIdsBack.message}</span>
                                            )}
                                        </Box>
                                    </Grid>
                                </>
                            )}
                            <Grid item md={6}>
                                <FmDatePicker
                                    name='PassportSubmittedOn'
                                    label='Passport Submitted On'
                                    control={control}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <FmSearchableSelect
                                    name='passportSubmittedById'
                                    control={control}
                                    apiUrl='loginusers'
                                    valueField='loginUserId'
                                    labelField={['employeeCode', 'loginUserName']}
                                    showField={['employeeCode', 'loginUserName']}
                                    label='Passport Submitted By'
                                    required
                                />
                            </Grid>
                            <Grid item md={6}>
                                <Controller
                                    name="signatureImage"
                                    control={control}
                                    rules={{ required: "Signature is required" }}
                                    render={({ field }) => (
                                        <MuiSignModal
                                            onSignatureUpload={(image) => {
                                                handleSignatureUpload(image);
                                                field.onChange(image);
                                            }}
                                            signatureImage={signatureImage}
                                            sigCanvas={sigCanvas}
                                            handleSaveSignature={handleSaveSignature}
                                            showModal={showModal}
                                            setShowModal={setShowModal}
                                            handleClearSignature={() => {
                                                setSignatureImage(null);
                                                field.onChange(null);
                                            }}
                                        />
                                    )}
                                />
                                {errors?.signatureImage && (
                                    <p style={{ color: "red" }}>
                                        {errors?.signatureImage?.message}
                                    </p>
                                )}
                            </Grid>
                            <Grid item md={12}>
                                <FmTextField
                                    name='submissionRemarks'
                                    control={control}
                                    label='Submission Remark'
                                    multiline
                                    rows={3}
                                    maxRows={4}
                                />
                            </Grid>
                        </Grid>
                        <ActionButtons
                            onSubmit={handleSubmit(handleSubmitPassportRequestSubmission)}
                            onCancel={() => navigate(-1)}
                            onReset={reset}
                            submitLoading={createLoading}
                            cancelLoading={false}
                        />
                    </form>
                </GlassCard>
            </Box>
        </DashboardLayout>
    )
}

export default SubmissionCreate
