import { Icon } from '@iconify/react/dist/iconify.js';
import { IconButton } from '@mui/material';

const IconButtonDesign = ({
  prev = false,
  handlePrevClick,
  handleNextClick,
  ...props
}) => {
  const handleClick = () => {
    if (prev && handlePrevClick) {
      handlePrevClick();
    } else if (!prev && handleNextClick) {
      handleNextClick();
    }
  };

  return (
    <IconButton
      sx={{
        color: 'text.primary',
        position: 'absolute',
        right: !prev && '10px',
        left: prev && '10px',
        top: '50%',
        padding: '12px',
        transform: 'translateY(-50%)',
        bgcolor: 'background.white',
        boxShadow: 3,
        '&:disabled': {
          opacity: 0,
        },
        '&:hover': {
          bgcolor: 'background.white',
          color: 'primary.main',
        },
      }}
      onClick={handleClick}
      {...props}
    >
      {prev ? (
        <Icon
          icon='ooui:previous-ltr'
          width='22'
          height='22'
          onClick={handlePrevClick}
        />
      ) : (
        <Icon icon='ooui:next-ltr' width='22' height='22' />
      )}
    </IconButton>
  );
};

export default IconButtonDesign;
