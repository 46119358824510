import { Box, InputAdornment } from '@mui/material';
import TextField from '@mui/material/TextField';
import React, { memo } from 'react';
import {
  Control,
  Controller,
  FieldValues,
  RegisterOptions,
} from 'react-hook-form';

interface FmTextFieldProps<T extends FieldValues> {
  name: string;
  control?: Control<T>;
  label?: string;
  defaultValue?: string | number;
  rules?: RegisterOptions;
  type?: string;
  multiline?: any;
  rows?: number;
  maxRows?: number;
  readOnly?: boolean;
  value?: number;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  inputProps?: any;
  required?: boolean;
  pattern?: string;
  variant?: string;
  minLength?: number;
  maxLength?: number;
  prefix?: string;
  suffix?: string;
  sx?: any;
  onBlur?: () => void;
  inputRef?: any;
}

const handleInputChange = (
  e: React.ChangeEvent<HTMLInputElement>,
  pattern?: string,
  prefix?: string,
  suffix?: string
) => {
  let inputValue = e.target.value;
  if (prefix && inputValue.startsWith(prefix)) {
    inputValue = inputValue.substring(prefix.length);
  }
  if (suffix && inputValue.endsWith(suffix)) {
    inputValue = inputValue.slice(0, -suffix.length);
  }

  let filteredValue = inputValue;

  switch (pattern) {
    case 'Percentage': {
      const percentagePattern = /^(100|(\d{1,3})(\.\d{1,3})?)$/;
      filteredValue = inputValue.replace(/[^0-9.]/g, '');

      if (!percentagePattern.test(filteredValue)) {
        const parts = filteredValue.split('.');

        if (parts.length > 2) {
          const integerPart = parts.shift();
          const decimalPart = parts.join('');
          filteredValue = integerPart + '.' + decimalPart;
        } else {
          const integerPart = Math.min(parseInt(parts[0] || '0', 10), 100);

          if (integerPart === 100) {
            filteredValue = '100';
          } else {
            parts[0] = integerPart.toString();
            if (parts[1]) {
              parts[1] = parts[1].slice(0, 2);
            }
            filteredValue = parts.join('.');
          }
        }
      }

      break;
    }
    case 'PercentageWithoutDecimal': {
      const percentagePattern = /^(100|[1-9]?[0-9])$/;

      filteredValue = inputValue.replace(/[^0-9]/g, '');

      if (!percentagePattern.test(filteredValue)) {
        const integerValue = Math.min(parseInt(filteredValue || '0', 10), 100);
        filteredValue = integerValue.toString();
      }

      break;
    }
    case 'NumberWithMonthRange':
      filteredValue = inputValue.replace(/[^0-9]/g, '');

      if (filteredValue !== '') {
        const numericValue = Math.min(
          Math.max(parseInt(filteredValue, 10), 0),
          12
        );
        filteredValue = numericValue.toString();
      }
      break;
    case 'NumberWithDaysRange':
      filteredValue = inputValue.replace(/[^0-9]/g, '');

      if (filteredValue !== '') {
        const numericValue = Math.min(
          Math.max(parseInt(filteredValue, 10), 0),
          366
        );
        filteredValue = numericValue.toString();
      }
      break;
    case 'Text':
      filteredValue = inputValue.replace(/[^A-Za-z\s-]+/g, '');
      break;
    case 'Email':
      filteredValue = inputValue.replace(/[^A-Za-z0-9@._-]/g, '').toLowerCase();
      break;
    case 'Number':
      filteredValue = inputValue.replace(/[^\d]/g, '');
      break;
    case 'GST':
      filteredValue = inputValue.replace(/[^A-Za-z0-9]/g, '').toUpperCase();
      break;
    case 'URL':
      filteredValue = inputValue.replace(
        /[^A-Za-z0-9!@#$%^&*_+\-=;:'",./?]/g,
        ''
      );
      break;
    case 'Decimal': {
      filteredValue = inputValue.replace(/[^\d.]/g, '');

      const parts = filteredValue.split('.');

      if (parts.length > 2) {
        const integerPart = parts.shift();
        const decimalPart = parts.join('');
        filteredValue = integerPart + '.' + decimalPart;
      } else {
        parts[0] = parts[0].slice(0, 18);

        if (parts[1]) {
          parts[1] = parts[1].slice(0, 5);
        }

        filteredValue = parts.join('.');
      }

      break;
    }
    case 'Text With Space':
      filteredValue = inputValue.replace(/^\s+|[^A-Za-z\s]+/g, '');
      break;
    case 'Text Number':
      filteredValue = inputValue.replace(/[^A-Za-z0-9]/g, '');
      break;
    case 'Upper Case':
      filteredValue = inputValue
        .replace(/^\s+|[^A-Za-z\s]+/g, '')
        .toUpperCase();
      break;
    case 'Lower Case':
      filteredValue = inputValue
        .replace(/^\s+|[^A-Za-z\s]+/g, '')
        .toLowerCase();
      break;
    case 'UpperCaseWithNumber':
      filteredValue = inputValue
        .replace(/^\s+|[^A-Za-z0-9\s]+/g, '')
        .toUpperCase();
      break;
    case 'Init Caps':
      filteredValue =
        inputValue
          .replace(/^\s+|[^A-Za-z\s]+/g, '')
          .charAt(0)
          .toUpperCase() + inputValue.slice(1).toLowerCase();
      break;
    default:
      filteredValue = inputValue;
      break;
  }

  return `${filteredValue}${suffix || ''}`;
};

const FmTextField = <T extends FieldValues>({
  name,
  control,
  label,
  defaultValue = '',
  rules = {},
  type = 'text',
  required = false,
  sx,
  multiline,
  rows,
  variant = 'filled',
  maxRows,
  readOnly = false,
  onChange,
  disabled = false,
  inputProps = {},
  pattern,
  minLength,
  maxLength,
  prefix,
  suffix,
  onBlur,
  onKeyDown,
  inputRef,
}: FmTextFieldProps<T>) => (
  <Controller
    name={name}
    control={control}
    defaultValue={defaultValue}
    rules={rules}
    render={({ field, fieldState: { error } }) => (
      <Box>
        <TextField
          {...field}
          label={label}
          type={type}
          variant={variant}
          error={!!error}
          fullWidth
          required={required}
          sx={{
            ...sx,
            '& .MuiInputLabel-root': {
              '& .MuiInputLabel-asterisk': {
                color: 'red',
              },
            },
          }}
          multiline={multiline}
          rows={rows}
          inputRef={inputRef}
          maxRows={maxRows}
          inputProps={{
            ...inputProps,
            minLength,
            maxLength,
            ...(readOnly ? { readOnly: true } : { min: 0 }),
          }}
          InputProps={{
            startAdornment: prefix ? (
              <InputAdornment position='start'>{prefix}</InputAdornment>
            ) : null,
            endAdornment: suffix ? (
              <InputAdornment position='end'>{suffix}</InputAdornment>
            ) : null,
          }}
          onChange={(e) => {
            const fullValue = handleInputChange(e, pattern, prefix, suffix);

            field.onChange(fullValue);
            onChange?.(e);
          }}
          onBlur={() => {
            field.onBlur();
            onBlur?.();
          }}
          onKeyDown={(e) => {
            if (onKeyDown) {
              onKeyDown(e);
            }
          }}
          disabled={disabled ? true : false}
        />
        {error ? (
          <Box sx={{ marginTop: 0.2, color: 'error.main', fontSize: '8px' }}>
            {error?.message}
          </Box>
        ) : null}
      </Box>
    )}
  />
);

export default memo(FmTextField);
