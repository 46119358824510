// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rename-file {
  position: relative;
  min-width: 69%;
  max-width: -webkit-fill-available;
  text-align: center;
  border: 4px solid #067E89;
  border-radius: 10px;
  font-size: 15px;
  font-family: "Nunito Sans", sans-serif;
  padding: 1px 7px;
  resize: none;
  field-sizing: content;
  overflow-y: hidden;
}
.rename-file:focus {
  outline: none;
}`, "",{"version":3,"sources":["webpack://./src/lib/FileManager/components/NameInput/NameInput.scss"],"names":[],"mappings":"AAEI;EACI,kBAAA;EACA,cAAA;EACA,iCAAA;EACA,kBAAA;EACA,yBAAA;EACA,mBAAA;EACA,eAAA;EACA,sCAAA;EACA,gBAAA;EACA,YAAA;EACA,qBAAA;EACA,kBAAA;AADR;AAGQ;EACI,aAAA;AADZ","sourcesContent":["    @import \"../../styles/variables\";\n\n    .rename-file {\n        position: relative;\n        min-width: 69%;\n        max-width: -webkit-fill-available;\n        text-align: center;\n        border: 4px solid $primary-color;\n        border-radius: 10px;\n        font-size: 15px;\n        font-family: \"Nunito Sans\", sans-serif;\n        padding: 1px 7px;\n        resize: none;\n        field-sizing: content;\n        overflow-y: hidden;\n\n        &:focus {\n            outline: none;\n        }\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
