import React, { useState } from 'react';
import { Box, Button } from '@mui/material';
import { Icon } from '@iconify/react';
import { useForm } from 'react-hook-form';
import useCommonFetchApi from '../../../../components/helpers/useCommonFetchApi.tsx';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import Crud_Service from '../../../../apis/CrudService.jsx';
import { toast } from 'react-toastify';
import FmMRTDataTable from '../../../../components/FmMRTDataTable.tsx';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import TableActionButtons from '../../../../components/_form/TableActionButtons.jsx';
import FmDatePicker from '../../../../components/_mui/FmDatePicker.tsx';
import MuiDialogOne from '../../../../components/_mui/MuiDialogOne.jsx';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import CommonView from '../../../../components/helpers/CommonView.jsx';
import DynamicViewFields from '../../../../components/helpers/DynamicViewFields.jsx';
import FmViewDateField from '../../../../components/_mui/FmViewDateField.tsx';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import { navigate } from 'react-big-calendar/lib/utils/constants.js';
import { useNavigate } from 'react-router-dom';

const AdvertisementList = (props) => {
  const crud = new Crud_Service();
  const {
    rows,
    tableRecordCounts,
    pagination,
    sorting,
    setPagination,
    setSorting,
    columnFilters,
    globalFilter,
    setColumnFilters,
    setGlobalFilter,
    isLoading,
    setSearchKeyword,
    searchKeyword,
    fetchData,
  } = props;
  const { control, setValue, handleSubmit } = useForm();
  const [editingRowId, setEditingRowId] = useState(null);
  const [editingRowData, setEditingRowData] = useState(null);
  const { startEditLoading, stopEditLoading } = useLoader();
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [openView, setOpenView] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [viewDetails, setViewdetails] = useState(false);
  const { data: vehicles } = useCommonFetchApi('vehicles') || {
    data: [],
  };
  const excludeKeys = [
    'vehicleAdvertisementId',
    'vechicleId',
    'vehicleAdvertisementCode',
    'status',
  ];
  const handleViewClick = async (props) => {
    navigate(`view/${props?.vehicleAdvertisementId}`);
  };

  const handleEdit = (row) => {
    navigate(`edit/${row?.vehicleAdvertisementId}`);
  };

  const handleCancel = () => {
    setEditingRowId(null);
    setEditingRowData(null);
  };

  const handleViewClose = () => {
    setOpenView(false);
  };

  const handleDelete = async (props) => {
    await crud.remove(
      'vehicleadvertisements',
      props?.vehicleAdvertisementId,
      (__err, res) => {
        if (res?.status === 204) {
          toast.success('Advertisement Deleted Successfully');
          fetchData();
        } else {
        }
      }
    );
  };

  const handleUpdateEvent = async (values) => {
    try {
      startEditLoading();
      const combinedData = {
        ...values,
        expiryDate: dayjs(values?.expiryDate).format('YYYY-MM-DD'),
        issueDate: dayjs(values?.issueDate).format('YYYY-MM-DD'),
        Status: 2,
      };
      await crud.update(
        'vehicleadvertisements',
        editingRowId,
        combinedData,
        (_err, res) => {
          if (res?.status === 200) {
            stopEditLoading();
            fetchData();
            toast.success('Advertisement Updated Successfully');
            setEditingRowData(null);
            setEditingRowId(null);
          } else {
            stopEditLoading();
            toast.error('Failed to update the vehicle Report');
          }
        }
      );
    } catch (error) {
      stopEditLoading();
      toast.error('An error occurred while updating the vehicle Report');
    }
  };

  const ActionData = [
    {
      name: 'View Details',
      icon: <Icon icon='solar:eye-linear' />,
      onClick: (props) => handleViewClick(props),
    },
    {
      name: 'Edit',
      icon: <Icon icon='solar:pen-2-broken' />,
      onClick: (props) => handleEdit(props),
    },
    {
      name: 'Delete',
      icon: <Icon icon='solar:trash-bin-minimalistic-broken' />,
      onClick: (props) => handleDelete(props),
      danger: true,
    },
  ];

  const columns = [
    {
      header: 'Actions',
      Cell: ({ row }) => (
        <Box className='flex gap-2'>
          {editingRowId === row?.original?.vehicleAdvertisementId ? (
            <>
              <Button onClick={handleSubmit(handleUpdateEvent)}>Save</Button>
              <Button onClick={handleCancel}>Cancel</Button>
            </>
          ) : (
            <TableActionButtons
              Actions={ActionData?.map((action) => ({
                ...action,
                onClick: () => action.onClick(row?.original),
              }))}
            />
          )}
        </Box>
      ),
    },
    {
      accessorKey: 'vehicleId',
      header: 'Vehicle',
      enableEditing: true,
      Cell: ({ cell }) => {
        const vehicle: any = rows?.find(
          (item) =>
            item?.vehicleAdvertisementId ===
            cell?.row?.original?.vehicleAdvertisementId
        );
        return editingRowId === cell?.row?.original?.vehicleAdvertisementId ? (
          <FmSearchableSelect
            name='vehicleId'
            control={control}
            apiUrl='vehicles'
            valueField='vehicleId'
            headerField={['Vehicle Name']}
            labelField={['vehicleName']}
            showField={['vehicleName']}
            label='Vehicle'
            defaultValue={{
              vehicleId: editingRowData?.vehicleId,
              vehicleName: editingRowData?.vehicleName,
            }}
          />
        ) : (
          vehicle?.vehicleName || ''
        );
      },
    },
    {
      accessorKey: 'permitNumber',
      header: 'Permit Number',
      enableEditing: true,
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.vehicleAdvertisementId ? (
          <FmTextField
            name='permitNumber'
            control={control}
            defaultValue={editingRowData?.permitNumber}
          />
        ) : (
          cell?.row?.original?.permitNumber
        );
      },
    },
    {
      accessorKey: 'issueDate',
      header: 'Issue Date',
      enableEditing: true,
      Cell: ({ cell }) => {
        const vehicleReport: any = rows?.find(
          (item) =>
            item?.vehicleAdvertisementId ===
            cell?.row?.original?.vehicleAdvertisementId
        );
        return editingRowId === cell?.row?.original?.vehicleAdvertisementId ? (
          <FmDatePicker
            name='issueDate'
            label='Issue Date'
            control={control}
            defaultValue={editingRowData?.issueDate}
            required
          />
        ) : (
          <FmViewDateField value={vehicleReport?.issueDate} />
        );
      },
    },
    {
      accessorKey: 'expiryDate',
      header: 'Expiry Date',
      enableEditing: true,
      Cell: ({ cell }) => {
        const vehicleReport: any = rows?.find(
          (item) =>
            item?.vehicleAdvertisementId ===
            cell?.row?.original?.vehicleAdvertisementId
        );
        return editingRowId === cell?.row?.original?.vehicleAdvertisementId ? (
          <FmDatePicker
            name='expiryDate'
            label='Expiry Date'
            control={control}
            defaultValue={editingRowData?.expiryDate}
            required
          />
        ) : (
          <FmViewDateField value={vehicleReport?.expiryDate} />
        );
      },
    },
    {
      accessorKey: 'remarks',
      header: 'Remarks',
      enableEditing: true,
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.vehicleAdvertisementId ? (
          <FmTextField
            name='remarks'
            control={control}
            defaultValue={editingRowData?.remarks}
          />
        ) : (
          cell?.row?.original?.remarks
        );
      },
    },

  ];

  return (
    <>
      <FmMRTDataTable
        columns={columns}
        enableRowSelection={false}
        rows={rows}
        rowCount={tableRecordCounts}
        editingRow={editingRowId}
        pagination={pagination}
        sorting={sorting}
        columnFilters={columnFilters}
        globalFilter={globalFilter}
        setPagination={setPagination}
        fetchData={fetchData}
        setSorting={setSorting}
        setColumnFilters={setColumnFilters}
        setGlobalFilter={setGlobalFilter}
        isLoading={isLoading}
        setSearchKeyword={setSearchKeyword}
        searchKeyword={searchKeyword}
        handleRowDoubleClick={(e) => handleEdit(e?.original)}
      />
      {/* view */}
      <MuiDialogOne
        title='View Details'
        open={viewDetails}
        onClose={() => setViewdetails(!viewDetails)}
      >
        {viewDetails && viewDetails?.vehicleAdvertisementId && (
          <CommonView
            url='vehicleadvertisements'
            id={viewDetails?.vehicleAdvertisementId}
            excludeKeys={excludeKeys}
            renderFields={(data, fields) => (
              <DynamicViewFields data={data} fields={fields} />
            )}
          />
        )}
      </MuiDialogOne>
    </>
  );
};

export default AdvertisementList;
