import React, { useEffect, useState } from 'react';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import MRTExpandTableStyle from '../../../../components/table/MRTExpandTableStyle.tsx';
import { Box, Button } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import FmMRTDataTable from '../../../../components/FmMRTDataTable.tsx';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import Crud_Service from '../../../../apis/CrudService.jsx';
import TableActionButtons from '../../../../components/_form/TableActionButtons.jsx';
import { Icon } from '@iconify/react';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import FmDatePicker from '../../../../components/_mui/FmDatePicker.tsx';
import FmViewDateField from '../../../../components/_mui/FmViewDateField.tsx';
import apiInstance from '../../../../apis/ApiService.jsx';
import MRTIconButton from '../../../../components/table/MRTIconButton.tsx';
import DataGridHeaderTooltip from '../../../../components/datagrid/DataGridHeaderTooltip.tsx';
import TextElipseTooltip from '../../../../components/datagrid/TextElipseTooltip.tsx';
import Icons from '../../../../utils/Icon.jsx';
const TextOverflow = ({ name, width = '180px' }) => {
  return (
    <Box sx={{ width: width }}>
      <TextElipseTooltip name={name} />
    </Box>
  );
};

const ListCashBankReceipt = (props) => {
  const {
    rows,
    tableRecordCounts,
    pagination,
    sorting,
    setPagination,
    setSorting,
    columnFilters,
    globalFilter,
    setColumnFilters,
    setGlobalFilter,
    isLoading,
    setSearchKeyword,
    searchKeyword,
    fetchData,
    type
  } = props;
  const [editingRowId, setEditingRowId] = useState(null);
  const [editingRowData, setEditingRowData] = useState(null);
  const { startEditLoading, stopEditLoading } = useLoader();
  const { control, setValue, handleSubmit } = useForm();
  const crud = new Crud_Service();
  const navigate = useNavigate();
  const location = useLocation();

  const handleDelete = async (props) => {
    await crud.remove(
      'financialtransactions',
      props?.original?.financialTransactionId,
      (__err, res) => {
        if (res?.status === 204) {
          toast.success('Deleted Successfully');
          fetchData();
        } else {
        }
      }
    );
  };

  const handleUpdateEvent = async (values) => {
    try {
      // Start loading indicator
      startEditLoading();
      // Prepare the data to be updated
      const combinedData = {
        ...values,
        requestDate: dayjs(values?.requestDate).format('YYYY-MM-DD'), // Format resumption date
        chequeDate: dayjs(values?.chequeDate).format('YYYY-MM-DD'),
        Status: 2,
      };
      await crud.update(
        'financialtransactions',
        editingRowId,
        combinedData,
        (_err, res) => {
          if (res?.status === 200) {
            stopEditLoading();
            fetchData();
            toast.success(' Updated Successfully');
            setEditingRowData(null);
            setEditingRowId(null);
          } else {
            stopEditLoading();
            toast.error('Failed to update');
          }
        }
      );
    } catch (error) {
      stopEditLoading();
      toast.error('An error occurred while updating');
    }
  };

  const handleView = (id) => {
    navigate(`${location.pathname}/view/${id}`);
  };
  const ActionData = [
    {
      name: 'Edit',
      icon: Icons.edit,

      onClick: (props) => {
        handleView(props?.original?.financialTransactionId);
      },
    },

    {
      name: 'Delete',
      icon: Icons.delete,
      onClick: (props) => handleDelete(props),
    },
  ];


  const columns = [
    {
      field: 'action',
      header: 'Actions',
      sortable: false,
      hideable: false,
      disableClickEventBubbling: false,
      size: 100,

      Cell: (params) => {
        return (
          <Box className='flex gap-2'>
            {editingRowId === params?.row?.original?.financialTransactionId ? (
              <>
                <Button onClick={handleSubmit(handleUpdateEvent)}>Save</Button>
                <Button onClick={handleCancel}>Cancel</Button>
              </>
            ) : (
              <TableActionButtons
                Actions={ActionData?.map((action) => ({
                  ...action,
                  onClick: () => action.onClick(params.row),
                }))}
                selectedRow={params.row}
                setSelectedRow={() => { }}
              />
            )}
          </Box>
        );
      },
    },
    {
      accessorKey: 'financialTransactionCode',
      header: (
        <DataGridHeaderTooltip
          name={'FTC'}
          title={'Financial Transaction Code'}
        />
      ),
      enableEditing: true,
      size: 100,
      Cell: ({ cell }) => {
        const salesDetails: any = rows?.find(
          (item) =>
            item?.financialTransactionId ===
            cell?.row?.original?.financialTransactionId
        );
        return editingRowId === cell?.row?.original?.financialTransactionId ? (
          <FmTextField
            name='financialTransactionCode'
            control={control}
            label='Financial Transaction Code'
          />
        ) : (
          salesDetails?.financialTransactionCode || ''
        );
      },
    },
    {
      accessorKey: 'requestDate',
      header: 'Request Date',
      enableEditing: true,
      size: 150,
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.financialTransactionId ? (
          <FmDatePicker
            name='requestDate'
            label='Request Date'
            control={control}
            required
          />
        ) : (
          FmViewDateField({ value: cell?.row?.original?.requestDate })
        );
      },
    },
    {
      accessorKey: 'maturityDate',
      header: 'Maturity Date',
      enableEditing: true,
      size: 150,
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.financialTransactionId ? (
          <FmDatePicker
            name='maturityDate'
            label='Maturity Date'
            control={control}
            required
          />
        ) : (
          FmViewDateField({ value: cell?.row?.original?.maturityDate })
        );
      },
    },
    {
      accessorKey: 'bankName',
      header: 'Bank',
      size: 100,
      enableEditing: true,
      Cell: ({ cell }) => {
        const salesItemDetails: any = rows?.find(
          (item) =>
            item?.financialTransactionId ===
            cell?.row?.original?.financialTransactionId
        );
        return editingRowId === cell?.row?.original?.financialTransactionId ? (
          <FmSearchableSelect
            name='bankId'
            control={control}
            apiUrl='banks'
            valueField='bankId'
            headerField={['Bank Code', 'Bank Name']}
            labelField={['bankCode', 'bankName']}
            showField={['bankName']}
            label='Bank'
            defaultValue={{
              bankId: editingRowData?.bankId,
              bankName: editingRowData?.bankName,
            }}
          />
        ) : (
          <TextOverflow name={salesItemDetails?.bankName || ''} width='100px' />
        );
      },
    },
    {
      accessorKey: 'companyId',
      header: 'Company',
      enableEditing: true,
      size: 100,
      Cell: ({ cell }) => {
        const cashAndBankDetails: any = rows?.find(
          (item) =>
            item?.financialTransactionId ===
            cell?.row?.original?.financialTransactionId
        );
        return editingRowId === cell?.row?.original?.financialTransactionId ? (
          <FmSearchableSelect
            name='companyId'
            control={control}
            apiUrl='companies'
            valueField='companyId'
            headerField={['Company Code', 'Company Name']}
            labelField={['companyCode', 'companyName']}
            showField={['companyName']}
            label='Company'
            defaultValue={{
              companyId: editingRowData?.companyId,
              companyName: editingRowData?.companyName,
            }}
          />
        ) : (
          <TextOverflow name={cashAndBankDetails?.companyName || ''} />
        );
      },
    },
    {
      accessorKey: 'branchId',
      header: 'Branch',
      enableEditing: true,
      Cell: ({ cell }) => {
        const cashAndBankDetails: any = rows?.find(
          (item) =>
            item?.financialTransactionId ===
            cell?.row?.original?.financialTransactionId
        );
        return editingRowId === cell?.row?.original?.financialTransactionId ? (
          <FmSearchableSelect
            name='branchId'
            control={control}
            apiUrl='branches'
            valueField='branchId'
            labelField={['branchName']}
            showField={['branchName']}
            label={'Branch'}
            defaultValue={{
              branchId: editingRowData?.branchId,
              branchName: editingRowData?.branchName,
            }}
          />
        ) : (
          cashAndBankDetails?.branchName || ''
        );
      },
    },
    {
      accessorKey: 'placeOfSupplyId',
      header: 'Placce of Supply',
      enableEditing: true,
      Cell: ({ cell }) => {
        const cashAndBankDetails: any = rows?.find(
          (item) =>
            item?.financialTransactionId ===
            cell?.row?.original?.financialTransactionId
        );
        return editingRowId === cell?.row?.original?.financialTransactionId ? (
          <FmSearchableSelect
            name='placeOfSupplyId'
            control={control}
            apiUrl='placeofsupplies'
            valueField='placeOfSupplyId'
            labelField={['placeOfSupplyName']}
            showField={['placeOfSupplyName']}
            label={'Place Of Supply'}
            defaultValue={{
              placeOfSupplyId: editingRowData?.placeOfSupplyId,
              placeOfSupplyName: editingRowData?.placeOfSupplyName,
            }}
          />
        ) : (
          cashAndBankDetails?.placeOfSupplyName || ''
        );
      },
    },
    {
      accessorKey: 'jurisdictionId',
      header: 'Jurisdiction',
      enableEditing: true,
      Cell: ({ cell }) => {
        const cashAndBankDetails: any = rows?.find(
          (item) =>
            item?.financialTransactionId ===
            cell?.row?.original?.financialTransactionId
        );
        return editingRowId === cell?.row?.original?.financialTransactionId ? (
          <FmSearchableSelect
            name='jurisdictionId'
            control={control}
            apiUrl='jurisdictions'
            valueField='jurisdictionId'
            labelField={['jurisdictionName']}
            showField={['jurisdictionName']}
            label={'Jurisdiction'}
            defaultValue={{
              jurisdictionId: editingRowData?.jurisdictionId,
              jurisdictionName: editingRowData?.jurisdictionName,
            }}
          />
        ) : (
          cashAndBankDetails?.jurisdictionName || ''
        );
      },
    },
    {
      accessorKey: 'salesOrderId',
      header: 'Sales Order',
      enableEditing: true,
      Cell: ({ cell }) => {
        const cashAndBankDetails: any = rows?.find(
          (item) =>
            item?.financialTransactionId ===
            cell?.row?.original?.financialTransactionId
        );
        return editingRowId === cell?.row?.original?.financialTransactionId ? (
          <FmSearchableSelect
            name='salesOrderId'
            control={control}
            apiUrl='salesorders'
            valueField='salesOrderId'
            labelField={['salesOrderName']}
            showField={['salesOrderName']}
            label={'Sales Order'}
            defaultValue={{
              salesOrderId: editingRowData?.salesOrderId,
              salesOrderName: editingRowData?.salesOrderName,
            }}
          />
        ) : (
          cashAndBankDetails?.salesOrderName || ''
        );
      },
    },
    {
      accessorKey: 'salesOrderDistributionId',
      header: 'Sales Order Distribution',
      enableEditing: true,
      Cell: ({ cell }) => {
        const cashAndBankDetails: any = rows?.find(
          (item) =>
            item?.financialTransactionId ===
            cell?.row?.original?.financialTransactionId
        );
        return editingRowId === cell?.row?.original?.financialTransactionId ? (
          <FmSearchableSelect
            name='salesOrderDistributionId'
            control={control}
            apiUrl='salesorderdistributions'
            valueField='salesOrderDistributionId'
            labelField={['salesOrderDistributionCode']}
            showField={['salesOrderDistributionCode']}
            label={'Sales Order Distribution'}
            defaultValue={{
              salesOrderDistributionId: editingRowData?.salesOrderDistributionId,
              salesOrderDistributionCode: editingRowData?.salesOrderDistributionCode,
            }}
          />
        ) : (
          cashAndBankDetails?.salesOrderDistributionCode || ''
        );
      },
    },

    {
      accessorKey: 'salesInvoiceId',
      header: 'Sales Invoice',
      enableEditing: true,
      Cell: ({ cell }) => {
        const cashAndBankDetails: any = rows?.find(
          (item) =>
            item?.financialTransactionId ===
            cell?.row?.original?.financialTransactionId
        );
        return editingRowId === cell?.row?.original?.financialTransactionId ? (
          <FmSearchableSelect
            name='salesInvoiceId'
            control={control}
            apiUrl='salesinvoices'
            valueField='salesInvoiceId'
            labelField={['salesInvoiceCode']}
            showField={['salesInvoiceCode']}
            label={'Sales Invoice'}
            defaultValue={{
              salesInvoiceId: editingRowData?.salesInvoiceId,
              salesInvoiceCode: editingRowData?.salesInvoiceCode,
            }}
          />
        ) : (
          cashAndBankDetails?.salesInvoiceCode || ''
        );
      },
    },
    {
      accessorKey: 'payeeUserId',
      header: 'Payee User',
      enableEditing: true,
      Cell: ({ cell }) => {
        const cashAndBankDetails: any = rows?.find(
          (item) =>
            item?.financialTransactionId ===
            cell?.row?.original?.financialTransactionId
        );
        return editingRowId === cell?.row?.original?.financialTransactionId ? (
          <FmSearchableSelect
            name='payeeUserId'
            control={control}
            apiUrl='loginusers'
            valueField='loginUserId'
            labelField={['employeeCode', 'loginUserName']}
            showField={['employeeCode', 'loginUserName']}
            pageSize={50}
            label='Payee User'
            defaultValue={{
              loginUserId: editingRowData?.payeeUserId,
              loginUserName: editingRowData?.payeeusername,
            }}
          />
        ) : (
          cashAndBankDetails?.payeeusername || ''
        );
      },
    },
    {
      accessorKey: 'currencyId',
      header: 'Currency',
      enableEditing: true,
      size: 100,
      Cell: ({ cell }) => {
        const cashAndBankDetails: any = rows?.find(
          (item) =>
            item?.financialTransactionId ===
            cell?.row?.original?.financialTransactionId
        );
        return editingRowId === cell?.row?.original?.financialTransactionId ? (
          <FmSearchableSelect
            name='currencyId'
            control={control}
            apiUrl='currencies'
            valueField='currencyId'
            headerField={['Currency Code', 'Currency Name']}
            labelField={['currencyCode', 'currencyName']}
            showField={['currencyName']}
            label='Currency'
            defaultValue={{
              currencyId: editingRowData?.currencyId,
              currencyName: editingRowData?.currencyName,
            }}
          />
        ) : (
          cashAndBankDetails?.currencyName || ''
        );
      },
    },
    {
      accessorKey: 'chequeNo',
      header: 'Cheque No',
      enableEditing: true,
      size: 100,
      Cell: ({ cell }) => {
        const salesDetails: any = rows?.find(
          (item) =>
            item?.financialTransactionId ===
            cell?.row?.original?.financialTransactionId
        );
        return editingRowId === cell?.row?.original?.financialTransactionId ? (
          <FmTextField
            name='chequeNo'
            control={control}
            label='Cheque No'
          />
        ) : (
          salesDetails?.chequeNo || ''
        );
      },
    },
    {
      accessorKey: 'chequeDate',
      header: 'Cheque Date',
      enableEditing: true,
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.financialTransactionId ? (
          <FmDatePicker
            name='chequeDate'
            label='Cheque Date'
            control={control}
            required
          />
        ) : (
          FmViewDateField({ value: cell?.row?.original?.chequeDate })
        );
      },
    },
    {
      accessorKey: 'pdcNo',
      header: 'PDCNo',
      enableEditing: true,
      Cell: ({ cell }) => {
        const cashAndBankDetails: any = rows?.find(
          (item) =>
            item?.financialTransactionId ===
            cell?.row?.original?.financialTransactionId
        );
        return editingRowId === cell?.row?.original?.financialTransactionId ? (
          <FmTextField name='pdcNo' control={control} label='PDCNo' />
        ) : (
          cashAndBankDetails?.pdcNo || ''
        );
      },
    },

    {
      accessorKey: 'narration',
      header: 'Narration',
      enableEditing: true,
      Cell: ({ cell }) => {
        const cashAndBankDetails: any = rows?.find(
          (item) =>
            item?.financialTransactionId ===
            cell?.row?.original?.financialTransactionId
        );
        return editingRowId === cell?.row?.original?.financialTransactionId ? (
          <FmTextField name='narration' control={control} label='Narration' />
        ) : (
          cashAndBankDetails?.narration || ''
        );
      },
    },
    {
      accessorKey: 'existLock',
      header: 'Exist Lock',
      enableEditing: true,
      Cell: ({ cell }) => {
        const cashAndBankDetails: any = rows?.find(
          (item) =>
            item?.financialTransactionId ===
            cell?.row?.original?.financialTransactionId
        );
        return editingRowId === cell?.row?.original?.financialTransactionId ? (
          <FmTextField name='existLock' control={control} label='Exist Lock' />
        ) : (
          cashAndBankDetails?.existLock || ''
        );
      },
    },
    {
      accessorKey: 'fileId',
      header: 'Attached Document',
      enableEditing: true,
      Cell: ({ cell }) => {
        const cashAndBankDetails: any = rows?.find(
          (item) =>
            item?.financialTransactionId ===
            cell?.row?.original?.financialTransactionId
        );
        return (
          <MRTIconButton
            icon={<Icon icon='akar-icons:download' />}
            tooltip={cashAndBankDetails?.fileName}
            onClick={async () => {
              if (!cashAndBankDetails?.fileId) {
                toast.error('No file available for download');
                return;
              }
              try {
                const imageUrl = await apiInstance.getFiles(
                  `files/download/${cashAndBankDetails?.fileId}`
                );
                const link = document.createElement('a');
                link.href = imageUrl;
                link.setAttribute('download', `${cashAndBankDetails?.fileName}`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              } catch (error) {
                toast.error('File not found');
              }
            }}
          />
        );
      },
    },
    {
      accessorKey: 'jurisdictionId',
      header: 'Jurisdiction',
      enableEditing: true,
      Cell: ({ cell }) => {
        const cashAndBankDetails: any = rows?.find(
          (item) =>
            item?.financialTransactionId ===
            cell?.row?.original?.financialTransactionId
        );
        return editingRowId === cell?.row?.original?.financialTransactionId ? (
          <FmSearchableSelect
            name='jurisdictionId'
            control={control}
            apiUrl='emirates'
            valueField='emirateId'
            labelField={['emirateName']}
            showField={['emirateName']}
            label={'Jurisdiction'}
            defaultValue={{
              emirateId: editingRowData?.jurisdictionId,
              emirateName: editingRowData?.jurisdictionName,
            }}
          />
        ) : (
          cashAndBankDetails?.jurisdictionName || ''
        );
      },
    },

    // {
    //   accessorKey: 'currencyId',
    //   header: 'Currency',
    //   enableEditing: true,
    //   Cell: ({ cell }) => {
    //     const cashAndBankDetails: any = rows?.find(
    //       (item) =>
    //         item?.financialTransactionId ===
    //         cell?.row?.original?.financialTransactionId
    //     );
    //     return editingRowId === cell?.row?.original?.financialTransactionId ? (
    //       <FmSearchableSelect
    //         name='currencyId'
    //         control={control}
    //         apiUrl='currencies'
    //         valueField='currencyId'
    //         headerField={['Currency Code', 'Currency Name']}
    //         labelField={['currencyCode', 'currencyName']}
    //         showField={['currencyName']}
    //         label='Currency'
    //         defaultValue={{
    //           currencyId: editingRowData?.currencyId,
    //           currencyName: editingRowData?.currencyName,
    //         }}
    //       />
    //     ) : (
    //       cashAndBankDetails?.currencyName || ''
    //     );
    //   },
    // },


  ];
  const displayedColumns =
    type === 2 || type === 3
      ? columns
      : columns.filter(
        (column) =>
          ![
            'placeOfSupplyId',
            'jurisdictionId',
            'salesOrderId',
            'salesOrderDistributionId',
          ].includes(column.accessorKey)
      );
  useEffect(() => {
    if (editingRowData) {
      Object.entries(editingRowData).forEach(([key, value]) => {
        setValue(key, value);
      });
    }
  }, [editingRowData, setValue]);
  return (
    <>
      <GlassCard className='h-full'>
        <MRTExpandTableStyle enableFullHeight>
          <FmMRTDataTable
            columns={displayedColumns}
            enableRowSelection={false}
            rows={rows}
            rowCount={tableRecordCounts}
            editingRow={editingRowId}
            pagination={pagination}
            sorting={sorting}
            columnFilters={columnFilters}
            globalFilter={globalFilter}
            setPagination={setPagination}
            setSorting={setSorting}
            setColumnFilters={setColumnFilters}
            setGlobalFilter={setGlobalFilter}
            isLoading={isLoading}
            setSearchKeyword={setSearchKeyword}
            searchKeyword={searchKeyword}
            fetchData={fetchData}
            handleRowDoubleClick={(e) => handleView(e?.original?.financialTransactionId)}
            muiTableContainerProps={{
              sx: {
                maxHeight: '100%',
                flex: 1,
              },
            }}
          />
        </MRTExpandTableStyle>
      </GlassCard>
    </>
  );
};

export default ListCashBankReceipt;
