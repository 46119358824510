import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import useTableLogic from '../../../../components/helpers/MRTUseTableLogic.tsx';
import { CreateButton } from '../../../../components/small/Buttons.jsx';
import { findNavByName } from '../../../../utils/navUtils.ts';
import ListSalesRetention from './ListSalesRetention.tsx';

const SalesRetention = () => {
  const {
    rows,
    rowCount,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic('salesretentions');
  const location = useLocation();
  const menu = useSelector((state) => state?.menu?.items);

  const SubMenu = useMemo(() => {
    return menu?.map((item) => item?.menus?.flat()).flat() || [];
  }, [menu]);

  const result = useMemo(
    () => findNavByName(SubMenu, 'Financial', 'Accounts Receivable'),
    [SubMenu]
  );

  const navigate = useNavigate();

  useEffect(() => {
    document.title = `Sales Retention`;
  }, []);

  return (
    <>
      <DashboardLayout
        title={'Sales Retention'}
        hasSubmenu
        menu={result}
        actionButtons={
          <>
            <CreateButton name='Create' onClick={() => navigate('create')} />
          </>
        }
      >
        <ListSalesRetention
          rows={rows}
          tableRecordCounts={tableRecordCounts}
          pagination={pagination}
          sorting={sorting}
          fetchData={fetchData}
          setPagination={setPagination}
          setSorting={setSorting}
          columnFilters={columnFilters}
          globalFilter={globalFilter}
          setColumnFilters={setColumnFilters}
          setGlobalFilter={setGlobalFilter}
          isLoading={isLoading}
          setSearchKeyword={setSearchKeyword}
          searchKeyword={searchKeyword}
        />
      </DashboardLayout>
    </>
  );
};

export default SalesRetention;
