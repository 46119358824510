import { yupResolver } from '@hookform/resolvers/yup';
import { Icon } from '@iconify/react';
import { Box, Button, Grid, MenuItem, TextField } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Crud_Service from '../../../apis/CrudService';
import { ActionButtons } from '../../../components/_form';
import UploadSquareBoxes from '../../../components/_form/UploadSquareBoxes.tsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import CommonLoader from '../../../components/page/CommonLoader';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { TenderInspectionvalidationSchema } from '../../../utils/CommonvalidationSchemas.tsx';
import FmSearchableSelect from '../../../components/_mui/FmSearchableSelect.tsx';
import FmDatePicker from '../../../components/_mui/FmDatePicker.tsx';
import UploadModalBox from '../../../components/upload/UploadModalBox.tsx';
import apiInstance from '../../../apis/ApiService.jsx';
import dayjs from 'dayjs';

const GridValue = 3;

const EditTenderInspectionChecklist = () => {
  const navigate = useNavigate();
  const crud = new Crud_Service();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(TenderInspectionvalidationSchema),
    mode: 'onChange',
  });
  const [counter, setCounter] = useState(1);
  const [editData, setEditData] = useState(null);
  const { startEditLoading, stopEditLoading, editLoading } = useLoader();
  const [components, setComponents] = useState([]);
  const [imageUrl, setImageUrl] = useState([]);
  const [previewdData, setPreviewData] = useState([]);
  const [uploadedFileIds, setUploadedFileIds] = useState([]);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    getTenderChecklist();
  }, []);

  const getTenderChecklist = async () => {
    setLoading(true);
    await crud.getSingle('tenderinspectionchecklists', id, (err, res) => {
      if (res?.status === 200) {
        Object.entries({
          ...res?.data,
        }).forEach(([key, value]) => {
          setValue(key, value);
        });
        setEditData(res?.data);
        setLoading(false);
      } else {
      }
    });
  };

  useEffect(() => {
    if (editData && editData?.tenderInspectionCheckListAttachments) {
      editData?.tenderInspectionCheckListAttachments?.forEach((image) => {
        handleDownloadFile(image?.fileId);
      });
    }
  }, [editData]);

  const handleDownloadFile = async (fileId) => {
    try {
      // Check if fileId is null, undefined, or an empty string
      if (!fileId) {
        return; // Exit early without calling the API
      }

      const imageUrl = await apiInstance.getFiles(`files/download/${fileId}`);

      setPreviewData((prevData) => [...prevData, imageUrl, fileId]);
    } catch (err) {
      toast.error(`Error fetching image: ${err.message}`);
    }
  };

  //Update tender Inspection Checklists
  const handleEditTenderChecklist = (values) => {
    startEditLoading();

    const combinedData = {
      ...values,
      noOfFloor: Number(values?.noOfFloor),
      flatPerFloor: Number(values?.flatPerFloor),
      siteVisitDate: dayjs(values?.siteVisitDate).format('YYYY-MM-DD'),
      updateAttachments: uploadedFileIds?.map((fileId, index) => ({
        tenderInspectionCheckListAttachmentId:
          values?.tenderInspectionCheckListAttachments?.[index]
            ?.tenderInspectionCheckListAttachmentId || 0,
        fileId:
          values?.tenderInspectionCheckListAttachments?.[index]?.fileId ||
          fileId,
        status: 2,
      })),
    };

    crud.update(`tenderinspectionchecklists`, id, combinedData, (err, res) => {
      if (err) {
        toast.error(err.message || 'Something went wrong');
      } else if (res?.status === 200) {
        toast.success('Tender Checklist Updated Successfully');
        navigate(-1);
      } else {
      }
      stopEditLoading();
    });
  };

  const handleAddComponent = () => {
    const currentFileIds = getValues('fileUploadIds') || [];
    if (currentFileIds.length >= 3) {
      // Set an error if the file count is already at the limit
      setError('fileUploadIds', {
        type: 'manual',
        message: 'You can upload a maximum of 3 files.',
      });
      return; // Prevent adding a new component if validation fails
    } else {
      // Clear any existing errors if the file count is within the limit
      clearErrors('fileUploadIds');
    }

    // Add the new component and increment the counter
    setComponents([...components, { id: counter }]);
    setCounter(counter + 1);
  };

  const handleRemoveComponent = (id) => {
    setComponents(components.filter((component) => component.id !== id));
    setImageUrl((prev) => {
      const updatedUrls = { ...prev };
      delete updatedUrls[id];
      return updatedUrls;
    });
    clearErrors('fileUploadIds');
  };
  const handleFileChange = (id, fileId) => {
    if (typeof fileId === 'number') {
      const currentFileIds = getValues('fileUploadIds') || [];
      const updatedFileIds = [...currentFileIds, fileId];
      setValue('fileUploadIds', updatedFileIds);
    }
  };

  const handleTenderChange = (value) => {
    if (value) {
      setValue('clientId', value?.clientId);
      setValue('clientName', value?.clientName);
      setValue('ClientContactPerson ', value?.ClientContactPerson);
      setValue('ClientMail', value?.ClientMail);
      setValue('ClientContactNo', value?.ClientContactNo);
      clearErrors('clientId');
    }
  };

  const handleTenderCancel = () => {
    setValue('clientId', '');
    setValue('clientName', '');
    setValue('ClientContactPerson ', '');
    setValue('ClientMail', '');
    setValue('ClientContactNo', '');
  };

  const handleFileIds = (fileIds) => {
    setUploadedFileIds(fileIds);
  };

  return (
    <DashboardLayout title='Edit Tender Inspection Checklist'>
      <GlassCard className='p-3'>
        {loading ? (
          <CommonLoader />
        ) : (
          <form onSubmit={handleSubmit(handleEditTenderChecklist)}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid container spacing={2}>
                <Grid item md={GridValue}>
                  <FmSearchableSelect
                    name='tenderId'
                    control={control}
                    apiUrl='tenders'
                    valueField='tenderId'
                    labelField={['tenderCode', 'tenderName']}
                    showField={['tenderCode', 'tenderName']}
                    onChangeProp={handleTenderChange}
                    clearSelection={handleTenderCancel}
                    label={'Tenders'}
                    defaultValue={{
                      tenderId: editData?.tenderId,
                      tenderName: editData?.tenderName,
                    }}
                    required
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <FmSearchableSelect
                    name='tenderScopeId'
                    control={control}
                    apiUrl='tenderscopes'
                    valueField='tenderScopeId'
                    labelField={['subject']}
                    showField={['subject']}
                    label={'Tender Scope'}
                    defaultValue={{
                      tenderScopeId: editData?.tenderScopeId,
                      subject: editData?.tenderScopeSubject,
                    }}
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <FmTextField
                    name='tenderRegNo'
                    label='Tender Reg No'
                    control={control}
                    disabled
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <FmTextField
                    name='clientName'
                    label='Client Name'
                    control={control}
                    disabled
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <FmTextField
                    name='ClientContactPerson'
                    label='Client Contact Person Name'
                    control={control}
                    disabled
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <FmTextField
                    name='ClientMail'
                    label='Client Contact Person Email'
                    control={control}
                    disabled
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <FmTextField
                    name='ClientContactNo'
                    label='Client Contact Person Number'
                    control={control}
                    disabled
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <FmDatePicker
                    name='siteVisitDate'
                    label='Site Visit Date'
                    control={control}
                    disabled
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <FmSearchableSelect
                    name='siteVisitDoneBy'
                    control={control}
                    apiUrl='loginusers'
                    valueField='loginUserId'
                    labelField={['employeeCode', 'loginUserName']}
                    showField={['employeeCode', 'loginUserName']}
                    label={'Site Visited Done By'}
                    defaultValue={{
                      siteVisitDoneBy: editData?.siteVisitDoneBy,
                      loginUserName: editData?.siteVisitorName,
                    }}
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <FmTextField
                    name='locationName'
                    label='Location Name'
                    control={control}
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <FmTextField
                    name='buildingName'
                    label='Building Name'
                    control={control}
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <UploadModalBox
                    onSubmitFiles={handleFileIds}
                    files={files}
                    setFiles={setFiles}
                    previewdData={previewdData}
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <FmTextField
                    name='existingManpowerDetail'
                    label='Existing Manpower Detail'
                    control={control}
                    rows={3}
                    maxRows={3}
                    multiline={true}
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <FmTextField
                    name='existServiceProvider'
                    label='Exist Service Provider'
                    control={control}
                    rows={3}
                    maxRows={3}
                    multiline={true}
                  />
                </Grid>

                <Grid item md={GridValue * 2}>
                  <FmTextField
                    name='remarks'
                    label='Remarks'
                    control={control}
                    multiline={true}
                    rows={3}
                    maxRows={3}
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <Controller
                    name='status'
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label='Status'
                        fullWidth
                        select
                        variant='filled'
                      >
                        <MenuItem value={2}>Active</MenuItem>
                        <MenuItem value={1}>Inactive</MenuItem>
                      </TextField>
                    )}
                  />
                </Grid>

              </Grid>
            </LocalizationProvider>
            <ActionButtons
              onSubmit={handleSubmit(handleEditTenderChecklist)}
              onCancel={() => navigate(-1)}
              onReset={reset}
              submitLoading={editLoading}
              cancelLoading={false}
              submitText='Update'
              cancelText='Go Back'
            />
          </form>
        )}
      </GlassCard>
    </DashboardLayout>
  );
};

export default EditTenderInspectionChecklist;
