import React, { useEffect, useMemo } from 'react';
import DashboardLayout from '../../../../../components/DashboardLayout.tsx';
import Nav from '../../../../../utils/Nav.tsx';
import AddPendingBillsPayments from './AddPendingBillsPayments.tsx';
import ListPendingBillsPayments from './ListPendingBillsPayments.tsx';
import { useSelector } from 'react-redux';
import { findNavByName } from '../../../../../utils/navUtils.ts';

const PaymentsPendingBills = () => {
  const menu = useSelector((state) => state?.menu?.items);
  
  // Flatten menu only once and store it for reuse
  const SubMenu = useMemo(() => {
    return menu?.map(item => item?.menus?.flat()).flat() || [];
  }, [menu]);

  // Use findNavByName to get the "Accounts Receivable" menu or its child menus
  const result = useMemo(() => findNavByName(SubMenu,"Financial", "Cash and Bank"), [SubMenu]);

  useEffect(() => {
    document.title = 'Pending Bills - Payments | Cash and Bank | Financial';
  }, []);

  return (
    <DashboardLayout
      title='Pending Bills - Payments'
      hasSubmenu
      menu={result}
    >
      <AddPendingBillsPayments />
      <ListPendingBillsPayments />
    </DashboardLayout>
  );
};

export default PaymentsPendingBills;
