import { Icon } from '@iconify/react/dist/iconify.js';
import { useDetectOutsideClick } from '../hooks/useDetectOutsideClick';
import { useLayout } from '../contexts/LayoutContext';
import FileManagerIcons from '../FileManagerIcons';

const LayoutToggler = ({ setShowToggleViewMenu, onLayoutChange }) => {
  const toggleViewRef = useDetectOutsideClick(() => {
    setShowToggleViewMenu(false);
  });
  const { activeLayout, setActiveLayout } = useLayout();

  const layoutOptions = [
    {
      key: 'grid',
      name: 'Grid',
      icon: FileManagerIcons.grid,
    },
    {
      key: 'list',
      name: 'List',
      icon: FileManagerIcons.list,
    },
  ];

  const handleSelection = (key) => {
    setActiveLayout(key);
    onLayoutChange(key);
    setShowToggleViewMenu(false);
  };

  return (
    <div ref={toggleViewRef.ref} className='toggle-view' role='dropdown'>
      <ul role='menu' aria-orientation='vertical'>
        {layoutOptions.map((option) => (
          <li
            role='menuitem'
            key={option.key}
            onClick={() => handleSelection(option.key)}
            onKeyDown={() => handleSelection(option.key)}
          >
            <div className='flex items-center gap-3'>
              <span>{option.icon}</span>
              <span>{option.name}</span>
            </div>
            <span className='text-green-600'>
              {option.key === activeLayout && FileManagerIcons.check}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LayoutToggler;
