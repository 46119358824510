import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import { Box, Grid } from '@mui/material';
import GlassCard from '../../../components/small/GlassCard.tsx';
import ActionButtons from '../../../components/_form/ActionButtons.tsx';
import { useNavigate, useParams } from 'react-router-dom';
import Crud_Service from '../../../apis/CrudService.jsx';
import { useForm } from 'react-hook-form';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import CommonLoader from '../../../components/page/CommonLoader.jsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import { toast } from 'react-toastify';
import FmAutoComplete from '../../../components/_mui/FmAutoComplete.tsx';
import { CreateButton } from '../../../components/small/Buttons.jsx';
import FmSearchableSelect from '../../../components/_mui/FmSearchableSelect.tsx';
import FmDatePicker from '../../../components/_mui/FmDatePicker.tsx';
import { statusOptions } from '../../../utils/CommonVariables.tsx';
import useCommonFetchApi from '../../../components/helpers/useCommonFetchApi.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { uniformsSchama } from '../../../utils/CommonvalidationSchemas.tsx';
import dayjs from 'dayjs';

const EditUniforms = () => {
  const navigate = useNavigate();
  const crud = new Crud_Service();
  const [loading, setLoading] = useState(false);
  const [editData, setEditData] = useState(null);
  const { handleSubmit, control, setValue, reset } = useForm({
    mode: 'onChange',
    resolver: yupResolver(uniformsSchama),
  });
  const [uniformsizes, setuniformsizes] = useState([]);
  const { startEditLoading, stopEditLoading, editLoading } = useLoader();
  const { id } = useParams();
  const GridValue = 2.4;

  useEffect(() => {
    getUniforms();
  }, []);

  useEffect(() => {
    handleChangeuniformType(editData);
  }, [editData]);

  //fetch All Apis
  const { data: uniformcategories } = useCommonFetchApi('uniformcategories');
  const { data: uniformtypes } = useCommonFetchApi('uniformtypes');

  const getUniforms = async () => {
    setLoading(true);
    await crud.getSingle('uniforms', id, (_err, res) => {
      if (res?.status === 200) {
        const data = res?.data;
        Object.entries({
          ...data,
        }).forEach(([key, value]) => {
          setValue(key, value);
        });
        setEditData(res?.data);
        setLoading(false);
      } else {
      }
    });
  };

  const handleUpdateUniforms = async (values) => {
    startEditLoading();

    const combinedData = {
      ...values,
      requestDate: dayjs(values?.requestDate).format('YYYY-MM-DD'),
    };

    await crud.update('uniforms', id, combinedData, (err, res) => {
      if (res?.status === 200) {
        toast.success('uniforms Updated Successfully');
        navigate(-1);
        stopEditLoading();
      } else {
        stopEditLoading();
      }
    });
  };

  const handleChangeuniformType = async (e) => {
    if (e?.uniformTypeId) {
      await crud.getAll(
        'uniformsizes',
        { uniformTypeId: e?.uniformTypeId },
        (err, res) => {
          if (res?.status === 200) {
            setuniformsizes(res?.data?.data);
          } else {
            setuniformsizes([]);
          }
        }
      );
    } else {
      setuniformsizes([]);
    }
  };

  return (
    <DashboardLayout
      title='Edit Uniforms'
      actionButtons={
        <>
          <Box className='flex flex-row items-center whitespace-nowrap gap-3'>
            <CreateButton
              name='Create'
              onClick={() =>
                navigate(
                  '/admin/uniform-accessories-requistions/uniforms/create'
                )
              }
            />
          </Box>
        </>
      }
    >
      <GlassCard className='p-5'>
        {loading ? (
          <CommonLoader />
        ) : (
          <form onSubmit={handleSubmit(handleUpdateUniforms)}>
            <Grid container spacing={2}>
              <Grid item md={GridValue}>
                <FmSearchableSelect
                  name='projectId'
                  control={control}
                  apiUrl='projects'
                  valueField='projectId'
                  headerField={['Project Code', 'Project Name']}
                  labelField={['projectCode', 'projectName']}
                  showField={['projectName']}
                  label={'Projects'}
                  defaultValue={{
                    projectId: editData?.projectId,
                    projectName: editData?.projectName,
                  }}
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmAutoComplete
                  name='uniformTypeId'
                  control={control}
                  options={uniformtypes}
                  label='Uniform Type'
                  displayField='uniformTypeName'
                  optionFields={['uniformTypeName']}
                  valueKey='uniformTypeId'
                  onChangeValue={true}
                  onChange={handleChangeuniformType}
                  required
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmAutoComplete
                  name='uniformSizeId'
                  control={control}
                  options={uniformsizes}
                  label='Uniform Size'
                  displayField='uniformSizeName'
                  optionFields={['uniformSizeName']}
                  valueKey='uniformSizeId'
                  required
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmAutoComplete
                  name='uniformCategoryId'
                  control={control}
                  options={uniformcategories}
                  label='Uniform Category'
                  displayField='uniformCategoryName'
                  optionFields={['uniformCategoryName']}
                  valueKey='uniformCategoryId'
                />
              </Grid>

              <Grid item md={GridValue}>
                <FmDatePicker
                  name='requestDate'
                  control={control}
                  label='Request Date'
                  required
                />
              </Grid>

              <Grid item md={GridValue}>
                <FmTextField
                  name='quantity'
                  control={control}
                  label='Quantity'
                  required
                />
              </Grid>
            </Grid>
            <ActionButtons
              onSubmit={handleSubmit(handleUpdateUniforms)}
              onReset={reset}
              submitLoading={editLoading}
              submitText='Update'
              cancelText='Cancel'
              onCancel={() => navigate(-1)}
            />
          </form>
        )}
      </GlassCard>
    </DashboardLayout>
  );
};

export default EditUniforms;
