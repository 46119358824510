import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import { Box, Grid } from '@mui/material';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import ActionButtons from '../../../../components/_form/ActionButtons.tsx';
import { useNavigate, useParams } from 'react-router-dom';
import Crud_Service from '../../../../apis/CrudService.jsx';
import { useForm, Controller } from 'react-hook-form';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import CommonLoader from '../../../../components/page/CommonLoader.jsx';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import { toast } from 'react-toastify';
import FmAutoComplete from '../../../../components/_mui/FmAutoComplete.tsx';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import useCommonFetchApi from '../../../../components/helpers/useCommonFetchApi.tsx';
import { CreateButton } from '../../../../components/small/Buttons.jsx';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import FmDatePicker from '../../../../components/_mui/FmDatePicker.tsx';
import { employeeWiseMedicalInsuranceSchema } from '../../../../utils/CommonvalidationSchemas.tsx';
import { yupResolver } from '@hookform/resolvers/yup';

const EditEmployeeWiseMIPCalculator = () => {
  const navigate = useNavigate();
  const crud = new Crud_Service();
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const GridItem = 2.4;
  const [editData, setEditData] = useState(null);
  const { handleSubmit, control, setValue, reset, getValues, watch } = useForm({
    mode: 'onChange',
    resolver: yupResolver(employeeWiseMedicalInsuranceSchema),
  });
  const { editLoading, startEditLoading, stopEditLoading } = useLoader();
  const startDate = watch('startDate');

  //get All Apis
  const { data: ageBands } = useCommonFetchApi(
    'companymedicalinsuranceagebands'
  ) || { data: [] };
  const { data: regions } = useCommonFetchApi('regions') || { data: [] };

  useEffect(() => {
    getEmployeeWiseMIpCalculator();
  }, []);

  //   Setting the updated client values
  const getEmployeeWiseMIpCalculator = async () => {
    setLoading(true);
    await crud.getSingle(
      'employeemedicalinsurancepolicies',
      id,
      (_err, res) => {
        if (res?.status === 200) {
          const data = res?.data;
          Object.entries({
            ...data,
          }).forEach(([key, value]) => {
            setValue(key, value);
          });
          setEditData(data);
          setLoading(false);
        } else {
        }
      }
    );
  };

  const calculate = () => {
    if (
      getValues('regionId') &&
      getValues('employeeId') &&
      getValues('startDate') &&
      getValues('endDate')
    ) {
      handleCalculation(getValues('employeeId'));
    }
  };

  const handleCalculation = async (employeeId) => {
    await crud.getAll(
      'medicalinsurancepolicies/medicalinsurancepolicycalculation',
      {
        employeeId: getValues('employeeId'),
        regionId: getValues('regionId'),
        companyMedicalInsuranceAgeBandId: getValues(
          'companyMedicalInsuranceAgeBandId'
        ),
        fromDate: dayjs(getValues('startDate')).format('MM-DD-YYYY'),
        toDate: dayjs(getValues('endDate')).format('MM-DD-YYYY'),
      },
      (err, res) => {
        if (err) {
          toast.error(err);
        }
        if (res?.status === 200) {
          setValue('premiumAmount', res?.data?.data[0]?.calculateAmount);
        }
      }
    );
  };

  const handleUpdateEmployeeWiseMIPCalculator = async (values) => {
    startEditLoading();

    // Format startDate and endDate to 'YYYY-MM-DD'
    if (values.startDate) {
      values.startDate = dayjs(values.startDate).format('YYYY-MM-DD');
    }

    if (values.endDate) {
      values.endDate = dayjs(values.endDate).format('YYYY-MM-DD');
    }

    // Prepare the payload to match the API structure
    const payload = {
      employeeId: values.employeeId,
      regionId: values.regionId,
      costCenterId: values.costCenterId,
      companyMedicalInsuranceAgeBandId: values.companyMedicalInsuranceAgeBandId,
      startDate: values.startDate,
      endDate: values.endDate,
      premiumAmount: values.premiumAmount,
      status: 1,
    };

    await crud.update(
      'employeemedicalinsurancepolicies',
      id, // Make sure this ID is correct
      payload,
      (err, res) => {
        if (res?.status === 200) {
          stopEditLoading();
          navigate(-1);
          toast.success('Employee Wise Policy Updated Successfully');
        } else {
          stopEditLoading();
          toast.error('Error updating policy'); // Notify of failure
        }
      }
    );
  };

  return (
    <DashboardLayout
      title='Edit Employee Wise Policy'
      actionButtons={
        <>
          <Box className='flex flex-row items-center whitespace-nowrap gap-3'>
            <CreateButton
              name='Create'
              onClick={() =>
                navigate(
                  '/admin/company-medical-insurance-policy/employee-wise-premium-calculator/create'
                )
              }
            />
          </Box>
        </>
      }
    >
      <GlassCard className='p-5'>
        {loading ? (
          <CommonLoader />
        ) : (
          <form onSubmit={handleSubmit(handleUpdateEmployeeWiseMIPCalculator)}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid container spacing={2}>
                <Grid item md={GridItem}>
                  <FmSearchableSelect
                    name='employeeId'
                    control={control}
                    apiUrl='employees'
                    valueField='employeeId'
                    headerField={['Code', 'Name']}
                    labelField={['employeeCode', 'firstName']}
                    showField={['firstName']}
                    label={'Employee'}
                    onChangeProp={calculate}
                    setSearchValue={() => { }}
                    defaultValue={{
                      employeeId: editData?.employeeId,
                      firstName: editData?.employeeName,
                    }}
                  />
                </Grid>

                <Grid item md={GridItem}>
                  <FmDatePicker
                    name='startDate'
                    control={control}
                    label='Start Date'
                  />
                </Grid>

                <Grid item md={GridItem}>
                  <FmDatePicker
                    name='endDate'
                    control={control}
                    label='End Date'
                    minDate={startDate ? dayjs(startDate) : null}
                  />
                </Grid>

                <Grid item md={GridItem}>
                  <FmAutoComplete
                    name='companyMedicalInsuranceAgeBandId'
                    control={control}
                    options={ageBands}
                    label='Age Band'
                    displayField='companyMedicalInsuranceAgeBandName'
                    optionFields={['companyMedicalInsuranceAgeBandName']}
                    valueKey='companyMedicalInsuranceAgeBandId'
                    onChangeProp={calculate}
                    setSearchValue={() => { }}
                  />
                </Grid>

                <Grid item md={GridItem}>
                  <FmSearchableSelect
                    name='costCenterId'
                    control={control}
                    apiUrl='costCenters'
                    valueField='costCenterId'
                    headerField={['Cost Center Code', 'Cost Center Name']}
                    labelField={['costCenterCode', 'costCenterName']}
                    showField={['costCenterName']}
                    label='Cost Center'
                    defaultValue={{
                      costCenterId: editData?.costCenterId,
                      costCenterName: editData?.costCenterName,
                    }}
                  />
                </Grid>

                <Grid item md={GridItem}>
                  <FmAutoComplete
                    name='regionId'
                    control={control}
                    options={regions}
                    label='Emirate'
                    displayField='regionCode'
                    optionFields={['regionCode']}
                    valueKey='regionId'
                    onChangeProp={calculate}
                    setSearchValue={() => { }}
                  />
                </Grid>

                <Grid item md={GridItem}>
                  <FmTextField
                    name='premiumAmount'
                    label='Premium Amount'
                    control={control}
                  />
                </Grid>
              </Grid>

              <ActionButtons
                onSubmit={handleSubmit(handleUpdateEmployeeWiseMIPCalculator)}
                onCancel={() => navigate(-1)}
                onReset={() => reset()}
                submitText='Update'
                cancelText='Cancel'
                submitLoading={editLoading}
                cancelLoading={false}
              />
            </LocalizationProvider>
          </form>
        )}
      </GlassCard>
    </DashboardLayout>
  );
};

export default EditEmployeeWiseMIPCalculator;
