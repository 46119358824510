import { Icon } from '@iconify/react/dist/iconify.js';

const IconsList = {
  image: <Icon icon='vscode-icons:file-type-image' width='55' height='55' />,
  pdf: <Icon icon='vscode-icons:file-type-pdf2' width='55' height='55' />,
  txt: <Icon icon='ix:txt-document' width='55' height='55' />,
  doc: <Icon icon='vscode-icons:file-type-word' width='55' height='55' />,
  mp3: <Icon icon='fxemoji:musicalnote' width='55' height='55' />,
  video: <Icon icon='vscode-icons:file-type-video' width='55' height='55' />,
  zip: <Icon icon='vscode-icons:file-type-zip2' width='55' height='55' />,
  ppt: (
    <Icon
      icon='vscode-icons:file-type-libreoffice-impress'
      width='55'
      height='55'
    />
  ),
  excel: <Icon icon='vscode-icons:file-type-excel' width='55' height='55' />,
  cog: <Icon icon='vscode-icons:file-type-config' width='55' height='55' />,
  code: <Icon icon='vscode-icons:file-type-vscode' width='55' height='55' />,
};

export const useFileIcons = (size) => {
  const fileIcons = {
    pdf: IconsList.pdf,
    jpg: IconsList.image,
    jpeg: IconsList.image,
    png: IconsList.image,
    txt: IconsList.txt,
    doc: IconsList.doc,
    docx: IconsList.doc,
    mp4: IconsList.video,
    webm: IconsList.video,
    mp3: IconsList.mp3,
    m4a: IconsList.mp3,
    zip: IconsList.zip,
    ppt: IconsList.ppt,
    pptx: IconsList.ppt,
    xls: IconsList.excel,
    xlsx: IconsList.excel,
    exe: IconsList.cog,
    html: IconsList.code,
    css: IconsList.code,
    js: IconsList.code,
    php: IconsList.code,
    py: IconsList.code,
    java: IconsList.code,
    cpp: IconsList.code,
    c: IconsList.code,
    ts: IconsList.code,
    jsx: IconsList.code,
    tsx: IconsList.code,
    json: IconsList.code,
    xml: IconsList.code,
    sql: IconsList.code,
    csv: IconsList.code,
    md: IconsList.code,
    svg: IconsList.code,
  };

  return fileIcons;
};
