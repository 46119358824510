import React, { useEffect } from 'react';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import { Box } from '@mui/material';
import { CreateButton } from '../../../components/small/Buttons.jsx';
import { useNavigate } from 'react-router-dom';
import ListVendorDocuments from './ListVendorDocuments.tsx';

const VendorDocuments = () => {
  const navigate = useNavigate();
  useEffect(() => {
    document.title = 'Vendor Document | Financial';
  }, []);

  return (
    <DashboardLayout
      title={'Vendor Document'}
      actionButtons={
        <Box display='flex' flexDirection='row' alignItems='center' gap={3}>
          <Box>
            <CreateButton name='Create' onClick={() => navigate('create')} />
          </Box>
        </Box>
      }
    >
      <Box className='flex flex-col h-full gap-2'>
        {/* <AddVendorInvoiceReceivable getData={getData} /> */}
        <ListVendorDocuments />
      </Box>
    </DashboardLayout>
  );
};

export default VendorDocuments;
