import React, { useEffect, useState } from 'react';
import GlassCard from '../../../../../components/small/GlassCard.tsx';
import Icons from '../../../../../utils/Icon.jsx';
import { Box, Button } from '@mui/material';
import useTableLogic from '../../../../../components/helpers/MRTUseTableLogic.tsx';
import ViewCheckoutTable from './ViewCheckoutTable.tsx';
import Crud_Service from '../../../../../apis/CrudService.jsx';
import { useForm } from 'react-hook-form';
import TableActionButtons from '../../../../../components/_form/TableActionButtons.jsx';
import FmSearchableSelect from '../../../../../components/_mui/FmSearchableSelect.tsx';
import useLoader from '../../../../../components/helpers/UseLoader.tsx';
import { toast } from 'react-toastify';
import AddViewCheckItems from './AddViewCheckItems.tsx';
import FmTextField from '../../../../../components/_mui/FmTextField.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { CheckoutItemsValidationSchema } from '../../../../../utils/CommonvalidationSchemas.tsx';
import FmAutoComplete from '../../../../../components/_mui/FmAutoComplete.tsx';

const BoldBox = ({ title }) => {
  return <Box className='font-semibold'>{title}</Box>;
};

const ViewListCheckoutForm = ({ checkOutFormId }) => {
  const checkoutAcceptedBy = [
    {
      label: 'Yes',
      value: 1,
    },
    {
      label: 'No',
      value: 2,
    },
  ];
  const checkinAcceptedBy = [
    {
      label: 'Yes',
      value: 1,
    },
    {
      label: 'No',
      value: 2,
    },
  ];
  const [editingRowId, setEditingRowId] = useState(null);
  const [editingRowData, setEditingRowData] = useState(null);
  const { handleSubmit, control, setValue } = useForm({
    resolver: yupResolver(CheckoutItemsValidationSchema),
    mode: 'onChange',
  });
  const { startEditLoading, stopEditLoading } = useLoader();
  const crud = new Crud_Service();

  const handleCancel = () => {
    setEditingRowId(null);
    setEditingRowData(null);
  };
  const handleEdit = (row) => {
    setEditingRowId(row?.checkOutFormItemId);
    setEditingRowData({ ...row });
  };
  const handleDeleteEvent = async (view) => {
    await crud.remove(
      'checkoutformitems',
      view?.checkOutFormItemId,
      (err, res) => {
        if (res?.status === 204) {
          fetchData();
          toast.success('Item Deleted Successfully');
        } else {
        }
      }
    );
  };

  const handleUpdateCheckOut = async (values) => {
    const combinedData = {
      ...values,
      checkInLocationId:
        values.checkInLocationId || editingRowData?.checkInLocationId,
      checkInConditionAccept:
        values.checkInCondition || editingRowData?.checkInCondition,
      checkOutConditionAccept:
        values.checkOutCondition || editingRowData?.checkOutCondition,
      status: 2,
    };
    startEditLoading();
    await crud.update(
      'checkoutformitems',
      editingRowId,
      combinedData,
      (err, res) => {
        if (res?.status === 200) {
          stopEditLoading();
          fetchData();
          toast.success('Item Updated Successfully');
          setEditingRowData(null);
          setEditingRowId(null);
        } else {
          stopEditLoading();
        }
      }
    );
  };

  const {
    rows,
    rowCount,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic('checkoutformitems');

  const ActionData = [
    {
      name: 'Edit',
      icon: Icons.edit,
      onClick: (props) => {
        handleEdit(props);
      },
    },
    {
      name: 'Delete',
      icon: Icons.delete,
      onClick: (props) => handleDeleteEvent(props),
      danger: true,
    },
  ];

  const columns = [
    {
      header: 'Actions',
      Cell: ({ row }) => (
        <Box className='flex gap-2'>
          {editingRowId === row?.original?.checkOutFormItemId ? (
            <>
              <Button onClick={handleSubmit(handleUpdateCheckOut)}>Save</Button>
              <Button onClick={handleCancel}>Cancel</Button>
            </>
          ) : (
            <TableActionButtons
              Actions={ActionData?.map((action) => ({
                ...action,
                onClick: () => action.onClick(row?.original),
              }))}
            />
          )}
        </Box>
      ),
    },
    {
      accessorKey: 'checkInLocationId',
      header: 'Location',
      enableEditing: true,
      Cell: ({ cell }) => {
        const checkOut: any = rows?.find(
          (item) =>
            item?.checkOutFormItemId === cell?.row?.original?.checkOutFormItemId
        );
        return editingRowId === cell?.row?.original?.checkOutFormItemId ? (
          <FmSearchableSelect
            name='checkInLocationId'
            control={control}
            apiUrl='checkinlocations'
            valueField='checkInLocationId'
            headerField={['Location Name']}
            defaultValue={{
              checkInLocationId: editingRowData?.checkInLocationId,
              checkInLocationName: editingRowData?.checkInLocationName,
            }}
            labelField={['checkInLocationName']}
            showField={['checkInLocationName']}
            label='Location'
            required
          />
        ) : (
          checkOut?.checkInLocationName || ''
        );
      },
    },
    {
      accessorKey: 'description',
      header: 'Description',
      enableEditing: true,
      Cell: ({ cell }) => {
        const checkOut: any = rows?.find(
          (item) =>
            item?.checkOutFormItemId === cell?.row?.original?.checkOutFormItemId
        );
        return editingRowId === cell?.row?.original?.checkOutFormItemId ? (
          <FmTextField
            name='description'
            label='Description'
            control={control}
            defaultValue={editingRowData?.description}
          />
        ) : (
          checkOut?.description || ''
        );
      },
    },
    {
      accessorKey: 'checkInRemarks',
      header: 'Check In Remarks',
      enableEditing: true,
      Cell: ({ cell }) => {
        const checkOut: any = rows?.find(
          (item) =>
            item?.checkOutFormItemId === cell?.row?.original?.checkOutFormItemId
        );
        return editingRowId === cell?.row?.original?.checkOutFormItemId ? (
          <FmTextField
            name='checkInRemarks'
            label='checkInRemarks'
            control={control}
            defaultValue={editingRowData?.checkInRemarks}
          />
        ) : (
          checkOut?.checkInRemarks || ''
        );
      },
    },
    {
      accessorKey: 'checkOutRemarks',
      header: 'Check Out Remarks',
      enableEditing: true,
      Cell: ({ cell }) => {
        const checkOut: any = rows?.find(
          (item) =>
            item?.checkOutFormItemId === cell?.row?.original?.checkOutFormItemId
        );
        return editingRowId === cell?.row?.original?.checkOutFormItemId ? (
          <FmTextField
            name='checkOutRemarks'
            label='checkOutRemarks'
            control={control}
          />
        ) : (
          checkOut?.checkOutRemarks || ''
        );
      },
    },
    {
      accessorKey: 'estimationCost',
      header: 'Estimation Cost',
      enableEditing: true,
      Cell: ({ cell }) => {
        const checkOut: any = rows?.find(
          (item) =>
            item?.checkOutFormItemId === cell?.row?.original?.checkOutFormItemId
        );
        return editingRowId === cell?.row?.original?.checkOutFormItemId ? (
          <FmTextField
            name='estimationCost'
            label='estimationCost'
            control={control}
          />
        ) : (
          checkOut?.estimationCost || ''
        );
      },
    },
    {
      accessorKey: 'checkOutConditionAccept',
      header: 'Check Out Accepted By',
      enableEditing: true,
      Cell: ({ cell }) => {
        const checkOut: any = rows?.find(
          (item) =>
            item?.checkOutFormItemId === cell?.row?.original?.checkOutFormItemId
        );
        return editingRowId === cell?.row?.original?.checkOutFormItemId ? (
          <FmAutoComplete
            name='checkOutConditionAccept'
            control={control}
            options={checkoutAcceptedBy}
            label='Check Out Accepted By'
            displayField='label'
            optionFields={['label']}
            valueKey='value'
          />
        ) : (
          checkOut?.checkOutCondition || ''
        );
      },
    },
    {
      accessorKey: 'checkInConditionAccept',
      header: 'Check In Accepted By',
      enableEditing: true,
      Cell: ({ cell }) => {
        const checkOut: any = rows?.find(
          (item) =>
            item?.checkOutFormItemId === cell?.row?.original?.checkOutFormItemId
        );
        return editingRowId === cell?.row?.original?.checkOutFormItemId ? (
          <FmAutoComplete
            name='checkInCondition'
            control={control}
            options={checkinAcceptedBy}
            label='Check In Accepted By'
            displayField='label'
            optionFields={['label']}
            valueKey='value'
            defaultValue={editingRowData?.checkInCondition}
          />
        ) : (
          checkOut?.checkInCondition || ''
        );
      },
    },
  ];

  useEffect(() => {
    if (editingRowData?.description) {
      setValue('description', editingRowData?.description);
    }
    if (editingRowData?.checkInRemarks) {
      setValue('checkInRemarks', editingRowData?.checkInRemarks);
    }
    if (editingRowData?.checkOutRemarks) {
      setValue('checkOutRemarks', editingRowData?.checkOutRemarks);
    }
    if (editingRowData?.estimationCost) {
      setValue('estimationCost', editingRowData?.estimationCost);
    }
    if (editingRowData?.checkInCondition) {
      setValue('checkInCondition', editingRowData?.checkInCondition);
    }
    if (editingRowData?.checkOutCondition) {
      setValue('checkOutCondition', editingRowData?.checkOutCondition);
    }
  }, [editingRowData, setValue]);

  return (
    <>
      <GlassCard className='my-4'>
        <AddViewCheckItems
          fetchData={fetchData}
          checkOutFormId={checkOutFormId}
        />
        <>
          <ViewCheckoutTable
            rows={rows}
            columns={columns}
            editingRowId={null}
            tableRecordCounts={tableRecordCounts}
            isLoading={isLoading}
            pagination={pagination}
            sorting={sorting}
            setPagination={setPagination}
            setSorting={setSorting}
            columnFilters={columnFilters}
            globalFilter={globalFilter}
            setColumnFilters={setColumnFilters}
            setGlobalFilter={setGlobalFilter}
            setSearchKeyword={setSearchKeyword}
            searchKeyword={searchKeyword}
            fetchData={fetchData}
          />
        </>
      </GlassCard>
    </>
  );
};

export default ViewListCheckoutForm;
