import { Icon } from '@iconify/react';
import {
  Alert,
  Avatar,
  Box,
  Button,
  CircularProgress,
  IconButton,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import Crud_Service from '../../apis/CrudService';
import MuiDialogOne from '../_mui/MuiDialogOne';
import GlassCard from '../small/GlassCard.tsx';

const UploadWithLoading = ({
  onSubmitFiles,
  removeBorder,
  previewdData,
  uploadSingle = false,
  label = 'Upload',
  modal = true,
}) => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [view, setView] = useState(false);
  const crud = new Crud_Service();
  const [singleFile, setSingleFile] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleCloseModal = () => {
    // setFiles([]);
    setView(false);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'image/*,application/pdf',
    multiple: true,
    onDrop: async (acceptedFiles) => {
      setLoading(true);

      const newFiles = acceptedFiles?.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );

      setFiles((prevFiles) => [...prevFiles, ...newFiles]);
      setLoading(false);
    },
  });

  // const { getRootProps, getInputProps, isDragActive } = useDropzone({
  //   accept: 'image/*,application/pdf',
  //   multiple: true,
  //   onDrop: async (acceptedFiles) => {
  //     setLoading(true);

  //     // Initialize new files with status
  //     const newFiles = acceptedFiles?.map((file) =>
  //       Object.assign(file, {
  //         preview: URL.createObjectURL(file),
  //         status: 'pending',
  //       })
  //     );

  //     // Add new files to the existing list in state
  //     setFiles((prevFiles) => [...prevFiles, ...newFiles]);

  //     const updatedFiles = [...newFiles];
  //     const uploadPromises = updatedFiles.map(async (file, index) => {
  //       try {
  //         updatedFiles[index].status = 'loading';

  //         // Update the state once to show the "loading" status
  //         setFiles((prevFiles) =>
  //           prevFiles?.map((f) =>
  //             f?.name === file?.name ? { ...f, status: 'loading' } : f
  //           )
  //         );

  //         const fileId = await uploadFile(file); // Upload API call
  //         updatedFiles[index].status = 'uploaded';
  //         updatedFiles[index].fileId = fileId;
  //       } catch (error) {
  //         updatedFiles[index].status = 'error';
  //       }
  //     });

  //     await Promise.all(uploadPromises);

  //     // Final update for all files
  //     setFiles((prevFiles) =>
  //       prevFiles?.map((f) => {
  //         const updatedFile = updatedFiles?.find((u) => u.name === f.name);
  //         return updatedFile ? { ...f, ...updatedFile } : f;
  //       })
  //     );

  //     setLoading(false);
  //   },
  // });

  useEffect(() => {
    if (files?.length > 1 && uploadSingle) {
      setSingleFile(true);
      setErrorMessage('Only one file is allowed');
    } else {
      setSingleFile(false);
      setErrorMessage('');
    }
  }, [files]);

  const handleRemoveFile = (fileName) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
  };

  //   const handleSubmit = async () => {
  //     const updatedFiles = [...files];
  //     try {
  //       setLoading(true);

  //       // Upload files where apiCallNo is false
  //       const fileIds = await Promise.all(
  //         files.map(async (file) => {
  //           if (file?.apiCallNo === false) {
  //             return;
  //           } else {
  //             const fileId = await uploadFile(file);
  //             return fileId; // Collect fileId for each file
  //           }
  //         })
  //       );

  //       // Filter out any null values (files that were skipped or failed uploads)
  //       const validFileIds = fileIds.filter((fileId) => fileId !== null);

  //       onSubmitFiles(validFileIds);

  //       // Clear local files
  //       // setFiles([]);
  //       setLoading(false);
  //     } catch (error) {
  //       setLoading(false);
  //     }
  //   };

  const handleSubmit = async () => {
    const updatedFiles = [...files];

    for (let i = 0; i < updatedFiles.length; i++) {
      const file = updatedFiles[i];
      updatedFiles[i].status = 'loading';
      setFiles([...updatedFiles]);

      try {
        const fileId = await uploadFile(file);
        updatedFiles[i].status = 'uploaded';
        updatedFiles[i].fileId = fileId;
        setFiles([...updatedFiles]);
      } catch (error) {
        updatedFiles[i].status = 'error';
        setFiles([...updatedFiles]);
        // toast.error(`Failed to upload ${file.name}`);
      }
    }

    const uploadedFileIds = updatedFiles
      .filter((file) => file.status === 'uploaded')
      .map((file) => file.fileId);

    onSubmitFiles(uploadedFileIds);
  };

  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileTypeId', 50);

    try {
      return await new Promise((resolve, reject) => {
        crud.create('/files/upload', formData, (err, res) => {
          if (res?.status === 200) {
            const newFile = {
              fileName: res?.data?.originalFileName,
              fileId: res?.data?.fileId,
            };
            resolve(newFile.fileId); // Resolve with fileId
            // setView(false);
            setLoading(false);
          } else {
            // toast.error('Failed to upload file.');
            reject(new Error('Failed to upload file.'));
            setLoading(false);
            // setView(false);
          }
        });
      });
    } catch (error) {
      setLoading(false);
      setView(false);
      return null;
    }
  };

  useEffect(() => {
    if (previewdData) {
      const parsedFiles = previewdData
        .map((data, index) => {
          if (typeof data === 'string' && data.includes(';base64,')) {
            const [type, base64] = data.split(';base64,');
            const fileType = type.split(':')[1];
            return {
              type: fileType,
              preview: data,
              name: `File-${index + 1}`,
              apiCallNo: false,
            };
          }
          return null;
        })
        .filter((file) => file !== null);

      setFiles(parsedFiles);
    }
  }, [previewdData]);

  return (
    <>
      {modal ? (
        <>
          <Button
            className='flex items-center gap-3 justify-center py-2.5 font-semibold'
            onClick={() => setView(!view)}
            fullWidth
            sx={{
              border: !removeBorder && '1px solid',
              borderColor: !removeBorder && 'border.dark',
            }}
          >
            {files?.length > 0 ? (
              <>
                <span className='text-lg'>
                  <Icon icon='oi:circle-check' />
                </span>
                <span>Uploaded files - {files?.length}</span>
              </>
            ) : (
              <>
                {' '}
                <span className='text-lg'>
                  <Icon icon='ph:upload-bold' />
                </span>
                <span style={{ lineHeight: 1 }}>{label}</span>
              </>
            )}
          </Button>
          <MuiDialogOne
            title='Upload Images'
            open={view}
            onClose={handleCloseModal}
            width='550px'
          >
            {singleFile && (
              <Alert variant='filled' severity='error' className='mb-5'>
                {errorMessage}
              </Alert>
            )}

            <Box
              {...getRootProps()}
              className='w-full py-8 px-2 rounded-sm text-center'
              sx={{
                border: '2px dashed',
                borderColor: 'primary.main',
                bgcolor: isDragActive
                  ? 'background.light'
                  : 'background.mainLight',
                cursor: 'pointer',
              }}
            >
              <input {...getInputProps()} disabled={loading} />

              <Box
                className='text-5xl flex justify-center mb-4'
                sx={{ color: 'primary.main' }}
              >
                <Icon icon='ph:upload' />
              </Box>

              <Box className='text-base font-semibold mb-2'>
                {isDragActive
                  ? 'Drop files here...'
                  : 'Drag & drop files here, or click to select files'}
              </Box>

              <Button
                variant='outlined'
                color='primary'
                className='font-semibold'
                onClick={() => {}}
              >
                Upload Files
              </Button>
            </Box>

            <Box className='mt-5'>
              {files.length > 0 &&
                files.map((file, index) => (
                  <GlassCard
                    key={index}
                    className='flex justify-between items-center p-1 px-2 mb-3'
                  >
                    <Box className='flex gap-3 items-center w-full'>
                      {file.type.startsWith('image/') ? (
                        <Avatar
                          sx={{ bgcolor: 'primary.main' }}
                          src={file.preview}
                          alt={file.name}
                        ></Avatar>
                      ) : (
                        <Avatar sx={{ bgcolor: 'primary.main' }}>
                          <Icon icon='pepicons-pencil:file' />
                        </Avatar>
                      )}

                      {/* <h3 className='font-bold flex-1 min-w-0'>{file.name}</h3> */}
                      <h3 className='font-bold flex-1 min-w-0'>
                        {file.name}{' '}
                        {file.status === 'loading' && (
                          <CircularProgress size={16} sx={{ marginLeft: 1 }} />
                        )}
                        {file.status === 'uploaded' && (
                          <span>
                            -- Uploaded
                            <Icon
                              icon='prime:check-circle'
                              width='24'
                              height='24'
                              color='green'
                            />
                          </span>
                        )}
                        {file.status === 'error' && ' - Error'}
                      </h3>

                      {/* {file?.status === 'loading' && (
                    <LinearProgress
                      variant='determinate'
                      value={file.progress || 0}
                      sx={{ marginTop: 1, width: '100%' }}
                    />
                  )} */}

                      <IconButton
                        color='error'
                        onClick={() => handleRemoveFile(file.name)}
                        className='p-2'
                      >
                        <Icon icon='ic:baseline-clear' />
                      </IconButton>
                    </Box>
                  </GlassCard>
                ))}
            </Box>

            <Box className='flex justify-between gap-4 mt-10'>
              <Button
                variant='outlined'
                onClick={() => setFiles([])}
                className='min-w-28'
              >
                Clear
              </Button>
              <Button
                variant='contained'
                onClick={handleSubmit}
                disabled={
                  files.length === 0 || (uploadSingle === true && singleFile)
                }
                className='min-w-28 font-bold'
              >
                Submit
              </Button>
            </Box>
          </MuiDialogOne>
        </>
      ) : (
        <>
          {singleFile && (
            <Alert variant='filled' severity='error' className='mb-5'>
              {errorMessage}
            </Alert>
          )}

          <Box
            {...getRootProps()}
            className='w-full py-8 px-2 rounded-sm text-center'
            sx={{
              border: '2px dashed',
              borderColor: 'primary.main',
              bgcolor: isDragActive
                ? 'background.light'
                : 'background.mainLight',
              cursor: 'pointer',
            }}
          >
            <input {...getInputProps()} disabled={loading} />

            <Box
              className='text-5xl flex justify-center mb-4'
              sx={{ color: 'primary.main' }}
            >
              <Icon icon='ph:upload' />
            </Box>

            <Box className='text-base font-semibold mb-2'>
              {isDragActive
                ? 'Drop files here...'
                : 'Drag & drop files here, or click to select files'}
            </Box>

            <Button
              variant='outlined'
              color='primary'
              className='font-semibold'
              onClick={() => {}}
            >
              Upload Files
            </Button>
          </Box>

          <Box className='mt-5'>
            {files?.length > 0 &&
              files?.map((file, index) => (
                <GlassCard
                  key={index}
                  className='flex justify-between items-center p-1 px-2 mb-3'
                >
                  <Box className='flex gap-3 items-center w-full'>
                    <Avatar sx={{ bgcolor: 'primary.main' }}>
                      <Icon icon='pepicons-pencil:file' />
                    </Avatar>
                    {/* {file?.type?.startsWith('image/') ? (
                      <Avatar
                        sx={{ bgcolor: 'primary.main' }}
                        src={file?.preview}
                        alt={file?.name}
                      ></Avatar>
                    ) : (
                      <Avatar sx={{ bgcolor: 'primary.main' }}>
                        <Icon icon='pepicons-pencil:file' />
                      </Avatar>
                    )} */}

                    {/* <h3 className='font-bold flex-1 min-w-0'>{file.name}</h3> */}
                    <h3 className='font-bold flex-1 min-w-0'>
                      {file?.name}{' '}
                      {file?.status === 'loading' && (
                        <CircularProgress size={16} sx={{ marginLeft: 1 }} />
                      )}
                      {file?.status === 'uploaded' && (
                        <span>
                          -- Uploaded
                          <Icon
                            icon='prime:check-circle'
                            width='24'
                            height='24'
                            color='green'
                          />
                        </span>
                      )}
                      {file?.status === 'error' && ' - Error'}
                    </h3>

                    {/* {file?.status === 'loading' && (
                    <LinearProgress
                      variant='determinate'
                      value={file.progress || 0}
                      sx={{ marginTop: 1, width: '100%' }}
                    />
                  )} */}

                    <IconButton
                      color='error'
                      onClick={() => handleRemoveFile(file?.name)}
                      className='p-2'
                    >
                      <Icon icon='ic:baseline-clear' />
                    </IconButton>
                  </Box>
                </GlassCard>
              ))}
          </Box>

          <Box className='flex justify-between gap-4 mt-10'>
            <Button
              variant='outlined'
              onClick={() => setFiles([])}
              className='min-w-28'
            >
              Clear
            </Button>
            <Button
              variant='contained'
              onClick={handleSubmit}
              disabled={
                files?.length === 0 || (uploadSingle === true && singleFile)
              }
              className='min-w-28 font-bold'
            >
              Submit
            </Button>
          </Box>
        </>
      )}
    </>
  );
};

export default UploadWithLoading;
