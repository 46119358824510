import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import GlassCard from '../../../components/small/GlassCard.tsx';
import Crud_Service from '../../../apis/CrudService.jsx';
import { useForm } from 'react-hook-form';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import { toast } from 'react-toastify';
import useCommonFetchApi from '../../../components/helpers/useCommonFetchApi.tsx';
import FmAutoComplete from '../../../components/_mui/FmAutoComplete.tsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import dayjs from 'dayjs';
import ActionButtons from '../../../components/_form/ActionButtons.tsx';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { CompanyInsurancePolicySchema } from '../../../utils/CommonvalidationSchemas.tsx';
import FmDatePicker from '../../../components/_mui/FmDatePicker.tsx';
import UploadModalBox from '../../../components/upload/UploadModalBox.tsx';

const GridValue = 2.4;

const AddCompanyInsurancePolicy = (props) => {
  const crud = new Crud_Service();
  const { startCreateLoading, stopCreateLoading, createLoading } = useLoader();
  const navigate = useNavigate();
  const { control, handleSubmit, watch, reset, trigger } = useForm({
    mode: 'onChange',
    resolver: yupResolver(CompanyInsurancePolicySchema),
  });
  const [uploadedFileIds, setUploadedFileIds] = useState([]);
  const [files, setFiles] = useState([]);
  const handleFileIds = (fileIds) => {
    setUploadedFileIds(fileIds);
  };
  const { data: insurancePolicy } = useCommonFetchApi(
    'companyinsurancepolicies'
  ) || {
    data: [],
  };
  const { data: endUser } = useCommonFetchApi(
    'companyinsurancepolicyendusers'
  ) || {
    data: [],
  };
  const { data: typeOfProduct } = useCommonFetchApi(
    'companyinsurancepolicytypeofproducts'
  ) || {
    data: [],
  };
  const { data: insurer } = useCommonFetchApi(
    'companyinsurancepolicyinsurers'
  ) || {
    data: [],
  };
  const { data: premium } = useCommonFetchApi(
    'companyinsurancepolicypremiums'
  ) || {
    data: [],
  };

  const handleSubmitPolicy = async (values) => {
    const formattedStartDate = values.startDate
      ? dayjs(values.startDate).format('YYYY-MM-DD')
      : null;
    const formattedEndDate = values.endDate
      ? dayjs(values.endDate).format('YYYY-MM-DD')
      : null;

    startCreateLoading();

    const payload = {
      companyInsurancePolicyId: values?.companyInsurancePolicyId || null, // Default to null if undefined
      description: values?.description || '', // Default to an empty string if undefined
      companyInsurancePolicyEndUserId:
        values?.companyInsurancePolicyEndUserId || null,
      companyInsurancePolicyTypeOfProductId:
        values?.companyInsurancePolicyTypeOfProductId || null,
      companyInsurancePolicyInsurerId:
        values?.companyInsurancePolicyInsurerId || null,
      companyInsurancePolicyPremiumId:
        values?.companyInsurancePolicyPremiumId || null,
      policyDetail: values?.policyDetail || '',
      brokerName: values?.brokerName || '',
      mobileNumber: Number(values?.mobileNumber),
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      priorNotificationDays: values?.priorNotificationDays || null,
      insuredSum: values?.insuredSum || 0,
      remarks: values?.remarks || '',
      fileUploadIds: uploadedFileIds,
    };

    try {
      await crud.create(
        'companyInsurancePolicyDetails',
        payload,
        (_err, res) => {
          if (res?.status === 201) {
            navigate(-1);
            toast.success('Added Insurance Policy Successfully');
          } else {
            stopCreateLoading();
          }
        }
      );
    } catch (error) {
      stopCreateLoading();
    }
  };

  const startDate = watch('startDate');

  return (
    <DashboardLayout title='Add Company Insurance Policy'>
      <GlassCard className='p-4 mb-4'>
        <form onSubmit={handleSubmit(handleSubmitPolicy)}>
          <Grid container spacing={3}>
            <Grid item md={GridValue}>
              <FmAutoComplete
                name='companyInsurancePolicyId'
                control={control}
                options={insurancePolicy}
                label='Insurance Policy'
                displayField='companyInsurancePolicyName'
                optionFields={['companyInsurancePolicyName']}
                valueKey='companyInsurancePolicyId'
                required
              />
            </Grid>

            <Grid item md={GridValue}>
              <FmAutoComplete
                name='companyInsurancePolicyEndUserId'
                control={control}
                options={endUser}
                label='End User'
                displayField='companyInsurancePolicyEndUserName'
                optionFields={['companyInsurancePolicyEndUserName']}
                valueKey='companyInsurancePolicyEndUserId'
                required
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmAutoComplete
                name='companyInsurancePolicyTypeOfProductId'
                control={control}
                options={typeOfProduct}
                label='Type of Product'
                displayField='companyInsurancePolicyTypeOfProductName'
                optionFields={['companyInsurancePolicyTypeOfProductName']}
                valueKey='companyInsurancePolicyTypeOfProductId'
                required
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmAutoComplete
                name='companyInsurancePolicyInsurerId'
                control={control}
                options={insurer}
                label='Insurer'
                displayField='companyInsurancePolicyInsurerName'
                optionFields={['companyInsurancePolicyInsurerName']}
                valueKey='companyInsurancePolicyInsurerId'
                required
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmAutoComplete
                name='companyInsurancePolicyPremiumId'
                control={control}
                options={premium}
                label='Insurance Policy Premium'
                displayField='companyInsurancePolicyName'
                optionFields={['companyInsurancePolicyName']}
                valueKey='companyInsurancePolicyPremiumId'
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmTextField
                name='policyDetail'
                label='Policy Details'
                control={control}
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmTextField
                name='brokerName'
                label='Broker Name'
                control={control}
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmTextField
                name='mobileNumber'
                pattern='Number'
                label='Mobile Number'
                control={control}
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmDatePicker
                name='startDate'
                label='Start Date'
                control={control}
                onChangeProp={() => {
                  trigger('endDate');
                }}
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmDatePicker
                name='endDate'
                label='End Date'
                control={control}
                defaultValue={dayjs().add(1, 'day')}
                minDate={dayjs(startDate).add(1, 'day')}
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmTextField
                name='insuredSum'
                label='Insured Sum Details'
                control={control}
                pattern='Number'
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmTextField
                name='priorNotificationDays'
                label='Prior Notification Days'
                control={control}
                pattern='Number'
                minLength={1}
              />
            </Grid>
            <Grid item md={GridValue}>
              <UploadModalBox
                onSubmitFiles={handleFileIds}
                files={files}
                setFiles={setFiles}
              />
            </Grid>
            <Grid item md={GridValue * 2}>
              <FmTextField
                name='description'
                label='Description'
                control={control}
                multiline
                rows={2}
                maxRows={5}
              />
            </Grid>
            <Grid item md={GridValue * 2}>
              <FmTextField
                name='remarks'
                label='Remarks'
                control={control}
                multiline
                rows={2}
                maxRows={5}
              />
            </Grid>
          </Grid>
          <ActionButtons
            onSubmit={handleSubmit(handleSubmitPolicy)}
            onCancel={() => navigate(-1)}
            submitText='Create'
            cancelText='Cancel'
            submitLoading={createLoading}
            onReset={reset}
          />
        </form>
      </GlassCard>
    </DashboardLayout>
  );
};

export default AddCompanyInsurancePolicy;
