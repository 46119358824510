import React from 'react';
import { Menu, IconButton, Box, Button } from '@mui/material';
import { Icon } from '@iconify/react';

const ConfirmationPopoverBox = ({ title, icon, onConfirm }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box>
        <IconButton onClick={handleClick}>{icon}</IconButton>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          component='div'
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          sx={{
            '& .MuiPaper-root': {
              padding: 0,
              boxShadow: 2,
            },
            '& .MuiList-root': { padding: 0 },
          }}
        >
          <Box className='p-5'>
            <div className='font-bold mb-2 flex gap-3 items-center'>
              {' '}
              <span className='text-2xl'>
                <Icon icon='mdi:trash-outline' />
              </span>{' '}
              {title}
            </div>
            <div className='w-72'>
              Are you sure you want to delete this?
            </div>
            <div className='flex justify-end items-center gap-5'>
              <Button variant='outlined' color='primary' onClick={handleClose}>
                No
              </Button>
              <Button
                onClick={onConfirm}
                variant='contained'
                sx={{ fontWeight: 700 }}
                color='error'
              >
                Yes
              </Button>
            </div>
          </Box>
        </Menu>
      </Box>
    </>
  );
};

export default ConfirmationPopoverBox;
