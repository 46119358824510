import React, { useEffect } from 'react';
import { Grid, InputAdornment } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import FmSearchableSelect from '../../../components/_mui/FmSearchableSelect.tsx';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import Crud_Service from '../../../apis/CrudService.jsx';
import { toast } from 'react-toastify';
import ActionButtons from '../../../components/_form/ActionButtons.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { SalesOrdervalidationSchema } from '../../../utils/CommonvalidationSchemas.tsx';
import GlassCardCollapse from './../../../components/small/GlassCardCollapse.tsx';

const GridSize = 2.4;

const AddSalesOrderDistribution = (props) => {
  const { fetchData } = props;
  const [selectedClient, setSelectedClient] = React.useState(null);
  const [selectedProject, setSelectedProject] = React.useState(null);

  const { handleSubmit, control, reset, setValue, watch } = useForm({
    resolver: yupResolver(SalesOrdervalidationSchema),
    mode: 'onChange',
  });
  const crud = new Crud_Service();
  const { createLoading, startCreateLoading, stopCreateLoading } = useLoader();

  const handleSubmitSalesOrderDistribution = async (values) => {
    startCreateLoading();
    const combinedData = {
      ...values,
      salesOrderDistribustionDate: dayjs(
        values?.salesOrderDistribustionDate
      ).format('YYYY-MM-DD'),
    };
    await crud.create('salesorderdistributions', combinedData, (err, res) => {
      if (err) {
        stopCreateLoading();

        return;
      }
      if (res?.status === 201) {
        toast.success('Sales Order Distribution Created successfully');
        stopCreateLoading();
        fetchData();
        reset();
      } else {
        stopCreateLoading();
      }
    });
  };

  const totalContractValue = watch('totalContractValue');
  const budgetValue = watch('budgetValue');

  useEffect(() => {
    if (totalContractValue && budgetValue) {
      const calculatedBudget = Math.round(
        (totalContractValue * budgetValue) / 100
      );
      setValue('totalBudgetValue', calculatedBudget, { shouldValidate: true });
    }
  }, [totalContractValue, budgetValue, setValue]);
  const handleContractChange = (selectedContract) => {
    if (selectedContract) {
      setValue('totalContractValue', selectedContract.contractCost ?? '', {
        shouldValidate: true,
      });
    }
  };
  const handleSelectedClient = (e) => {
    setSelectedClient(e);
  };
  return (
    <GlassCardCollapse title='Create New'>
      <form onSubmit={handleSubmit(handleSubmitSalesOrderDistribution)}>
        <Grid container spacing={2}>
          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='companyId'
              control={control}
              apiUrl='companies'
              valueField='companyId'
              labelField={['companyCode', 'companyName']}
              showField={['companyCode', 'companyName']}
              label='Company'
              required
            />
          </Grid>

          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='clientId'
              control={control}
              apiUrl='clients'
              valueField='clientId'
              onChangeProp={handleSelectedClient}
              labelField={['clientCode', 'clientName']}
              showField={['clientCode', 'clientName']}
              label='Client'
              required
            />
          </Grid>

          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='projectId'
              control={control}
              apiUrl='projects'
              queryparam='clientId'
              queryparamValue={selectedClient?.clientId}
              valueField='projectId'
              onChangeProp={(selectedProject) =>
                setSelectedProject(selectedProject)
              }
              labelField={['projectCode', 'projectName']}
              showField={['projectCode', 'projectName']}
              label='Project'
              required
            />
          </Grid>

          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='contractId'
              control={control}
              apiUrl='contracts'
              queryparam='projectId'
              queryparamValue={selectedProject?.projectId}
              valueField='contractId'
              labelField={['contractCode', 'contractName']}
              showField={['contractCode', 'contractName']}
              label='Contract'
              onChangeProp={(selectedContract) =>
                handleContractChange(selectedContract)
              }
              required
            />
          </Grid>

          <Grid item md={GridSize}>
            <FmTextField
              name='totalContractValue'
              control={control}
              label='Total Contract'
              pattern='Number'
              maxLength={10}
              minLength={1}
              required
              readOnly
            />
          </Grid>
          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='workOrderId'
              control={control}
              apiUrl='workorders'
              valueField='workOrderId'
              labelField={['workOrderName', 'workOrderCode']}
              showField={['workOrderName', 'workOrderCode']}
              label='Work order'
              required
            />
          </Grid>

          <Grid item md={GridSize}>
            <FmTextField
              name='budgetValue'
              control={control}
              label='Budget value %'
              pattern='Percentage'
              maxLength={100}
              required

            />
          </Grid>
          <Grid item md={GridSize}>
            <FmTextField
              name='totalBudgetValue'
              control={control}
              label='Total Budget (AED)'
              pattern='Number'
              minLength={1}
              maxLength={10}
              prefix='AED'
              required
              readOnly
            />
          </Grid>

          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='approvedBy'
              control={control}
              apiUrl='loginusers'
              valueField='loginUserId'
              labelField={['employeeCode', 'loginUserName']}
              showField={['employeeCode', 'loginUserName']}
              label='Approved By'
              required
            />
          </Grid>

          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='preparedBy'
              control={control}
              apiUrl='loginusers'
              valueField='loginUserId'
              labelField={['employeeCode', 'loginUserName']}
              showField={['employeeCode', 'loginUserName']}
              label='Prepared By'
              required
            />
          </Grid>

          <Grid item md={GridSize}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Controller
                name='salesOrderDistribustionDate'
                control={control}
                defaultValue={dayjs()}
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    label='Date'
                    value={value || dayjs()}
                    format='DD/MM/YYYY'
                    onChange={(newValue) => {
                      onChange(newValue);
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        <ActionButtons
          onSubmit={handleSubmit(handleSubmitSalesOrderDistribution)}
          onReset={reset}
          submitLoading={createLoading}
          cancelLoading={false}
          submitText='Create'
        />
      </form>
    </GlassCardCollapse>
  );
};

export default AddSalesOrderDistribution;
