// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fm-button {
  border-radius: 5px;
  font-weight: 600;
  border: none;
}
.fm-button:hover {
  cursor: pointer;
}

.fm-button-primary {
  background-color: #0d9f91;
  color: white;
}
.fm-button-primary:hover {
  background-color: #0b7a6f;
}

.fm-button-secondary {
  background-color: #f0f0f0;
  color: black;
}
.fm-button-secondary:hover {
  background-color: #e0e0e0;
}

.fm-button-danger {
  background-color: #f44336;
  color: white;
}
.fm-button-danger:hover {
  background-color: #d32f2f;
}`, "",{"version":3,"sources":["webpack://./src/lib/FileManager/components/Button/Button.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,gBAAA;EACA,YAAA;AACF;AACE;EACE,eAAA;AACJ;;AAGA;EACE,yBAAA;EACA,YAAA;AAAF;AAEE;EACE,yBAAA;AAAJ;;AAIA;EACE,yBAAA;EACA,YAAA;AADF;AAGE;EACE,yBAAA;AADJ;;AAKA;EACE,yBAAA;EACA,YAAA;AAFF;AAIE;EACE,yBAAA;AAFJ","sourcesContent":[".fm-button {\n  border-radius: 5px;\n  font-weight: 600;\n  border: none;\n\n  &:hover {\n    cursor: pointer;\n  }\n}\n\n.fm-button-primary {\n  background-color: #0d9f91;\n  color: white;\n\n  &:hover {\n    background-color: #0b7a6f;\n  }\n}\n\n.fm-button-secondary {\n  background-color: #f0f0f0;\n  color: black;\n\n  &:hover {\n    background-color: #e0e0e0;\n  }\n}\n\n.fm-button-danger {\n  background-color: #f44336;\n  color: white;\n\n  &:hover {\n    background-color: #d32f2f;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
