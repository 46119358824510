import React, { useEffect, useMemo } from 'react';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import Nav from '../../../../utils/Nav.tsx';
import { useLocation, useNavigate } from 'react-router-dom';
import { CreateButton } from '../../../../components/small/Buttons.jsx';
import ListSalesInvoicesAR from './ListSalesInvoicesAR.tsx';
import { useSelector } from 'react-redux';
import { findNavByName } from '../../../../utils/navUtils.ts';

const SalesInvoicesAR = () => {
  const navigation = useNavigate();
  const location = useLocation();
  const menu = useSelector((state) => state?.menu?.items);
  
  // Flatten menu only once and store it for reuse
  const SubMenu = useMemo(() => {
    return menu?.map(item => item?.menus?.flat()).flat() || [];
  }, [menu]);

  // Use findNavByName to get the "Accounts Receivable" menu or its child menus
  const result = useMemo(() => findNavByName(SubMenu,"Financial","Accounts Receivable"), [SubMenu]);

  

  useEffect(() => {
    document.title = `Sales Invoices | Accounts Receivable | Financial`;
  }, []);

  return (
    <DashboardLayout
      title='Sales Invoices'
      hasSubmenu
      menu={result}
      actionButtons={
        <CreateButton
          name='New'
          onClick={() => navigation(`${location.pathname}/create`)}
        />
      }
    >
      <ListSalesInvoicesAR />
    </DashboardLayout>
  );
};

export default SalesInvoicesAR;
