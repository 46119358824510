import React, { useState, useEffect } from 'react';
import DataTable from '../../../components/DataTable.tsx';
import { Box } from '@mui/material';
import GlassCard from '../../../components/small/GlassCard.tsx';
import ViewDetails from './ViewDetails.tsx';
import { CreateButton } from '../../../components/small/Buttons.jsx';
import { useNavigate } from 'react-router-dom';
import Crud_Service from '../../../apis/CrudService.jsx';
import { toast } from 'react-toastify';
import useDateFormatter from '../../../components/helpers/useDateFormatter.jsx';
import {
  EstimationStatus,
  StatusBadge,
  TextColor,
} from '../../../components/_form/FormElements.jsx';
import { useTranslation } from 'react-i18next';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import useTableLogic from '../../../components/helpers/UseTableLogic.tsx';
import TableSearch from '../../../components/_form/TableSearch.jsx';
import DataTableToolbarButtons from '../../../components/helpers/DataTableToolbarButtons.tsx';
import apiInstance from '../../../apis/ApiService.jsx';
import TableActionButtons from '../../../components/_form/TableActionButtons.jsx';
import Icons from '../../../utils/Icon.jsx';

const EstimationSheetForAMC = () => {
  const navigate = useNavigate();
  const [selectedRow, setSelectedRow] = useState();
  const [assetView, setAssetView] = useState(false);
  const formateDate = useDateFormatter();
  const {
    rows,
    pageSize,
    pageNumber,
    setSearchKeyword,
    isLoading,
    tableRecordCounts,
    searchKeyword,
    handlePaginationModelChange,
    handleSortModelChange,
    fetchData,
  } = useTableLogic('estimationamcsheets');
  const crud = new Crud_Service();
  const { t } = useTranslation();
  useEffect(() => {
    document.title = `Presales | Estimation Sheet For AMC`;
  }, []);

  // View Details
  const handleViewClick = async (props) => {
    setSelectedRow(props);
    setAssetView(true);
  };

  //Navigate Edit Page
  const handleEditClick = async (props) => {
    navigate(`edit/${props?.estimationAmcSheetId}`);
  };

  const handledeleteClick = async (props) => {
    await crud.remove(
      `estimationamcsheets`,
      props?.estimationAmcSheetId,
      (err, res) => {
        if (err) {
          toast.error(err);
          return;
        }
        if (res?.status === 204) {
          toast.success('AMC Sheet Deleted Successfully');
          fetchData();
        }
      }
    );
  };

  const ActionData = [
    {
      name: 'View Details',
      icon: Icons.view,
      onClick: (props) => handleViewClick(props),
    },
    {
      name: t('edit'),
      icon: Icons.edit,
      onClick: (props) => handleEditClick(props),
    },
    {
      name: 'Delete',
      icon: Icons.delete,
      onClick: (props) => handledeleteClick(props),
      danger: true,
    },
  ];

  const columns = [
    {
      field: 'action',
      headerName: '',
      width: 130,
      sortable: false,
      hideable: false,
      disableClickEventBubbling: false,
      renderCell: (params) => {
        return (
          <TableActionButtons
            Actions={ActionData?.map((action) => ({
              ...action,
              onClick: () => action.onClick(params.row),
            }))}
            selectedRow={params.row}
            setSelectedRow={setSelectedRow}
          />
        );
      },
    },
    {
      field: 'tenderName',
      headerName: 'Tender',
      minWidth: 320,
    },
    {
      field: 'projectName',
      headerName: 'Project',
      minWidth: 250,
      hideable: false,
    },
    {
      field: 'clientName',
      headerName: 'Client',
      minWidth: 200,
    },
    {
      field: 'buildingName',
      headerName: 'Building',
      minWidth: 200,
    },
    {
      field: 'dateOfEnquiry',
      headerName: 'Date of Enquiry',
      minWidth: 200,
      renderCell: (params) => {
        return formateDate(params.value);
      },
    },
    //For Future Use
    // {
    //   field: 'tenderNo',
    //   headerName: 'Tender No',
    //   flex: 1,
    //   width: 220,
    // },

    // {
    //   field: 'tenderSubmissionDate',
    //   headerName: 'Tender Submission Date',
    //   flex: 1,
    //   renderCell: (params) => {
    //     return formateDate(params.value);
    //   },
    // },
    {
      field: 'contractPeriod',
      headerName: 'Contract Period',
      minWidth: 200,
    },
    {
      field: 'otherPercent',
      headerName: 'Other (%)',
      minWidth: 200,
    },
    {
      field: 'discountOverAllPercent',
      headerName: 'Discount (%)',
      minWidth: 200,
    },
    {
      field: 'existContract',
      headerName: 'Exist Contract',
      minWidth: 200,
    },
    {
      field: 'sitVisitDoneByName',
      headerName: 'SitVisitDone By Name',
      minWidth: 200,
    },
    {
      field: 'bidBond',
      headerName: 'Tender Bond/Bidbond Required',
      minWidth: 300,
      renderCell: (params) => {
        return (
          <TextColor
            title={params.value === true ? 'Yes' : 'No'}
            color={params.value === true ? 'green' : 'red'}
          />
        );
      },
    },
    {
      field: 'bidBondValue',
      headerName: 'Fixed Tender Bond/Bidbond Value',
      minWidth: 300,
    },
    {
      field: 'sitVisitDoneByName',
      headerName: 'Site Visit Done By',
      minWidth: 200,
    },
    {
      field: 'preparedByName',
      headerName: 'Prepared By',
      minWidth: 300,
    },
    {
      field: 'status',
      headerName: 'Estimation Status',
      minWidth: 130,
      renderCell: (params) => {
        const statusId = params?.row?.estimationStatusId;
        const statusName = params?.row?.estimationStatusName;
        const statusColor =
          statusId === 3
            ? 'red'
            : statusId === 2
            ? 'green'
            : statusId === 1
            ? 'blue'
            : ''; // Example logic

        return <EstimationStatus name={statusName} color={statusColor} />;
      },
    },
    {
      field: 'createdDate',
      headerName: 'Created Date',
      minWidth: 200,
      renderCell: (params) => {
        return formateDate(params.value);
      },
    },
    {
      field: 'updatedDate',
      headerName: 'Updated Date',
      minWidth: 200,
      renderCell: (params) => {
        return formateDate(params.value);
      },
    },
  ];

  const onRowDoubleClick = (e) => {
    if (e?.row) {
      handleEditClick(e?.row);
    }
  };

  const downloadAmc = async () => {
    const downloadUrl = await apiInstance.getFiles(
      'estimationamcsheets/exportexcel'
    );

    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', 'estimationAMC.xlsx');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Table data changes

  return (
    <DashboardLayout
      title={assetView ? false : 'Estimation Sheet for AMC'}
      actionButtons={
        <CreateButton name='New' onClick={() => navigate('create')} />
      }
    >
      {!assetView && (
        <GlassCard className='overflow-hidden h-full-css'>
          <Box className='h-full-css'>
            <DataTable
              rows={rows}
              columns={columns}
              loading={isLoading}
              onRowDoubleClick={(e) => onRowDoubleClick(e)}
              sortingMode='server'
              paginationMode='server'
              onPaginationModelChange={(model) =>
                handlePaginationModelChange(model)
              }
              onSortModelChange={(model) => handleSortModelChange(model)}
              page={pageNumber - 1}
              pageSize={pageSize}
              rowCount={tableRecordCounts?.total}
              slots={{
                toolbar: () => (
                  <Box
                    className='p-2 w-full flex justify-between items-center'
                    sx={{
                      borderBottom: '1px solid',
                      borderColor: 'border.main',
                    }}
                  >
                    <Box sx={{ maxWidth: '350px', width: '100%' }}>
                      <TableSearch
                        placeholder='Search'
                        fullWidth
                        setSearchKeyword={setSearchKeyword}
                        searchValue={searchKeyword}
                      />
                    </Box>
                    <DataTableToolbarButtons handleExcelExport={downloadAmc} />
                  </Box>
                ),
              }}
            />
          </Box>
        </GlassCard>
      )}
      {assetView && (
        <ViewDetails
          handleBackButton={() => setAssetView(!assetView)}
          TableData={selectedRow}
        />
      )}
    </DashboardLayout>
  );
};

export default EstimationSheetForAMC;
