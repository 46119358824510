import React from 'react';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import { useNavigate, useParams } from 'react-router-dom';
import CommonView from '../../../../components/helpers/CommonView.jsx';
import DynamicViewFields from '../../../../components/helpers/DynamicViewFields.jsx';
import { CreateButton } from '../../../../components/small/Buttons.jsx';
import ActionButtons from '../../../../components/_form/ActionButtons.tsx';

const ViewMonthUpdate = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const excludeKeys = [
    'contactPerson',
    'divisionId',
    'hiredVehicleId',
    'locationId',
    'ownVehicleId',
    'projectId',
    'siteId',
    'vehicleMonthlyUpdateId',
    'createdDate',
    'updatedDate',
    'statusName',
    'ownVehiclePlateNo',
    'ownVehicleTypeName',
    'hiredVehiclePlateNo',
    'hiredVehicleTypeName',
    'status',
  ];

  return (
    <DashboardLayout
      title='View Monthly Update'
      actionButtons={
        <CreateButton
          name='Create'
          onClick={() => navigate('/admin/vehicles/month-updates/create')}
        />
      }
    >
      <GlassCard className='p-5'>
        <CommonView
          url='vehiclemonthlyupdates'
          id={id}
          excludeKeys={excludeKeys}
          renderFields={(data, fields) => (
            <DynamicViewFields data={data} fields={fields} />
          )}
        />
        <ActionButtons
          cancelLoading={false}
          cancelText='Cancel'
          submitText='Edit'
          onSubmit={() => navigate(`/admin/vehicles/month-updates/edit/${id}`)}
          onCancel={() => navigate('/admin/vehicles/month-updates')}
        />
      </GlassCard>
    </DashboardLayout>
  );
};

export default ViewMonthUpdate;
