// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fm-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #cfcfcf;
  padding: 10px 20px;
}
.fm-modal-header .fm-modal-heading {
  margin: 0;
  font-weight: bold;
  color: black;
}

.dialog[open] {
  animation: expand 0.4s forwards;
}
.dialog[open]::backdrop {
  background: rgba(0, 0, 0, 0.5);
}

@keyframes expand {
  from {
    transform: scale(0.4);
  }
  to {
    transform: scale(1);
  }
}`, "",{"version":3,"sources":["webpack://./src/lib/FileManager/components/Modal/Modal.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,gCAAA;EACA,kBAAA;AADF;AAGE;EACE,SAAA;EACA,iBAAA;EACA,YAAA;AADJ;;AAKA;EACE,+BAAA;AAFF;AAIE;EACE,8BAAA;AAFJ;;AAMA;EACE;IACE,qBAAA;EAHF;EAMA;IACE,mBAAA;EAJF;AACF","sourcesContent":["@import \"../../styles/variables\";\n\n.fm-modal-header {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  border-bottom: 1px solid $border-color;\n  padding: 10px 20px;\n\n  .fm-modal-heading {\n    margin: 0;\n    font-weight: bold;\n    color: black;\n  }\n}\n\n.dialog[open] {\n  animation: expand 0.4s forwards;\n\n  &::backdrop {\n    background: rgba(0, 0, 0, 0.5);\n  }\n}\n\n@keyframes expand {\n  from {\n    transform: scale(0.4);\n  }\n\n  to {\n    transform: scale(1);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
