import { Box, Tooltip } from '@mui/material';
import React from 'react';
import { ViewTable } from '../_form/FormElements.jsx';

const TableExtraContentTooltip = ({ children, titleRows, dark }) => {
  const TitleContent = () => {
    return (
      <Box className='min-w-48 p-2 flex flex-col gap-1 min-h-4'>
        <ViewTable disableBorder paddingLow right rows={titleRows} />
      </Box>
    );
  };

  return (
    <Tooltip
      title={<TitleContent />}
      placement='left'
      arrow
      classes={{
        tooltip: 'px-0 py-1 rounded-lg',
        arrow: dark ? 'text.dark' : 'text-white',
      }}
      slotProps={{
        tooltip: {
          sx: {
            background: dark ? 'text.dark' : '#fff',
            boxShadow: dark ? 0 : 8,
            color: dark ? '#fff' : 'text.main',
          },
        },
      }}
    >
      <Box className='w-full'>{children}</Box>
    </Tooltip>
  );
};

export default TableExtraContentTooltip;
