import { Box, Grid } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Crud_Service from '../../../apis/CrudService.jsx';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import DataTable from '../../../components/DataTable.tsx';
import ActionButtons from '../../../components/_form/ActionButtons.tsx';
import { StatusBadge } from '../../../components/_form/FormElements.jsx';
import TableActionButtons from '../../../components/_form/TableActionButtons.jsx';
import TableAvatar from '../../../components/_form/TableAvatar.jsx';
import TableSearch from '../../../components/_form/TableSearch.jsx';
import FmViewDateField from '../../../components/_mui/FmViewDateField.tsx';
import MuiDialogOne from '../../../components/_mui/MuiDialogOne.jsx';
import { ToolbarBoxesFlex } from '../../../components/boxes/Boxes.jsx';
import CommonView from '../../../components/helpers/CommonView.jsx';
import DataTableToolbarButtons from '../../../components/helpers/DataTableToolbarButtons.tsx';
import DynamicViewFields from '../../../components/helpers/DynamicViewFields.jsx';
import CommonLoader from '../../../components/page/CommonLoader.jsx';
import { CreateButton } from '../../../components/small/Buttons.jsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import Icons from '../../../utils/Icon.jsx';
import { findNavByName } from '../../../utils/navUtils.ts';

const EIDApplication = () => {
  const navigate = useNavigate();
  const crud = new Crud_Service();
  const [rows, setRows] = useState([]);
  const [openView, setOpenView] = useState(false);
  const [viewData, setViewData] = useState([]);
  const [viewLoading, setViewLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tablerecordCounts, setTableRecordCounts] = useState(null);
  const [calculation, setCalculation] = useState<any>({});
  const [filteredRows, setFilteredRows] = useState<any>([]);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [sortDirection, setSortDirection] = useState('');
  const [total, setTotal] = useState(0);
  const excludeKeys = [
    'uidNo',
    'cadre',
    'deployedSite',
    'eidApplicationCode',
    'employeeCode',
    'departmentName',
    'sponsorId',
    'employeeId',
    'eidApplicationId',
    'collectedBy',
    'eidApplicationStatusId',
    'updatedDate',
    'status',
  ];

  const fetchData = async () => {
    try {
      await crud.getAll(
        'eidapplications',
        { pageSize, pageNumber, searchKeyword, sortBy, sortDirection },
        (err, res) => {
          if (res?.status === 200) {
            setTableRecordCounts(res?.data);
            setFilteredRows(res.data.eidApplications);
            setCalculation(res.data.eidApplicationStatusCount);
            const transformedData = res?.data?.eidApplications?.data?.map(
              (row) => ({
                ...row,
                id: row?.eidApplicationId,
              })
            );
            setRows(transformedData);
            setTotal(res?.data?.eidApplications?.total);
          }
          toggleLoading(false);
        }
      );
    } catch (error) {
      toast.error('Error fetching data:', error);
    }
  };
  const toggleLoading = (loading) => {
    setIsLoading(loading);
  };
  const handleViewClickOpen = async (view) => {
    setOpenView(true);
    setViewLoading(true);
    await crud.getSingle(
      'eidapplications',
      view?.eidApplicationId,
      (_err, res) => {
        if (res?.status === 200) {
          setViewData(res?.data);
          setViewLoading(false);
          setViewData(view);
        } else {
          setViewLoading(false);
        }
      }
    );
  };
  const handleViewClose = () => {
    setOpenView(false);
  };
  const handleEditClick = async (view) => {
    navigate(`edit/${view?.eidApplicationId}`);
  };
  const onRowDoubleClick = (e) => {
    if (e?.row) {
      handleEditClick(e?.row);
    }
  };

  const handleDeleteClick = async (view) => {
    await crud.remove(
      'eidapplications',
      view?.eidApplicationId,
      (_err, res) => {
        if (res?.status === 204) {
          toast.success('Eid Deleted Successfully');
          fetchData();
        }
      }
    );
  };

  const handlePagination = (model) => {
    setPageSize(model?.pageSize);
    setPageNumber(model?.page + 1);
  };
  const handleSortChange = (sortModel) => {
    if (sortModel.length > 0) {
      setSortBy(sortModel[0].field);
      setSortDirection(sortModel[0].sort);
    } else {
      setSortBy('');
      setSortDirection('');
    }
  };

  useEffect(() => {
    fetchData();
  }, [pageNumber, pageSize, searchKeyword]);

  const ActionData = [
    {
      name: 'view',
      icon: Icons.view,
      onClick: (props) => handleViewClickOpen(props),
    },
    {
      name: 'Edit',
      icon: Icons.edit,
      onClick: (props) => handleEditClick(props),
    },
    {
      name: 'Delete',
      icon: Icons.delete,
      onClick: (props) => handleDeleteClick(props),
      danger: true,
    },
  ];

  const columns = [
    {
      field: 'action',
      headerName: '',
      width: 100,
      sortable: false,
      hideable: false,
      disableClickEventBubbling: false,
      renderCell: (params) => {
        return (
          <TableActionButtons
            Actions={ActionData?.map((action) => ({
              ...action,
              onClick: () => action.onClick(params.row),
            }))}
            selectedRow={params.row}
            setSelectedRow={() => {}}
          />
        );
      },
    },
    {
      field: 'employeeName',
      headerName: 'Employee Name',
      width: 250,
      hideable: false,
      renderCell: (params) => {
        return (
          <TableAvatar
            name={params?.value}
            desc={`EID : ${params?.row?.eidNo}`}
          />
        );
      },
    },

    {
      field: 'eidApplicationNo',
      headerName: 'Eid Application No / Date',
      width: 200,
      renderCell: (params) => {
        return (
          <TableAvatar
            name={params?.value}
            desc={FmViewDateField({ value: params?.row?.eidApplicationDate })}
            removeImage
          />
        );
      },
    },

    {
      field: 'sponsorName',
      headerName: 'Sponsor Name',
      width: 220,
    },
    {
      field: 'eidApplicationStatusName',
      headerName: 'Eid Status',
      width: 160,
      renderCell: (params) => {
        return <StatusBadge title={params?.value} type={'teal'} />;
      },
    },
    {
      field: 'validUntil',
      headerName: 'Valid Until',
      width: 160,
      renderCell: (params) => {
        return FmViewDateField({ value: params?.value });
      },
    },
    {
      field: 'collectedByUserName',
      headerName: 'Collected By',
      width: 260,
      renderCell: (params) => {
        return <TableAvatar name={(params?.value || '').toLowerCase()} />;
      },
    },
    {
      field: 'collectedDate',
      headerName: 'Collected Date',
      width: 160,
      renderCell: (params) => {
        return FmViewDateField({ value: params?.value });
      },
    },
  ];

  const SearchFilteredBoxes = () => {
    return (
      <ToolbarBoxesFlex>
        <Box className='w-full' sx={{ maxWidth: '280px' }}>
          <TableSearch
            placeholder='Search'
            setSearchKeyword={setSearchKeyword}
            searchValue={searchKeyword}
          />
        </Box>
        <DataTableToolbarButtons />
      </ToolbarBoxesFlex>
    );
  };
  const menu = useSelector((state) => state?.menu?.items);

  const SubMenu = useMemo(() => {
    return menu?.map((item) => item?.menus?.flat()).flat() || [];
  }, [menu]);

  const result = useMemo(
    () => findNavByName(SubMenu, 'PR', 'Employee'),
    [SubMenu]
  );

  return (
    <DashboardLayout
      title='EID Tracker'
      hasSubmenu
      menu={result}
      actionButtons={
        <CreateButton onClick={() => navigate('create')} name='New' />
      }
    >
      <GlassCard className='overflow-hidden h-full'>
        <Box className='h-full'>
          <DataTable
            rows={rows || []}
            columns={columns}
            loading={isLoading}
            onRowDoubleClick={(e) => onRowDoubleClick(e)}
            sortingMode='server'
            paginationMode='server'
            onPaginationModelChange={(model) => handlePagination(model)}
            onSortModelChange={(model) => handleSortChange(model)}
            page={pageNumber - 1}
            pageSize={pageSize}
            rowCount={total}
            getRowId={(row) => row?.id}
            slots={{
              toolbar: () => (
                <Box>
                  <SearchFilteredBoxes />
                  {/* TODO: In Future IF We Need to Enable THis BUtton */}
                  {/* <Button onClick={handleAddRow}>Add New Row</Button> */}
                </Box>
              ),
            }}
          />
        </Box>
      </GlassCard>
      <MuiDialogOne
        title='View Details'
        open={openView}
        onClose={handleViewClose}
        width='900px'
      >
        {viewLoading ? (
          <CommonLoader />
        ) : (
          <>
            <Grid className='p-5'>
              <CommonView
                url='eidapplications'
                id={viewData?.eidApplicationId}
                excludeKeys={excludeKeys}
                renderFields={(data, fields) => (
                  <DynamicViewFields
                    data={data}
                    fields={fields}
                    gridValue={4}
                  />
                )}
              />
              <ActionButtons
                cancelLoading={false}
                cancelText='Go Back'
                submitText='Edit'
                onSubmit={() =>
                  navigate(
                    `/pr/employee/eid/edit/${viewData?.eidApplicationId}`
                  )
                }
                onCancel={() => setOpenView(false)}
              />
            </Grid>
          </>
        )}
      </MuiDialogOne>
    </DashboardLayout>
  );
};

export default EIDApplication;
