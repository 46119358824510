// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fm-loader {
  z-index: 3;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: -webkit-fill-available;
  width: -webkit-fill-available;
  background-color: rgba(255, 255, 255, 0.542);
}`, "",{"version":3,"sources":["webpack://./src/lib/FileManager/components/Loader/Loader.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,kBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,8BAAA;EACA,6BAAA;EACA,4CAAA;AACF","sourcesContent":[".fm-loader {\n  z-index: 3;\n  position: absolute;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: -webkit-fill-available;\n  width: -webkit-fill-available;\n  background-color: rgba(255, 255, 255, 0.542);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
