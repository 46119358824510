import { yupResolver } from '@hookform/resolvers/yup';
import { Icon } from '@iconify/react';
import { Box, Button } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import Crud_Service from '../../../../../apis/CrudService.jsx';
import TableActionButtons from '../../../../../components/_form/TableActionButtons.jsx';
import FmSearchableSelect from '../../../../../components/_mui/FmSearchableSelect.tsx';
import FmTextField from '../../../../../components/_mui/FmTextField.tsx';
import FmMRTDataTable from '../../../../../components/FmMRTDataTable.tsx';
import useTableLogic from '../../../../../components/helpers/MRTUseTableLogic.tsx';
import useLoader from '../../../../../components/helpers/UseLoader.tsx';
import GlassCard from '../../../../../components/small/GlassCard.tsx';
import MRTExpandTableStyle from '../../../../../components/table/MRTExpandTableStyle.tsx';
import { FTItemsSchema } from '../../../../../utils/CommonvalidationSchemas.tsx';
import MuiDialogOne from '../../../../../components/_mui/MuiDialogOne.jsx';
import apiInstance from '../../../../../apis/ApiService.jsx';
import { useParams } from 'react-router-dom';
import FmFileInput from '../../../../../components/_mui/FmFileInput.tsx';
import { useHotkeys } from 'react-hotkeys-hook';
import useCommonFetchApi from '../../../../../components/helpers/useCommonFetchApi.tsx';

const ViewCashBankReceiptList = (props) => {
  const { financialTransactionId } = props;
  const [editingRowId, setEditingRowId] = useState(null);
  const [editingRowData, setEditingRowData] = useState(null);
  const { createLoading, startCreateLoading, stopCreateLoading } = useLoader();
  const { control, setValue, reset, getValues, watch, formState: { isValid } } = useForm({
    resolver: yupResolver(FTItemsSchema),
    mode: 'all',
  });
  const id = useParams()?.id
  const [openUpload, setOpenUpload] = useState(false);
  const [filesData, setFilesData] = useState(null);
  const crud = new Crud_Service();
  const firstColumnInputRef = useRef(null);
  // const secondFieldInputRef = useRef(null);
  // const thirdFieldInputRef = useRef(null);
  const [loader, setLoader] = useState(false);
  const [editingRowIndex, setEditingRowIndex] = useState(null);
  const [stateError, setStateError] = useState(false);

  const [rowState, setRowState] = useState({
    editingRowId: null,
    newRowId: null,
    isCreatingRow: false,
  });
  const {
    rows,
    setRows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic('financialtransactionitems', 'financialTransactionId', financialTransactionId);
  const handleAddNewRow = () => {
    const newId = 'new_row_' + Date.now();
    reset();
    setEditingRowData(null);
    setEditingRowId(null);
    setRowState({
      newRowId: newId,
      isCreatingRow: true,
    });
    setEditingRowIndex(
      editingRowIndex === rows.length ? editingRowIndex + 1 : rows.length
    );
    setRows((prevRows) => [
      ...prevRows,
      {
        financialTransactionItemId: newId,
        financialTransactionId: financialTransactionId,
        salesOrderId: '',
        contractId: '',
        clientId: '',
        workOrderId: '',
        divisionId: '',
        employeeId: '',
        inchargeId: '',
        fixedAsset: '',
        amount: '',
        reference: '',
        isEditable: true,
        isNew: true,
      },
    ]);
    reset();
  };
  const handleBlurAndFetch = async () => {
    setLoader(true);
    // const updatedRow = getValues(); // Get the latest values from the form
    // const updatedRows = [...rows]; // Clone the rows[0] to avoid direct mutation

    // // Update the specific row in the cloned array
    // updatedRows[editingRowIndex] = updatedRow;

    // setRows(updatedRows);

    const values = getValues();

    const updateCombinedData = {
      ...values,
      financialTransactionId: financialTransactionId,
      status: 2,
    };
    await crud.update(
      'financialtransactionitems',
      editingRowId,
      updateCombinedData,
      (err, res) => {
        if (res?.status === 200) {
          const totalRows = rows.length;
          if (editingRowIndex <= totalRows && rows.some((row) => row.isNew)) {
            const updatedRow = res?.data;
            const updatedRows = [...rows];
            updatedRows[editingRowIndex] = {
              ...updatedRow,
              isEditable: true,
              isNew: true,
            };
            setRows(updatedRows);
            handleAddNewRow();
          } else {
            const updatedRow = res?.data;
            const updatedRows = [...rows];
            updatedRows[editingRowIndex] = {
              ...updatedRow,
              isEditable: false,
              isNew: false,
            };

            setRows(updatedRows);
            setStateError(false);
            setLoader(false);
          }
        } else {
          setStateError(true);
        }
      }
    );
  };
  const handleCancel = (row) => {
    if (row?.original?.financialTransactionItemId === rowState?.newRowId) {
      setRows((prevRows) => {
        if (prevRows?.length > 0) {
          return prevRows?.slice(0, -1);
        }
        return prevRows;
      });

      setRowState((prevState) => ({
        ...prevState,
        newRowId: null,
        isCreatingRow: false,
      }));
    } else {
      setEditingRowId(null);
    }
    reset();
  };
  const handleEdit = (row) => {
    reset();
    if (rowState?.newRowId) {
      setRows((prevRows) => {
        if (prevRows?.length > 0) {
          return prevRows?.slice(0, -1);
        }
        return prevRows;
      });
      setRowState((prevState) => ({
        ...prevState,
        newRowId: null,
        isCreatingRow: false,
      }));
      // setEditingRowIndex(null);
    }
    setEditingRowId(row?.financialTransactionItemId);
    Object.entries({
      ...row,
    }).forEach(([key, value]) => {
      setValue(key, value);
    });
    setEditingRowData({ ...row });
  };
  useHotkeys(
    'Enter',
    () => handleAddNewRow(),
    {
      enableOnTags: ['INPUT', 'TEXTAREA'], // Optional: Enable hotkey only when focused on specific tags
    },
    [editingRowIndex, rows, stateError, loader]
  );

  useHotkeys(
    'Tab',
    (event) => {
      if (event.key !== 'Tab') return;
      if (stateError) {
        event.preventDefault();
        return;
      }
      const totalRows = rows.length;
      const nextRowIndex = editingRowIndex + 1;

      if (
        nextRowIndex < totalRows &&
        stateError === false &&
        loader === false
      ) {
        handleEdit(rows[nextRowIndex]);
        setEditingRowIndex(nextRowIndex);
      }
    },
    [editingRowIndex, rows, stateError, loader, isValid]
  );

  useEffect(() => {
    if (editingRowIndex !== null && firstColumnInputRef.current && !isLoading) {
      firstColumnInputRef.current.focus();
    }
  }, [editingRowIndex, isLoading]);

  const previousValues = useRef(getValues());

  const handleCancelAndAddNewRow = () => {
    const newId = 'new_row_' + Date.now();
    reset();
    setEditingRowData(null);
    setEditingRowId(null);

    setRowState({
      newRowId: newId,
      isCreatingRow: true,
    });
    setEditingRowIndex(
      editingRowIndex === rows?.length ? editingRowIndex + 1 : rows?.length
    );
    setRows((prevRows) => [
      ...prevRows,
      {
        financialTransactionItemId: newId,
        financialTransactionId: financialTransactionId,
        salesOrderId: '',
        contractId: '',
        clientId: '',
        workOrderId: '',
        divisionId: '',
        employeeId: '',
        inchargeId: '',
        fixedAsset: '',
        amount: '',
        reference: '',
        isEditable: true,
        isNew: true,
      },
    ]);
    reset();
  };
  const handleBlur = () => {
    const currentValues = getValues();
    const salesOrderId = getValues('salesOrderId');
    const contractId = getValues('contractId');
    const clientId = getValues('clientId');

    if (!salesOrderId) {
      firstColumnInputRef.current.focus();
      return;
    }
    // if (!contractId) {
    //   secondFieldInputRef.current.focus();
    //   return;
    // }
    // if (!clientId) {
    //   thirdFieldInputRef.current.focus();
    //   return;
    // }


    // Compare the previous values with the current values
    const hasChanges = Object.keys(currentValues).some(
      (key) => currentValues[key] !== previousValues.current[key]
    );

    if ((hasChanges && isValid) || (hasChanges && editingRowId)) {
      apiCallAsync(currentValues); // Call the API with the new values
      previousValues.current = currentValues; // Update previous values
    }
  };
  const apiCallAsync = async (values) => {
    setLoader(true);
    const convertedValues = {
      ...values,
      financialTransactionId: financialTransactionId,
    };

    const updatedCombinedData = {
      ...values,
      financialTransactionId: financialTransactionId,
      status: 2,
    };

    if (editingRowId === null) {
      await crud.create('financialtransactionitems', convertedValues, (err, res) => {
        if (res?.status === 201) {
          setEditingRowId(res?.data?.financialTransactionItemId);
          // setValue('financialTransactionItemId', res?.data?.financialTransactionItemId);
        } else {
        }
      });
    } else {
      await crud.update(
        'financialtransactionitems',
        editingRowId,
        updatedCombinedData,
        (err, res) => {
          if (res?.status === 200) {
            if (rows.some((row) => row.isNew)) {
              const updatedRow = res?.data;
              const updatedRows = [...rows];
              updatedRows[editingRowIndex] = {
                ...updatedRow,
                isEditable: true,
                isNew: true,
              };
              setRows(updatedRows);
              setStateError(false);
              setLoader(false);
            } else {
              setStateError(false);
              setLoader(false);
              const updatedRow = res?.data;
              const updatedRows = [...rows];

              updatedRows[editingRowIndex] = updatedRow;

              setRows(updatedRows);
            }
          } else {
            setStateError(true);
          }
        }
      );
    }
  };



  // const handleEdit = (row) => {
  //   setEditingRowId(row?.original?.financialTransactionItemId);
  //   setEditingRowData({ ...row?.original });
  // };

  // const handleCancel = () => {
  //   setEditingRowId(null);
  //   setEditingRowData(null);
  // };

  const handleDelete = async (props) => {
    await crud.remove(
      'financialtransactionitems',
      props?.financialTransactionItemId,
      (__err, res) => {
        if (res?.status === 204) {
          toast.success('Deleted Successfully');
          fetchData();
        } else {
        }
      }
    );
  };

  const { data: salesorders } = useCommonFetchApi('salesorders');
  const selectedSalesId = watch('salesOrderId');

  useEffect(() => {
    const selectedEmpDetail = salesorders.find(
      (sales) => sales?.salesOrderId === selectedSalesId
    );
    if (selectedEmpDetail) {
      setValue('contractId', selectedEmpDetail.contractId);
      setValue('workOrderId', selectedEmpDetail.workOrderId);
      setValue('divisionId', selectedEmpDetail.divisionId);
      setValue('clientId', selectedEmpDetail.clientId);
      setValue('inchargeId', selectedEmpDetail.projectInChargeId);
    }
  }, [selectedSalesId, setValue]);

  // const handleUpdateEvent = async (values) => {
  //   try {
  //     // Start loading indicator
  //     startEditLoading();
  //     // Prepare the data to be updated
  //     const combinedData = {
  //       ...values,

  //       Status: 2,
  //     };
  //     await crud.update(
  //       'financialtransactionitems',
  //       editingRowId,
  //       combinedData,
  //       (_err, res) => {
  //         if (res?.status === 200) {
  //           stopEditLoading();
  //           fetchData();
  //           toast.success('Updated Successfully');
  //           setEditingRowData(null);
  //           setEditingRowId(null);
  //         } else {
  //           stopEditLoading();
  //           toast.error('Failed to update');
  //         }
  //       }
  //     );
  //   } catch (error) {
  //     stopEditLoading();
  //     toast.error('An error occurred while updating');
  //   }
  // };

  const ActionData = [
    // {
    //   name: 'Edit',
    //   icon: <Icon icon='solar:pen-2-broken' />,
    //   onClick: (props) => {
    //     handleEdit(props);
    //   },
    // },
    {
      name: 'Delete',
      icon: <Icon icon='solar:trash-bin-minimalistic-broken' />,
      onClick: (props) => handleDelete(props),

    },
  ];
  const columns = [
    // {
    //   field: 'action',
    //   header: 'Actions',
    //   sortable: false,
    //   hideable: false,
    //   disableClickEventBubbling: false,

    //   Cell: (params) => {
    //     return (
    //       <Box className='flex gap-2'>
    //         {editingRowId ===
    //           params?.row?.original?.financialTransactionItemId ? (
    //           <>
    //             <Button onClick={handleSubmit(handleUpdateEvent)}>Save</Button>
    //             <Button onClick={handleCancel}>Cancel</Button>
    //           </>
    //         ) : (
    //           <TableActionButtons
    //             Actions={ActionData?.map((action) => ({
    //               ...action,
    //               onClick: () => action.onClick(params.row),
    //             }))}
    //             selectedRow={params.row}
    //             setSelectedRow={() => { }}
    //           />
    //         )}
    //       </Box>
    //     );
    //   },
    // },
    {
      accessorKey: 'salesOrderId',
      header: 'Sales Order Code',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow = row?.original?.financialTransactionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.financialTransactionItemId || isNewRow;
        return isEditing ? (
          <FmSearchableSelect
            name='salesOrderId'
            control={control}
            apiUrl='salesorders'
            valueField='salesOrderId'
            headerField={['Code']}
            labelField={['salesOrderName']}
            showField={['salesOrderName']}
            label='Sales Order Code'
            onBlur={handleBlur}
            inputRef={firstColumnInputRef}
            defaultValue={{
              salesOrderId: editingRowData?.salesOrderId,
              salesOrderName: editingRowData?.salesOrderName,
            }}
          />
        ) : (
          row?.original?.salesOrderName || ''
        );
      },
    },
    {
      accessorKey: 'contractId',
      header: 'Contract',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow = row?.original?.financialTransactionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.financialTransactionItemId || isNewRow;
        return isEditing ? (
          <FmSearchableSelect
            name='contractId'
            control={control}
            apiUrl='contracts'
            valueField='contractId'
            labelField={['contractCode', 'contractName']}
            showField={['contractCode', 'contractName']}
            label={'Contract'}
            onBlur={handleBlur}
            // inputRef={secondFieldInputRef}
            defaultValue={{
              contractId: editingRowData?.contractId,
              contractName: editingRowData?.contractName,
            }}
          />
        ) : (
          row?.original?.contractName || ''
        );
      },
    },
    {
      accessorKey: 'clientId',
      header: 'Client',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow = row?.original?.financialTransactionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.financialTransactionItemId || isNewRow;
        return isEditing ? (
          <FmSearchableSelect
            name='clientId'
            control={control}
            apiUrl='clients'
            valueField='clientId'
            headerField={['Client Code', 'Client Name']}
            labelField={['clientCode', 'clientName']}
            showField={['clientName']}
            label='Client'
            onBlur={handleBlur}
            // inputRef={thirdFieldInputRef}
            defaultValue={{
              clientId: editingRowData?.clientId,
              clientName: editingRowData?.clientName,
            }}
          />
        ) : (
          row?.original?.clientName || ''
        );
      },
    },
    {
      accessorKey: 'workOrderId',
      header: 'Work Order',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow = row?.original?.financialTransactionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.financialTransactionItemId || isNewRow;

        return isEditing ? (
          <FmSearchableSelect
            name='workOrderId'
            control={control}
            apiUrl='workorders'
            valueField='workOrderId'
            labelField={['workOrderName', 'workOrderCode']}
            showField={['workOrderName', 'workOrderCode']}
            label='Work Order'
            onBlur={handleBlur}
            defaultValue={{
              workOrderId: editingRowData?.workOrderId,
              workOrderName: editingRowData?.workOrderName,
            }}
          />
        ) : (
          row?.original?.workOrderName || ''
        );
      },
    },
    {
      accessorKey: 'divisionId',
      header: 'Division',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow = row?.original?.financialTransactionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.financialTransactionItemId || isNewRow;

        return isEditing ? (
          <FmSearchableSelect
            name='divisionId'
            control={control}
            apiUrl='divisions'
            valueField='divisionId'
            labelField={['divisionName']}
            showField={['divisionName']}
            label='Division'
            onBlur={handleBlur}
            defaultValue={{
              divisionId: editingRowData?.divisionId,
              divisionName: editingRowData?.divisionName,
            }}
          />
        ) : (
          row?.original?.divisionName || ''
        );
      },
    },
    {
      accessorKey: 'employeeId',
      header: 'Employee',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow = row?.original?.financialTransactionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.financialTransactionItemId || isNewRow;

        return isEditing ? (
          <FmSearchableSelect
            name='employeeId'
            control={control}
            apiUrl='employees'
            valueField='employeeId'
            labelField={['firstName']}
            showField={['firstName']}
            label='Employees'
            onBlur={handleBlur}
            defaultValue={{
              employeeId: editingRowData?.employeeId,
              firstName: editingRowData?.employeeName,
            }}
          />
        ) : (
          row?.original?.employeeName || ''
        );
      },
    },

    {
      accessorKey: 'inchargeId',
      header: 'In Charge',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow = row?.original?.financialTransactionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.financialTransactionItemId || isNewRow;

        return isEditing ? (
          <FmSearchableSelect
            name='inchargeId'
            control={control}
            apiUrl='loginusers'
            valueField='loginUserId'
            headerField={['Name']}
            labelField={['loginUserName']}
            showField={['loginUserName']}
            label='In Charge'
            onBlur={handleBlur}
            defaultValue={{
              loginUserId: editingRowData?.inchargeId,
              loginUserName: editingRowData?.inchargeName,
            }}
          />
        ) : (
          row?.original?.inchargeName || ''
        );
      },
    },
    {
      accessorKey: 'fixedAsset',
      header: 'Fixed Asset',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow = row?.original?.financialTransactionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.financialTransactionItemId || isNewRow;

        return isEditing ? (
          <FmTextField
            name='fixedAsset'
            control={control}
            label='Fixed Asset'
            onBlur={handleBlur}
            defaultValue={row?.original?.fixedAsset}
          />
        ) : (
          row?.original?.fixedAsset || ''
        );
      },
    },
    {
      accessorKey: 'amount',
      header: 'Amount',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow = row?.original?.financialTransactionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.financialTransactionItemId || isNewRow;

        return isEditing ? (
          <FmTextField name='amount' control={control} label='Amount' />
        ) : (
          row?.original?.amount || ''
        );
      },
    },
    {
      accessorKey: 'reference',
      header: 'Reference',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow = row?.original?.financialTransactionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.financialTransactionItemId || isNewRow;

        return isEditing ? (
          <FmTextField name='reference' control={control} label='Reference' onBlur={handleBlurAndFetch} />
        ) : (
          row?.original?.reference || ''
        );
      },
    },
    {
      header: 'Action',
      enableColumnPinning: true,
      enableEditing: false,
      enableSorting: false,
      size: 100,
      Cell: ({ row, table }) => {
        const isNewRow = row?.original?.financialTransactionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.financialTransactionItemId || isNewRow;

        // Check if the current row is the last row in the table
        const isLastRow = row.index === table.getRowModel().rows.length - 1;

        return (
          <Box className='flex gap-2'>
            {isEditing ? (
              <>
                <Button onClick={() => handleCancel(row)}>Close</Button>
                {isLastRow && rowState?.isCreatingRow === false && (
                  <Button
                    onFocus={() => handleCancelAndAddNewRow()}
                    hidden={true}
                  ></Button>
                )}
              </>
            ) : (
              <Box className='flex gap-2'>
                <TableActionButtons
                  Actions={ActionData?.map((action) => ({
                    ...action,
                    onClick: () => action.onClick(row?.original),
                  }))}
                />
                {isLastRow && (
                  <Button
                    variant='contained'
                    color='primary'
                    className='px-3 py-1'
                    onClick={() => {
                      setEditingRowIndex(row?.index + 1);
                      handleAddNewRow();
                    }}
                  >
                    Add
                  </Button>
                )}
              </Box>
            )}
          </Box>
        );
      },
    },
  ];
  useEffect(() => {
    if (editingRowData) {
      Object.entries(editingRowData).forEach(([key, value]) => {
        setValue(key, value);
      });
    }
  }, [editingRowData, setValue]);

  const handleClose = () => {
    setOpenUpload(false);
    setFilesData(null)
  };

  const handleExport = async () => {
    const downloadUrl = await apiInstance.getFiles(
      `financialtransactionitems/${id}/exportexcel`
    );

    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', 'Receipt.xlsx');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleUploadSheet = (event) => {
    startCreateLoading();
    const file = event.target.files[0];
    if (file) {
      const fileFormData = new FormData();
      fileFormData.append('file', file);
      fileFormData.append('fileTypeId', 59);

      crud.create('files/upload', fileFormData, (_err, res) => {
        setFilesData(res?.data)
        if (res?.status === 200) {
          crud.create(
            `financialtransactionitems/${id}/process/${res.data?.fileId}`,
            '',
            (_err, res) => {
              if (res?.status === 200) {
                stopCreateLoading()
                toast.success('Receipt Items Uploaded Successfully');
                fetchData();
                setOpenUpload(false);
              } else {
                stopCreateLoading()
              }
            }
          );
        } else {
          setOpenUpload(false);
          stopCreateLoading()
        }
      });
    }
  };

  return (
    <>
      <GlassCard className='my-4'>
        {/* <CreateForm
          fetchData={fetchData}
          financialTransactionId={financialTransactionId}
        /> */}
        <GlassCard className='p-2 m-2'>
          <MRTExpandTableStyle>
            <FmMRTDataTable
              rows={rows}
              columns={columns}
              onCreateRow={handleAddNewRow}
              isCreatingRow={rowState?.isCreatingRow}
              enableRowSelection={false}
              rowCount={tableRecordCounts}
              editingRow={editingRowId}
              pagination={pagination}
              fetchData={fetchData}
              sorting={sorting}
              columnFilters={columnFilters}
              globalFilter={globalFilter}
              setPagination={setPagination}
              setSorting={setSorting}
              setColumnFilters={setColumnFilters}
              setGlobalFilter={setGlobalFilter}
              isLoading={isLoading}
              setSearchKeyword={setSearchKeyword}
              searchKeyword={searchKeyword}
              handleExport={handleExport}
              handleImport={() => setOpenUpload(true)}
              handleRowDoubleClick={(row) => {
                handleEdit(row?.original);
                setEditingRowIndex(row?.index);
              }}
            />
            <MuiDialogOne
              title='Upload Sheet'
              open={openUpload}
              onClose={handleClose}
            >
              <FmFileInput
                onChange={handleUploadSheet} documentName={filesData?.originalFileName} loading={createLoading} />
            </MuiDialogOne>
          </MRTExpandTableStyle>
        </GlassCard>
      </GlassCard>
    </>
  );
};

export default ViewCashBankReceiptList;
