export const findNavById = (navItems, id) => {
  for (const item of navItems) {
    if (item.id === id) return item;
    if (item.child) {
      const found = findNavById(item.child, id);
      if (found) return found;
    }
  }
  return null;
};

export const findNavByPath = (navItems, path) => {
  for (const item of navItems) {
    if (item.path?.toLowerCase() === path.toLowerCase()) return item;
    if (item.child || item.children) {
      const found = findNavByPath(item.child || item.children, path);
      if (found) return found;
    }
  }
  return null;
};

export const findNavByName = (menuList, moduleName, menuName) => {
  if (!Array.isArray(menuList)) return null;

  for (const menu of menuList) {
    // Check if the current menu matches moduleName and menuName
    if (menu.moduleName === moduleName && menu.menuName === menuName) {
      return menu.childMenus || []; // Return childMenus if present
    }

    // Check in childMenus recursively
    if (menu.childMenus && menu.childMenus.length > 0) {
      const found = findNavByName(menu.childMenus, moduleName, menuName);
      if (found) {
        return found; // Return the found childMenus
      }
    }
  }

  return null; // Return null if no match is found
};

