import React, { useState, useEffect, useMemo } from 'react';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import DataTable from '../../../components/DataTable.tsx';
import DataTableToolbarButtons from '../../../components/helpers/DataTableToolbarButtons.tsx';
import { Icon } from '@iconify/react';
import { Avatar, Tabs, Tab } from '@mui/material';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { CreateButton } from '../../../components/small/Buttons';
import { useNavigate } from 'react-router-dom';
import { Box, Grid } from '@mui/material';
import BackToButton from '../../../components/small/BackToButton';
import { useTranslation } from 'react-i18next';
import Crud_Service from '../../../apis/CrudService.jsx';
import EmployeeProfile from './EmployeeProfile.tsx';
import BankDetails from './BankDetails.tsx';
import EmployeeEducationDetails from './EmployeeEducationDetails.tsx';
import { useSelector } from 'react-redux';
import { getHrmsEMpMasterCol } from './HrmsEMpMasterCol.tsx';
import useDateFormatter from '../../../components/helpers/useDateFormatter.jsx';
import EmployeeAllowence from './EmployeeAllowence.tsx';
import EmployeeIdentity from './EmployeeIdentity.tsx';
import ProjectDetails from './ProjectDetails.tsx';
import apiInstance from '../../../apis/ApiService.jsx';
import EmployeeConfigure from './EmployeeConfigure.tsx';
import CampDetails from './CampDetails.tsx';
import TableSearch from '../../../components/_form/TableSearch.jsx';
import { ToolbarBoxesFlex } from '../../../components/boxes/Boxes.jsx';
import { toast } from 'react-toastify';
import { findNavByName } from '../../../utils/navUtils.ts';
import FileManagerOne from '../../../components/filemanager/FileManagerOne.tsx';

const IconWithText = ({ icon, text }) => {
  return (
    <Box className='flex flex-row items-center gap-3 w-full'>
      <span className='text-lg'>{icon}</span>
      {text}
    </Box>
  );
};

const SideViewCard = ({ title, icon, count, total }) => {
  return (
    <GlassCard
      className={`flex flex-col w-full p-2 py-4 px-3 justify-between items-center`}
    >
      <Box className='mb-2' sx={{ color: 'secondary.main', fontSize: '40px' }}>
        {icon}
      </Box>
      <Box className='flex-1 text-center'>
        <Box className='text-lg font-bold flex gap-2 items-center mb-1'>
          <span>{count}</span>
          <span className='text-sm opacity-65 font-normal'>/ {total}</span>
        </Box>
        <Box>{title}</Box>
      </Box>
    </GlassCard>
  );
};

const HRMSEmployeeMaster = (props) => {
  const navigate = useNavigate();
  const { type } = props;
  const { t } = useTranslation();
  const [rows, setRows] = useState([]);
  const [viewData, setViewData] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const crud = new Crud_Service();
  const [pageSize, setPageSize] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);
  const [sortBy, setSortBy] = useState('');
  const [sortDirection, setSortDirection] = useState('');
  const [value, setValue] = useState(0);
  const [tablerecordCounts, setTableRecordCounts] = useState(null);
  const [employeeEducationData] = useState({});
  const menu = useSelector((state) => state?.menu?.items);
  const formateDate = useDateFormatter();
  const [searchKeyword, setSearchKeyword] = useState('');
  const [image, setImage] = useState('');
  const [sortableKeys, setSortableKeys] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const getImage = async () => {
    try {
      const imageUrl = await apiInstance.getFiles(
        `files/download/${selectedRow?.fileId}`
      );
      setImage(imageUrl);
    } catch (err) {
      toast.error('Error fetching image:', err);
    }
  };

  const toggleLoading = (loading) => {
    setIsLoading(loading);
  };

  const ViewPageData = [
    {
      groupName: 'Employee profile',
      child: [
        {
          title: 'Employee Name',
          name: 'Miss Lauren Boyle',
        },

        {
          title: 'Designation',
          name: 'Regional Optimization Facilitator',
        },
        {
          title: 'Visa Designation',
          name: 'National Brand Engineer',
        },
        {
          title: 'Department',
          name: 'Outdoors',
        },
        {
          title: 'Email Id',
          name: 'Vito_Smitham74@gmail.com',
        },
        {
          title: 'Contact Number',
          name: 'Price.Pfeffer-Schuster49@yahoo.com',
        },
        {
          title: 'Native Contact Number',
          name: 'Hildegard.Mayer@hotmail.com',
        },
        {
          title: 'Date Of Joining',
          name: '20-03-2000',
        },
        {
          title: 'Revision date',
          name: '12-03-2022',
        },
        {
          title: 'Applicable Date',
          name: '23-3-2014',
        },
        {
          title: 'Gender',
          name: 'Male',
        },
        {
          title: 'Position',
          name: 'Dev',
        },
        {
          title: 'Reporting To',
          name: 'lead',
        },
        {
          title: 'Blood Group',
          name: 'A1+',
        },
        {
          title: 'Marital Status',
          name: 'Married',
        },
        {
          title: 'Nationality',
          name: 'Mexican',
        },
        {
          title: 'Date of Birth',
          name: '1/9/2024',
        },
        {
          title: 'Place Of Birth',
          name: 'Port Enriquefield',
        },
        {
          title: 'Contract Type',
          name: 'Contractual',
        },
        {
          title: 'Unified No',
          name: 'ZUHKNG',
        },
        {
          title: 'EMP ID Card Provided',
          name: 'IB2555868',
        },
        {
          title: 'Permanent Home Country  Address',
          name: '361 Wilhelm Trace',
        },
        {
          title: 'Personal Email ID',
          name: 'Lennie_Reinger19@hotmail.com',
        },
        {
          title: 'Last Annual Leave Return Date',
          name: '11/27/2023',
        },
        {
          title: 'Emirates',
          name: 'Abu Dhabi',
        },
      ],
      renderCell: (i, id, value, employeeId) => {
        return (
          <EmployeeProfile
            i={i}
            id={id}
            value={value}
            selectedRow={selectedRow}
          />
        );
      },
    },
    {
      groupName: 'Account Details',
      child: [
        {
          title: 'Employee Account',
          name: 'JFSDHFGHSGH558',
        },
        {
          title: 'Salary Account',
          name: '15',
        },
        {
          title: 'Pay Group',
          name: 'Actual',
        },
        {
          title: 'Payment Type',
          name: 'Visa',
        },
        {
          title: 'Bank',
          name: 'Visa',
        },
        {
          title: 'Account No./IBAN No.',
          name: 'Visa',
        },
      ],
      renderCell: (i, id, value, employeeId) => {
        return <BankDetails i={i} id={id} value={value} />;
      },
    },
    {
      groupName: 'Allowance',
      child: [
        {
          title: 'Housing Allowance',
          name: '2500',
        },
        {
          title: 'Transport Allowance',
          name: '2558',
          flex: 4,
        },
        {
          title: 'Food Allowance',
          name: '15',
        },
        {
          title: 'Prov. Air Ticket Allowance',
          name: '5588',
        },
        {
          title: 'Telephone Allowance',
          name: '6558',
        },
        {
          title: 'Additional Allowances',
          name: '5888',
        },
      ],
      renderCell: (i, id, value, employeeId) => {
        return <EmployeeAllowence i={i} id={id} value={value} />;
      },
    },
    {
      groupName: 'Employee Identity',
      child: [
        {
          title: 'Passport Number',
          name: '2500',
        },
        {
          title: 'Passport Issue Place',
          name: '2558',
        },
        {
          title: 'Passport Issue Date',
          name: '15',
        },
        {
          title: 'Passport Expiry Date',
          name: '5588',
        },
        {
          title: 'Labour Card Expiry Date',
          name: '6558',
        },
        {
          title: 'Visa  file Number',
          name: '5888',
        },
        {
          title: 'Visa End Date',
          name: '5888',
        },
        {
          title: 'Emirates ID No.',
          name: '5888',
        },
        {
          title: 'Emirates ID Expiry Date',
          name: '5888',
        },
      ],
      renderCell: (i, id, value, employeeId) => {
        return <EmployeeIdentity i={i} id={id} value={value} />;
      },
    },
    {
      groupName: 'Employee Configuration',
      child: [
        {
          title: 'Passport Number',
          name: '2500',
        },
        {
          title: 'Passport Issue Place',
          name: '2558',
        },
        {
          title: 'Passport Issue Date',
          name: '15',
        },
        {
          title: 'Passport Expiry Date',
          name: '5588',
        },
        {
          title: 'Labour Card Expiry Date',
          name: '6558',
        },
        {
          title: 'Visa  file Number',
          name: '5888',
        },
        {
          title: 'Visa End Date',
          name: '5888',
        },
        {
          title: 'Emirates ID No.',
          name: '5888',
        },
        {
          title: 'Emirates ID Expiry Date',
          name: '5888',
        },
      ],
      renderCell: (i, id, value, employeeId) => {
        return <EmployeeConfigure i={i} id={id} value={value} />;
      },
    },
    {
      groupName: 'Camp Details',
      child: [
        {
          title: 'Camp Location',
          name: '2500',
        },
        {
          title: 'Room No.',
          name: '2558',
        },
      ],
      renderCell: (i, id, value, detailId, employeeId) => {
        return <CampDetails i={i} id={id} value={value} detailId={detailId} />;
      },
    },
    {
      groupName: 'Project details',
      child: [
        {
          title: 'Project',
          name: '2500',
        },
        {
          title: 'Cost Center',
          name: '2558',
        },
      ],
      renderCell: (i, id, value, employeeId) => {
        return <ProjectDetails i={i} id={id} value={value} />;
      },
    },
    // {
    //   groupName: 'Training Details',
    //   child: [
    //     {
    //       title: 'Training Title',
    //       name: '2500',
    //     },
    //     {
    //       title: 'Training Date',
    //       name: '2558',
    //     },
    //     {
    //       title: 'Attended',
    //       name: 'YES',
    //     },
    //   ],
    //   renderCell: (i, id, value) => {
    //     return <EmployeeProfile i={i} id={id} value={value} />;
    //   },
    // },
    {
      groupName: 'Educations',
      child: [
        {
          title: 'Employee Education ID',
          name: employeeEducationData?.employeeEducationId,
        },
        { title: 'Employee ID', name: employeeEducationData?.employeeId },
        { title: 'Employee Name', name: employeeEducationData?.employeeName },
        {
          title: 'Education Type ID',
          name: employeeEducationData?.educationTypeId,
        },
        {
          title: 'Education Type Name',
          name: employeeEducationData?.educationTypeName,
        },
        {
          title: 'Qualification Type ID',
          name: employeeEducationData?.qualificationTypeId,
        },
        {
          title: 'Qualification Type Name',
          name: employeeEducationData?.qualificationTypeName,
        },
        { title: 'Completed Year', name: employeeEducationData?.completedYear },

        { title: 'Status', name: employeeEducationData?.status },
        { title: 'Status Name', name: employeeEducationData?.statusName },
        { title: 'Created Date', name: employeeEducationData?.createdDate },
        { title: 'Updated Date', name: employeeEducationData?.updatedDate },
      ],
      renderCell: (i, id, value, employeeId) => {
        return <EmployeeEducationDetails i={i} id={id} value={value} />;
      },
    },
  ];
  const handleChange = (_event, newValue) => {
    setValue(newValue);
  };

  const fetchData = async () => {
    try {
      crud.getAll(
        'employees/all-employees',
        {
          pageSize,
          pageNumber,
          sortBy,
          sortDirection,
          searchKeyword,
        },
        (_err, res) => {
          setTableRecordCounts(res?.data);
          const transformedData = res?.data?.data?.map((row) => ({
            ...row,
            basicSalary: '####',
            grossSalary: '####',
            housingAllowance: '####',
            transportAllowance: '####',
            foodAllowance: '####',
            otherAllowance: '####',
            airTicketAllowance: '####',
            telephoneAllowance: '####',
            incentiveAllowance: '####',
            additionalAllowance: '####',
            totalSalary: '####',
            id: row?.employeeId,
            maritalStatusName:
              row?.maritalStatusName === '0' ? 'N/A' : row?.maritalStatusName,
          }));
          setRows(transformedData);
          setSortableKeys(res?.data?.sortFields);
          toggleLoading(false);
        }
      );
    } catch (error) {
      toast.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if (selectedRow?.fileId) {
      getImage();
    }
  }, [selectedRow?.fileId]);

  useEffect(() => {
    toggleLoading(true);
    fetchData();
    document.title = `HRMS-Employee`;
  }, [pageNumber, pageSize, sortBy, sortDirection, searchKeyword]);

  const handleSortChange = (sortModel) => {
    if (sortModel.length > 0) {
      setSortBy(sortModel[0].field);
      setSortDirection(sortModel[0].sort);
    } else {
      setSortBy('');
      setSortDirection('');
    }
  };

  const handleViewClick = (view) => {
    toggleLoading(true);
    setViewData(view);
    toggleLoading(false);
    setValue(0);
  };

  const handleEditClick = (view) => {
    navigate(`edit/${view?.employeeId}`, { state: { view } });
  };

  const handleDeleteClick = (view) => {
    toggleLoading(true);
    crud.remove('employees', view?.employeeId, (err, res) => {
      if (err) {
        toast.error('Error deleting data:', err);
        return;
      }
      if (res?.status === 204) {
        toggleLoading(false);
        fetchData();
        toast.success('Employee Deleted Successfully');
      }
    });
  };
  const Actions = [
    // {
    //   name: t("view external"),
    //   icon: <Icon icon="lucide:external-link" />,
    //   onClick: null,
    // },
    {
      name: t('view'),
      icon: <Icon icon='solar:eye-linear' />,
      onClick: (props) => handleViewClick(props),
    },
    {
      name: t('edit'),
      icon: <Icon icon='solar:pen-new-round-linear' />,
      onClick: (props) => handleEditClick(props),
    },
    {
      name: t('delete'),
      icon: <Icon icon='solar:trash-bin-2-linear' />,
      onClick: (props) => handleDeleteClick(props),
      danger: true,
    },
  ];

  const columns = getHrmsEMpMasterCol(t, Actions, setSelectedRow, formateDate);
  const handlePagination = (model) => {
    setPageSize(model?.pageSize);
    setPageNumber(model?.page + 1);
  };

  //Row Double Click View Page
  const onRowDoubleClick = (e) => {
    toggleLoading(true);
    handleEditClick(e?.row);
  };

  const downloadEmployees = async () => {
    toggleLoading(true);
    const downloadUrl = await apiInstance.getFiles('employees/exportexcel');

    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', 'employees.xlsx');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    toggleLoading(false);
  };

  const SubMenu = useMemo(() => {
    return menu?.map((item) => item?.menus?.flat()).flat() || [];
  }, [menu]);

  const resultHRMS = useMemo(
    () => findNavByName(SubMenu, 'HRMS', 'Employee'),
    [SubMenu]
  );
  const resultPR = useMemo(
    () => findNavByName(SubMenu, 'PR', 'Employee'),
    [SubMenu]
  );

  return (
    <DashboardLayout
      title={!viewData ? t('employees') : false}
      actionButtons={
        <>
          <Box className='flex flex-row items-center whitespace-nowrap gap-3'>
            <CreateButton
              name='Create'
              onClick={() => {
                navigate('create');
                toggleLoading(true);
              }}
            />
          </Box>
        </>
      }
      menu={type === 'PR' ? resultPR : resultHRMS}
      hasSubmenu
    >
      {!viewData && (
        <>
          <GlassCard className='h-full-css'>
            <Box className='h-full-css'>
              <DataTable
                rows={rows?.length > 0 ? rows : []}
                columns={columns}
                loading={isLoading}
                onRowDoubleClick={(e) => onRowDoubleClick(e)}
                rowCount={tablerecordCounts?.total}
                getRowClassName={(params) =>
                  `${
                    params.row.workingStatus === 'InActive' &&
                    'bg-red-500 bg-opacity-15'
                  }`
                }
                pageSizeOptions={[5, 10, 25, 50, 100]}
                sortingMode='server'
                paginationMode='server'
                onPaginationModelChange={(model) => handlePagination(model)}
                onSortModelChange={(model) => handleSortChange(model)}
                page={pageNumber - 1}
                pageSize={pageSize}
                sortableKeys={sortableKeys}
                initialState={{
                  columns: {
                    columnVisibilityModel: {
                      nationalityName: true,
                      genderName: true,
                      maritalStatus: true,
                      placeOfBirth: true,
                      empIdCardProvided: true,
                      unifiedNo: true,
                      molStatusName: true,
                      molReferenceNumber: true,
                      employeeStatusId: false,
                      employeeStatusName: true,
                      passportNumber: true,
                      passportIssuePlace: true,
                      passportIssueDate: true,
                      passportExpiryDate: true,
                      passportStatusId: false,
                      passportStatusName: true,
                      checkOutDate: true,
                      inOrOut: true,
                      employeeVisaDetailId: false,
                      visaNumber: true,
                      issuedOrganization: true,
                      issuedDate: true,
                      expiryDate: true,
                      remarks: false,
                      immigrationStatusId: false,
                      immigrationStatusName: true,
                      createdDate: true,
                      updatedDate: true,
                      permanentAddress: false,
                      basicSalary: false,
                      foodAllowance:false,
                      grossSalary:false,
                      housingAllowance:false,
                      incentiveAllowance:false,
                      telephoneAllowance:false,
                      transportAllowance:false,
                    },
                  },
                  pagination: {
                    paginationModel: {
                      pageSize: 50,
                    },
                  },
                }}
                slots={{
                  toolbar: () => (
                    <ToolbarBoxesFlex>
                      <TableSearch
                        placeholder='Search'
                        setSearchKeyword={setSearchKeyword}
                        searchValue={searchKeyword}
                      />

                      <DataTableToolbarButtons
                        handleExcelExport={downloadEmployees}
                      />
                    </ToolbarBoxesFlex>
                  ),
                }}
              />
            </Box>
          </GlassCard>
        </>
      )}
      {viewData && (
        <>
          <Box sx={{ color: 'text.main' }}>
            <BackToButton
              title='Back to List'
              onClick={() => setViewData(false)}
              className='mb-3 px-2 rounded-none'
            />

            <Grid spacing={1} container>
              <Grid item md={3}>
                {/* profile pic */}
                <GlassCard className='w-full relative overflow-hidden'>
                  <Box className='flex flex-col text-center justify-center items-center p-5 gap-2'>
                    <Box
                      className='flex gap-3 text-left w-full items-center mb-3 pb-3'
                      sx={{
                        borderBottom: '1px solid',
                        borderColor: 'border.main',
                      }}
                    >
                      <Avatar
                        alt={selectedRow?.employeeName}
                        src={image}
                        sx={{
                          width: 80,
                          height: 80,
                          bgcolor: 'background.light',
                          color: 'text.main',
                          '& .MuiSvgIcon-root': {
                            width: '50%',
                            height: '50%',
                          },
                        }}
                      />
                      <Box className='flex-1 min-w-0'>
                        <h3 className='font-bold text-base text-left'>
                          {selectedRow?.employeeName}
                        </h3>
                        <p>{selectedRow?.designationName}</p>
                        <p>Employee Code : {selectedRow?.employeeCode}</p>
                      </Box>
                    </Box>

                    <IconWithText
                      text={selectedRow?.placeOfBirth}
                      icon={<Icon icon='fluent:location-arrow-32-regular' />}
                    />

                    <IconWithText
                      text={selectedRow?.personalEmailId}
                      icon={<Icon icon='fluent:mail-32-regular' />}
                    />

                    <IconWithText
                      text={selectedRow?.mobileNumber}
                      icon={<Icon icon='fluent:call-32-regular' />}
                    />
                    <FileManagerOne id={selectedRow?.employeeId} />
                  </Box>
                </GlassCard>
                {/* profile pic */}

                {/* grid */}
                <Box className='flex gap-3 flex-col mt-5'>
                  <Grid container spacing={1}>
                    <Grid item md={6}>
                      <SideViewCard
                        title='Annual leave'
                        icon={<Icon icon='fluent:beach-48-regular' />}
                        count='43.2'
                        total='48.2'
                        color='red'
                      />
                    </Grid>
                    <Grid item md={6}>
                      <SideViewCard
                        title='Sick leave'
                        icon={<Icon icon='fluent:emoji-sad-16-regular' />}
                        count='14.14'
                        total='48.2'
                        color='orange'
                      />
                    </Grid>
                    <Grid item md={6}>
                      <SideViewCard
                        title='Emergency leave'
                        icon={<Icon icon='fluent:access-time-24-regular' />}
                        count='12.14'
                        total='48.2'
                        color='purple'
                      />
                    </Grid>
                    <Grid item md={6}>
                      <SideViewCard
                        title='Travel leave'
                        icon={
                          <Icon icon='fluent:airplane-take-off-24-regular' />
                        }
                        count='12.14'
                        total='48.2'
                        color='green'
                      />
                    </Grid>
                  </Grid>
                </Box>
                {/* grid */}
              </Grid>
              <Grid item md={9}>
                <GlassCard className='mb-4 overflow-hidden'>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    variant='scrollable'
                    sx={TabSxStyles}
                  >
                    {ViewPageData?.map((item, i) => {
                      return <Tab value={i} label={item?.groupName} />;
                    })}
                  </Tabs>
                </GlassCard>

                {ViewPageData?.map((item, i) => {
                  return (
                    // <GlassCard className={value === i ? "w-full  p-5" : "hidden"}>
                    //   <StyledCard title={item.groupName}>
                    //     <Grid container spacing={3}>
                    //       {item?.child?.map((had, i) => (
                    //         <Grid item md={4}>
                    //           <ViewField label={had.title} title={had.name} />
                    //         </Grid>
                    //       ))}
                    //     </Grid>
                    //   </StyledCard>
                    // </GlassCard>
                    item?.renderCell(
                      i,
                      selectedRow?.id,
                      value,
                      selectedRow?.employeeDetailId
                    )
                    // <EmployeeProfile  i={i} id={selectedRow.id} />
                  );
                })}
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </DashboardLayout>
  );
};

const TabSxStyles = {
  minHeight: 'inherit',
  '& .MuiButtonBase-root.MuiTabScrollButton-root': {
    padding: 0,
  },

  '& .Mui-disabled': {
    display: 'none',
  },

  '& .MuiButtonBase-root': {
    fontWeight: '500',
    minWidth: '50px',
    margin: 0,
    padding: '12px',
    justifyContent: 'center',
    textAlign: 'center',
    borderRadius: '0px',
    '&.Mui-selected': {
      color: 'primary.main',
      fontWeight: 600,
    },
  },
};

export default HRMSEmployeeMaster;
