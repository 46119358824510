import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import { Grid } from '@mui/material';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import { ActionButtons } from '../../../../components/_form/index.jsx';
import { useNavigate, useParams } from 'react-router-dom';
import Crud_Service from '../../../../apis/CrudService.jsx';
import CommonLoader from '../../../../components/page/CommonLoader.jsx';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import FmAutoComplete from '../../../../components/_mui/FmAutoComplete.tsx';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { EditCertificationSchema } from '../../../../utils/CommonvalidationSchemas.tsx';
import FmFileInput from '../../../../components/_mui/FmFileInput.tsx';
import FmDatePicker from '../../../../components/_mui/FmDatePicker.tsx';
import SimpleCard from '../../../../components/small/SimpleCard.tsx';
import useCommonFetchApi from '../../../../components/helpers/useCommonFetchApi.tsx';
import { CommonTrueFalse } from '../../../../utils/CommonVariables.tsx';
const EditCompanyCertificates = () => {
  const navigate = useNavigate();
  const crud = new Crud_Service();
  const { id } = useParams();
  const gridvalue = 2.4;
  const [editPageLoading, setEditPageLoading] = useState(false);
  const [loading, setLoading] = useState({
    certificationFileId: false,
    receiptFileId: false,
  });
  const [filesData, setFilesData] = useState({
    certificationFileId: null,
    receiptFileId: null,
  });
  const { editLoading, startEditLoading, stopEditLoading } = useLoader();
  const [editData, setEditData] = useState(null);
  const { setValue, handleSubmit, control, reset, watch, trigger } = useForm({
    // mode: 'onChange',
    // resolver: yupResolver(EditCertificationSchema),
  });
  const expiryDate = watch('expiryDate');

  //get All Api
  const { data: certificatetypes } = useCommonFetchApi('certificatetypes');
  const { data: certifications } = useCommonFetchApi('certifications');

  useEffect(() => {
    getCertificates();
  }, []);

  const getCertificates = async () => {
    setEditPageLoading(true);
    await crud.getSingle(`certificatecatalogpayments/${id}`, '', (err, res) => {
      if (res?.status === 200) {
        const data = res?.data;
        Object.entries({
          ...data,
        }).forEach(([key, value]) => {
          setValue(key, value);
        });
        setEditData(res?.data);
        setEditPageLoading(false);
      } else {
        setEditPageLoading(false);
      }
    });
  };

  const handleFileUpload = async (event, fieldName) => {
    setLoading((prev) => ({
      ...prev,
      [fieldName]: true,
    }));

    const file = event.target.files[0];
    if (file) {
      const fileFormData = new FormData();
      fileFormData.append('file', file);

      // Set fileTypeId dynamically based on the field name
      const fileTypeId = fieldName === 'certificationFileId' ? 50 : 60;
      fileFormData.append('fileTypeId', fileTypeId);

      try {
        const response = await new Promise((resolve, reject) => {
          crud.create('files/upload', fileFormData, (err, res) => {
            if (res?.status === 200) {
              resolve(res);
            } else {
              reject(new Error('File upload failed'));
            }
          });
        });


        // Dynamically update the corresponding fields
        setFilesData((prev) => ({
          ...prev,
          [fieldName]: response?.data,
        }));
        trigger(fieldName);
        setValue(`${fieldName}`, response?.data?.originalFileName);
      } catch (error) {
        toast.error(error.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleEditCompanycertificate = async (values) => {
    startEditLoading();
    const combinedData = {
      ...values,
      costCenterId: Number(values?.costCenterId),
      expenseTypeId: Number(values?.expenseTypeId),
      cost: Number(values?.cost),
      paymentDate: dayjs(values?.paymentDate).format('YYYY-MM-DD'),
      receiptFileId:
        filesData?.receiptFileId?.fileId || editData?.receiptFileId,
      updateCertificateCatalog: {
        ...values?.certificateCatalog,
        issuedDate: dayjs(values?.certificateCatalog?.issuedDate).format(
          'YYYY-MM-DD'
        ),
        expiryDate: dayjs(values?.certificateCatalog?.expiryDate).format(
          'YYYY-MM-DD'
        ),
        certificationFileId:
          filesData?.certificationFileId?.fileId ||
          editData?.certificationFileId,
      },
    };

    await crud.update(
      `certificatecatalogpayments/${id}`,
      '',
      combinedData,
      (err, res) => {
        if (res?.status === 200) {
          toast.success('Company Certificate Updated Successfully');
          navigate(-1);
          stopEditLoading();
        } else {
          stopEditLoading();
        }
      }
    );
  };

  const handleChangeExpenseType = (e) => {
    if (e?.cost) {
      setValue('cost', e?.cost);
    } else {
      setValue('cost', '');
    }
  }

  return (
    <DashboardLayout title='Edit Company Certificates'>
      <GlassCard className='p-4'>
        {editPageLoading ? (
          <CommonLoader />
        ) : (
          <form onSubmit={handleSubmit(handleEditCompanycertificate)}>
            <SimpleCard title={'Certificate Details'}>
              <Grid container spacing={2}>
                <Grid item md={gridvalue}>
                  <FmAutoComplete
                    name='certificateCatalog.certificateTypeId'
                    control={control}
                    options={certificatetypes}
                    label='Certificate Type'
                    displayField='certificateTypeName'
                    optionFields={['certificateTypeName']}
                    valueKey='certificateTypeId'
                  />
                </Grid>
                <Grid item md={gridvalue}>

                  <FmSearchableSelect
                    name='certificateCatalog.certificationId'
                    control={control}
                    apiUrl='certifications'
                    valueField='certificationId'
                    headerField={['Code', 'Name']}
                    labelField={['certificationCode', 'certificationName']}
                    showField={['certificationCode', 'certificationName']}
                    label='Certificate'
                    defaultValue={{
                      certificationId: editData?.certificateCatalog?.certificationId,
                      certificationName: editData?.certificateCatalog?.certificationName,
                    }}
                  />
                </Grid>
                <Grid item md={gridvalue}>
                  <FmAutoComplete
                    name='certificateCatalog.certificateShouldBeRenewed'
                    control={control}
                    options={CommonTrueFalse}
                    label='Certificate Should Be Renewed'
                    displayField='label'
                    optionFields={['label']}
                    valueKey='value'
                  />
                </Grid>

                <Grid item md={gridvalue}>
                  <FmTextField
                    name='certificateCatalog.certificationNumber'
                    control={control}
                    label='Certification Number'
                    pattern='Number'
                  />
                </Grid>
              </Grid>
            </SimpleCard>

            <SimpleCard title={'Dates'}>
              <Grid container spacing={2}>
                <Grid item md={gridvalue}>
                  <FmDatePicker
                    name='certificateCatalog.issuedDate'
                    control={control}
                    label='issued Date'
                  />
                </Grid>
                <Grid item md={gridvalue}>
                  <FmDatePicker
                    name='certificateCatalog.expiryDate'
                    control={control}
                    label='Expiry Date'
                  />
                </Grid>
              </Grid>
            </SimpleCard>

            <SimpleCard title={'Certificate & Status'}>
              <Grid container spacing={2}>
                <Grid item md={gridvalue}>
                  <FmFileInput
                    name='certificateCatalog.certificationFileId'
                    documentName={
                      filesData?.certificationFileId?.originalFileName
                    }
                    onChange={(e) => handleFileUpload(e, 'certificationFileId')}
                    loading={loading?.certificationFileId}
                  />
                </Grid>
                <Grid item md={gridvalue}>
                  <FmSearchableSelect
                    name='certificateCatalog.certificateStatusId'
                    control={control}
                    apiUrl='certificatestatuses'
                    valueField='certificateStatusId'
                    labelField={[
                      'certificateStatusCode',
                      'certificateStatusName',
                    ]}
                    showField={[
                      'certificateStatusCode',
                      'certificateStatusName',
                    ]}
                    label={'Certificate Status'}
                    defaultValue={{
                      certificateStatusId:
                        editData?.certificateCatalog?.certificateStatusId,
                      certificateStatusName:
                        editData?.certificateCatalog?.certificateStatusName,
                    }}
                  />
                </Grid>
                <Grid item md={gridvalue * 2}>
                  <FmTextField
                    name='certificateCatalog.remarks'
                    control={control}
                    label='Remarks'
                    multiline={true}
                    rows={2}
                    maxRows={5}
                  />
                </Grid>
              </Grid>
            </SimpleCard>
            <SimpleCard title={'Expenses'}>
              <Grid container spacing={2}>
                <Grid item md={gridvalue}>
                  <FmSearchableSelect
                    name='expenseTypeId'
                    control={control}
                    apiUrl='expensetypes'
                    valueField='expenseTypeId'
                    headerField={['Code', 'Name']}
                    labelField={['expenseTypeCode', 'expenseTypeName']}
                    showField={['expenseTypeCode', 'expenseTypeName']}
                    label={'Expense Type'}
                    defaultValue={{
                      expenseTypeId: editData?.expenseTypeId,
                      expenseTypeName: editData?.expenseTypeName,
                    }}
                    onChangeProp={(e) => handleChangeExpenseType(e)}
                  />
                </Grid>
                <Grid item md={gridvalue}>
                  <FmTextField
                    name='cost'
                    control={control}
                    label='Cost'
                    pattern='Decimal'
                  />
                </Grid>
                <Grid item md={gridvalue}>
                  <FmDatePicker
                    name='paymentDate'
                    control={control}
                    label='Payment Date'
                  />
                </Grid>
              </Grid>
            </SimpleCard>
            <SimpleCard title={'Transaction Details'}>
              <Grid container spacing={2}>
                <Grid item md={gridvalue}>
                  <FmSearchableSelect
                    name='costCenterId'
                    control={control}
                    apiUrl='costcenters'
                    valueField='costCenterId'
                    headerField={['Code', 'Name']}
                    labelField={['costCenterCode', 'costCenterName']}
                    showField={['costCenterCode', 'costCenterName']}
                    label={'Cost Center'}
                    defaultValue={{
                      costCenterId: editData?.costCenterId,
                      costCenterName: editData?.costCenterName,
                    }}
                  />
                </Grid>
                <Grid item md={gridvalue}>
                  <FmTextField
                    name='transactionNumber'
                    control={control}
                    label='Transaction Number'
                    pattern='Number'
                  />
                </Grid>
                <Grid item md={gridvalue}>
                  <FmFileInput
                    name='receiptFileId'
                    documentName={
                      filesData?.receiptFileId?.originalFileName ||
                      editData?.receiptFileName
                    }
                    onChange={(e) => handleFileUpload(e, 'receiptFileId')}
                    loading={loading?.receiptFileId}
                  />
                </Grid>
                <Grid item md={gridvalue * 2}>
                  <FmTextField
                    name='remarks'
                    control={control}
                    label='Remarks'
                    multiline={true}
                    maxRows={5}
                    rows={2}
                  />
                </Grid>
              </Grid>
            </SimpleCard>
            <ActionButtons
              onSubmit={handleSubmit(handleEditCompanycertificate)}
              onCancel={() => navigate(-1)}
              onReset={reset}
              submitLoading={editLoading}
              cancelLoading={false}
              submitText='Update'
              cancelText='Go Back'
            />
          </form>
        )}
      </GlassCard>
    </DashboardLayout>
  );
};

export default EditCompanyCertificates;
