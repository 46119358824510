import { Icon } from '@iconify/react/dist/iconify.js';
import { useEffect, useRef } from 'react';
import './Modal.scss';

const Modal = ({
  children,
  show,
  setShow,
  heading,
  dialogWidth = '25%',
  contentClassName = '',
  closeButton = true,
}) => {
  const modalRef = useRef(null);

  const handleKeyDown = (e) => {
    if (e.key === 'Escape') {
      setShow(false);
    }
  };

  useEffect(() => {
    if (show) {
      modalRef.current.showModal();
    } else {
      modalRef.current.close();
    }
  }, [show]);

  return (
    <dialog
      ref={modalRef}
      className={`fm-modal dialog`}
      style={{ width: dialogWidth, height: '90%' }}
      onKeyDown={handleKeyDown}
    >
      <div className='fm-modal-header'>
        <span className='fm-modal-heading'>{heading}</span>
        {closeButton && (
          <Icon
            icon='ic:baseline-clear'
            width='32'
            height='32'
            onClick={() => setShow(false)}
            className='close-icon'
            title='Close'
          />
        )}
      </div>
      {children}
    </dialog>
  );
};

export default Modal;
