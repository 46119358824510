import React from 'react';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import { useNavigate, useParams } from 'react-router-dom';
import CommonView from '../../../../components/helpers/CommonView.jsx';
import DynamicViewFields from '../../../../components/helpers/DynamicViewFields.jsx';
import { CreateButton } from '../../../../components/small/Buttons.jsx';
import ActionButtons from '../../../../components/_form/ActionButtons.tsx';

const ViewOwnvehicle = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const excludeKeys = [
    'advertisement',
    'divisionId',
    'ownVehicleCode',
    'driverId',
    'insuranceId',
    'ownVehicleId',
    'vehicleId',
    'createdDate',
    'updatedDate',
    'vehicleBrandName',
    'vehicleCategoryName',
    'vehicleTypeName',
    'vehiclePlateTypeName',
    'vehiclePlateNo',
    'vehicleRegisteredName',
    'registeredName',
    'presentlyLocatedName', 'vehicleModel', 'registeredDate', 'registeredExpiryDate', 'insuranceExpiryDate', 'insuranceTypeName',
    'status', 'statusName', 'permitNumber', 'expiryDate', 'driverContactNumber'
  ];

  return (
    <DashboardLayout
      title='View Own Vehicles'
      actionButtons={
        <CreateButton
          name='Create'
          onClick={() => navigate('/admin/vehicles/own-vehicles/create')}
        />
      }
    >
      <GlassCard className='p-5'>
        <CommonView
          url='ownvehicles'
          id={id}
          excludeKeys={excludeKeys}
          renderFields={(data, fields) => (
            <DynamicViewFields data={data} fields={fields} />
          )}
        />
        <ActionButtons
          cancelLoading={false}
          cancelText='Cancel'
          submitText='Edit'
          onSubmit={() => navigate(`/admin/vehicles/own-vehicles/edit/${id}`)}
          onCancel={() => navigate('/admin/vehicles/own-vehicles')}
        />
      </GlassCard>
    </DashboardLayout>
  );
};

export default ViewOwnvehicle;
