import React from 'react';
import { MuiTelInput, MuiTelInputInfo } from 'mui-tel-input';
import { Controller, Control } from 'react-hook-form';

interface FmTelInputProps {
  name: string;
  control: Control<any>;
  handleChange?: (value: string, info: MuiTelInputInfo) => void;
  anyCountry?: boolean;
  label?: string;
  fullWidth?: boolean;
  defaultValue?: string;
  onlyCountries?: Array<string>;
  required?: boolean;
}

const FmTelInput: React.FC<FmTelInputProps> = ({
  name,
  control,
  handleChange,
  anyCountry,
  label,
  fullWidth = true,
  defaultValue = '',
  required = false,
  onlyCountries,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <MuiTelInput
          required={required}
          label={label}
          defaultCountry={anyCountry ? undefined : 'AE'}
          fullWidth={fullWidth}
          value={value}
          variant='filled'
          onlyCountries={onlyCountries}
          onChange={(value, info) => {
            onChange(value);
            if (handleChange) {
              handleChange(value, info);
            }
          }}
          error={!!error}
          helperText={error ? error.message : null}
        />
      )}
    />
  );
};

export default FmTelInput;
