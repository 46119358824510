import { useState } from 'react';
import { getFileExtension } from '../../utils/getFileExtension';
import Loader from '../../components/Loader/Loader';
import { useSelection } from '../../contexts/SelectionContext';
import Button from '../../components/Button/Button';
import { getDataSize } from '../../utils/getDataSize';
import { Icon } from '@iconify/react/dist/iconify.js';
import { useFileIcons } from '../../hooks/useFileIcons';
import './PreviewFile.action.scss';
import apiInstance from '../../../../apis/ApiService';

const imageExtensions = ['jpg', 'jpeg', 'png'];
const videoExtensions = ['mp4', 'mov', 'avi'];
const audioExtensions = ['mp3', 'wav', 'm4a'];
const iFrameExtensions = ['txt', 'pdf'];

const PreviewFileAction = ({ filePreviewPath }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const { selectedFiles } = useSelection();
  const fileIcons = useFileIcons(73);
  const cleanedFilePath = selectedFiles[0].path.replace(/\s*\([^)]*\)/g, '');
  const extension = getFileExtension(selectedFiles[0].name)?.toLowerCase();
  const encodedFilePath = encodeURIComponent(cleanedFilePath);

  const filePath = `${filePreviewPath}${encodedFilePath}`;

  const handleImageLoad = () => {
    setIsLoading(false); // Loading is complete
    setHasError(false); // No error
  };

  const handleImageError = () => {
    setIsLoading(false); // Loading is complete
    setHasError(true); // Error occurred
  };

  const handleDownload = async () => {
    try {
      // Ensure the path is properly formatted
      const filePath = encodeURIComponent(
        selectedFiles[0].path.replace(/^\//, '')
      );

      // Construct the download URL
      const downloadUrl = `filestructures/1 ADEEB/${filePath}/download`;

      // Fetch the file data
      const file = await apiInstance.getFiles(downloadUrl);

      // Create a download link and trigger the download
      const link = document.createElement('a');
      link.href = file.url; // Ensure this is the direct file URL
      link.setAttribute('download', selectedFiles[0].name);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };

  return (
    <section
      className={`file-previewer relative ${
        extension === 'pdf' ? 'pdf-previewer' : ''
      }`}
    >
      {hasError ||
        (![
          ...imageExtensions,
          ...videoExtensions,
          ...audioExtensions,
          ...iFrameExtensions,
        ].includes(extension) && (
          <div className='preview-error'>
            <span className='error-icon'>
              {fileIcons[extension] ?? (
                <Icon icon='bx:file' width='24' height='24' />
              )}
            </span>
            <span className='error-msg'>
              Sorry! Preview is not available for this file.
            </span>
            <div className='file-info'>
              <span className='file-name'>{selectedFiles[0].name}</span>
              {selectedFiles[0].size && <span>-</span>}
              <span className='file-size'>
                {getDataSize(selectedFiles[0].size)}
              </span>
            </div>
            <Button onClick={handleDownload} padding='0.45rem .9rem'>
              <div className='download-btn'>
                <Icon icon='material-symbols:download' width='18' height='18' />
                <span>Download</span>
              </div>
            </Button>
          </div>
        ))}
      {imageExtensions.includes(extension) && (
        <>
          <Loader isLoading={isLoading} />
          <img
            src={`${filePath}/download`}
            alt='Preview Unavailable'
            className={`photo-popup-image ${isLoading ? 'img-loading' : ''}`}
            onLoad={handleImageLoad}
            onError={handleImageError}
            loading='lazy'
          />
          <Button
            onClick={handleDownload}
            padding='0.45rem .9rem'
            classCustom='absolute right-[60px] top-[-43px] flex gap-3'
          >
            <Icon icon='material-symbols:download' width='18' height='18' />
            <span>Download</span>
          </Button>
        </>
      )}
      {videoExtensions.includes(extension) && (
        <video src={filePath} className='video-preview' controls autoPlay />
      )}
      {audioExtensions.includes(extension) && (
        <audio src={filePath} controls autoPlay className='audio-preview' />
      )}
      {iFrameExtensions.includes(extension) && (
        <>
          <iframe
            src={filePath}
            onLoad={handleImageLoad}
            onError={handleImageError}
            frameBorder='0'
            className={`photo-popup-iframe ${isLoading ? 'img-loading' : ''}`}
          ></iframe>
          <Button
            onClick={handleDownload}
            padding='0.45rem .9rem'
            classCustom='absolute right-[60px] top-[-43px] flex gap-3'
          >
            <Icon icon='material-symbols:download' width='18' height='18' />
            <span>Download</span>
          </Button>
        </>
      )}
    </section>
  );
};

export default PreviewFileAction;
