import React from 'react';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import { Grid } from '@mui/material';
import Crud_Service from '../../../../apis/CrudService.jsx';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import dayjs from 'dayjs';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import ActionButtons from '../../../../components/_form/ActionButtons.tsx';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FmTimePicker from '../../../../components/_mui/FmTimePicker.tsx';
import { HiredVechiclesSchema } from '../../../../utils/CommonvalidationSchemas.tsx';
import { useNavigate } from 'react-router-dom';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import FmAutoComplete from '../../../../components/_mui/FmAutoComplete.tsx';
import useCommonFetchApi from '../../../../components/helpers/useCommonFetchApi.tsx';

const GridValue = 3;
const AddHiredVechicles = (props) => {
  // const { fetchData } = props;
  const crud = new Crud_Service();
  const { handleSubmit, control, reset } = useForm({
    resolver: yupResolver(HiredVechiclesSchema),
    mode: 'onChange',
  });
  const navigate = useNavigate();
  const { startCreateLoading, stopCreateLoading, createLoading } = useLoader();
  const { data: drivers } = useCommonFetchApi('drivers') || {
    data: [],
  };
  const handleSubmithiredVechicles = async (values) => {
    startCreateLoading();

    const combinedData = {
      ...values,
      pickUpTime: values?.pickUpTime,
      dropUpTime: values?.dropUpTime,
    };

    await crud.create('hiredvehicles', combinedData, (err, res) => {
      if (res?.status === 201) {
        toast.success('Hired Vechicles Created Successfully');
        reset();
        stopCreateLoading();
        navigate(-1);
      } else {
        stopCreateLoading();
      }
    });
  };

  return (
    <DashboardLayout title='Create Hired vehicle'>
      <GlassCard className='mb-3 p-3'>
        <form onSubmit={handleSubmit(handleSubmithiredVechicles)}>
          <Grid container spacing={2}>
            <Grid item md={GridValue}>
              <FmTextField
                name='vehicleRegNo'
                control={control}
                label='Vehicle Reg No'
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmTextField
                name='vehiclePlateNo'
                control={control}
                label='Vehicle Plate No'
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmSearchableSelect
                name='vehicleTypeId'
                control={control}
                apiUrl='vehicletypes'
                valueField='vehicleTypeId'
                labelField={['vehicleTypeCode', 'vehicleTypeName']}
                showField={['vehicleTypeCode', 'vehicleTypeName']}
                label={'Vehicle Type'}
                required
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmSearchableSelect
                name='siteId'
                control={control}
                apiUrl='buildings'
                valueField='buildingId'
                labelField={['buildingCode', 'buildingName']}
                showField={['buildingCode', 'buildingName']}
                label={'Site'}
                required
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmSearchableSelect
                name='locationId'
                control={control}
                apiUrl='locations'
                valueField='locationId'
                labelField={['locationCode', 'locationName']}
                showField={['locationCode', 'locationName']}
                label={'Location'}
                required
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmSearchableSelect
                name='divisionId'
                control={control}
                apiUrl='divisions'
                valueField='divisionId'
                labelField={['divisionCode', 'divisionName']}
                showField={['divisionCode', 'divisionName']}
                label={'Division'}
                required
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmTimePicker
                control={control}
                name='pickUpTime'
                label='Pick-up Time'
                required={true}

              />
            </Grid>
            <Grid item md={GridValue}>
              <FmTimePicker
                control={control}
                name='dropUpTime'
                label='Drop-up Time'
                required={true}
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmTextField
                name='currentKilometer'
                control={control}
                label='Current Kilometer'
                required
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmSearchableSelect
                name='supplierId'
                control={control}
                apiUrl='suppliers'
                valueField='supplierId'
                labelField={['supplierCode', 'officialSupplierName']}
                showField={['supplierCode', 'officialSupplierName']}
                label={'Supplier'}
                required
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmSearchableSelect
                name='stationId'
                control={control}
                apiUrl='emirates'
                valueField='emirateId'
                labelField={['emirateCode', 'emirateName',]}
                showField={['emirateCode', 'emirateName']}
                label={'Station'}
                required
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmAutoComplete
                name='driverId'
                control={control}
                options={drivers}
                label='Driver'
                displayField='driverName'
                optionFields={['driverCode', 'driverName']}
                valueKey='driverId'
                required
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmSearchableSelect
                name='projectId'
                control={control}
                apiUrl='projects'
                valueField='projectId'
                labelField={['projectCode', 'projectName']}
                showField={['projectCode', 'projectName']}
                label={'Project'}
                required
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmSearchableSelect
                name='contractId'
                control={control}
                apiUrl='contracts'
                valueField='contractId'
                labelField={['contractCode', 'contractName']}
                showField={['contractCode', 'contractName']}
                label={'Contract'}
                required
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmSearchableSelect
                name='contactPersonId'
                control={control}
                apiUrl='supplierspocs'
                valueField='supplierSPOCId'
                labelField={['supplierSPOCName', 'phoneNumber']}
                showField={['supplierSPOCName', 'phoneNumber']}
                label={'Contact Person'}
                required
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmTextField
                name='rentAmount'
                control={control}
                label='Rent Amount'
                pattern='Decimal'
                required
              />
            </Grid>
            <Grid item md={6}>
              <FmTextField
                name='remarks'
                control={control}
                label='Remarks'
                multiline={true}
                rows={2}
                maxRows={3}
              />
            </Grid>
          </Grid>
          <ActionButtons
            onSubmit={handleSubmit(handleSubmithiredVechicles)}
            onReset={reset}
            submitLoading={createLoading}
            submitText='Create'
            cancelText='Cancel'
            onCancel={() => navigate(-1)}
          />
        </form>
      </GlassCard>
    </DashboardLayout>
  );
};
export default AddHiredVechicles;
