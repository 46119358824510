import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import { Box, Grid } from '@mui/material';
import GlassCard from '../../../components/small/GlassCard.tsx';
import ActionButtons from '../../../components/_form/ActionButtons.tsx';
import { useNavigate, useParams } from 'react-router-dom';
import Crud_Service from '../../../apis/CrudService.jsx';
import { useForm } from 'react-hook-form';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import CommonLoader from '../../../components/page/CommonLoader.jsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import { toast } from 'react-toastify';
import FmAutoComplete from '../../../components/_mui/FmAutoComplete.tsx';
import { CreateButton } from '../../../components/small/Buttons.jsx';
import FmSearchableSelect from '../../../components/_mui/FmSearchableSelect.tsx';
import FmDatePicker from '../../../components/_mui/FmDatePicker.tsx';
import { statusOptions } from '../../../utils/CommonVariables.tsx';
import dayjs from 'dayjs';
import { yupResolver } from '@hookform/resolvers/yup';
import { uniformRequsitionReturnSchema } from '../../../utils/CommonvalidationSchemas.tsx';

const EditUniformReturn = () => {
  const navigate = useNavigate();
  const crud = new Crud_Service();
  const [loading, setLoading] = useState(false);
  const [editData, setEditData] = useState(null);
  const { handleSubmit, control, setValue, reset } = useForm({
    mode: 'onChange',
    resolver: yupResolver(uniformRequsitionReturnSchema)
  });
  const { startEditLoading, stopEditLoading, editLoading } = useLoader();
  const { id } = useParams();
  const GridValue = 2.4;

  useEffect(() => {
    getUniforRequisition();
  }, []);

  const getUniforRequisition = async () => {
    setLoading(true);
    await crud.getSingle(
      'uniformandaccessoriesrequisitionreturns',
      id,
      (_err, res) => {
        if (res?.status === 200) {
          const data = res?.data;
          Object.entries({
            ...data,
          }).forEach(([key, value]) => {
            setValue(key, value);
          });
          setEditData(res?.data);
          setLoading(false);
        } else {
        }
      }
    );
  };

  const handleUpdateUniformReturn = async (values) => {
    startEditLoading();
    const combinedData = {
      ...values,
      requisitionDate: dayjs(values?.requisitionDate).format('YYYY-MM-DD'),
      status: 2,
    };

    await crud.update(
      'uniformandaccessoriesrequisitionreturns',
      id,
      combinedData,
      (err, res) => {
        if (res?.status === 200) {
          toast.success('Uniform and Accessories Return Updated Successfully');
          navigate(-1);
          stopEditLoading();
        } else {
          stopEditLoading();
        }
      }
    );
  };
  return (
    <DashboardLayout
      title='Edit Uniform and Accessories-Return'
      actionButtons={
        <>
          <Box className='flex flex-row items-center whitespace-nowrap gap-3'>
            <CreateButton
              name='Create'
              onClick={() =>
                navigate('/admin/uniform-accessories-requistions/return/create')
              }
            />
          </Box>
        </>
      }
    >
      <GlassCard className='p-5'>
        {loading ? (
          <CommonLoader />
        ) : (
          <form onSubmit={handleSubmit(handleUpdateUniformReturn)}>
            <Grid container spacing={2}>
              <Grid item md={GridValue}>
                <FmSearchableSelect
                  name='employeeId'
                  control={control}
                  apiUrl='employees'
                  valueField='employeeId'
                  labelField={[
                    'employeeCode',
                    'uniformAndAccessoriesRequisitionCode',
                  ]}
                  showField={['employeeCode', 'firstName']}
                  label={'Employees'}
                  defaultValue={{
                    employeeId: editData?.employeeId,
                    firstName: editData?.employeeName,
                  }}
                  required
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmSearchableSelect
                  name='uniformAndAccessoriesRequisitionId'
                  control={control}
                  apiUrl='uniformandaccessoriesrequisitions'
                  valueField='uniformAndAccessoriesRequisitionId'
                  headerField={['Code']}
                  labelField={['uniformAndAccessoriesRequisitionCode']}
                  showField={['uniformAndAccessoriesRequisitionCode']}
                  label={'Uniform And Accessories Requisition'}
                  defaultValue={{
                    uniformAndAccessoriesRequisitionId:
                      editData?.uniformAndAccessoriesRequisitionId,
                    uniformAndAccessoriesRequisitionCode:
                      editData?.uniformAndAccessoriesRequisitionReturnCode,
                  }}
                  required
                />
              </Grid>

              <Grid item md={GridValue}>
                <FmDatePicker
                  name='requisitionDate'
                  control={control}
                  label='Request Date'
                />
              </Grid>

              <Grid item md={GridValue}>
                <FmTextField name='qrCode' control={control} label='QR Code' />
              </Grid>
              <Grid item md={4}>
                <FmTextField
                  name='remarks'
                  control={control}
                  label='Remarks'
                  multiline={true}
                  rows={2}
                  maxRows={3}
                />
              </Grid>
            </Grid>
            <ActionButtons
              onSubmit={handleSubmit(handleUpdateUniformReturn)}
              onReset={reset}
              submitLoading={editLoading}
              submitText='Update'
              cancelText='Cancel'
              onCancel={() => navigate(-1)}
            />
          </form>
        )}
      </GlassCard>
    </DashboardLayout>
  );
};

export default EditUniformReturn;
