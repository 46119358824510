import { yupResolver } from '@hookform/resolvers/yup';
import { Icon } from '@iconify/react/dist/iconify.js';
import {
  Box,
  Button,
  Grid,
  LinearProgress,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import apiInstance from '../../../apis/ApiService.jsx';
import Crud_Service from '../../../apis/CrudService';
import { ActionButtons } from '../../../components/_form';
import { ViewField } from '../../../components/_form/FormElements.jsx';
import MuiDialogOne from '../../../components/_mui/MuiDialogOne';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import { FileCardGrid } from '../../../components/small/Cards';
import GlassCard from '../../../components/small/GlassCard.tsx';
import UploadModalBox from '../../../components/upload/UploadModalBox.tsx';
import { documentUploadValidationSchema } from '../../../utils/CommonvalidationSchemas.tsx';
import FileViewer from '../../../components/elements/FileViewer.tsx';
import MuiDialogFullscreen from '../../../components/_mui/MuiDialogFullscreen.tsx';

const UploadBoxStyle = {
  border: '2px dashed',
  borderColor: 'border.main',
  padding: '15px',
  marginBottom: '30px',
  borderRadius: '6px',
  bgcolor: 'background.white',
};

const DocumentUpload = ({ id }) => {
  const [selectedRow, setSelectedRow] = useState(null);
  const [uploadView, setUploadView] = useState(false);
  const [showView, setShowView] = useState(false);
  const [viewData, setViewData] = useState({});
  const [companies, setCompanies] = useState([]);
  const [docType, setDocType] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [fileId, setFileId] = useState(null);
  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(documentUploadValidationSchema),
    defaultValues: {
      files: [
        {
          companyId: '',
          remarks: '',
          documentName: '',
          fileId: '',
        },
      ],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'files',
  });
  const { startCreateLoading, stopCreateLoading, createLoading } = useLoader();
  const [uploadProgress, setUploadProgress] = useState({});
  const crud = new Crud_Service();
  const navigate = useNavigate();

  useEffect(() => {
    getAllDocuments();
  }, [id]);

  useEffect(() => {
    getCompanies();
    getDocumentTypes();
  }, []);

  const getAllDocuments = async () => {
    const params = {
      documentTypeId: 95,
      documentTypeCategoryId: 3,
      // tagId: id,
    };

    await crud.getAll('tenderdocuments', { tenderId: id }, (err, res) => {
      if (res?.status === 200) {
        setDocuments(res?.data?.data);
      } else {
      }
    });
  };

  const getDocumentTypes = async () => {
    await crud.getAll('documenttypes', '', (err, res) => {
      if (res?.status === 200) {
        setDocType(res?.data?.data);
      } else {
      }
    });
  };

  const getCompanies = async () => {
    await crud.getAll('companies', '', (err, res) => {
      if (res?.status === 200) {
        setCompanies(res?.data?.data);
      } else {
      }
    });
  };

  const handleFileUpload = async (index, event) => {
    const file = event.target.files[0];
    if (file) {
      const fileFormData = new FormData();
      fileFormData.append('file', file);
      fileFormData.append('fileTypeId', 8);

      try {
        const response = await new Promise((resolve, reject) => {
          crud.create('files/upload', fileFormData, (err, res) => {
            if (res?.status === 200) {
              resolve(res);
            } else {
              reject(new Error('File upload failed'));
            }
          });
        });

        // Store the fileId for the specific index
        setValue(`files.${index}.fileId`, response?.data?.fileId);
        setFileId(getValues(`files.${index}.fileId`, response?.data?.fileId));
        setValue(
          `files[${index}].documentName`,
          response?.data?.originalFileName
        );

        // Simulate upload progress
        for (let progress = 0; progress <= 100; progress += 10) {
          setUploadProgress((prevProgress) => ({
            ...prevProgress,
            [index]: progress,
          }));
          await new Promise((resolve) => setTimeout(resolve, 100));
        }
      } catch (error) {
        toast.error(error.message);
      }
    }
  };

  const handleSubmitDocuments = async (values) => {
    startCreateLoading();
    const combinedData = values?.files?.map((file) => ({
      ...file,
      fileId: fileId,
      documentStatus: 2,
      documentTypeId: 95,
      documentTypeCategoryId: 3,
      tagId: id,
    }));
    try {
      crud.create('documents', combinedData, (err, res) => {
        if (res?.status === 201) {
          stopCreateLoading();
          setUploadView(false);
          toast.success('Document Uploaded Successfully');
          reset();
          getAllDocuments();
          setUploadProgress({});
        } else {
          stopCreateLoading();
        }
      });
    } catch (error) {
      stopCreateLoading();
      toast.error(error.message);
    }
  };

  const handleRemove = (index) => {
    remove(index);
    //Remove The Percentage Progress
    setUploadProgress((prevProgress) => {
      const newProgress = { ...prevProgress };
      delete newProgress[index];
      return newProgress;
    });
  };

  const handleViewClick = async (documentId) => {
    setShowView(true);
    await crud.getSingle('documents', documentId, (err, res) => {
      if (res?.status === 200) {
        setViewData(res?.data);
      } else {
      }
    });
  };

  const handleDownloadClick = async (documentId, item) => {
    try {
      const imageUrl = await apiInstance.getFiles(
        `files/download/${item?.fileId}`
      );
      const link = document.createElement('a');
      link.href = imageUrl;
      link.setAttribute('download', `${item?.documentName}`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      toast.error('Error fetching image:', err);
    }
  };

  const handleDelete = async (documentId) => {
    await crud.remove('documents', documentId, (err, res) => {
      if (res?.status === 204) {
        toast.success('Document Deleted Successfully');
        getAllDocuments();
      } else {
      }
    });
  };

  const FileActionData = [
    {
      name: 'View Details',
      icon: <Icon icon='solar:eye-linear' />,
      onClick: (documentId) => handleViewClick(documentId),
    },
    {
      name: 'Download',
      icon: <Icon icon='solar:download-minimalistic-outline' />,
      onClick: (documentId, item) => handleDownloadClick(documentId, item),
    },
    {
      name: 'Delete',
      icon: <Icon icon='solar:trash-bin-minimalistic-broken' />,
      onClick: (documentId) => handleDelete(documentId),
      danger: true,
    },
  ];

  // get the FileTypes
  const getFileExtension = (fileName) => {
    return fileName.split('.').pop().toUpperCase();
  };

  return (
    <GlassCard className='p-3'>
      <Box className='flex justify-between items-center mb-3'>
        <Box className='text-base font-semibold '>Documents Upload</Box>
        <Button
          variant='contained'
          color='primary'
          startIcon={<Icon icon='ph:upload' />}
          // onClick={() => {
          //   setUploadView(true);
          //   reset({
          //     files: [
          //       {
          //         companyId: '',
          //         remarks: '',
          //         documentName: '',
          //         fileId: '',
          //       },
          //     ],
          //   });
          // }}
          onClick={() => navigate(`upload-document`)}
        >
          {' '}
          Upload Document
        </Button>
      </Box>
      <Grid container spacing={2}>
        {documents?.map((item: any) => (
          <Grid item md={3} key={item.documentId}>
            <FileCardGrid
              name={item.documentName}
              size='15 KB'
              format={getFileExtension(item.fileName)}
              Actions={FileActionData?.map((action) => ({
                ...action,
                onClick: () => action.onClick(item.documentId, item),
              }))}
              selectedRow={selectedRow}
              setSelectedRow={setSelectedRow}
              onClickView={() => handleViewClick(item.documentId)}
            />
          </Grid>
        ))}
      </Grid>

      <MuiDialogOne
        title='Upload Document'
        open={uploadView}
        onClose={() => setUploadView(!uploadView)}
        lg
      >
        <form onSubmit={handleSubmit(handleSubmitDocuments)}>
          <Box sx={UploadBoxStyle}>
            {fields?.map((field, index) => (
              <Box key={field.id}>
                <Grid container spacing={2}>
                  {/* <Grid item md={3}>
                    <Controller
                      name={`files[${index}].documentTypeId`}
                      control={control}
                      render={({ field }) => (
                        <Autocomplete
                          value={
                            docType?.find(
                              (option) => option.documentTypeId === field.value
                            ) || null
                          }
                          options={docType}
                          getOptionLabel={(option) =>
                            option?.documentTypeName || ''
                          }
                          onChange={(event, value) =>
                            field.onChange(value?.documentTypeId || null)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label='Document Types'
                              variant='outlined'
                              fullWidth
                              error={!!errors.files?.[index]?.documentTypeId}
                              helperText={
                                errors.files?.[index]?.documentTypeId?.message
                              }
                            />
                          )}
                        />
                      )}
                    />
                  </Grid> */}
                </Grid>
                <Box className='flex justify-between gap-10 mt-3 items-center'>
                  <Box className='flex-1 flex items-center gap-10'>
                    {/* <FmFileInput
                      name={`files[${index}].documentName`}
                      documentName={getValues(`files[${index}].documentName`)}
                      onChange={(e) => handleFileUpload(index, e)}
                    /> */}

                    <UploadModalBox />

                    <Box className='flex-1'>
                      <LinearProgress
                        variant='determinate'
                        value={uploadProgress[index] || 0}
                      />
                      <Box sx={{ minWidth: 35, ml: 2 }}>
                        <Typography
                          variant='body2'
                          color='text.secondary'
                        >{`${Math.round(
                          uploadProgress[index] || 0
                        )}%`}</Typography>
                      </Box>
                    </Box>
                  </Box>

                  <Box className='flex items-center gap-10'>
                    <Button
                      startIcon={<Icon icon='ion:remove' />}
                      className='p-0 text-red-600 font-semibold'
                      onClick={() => handleRemove(index)}
                      disabled={fields?.length === 1}
                    >
                      Remove
                    </Button>
                  </Box>
                </Box>

                {/* Hidden input to store fileId */}
                <input type='hidden' {...register(`files[${index}].fileId`)} />
                {/* TODO */}
                {/* {errors?.files?.[index]?.fileId && (
                  <Typography variant="body2" color="error">
                    {errors.files[index].fileId.message}
                  </Typography>
                )} */}
                {uploadProgress[index] !== 100 &&
                  errors?.files?.[index]?.documentName && (
                    <p style={{ color: 'red' }}>
                      {errors.files[index].documentName.message}
                    </p>
                  )}
                <br />
                <Grid item md={6}>
                  <TextField
                    fullWidth
                    label='Remarks'
                    {...register(`files[${index}].remarks`)}
                    multiline
                    variant='outlined'
                  />
                </Grid>
              </Box>
            ))}

            <Button
              className='p-0 font-semibold'
              startIcon={<Icon icon='ion:add-outline' />}
              sx={{ float: 'right' }}
              onClick={() =>
                append({
                  companyId: '',
                  remarks: '',
                  documentName: '',
                  fileId: '',
                })
              }
            >
              Add Fields
            </Button>
            <br />
          </Box>
          <ActionButtons
            onSubmit={handleSubmit(handleSubmitDocuments)}
            onCancel={() => setUploadView(!uploadView)}
            onReset={reset}
            // submitLoading={createLoading}
            cancelLoading={false}
          />
        </form>
      </MuiDialogOne>

      {/* View Details */}
      <MuiDialogFullscreen
        title={false}
        open={showView}
        onClose={() => setShowView(!showView)}
      >
        {/* <Grid container spacing={4}>
          <Grid item md={4}>
            <ViewField label='Document Code' title={viewData?.documentCode} />
          </Grid>
          <Grid item md={4}>
            <ViewField label='Document Name' title={viewData?.documentName} />
          </Grid>
          <Grid item md={4}>
            <ViewField
              label='Document Type'
              title={viewData?.documentTypeName}
            />
          </Grid>
          <Grid item md={4}>
            <ViewField label='Remarks' title={viewData?.remarks} />
          </Grid>
        </Grid> */}
        <FileViewer
          documents={[
            {
              uri: '/images/sample.pdf',
            },
          ]}
          onClose={() => setShowView(!showView)}
          name={viewData?.documentName}
        />
      </MuiDialogFullscreen>
    </GlassCard>
  );
};

export default DocumentUpload;
