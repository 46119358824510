import React, { useState } from 'react';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import { CreateButton } from '../../../components/small/Buttons.jsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { Box, CircularProgress, Grid } from '@mui/material';
import DataTable from '../../../components/DataTable.tsx';
import Crud_Service from '../../../apis/CrudService.jsx';
import TableActionButtons from '../../../components/_form/TableActionButtons.jsx';
import {
  StatusBadge,
  ViewField,
} from '../../../components/_form/FormElements.jsx';
import { useNavigate } from 'react-router-dom';
import useTableLogic from '../../../components/helpers/UseTableLogic.tsx';
import { toast } from 'react-toastify';
import MuiDialogOne from '../../../components/_mui/MuiDialogOne.jsx';
import DataTableTopBar from '../../../components/datagrid/DataTableTopBar.tsx';
import Icons from '../../../utils/Icon.jsx';

const Clients = () => {
  const crud = new Crud_Service();
  const navigate = useNavigate();
  const [viewData, setViewData] = useState(false);
  const [viewLoading, setViewLoading] = useState(true);
  const [selectedRow, setSelectedRow] = useState();
  const {
    rows,
    pageSize,
    pageNumber,
    setSearchKeyword,
    isLoading,
    tableRecordCounts,
    searchKeyword,
    handlePaginationModelChange,
    handleSortModelChange,
    fetchData,
  } = useTableLogic('clients');

  //view the ShiftTiming
  const handleViewClick = async (view) => {
    setViewData(true);
    setViewLoading(true);
    await crud.getSingle('clients', view?.clientId, (err, res) => {
      if (res?.status === 200) {
        setViewLoading(false);
        setSelectedRow(res?.data);
      } else {
      }
    });
  };

  //handle Delete
  const handleDelete = async (props) => {
    await crud.remove('clients', props?.clientId, (err, res) => {
      if (res?.status === 204) {
        fetchData();
        toast.success('Client Deleted successfully');
      }
    });
  };

  const handleEditClick = async (props) => {
    navigate(`edit/${props?.clientId}`);
  };

  const ActionData = [
    {
      name: 'View',
      icon: Icons.view,
      onClick: (props) => handleViewClick(props),
    },
    {
      name: 'Edit',
      icon: Icons.edit,
      // onClick: () => setOpenCreate(!openCreate),
      onClick: (props) => handleEditClick(props),
    },
    {
      name: 'Delete',
      icon: Icons.delete,
      onClick: (props) => handleDelete(props),
      danger: true,
    },
  ];
  const columns = [
    {
      field: 'action',
      headerName: '',
      width: 130,
      sortable: false,
      disableClickEventBubbling: false,
      renderCell: (params) => {
        return (
          <TableActionButtons
            Actions={ActionData?.map((action) => ({
              ...action,
              onClick: () => action.onClick(params.row),
            }))}
          />
        );
      },
    },
    {
      field: 'clientCode',
      headerName: 'Client Code',
      minWidth: 150,
    },

    {
      field: 'clientName',
      headerName: 'Client',
      minWidth: 150,
    },
    {
      field: 'manualClientCode',
      headerName: 'Manual Client Code',
      minWidth: 150,
    },
    {
      field: 'parentClientName',
      headerName: 'Parent Client',
      minWidth: 150,
    },
    {
      field: 'headOfficeAddress',
      headerName: 'Head Office Address',
      minWidth: 200,
    },
    {
      field: 'clientIndustry',
      headerName: 'Client Industry',
      minWidth: 150,
    },
    {
      field: 'contactPerson',
      headerName: 'Contact Person',
      minWidth: 150,
    },
    {
      field: 'contactNumber',
      headerName: 'Contact Number',
      minWidth: 150,
    },
    {
      field: 'contactEmailId',
      headerName: 'Contact Email',
      minWidth: 200,
    },
    {
      field: 'createdDate',
      headerName: 'Created Date',
      minWidth: 150,
    },
    {
      field: 'updatedDate',
      headerName: 'Updated Date',
      minWidth: 150,
    },
  ];

  const onRowDoubleClick = (e) => {
    if (e?.row) {
      handleEditClick(e?.row);
    }
  };

  return (
    <>
      <DashboardLayout
        title='Manage Clients'
        actionButtons={
          <CreateButton name='New' onClick={() => navigate('create')} />
        }
      >
        <GlassCard className='h-full-css'>
          <Box className='h-full-css'>
            <DataTable
              rows={rows}
              columns={columns}
              loading={isLoading}
              onRowDoubleClick={(e) => onRowDoubleClick(e)}
              getRowClassName={(params) =>
                `${
                  params.row.workingStatus === 'InActive' &&
                  'bg-red-500 bg-opacity-15'
                }`
              }
              sortingMode='server'
              paginationMode='server'
              onPaginationModelChange={(model) =>
                handlePaginationModelChange(model)
              }
              onSortModelChange={(model) => handleSortModelChange(model)}
              page={pageNumber - 1}
              pageSize={pageSize}
              rowCount={tableRecordCounts?.total}
              shrinkColumns={true}
              slots={{
                toolbar: () => (
                  <DataTableTopBar
                    setSearchKeyword={setSearchKeyword}
                    searchValue={searchKeyword}
                  />
                ),
              }}
            />
          </Box>
        </GlassCard>

        <MuiDialogOne
          title='View Details'
          open={viewData}
          onClose={() => setViewData(false)}
        >
          {viewLoading ? (
            <Box className='my-4 flex justify-center'>
              <CircularProgress />
            </Box>
          ) : (
            <Grid container spacing={3}>
              <Grid item md={6}>
                <ViewField
                  label='Client Code'
                  title={selectedRow?.clientCode}
                />
              </Grid>

              <Grid item md={6}>
                <ViewField
                  label='Client Name'
                  title={selectedRow?.clientName}
                />
              </Grid>
              <Grid item md={6}>
                <ViewField
                  label='Manual Client Code'
                  title={selectedRow?.manualClientCode}
                />
              </Grid>
              <Grid item md={6}>
                <ViewField
                  label='Parent Client'
                  title={selectedRow?.parentClientName}
                />
              </Grid>
              <Grid item md={6}>
                <ViewField
                  label='Head Office Address'
                  title={selectedRow?.headOfficeAddress}
                />
              </Grid>
              <Grid item md={6}>
                <ViewField
                  label='Client Industry'
                  title={selectedRow?.clientIndustry}
                />
              </Grid>
              <Grid item md={6}>
                <ViewField
                  label='Contact Number'
                  title={selectedRow?.contactNumber}
                />
              </Grid>

              <Grid item md={6}>
                <ViewField
                  label='Contact EmailId'
                  title={selectedRow?.contactEmailId}
                />
              </Grid>

              <Grid item md={6}>
                <ViewField
                  label='Created Date'
                  title={selectedRow?.createdDate}
                />
              </Grid>

              <Grid item md={6}>
                <ViewField
                  label='Updated Date'
                  title={selectedRow?.updatedDate}
                />
              </Grid>

              <Grid item md={6}>
                <ViewField
                  label='Status Name'
                  title={selectedRow?.statusName}
                />
              </Grid>
            </Grid>
          )}
        </MuiDialogOne>
      </DashboardLayout>
    </>
  );
};

export default Clients;
