import { Icon } from '@iconify/react/dist/iconify.js';

const FileManagerIcons = {
  add: <Icon icon='mdi:add' width='24' height='24' />,
  upload: <Icon icon='mdi:upload' width='24' height='24' />,
  paste: <Icon icon='mdi:content-paste' width='24' height='24' />,
  grid: <Icon icon='mdi-light:grid' width='24' height='24' />,
  list: <Icon icon='mdi-light:format-list-bulleted' width='24' height='24' />,
  reload: <Icon icon='mdi-light:refresh' width='24' height='24' />,
  check: <Icon icon='mdi:checkbox-marked-circle' width='24' height='24' />,
  cut: <Icon icon='mdi:content-cut' width='24' height='24' />,
  copy: <Icon icon='mdi:content-copy' width='24' height='24' />,
  rename: <Icon icon='mdi:pencil-outline' width='24' height='24' />,
  download: <Icon icon='mdi-light:cloud-download' width='24' height='24' />,
  delete: <Icon icon='mdi:delete-outline' width='24' height='24' />,
  close: <Icon icon='mdi:close-circle-multiple' width='24' height='24' />,
  folder: <Icon icon='flat-color-icons:folder' width='24' height='24' />,
  folderOpen: (
    <Icon icon='flat-color-icons:opened-folder' width='24' height='24' />
  ),
  home: <Icon icon='mdi:home' width='18' height='18' />,
  horizantal: <Icon icon='mdi:dots-horizontal' width='18' height='18' />,
  right: <Icon icon='mdi:chevron-right' width='18' height='18' />,
  folderLarge: <Icon icon='flat-color-icons:folder' width='60' height='60' />,
};

export default FileManagerIcons;
