import React, { useEffect, useRef, useState } from "react";
import GlassCard from "../../../../../components/small/GlassCard.tsx";
import { Box, Button } from "@mui/material";
import { Icon } from "@iconify/react";
import MuiDialogOne from "../../../../../components/_mui/MuiDialogOne.jsx";
import FmMRTDataTable from "../../../../../components/FmMRTDataTable.tsx";
import useTableLogic from "../../../../../components/helpers/MRTUseTableLogic.tsx";
import { useForm } from "react-hook-form";
import Crud_Service from "../../../../../apis/CrudService.jsx";
import TableActionButtons from "../../../../../components/_form/TableActionButtons.jsx";
import { toast } from "react-toastify";
import { t } from "i18next";
import useCommonFetchApi from "../../../../../components/helpers/useCommonFetchApi.tsx";
import FmTextField from "../../../../../components/_mui/FmTextField.tsx";
import FmSearchableSelect from "../../../../../components/_mui/FmSearchableSelect.tsx";
import { salesorderitemschema } from "../../../../../utils/CommonvalidationSchemas.tsx";
import { yupResolver } from "@hookform/resolvers/yup";
import { useHotkeys } from "react-hotkeys-hook";
import useLoader from "../../../../../components/helpers/UseLoader.tsx";
import apiInstance from "../../../../../apis/ApiService.jsx";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import FmDatePicker from "../../../../../components/_mui/FmDatePicker.tsx";
import FmFileInput from "../../../../../components/_mui/FmFileInput.tsx";
import ActionButtons from "../../../../../components/_form/ActionButtons.tsx";
import { useSelector } from 'react-redux';

const BoldBox = ({ title, primary }) => {
  return (
    <Box className="font-semibold" sx={{ color: primary && "primary.main" }}>
      {title}
    </Box>
  );
};

const VewMrtTableSalesOrder = ({ type, getData, setGetData }) => {
  const id = useParams().id;
  const {
    rows,
    setRows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic(`salesorderitems?salesOrderId=${id}&sortDirection=asc`);
  const [viewDetails, setViewdetails] = useState(false);
  const [viewData, setViewData] = useState(null);
  const crud = new Crud_Service();
  const [editingRowId, setEditingRowId] = useState(null);
  const [editingRowData, setEditingRowData] = useState({});
  const [loader, setLoader] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [rowState, setRowState] = useState({
    newRowId: null,
    isCreatingRow: false,
  });
  const firstColumnInputRef = useRef(null);
  const [stateError, setStateError] = useState(false);
  const [editingRowIndex, setEditingRowIndex] = useState(null);
  const [currentRowIndex, setCurrentRowIndex] = useState(0);
  const [newRowCreated, setNewRowCreated] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadedfileData, setUploadedfileData] = useState(null);
  const { startCreateLoading, stopCreateLoading, createLoading } = useLoader();
  const salesOrderId = useParams().id;
  const {
    control: lineControl,
    handleSubmit: handleLineSubmit,
    setValue,
    watch,
    getValues,
    reset,
    formState: { isValid, errors },
  } = useForm({
    resolver: yupResolver(salesorderitemschema),
    mode: "all",
  });
  const [view, setView] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const { salesQuotation, loading, error } = useSelector(
    (state) => state?.salesQuotation
  );

  useEffect(() => {
    setEditingRowData((prev) => ({
      ...prev,
      description: selectedItem?.description,
      taxCode: selectedItem?.taxCodeCode,
      unit: selectedItem?.unitOfMeasurementName,
      rate: selectedItem?.rate,
    }));
    setValue("rate", selectedItem?.rate);
  }, [selectedItem]);

  useEffect(() => {
    const quantity = watch("quantity");
    const rate = watch("rate");
    if (quantity && rate) {
      const gross = quantity * rate;
      setValue("gross", gross);

      // Update the amountWithTax based on gross, discount, and vat
      const discount = watch("discount") || 0;
      const vat = watch("vat") || 0;
      const discountedGross = gross - (gross * discount) / 100;
      const amountWithTax = discountedGross + (discountedGross * vat) / 100;
      setValue("amountWithTax", amountWithTax);
    }
  }, [watch("quantity"), watch("rate"), watch("discount"), watch("vat")]);
  useEffect(() => {
    if (getData) {
      fetchData();
      setGetData(false);
    }
  }, [getData]);

  const { data: items } = useCommonFetchApi("item");


  const handleExportAMC = async () => {
    const downloadUrl = await apiInstance.getFiles(
      `salesorderitems/${salesOrderId}/export-excel`
    );
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.setAttribute("download", "Sales Order.xlsx");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleSubmitImport = async () => {
    startCreateLoading();
    await crud.create(
      `salesorderitems/${salesOrderId}/process/${uploadedfileData?.fileId}`,
      '',
      (err, res) => {
        if (res?.status === 200) {
          toast.success('Estimation AMC Sheet Detail Created Successfully');
          stopCreateLoading();
          setOpenUpload(false);
          setUploadedfileData(null);
          fetchData();
        } else {
          stopCreateLoading();
          toast.error('Something Went Wrong');
        }
      }
    );
  };

  const handleFileUpload = async (e) => {
    setUploadLoading(true);
    const file = e.target.files[0];
    if (file) {
      const fileFormData = new FormData();
      fileFormData.append('file', file);
      fileFormData.append('fileTypeId', 40);

      await crud.create(`/files/upload`, fileFormData, (err, res) => {
        if (res?.status === 200) {
          setUploadLoading(false);
          setUploadedfileData(res?.data);
        } else {
          setUploadLoading(false);
          toast.error(t('something_Went_Wrong'));
        }
      });
    }
  };

  const handleViewClick = async (props) => {
    setViewdetails(true);
    setViewData(
      `${
        type === "quotation"
          ? props?.salesOrderItemId
          : props?.salesOrderItemId
      }`
    );
  };

  const handleDelete = (props) => {
    crud.remove(
      `${type === "quotation" ? "salesquotationitems" : "salesorderitems"}`,
      `${
        type === "quotation"
          ? props.salesOrderItemId
          : props.salesOrderItemId
      }`,
      (_err, res) => {
        if (res?.status === 204) {
          fetchData();
        } else {
        }
      }
    );
  };

  useEffect(() => {
    if (editingRowIndex !== null && firstColumnInputRef.current && !isLoading) {
      firstColumnInputRef.current.focus();
    }
  }, [editingRowIndex, isLoading]);

  useEffect(() => {
    // Filter rows[0]?.estimationAmcSheetDetails? based on rowSelection keys
    if (rowSelection) {
      const selectedRowIds = rows
        ?.filter((_, index) => rowSelection[index]) // Check if row index is selected in rowSelection
        .map((row) => row?.salesOrderItemId); // Get the `salesOrderItemId` of selected rows[0]?.estimationAmcSheetDetails?

      setSelectedRowIds(selectedRowIds);
    }
  }, [rowSelection]);

  const delectCheckBoxClicked = async () => {
    await crud.remove(
      "estimationamcsheetdetails",
      selectedRowIds,
      (err, res) => {
        if (res) {
          fetchData();
          setSelectedRowIds([]);
          setRowSelection({});
        }
      }
    );
  };


  const handleAddNewRow = () => {
    if (salesQuotation?.salesQuotationId !== null) {
      return;
    }
    const newId = "new_row_" + Date.now();
    reset();
    setEditingRowData(null);
    setEditingRowId(null);
    setRowState({
      newRowId: newId,
      isCreatingRow: true,
    });

    setEditingRowIndex(
      editingRowIndex === rows.length ? editingRowIndex + 1 : rows.length
    );
    setNewRowCreated(false);
    setRows((prevRows) => [
      ...prevRows,
      {
        salesOrderId: newId,
        salesOrderItemId: "", // Ensure this is set as needed
        salesQuotationItemName: "",
        itemId: "",
        divisionId: "",
        quantity: "",
        gross: 0,
        quantity: 0,
        discount: "",
        vat: 0,
        remarks: "",
        isEditable: true,
        isNew: true,
      },
    ]);
  };
 useEffect(() => {
   if(salesQuotation?.salesQuotationId !== null){
    fetchData()
   }
 },[salesQuotation])

  const previousValues = useRef(getValues()); // store previous values to compare with

  const handleBlur = () => {
    const currentValues = getValues();

    // Compare the previous values with the current values
    const hasChanges = Object.keys(currentValues).some(
      (key) => currentValues[key] !== previousValues.current[key]
    );

    if (hasChanges && isValid || hasChanges && editingRowId) {
      apiCallAsync(currentValues); // Call the API with the new values
      previousValues.current = currentValues; // Update previous values
    }
  };
  const apiCallAsync = async (values) => {
    setLoader(true);
    const convertedValues = {
      ...values,
      salesOrderId: salesOrderId,
      quantity: Number(values.quantity) || 0,
      vat: Number(values.vat) || 0,
      gross: Number(values.gross) || 0,
      discount: Number(values.discount) || 0,
      amountWithTax: Number(values.amountWithTax) || 0,
      amount:Number(values.amount) || null,
      contractId: Number(values.contractId) || 0,
      inChargeId: Number(values.inChargeId) || null,
      costCenterId: Number(values.costCenterId) || null,
      locationId: Number(values.locationId) || null,
      taxId: Number(values.taxId) || null,
      accountId: Number(values.accountId) || null,
      setQuantity: Number(values.setQuantity) || 0,
      dueDate: dayjs(values.dueDate).format("YYYY-MM-DD"),
      divsionId: Number(values.divisionId) || null,
      rate: Number(values.rate) || 0,
    };

    const combinedData = {
      ...convertedValues,
    };

    const updateCombinedData = {
      ...values,
      salesOrderId: salesOrderId,
      quantity: Number(values.quantity) || 0,
      vat: Number(values.vat) || 0,
      gross: Number(values.gross) || 0,
      discount: Number(values.discount) || 0,
      amountWithTax: Number(values.amountWithTax) || 0,
      amount:Number(values.amount) || null,
      contractId: Number(values.contractId) || 0,
      inChargeId: Number(values.inChargeId) || null,
      costCenterId: Number(values.costCenterId) || null,
      locationId: Number(values.locationId) || null,
      taxId: Number(values.taxId) || null,
      accountId: Number(values.accountId) || null,
      setQuantity: Number(values.setQuantity) || 0,
      dueDate: dayjs(values.dueDate).format("YYYY-MM-DD"),
      divsionId: Number(values.divisionId) || null,
      rate: Number(values.rate) || 0,
      status: 2,
    };

    // Conditional API call based on whether this is a new row or an update
    if (editingRowId === null) {
      await crud.create("salesorderitems", combinedData, (err, res) => {
        if (res?.status === 201) {
          // fetchData()
          setEditingRowId(res?.data?.salesOrderItemId);
          setValue("salesOrderItemId", res?.data?.salesOrderItemId);
        } else {
        }
      });
    } else {
      await crud.update(
        "salesorderitems",
        editingRowId,
        updateCombinedData,
        (err, res) => {
          if (res?.status === 200) {
            if (rows.some((row) => row.isNew)) {
              const updatedRow = res?.data;
              const updatedRows = [...rows];
              updatedRows[editingRowIndex] = {
                ...updatedRow,
                isEditable: true,
                isNew: true,
              };
              setRows(updatedRows);
              setStateError(false);
              setLoader(false);
            } else {
              setStateError(false);
              setLoader(false);
              const updatedRow = res?.data;
              const updatedRows = [...rows];

              updatedRows[editingRowIndex] = updatedRow;

              setRows(updatedRows);
            }
          } else {
            setStateError(true);
          }
        }
      );
    }
  };

  const handleCancel = (row) => {
    if (row?.original?.salesOrderId === rowState?.newRowId) {
      setRows((prevRows) =>
        prevRows.filter((r) => r?.salesOrderId !== rowState?.newRowId)
      );
      setRowState((prevState) => ({
        ...prevState,
        newRowId: null,
        isCreatingRow: false,
      }));
      setEditingRowIndex(null);
    } else {
      setEditingRowId(null);
      setEditingRowIndex(null);
    }
    reset();
  };
  const handleEdit = (row) => {
    if (rowState?.newRowId) {
      setRows((prevRows) =>
        prevRows.filter((r) => r?.salesOrderId !== rowState?.newRowId)
      );
      setRowState((prevState) => ({
        ...prevState,
        newRowId: null,
        isCreatingRow: false,
      }));
    }
    setEditingRowId(row?.salesOrderItemId);
    Object.entries({
      ...row,
    }).forEach(([key, value]) => {
      setValue(key, value);
    });
    setEditingRowData({ ...row });
  };

  useHotkeys(
    "Enter",
    () => handleAddNewRow(),
    {
      enableOnTags: ["INPUT", "TEXTAREA"], // Optional: Enable hotkey only when focused on specific tags
    },
    [editingRowIndex, rows, stateError, loader]
  );

  useHotkeys(
    "Tab",
    (event) => {
      if (event.key !== "Tab") return;
      if (stateError) {
        event.preventDefault();
        return;
      }
      const totalRows = rows.length;
      const nextRowIndex = editingRowIndex + 1;

      if (
        nextRowIndex < totalRows &&
        stateError === false &&
        loader === false
      ) {
        setCurrentRowIndex(nextRowIndex);
        handleEdit(rows[nextRowIndex]);
        setEditingRowIndex(nextRowIndex);
      }
    },
    [
      editingRowIndex,
      rows,
      stateError,
      loader,
      isValid,
    ]
  );

  const handleBlurAndFetch = async () => {
    setLoader(true);
    const updatedRow = getValues(); // Get the latest values from the form
    const updatedRows = [...rows]; // Clone the rows[0] to avoid direct mutation

    // Update the specific row in the cloned array
    updatedRows[editingRowIndex] = updatedRow;

    setRows(updatedRows);

    const values = getValues();
    const { salesOrderItemId, ...restValues } = values; // Destructure and exclude salesOrderItemId
    const updateCombinedData = {
      ...restValues, // Spread the rest of the values
      salesOrderId: Number(salesOrderId),
      quantity: Number(values.quantity) || 0,
      vat: Number(values.vat) || 0,
      gross: Number(values.gross) || 0,
      discount: Number(values.discount) || 0,
      amountWithTax: Number(values.amountWithTax) || 0,
      amount:Number(values.amount) || null,
      contractId: Number(values.contractId) || 0,
      inChargeId: Number(values.inChargeId) || null,
      costCenterId: Number(values.costCenterId) || null,
      locationId: Number(values.locationId) || null,
      taxId: Number(values.taxId) || null,
      accountId: Number(values.accountId) || null,
      setQuantity: Number(values.setQuantity) || 0,
      dueDate: dayjs(values.dueDate).format("YYYY-MM-DD"),
      divsionId: Number(values.divisionId) || null,
      rate: Number(values.rate) || 0,
      status: 2,
    };
    await crud.update(
      "salesorderitems",
      editingRowId,
      updateCombinedData,
      (err, res) => {
        if (res?.status === 200) {
          const totalRows = rows.length;
          if (
            editingRowIndex <= totalRows &&
            rows.some((row) => row.isNew)
          ) {
            setNewRowCreated(true);
            const updatedRow = res?.data;
            const updatedRows = [...rows];
            updatedRows[editingRowIndex] = {
              ...updatedRow,
              isEditable: true,
              isNew: true,
            };
            setRows(updatedRows);
            handleAddNewRow();
          } else {
            const updatedRow = res?.data;
            const updatedRows = [...rows];
            updatedRows[editingRowIndex] = {
              ...updatedRow,
              isEditable: false,
              isNew: false,
            };
            setRows(updatedRows);
            setStateError(false);
            setLoader(false);
          }
        } else {
          setStateError(true);
        }
      }
    );
  };

  const handleCancelAndAddNewRow = () => {
    const newId = "new_row_" + Date.now();
    reset();
    setEditingRowData(null);
    setEditingRowId(null);
    setRowState({
      newRowId: newId,
      isCreatingRow: true,
    });

    setEditingRowIndex(
      editingRowIndex === rows.length
        ? editingRowIndex + 1
        : rows.length
    );
    setNewRowCreated(false);
    setRows((prevRows) => [
      ...prevRows,
      {
        salesOrderId: newId,
        salesOrderItemId: "", // Ensure this is set as needed
        productCode: "",
        estimationTypeId: "",
        workManPowerName: "",
        unitSellingPrice: 0,
        description: "",
        months: 0,
        quantity: 0,
        unitOfMeasurementId: "",
        currencyId: "",
        isEditable: true,
        isNew: true,
      },
    ]);
    reset();
  };
  const ActionData = [
    {
      name: "Delete",
      icon: <Icon icon="solar:trash-bin-minimalistic-broken" />,
      onClick: (props) => handleDelete(props),
      danger: true,
    },
  ];
  // Define the columns
  const columns = [
    {
      accessorKey: "salesOrderItemName",
      header: "Sales Order Item Name",
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow = row?.original?.salesOrderId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.salesOrderItemId || isNewRow;
        return isEditing ? (
          <>
            <FmTextField
              name="salesOrderItemName"
              required
              control={lineControl}
              label="Quotation Item Name"
              inputRef={firstColumnInputRef}
              onBlur={handleBlur} // Trigger onBlur
            />
          </>
        ) : (
            row?.original?.salesOrderItemName
        );
      },
    },
    {
      accessorKey: "itemName",
      header: "Item name",
      flex: 1,
      minWidth: 200,
      enableEditing: true,
      Cell: ({ cell, row }) => {
        const isNewRow = row?.original?.salesOrderId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.salesOrderItemId || isNewRow;
        return isEditing ? (
          <FmSearchableSelect
            name="itemId"
            control={lineControl}
            apiUrl="item"
            valueField="itemId"
            headerField={["itemCode", "itemName"]}
            labelField={["itemCode", "itemName"]}
            showField={["itemCode", "itemName"]}
            defaultValue={editingRowData}
            
            label="Item Name"
            onChangeProp={(row) => {
              setSelectedItem(row);
            }}
            onBlur={handleBlur}
            required
          />
        ) : (
            row?.original?.itemName || ""
        );
      },
    },
    {
      accessorKey: "contractName",
      header: "Contract Name",
      flex: 1,
      minWidth: 200,
      enableEditing: true,
      Cell: ({ cell, row }) => {
        const isNewRow = row?.original?.salesOrderId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.salesOrderItemId || isNewRow;
        return isEditing ? (
          <FmSearchableSelect
            name="contractId"
            control={lineControl}
            apiUrl="contracts"
            valueField="contractId"
            headerField={["contractCode", "contractName"]}
            labelField={["contractName"]}
            showField={["contractName"]}
            defaultValue={editingRowData}
            
            label="contract"
            onChangeProp={(row) => {
              setSelectedItem(row);
            }}
            onBlur={handleBlur}
            required
          />
        ) : (
            row?.original?.contractName || ""
        );
      },
  },
    {
        accessorKey: "quantity",
        header: "Quantity",
        enableEditing: true,
  
        Cell: ({ cell, row }) => {
          const isNewRow = row?.original?.salesOrderId === rowState?.newRowId;
          const isEditing =
            editingRowId === row?.original?.salesOrderItemId || isNewRow;
          return isEditing ? (
            <FmTextField
              name="quantity"
              control={lineControl}
              defaultValue={editingRowData?.quantity}
              onBlur={handleBlur}
            />
          ) : (
              <BoldBox title={cell?.row?.original?.quantity || ""} />
          );
        },
      },
      {
        accessorKey: "taxCodeName",
        header: "Tax Code",
        flex: 1,
        minWidth: 200,
        enableEditing: true,
        Cell: ({ cell, row }) => {
          const isNewRow = row?.original?.salesOrderId === rowState?.newRowId;
          const isEditing =
            editingRowId === row?.original?.salesOrderItemId || isNewRow;
          return isEditing ? (
            <FmSearchableSelect
              name="taxId"
              control={lineControl}
              apiUrl="taxcodes"
              valueField="taxCodeId"
              headerField={["contractCode", "contractName"]}
              labelField={["taxCodeName"]}
              showField={["taxCodeName"]}
              defaultValue={editingRowData}
              
              label="Tax Code"
              onChangeProp={(row) => {
                setSelectedItem(row);
              }}
              onBlur={handleBlur}
              required
            />
          ) : (
              row?.original?.taxCodeName || ""
          );
        },
    },
    {
      accessorKey: "divisionName",
      header: "Division Name",
      flex: 1,
      minWidth: 200,
      enableEditing: true,
      Cell: ({ cell, row }) => {
        const isNewRow = row?.original?.salesOrderId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.salesOrderItemId || isNewRow;
        return isEditing ? (
          <FmSearchableSelect
            name="divisionId"
            control={lineControl}
            apiUrl="divisions"
            valueField="divisionId"
            headerField={["itemCode", "itemName"]}
            labelField={["divisionName"]}
            showField={["divisionName"]}
            defaultValue={editingRowData}
            
            label="Division"
            onChangeProp={(row) => {
              setSelectedItem(row);
            }}
            onBlur={handleBlur}
            required
          />
        ) : (
            row?.original?.divisionName || ""
        );
      },
    },

    {
        accessorKey: "inChargeName",
        header: "InCharge",
        flex: 1,
        minWidth: 200,
        enableEditing: true,
        Cell: ({ cell, row }) => {
          const isNewRow = row?.original?.salesOrderId === rowState?.newRowId;
          const isEditing =
            editingRowId === row?.original?.salesOrderItemId || isNewRow;
          return isEditing ? (
            <FmSearchableSelect
              name="inChargeId"
              control={lineControl}
              apiUrl="loginusers"
              valueField="loginUserId"
              headerField={["contractCode", "contractName"]}
              labelField={["loginUserName"]}
              showField={["loginUserName"]}
              defaultValue={editingRowData}
              
              label="InCharge"
              onChangeProp={(row) => {
                setSelectedItem(row);
              }}
              onBlur={handleBlur}
              required
            />
          ) : (
              row?.original?.inChargeName || ""
          );
        },
    },
    {
        accessorKey: "costCenterName",
        header: "Cost Center",
        flex: 1,
        minWidth: 200,
        enableEditing: true,
        Cell: ({ cell, row }) => {
          const isNewRow = row?.original?.salesOrderId === rowState?.newRowId;
          const isEditing =
            editingRowId === row?.original?.salesOrderItemId || isNewRow;
          return isEditing ? (
            <FmSearchableSelect
              name="costCenterId"
              control={lineControl}
              apiUrl="costcenters"
              valueField="costCenterId"
              headerField={["contractCode", "contractName"]}
              labelField={["costCenterName"]}
              showField={["costCenterName"]}
              defaultValue={editingRowData}
              
              label="Cost Center"
              onChangeProp={(row) => {
                setSelectedItem(row);
              }}
              onBlur={handleBlur}
              required
            />
          ) : (
              row?.original?.costCenterName || ""
          );
        },
    },
    {
        accessorKey: "locationName",
        header: "Location",
        flex: 1,
        minWidth: 200,
        enableEditing: true,
        Cell: ({ cell, row }) => {
          const isNewRow = row?.original?.salesOrderId === rowState?.newRowId;
          const isEditing =
            editingRowId === row?.original?.salesOrderItemId || isNewRow;
          return isEditing ? (
            <FmSearchableSelect
              name="locationId"
              control={lineControl}
              apiUrl="locations"
              valueField="locationId"
              headerField={["contractCode", "contractName"]}
              labelField={["locationName"]}
              showField={["locationName"]}
              defaultValue={editingRowData}
              
              label="Location"
              onChangeProp={(row) => {
                setSelectedItem(row);
              }}
              onBlur={handleBlur}
              required
            />
          ) : (
              row?.original?.locationName || ""
          );
        },
    },
 
    {
        accessorKey: "accountName",
        header: "Account",
        flex: 1,
        minWidth: 200,
        enableEditing: true,
        Cell: ({ cell, row }) => {
          const isNewRow = row?.original?.salesOrderId === rowState?.newRowId;
          const isEditing =
            editingRowId === row?.original?.salesOrderItemId || isNewRow;
          return isEditing ? (
            <FmSearchableSelect
              name="accountId"
              control={lineControl}
              apiUrl="accounts"
              valueField="accountId"
              headerField={["contractCode", "contractName"]}
              labelField={["accountName"]}
              showField={["accountName"]}
              defaultValue={editingRowData}
              
              label="Account"
              onChangeProp={(row) => {
                setSelectedItem(row);
              }}
              onBlur={handleBlur}
              required
            />
          ) : (
              row?.original?.accountName || ""
          );
        },
    },
    {
      accessorKey: "amountWithTax",
      header: "Amount with tax",
      enableEditing: true,

      Cell: ({ cell, row }) => {
        const isNewRow = row?.original?.salesOrderId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.salesOrderItemId || isNewRow;
        return isEditing ? (
          <FmTextField
            name="amountWithTax"
            control={lineControl}
            defaultValue={editingRowData?.amountWithTax}
            onBlur={handleBlur}
          />
        ) : (
            <BoldBox title={cell?.row?.original?.amountWithTax || ""} />
        );
      },
    },
    {
      accessorKey: "rate",
      header: "Rate",
      enableEditing: true,

      Cell: ({ cell, row }) => {
        const isNewRow = row?.original?.salesOrderId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.salesOrderItemId || isNewRow;
        return isEditing ? (
          <FmTextField
            name="rate"
            control={lineControl}
            defaultValue={editingRowData?.rate}
            onBlur={handleBlur}
          />
        ) : (
            <BoldBox title={cell?.row?.original?.rate || ""} />
        );
      },
    },
    {
      accessorKey: "gross",
      header: "Gross",

      Cell: ({ cell, row }) => {
        const isNewRow = row?.original?.salesOrderId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.salesOrderItemId || isNewRow;
        return isEditing ? (
          <FmTextField
            name="gross"
            control={lineControl}
            defaultValue={editingRowData?.gross}
            disabled
          />
        ) : (
            <BoldBox title={cell?.row?.original?.gross || ""} />
        );
      },
    },
    {
      accessorKey: "discount",
      header: "Discount",
      enableEditing: true,
      Cell: ({ cell, row }) => {
        const isNewRow = row?.original?.salesOrderId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.salesOrderItemId || isNewRow;
        return isEditing ? (
          <FmTextField
            name="discount"
            control={lineControl}
            defaultValue={editingRowData?.discount}
            onBlur={handleBlur}
          />
        ) : (
            <BoldBox title={cell?.row?.original?.discount || ""} />
        );
      },
    },
    {
      accessorKey: "vat",
      header: "VAT",
      enableEditing: true,
      Cell: ({ cell, row }) => {
        const isNewRow = row?.original?.salesOrderId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.salesOrderItemId || isNewRow;
        return isEditing ? (
          <FmTextField
            name="vat"
            control={lineControl}
            defaultValue={editingRowData?.vat}
            onBlur={handleBlur}
          />
        ) : (
            <BoldBox title={cell?.row?.original?.vat || ""} />
        );
      },
    },
    {
      accessorKey: "amount",
      header: "Amount",
      enableEditing: true,

      Cell: ({ cell, row }) => {
        const isNewRow = row?.original?.salesOrderId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.salesOrderItemId || isNewRow;
        return isEditing ? (
          <FmTextField
            name="amount"
            control={lineControl}
            defaultValue={editingRowData?.amount}
            onBlur={handleBlurAndFetch}
          />
        ) : (
            <BoldBox title={cell?.row?.original?.amount || ""} />
        );
      },
    },
    {
        accessorKey: 'dueDate',
        header: 'Due Date',
        enableEditing: true,
        Cell: ({ row }) => {
            const isNewRow = row?.original?.salesOrderId === rowState?.newRowId;
            const isEditing =
              editingRowId === row?.original?.salesOrderItemId || isNewRow;
            return isEditing ?  (
            <FmDatePicker
                name="dueDate"
                label="Due Date"
                defaultValue={editingRowData?.dueDate}
                control={lineControl}
                required
              />
          ) : row?.original?.dueDate ? (
            dayjs(row?.original?.dueDate).format('DD-MM-YYYY')
          ) : (
            ''
          );
        },
      },
    {
        accessorKey: "remarks",
        header: "Remarks",
        enableEditing: true,
  
        Cell: ({ cell, row }) => {
          const isNewRow = row?.original?.salesOrderId === rowState?.newRowId;
          const isEditing =
            editingRowId === row?.original?.salesOrderItemId || isNewRow;
          return isEditing ? (
            <FmTextField
              name="remarks"
              control={lineControl}
              defaultValue={editingRowData?.remarks}
              onBlur={handleBlurAndFetch}
            />
          ) : (
              <BoldBox title={cell?.row?.original?.remarks || ""} />
          );
        },
    },
    {
      header: "Action",
      enableColumnPinning: false,
      Cell: ({ row, table }) => {
        const isNewRow =
          row?.original?.salesOrderItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.salesOrderItemId || isNewRow;

        // Check if the current row is the last row in the table
        const isLastRow = row.index === table.getRowModel().rows.length - 1;

        return (
          <Box className="flex gap-2">
            {isEditing ? (
              <>
                <Button onClick={() => handleCancel(row)}>Close</Button>
                {isLastRow && rowState?.isCreatingRow === false && (
                  <Button
                    onFocus={() => handleCancelAndAddNewRow(row)}
                  ></Button>
                )}
              </>
            ) : (
              <Box className="flex gap-2">
                {salesQuotation?.salesQuotationId === null && (
                  <TableActionButtons
                  Actions={ActionData.map((action) => ({
                    ...action,
                    onClick: () => action.onClick(row?.original),
                  }))}
                />
                )}
                {isLastRow && (
                  <Button
                    variant="contained"
                    color="primary"
                    className="px-3 py-1"
                    disabled={salesQuotation?.salesQuotationId !== null}
                    onClick={() => {
                      setEditingRowIndex(row?.index + 1);
                      handleAddNewRow(row);
                    }}
                  >
                    Add
                  </Button>
                )}
              </Box>
            )}
          </Box>
        );
      },
    },
  ];

  return (
    <>
      <GlassCard className="my-4">
        <FmMRTDataTable
          muiTableContainerProps={{
            sx: {
              maxHeight: "350px",
            },
          }}
          columns={columns}
          enableRowSelection={false}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          delectCheckBoxClicked={delectCheckBoxClicked}
          rows={rows}
          onCreateRow={handleAddNewRow}
          isCreatingRow={rowState?.isCreatingRow}
          rowCount={tableRecordCounts}
          showPagination={false}
          editingRow={editingRowId}
          pagination={pagination}
          setSearchKeyword={setSearchKeyword}
          sorting={sorting}
          columnFilters={columnFilters}
          globalFilter={globalFilter}
          setPagination={setPagination}
          setSorting={setSorting}
          setColumnFilters={setColumnFilters}
          setGlobalFilter={setGlobalFilter}
          isLoading={isLoading}
          disableNewButton={rows.length === 0}
          setSearchKeyword={setSearchKeyword}
          searchKeyword={searchKeyword}
          columnPining={false}
          fetchData={fetchData}
          handleRowDoubleClick={(row) => {
            if (salesQuotation?.salesQuotationId !== null) {
              return;
            }
            handleEdit(row?.original);
            setEditingRowIndex(row?.index);
          }}
          renderTopToolbarCustomActionsChildrens={
            <Box className="flex justify-end gap-2 me-2">
              <Button
                variant="outlined"
                color="primary"
                className="px-3 py-1"
                disabled={salesQuotation?.salesQuotationId !== null}
                onClick={() => {
                  setOpenUpload(true);
                  setUploadedfileData(null);
                }}
              >
                Import
              </Button>
              <Button
                variant="outlined"
                color="primary"
                className="px-3 py-1"
                onClick={handleExportAMC}
              >
                Export
              </Button>
            </Box>
          }
        />

<MuiDialogOne
          open={openUpload}
          onClose={() => setOpenUpload(false)}
          title="Import Sales Order Items"
          description="Upload your amc details Xlsx file"
        >
          <FmFileInput
            name="file"
            documentName={uploadedfileData?.originalFileName || ""}
            onChange={handleFileUpload}
            loading={uploadLoading}
          />
          <ActionButtons
            onSubmit={handleSubmitImport}
            onCancel={() => setOpenUpload(false)}
            submitLoading={createLoading}
            onReset={reset}
            cancelLoading={false}
          />
        </MuiDialogOne>
      </GlassCard>
    </>
  );
};

export default VewMrtTableSalesOrder;
