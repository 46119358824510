import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import DataTable from '../../../components/DataTable.tsx';
import PageHeader from '../../../components/PageHeader.jsx';
import { CreateButton } from '../../../components/small/Buttons.jsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import DataTableToolbarButtons from '../../../components/helpers/DataTableToolbarButtons.tsx';
import useTableLogic from '../../../components/helpers/UseTableLogic.tsx';
import TableSearch from '../../../components/_form/TableSearch.jsx';
import apiInstance from '../../../apis/ApiService.jsx';
import { ToolbarBoxesFlex } from '../../../components/boxes/Boxes.jsx';
import TableAvatar from '../../../components/_form/TableAvatar.jsx';
import FmViewDateField from '../../../components/_mui/FmViewDateField.tsx';
import { useSelector } from 'react-redux';
import { findNavByName } from '../../../utils/navUtils.ts';
import { ExpiryDateCellColor } from '../../../utils/Date/ExpiryDateCellColor.tsx';
import TrackerExpiryDate, {
  TrackerExpiryDateLegend,
} from '../../../components/elements/TrackerExpiryDate.tsx';

const VisaTracker = (props) => {
  const { type } = props;
  const navigate = useNavigate();
  const menu = useSelector((state) => state?.menu?.items);
  const {
    rows,
    pageSize,
    pageNumber,
    setSearchKeyword,
    isLoading,
    tableRecordCounts,
    handlePaginationModelChange,
    handleSortModelChange,
    fetchData,
    setRows,
    toggleLoading,
    searchKeyword,
  } = useTableLogic('visa-details');

  //   useEffect(() => {
  //     document.title = `${type === 'HRMS' ? 'HRMS' : 'PR'}-Passport Request`;
  //   }, []);

  const handleEditClick = (view) => {
    navigate(`edit/${view?.employeePassportRequestId}`);
  };

  const columns = [
    //TODO: For Future Use
    // {
    //   field: 'column_sort',
    //   headerName: '',
    //   width: 230,
    //   sortable: false,
    //   disableClickEventBubbling: false,
    //   renderCell: (params) => {
    //     return (
    //       <Box className='flex gap-2'>
    //         {/* Render TableActionButtons */}
    //         <TableActionButtons
    //           Actions={Actions?.map((action) => ({
    //             ...action,
    //             onClick: () => {
    //               action.onClick(params.row);
    //             },
    //           }))}
    //           selectedRow={params.row}
    //           setSelectedRow={setSelectedRow}
    //         />
    //       </Box>
    //     );
    //   },
    // },
    {
      field: 'employeeCode',
      headerName: 'Code',
      minWidth: 150,
    },
    {
      field: 'employeeName',
      headerName: 'Name',
      minWidth: 400,
      renderCell: (params) => {
        return <TableAvatar name={params.value} />;
      },
    },

    {
      field: 'issuedDate',
      headerName: 'Issued Date',
      flex: 5,
      minWidth: 150,
      renderCell: (params) => FmViewDateField({ value: params?.value }),
    },
    {
      field: 'expiryDate',
      headerName: 'Expiry Date',
      flex: 5,
      minWidth: 150,
      cellClassName: (params) => ExpiryDateCellColor(params?.value),
      renderCell: (params) => (
        <TrackerExpiryDate
          date={FmViewDateField({ value: params?.value })}
          value={params?.value}
        />
      ),
    },
    {
      field: 'visaStatusName',
      headerName: 'Visa Status',
      flex: 5,
      minWidth: 150,
    },
    {
      field: 'visaNumber',
      headerName: 'Visa Number',
      flex: 5,
      minWidth: 150,
    },
    {
      field: 'issuedOrganization',
      headerName: 'Issued Organization',
      flex: 5,
      minWidth: 200,
    },
    {
      field: 'immigrationStatusName',
      headerName: 'Immigration Status',
      flex: 5,
      minWidth: 180,
    },
    {
      field: 'remainingDays',
      headerName: 'Remaining Days',
      flex: 5,
      minWidth: 150,
    },
    {
      field: 'delayDays',
      headerName: 'Delay Days',
      flex: 5,
      minWidth: 200,
    },
    {
      field: 'createdDate',
      headerName: 'Created Date',
      flex: 5,
      minWidth: 150,
      renderCell: (params) => FmViewDateField({ value: params.value }),
    },
    {
      field: 'updatedDate',
      headerName: 'Updated Date',
      flex: 5,
      minWidth: 150,
      renderCell: (params) => FmViewDateField({ value: params.value }),
    },
  ];

  const counters = [];

  const clearFilter = () => {
    setRows(rows);
  };

  //Row Double Click
  const onRowDoubleClick = (e) => {
    // if (e?.row) {
    //   handleEditClick(e?.row);
    // }
  };

  const downloadEmployees = async () => {
    toggleLoading(true);
    const downloadUrl = await apiInstance.getFiles(
      'employee-passport-requests/exportexcel'
    );

    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', 'employees.xlsx');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    toggleLoading(false);
  };

  const SubMenu = useMemo(() => {
    return menu?.map((item) => item?.menus?.flat()).flat() || [];
  }, [menu]);

  const resultHRMS = useMemo(
    () => findNavByName(SubMenu, 'HRMS', 'Trackers'),
    [SubMenu]
  );
  const resultPR = useMemo(
    () => findNavByName(SubMenu, 'PR', 'Trackers'),
    [SubMenu]
  );

  return (
    <>
      <DashboardLayout
        title='Visa Tracker'
        actionButtons={
          <Box className='flex flex-row items-center whitespace-nowrap gap-3'>
            <PageHeader counters={counters} />
          </Box>
        }
        hasSubmenu
        menu={type === 'PR' ? resultPR : resultHRMS}
      >
        <GlassCard className='h-full'>
          <Box className='h-full'>
            <DataTable
              rows={rows}
              columns={columns}
              loading={isLoading}
              onRowDoubleClick={(e) => onRowDoubleClick(e)}
              getRowClassName={(params) =>
                `${
                  params.row.workingStatus === 'InActive' &&
                  'bg-red-500 bg-opacity-15'
                }`
              }
              sortingMode='server'
              paginationMode='server'
              onPaginationModelChange={(model) =>
                handlePaginationModelChange(model)
              }
              onSortModelChange={(model) => handleSortModelChange(model)}
              page={pageNumber - 1}
              pageSize={pageSize}
              rowCount={tableRecordCounts?.total}
              slots={{
                toolbar: () => (
                  <ToolbarBoxesFlex>
                    <TableSearch
                      placeholder='Search'
                      setSearchKeyword={setSearchKeyword}
                      searchValue={searchKeyword}
                    />
                    {/* <DataTableToolbarButtons
                      handleExcelExport={downloadEmployees}
                    /> */}
                    <TrackerExpiryDateLegend />
                  </ToolbarBoxesFlex>
                ),
              }}
            />
          </Box>
        </GlassCard>
      </DashboardLayout>
    </>
  );
};

export default VisaTracker;
