import axios from 'axios';
import { toast } from 'react-toastify';
import { store } from '../store/store.ts';
import { getRequest, userRequest } from './RequestMethod';

const getToken = () => {
  const state = store.getState();
  return state.auth.token;
};

let prevToken = getToken();

store.subscribe(() => {
  const currentToken = getToken();
  if (prevToken !== currentToken) {
    prevToken = currentToken;
  }
});
class API {
  constructor() {
    this.rootUrl = process.env.REACT_APP_BASE;
  }

  getToken() {
    return getToken();
  }

  handleError = (error) => {
    const errorMessage =
      error?.response?.data?.error ||
      error?.response?.data?.errors ||
      error?.response?.data?.message ||
      error?.response?.data?.Message ||
      error?.message ||
      'An error occurred';

    if (error.code === 'ERR_CONNECTION_REFUSED') {
      toast.error('Server Under Maintenance');
    } else if (error.code === 'ERR_NETWORK') {
      toast.error('Please check your Internet Connection');
    } else {
      toast.error(errorMessage);
    }
  };

  login = async (data) => {
    try {
      const res = await getRequest.post('auth/login', data);
      return res;
    } catch (error) {
      this.handleError(error);
    }
  };
  logout = async (url, params) => {
    try {
      const res = await userRequest.delete(url, params);
      return res;
    } catch (error) {
      this.handleError(error);
    }
  };
  NavMenu = async (url, params) => {
    try {
      const res = await userRequest.get(url, params);
      return res;
    } catch (error) {
      this.handleError(error);
    }
  };
  logoutImpersonate = async (url, params) => {
    try {
      const res = await userRequest.post(url, params);
      return res;
    } catch (error) {
      this.handleError(error);
    }
  };
  impersonate = async (data) => {
    try {
      const res = await userRequest.post('auth/impersonate', data);
      return res;
    } catch (error) {
      this.handleError(error);
    }
  };

  getAll = async (url, params) => {
    try {
      const res = await userRequest.get(url, {
        params: params,
        headers: {
          authorization: `Bearer ${this.getToken()}`,
        },
      });
      return res;
    } catch (error) {
      this.handleError(error);
    }
  };

  getSingle = async (url, params) => {
    try {
      const res = await userRequest.get(`${url}/${params}`, {
        headers: {
          authorization: `Bearer ${this.getToken()}`,
        },
      });
      return res;
    } catch (error) {
      this.handleError(error);
    }
  };

  // Get files function
  getFiles = async (url) => {
    try {
      const res = await axios.get(`${this.rootUrl}/${url}`, {
        responseType: 'blob',
        headers: {
          authorization: `Bearer ${this.getToken()}`,
        },
      });

      if (res.status === 200) {
        const reader = new FileReader();
        reader.readAsDataURL(res.data);
        return new Promise((resolve) => {
          reader.onloadend = function () {
            resolve(reader.result);
          };
        });
      }
    } catch (err) {
      this.handleError(err);
      throw err;
    }
  };

  // Post files
  postFiles = async (url, data) => {
    try {
      const res = await axios.post(`${this.rootUrl}/${url}`, data, {
        responseType: 'blob',
        headers: {
          authorization: `Bearer ${this.getToken()}`,
        },
      });

      if (res.status === 200) {
        const reader = new FileReader();
        reader.readAsDataURL(res.data);
        return new Promise((resolve) => {
          reader.onloadend = function () {
            resolve(reader.result);
          };
        });
      }
    } catch (err) {
      this.handleError(err);
      throw err;
    }
  };

  create = async (url, data) => {
    try {
      const res = await userRequest.post(url, data);
      return res;
    } catch (error) {
      this.handleError(error);
    }
  };

  update = async (url, params, data) => {
    try {
      const res = await userRequest.put(`${url}/${params}`, data);
      return res;
    } catch (error) {
      this.handleError(error);
    }
  };

  patch = async (url, params, data) => {
    try {
      const finalUrl = params ? `${url}/${params}` : url;
      const res = await userRequest.patch(finalUrl, data);
      return res;
    } catch (error) {
      this.handleError(error);
    }
  };

  remove = async (url, params) => {
    try {
      const fullUrl = params ? `${url}/${params}` : url;
      const res = await userRequest.delete(fullUrl);
      return res;
    } catch (error) {
      this.handleError(error);
    }
  };

  delete = async (url, data) => {
    try {
      const res = await userRequest.delete(url, { data: data });
      return res;
    } catch (error) {
      this.handleError(error);
    }
  };

  getAllEmployee = async (employeeSearchValue) => {
    try {
      const res = await userRequest.get(
        employeeSearchValue && employeeSearchValue !== ''
          ? `employees?searchKeyword=${employeeSearchValue}`
          : 'employees'
      );
      return res;
    } catch (error) {
      this.handleError(error);
    }
  };

  getLoginUsers = async () => {
    try {
      const res = await userRequest.get('loginusers?pageSize=100&pageNumber=1');
      return res;
    } catch (error) {
      this.handleError(error);
    }
  };
  filter = async (url, params) => {
    const qparms = { ...params };
    Object.keys(qparms).forEach(
      (key) =>
        qparms[key] === undefined || (qparms[key] === '' && delete qparms[key])
    );
    try {
      const res = await userRequest.get(url, { params: qparms });
      return res;
    } catch (error) {
      this.handleError(error);
    }
  };
}

const apiInstance = new API();

export default apiInstance;
