import React, { useState, useEffect } from 'react';
import { Grid, InputAdornment, Box, Alert } from '@mui/material';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import { ActionButtons } from '../../../../components/_form';
import Crud_Service from '../../../../apis/CrudService';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import { toast } from 'react-toastify';
import { Controller, useForm } from 'react-hook-form';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import FmAutoComplete from '../../../../components/_mui/FmAutoComplete.tsx';

const Allowance = (props) => {
  const { setEnabledTabs, setValues, createdEmployee, value } = props;
  const [payGroups, setPayGroups] = useState([]);
  const { handleSubmit, control, setValue, reset } = useForm({
    defaultValues: {
      effectiveDate: dayjs(),
    },
  });
  const { createLoading, startCreateLoading, stopCreateLoading } = useLoader();
  const crud = new Crud_Service();
  const [formValues, setFormValues] = useState({
    totalSalary: 0,
    basicSalary: 0,
    housingAllowance: 0,
    transportAllowance: 0,
    foodAllowance: 0,
    otherAllowance: 0,
    airTicketAllowancePerMonth: 0,
    airTicketAllowancePerYear: 0,
    telephoneAllowance: 0,
    additionalAllowance: 0,
    grossSalary: 0,
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const {
      basicSalary,
      housingAllowance,
      transportAllowance,
      otherAllowance,
      foodAllowance,
      airTicketAllowancePerMonth,
      telephoneAllowance,
      additionalAllowance,
      incentiveAllowance,
    } = formValues;

    const allowancesSum =
      (basicSalary || 0) +
      (housingAllowance || 0) +
      (transportAllowance || 0) +
      (otherAllowance || 0);
    setValue('grossSalary', allowancesSum);
    const totalAllowances =
      (incentiveAllowance || 0) +
      (foodAllowance || 0) +
      (airTicketAllowancePerMonth || 0) +
      (telephoneAllowance || 0) +
      (additionalAllowance || 0) +
      (allowancesSum || 0);

    setValue('totalSalary', totalAllowances);
  }, [formValues]);

  useEffect(() => {
    if (value === 4) {
      getAllPayGroup();
    }
  }, [value]);

  const getAllPayGroup = async () => {
    await crud.getAll('paygroups', {}, (_err, res) => {
      if (res?.status === 200) {
        setPayGroups(res?.data?.data);
      } else {
      }
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormValues((prevValues) => {
      const updatedValues = {
        ...prevValues,
        [name]: Number(value) || 0,
      };
      if (name === 'airTicketAllowancePerMonth') {
        updatedValues.airTicketAllowancePerYear = (Number(value) || 0) * 12;
        setValue(
          'airTicketAllowancePerYear',
          updatedValues?.airTicketAllowancePerYear
        );
      }
      if (name === 'bonusPerMonth') {
        updatedValues.bonusPerYear = (Number(value) || 0) * 12;
        setValue('bonusPerYear', updatedValues?.bonusPerYear);
      }

      return updatedValues;
    });
  };

  const handleSubmitNew = async (values) => {
    if (errors.totalSalary) {
      toast.error(errors.totalSalary);
      return;
    }

    startCreateLoading();
    const combinedData = {
      ...values,
      employeeId: createdEmployee?.employeeId,
      totalSalary: Number(values?.totalSalary),
      deductions: Number(values?.deductions),
      effectiveDate: dayjs(values?.effectiveDate).format('YYYY-MM-DD'),
    };
    crud.create('employeesalarysplitups', combinedData, (err, res) => {
      if (err) {
        stopCreateLoading();
        toast.error('Error creating employee:', err);
        return;
      }
      if (res?.status === 201) {
        setValues(5);
        toast.success('Employee Salary Split Created Successfully');
        setEnabledTabs(1);
        setEnabledTabs(2);
        setEnabledTabs(3);
        setEnabledTabs(4);
        setEnabledTabs(5);
        setEnabledTabs(6);
        stopCreateLoading();
      } else {
        stopCreateLoading();
      }
    });
  };

  return (
    <>
      <GlassCard className='p-4'>
        <form onSubmit={handleSubmit(handleSubmitNew)}>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <FmAutoComplete
                name='payGroupId'
                control={control}
                options={payGroups}
                label='Pay Group'
                displayField='payGroupName'
                optionFields={['payGroupName']}
                valueKey='payGroupId'
              />
            </Grid>
            <Grid item md={6}>
              <FmTextField
                name='basicSalary'
                control={control}
                label='Basic Salary'
                pattern='Number'
                rules={{
                  required: 'Basic Salary is required',
                }}
                value={formValues?.basicSalary || 0}
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <Box
                        sx={{
                          fontSize: '10px',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        AED
                      </Box>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item md={6}>
              <FmTextField
                name='housingAllowance'
                control={control}
                label='Housing Allowance'
                pattern='Number'
                value={formValues?.housingAllowance || 0}
                onChange={handleChange}
              />
            </Grid>
            <Grid item md={6}>
              <FmTextField
                name='transportAllowance'
                control={control}
                label='Transport Allowance'
                pattern='Number'
                value={formValues?.transportAllowance || 0}
                onChange={handleChange}
              />
            </Grid>
            <Grid item md={6}>
              <FmTextField
                name='otherAllowance'
                control={control}
                label='Other Allowance'
                pattern='Number'
                value={formValues?.otherAllowance}
                onChange={handleChange}
              />
            </Grid>
            <Grid item md={6}>
              <FmTextField
                name='grossSalary'
                control={control}
                label='Gross Salary'
                pattern='Number'
                value={formValues?.grossSalary || 0}
                readOnly={true}
              />
            </Grid>
            <Grid item md={6}>
              <FmTextField
                name='foodAllowance'
                control={control}
                label='Food Allowance'
                pattern='Number'
                value={formValues?.foodAllowance}
                onChange={handleChange}
              />
            </Grid>

            <Grid item md={6}>
              <FmTextField
                name='airTicketAllowancePerMonth'
                control={control}
                label='Air Ticket Allowance'
                pattern='Number'
                value={formValues?.airTicketAllowancePerMonth || 0}
                onChange={handleChange}
              />
            </Grid>
            <Grid item md={6}>
              <FmTextField
                name='airTicketAllowancePerYear'
                control={control}
                label='Air Ticket Allowance per year'
                readOnly={true}
              />
            </Grid>
            <Grid item md={6}>
              <FmTextField
                name='telephoneAllowance'
                control={control}
                label='Telephone Allowance'
                pattern='Number'
                value={formValues?.telephoneAllowance || 0}
                onChange={handleChange}
              />
            </Grid>
            <Grid item md={6}>
              <FmTextField
                name='additionalAllowance'
                control={control}
                label='Additional Allowance'
                pattern='Number'
                value={formValues?.additionalAllowance || 0}
                onChange={handleChange}
              />
            </Grid>
            <Grid item md={6}>
              <FmTextField
                name='incentiveAllowance'
                control={control}
                label='Incentive Allowance'
                pattern='Number'
                value={formValues?.incentiveAllowance || 0}
                onChange={handleChange}
              />
            </Grid>

            <Grid item md={6}>
              <FmTextField
                name='totalSalary'
                control={control}
                label='Total Salary'
                pattern='Number'
                rules={{
                  required: 'Total Salary is required',
                }}
                value={formValues?.totalSalary || 0}
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <Box
                        sx={{
                          fontSize: '10px',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        AED
                      </Box>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item md={6}>
              <FmTextField
                name='bonusPerMonth'
                control={control}
                label='Bonus Per Month'
                pattern='Number'
                value={formValues?.bonusPerMonth || 0}
                onChange={handleChange}
              />
            </Grid>
            <Grid item md={6}>
              <FmTextField
                name='bonusPerYear'
                control={control}
                label='Bonus Per year'
                readOnly={true}
              />
            </Grid>
            <Grid item md={6}>
              <FmTextField
                name='deductions'
                control={control}
                label='Deductions'
                pattern='Number'
              />
            </Grid>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid item md={6}>
                <Controller
                  name='effectiveDate'
                  control={control}
                  defaultValue={null}
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      label='Effective Date'
                      value={value}
                      defaultValue={dayjs(new Date())}
                      minDate={dayjs()}
                      sx={{ width: '100%' }}
                      format='DD/MM/YYYY'
                      onChange={(newValue) => {
                        onChange(newValue);
                      }}
                    />
                  )}
                />
              </Grid>
            </LocalizationProvider>
          </Grid>
          <br />
          {errors?.totalSalary && (
            <Alert severity='error'>{errors?.totalSalary}</Alert>
          )}
          <ActionButtons
            onSubmit={handleSubmit(handleSubmitNew)}
            onReset={reset}
            onCancel={() => setValues(3)}
            submitLoading={createLoading}
            cancelLoading={false}
          />
        </form>
      </GlassCard>
    </>
  );
};

export default Allowance;
