import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import { useNavigate, useParams } from 'react-router-dom';
import CommonView from '../../../../components/helpers/CommonView.jsx';
import DynamicViewFields from '../../../../components/helpers/DynamicViewFields.jsx';
import { CreateButton } from '../../../../components/small/Buttons.jsx';
import ActionButtons from '../../../../components/_form/ActionButtons.tsx';
import { Grid } from '@mui/material';
import {
  ViewField,
  ViewTable,
} from '../../../../components/_form/FormElements.jsx';
import Crud_Service from '../../../../apis/CrudService.jsx';
import StaticTable from '../../../../components/_form/StaticTable.jsx';

const ViewMIPPremiumCalculator = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(false);
  const crud = new Crud_Service();
  const { id } = useParams();

  useEffect(() => {
    getMIPPremiumCalculator();
  }, []);

  const getMIPPremiumCalculator = async () => {
    setLoading(true);
    await crud.getSingle('medicalinsurancepolicies', id, (_err, res) => {
      if (res?.status === 200) {
        setLoading(false);
        setData(res?.data);
      } else {
        setLoading(false);
      }
    });
  };

  return (
    <DashboardLayout
      title='View MIP Premium Calculator'
      actionButtons={
        <CreateButton
          name='Create'
          onClick={() =>
            navigate(
              '/admin/company-medical-insurance-policy/medical-insurance-policy-premium-calculator/create'
            )
          }
        />
      }
    >
      <GlassCard className='p-5'>
        <Grid container spacing={1}>
          <Grid item md={3}>
            <ViewField
              label='Company MedicalInsurance AgeBandName'
              title={data?.companyMedicalInsuranceAgeBandName}
            />
          </Grid>
          <Grid item md={3}>
            <ViewField
              label='Company MedicalInsurance DependencyName'
              title={data?.companyMedicalInsuranceDependencyName}
            />
          </Grid>
          <Grid item md={3}>
            <ViewField label='Maternity' title={data?.maternity} />
          </Grid>
          <Grid item md={3}>
            <ViewField label='Maximum Age' title={data?.maximumAge} />
          </Grid>
          <Grid item md={3}>
            <ViewField label='Minimum Age' title={data?.minimumAge} />
          </Grid>
          <Grid item md={3}>
            <ViewField label='Principal' title={data?.principal} />
          </Grid>
          <Grid item md={3}>
            <ViewField label='Region' title={data?.regionName} />
          </Grid>
        </Grid>
        <Grid container spacing={1} className='mt-3'>
          <Grid item md={6}>
            <StaticTable
              columns={[
                { field: 'gradeName', headerName: 'Grade' },
                { field: 'male', headerName: 'Male' },
                { field: 'female', headerName: 'Female' },
              ]}
              data={data?.medicalInsurancePolicyCategories}
            />
          </Grid>
        </Grid>

        <ActionButtons
          cancelLoading={false}
          cancelText='Cancel'
          submitText='Edit'
          onSubmit={() =>
            navigate(
              `/admin/company-medical-insurance-policy/medical-insurance-policy-premium-calculator/edit/${id}`
            )
          }
          onCancel={() =>
            navigate(
              '/admin/company-medical-insurance-policy/medical-insurance-policy-premium-calculator'
            )
          }
        />
      </GlassCard>
    </DashboardLayout>
  );
};

export default ViewMIPPremiumCalculator;
