import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Crud_Service from '../../../apis/CrudService.jsx';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import FmMRTDataTable from '../../../components/FmMRTDataTable.tsx';
import useTableLogic from '../../../components/helpers/MRTUseTableLogic.tsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { findNavByName } from '../../../utils/navUtils.ts';

const Asateel = ({ locationId }) => {
  const crud = new Crud_Service();
  const { id } = useParams();
  const [rowState, setRowState] = useState({
    newRowId: null,
    isCreatingRow: false,
  });
  const { setValue } = useForm();
  const [editingRowId, setEditingRowId] = useState(null);
  const [editingRowData, setEditingRowData] = useState(null);
  const [viewdetails, setViewdetails] = useState(null);
  const [availableQuantity, setAvailableQuantity] = useState(0);

  const {
    rows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setSearchKeyword,
    searchKeyword,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
  } = useTableLogic('vehicleasateels');

  const columns = [
    { header: 'Vehicle Asateel Code', accessorKey: 'vehicleAsateelCode' },
    { header: 'Vehicle Name', accessorKey: 'vehicleName' },
    { header: 'Vehicle Category Name', accessorKey: 'vehicleCategoryName' },
    { header: 'Vehicle Brand Name', accessorKey: 'vehicleBrandName' },
    { header: 'Vehicle Plate Type Name', accessorKey: 'vehiclePlateTypeName' },
    { header: 'Vehicle Plate No', accessorKey: 'vehiclePlateNo' },
    { header: 'Vehicle Type Name', accessorKey: 'vehicleTypeName' },
    { header: 'Type Name', accessorKey: 'typeName' },
    { header: 'Division Name', accessorKey: 'divisionName' },
    { header: 'Concern Person', accessorKey: 'concernPerson' },
    { header: 'Date of Installation', accessorKey: 'dateOfInstallation' },
    { header: 'Date of Expiry', accessorKey: 'dateOfExpiry' },
  ];

  const menu = useSelector((state) => state?.menu?.items);

  const SubMenu = useMemo(() => {
    return menu?.map((item) => item?.menus?.flat()).flat() || [];
  }, [menu]);

  const result = useMemo(
    () => findNavByName(SubMenu, 'Admin', 'Vehicles'),
    [SubMenu]
  );

  return (
    <>
      <DashboardLayout title='Asateel' hasSubmenu menu={result}>
        <GlassCard>
          <FmMRTDataTable
            columns={columns}
            enableRowSelection={false}
            rows={rows}
            isCreatingRow={rowState?.isCreatingRow}
            rowCount={tableRecordCounts}
            editingRow={editingRowId}
            pagination={pagination}
            setSearchKeyword={setSearchKeyword}
            sorting={sorting}
            columnFilters={columnFilters}
            globalFilter={globalFilter}
            setPagination={setPagination}
            setSorting={setSorting}
            setColumnFilters={setColumnFilters}
            setGlobalFilter={setGlobalFilter}
            isLoading={isLoading}
            setSearchKeyword={setSearchKeyword}
            searchKeyword={searchKeyword}
          />
        </GlassCard>
      </DashboardLayout>
    </>
  );
};

export default Asateel;
