import React, { useEffect, useRef, useState } from 'react';
import { Box, Button } from '@mui/material';
import Crud_Service from '../../../apis/CrudService';
import { toast } from 'react-toastify';
import useCommonFetchApi from '../../../components/helpers/useCommonFetchApi.tsx';
import BackToButton from './../../../components/small/BackToButton';
import TenderCard from './TenderCard.jsx';
import TotalBox from './TotalBox.jsx';
import { Icon } from '@iconify/react/dist/iconify.js';
import FmFileInput from '../../../components/_mui/FmFileInput.tsx';
import ActionButtons from '../../../components/_form/ActionButtons.tsx';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import MuiDialogOne from '../../../components/_mui/MuiDialogOne.jsx';
import apiInstance from '../../../apis/ApiService.jsx';
import { useForm } from 'react-hook-form';
import { t } from 'i18next';
import useTableLogic from '../../../components/helpers/MRTUseTableLogic.tsx';
import FmMRTDataTable from '../../../components/FmMRTDataTable.tsx';
import TableActionButtons from '../../../components/_form/TableActionButtons.jsx';
import FmAutoComplete from '../../../components/_mui/FmAutoComplete.tsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import { AMCTableValidationSchema } from '../../../utils/CommonvalidationSchemas.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHotkeys } from 'react-hotkeys-hook';
import GlassCard from '../../../components/small/GlassCard.tsx';
import axios from 'axios';

const ViewDetails = ({ handleBackButton, TableData }) => {
  const [total, setTotalAmount] = useState(0);
  const [amcEstimationSheetId, setAMCEstimationSheetId] = useState(null);
  const [amcSheetDetailsCall, setAmcSheetDetailsCall] = useState(false);


  useEffect(() => {
    getEstimationAMC();
  }, []);


  const getEstimationAMC = async () => {
    setTotalBoxLoader(true);
    await crud.getAll(
      `estimationamcsheets`,
      TableData?.estimationAmcSheetId,
      (err, res) => {
        if (err) {
          toast.error(err);
        }
        if (res?.status === 200) {
          setEstimationAMC(res?.data);
          setAMCEstimationSheetId(res?.data?.data[0]);
          setAmcSheetDetailsCall(true)
          setAmcSheetsPercent((prev) => ({
            ...prev,
            overHeadOverAllPercent: res?.data?.overHeadOverAllPercent || 0,
            overHeadCost: res?.data?.overHeadCost || 0,
            contingencyOverAllPercent:
              res?.data?.contingencyOverAllPercent || 0,
            contingencyCost: res?.data?.contingencyCost || 0,
            otherOverAllPercent: res?.data?.otherOverAllPercent,
            otherCost: res?.data?.otherCost,
            markUpOverAllPercent: res?.data?.markUpOverAllPercent || 0,
            markUpCost: res?.data?.markUpCost || 0,
            discountOverAllPercent: res?.data?.discountOverAllPercent,
            discountCost: res?.data?.discountCost,
            negotiationPercent: res?.data?.negotiationPercent,
            negotiationCost: res?.data?.negotiationCost,
            totalEstimationCost: res?.data?.totalEstimationCost || 0,
            note: res?.data?.note || '',
          }));
          setTotalBoxLoader(false);
        }
      }
    );
  };

  const {
    rows,
    setRows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setSearchKeyword,
    searchKeyword,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
  } = useTableLogic(
    `estimationamcsheetdetails/${TableData?.estimationAmcSheetId}/amc-details?sortDirection=asc`,
  );
  const [estimationAMC, setEstimationAMC] = useState({});

  const {
    reset,
    control,
    setValue,
    getValues,
    formState: { isValid },
  } = useForm({
    resolver: yupResolver(AMCTableValidationSchema),
    mode: 'all',
    defaultValues: {
      sspDetail: '',
      productCode: '',
      workManPowerName: '',
      description: '',
      lineItemName: '',
      unitOfMeasurementId: 0,
      quantity: 0,
      months: 0,
      unitSellingPrice: 0,
      subTotalAmount: 0,
      actualUnitPrice: 0,
      overHeadPercent: 0,
      overHeadUnitPrice: 0,
      contingencyPercent: 0,
      contingencyUnitPrice: 0,
      markUpPercent: 0,
      markupUnitPrice: 0,
      discountPercent: 0,
      discountUnitPrice: 0,
      negotiationPercent: 0,
      negotiationUnitPrice: 0,
      unitPrice: 0,
      totalAmcAmount: 0,
      currencyId: 0,
    },
  });

  useEffect(() => {
    if (amcSheetDetailsCall) {
      fetchData()
    }
  }, [amcSheetDetailsCall]);

  const [amcSheetsPercent, setAmcSheetsPercent] = useState({
    note: '',
    overHeadOverAllPercent: 0,
    overHeadCost: 0,
    contingencyOverAllPercent: 0,
    contingencyCost: 0,
    otherOverAllPercent: 0,
    otherCost: 0,
    markUpOverAllPercent: 0,
    markUpCost: 0,
    discountOverAllPercent: 0,
    discountCost: 0,
    negotiationPercent: 0,
    negotiationCost: 0,
    totalEstimationCost: 0,
  });
  const [fieldEdited, setFieldEdited] = useState(false);
  const [totalBoxLoader, setTotalBoxLoader] = useState(true);
  const [openUpload, setOpenUpload] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadedfileData, setUploadedfileData] = useState(null);
  const { startCreateLoading, stopCreateLoading, createLoading } = useLoader();
  const crud = new Crud_Service();
  const [editingRowData, setEditingRowData] = useState(null);
  const [editingRowId, setEditingRowId] = useState(null);
  const [selectedRate, setSelectedRate] = useState(null);
  const [currentRowIndex, setCurrentRowIndex] = useState(0);
  const [activeRowIndex, setActiveRowIndex] = useState(null);
  const [activeField, setActiveField] = useState(null);
  const [editingRowIndex, setEditingRowIndex] = useState(null);
  const [newRowCreated, setNewRowCreated] = useState(false);
  const [amcSubtotal, setAmcSubtotal] = useState(0);
  const [loader, setLoader] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [rowState, setRowState] = useState({
    newRowId: null,
    isCreatingRow: false,
  });
  const firstColumnInputRef = useRef(null);
  const [stateError, setStateError] = useState(false);
  const [stateErrorMessage, setStateErrorMessage] = useState(
    'This field is required.'
  );
  useEffect(() => {
    setTotalAmount(rows[0]?.totalAmount);
  }, [rows]);



  const { data: estimationTypes } = useCommonFetchApi('estimationTypes') || [];
  const { data: manpowerrates } = useCommonFetchApi('manpowerrates');
  const { data: unitofmeasurements } = useCommonFetchApi('unitofmeasurements');
  const { data: currencies } = useCommonFetchApi('currencies');

  const handleDeleteSheet = async (props) => {
    await crud.remove(
      `estimationamcsheetdetails?estimationAmcSheetDetailIds=${props?.estimationAmcSheetId}`,
      '',
      (err, res) => {
        if (res?.status === 204) {
          toast.success('AMC Sheet deleted Successfully');
          fetchData();
        } else {
          toast.error('Something Went Wrong!');
        }
      }
    );
  };
  const handleExportAMC = async () => {
    if (!TableData || !TableData.estimationAmcSheetId) {
      console.error('TableData or estimationAmcSheetId is undefined');
      return;
    }

    try {
      const downloadUrl = await apiInstance.getFiles(
        `estimationamcsheetdetails/${TableData.estimationAmcSheetId}/exportexcel`
      );
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', 'AMC Details.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error exporting AMC details:', error);
    }
  };

  const handleTotalSubmit = () => {
    const data = {
      ...amcSheetsPercent,
      contingencyCost: Number(amcSheetsPercent.contingencyCost),
      discountCost: Number(amcSheetsPercent.discountCost),
      markUpCost: Number(amcSheetsPercent.markUpCost),
      otherCost: Number(amcSheetsPercent.otherCost),
      overHeadCost: Number(amcSheetsPercent.overHeadCost),
      negotiationCost: Number(amcSheetsPercent.negotiationCost),
      totalEstimationCost: Number(amcSheetsPercent.totalEstimationCost),
    };
    crud.update(
      `estimationamcsheets/${TableData?.estimationAmcSheetId}/update-note`,
      '',
      data,
      (err, res) => {
        if (err) {
          toast.error(err);
          return;
        }
        if (res?.status === 200) {
          toast.success('Estimation AMC Sheet Detail Created Successfully');
          fetchData();
        } else {
          toast.error('Something Went Wrong');
        }
      }
    );
  };

  const handleFileUpload = async (e) => {
    setUploadLoading(true);
    const file = e.target.files[0];
    if (file) {
      const fileFormData = new FormData();
      fileFormData.append('file', file);
      fileFormData.append('fileTypeId', 40);

      await crud.create(`/files/upload`, fileFormData, (err, res) => {
        if (res?.status === 200) {
          setUploadLoading(false);
          setUploadedfileData(res?.data);
        } else {
          setUploadLoading(false);
          toast.error(t('something_Went_Wrong'));
        }
      });
    }
  };

  const handleSubmitImport = async () => {
    startCreateLoading();
    await crud.create(
      `estimationamcsheetdetails/${TableData?.estimationAmcSheetId}/process/${uploadedfileData?.fileId}`,
      '',
      (err, res) => {
        if (res?.status === 200) {
          toast.success('Estimation AMC Sheet Detail Created Successfully');
          stopCreateLoading();
          setOpenUpload(false);
          setUploadedfileData(null);
          fetchData();
        } else {
          stopCreateLoading();
          toast.error('Something Went Wrong');
        }
      }
    );
  };

  const handleAddNewRow = () => {
    const newId = 'new_row_' + Date.now();

    reset();
    setEditingRowData(null);
    setEditingRowId(null);

    setRowState({
      newRowId: newId,
      isCreatingRow: true,
    });

    setEditingRowIndex(
      editingRowIndex === rows[0]?.estimationAmcSheetDetails?.length
        ? editingRowIndex + 1
        : rows[0]?.estimationAmcSheetDetails?.length
    );

    // Add a new row to the rows state
    setRows((prevRows) => {
      if (prevRows.length === 0) {
        return [
          {
            id: newId,
            estimationAmcSheetDetails: [
              {
                estimationAmcSheetDetailId: newId,
                estimationAmcSheetId: newId,
                productCode: '',
                estimationTypeId: '',
                workManPowerName: '',
                unitSellingPrice: 0,
                description: '',
                months: 0,
                quantity: 0,
                unitOfMeasurementId: '',
                currencyId: '',
                isEditable: true,
                isNew: true,
              },
            ],
          },
        ];
      } else {
        // If rows are not empty, modify the first row to add a new entry
        return prevRows.map((row, index) => {
          if (index === 0) {
            return {
              ...row,
              estimationAmcSheetDetails: [
                ...row.estimationAmcSheetDetails,
                {
                  estimationAmcSheetDetailId: newId,
                  estimationAmcSheetId: newId,
                  productCode: '',
                  estimationTypeId: '',
                  workManPowerName: '',
                  unitSellingPrice: 0,
                  description: '',
                  months: 0,
                  quantity: 0,
                  unitOfMeasurementId: '',
                  currencyId: '',
                  isEditable: true,
                  isNew: true,
                },
              ],
            };
          }
          return row; // Return other rows unchanged
        });
      }
    });

    // Reset the form again after row addition
    reset();
  };

  const previousValues = useRef(getValues()); // store previous values to compare with

  const handleBlur = () => {
    const currentValues = getValues();
    const value = getValues('description');
    if (!value.trim()) {
      // Refocus the field if empty
      firstColumnInputRef.current.focus();
      return;
    }


    // if (hasFieldChanges) {
    //   setFieldEdited(true); // Mark field as edited
    // }
    // Compare the previous values with the current values
    const hasChanges = Object.keys(currentValues).some(
      (key) => currentValues[key] !== previousValues.current[key]
    );

    if ((hasChanges && isValid) || (hasChanges && editingRowId)) {
      apiCallAsync(currentValues);
      previousValues.current = currentValues; // Update previous values
    }
  };

  const apiCallAsync = async (values) => {
    setLoader(true);
    const convertedValues = {
      ...values,
      estimationAmcSheetId: TableData?.estimationAmcSheetId,
      sspDetail: 'Some SSP Detail', // Example static field
      description: values?.description, // Ensure the correct field is passed
      lineItemName: values?.lineItemName, // If this exists in your form
      unitSellingPrice:
        values?.unitSellingPrice || editingRowData?.unitSellingPrice, // Get latest or fallback to editingRowData
      discountUnitPrice: editingRowData?.discountUnitPrice,
      markupUnitPrice: editingRowData?.markupUnitPrice,
      negotiationUnitPrice: editingRowData?.negotiationUnitPrice,
      overHeadUnitPrice: editingRowData?.overHeadUnitPrice,
      subTotalAmount: editingRowData?.subTotalAmount,
      totalAmcAmount: editingRowData?.totalAmcAmount,
      actualUnitPrice: editingRowData?.actualUnitPrice,
      contingencyUnitPrice: editingRowData?.contingencyUnitPrice,
      unitOfMeasurementId: values?.unitOfMeasurementId || null,
      months: values?.months || 1,
      quantity: values?.quantity || 1,
      currencyId: values?.currencyId || null,
      createEstimationAmcDetailAttachments: [],
    };

    const combinedData = {
      estimationAmcSheetId: TableData?.estimationAmcSheetId,
      // estimationAmcDetails: [convertedValues], // Combine to match API format
      ...convertedValues,
    };

    const updateCombinedData = {
      ...values, // Same as above, combining updated values
      estimationAmcSheetDetailId: TableData?.estimationAmcSheetDetailId, // Ensure proper ID is passed
      unitSellingPrice:
        values?.unitSellingPrice || editingRowData?.unitSellingPrice,
      discountUnitPrice: editingRowData?.discountUnitPrice,
      markupUnitPrice: editingRowData?.markupUnitPrice,
      negotiationUnitPrice: editingRowData?.negotiationUnitPrice,
      overHeadUnitPrice: editingRowData?.overHeadUnitPrice,
      subTotalAmount: editingRowData?.subTotalAmount,
      totalAmcAmount: editingRowData?.totalAmcAmount,
      actualUnitPrice: editingRowData?.actualUnitPrice,
      contingencyUnitPrice: editingRowData?.contingencyUnitPrice, // Ensure this field is always set
      status: 1,
      unitOfMeasurementId: values?.unitOfMeasurementId || null,
      months: values?.months || 1,
      quantity: values?.quantity || 1,
      currencyId: values?.currencyId || null,
      updateEstimationAmcDetailAttachments: [],
    };

    // Conditional API call based on whether this is a new row or an update
    if (editingRowId === null) {
      await crud.create(
        'estimationamcsheetdetails',
        combinedData,
        (err, res) => {
          if (res?.status === 201) {
            // fetchData()
            setEditingRowId(res?.data?.estimationAmcSheetDetailId);
            setValue(
              'estimationAmcSheetDetailId',
              res?.data?.estimationAmcSheetDetailId
            );
          } else {
          }
        }
      );
    } else {
      await crud.update(
        'estimationamcsheetdetails',
        editingRowId,
        updateCombinedData,
        (err, res) => {
          if (res?.status === 200) {
            if (rows[0]?.estimationAmcSheetDetails?.some((row) => row.isNew)) {
              const updatedRow = res?.data;
              const updatedRows = [...rows[0]?.estimationAmcSheetDetails];
              updatedRows[editingRowIndex] = {
                ...updatedRow,
                isEditable: true,
                isNew: true,
              };
              setRows([{ ...rows[0], estimationAmcSheetDetails: updatedRows }]);
              setStateError(false);
              setLoader(false);
            } else {
              setStateError(false);
              setLoader(false);
              const updatedRow = res?.data;
              const updatedRows = [...rows[0]?.estimationAmcSheetDetails];

              updatedRows[editingRowIndex] = updatedRow;

              setRows([{ ...rows[0], estimationAmcSheetDetails: updatedRows }]);
            }
          } else {
            // Handle error
          }
        }
      );
    }
  };

  const handleCancel = (row) => {
    if (row?.original?.estimationAmcSheetId === rowState?.newRowId) {
      setRows((prevRows) =>
        prevRows.filter((r) => r?.estimationAmcSheetId !== rowState?.newRowId)
      );
      setRowState((prevState) => ({
        ...prevState,
        newRowId: null,
        isCreatingRow: false,
      }));
      setEditingRowIndex(null);
    } else {
      setEditingRowId(null);
      setEditingRowIndex(null);
    }
    reset();
  };

  useEffect(() => {
    if (selectedRate) {
      const selectedManPowerRate = manpowerrates.find(
        (item) => item.manPowerRateId === selectedRate
      );

      if (selectedManPowerRate?.ctcPerMonth) {
        setValue('unitSellingPrice', selectedManPowerRate.ctcPerMonth);
        setEditingRowData((prev) => ({
          ...prev,
          unitSellingPrice: selectedManPowerRate.ctcPerMonth,
        }));
      }
    }

    const updatedRowData = { ...editingRowData };


    // Update actualUnitPrice
    if (editingRowData?.unitSellingPrice) {
      updatedRowData.actualUnitPrice = editingRowData.unitSellingPrice;
    }

    // Update subTotalAmount
    if (
      editingRowData?.quantity &&
      editingRowData?.months &&
      editingRowData?.unitSellingPrice
    ) {
      updatedRowData.subTotalAmount =
        editingRowData.quantity *
        editingRowData.months *
        editingRowData.unitSellingPrice;
    }

    // Update overHeadUnitPrice
    if (editingRowData?.overHeadPercent && editingRowData?.actualUnitPrice) {
      const basePrice = editingRowData.actualUnitPrice;
      updatedRowData.overHeadUnitPrice = (
        basePrice *
        (1 + editingRowData.overHeadPercent / 100)
      ).toFixed(2);
      //setFieldEdited(true);
    }

    // Update contingencyUnitPrice
    if (
      editingRowData?.contingencyPercent &&
      (editingRowData?.overHeadUnitPrice || editingRowData?.actualUnitPrice)
    ) {
      const basePrice =
        editingRowData.overHeadUnitPrice || editingRowData.actualUnitPrice;
      updatedRowData.contingencyUnitPrice = (
        basePrice *
        (1 + editingRowData.contingencyPercent / 100)
      ).toFixed(2);
      //setFieldEdited(true);
    }

    // Update markupUnitPrice
    if (
      editingRowData?.markUpPercent &&
      (editingRowData?.contingencyUnitPrice ||
        editingRowData?.overHeadUnitPrice ||
        editingRowData?.actualUnitPrice)
    ) {
      const basePrice =
        editingRowData.contingencyUnitPrice ||
        editingRowData.overHeadUnitPrice ||
        editingRowData.actualUnitPrice;
      updatedRowData.markupUnitPrice = (
        basePrice *
        (1 + editingRowData.markUpPercent / 100)
      ).toFixed(2);
      //setFieldEdited(true);
    }

    // Update discountUnitPrice
    if (
      editingRowData?.discountPercent &&
      (editingRowData?.markupUnitPrice ||
        editingRowData?.contingencyUnitPrice ||
        editingRowData?.overHeadUnitPrice ||
        editingRowData?.actualUnitPrice)
    ) {
      const basePrice =
        editingRowData.markupUnitPrice ||
        editingRowData.contingencyUnitPrice ||
        editingRowData.overHeadUnitPrice ||
        editingRowData.actualUnitPrice;
      updatedRowData.discountUnitPrice = (
        basePrice *
        (1 - editingRowData.discountPercent / 100)
      ).toFixed(2);
      //setFieldEdited(true);
    }

    // Update negotiationUnitPrice
    if (
      editingRowData?.negotiationPercent &&
      (editingRowData?.discountUnitPrice ||
        editingRowData?.markupUnitPrice ||
        editingRowData?.contingencyUnitPrice ||
        editingRowData?.overHeadUnitPrice ||
        editingRowData?.actualUnitPrice)
    ) {
      const basePrice =
        editingRowData.discountUnitPrice ||
        editingRowData.markupUnitPrice ||
        editingRowData.contingencyUnitPrice ||
        editingRowData.overHeadUnitPrice ||
        editingRowData.actualUnitPrice;
      updatedRowData.negotiationUnitPrice = (
        basePrice *
        (1 - editingRowData.negotiationPercent / 100)
      ).toFixed(2);
      // setFieldEdited(true);
    }

    // Update totalAmcAmount
    if (
      editingRowData?.quantity &&
      editingRowData?.months &&
      editingRowData?.negotiationUnitPrice
    ) {
      updatedRowData.totalAmcAmount = (
        editingRowData.quantity *
        editingRowData.months *
        editingRowData.negotiationUnitPrice
      ).toFixed(2);
    }

    setEditingRowData(updatedRowData);
  }, [selectedRate, manpowerrates, editingRowData]);


  // useEffect(() => {
  //   // Update the editingRowData state with values from amcSheetsPercent
  //   setEditingRowData((prevData) => ({
  //     ...prevData,
  //     overHeadPercent: amcSheetsPercent.overHeadOverAllPercent,
  //     contingencyPercent: amcSheetsPercent.contingencyOverAllPercent,
  //     markUpPercent: amcSheetsPercent.markUpOverAllPercent,
  //     negotiationPercent: amcSheetsPercent.negotiationCost, // Assuming negotiationCost holds the percentage value
  //   }));
  // }, [amcSheetsPercent]);


  /////////


  const handleEdit = (row) => {
    reset();
    if (rowState?.newRowId) {
      setRows((prevRows) =>
        prevRows.filter((r) => r?.estimationAmcSheetId !== rowState?.newRowId)
      );
      setRowState((prevState) => ({
        ...prevState,
        newRowId: null,
        isCreatingRow: false,
      }));
      // setEditingRowIndex(null);
    }
    setEditingRowId(row?.estimationAmcSheetDetailId);
    Object.entries({
      ...row,
    }).forEach(([key, value]) => {
      setValue(key, value);
    });
    setEditingRowData({ ...row });
  };

  useHotkeys(
    'Enter',
    () => handleAddNewRow(),
    {
      enableOnTags: ['INPUT', 'TEXTAREA'], // Optional: Enable hotkey only when focused on specific tags
    },
    [editingRowIndex, rows, stateError, loader]
  );

  useHotkeys(
    'Tab',
    (event) => {
      if (event.key !== 'Tab') return;
      if (stateError) {
        event.preventDefault();
        return;
      }
      const totalRows = rows[0]?.estimationAmcSheetDetails?.length;
      const nextRowIndex = editingRowIndex + 1;

      if (
        nextRowIndex < totalRows &&
        stateError === false &&
        loader === false
      ) {
        setCurrentRowIndex(nextRowIndex);
        handleEdit(rows[0]?.estimationAmcSheetDetails[nextRowIndex]);
        setEditingRowIndex(nextRowIndex);
      }
    },
    [
      editingRowIndex,
      rows[0]?.estimationAmcSheetDetails,
      stateError,
      loader,
      isValid,
    ]
  );

  useEffect(() => {
    if (editingRowData) {
      const amcSubtotal = rows[0]?.estimationAmcSheetDetails?.reduce(
        (total, row, index) => {
          const amountToAdd =
            index === editingRowIndex
              ? Number(editingRowData?.totalAmcAmount) || 0
              : Number(row.totalAmcAmount) || 0;

          return total + amountToAdd;
        },
        0
      );
      setAmcSubtotal(amcSubtotal);
    }
  }, [editingRowData]);

  const handleBlurAndFetch = async () => {
    setLoader(true);
    const updatedRow = getValues(); // Get the latest values from the form
    const updatedRows = [...rows[0]?.estimationAmcSheetDetails]; // Clone the rows[0] to avoid direct mutation

    // Update the specific row in the cloned array
    updatedRows[editingRowIndex] = updatedRow;

    setRows([{ ...rows[0], estimationAmcSheetDetails: updatedRows }]);

    const values = getValues();
    const updateCombinedData = {
      ...values, // Same as above, combining updated values
      estimationAmcSheetDetailId: TableData?.estimationAmcSheetDetailId, // Ensure proper ID is passed
      unitSellingPrice:
        values?.unitSellingPrice || editingRowData?.unitSellingPrice,
      discountUnitPrice: editingRowData?.discountUnitPrice,
      markupUnitPrice: editingRowData?.markupUnitPrice,
      negotiationUnitPrice: editingRowData?.negotiationUnitPrice,
      overHeadUnitPrice: editingRowData?.overHeadUnitPrice,
      subTotalAmount: editingRowData?.subTotalAmount,
      totalAmcAmount: editingRowData?.totalAmcAmount,
      actualUnitPrice: editingRowData?.actualUnitPrice,
      contingencyUnitPrice: editingRowData?.contingencyUnitPrice, // Ensure this field is always set
      unitOfMeasurementId: values?.unitOfMeasurementId || null,
      months: values?.months || 1,
      quantity: values?.quantity || 1,
      currencyId: values?.currencyId || null,
      updateEstimationAmcDetailAttachments: [],
      status: 1, // Static status value
    };
    await crud.update(
      'estimationamcsheetdetails',
      editingRowId,
      updateCombinedData,
      (err, res) => {
        if (res?.status === 200) {
          const totalRows = rows[0]?.estimationAmcSheetDetails?.length;
          if (
            editingRowIndex <= totalRows &&
            rows[0]?.estimationAmcSheetDetails.some((row) => row.isNew)
          ) {
            setNewRowCreated(true);
            const updatedRow = res?.data;
            const updatedRows = [...rows[0]?.estimationAmcSheetDetails];
            updatedRows[editingRowIndex] = {
              ...updatedRow,
              isEditable: true,
              isNew: true,
            };
            setRows([{ ...rows[0], estimationAmcSheetDetails: updatedRows }]);
            handleAddNewRow();
          } else {
            const updatedRow = res?.data;
            const updatedRows = [...rows[0]?.estimationAmcSheetDetails];
            updatedRows[editingRowIndex] = {
              ...updatedRow,
              isEditable: false,
              isNew: false,
            };
            setRows([{ ...rows[0], estimationAmcSheetDetails: updatedRows }]);
            setStateError(false);
            setLoader(false);
          }
        } else {
          setStateError(true);
        }
      }
    );
  };

  const handleCancelAndAddNewRow = () => {
    const newId = 'new_row_' + Date.now();
    setEditingRowData(null);
    setEditingRowId(null);
    setRowState({
      newRowId: newId,
      isCreatingRow: true,
    });

    setEditingRowIndex(
      editingRowIndex === rows[0]?.estimationAmcSheetDetails?.length
        ? editingRowIndex + 1
        : rows[0]?.estimationAmcSheetDetails?.length
    );
    setNewRowCreated(false);
    setRows((prevRows) =>
      prevRows.map((row, index) => {
        if (index === 0) {
          // Add a new row to the estimationAmcSheetDetails of the first row
          return {
            ...row,
            estimationAmcSheetDetails: [
              ...row.estimationAmcSheetDetails,
              {
                estimationAmcSheetDetailId: newId,
                estimationAmcSheetId: newId,
                productCode: '',
                estimationTypeId: '',
                workManPowerName: '',
                unitSellingPrice: 0,
                description: '',
                months: 0,
                quantity: 0,
                unitOfMeasurementId: '',
                currencyId: '',
                isEditable: true,
                isNew: true,
              },
            ],
          };
        }
        return row; // Return other rows unchanged
      })
    );
    reset();
  };

  useEffect(() => {
    if (editingRowIndex !== null && firstColumnInputRef.current && !isLoading) {
      firstColumnInputRef.current.focus();
    }
  }, [editingRowIndex, isLoading]);

  useEffect(() => {
    // Filter rows[0]?.estimationAmcSheetDetails? based on rowSelection keys
    if (rowSelection) {
      const selectedRowIds = rows[0]?.estimationAmcSheetDetails
        ?.filter((_, index) => rowSelection[index]) // Check if row index is selected in rowSelection
        .map((row) => row?.estimationAmcSheetDetailId); // Get the `estimationAmcSheetDetailId` of selected rows[0]?.estimationAmcSheetDetails?

      setSelectedRowIds(selectedRowIds);
    }
  }, [rowSelection]);

  const delectCheckBoxClicked = async () => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BASE}/estimationamcsheetdetails`,
        {
          headers: {
            accept: '*/*',
            'Content-Type': 'application/json-patch+json',
          },
          data: {
            estimationAmcSheetDetailIds: selectedRowIds,
          },
        }
      );
      toast.success('Data Deleted Successfully');
      fetchData();
      setRowSelection({});
      setSelectedRowIds([]);
    } catch (error) {
      console.error('Error deleting data:', error);
    }
  };

  const columns = [
    {
      accessorKey: 'description',
      header: 'Description',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationAmcSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationAmcSheetDetailId ||
          isNewRow;
        return isEditing ? (
          <>
            <FmTextField
              name='description'
              required
              control={control}
              label='Description'
              inputRef={firstColumnInputRef}
              onBlur={handleBlur} // Trigger onBlur
            />
          </>
        ) : (
          row?.original?.description
        );
      },
    },
    {
      accessorKey: 'productCode',
      header: 'Product Code',
      enableEditing: true,
      Cell: ({ cell }) => {
        const isNewRow =
          cell?.row?.original?.estimationAmcSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === cell?.row?.original?.estimationAmcSheetDetailId ||
          isNewRow;

        return isEditing ? (
          <FmAutoComplete
            name='productCode'
            control={control}
            options={estimationTypes}
            label='Product Code'
            displayField='estimationTypeName'
            optionFields={['estimationTypeName']}
            valueKey='estimationTypeId'
            onBlur={handleBlur}
          />
        ) : (
          estimationTypes.find(
            (type) => type.estimationTypeId == cell?.row?.original?.productCode
          )?.estimationTypeName || ''
        );
      },
    },
    {
      accessorKey: 'workManPowerName',
      header: 'Work Man Power Name',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationAmcSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationAmcSheetDetailId ||
          isNewRow;
        return isEditing ? (
          <FmTextField
            name='workManPowerName'
            control={control}
            label='Work Man Power Name'
            onBlur={handleBlur}
          />
        ) : (
          row?.original?.workManPowerName
        );
      },
    },
    {
      accessorKey: 'unitSellingPrice',
      header: 'Unit Selling Price',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationAmcSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationAmcSheetDetailId ||
          isNewRow;

        return isEditing ? (
          <FmTextField
            name='unitSellingPrice'
            control={control}
            label='Unit Selling Price'
            onBlur={handleBlur}
            onChange={(e) => {
              const value = parseFloat(e.target.value) || 0;

              // Update the unitSellingPrice in editingRowData
              setEditingRowData((prev) => ({
                ...prev,
                unitSellingPrice: value,
              }));
            }}
          />
        ) : (
          row?.original?.unitSellingPrice || 0
        );
      },
    },

    {
      accessorKey: 'unitOfMeasurementId',
      header: 'Unit Of Measurement',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationAmcSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationAmcSheetDetailId ||
          isNewRow;
        return isEditing ? (
          <FmAutoComplete
            name='unitOfMeasurementId'
            control={control}
            options={unitofmeasurements}
            label='UOM'
            displayField='unitOfMeasurementName'
            optionFields={['unitOfMeasurementName']}
            valueKey='unitOfMeasurementId'
            onBlur={handleBlur}
          />
        ) : (
          row?.original?.unitOfMeasurementName || ''
        );
      },
    },
    {
      accessorKey: 'months',
      header: 'Months',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationAmcSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationAmcSheetDetailId ||
          isNewRow;
        return isEditing ? (
          <FmTextField
            name='months'
            control={control}
            label='Months'
            pattern='Number'
            defaultValue={editingRowData?.months || ''}
            onChange={(e) => {
              setEditingRowData((prev) => ({
                ...prev,
                months: e.target.value,
              }));
            }}
            onBlur={handleBlur}
          />
        ) : (
          row?.original?.months || 0
        );
      },
    },
    {
      accessorKey: 'quantity',
      header: 'Quantity',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationAmcSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationAmcSheetDetailId ||
          isNewRow;
        return isEditing ? (
          <FmTextField
            name='quantity'
            control={control}
            label='Quantity'
            pattern='Number'
            defaultValue={editingRowData?.quantity || ''}
            onChange={(e) => {
              setEditingRowData((prev) => ({
                ...prev,
                quantity: e.target.value,
              }));
            }}
            onBlur={handleBlur}
          />
        ) : (
          row?.original?.quantity || 0
        );
      },
    },
    {
      accessorKey: 'currencyId',
      header: 'Currency',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationAmcSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationAmcSheetDetailId ||
          isNewRow;
        return isEditing ? (
          <FmAutoComplete
            name='currencyId'
            control={control}
            options={currencies}
            label='currency'
            displayField='currencyName'
            optionFields={['currencyName']}
            valueKey='currencyId'
            onBlur={handleBlur}
          />
        ) : (
          row?.original?.currencyName || ''
        );
      },
    },
    {
      accessorKey: 'subTotalAmount',
      header: 'Sub Total',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationAmcSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationAmcSheetDetailId ||
          isNewRow;
        return isEditing
          ? editingRowData?.subTotalAmount || ''
          : row?.original?.subTotalAmount || 0;
      },
    },
    {
      accessorKey: 'actualUnitPrice',
      header: 'Actual Unit Price',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationAmcSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationAmcSheetDetailId ||
          isNewRow;
        return isEditing
          ? editingRowData?.unitSellingPrice || ''
          : row?.original?.actualUnitPrice || '';
      },
    },
    {
      accessorKey: 'overHeadPercent',
      header: 'Over Head(%)',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationAmcSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationAmcSheetDetailId ||
          isNewRow;
        return isEditing ? (
          <FmTextField
            name='overHeadPercent'
            control={control}
            label='Over Head(%)'
            defaultValue={editingRowData?.overHeadPercent || 0}
            onChange={(e) => {
              setFieldEdited(true);
              setEditingRowData((prev) => ({
                ...prev,
                overHeadPercent: e.target.value,
              }));
            }}
            onBlur={handleBlur}
          />
        ) : (
          row?.original?.overHeadPercent
        );
      },
    },
    {
      accessorKey: 'overHeadUnitPrice',
      header: 'Over Head With Unit Price',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationAmcSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationAmcSheetDetailId ||
          isNewRow;
        return isEditing
          ? editingRowData?.overHeadUnitPrice || ''
          : row?.original?.overHeadUnitPrice || 0;
      },
    },
    {
      accessorKey: 'contingencyPercent',
      header: 'Contingency (%)',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationAmcSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationAmcSheetDetailId ||
          isNewRow;
        return isEditing ? (
          <FmTextField
            name='contingencyPercent'
            control={control}
            label='Contingency (%)'
            defaultValue={editingRowData?.contingencyPercent || 0}
            onChange={(e) => {
              setFieldEdited(true);
              setEditingRowData((prev) => ({
                ...prev,
                contingencyPercent: e.target.value,
              }));
            }}
            onBlur={handleBlur}
          />
        ) : (
          row?.original?.contingencyPercent
        );
      },
    },
    {
      accessorKey: 'contingencyUnitPrice',
      header: 'Contingency With Unit Price',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationAmcSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationAmcSheetDetailId ||
          isNewRow;
        return isEditing
          ? editingRowData?.contingencyUnitPrice || ''
          : row?.original?.contingencyUnitPrice || 0;
      },
    },
    {
      accessorKey: 'markUpPercent',
      header: 'Mark Up (%)',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationAmcSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationAmcSheetDetailId ||
          isNewRow;
        return isEditing ? (
          <FmTextField
            name='markUpPercent'
            control={control}
            label='Mark Up (%)'
            defaultValue={editingRowData?.markUpPercent || 0}
            onChange={(e) => {
              setFieldEdited(true);
              setEditingRowData((prev) => ({
                ...prev,
                markUpPercent: e.target.value,
              }));
            }}
            onBlur={handleBlur}
          />
        ) : (
          row?.original?.markUpPercent
        );
      },
    },
    {
      accessorKey: 'markupUnitPrice',
      header: 'Mark Up With Unit Price',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationAmcSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationAmcSheetDetailId ||
          isNewRow;
        return isEditing
          ? editingRowData?.markupUnitPrice || ''
          : row?.original?.markupUnitPrice || 0;
      },
    },
    {
      accessorKey: 'negotiationPercent',
      header: 'Negotiation (%)',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationAmcSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationAmcSheetDetailId ||
          isNewRow;

        return isEditing ? (
          <FmTextField
            name='negotiationPercent'
            control={control}
            label='Negotiation (%)'
            pattern='Percentage'
            defaultValue={row.original.negotiationPercent || 0}
            onChange={(e) => {
              setFieldEdited(true);
              setEditingRowData((prev) => ({
                ...prev,
                negotiationPercent: e.target.value,
              }))
            }

            }
            onBlur={handleBlur}
          />
        ) : (
          row?.original?.negotiationPercent
        );
      },
    },
    {
      accessorKey: 'negotiationUnitPrice',
      header: 'Negotiation With Unit Price',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationAmcSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationAmcSheetDetailId ||
          isNewRow;
        return isEditing
          ? editingRowData?.negotiationUnitPrice || 0
          : row?.original?.negotiationUnitPrice;
      },
    },
    {
      accessorKey: 'discountPercent',
      header: 'Discount (%)',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationAmcSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationAmcSheetDetailId ||
          isNewRow;
        return isEditing ? (
          <FmTextField
            name='discountPercent'
            control={control}
            label='Discount (%)'
            pattern='Percentage'
            defaultValue={editingRowData?.discountPercent || 0}
            onChange={(e) => {
              setFieldEdited(true);
              setEditingRowData((prev) => ({
                ...prev,
                discountPercent: e.target.value,
              }));
            }}
            onBlur={handleBlurAndFetch}
          />
        ) : (
          row?.original?.discountPercent
        );
      },
    },
    {
      accessorKey: 'discountUnitPrice',
      header: 'Discount With Unit Price',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationAmcSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationAmcSheetDetailId ||
          isNewRow;

        return isEditing
          ? editingRowData?.discountUnitPrice || 0
          : row?.original?.discountUnitPrice || 0;
      },
    },
    {
      accessorKey: 'unitPrice',
      header: 'Unit Price',
      enableEditing: true,
      Cell: ({ row, cell }) => {
        const isNewRow =
          row?.original?.estimationAmcSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationAmcSheetDetailId ||
          isNewRow;
        return isEditing
          ? editingRowData?.unitSellingPrice
          : row?.original?.unitSellingPrice;
      },
    },
    {
      accessorKey: 'totalAmcAmount',
      header: 'Total Price',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationAmcSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationAmcSheetDetailId ||
          isNewRow;
        return isEditing
          ? editingRowData?.totalAmcAmount
          : row?.original?.totalAmcAmount;
      },
    },

    {
      header: 'Action',
      enableColumnPinning: false,
      // enableEditing: false,
      // enableSorting: false,
      Cell: ({ row, table }) => {
        const isNewRow =
          row?.original?.estimationAmcSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationAmcSheetDetailId ||
          isNewRow;

        // Determine if the current row is the last row in the table
        const isLastRow =
          row.index === rows[0]?.estimationAmcSheetDetails?.length - 1;
        return (
          <Box className='flex gap-2'>
            {isEditing ? (
              <>
                {/* <Button onClick={handleSubmit(handleUpdateAMCDetails)}>
                  Save
                </Button> */}
                <Button onClick={() => handleCancel(row)}>Close</Button>
                {isLastRow && rowState?.isCreatingRow === false && (
                  <Button
                    onFocus={() => {
                      handleCancelAndAddNewRow(row);
                    }}
                  ></Button>
                )}
              </>
            ) : (
              <Box className='flex gap-2'>
                {isLastRow && (
                  <Button
                    variant='contained'
                    color='primary'
                    className='px-3 py-1'
                    onClick={() => {
                      setEditingRowIndex(row?.index + 1);
                      handleAddNewRow(row);
                    }}
                  >
                    Add
                  </Button>
                )}
              </Box>
            )}
          </Box>
        );
      },
    },
  ];
  useEffect(() => {
    rows[0]?.estimationAmcSheetDetails?.map((item) => {

      if (amcSheetsPercent.overHeadOverAllPercent) {
        item.overHeadPercent = amcSheetsPercent.overHeadOverAllPercent;
        item.overHeadUnitPrice =
          (item.actualUnitPrice * item.overHeadPercent) / 100;
      }
      if (amcSheetsPercent.contingencyOverAllPercent) {
        item.contingencyPercent = amcSheetsPercent.contingencyOverAllPercent;
        item.contingencyUnitPrice =
          (item.actualUnitPrice * item.contingencyPercent) / 100;
      }
      if (amcSheetsPercent.markUpOverAllPercent) {
        item.markUpPercent = amcSheetsPercent.markUpOverAllPercent;
        item.markupUnitPrice =
          (item.actualUnitPrice * item.markUpPercent) / 100;
      }
      if (amcSheetsPercent.discountOverAllPercent) {
        item.discountPercent = amcSheetsPercent.discountOverAllPercent;
        item.discountUnitPrice =
          (item.actualUnitPrice * item.discountPercent) / 100;
      }
      if (amcSheetsPercent.negotiationPercent) {
        item.negotiationPercent = amcSheetsPercent.negotiationPercent;
        item.negotiationUnitPrice =
          (item.actualUnitPrice * item.negotiationPercent) / 100;
      }
    });
    setRows([...rows]);
  }, [amcSheetsPercent]);






  return (
    <>
      <BackToButton
        title='Back to List'
        onClick={handleBackButton}
        className='px-2 mb-1'
      />
      {/* <TenderCard estimationAMC={estimationAMC}/> */}
      <GlassCard>
        <FmMRTDataTable
          muiTableContainerProps={{
            sx: {
              maxHeight: '350px',
              position: 'relative', // Set relative positioning for the table container
            },
          }}
          columns={columns}
          enableRowSelection={true}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          delectCheckBoxClicked={delectCheckBoxClicked}
          rows={rows[0]?.estimationAmcSheetDetails || []}
          handleRowDoubleClick={(row) => {
            handleEdit(row?.original);
            setEditingRowIndex(row?.index);
          }}
          showPagination={false}
          onCreateRow={handleAddNewRow}
          isCreatingRow={rowState?.isCreatingRow}
          rowCount={rows[0]?.estimationAmcSheetDetails?.length}
          editingRow={editingRowId}
          pagination={pagination}
          setSearchKeyword={setSearchKeyword}
          sorting={sorting}
          columnFilters={columnFilters}
          globalFilter={globalFilter}
          setPagination={setPagination}
          setSorting={setSorting}
          setColumnFilters={setColumnFilters}
          setGlobalFilter={setGlobalFilter}
          isLoading={isLoading}
          disableNewButton={rows[0]?.estimationAmcSheetDetails?.length === 0}
          setSearchKeyword={setSearchKeyword}
          searchKeyword={searchKeyword}
          columnPining={false}
          fetchData={fetchData}
          handleExport={handleExportAMC}
          handleImport={() => {
            setOpenUpload(true);
            setUploadedfileData(null);
          }}
        />
      </GlassCard>

      {!totalBoxLoader && (
        <TotalBox
          rows={rows[0]?.estimationAmcSheetDetails || []}
          editedingRowData={editingRowData}
          amcSheetsPercent={amcSheetsPercent}
          total={amcSubtotal != 0 ? amcSubtotal : total}
          setAmcSheetsPercent={setAmcSheetsPercent}
          handleTotalSubmit={handleTotalSubmit}
          fieldEdited={fieldEdited}
        />
      )}
      <MuiDialogOne
        open={openUpload}
        onClose={() => setOpenUpload(false)}
        title='Import AMC Details'
        description='Upload your amc details Xlsx file'
      >
        <FmFileInput
          name='file'
          documentName={uploadedfileData?.originalFileName || ''}
          onChange={handleFileUpload}
          loading={uploadLoading}
        />
        <ActionButtons
          onSubmit={handleSubmitImport}
          onCancel={() => setOpenUpload(false)}
          submitLoading={createLoading}
          onReset={reset}
          cancelLoading={false}
        />
      </MuiDialogOne>
    </>
  );
};

export default ViewDetails;
