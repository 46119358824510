import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Crud_Service from '../../../apis/CrudService';
import { ActionButtons } from '../../../components/_form';
import FmAutoComplete from '../../../components/_mui/FmAutoComplete.tsx';
import FmDatePicker from '../../../components/_mui/FmDatePicker.tsx';
import FmSearchableSelect from '../../../components/_mui/FmSearchableSelect.tsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import useCommonFetchApi from '../../../components/helpers/useCommonFetchApi.tsx';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { enquiryValidationSchema } from '../../../utils/CommonvalidationSchemas.tsx';
import { BooleanOptions } from '../../../utils/CommonVariables.tsx';

const CreateEnquiry = () => {
  const navigate = useNavigate();
  const crud = new Crud_Service();

  const { handleSubmit, control, setValue, reset, watch } = useForm({
    resolver: yupResolver(enquiryValidationSchema),
    mode: 'onChange',
    defaultValues: {
      startDate: dayjs(),
      lastDate: dayjs(),
    },
  });
  const [bidBond, setBidbond] = useState(false);
  const [bidBondValue, setBidbondValue] = useState(null);
  const [buyer, setBuyer] = useState(null);
  const [expressOfInterest, setExpressOfInterest] = useState(null);
  const { createLoading, startCreateLoading, stopCreateLoading } = useLoader();
  useCommonFetchApi('clients');
  const { data: tenderEnquiryTypes } = useCommonFetchApi('tenderenquirytypes', {
    sortBy: 'tenderEnquiryTypeName',
    sortDirection: 'asc',
  });

  const handleSubmitTendors = async (values) => {
    startCreateLoading();
    const submissionRevisedDate = dayjs().format('YYYY-MM-DD');
    const CombinedData = {
      ...values,
      bidBondValue: Number(values?.bidBondValue) || null,
      receiveDate: dayjs(values?.receiveDate).format('YYYY-MM-DD'),
      subMissionDate: dayjs(values?.subMissionDate).format('YYYY-MM-DD'),
      submissionRevisedDate: submissionRevisedDate,
      lastDate: dayjs(values?.lastDate).format('YYYY-MM-DD'),
      startDate: dayjs(values?.startDate).format('YYYY-MM-DD'),
      tenderValue: Number(values?.tenderValue),
      bidBondTypeValue: values?.bidBondTypeValue || null,
      remarks: values?.remarks || null,
      autoRenewal: values?.autoRenewal || false,
      cost: values?.cost || null,
      tenderStatusId: 1,
      expressOfInterestDate: values?.expressOfInterest
        ? dayjs(values?.expressOfInterestDate).format('YYYY-MM-DD')
        : null,
      companyId: values?.companyId || null,
      tenderProjectTypeId: values?.tenderProjectTypeId,
      tenderFormatId: 3,
    };
    await crud.create('tenders', CombinedData, (_err, res) => {
      if (res?.status === 201) {
        navigate(-1);
        toast.success('Enquiry Created Successfully');
        stopCreateLoading();
      } else {
        stopCreateLoading();
      }
    });
  };
  useEffect(() => {
    watch('startDate') && setValue('lastDate', watch('startDate'));
  }, [watch('startDate')]);
  //handle Bidbond
  const handleBidBondChange = (e) => {
    setBidbond(e);
    !e && setValue('bidBondFormatId', null);
    !e && setValue('bidBondValue', null);
    !e && setValue('bidBondTypeId', null);
    !e && setValue('bidBondTypeValue', null);
  };
  useEffect(() => {
    setValue('tenderStatusId', 1);
  }, []);
  const handleOnChangeBuyer = (e) => {
    setBuyer(e?.value);
    setValue('cost', '');
  };
  const handleExpressOfInterest = (e) => {
    setExpressOfInterest(e?.value);
  };
  const { data: emirates } = useCommonFetchApi('emirates');

  return (
    <DashboardLayout title='Create New Enquiry'>
      <GlassCard className='p-4'>
        <form onSubmit={handleSubmit(handleSubmitTendors)}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid container spacing={2}>
              <Grid item md={3}>
                <FmTextField
                  name='tenderName'
                  control={control}
                  label='Tender Name'
                  required
                  minLength={2}
                />
              </Grid>

              <Grid item md={3}>
                <FmTextField
                  name='tenderRefNo'
                  control={control}
                  label='Tender Reference Number'
                />
              </Grid>
              {/* <Grid item md={3}>
                <FmTextField
                  name='tenderNumber'
                  control={control}
                  label='Enquiry Number'

                />
              </Grid> */}
              <Grid item md={3}>
                <FmSearchableSelect
                  name='companyId'
                  control={control}
                  apiUrl='companies'
                  label='Quote By Company'
                  showField={['companyCode', 'companyName']}
                  headerField={['Code', 'Name']}
                  labelField={['companyCode', 'companyName']}
                  valueField='companyId'
                  required
                />
              </Grid>
              {/* TODO */}
              {/* <Grid item md={3}>
                <FmTextField
                  name='requestForEstimate'
                  control={control}
                  label='Request For Estimate'
                  required
                />
              </Grid> */}
              <Grid item md={3}>
                <FmSearchableSelect
                  name='clientId'
                  control={control}
                  apiUrl='clients'
                  valueField='clientId'
                  headerField={['Code', 'Name']}
                  labelField={['clientCode', 'clientName']}
                  showField={['clientCode', 'clientName']}
                  pageSize={20}
                  label='Client'
                  required
                />
              </Grid>
              <Grid item md={3}>
                <FmSearchableSelect
                  name='tenderProjectTypeId'
                  control={control}
                  apiUrl='tenderProjectTypes'
                  valueField='tenderProjectTypeId'
                  headerField={['Code', 'Name']}
                  labelField={[
                    'tenderProjectTypeCode',
                    'tenderProjectTypeName',
                  ]}
                  showField={['tenderProjectTypeName']}
                  pageSize={20}
                  label='Tender Project Type'
                  required
                />
              </Grid>
              <Grid item md={3}>
                <FmAutoComplete
                  name='tenderEnquiryTypeId'
                  control={control}
                  options={tenderEnquiryTypes}
                  label='Mode of Submission'
                  displayField='tenderEnquiryTypeName'
                  optionFields={['tenderEnquiryTypeName']}
                  valueKey='tenderEnquiryTypeId'
                  required
                />
              </Grid>
              <Grid item md={3}>
                <FmSearchableSelect
                  name='divisionId'
                  control={control}
                  apiUrl='divisions'
                  headerField={['Code', 'Name']}
                  valueField='divisionId'
                  labelField={['divisionCode', 'divisionName']}
                  showField={['divisionCode', 'divisionName']}
                  label={'Division'}
                />
              </Grid>
              <Grid item md={3}>
                <FmAutoComplete
                  name='emirateIds'
                  control={control}
                  options={emirates}
                  displayField='emirateName'
                  optionFields={['emirateName']}
                  valueKey='emirateId'
                  label='Emirate'
                  isMultiSelect
                  required
                />
              </Grid>
              {/* TODO */}
              {/* <Grid item md={3}>
                <FmAutoComplete
                  name='tenderProjectTypeId'
                  control={control}
                  options={tenderProjectTypes}
                  label='Mode of Submission'
                  displayField='tenderProjectTypeName'
                  optionFields={['tenderProjectTypeName']}
                  valueKey='tenderProjectTypeId'
                  required
                />
              </Grid>*/}

              <Grid item md={3}>
                <FmAutoComplete
                  name='bidBond'
                  control={control}
                  options={[
                    { value: true, bidBondName: 'Yes' },
                    { value: false, bidBondName: 'No' },
                  ]}
                  label='Bid Bond'
                  displayField='bidBondName'
                  optionFields={['bidBondName']}
                  valueKey='value'
                  onChange={handleBidBondChange}
                />
              </Grid>
              <Grid item md={3}>
                <FmDatePicker
                  control={control}
                  name='receiveDate'
                  label='Received Date'
                  required
                />
              </Grid>
              <Grid item md={3}>
                <FmDatePicker
                  control={control}
                  name='subMissionDate'
                  label='Submission Date'
                  required
                />
              </Grid>
              <Grid item md={3}>
                <FmAutoComplete
                  name='buyTenderDocument'
                  control={control}
                  options={BooleanOptions}
                  label='Buy Enquiry Document'
                  displayField='label'
                  optionFields={['label']}
                  valueKey='value'
                  onChange={handleOnChangeBuyer}
                  onChangeValue={true}
                />
              </Grid>
              {buyer === true && (
                <Grid item md={3}>
                  <FmTextField
                    name='cost'
                    control={control}
                    label='Costs'
                    pattern='Decimal'
                  />
                </Grid>
              )}
              <Grid item md={3}>
                <FmAutoComplete
                  name='expressOfInterest'
                  control={control}
                  options={[
                    {
                      name: 'Yes',
                      value: true,
                    },
                    {
                      name: 'No',
                      value: false,
                    },
                  ]}
                  label='Express Of Interest'
                  displayField='name'
                  optionFields={['name']}
                  valueKey='value'
                  onChange={handleExpressOfInterest}
                  onChangeValue={true}
                  required
                />
              </Grid>
              {expressOfInterest === true && (
                <Grid item md={3}>
                  <FmDatePicker
                    control={control}
                    name='expressOfInterestDate'
                    label='EOI Date'
                  />
                </Grid>
              )}
              <Grid item md={3}>
                <FmTextField
                  name='remarks'
                  control={control}
                  label='Enquiry Remarks'
                  multiline
                  rows={3}
                  maxRows={3}
                />
              </Grid>
            </Grid>
          </LocalizationProvider>
          <ActionButtons
            onSubmit={handleSubmit(handleSubmitTendors)}
            onCancel={() => navigate(-1)}
            onReset={reset}
            submitLoading={createLoading}
            cancelLoading={false}
            submitText='Create'
          />
        </form>
      </GlassCard>
    </DashboardLayout>
  );
};
export default CreateEnquiry;
