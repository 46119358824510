// import { Icon } from '@iconify/react';
// import React from 'react';
// import {setMenu} from '../redux/menuSlice.ts'
// export interface NavItem {
//   id?: number;
//   name: string;
//   icon: React.ReactNode;
//   color?: string;
//   path?: string;
//   child?: NavItem[];
//   count?: string;
//   children?: any;
// }

// // export const Nav: NavItem[] = [
// //   {
// //     id: 1,
// //     name: 'HRMS',
// //     icon: <Icon icon='fluent:people-audience-24-filled' />,
// //     color: '#067e89',
// //     child: [
// //       {
// //         name: 'Dashboard',
// //         icon: <Icon icon='ic:round-dashboard' />,
// //         path: '/hrms/dashboard',
// //         children: null,
// //       },
// //       {
// //         name: 'Employee',
// //         icon: <Icon icon='ic:round-groups' />,
// //         count: '3',
// //         path: '/hrms/employee',
// //         children: [
// //           {
// //             name: 'Employee Dashboard',
// //             path: '/hrms/employee/dashboard',
// //           },
// //           {
// //             name: 'Employees',
// //             path: '/hrms/employee/master-list',
// //           },

// //           {
// //             name: 'Attendance',
// //             path: '/hrms/employee/attendance-summary',
// //           },
// //           {
// //             name: 'Employee Attendance Calculations',
// //             path: '/hrms/employee/attendance',
// //           },
// //           {
// //             name: 'Leave Request',
// //             path: '/hrms/employee/leave-request',
// //           },
// //           // {
// //           //   name: "Leave Transaction",
// //           //   path: "/hrms/employee-master/leave-transaction",
// //           // },
// //           {
// //             name: 'Leave Join',
// //             path: '/hrms/employee/leave-join',
// //           },
// //           {
// //             name: 'Passport Request',
// //             path: '/hrms/employee/passport-request',
// //           },
// //           {
// //             name: 'Employee Exit / Cancellation',
// //             path: '/hrms/employee/cancellation',
// //           },
// //           {
// //             name: 'Final Settlements',
// //             path: '/hrms/employee/final-settlement',
// //           },

// //           // {
// //           //   name: 'Training Transaction',
// //           //   path: '/hrms/employee-master/training-transaction',
// //           // },
// //           {
// //             name: 'Certificates',
// //             path: '/hrms/employee/certificates',
// //           },
// //           {
// //             name: 'Employee Salary Calculations',
// //             path: '/hrms/employee/employee-salary-calculations',
// //           },
// //         ],
// //       },

// //       // {
// //       //   name: "Document Management",
// //       //   icon: <Icon icon='streamline:definition-search-book' />,
// //       //   path: "/hrms/document-management",
// //       //   children: null,
// //       // },

// //       {
// //         name: 'Job Requisition',
// //         path: '/hrms/job-requisition',
// //         icon: <Icon icon='ic:round-work' />,
// //         children: [
// //           {
// //             name: 'Job Requisition',
// //             path: '/hrms/job-requisition/list',
// //           },
// //           {
// //             name: 'Job Recruitments',
// //             path: '/hrms/job-requisition/jobrecruitments',
// //           },
// //           {
// //             name: 'Candidates',
// //             path: '/hrms/job-requisition/candidates',
// //           },
// //         ],
// //       },
// //       // {
// //       //   name: 'Final Settlement',
// //       //   path: '/hrms/final-settlement',
// //       //   icon: <Icon icon='streamline:receipt-check' />,
// //       // },
// //       {
// //         name: 'Company Certificate',
// //         path: '/hrms/company-certificate',
// //         icon: <Icon icon='ic:round-workspace-premium' />,
// //       },
// //       {
// //         name: 'Training and Event',
// //         path: '/hrms/training-event',
// //         icon: <Icon icon='ic:round-event-note' />,
// //         children: [
// //           {
// //             name: 'Training and Event',
// //             path: '/hrms/training-event/list',
// //           },
// //         ],
// //       },
// //       // {
// //       //    name: "Approval Workflow",
// //       //    path: "/hrms/approval-workflow",
// //       //    icon: <Icon icon="mdi:workflow-outline" />,
// //       // },
// //       // {
// //       //    name: "Letter Management",
// //       //    path: "/hrms/letter-management",
// //       //    icon: <Icon icon="pepicons-pencil:letter-open" />,
// //       // },
// //       {
// //         name: 'All Masters',
// //         path: '/hrms/masters',
// //         icon: <Icon icon='ic:round-settings' />,
// //         children: [
// //           {
// //             name: 'Department',
// //             path: '/hrms/masters/department',
// //           },
// //           {
// //             name: 'Designation',
// //             path: '/hrms/masters/designation',
// //           },
// //           {
// //             name: 'Location',
// //             path: '/hrms/masters/location',
// //           },
// //           {
// //             name: 'Nationality',
// //             path: '/hrms/masters/nationality',
// //           },
// //           {
// //             name: 'Region',
// //             path: '/hrms/masters/region',
// //           },
// //           {
// //             name: 'Experience Type',
// //             path: '/hrms/masters/experience-type',
// //           },
// //           {
// //             name: 'Contract Type',
// //             path: '/hrms/masters/contract-type',
// //           },
// //           {
// //             name: 'Blood Group',
// //             path: '/hrms/masters/blood-group',
// //           },
// //           // {
// //           //   name: "Certificate Type",
// //           //   path: "/hrms/all-masters/certificate-type",
// //           // },
// //           {
// //             name: 'Document Type',
// //             path: '/hrms/masters/document-type',
// //           },
// //           {
// //             name: 'Document Type Category',
// //             path: '/hrms/masters/document-type-category',
// //           },
// //           {
// //             name: 'WPS',
// //             path: '/hrms/masters/wps',
// //           },
// //           {
// //             name: 'Type of Cancellation',
// //             path: '/hrms/masters/cancellation-type',
// //           },
// //           {
// //             name: 'Positions',
// //             path: '/hrms/masters/positions',
// //           },
// //           {
// //             name: 'Companies',
// //             path: '/hrms/masters/companies',
// //           },
// //           {
// //             name: 'Pay Groups',
// //             path: '/hrms/masters/pay-groups',
// //           },
// //           {
// //             name: 'Cost Centers',
// //             path: '/hrms/masters/cost-centers',
// //           },
// //           {
// //             name: 'Emirates',
// //             path: '/hrms/masters/emirates',
// //           },
// //           {
// //             name: 'Division',
// //             path: '/hrms/masters/division',
// //           },
// //         ],
// //       },
// //       {
// //         name: 'Shift Time',
// //         path: '/hrms/shift-time-master',
// //         icon: <Icon icon='ic:round-timer' />,
// //         children: null,
// //       },
// //       //TODO
// //       // {
// //       //   name: 'Department Shifts',
// //       //   path: '/hrms/department-shifts',
// //       //   icon: <Icon icon='ic:baseline-auto-awesome-motion' />,
// //       //   children: null,
// //       // },
// //       {
// //         name: 'Leave Configuration',
// //         path: '/hrms/leave-configuration',
// //         icon: <Icon icon='ic:baseline-article' />,
// //         children: null,
// //       },
// //       {
// //         name: 'Manpower RateCard',
// //         icon: <Icon icon='ic:round-accessibility-new' />,
// //         path: '/hrms/manpower-ratecard',
// //         children: null,
// //       },
// //       {
// //         name: 'Document Management',
// //         icon: <Icon icon='ic:round-folder-shared' />,
// //         path: '/hrms/document-management',
// //         children: null,
// //       },
// //     ],
// //   },

// //   {
// //     //   id: 2,
// //     //   name: "CRM",
// //     //   color: "#3F51B5",
// //     //   icon: <Icon icon="solar:archive-down-minimlistic-linear" />,
// //     //   child: [
// //     //     {
// //     //       name: "HRMS Contact",
// //     //       icon: <Icon icon="mdi:contact" />,
// //     //     },
// //     //     {
// //     //       name: "HRMS Appoinments",
// //     //       icon: <Icon icon="uis:calender" />,
// //     //     },
// //     //   ],
// //   },

// //   {
// //     //   id: 3,
// //     //   name: "ERP",
// //     //   icon: <Icon icon="solar:armchair-broken" />,
// //     //   color: "#FF9800",
// //     //   child: null,
// //   },

// //   {
// //     id: 4, //3
// //     name: 'PR',
// //     icon: <Icon icon='fluent:layer-diagonal-person-16-filled' />,
// //     color: '#FF9800',
// //     child: [
// //       {
// //         name: 'Dashboard',
// //         icon: <Icon icon='ic:round-dashboard' />,
// //         path: '/pr/dashboard',
// //         children: null,
// //       },
// //       {
// //         name: 'Employee',
// //         icon: <Icon icon='ic:round-groups' />,
// //         count: '3',
// //         path: '/pr/employee',
// //         children: [
// //           {
// //             name: 'Employee',
// //             path: '/pr/employee/list',
// //           },
// //           {
// //             name: 'Passport Request',
// //             path: '/pr/employee/passport-request',
// //           },
// //           {
// //             name: 'Employee Certificates',
// //             path: '/pr/employee/certificates',
// //           },
// //           // {
// //           //   name: 'Training transaction',
// //           //   path: '/pr/employee-master/training-transaction',
// //           // },
// //           {
// //             name: 'Candidates',
// //             path: '/pr/employee/candidates',
// //           },
// //           {
// //             name: 'Passport / Visa',
// //             path: '/pr/employee/passport-visa',
// //           },
// //           {
// //             name: 'EID Tracker',
// //             path: '/pr/employee/eid',
// //           },
// //         ],
// //       },

// //       {
// //         name: 'All master',
// //         icon: <Icon icon='ic:round-settings' />,
// //         path: '/pr/masters',
// //         children: [
// //           {
// //             name: 'Card Types',
// //             path: '/pr/masters/card-types',
// //           },
// //           {
// //             name: 'Expense type',
// //             path: '/pr/masters/expense-type',
// //           },
// //           {
// //             name: 'Company Insurance Policy',
// //             path: '/pr/masters/policies',
// //           },
// //           {
// //             name: 'Company Insurance Policy Types',
// //             path: '/pr/masters/policy-type',
// //           },
// //           {
// //             name: 'End Users',
// //             path: '/pr/masters/end-user',
// //           },
// //           {
// //             name: 'Type of Products',
// //             path: '/pr/masters/type-of-product',
// //           },
// //           {
// //             name: 'Premise Locations',
// //             path: '/pr/masters/premise-location',
// //           },
// //           {
// //             name: 'Medical Insurance Status',
// //             path: '/pr/masters/medical-insurance-status',
// //           },
// //           {
// //             name: 'Medical Insurance Age Bands',
// //             path: '/pr/masters/medical-insurance-age-band',
// //           },
// //           {
// //             name: 'Medical Insurance Policy',
// //             path: '/pr/masters/medical-insurance-policy',
// //           },
// //           {
// //             name: 'Medical Insurance Salary Band',
// //             path: '/pr/masters/medical-insurance-salary-band',
// //           },
// //           // {
// //           //   name: 'Premise Location Name',
// //           //   path: '/pr/masters/premise-location-name',
// //           // },
// //           {
// //             name: 'Policy Premiums',
// //             path: '/pr/masters/policy-premiums',
// //           },
// //           {
// //             name: 'Medical Insurance Dependencies',
// //             path: '/pr/masters/medical-insurance-dependencies',
// //           },
// //           {
// //             name: 'Premise Purpose',
// //             path: '/pr/masters/medical-insurance-premise-purpose',
// //           },
// //           {
// //             name: 'Premise Types',
// //             path: '/pr/masters/medical-insurance-premise-types',
// //           },
// //           {
// //             name: 'Uniform Sizes',
// //             path: '/pr/masters/uniform-sizes',
// //           },
// //           {
// //             name: 'Uniform Types',
// //             path: '/pr/masters/uniform-types',
// //           },
// //           {
// //             name: 'Insurer',
// //             path: '/pr/masters/insurer',
// //           },
// //           {
// //             name: 'Division',
// //             path: '/pr/masters/division',
// //           },
// //           {
// //             name: 'Emirates',
// //             path: '/pr/masters/emirates',
// //           },
// //           {
// //             name: 'Cost Center',
// //             path: '/pr/masters/cost-center',
// //           },
// //         ],
// //       },
// //       {
// //         name: 'Quota Employees Summary',
// //         path: '/pr/quota-employees-summary',
// //         icon: <Icon icon='ic:round-person-pin' />,
// //         children: [],
// //       },

// //       // {
// //       //   name: "Stock Reports",
// //       //   path: "/pr/stock-reports",
// //       //   icon: <Icon icon="pepicons-print:leave-off" />,
// //       //   children: [
// //       //     {
// //       //       name: "Uniform stocks",
// //       //       path: "/pr/stock-reports/uniform-stocks",
// //       //     },
// //       //     {
// //       //       name: "Shoe stocks",
// //       //       path: "/pr/stock-reports/shoe-stocks",
// //       //     },
// //       //   ],
// //       // },
// //       // {
// //       //   name: "Tenancy Tracker",
// //       //   path: "/pr/tenancy-tracker",
// //       //   icon: <Icon icon="pepicons-print:leave-off" />,
// //       //   children: null,
// //       // },
// //       // {
// //       //   name: "Vechicle",
// //       //   icon: <Icon icon='streamline:car-taxi-1' />,
// //       //   path: "/pr/vechicle-master",
// //       //   children: [
// //       //     {
// //       //       name: "Vechicle Maintenance",
// //       //       path: "/pr/vechicle-master/vechicle-maintenance",
// //       //     },
// //       //     {
// //       //       name: "Petrol expense",
// //       //       path: "/pr/vechicle-master/petrol-expense",
// //       //     },
// //       //     {
// //       //       name: "Toll Recharge",
// //       //       path: "/pr/vechicle_maste/toll-recharger",
// //       //     },
// //       //   ],
// //       // },
// //       // // {
// //       //   name: "Document Management",
// //       //   icon: <Icon icon='streamline:definition-search-book' />,
// //       //   path: "/pr/document-management",
// //       //   children: null,
// //       // },
// //       {
// //         name: 'Cash Account management',
// //         icon: <Icon icon='ic:baseline-account-balance' />,
// //         path: '/pr/cash-account-management',
// //         children: [
// //           {
// //             name: 'Cash Account management',
// //             path: '/pr/cash-account-management/list',
// //           },
// //           {
// //             name: 'Invoice/Transaction Management',
// //             path: '/pr/cash-account-management/transaction',
// //           },
// //         ],
// //       },
// //       {
// //         name: 'Transaction Management',
// //         icon: <Icon icon='ic:round-fact-check' />,
// //         path: '/pr/transaction',
// //         children: [
// //           {
// //             name: 'Invoice/Transaction Management',
// //             path: '/pr/transaction',
// //           },
// //         ],
// //       },
// //       {
// //         name: 'Document Management',
// //         icon: <Icon icon='ic:round-folder-shared' />,
// //         path: '/pr/document-management',
// //         children: null,
// //       },
// //     ],
// //   },
// //   {
// //     id: 5, //4
// //     name: 'PreSales',
// //     icon: <Icon icon='fluent:data-bar-vertical-star-16-filled' />,
// //     color: '#FF9800',
// //     path: '/PreSales/',
// //     child: [
// //       {
// //         name: 'Dashboard',
// //         icon: <Icon icon='ic:sharp-dashboard' />,
// //         path: '/presales/dashboard',
// //         children: null,
// //       },
// //       {
// //         name: 'All master',
// //         icon: <Icon icon='ic:baseline-settings' />,
// //         path: '/presales/masters',
// //         children: [
// //           {
// //             name: 'Tender Enquiry Types',
// //             path: '/presales/masters/tender-enquiry-types',
// //           },
// //           {
// //             name: 'Tender Project Types',
// //             path: '/presales/masters/tender-project-types',
// //           },
// //           {
// //             name: 'Tender Formats',
// //             path: '/presales/masters/tender-formats',
// //           },
// //           {
// //             name: 'Countries',
// //             path: '/presales/masters/countries',
// //           },
// //           {
// //             name: 'Supplier Types',
// //             path: '/presales/masters/supplier-types',
// //           },
// //           {
// //             name: 'Tender Followup Types',
// //             path: '/presales/masters/tender-followup-types',
// //           },
// //           {
// //             name: 'Tender Status',
// //             path: '/presales/masters/tender-status',
// //           },
// //           {
// //             name: 'Contact Modes',
// //             path: '/presales/masters/contact-modes',
// //           },
// //           {
// //             name: 'Regions',
// //             path: '/presales/masters/regions',
// //           },
// //           {
// //             name: 'Buildings',
// //             path: '/presales/masters/buildings',
// //           },
// //           {
// //             name: 'Locations',
// //             path: '/presales/masters/locations',
// //           },
// //           {
// //             name: 'Departments',
// //             path: '/presales/masters/departments',
// //           },
// //           {
// //             name: 'Emirates',
// //             path: '/presales/masters/emirates',
// //           },
// //           {
// //             name: 'Division',
// //             path: '/presales/masters/division',
// //           },
// //           {
// //             name: 'Contract Scope',
// //             path: '/presales/masters/contract-scope',
// //           },
// //           {
// //             name: 'Billing Frequency',
// //             path: '/presales/masters/billing-frequency',
// //           },
// //           {
// //             name: 'Contract Types',
// //             path: '/presales/masters/contract-type',
// //           },
// //         ],
// //       },

// //       {
// //         name: 'Clients',
// //         icon: <Icon icon='ic:baseline-people-alt' />,
// //         path: '/presales/clients',
// //         children: null,
// //       },
// //       {
// //         name: 'Enquiry',
// //         icon: <Icon icon='ic:baseline-email' />,
// //         count: '3',
// //         path: '/presales/enquiry',
// //         children: null,
// //       },

// //       {
// //         name: 'Tender Inspection Checklist',
// //         icon: <Icon icon='ic:baseline-check-circle' />,
// //         path: '/presales/tender-inspection-checklist',
// //         children: null,
// //       },
// //       {
// //         name: 'Estimation Sheet for AMC',
// //         icon: <Icon icon='ic:baseline-calculate' />,
// //         path: '/presales/estimation-sheet-amc',
// //         children: null,
// //       },
// //       {
// //         name: 'Estimation Sheet for MEP',
// //         icon: <Icon icon='ic:baseline-calculate' />,
// //         path: '/presales/estimation-sheet-mep',
// //         children: null,
// //       },

// //       // {
// //       //   name: 'Bidbond /Sitevisit Notification & Collection',
// //       //   path: '/presales/bidbond',
// //       //   icon: <Icon icon='streamline:color-palette' />,
// //       //   children: null,
// //       // },

// //       {
// //         name: 'Suppliers',
// //         icon: <Icon icon='ic:round-local-shipping' />,
// //         path: '/presales/suppliers',
// //         children: null,
// //       },
// //       {
// //         name: 'Proposal',
// //         path: '/presales/proposal',
// //         icon: <Icon icon='ic:baseline-auto-awesome-motion' />,
// //         children: null,
// //       },

// //       {
// //         name: 'Projects',
// //         icon: <Icon icon='ic:baseline-folder-special' />,
// //         path: '/presales/projects',
// //         children: null,
// //       },
// //       {
// //         name: 'Contracts',
// //         icon: <Icon icon='ic:baseline-bookmarks' />,
// //         path: '/presales/manage-contracts',
// //         children: [
// //           { name: 'CRUD Contract', path: '/presales/manage-contracts/crud' },
// //         ],
// //       },
// //       {
// //         name: 'Sales Order Distribution',
// //         icon: <Icon icon='ic:round-account-balance-wallet' />,
// //         path: '/presales/sales-order-distribution',
// //         children: null,
// //       },

// //       {
// //         name: 'Document Management',
// //         icon: <Icon icon='ic:round-library-books' />,
// //         path: '/presales/document-management',
// //         children: null,
// //       },
// //     ],
// //   },

// //   {
// //     id: 6, //5
// //     name: 'CAFM',
// //     icon: <Icon icon='fluent:building-people-24-filled' />,
// //     color: '#FF9800',
// //     path: '/cafm/',
// //     child: [
// //       {
// //         name: 'Dashboard',
// //         icon: <Icon icon='ic:round-dashboard' />,
// //         path: '/cafm/dashboard',
// //         children: null,
// //       },
// //       //TODO
// //       // {
// //       //   name: 'Tickets',
// //       //   icon: <Icon icon='ic:baseline-local-activity' />,
// //       //   path: '/cafm/tickets',
// //       //   children: null,
// //       // },

// //       {
// //         name: 'Helpdesk Ticket',
// //         icon: <Icon icon='ic:baseline-local-activity' />,
// //         path: '/cafm/helpdesk-ticket',
// //         children: null,
// //       },

// //       {
// //         name: 'Incident Management',
// //         icon: <Icon icon='ic:baseline-inventory-2' />,
// //         path: '/cafm/incident-management',
// //         children: null,
// //       },
// //       {
// //         name: 'Service Request Management',
// //         icon: <Icon icon='ic:baseline-insert-comment' />,
// //         path: '/cafm/service-request-management',
// //         children: null,
// //       },
// //       {
// //         name: 'Escalation management',
// //         icon: <Icon icon='ic:round-assessment' />,
// //         path: '/cafm/escalation-management',
// //         children: null,
// //       },
// //       {
// //         name: 'Knowledge Management',
// //         icon: <Icon icon='ic:round-lightbulb' />,
// //         path: '/cafm/knowledge-management',
// //         children: null,
// //       },
// //       {
// //         name: 'Reporting & Analytics',
// //         icon: <Icon icon='ic:round-bug-report' />,
// //         path: '/cafm/reporting-analytics',
// //         children: null,
// //       },

// //       {
// //         name: 'Asset History',
// //         icon: <Icon icon='ic:round-view-carousel' />,
// //         path: '/cafm/asset-history',
// //         children: null,
// //       },
// //     ],
// //   },

// //   {
// //     id: 7, //6
// //     name: 'Procurement',
// //     icon: <Icon icon='fluent:database-multiple-20-filled' />,
// //     color: '#FF9800',
// //     path: '/procurement/',
// //     child: [
// //       {
// //         name: 'Dashboard',
// //         icon: <Icon icon='ic:round-dashboard' />,
// //         path: '/procurement/dashboard',
// //         children: null,
// //       },
// //       {
// //         name: 'Material Request',
// //         icon: <Icon icon='ic:baseline-library-add-check' />,
// //         path: '/procurement/material-request',
// //         children: null,
// //       },
// //       {
// //         name: 'Request for Quote Management',
// //         icon: <Icon icon='ic:baseline-token' />,
// //         path: '/procurement/request-for-quote-management',
// //         children: null,
// //       },
// //       {
// //         name: 'Purchase Order',
// //         icon: <Icon icon='ic:baseline-preview' />,
// //         path: '/procurement/purchase-order-view',
// //         children: null,
// //       },
// //       {
// //         name: 'Inventory',
// //         icon: <Icon icon='ic:baseline-inventory-2' />,
// //         path: '/procurement/inventory-management',
// //         children: [
// //           {
// //             name: 'Store Locations',
// //             path: '/procurement/inventory-management/store-locations',
// //           },
// //           {
// //             name: 'Inventory Items',
// //             path: '/procurement/inventory-management/inventory-items',
// //           },
// //         ],
// //       },

// //       {
// //         name: 'All Masters',
// //         path: '/procurement/masters',
// //         icon: <Icon icon='ic:round-settings' />,
// //         children: [
// //           {
// //             name: 'Buildings',
// //             path: '/procurement/masters/building',
// //           },
// //           {
// //             name: 'Levels',
// //             path: '/procurement/masters/levels',
// //           },
// //           {
// //             name: 'Level Units',
// //             path: '/procurement/masters/levelUnits',
// //           },
// //           {
// //             name: 'Cost Centers',
// //             path: '/procurement/masters/costCenters',
// //           },
// //           {
// //             name: 'Supplier Items',
// //             path: '/procurement/masters/supplier-items',
// //           },
// //           {
// //             name: 'Brand',
// //             path: '/procurement/masters/brand',
// //           },
// //           {
// //             name: 'Tax Code',
// //             path: '/procurement/masters/tax-code',
// //           },
// //           {
// //             name: 'Unit Of Measurement',
// //             path: '/procurement/masters/unit-of-measurement',
// //           },
// //           {
// //             name: 'Item Types',
// //             path: '/procurement/masters/item-types',
// //           },
// //           {
// //             name: 'Valuation Method',
// //             path: '/procurement/masters/valuation-method',
// //           },
// //           {
// //             name: 'Item Group',
// //             path: '/procurement/masters/item-group',
// //           },
// //           {
// //             name: 'Group Types',
// //             path: '/procurement/masters/group-types',
// //           },
// //           {
// //             name: 'Companies',
// //             path: '/procurement/masters/companies',
// //           },
// //           {
// //             name: 'Regions',
// //             path: '/procurement/masters/regions',
// //           },
// //           {
// //             name: 'Department',
// //             path: '/procurement/masters/department',
// //           },
// //           {
// //             name: 'Location',
// //             path: '/procurement/masters/location',
// //           },
// //           {
// //             name: 'Material Requisition Types',
// //             path: '/procurement/masters/material-requisition-types',
// //           },
// //           {
// //             name: 'Material Requisition Status',
// //             path: '/procurement/masters/material-requisition-status',
// //           },
// //           {
// //             name: 'Payment Terms',
// //             path: '/procurement/masters/payment-terms',
// //           },
// //           {
// //             name: 'Currencies',
// //             path: '/procurement/masters/currencies',
// //           },
// //           {
// //             name: 'Local Purchase Order Status',
// //             path: '/procurement/masters/local-purchase-order-status',
// //           },
// //           {
// //             name: 'Category',
// //             path: '/procurement/masters/category',
// //           },
// //         ],
// //       },
// //       {
// //         name: 'Suppliers',
// //         icon: <Icon icon='ic:round-people' />,
// //         path: '/procurement/suppliers',
// //         children: null,
// //       },

// //       {
// //         name: 'Item Management',
// //         icon: <Icon icon='ic:round-local-mall' />,
// //         path: '/procurement/item-management',
// //         children: null,
// //       },

// //       {
// //         name: 'Goods Received Note',
// //         icon: <Icon icon='ic:round-local-shipping' />,
// //         path: '/procurement/goods-received-note',
// //         children: null,
// //       },
// //       {
// //         name: 'Document Management',
// //         icon: <Icon icon='ic:round-folder-shared' />,
// //         path: '/procurement/document-management',
// //         children: null,
// //       },
// //       // {
// //       //   name: "Vendor Management",
// //       //   icon: <Icon icon="icon-park-outline:every-user" />,
// //       //   path: "/procurement/vendor-management",
// //       //   children: null,
// //       // },
// //       // {
// //       //   name: "Transaction Approval Management",
// //       //   icon: <Icon icon="grommet-icons:transaction" />,
// //       //   path: "/procurement/transaction-approval-management",
// //       //   children: null,
// //       // },
// //     ],
// //   },
// //   {
// //     id: 8, //7
// //     name: 'Financial',
// //     icon: <Icon icon='fluent:building-bank-28-filled' />,
// //     color: '#FF9800',
// //     path: '/financial/',
// //     child: [
// //       {
// //         name: 'Dashboard',
// //         icon: <Icon icon='ic:round-dashboard' />,
// //         path: '/financial/dashboard',
// //         children: null,
// //       },
// //       {
// //         name: 'General Ledger Accounting',
// //         icon: <Icon icon='ic:baseline-account-tree' />,
// //         path: '/financial/general-ledger-accounting',
// //         children: null,
// //       },
// //       {
// //         name: 'Accounts Payable',
// //         icon: <Icon icon='game-icons:pay-money' />,
// //         path: '/financial/accounts-payable',
// //         children: [
// //           {
// //             name: 'Purchase Voucher',
// //             path: '/financial/accounts-payable/purchase-voucher',
// //           },
// //           {
// //             name: 'Purchase Return Voucher',
// //             path: '/financial/accounts-payable/purchase-return-voucher',
// //           },
// //           {
// //             name: 'Direct Purchase Order',
// //             path: '/financial/accounts-payable/direct-purchase-order',
// //           },
// //         ],
// //       },
// //       {
// //         name: 'Accounts Receivable',
// //         icon: <Icon icon='game-icons:receive-money' />,
// //         path: '/financial/accounts-receivable',
// //         children: [
// //           {
// //             name: 'Sales order',
// //             path: '/financial/accounts-receivable/sales-order',
// //           },
// //           {
// //             name: 'Sales Return',
// //             path: '/financial/accounts-receivable/sales-return',
// //           },
// //           {
// //             name: 'Sales Retention',
// //             path: '/financial/accounts-receivable/sales-retention',
// //           },
// //           {
// //             name: 'Sales Invoices',
// //             path: '/financial/accounts-receivable/sales-invoices',
// //           },
// //         ],
// //       },
// //       {
// //         name: 'Asset Accounting',
// //         icon: <Icon icon='ic:baseline-style' />,
// //         path: '/financial/asset-accounting',
// //         children: null,
// //       },
// //       {
// //         name: 'Bank Accounting',
// //         icon: <Icon icon='ic:baseline-account-balance' />,
// //         path: '/financial/bank-accounting',
// //         children: null,
// //       },
// //       {
// //         name: 'Travel Management',
// //         icon: <Icon icon='ic:outline-flight' />,
// //         path: '/financial/travel-management',
// //         children: null,
// //       },
// //       {
// //         name: 'Legal Consolidation',
// //         icon: <Icon icon='ic:baseline-how-to-vote' />,
// //         path: '/financial/legal-consolidation',
// //         children: null,
// //       },
// //       {
// //         name: 'General Ledger Closing Operations',
// //         icon: <Icon icon='ic:baseline-announcement' />,
// //         path: '/financial/general-ledger-closing-operations',
// //         children: null,
// //       },
// //       {
// //         name: 'Vendor Invoice Receivable',
// //         icon: <Icon icon='ic:sharp-receipt' />,
// //         path: '/financial/vendor-invoice-receivable',
// //         children: null,
// //       },
// //       {
// //         name: 'Cash and Bank',
// //         icon: <Icon icon='ic:baseline-account-balance' />,
// //         path: '/financial/cash-and-bank',
// //         children: [
// //           {
// //             name: 'Receipts',
// //             path: '/financial/cash-and-bank/receipts',
// //           },
// //           {
// //             name: 'Payments',
// //             path: '/financial/cash-and-bank/payments',
// //           },
// //           {
// //             name: 'Pending Bills - Payments',
// //             path: '/financial/cash-and-bank/pending-bills-payments',
// //           },
// //           {
// //             name: 'Petty Cash',
// //             path: '/financial/cash-and-bank/petty-cash',
// //           },
// //           {
// //             name: 'Post Dated - Receipts',
// //             path: '/financial/cash-and-bank/post-dated-receipts',
// //           },
// //           {
// //             name: 'Pending Bills - Post Dated Receipts',
// //             path: '/financial/cash-and-bank/pending-bills-post-dated-receipts',
// //           },
// //           {
// //             name: 'Post Dated - Payments',
// //             path: '/financial/cash-and-bank/post-dated-payments',
// //           },
// //           {
// //             name: 'Pending Bills - PDC Payments',
// //             path: '/financial/cash-and-bank/pending-bills-pdc-payments',
// //           },
// //           {
// //             name: 'Petty Cash - Projects',
// //             path: '/financial/cash-and-bank/petty-cash-projects',
// //           },
// //           {
// //             name: 'Matured PDC PV',
// //             path: '/financial/cash-and-bank/matured-pdc-pv',
// //           },
// //           {
// //             name: 'Pending Bills - Matured PDC PV',
// //             path: '/financial/cash-and-bank/pending-bills-matured-pdc-pv',
// //           },
// //           {
// //             name: 'Petty Cash Projects - View',
// //             path: '/financial/cash-and-bank/petty-cash-projects-view',
// //           },
// //         ],
// //       },
// //       {
// //         name: 'Final Accounts',
// //         icon: <Icon icon='ic:sharp-payments' />,
// //         path: '/financial/final-accounts',
// //         children: [
// //           {
// //             name: 'Trial Balance',
// //             path: '/financial/final-accounts/trial-balance',
// //           },
// //           {
// //             name: 'Profit and Loss',
// //             path: '/financial/final-accounts/profit-and-loss',
// //           },
// //           {
// //             name: 'Trading Account',
// //             path: '/financial/final-accounts/trading-account',
// //           },
// //           {
// //             name: 'Trading and Profit and Loss',
// //             path: '/financial/final-accounts/trading-and-profit-and-loss',
// //           },
// //           {
// //             name: 'Balance Sheet',
// //             path: '/financial/final-accounts/balance-sheet',
// //           },
// //           {
// //             name: 'Final Account Schedules',
// //             path: '/financial/final-accounts/final-account-schedules',
// //           },
// //           {
// //             name: 'Funds Flow',
// //             path: '/financial/final-accounts/funds-flow',
// //           },
// //           {
// //             name: 'Cash Flow',
// //             path: '/financial/final-accounts/cash-flow',
// //           },
// //           {
// //             name: 'Cash Flow Analysis',
// //             path: '/financial/final-accounts/cash-flow-analysis',
// //           },
// //           {
// //             name: 'Advance Cash Flow',
// //             path: '/financial/final-accounts/advance-cash-flow',
// //           },
// //           {
// //             name: 'Income Expense Trends',
// //             path: '/financial/final-accounts/income-expense-trends',
// //           },
// //           {
// //             name: 'Receivable Payable Report',
// //             path: '/financial/final-accounts/receivable-payable-report',
// //           },
// //           {
// //             name: 'Cash Flow Calender',
// //             path: '/financial/final-accounts/cash-flow-calender',
// //           },
// //         ],
// //       },
// //       {
// //         name: 'Journal Entries',
// //         path: '/financial/journal-entries/non-journal',
// //         icon: <Icon icon="ic:round-menu-book" />,
// //         children: [
// //           {
// //             name: 'Non Journal',
// //             path: '/financial/journal-entries/non-journal',
// //           },
// //           {
// //             name: 'Credit Notes',
// //             path: '/financial/journal-entries/credit-notes',
// //           },
// //           {
// //             name: 'Debit Notes',
// //             path: '/financial/journal-entries/debit-notes',
// //           },
// //         ],
// //       },
// //       {
// //         name: 'Reports',
// //         path: '/financial/reports',
// //         icon: <Icon icon='ic:round-receipt' />,
// //       },
// //       {
// //         name: 'Client Reports',
// //         path: '/financial/client-reports',
// //         icon: <Icon icon='mdi:user-card-details' />,
// //       },
// //       {
// //         name: 'Chart of Account',
// //         path: '/financial/chart-account',
// //         icon: <Icon icon='mdi:user-card-details' />,
// //       },
// //       {
// //         name: 'Master Account',
// //         path: '/financial/master-account',
// //         icon: <Icon icon='ic:baseline-grade' />,
// //       },
// //     ],
// //   },
// //   {
// //     id: 9, //8
// //     name: 'Budgeting',
// //     icon: <Icon icon='fluent:wallet-credit-card-24-filled' />,
// //     color: '#FF9800',
// //     path: '/budgeting/',
// //     child: [
// //       {
// //         name: 'Dashboard',
// //         icon: <Icon icon='ic:round-dashboard' />,
// //         path: '/budgeting/dashboard',
// //         children: null,
// //       },
// //       {
// //         name: 'Cost Element Accounting',
// //         icon: <Icon icon='ic:sharp-monetization-on' />,
// //         path: '/budgeting/cost-element-accounting',
// //         children: null,
// //       },
// //       {
// //         name: 'Cost Center Accounting',
// //         icon: <Icon icon='ic:baseline-account-balance' />,
// //         path: '/budgeting/cost-center-accounting',
// //         children: null,
// //       },
// //       {
// //         name: 'Internal Orders',
// //         icon: <Icon icon='ic:sharp-move-to-inbox' />,
// //         path: '/budgeting/internal-orders',
// //         children: null,
// //       },
// //       {
// //         name: 'Profit Center Accounting',
// //         icon: <Icon icon='ic:baseline-insert-chart' />,
// //         path: '/budgeting/profit-center-accounting',
// //         children: null,
// //       },
// //       {
// //         name: 'Profitability Analysis',
// //         icon: <Icon icon='ic:outline-auto-graph' />,
// //         path: '/budgeting/profitability-analysis',
// //         children: null,
// //       },
// //     ],
// //   },
// //   {
// //     id: 10, //9
// //     name: 'HSE',
// //     icon: <Icon icon='fluent:cube-multiple-20-filled' />,
// //     color: '#FF9800',
// //     path: '/hse/',
// //     child: [
// //       {
// //         name: 'Dashboard',
// //         icon: <Icon icon='ic:round-dashboard' />,
// //         path: '/hse/dashboard',
// //         children: null,
// //       },
// //       {
// //         name: 'Occupational Health',
// //         icon: <Icon icon='ic:sharp-record-voice-over' />,
// //         path: '/hse/occupational-health',
// //         children: null,
// //       },
// //       {
// //         name: 'Occupational Safety',
// //         icon: <Icon icon='ic:round-assistant' />,
// //         path: '/hse/occupational-safety',
// //         children: null,
// //       },
// //       {
// //         name: 'Environmental Management',
// //         icon: <Icon icon='ic:sharp-public' />,
// //         path: '/hse/environmental-management',
// //         children: null,
// //       },
// //       {
// //         name: 'Dangerous Goods Management',
// //         icon: <Icon icon='ic:round-dangerous' />,
// //         path: '/hse/dangerous-goods-management',
// //         children: null,
// //       },
// //       {
// //         name: 'Industrial Hygiene',
// //         icon: <Icon icon='ic:round-real-estate-agent' />,
// //         path: '/hse/industrial-hygiene',
// //         children: null,
// //       },
// //       {
// //         name: 'Incident Management',
// //         icon: <Icon icon='ic:sharp-question-answer' />,
// //         path: '/hse/incident-management',
// //         children: null,
// //       },
// //       {
// //         name: 'Audit Management',
// //         icon: <Icon icon='ic:round-monetization-on' />,
// //         path: '/hse/audit-management',
// //         children: null,
// //       },
// //       {
// //         name: 'Waste Management',
// //         icon: <Icon icon='ic:sharp-public-off' />,
// //         path: '/financial/waste-management',
// //         children: null,
// //       },
// //       {
// //         name: 'Chemical Management',
// //         icon: <Icon icon='ic:sharp-sports-volleyball' />,
// //         path: '/financial/chemical-management',
// //         children: null,
// //       },
// //       {
// //         name: 'Sustainability Performance Management',
// //         icon: <Icon icon='ic:sharp-psychology' />,
// //         path: '/financial/sustainability-performance-management',
// //         children: null,
// //       },
// //       {
// //         name: 'Risk & Regulatory Compliance',
// //         icon: <Icon icon='ic:sharp-smart-toy' />,
// //         path: '/financial/risk-regulatory-compliance',
// //         children: null,
// //       },

// //       {
// //         name: 'Hse Committee',
// //         icon: <Icon icon='ic:round-assignment-ind' />,
// //         path: '/hse/hse-committee',
// //         children: null,
// //       },

// //       {
// //         name: 'PPE issued Report',
// //         icon: <Icon icon='ic:sharp-speaker-notes' />,
// //         path: '/hse/ppe-issued-report',
// //         children: null,
// //       },

// //       {
// //         name: 'Safety Induction',
// //         icon: <Icon icon='ic:round-health-and-safety' />,
// //         path: '/hse/safty-induction',
// //         children: null,
// //       },

// //       {
// //         name: 'Safety Violation Slip',
// //         icon: <Icon icon='ic:sharp-sticky-note-2' />,
// //         path: '/hse/safty-violation-slip',
// //         children: null,
// //       },

// //       {
// //         name: 'Hot Work Permit',
// //         icon: <Icon icon='ic:sharp-local-fire-department' />,
// //         path: '/hse/hot-work-permit',
// //         children: null,
// //       },
// //     ],
// //   },
// //   {
// //     id: 11, //10
// //     name: 'Admin',
// //     icon: <Icon icon='fluent:person-star-32-filled' />,
// //     path: '/admin/',
// //     child: [
// //       {
// //         name: 'Dashboard',
// //         icon: <Icon icon='ic:round-dashboard' />,
// //         path: '/admin/dashboard',
// //         children: null,
// //       },
// //       {
// //         name: 'Company Insurance Policy',
// //         icon: <Icon icon='ic:baseline-medical-services' />,
// //         path: '/admin/company-insurance-policy',
// //         children: null,
// //       },
// //       {
// //         name: 'Company Medical Insurance Policy',
// //         icon: <Icon icon='ic:baseline-medication' />,
// //         path: '/admin/company-medical-insurance-policy',
// //         children: null,
// //       },
// //       {
// //         name: 'Accommodation',
// //         icon: <Icon icon='ic:baseline-door-sliding' />,
// //         path: '/admin/accommodation',
// //         children: [
// //           // {
// //           //   name: 'Summary',
// //           //   path: '/admin/accommodation/summary',
// //           // },
// //           {
// //             name: 'Accomodation',
// //             path: '/admin/accommodation/accomodations',
// //           },
// //           {
// //             name: 'Block',
// //             path: '/admin/accommodation/block',
// //           },
// //           {
// //             name: 'Room',
// //             path: '/admin/accommodation/room',
// //           },
// //           // TODO
// //           // {
// //           //   name: 'Room Capacity',
// //           //   path: '/admin/accommodation/room-capacity',
// //           // },
// //           {
// //             name: 'Camp',
// //             path: '/admin/accommodation/camp',
// //           },
// //         ],
// //       },
// //       {
// //         name: 'Uniform & Accessories - Requisitions',
// //         icon: <Icon icon='ic:baseline-co-present' />,
// //         path: '/admin/uniform-accessories-requistions',
// //         children: [
// //           {
// //             name: 'Uniform Requisitions',
// //             path: '/admin/uniform-accessories-requistions/list',
// //           },
// //           {
// //             name: 'Uniform Requisitions Return',
// //             path: '/admin/uniform-accessories-requistions/return',
// //           },
// //           {
// //             name: 'Uniforms',
// //             path: '/admin/uniform-accessories-requistions/uniforms',
// //           },
// //         ],
// //       },
// //       {
// //         name: 'Events Calendar',
// //         icon: <Icon icon='ic:baseline-calendar-month' />,
// //         path: '/admin/events-calendar',
// //         children: null,
// //       },
// //       {
// //         name: 'All Masters',
// //         icon: <Icon icon='ic:round-settings' />,
// //         path: '/admin/masters',
// //         children: [
// //           {
// //             name: 'Vehicles',
// //             path: '/admin/masters/vehicles',
// //           },
// //           {
// //             name: 'Vehicles Types',
// //             path: '/admin/masters/admin-vehicle-master',
// //           },
// //           {
// //             name: 'Vehicles Category',
// //             path: '/admin/masters/vehicle-category',
// //           },
// //           {
// //             name: 'Vehicles Brand',
// //             path: '/admin/masters/vehicle-brand',
// //           },
// //           {
// //             name: 'Vehicles Statuses',
// //             path: '/admin/masters/vehicle-statuses',
// //           },
// //           {
// //             name: 'Drivers',
// //             path: '/admin/masters/drivers',
// //           },
// //           {
// //             name: 'Insurance',
// //             path: '/admin/masters/insurance',
// //           },
// //           {
// //             name: 'Vehicle Advertisements',
// //             path: '/admin/masters/vehicle-advertisement',
// //           },
// //           {
// //             name: 'Traffic Sources',
// //             path: '/admin/masters/traffic-sources',
// //           },
// //           {
// //             name: 'Vehicle Suppliers',
// //             path: '/admin/masters/vehicle-suppliers',
// //           },
// //           {
// //             name: 'Floor',
// //             path: '/admin/masters/floor',
// //           },
// //           {
// //             name: 'Level',
// //             path: '/admin/masters/level',
// //           },
// //         ],
// //       },
// //       {
// //         name: 'Vehicles',
// //         icon: <Icon icon='ic:baseline-airport-shuttle' />,
// //         path: '/admin/vehicles',
// //         children: [
// //           {
// //             name: 'Own Vehicles',
// //             path: '/admin/vehicles/own-vehicles',
// //           },
// //           {
// //             name: 'Hired Vehicles',
// //             path: '/admin/vehicles/hired-vehicles',
// //           },
// //           {
// //             name: 'Month Updates',
// //             path: '/admin/vehicles/month-updates',
// //           },
// //           {
// //             name: 'Asateel',
// //             path: '/admin/vehicles/asateel',
// //           },
// //           {
// //             name: 'Traffic Fines',
// //             path: '/admin/vehicles/traffic-fines',
// //           },
// //           {
// //             name: 'Invoice Submission',
// //             path: '/admin/vehicles/invoice-submission',
// //           },
// //           {
// //             name: 'Advertisement',
// //             path: '/admin/vehicles/advertisement',
// //           },
// //         ],
// //       },
// //     ],
// //   },
// //   {
// //     id: 12, //11
// //     name: 'Project Division',
// //     icon: <Icon icon='fluent:target-arrow-24-filled' />,
// //     path: '/project-division/',
// //     child: [
// //       {
// //         name: 'Dashboard',
// //         icon: <Icon icon='ic:round-dashboard' />,
// //         path: '/project-division/dashboard',
// //         children: null,
// //       },
// //       {
// //         name: 'Project Division',
// //         icon: <Icon icon='ic:baseline-assignment' />,
// //         path: '/project-division/project-division',
// //         children: null,
// //       },

// //       {
// //         name: 'All Masters',
// //         icon: <Icon icon='ic:round-settings' />,
// //         path: '/project-division/masters',
// //         children: [
// //           {
// //             name: 'Project Bill',
// //             path: '/project-division/masters/project-boq-head-master',
// //           },
// //           {
// //             name: 'Project Head',
// //             path: '/project-division/masters/project-boq-package-master',
// //           },
// //           {
// //             name: 'Project BOQ Sub Head',
// //             path: '/project-division/masters/project-boq-sub-head-master',
// //           },
// //           {
// //             name: 'BOQ Line Item',
// //             path: '/project-division/masters/boq-line-item-master',
// //           },
// //         ],
// //       },
// //     ],
// //   },
// //   {
// //     id: 13, //12
// //     name: 'FM Division',
// //     icon: <Icon icon='fluent:channel-16-filled' />,
// //     path: '/fm-division/',
// //     child: [
// //       {
// //         name: 'Dashboard',
// //         icon: <Icon icon='ic:round-dashboard' />,
// //         path: '/fm-division/dashboard',
// //         children: null,
// //       },
// //       {
// //         name: 'Work Order',
// //         icon: <Icon icon='ic:sharp-engineering' />,
// //         path: '/fm-division/work-order',
// //         children: [
// //           {
// //             name: 'Work Order',
// //             path: '/fm-division/work-order/work-order-list',
// //           },

// //           {
// //             name: 'Check In form',
// //             path: '/fm-division/work-order/create-check-in-form',
// //           },
// //           {
// //             name: 'Check Out form',
// //             path: '/fm-division/work-order/create-check-out-form',
// //           },
// //         ],
// //       },
// //       {
// //         name: 'Enquiry',
// //         icon: <Icon icon='ic:outline-chat-bubble' />,
// //         count: '3',
// //         path: '/fm-division/enquiry',
// //         children: null,
// //       },
// //       {
// //         name: 'Estimation Sheet for AMC',
// //         icon: <Icon icon='ic:outline-menu-book' />,
// //         path: '/fm-division/estimation-sheet-amc',
// //         children: null,
// //       },
// //       {
// //         name: 'Estimation Installation',
// //         icon: <Icon icon='ic:outline-double-arrow' />,
// //         path: '/fm-division/estimation-installation',
// //         children: null,
// //       },
// //       {
// //         name: 'Site Visit & Follow Up',
// //         icon: <Icon icon='ic:round-description' />,
// //         path: '/fm-division/site-visit',
// //         children: null,
// //       },
// //       {
// //         name: 'Site Visit & CheckList',
// //         icon: <Icon icon='ic:round-chrome-reader-mode' />,
// //         path: '/fm-division/site-visit-checklist',
// //         children: null,
// //       },
// //       {
// //         name: 'Proposal',
// //         icon: <Icon icon='ic:outline-streetview' />,
// //         path: '/fm-division/proposal',
// //         children: null,
// //       },
// //       {
// //         name: 'Budget',
// //         icon: <Icon icon='ic:baseline-payments' />,
// //         path: '/fm-division/budget',
// //         children: null,
// //       },
// //       {
// //         name: 'Sub Contractors',
// //         icon: <Icon icon='ic:round-supervisor-account' />,
// //         path: '/fm-division/subcontractor',
// //         children: null,
// //       },
// //       {
// //         name: 'Sales Quotation',
// //         icon: <Icon icon='ic:round-file-open' />,
// //         path: '/fm-division/create-sales-quotation',
// //         children: null,
// //       },
// //       {
// //         name: 'Sales Orders',
// //         icon: <Icon icon='ic:round-table-chart' />,
// //         path: '/fm-division/create-sales-order',
// //         children: [
// //           {
// //             name: 'Sales order',
// //             path: '/financial/accounts-receivable/sales-order',
// //           },
// //           {
// //             name: 'Sales Return',
// //             path: '/financial/accounts-receivable/sales-return',
// //           },
// //           {
// //             name: 'Sales Retention',
// //             path: '/financial/accounts-receivable/sales-retention',
// //           },
// //         ],
// //       },
// //       {
// //         name: 'MR Request',
// //         icon: <Icon icon='ic:round-add-to-photos' />,
// //         path: '/fm-division/material-request',
// //         children: null,
// //       },
// //       {
// //         name: 'Company Legal certificate',
// //         icon: <Icon icon='ic:round-workspace-premium' />,
// //         path: '/fm-division/company-certificate',
// //         children: null,
// //       },
// //       {
// //         name: 'Employee Certificate',
// //         path: '/fm-division/certificates',
// //         icon: <Icon icon='ic:round-sticky-note-2' />,
// //       },
// //       {
// //         name: 'Job Requisition',
// //         path: '/fm-division/job-requisition',
// //         icon: <Icon icon='ic:round-cases' />,
// //       },
// //       {
// //         name: 'Inspection Report',
// //         icon: <Icon icon='ic:baseline-text-snippet' />,
// //         path: '/fm-division/inspection-report',
// //         children: null,
// //       },
// //       {
// //         name: 'Incident Report',
// //         icon: <Icon icon='ic:baseline-wysiwyg' />,
// //         path: '/fm-division/incident-report',
// //         children: null,
// //       },
// //       {
// //         name: 'Snag Report',
// //         icon: <Icon icon='ic:baseline-insert-chart' />,
// //         path: '/fm-division/snag-report',
// //         children: [
// //           {
// //             name: 'Snag Report',
// //             path: '/fm-division/snag-report',
// //           },
// //         ],
// //       },
// //       {
// //         name: 'View Service Request',
// //         path: '/fm-division/view-service-request',
// //         icon: <Icon icon='ic:round-adb' />,
// //       },
// //       {
// //         name: 'PPM planner',
// //         path: '/fm-division/ppm-planner',
// //         icon: <Icon icon='ic:sharp-app-registration' />,
// //       },
// //       {
// //         name: 'PPM workorders',
// //         path: '/fm-division/ppm-workorders',
// //         icon: <Icon icon='ic:round-fact-check' />,
// //       },
// //       {
// //         name: 'Asset Details',
// //         path: '/fm-division/asset-details',
// //         icon: <Icon icon='ic:round-room-preferences' />,
// //       },
// //       {
// //         name: 'Monthly Reports - Reactive, PPM',
// //         path: '/fm-division/monthly-reports',
// //         icon: <Icon icon='ic:round-equalizer' />,
// //       },
// //     ],
// //   },
// //   {
// //     id: 14, //13
// //     name: 'ELV',
// //     icon: <Icon icon='fluent:tap-double-24-filled' />,
// //     path: '/elv/',
// //     child: [
// //       {
// //         name: 'Dashboard',
// //         icon: <Icon icon='ic:round-dashboard' />,
// //         path: '/elv/dashboard',
// //         children: null,
// //       },
// //       {
// //         name: 'Inspection Report',
// //         icon: <Icon icon='ic:baseline-text-snippet' />,
// //         path: '/elv/inspection-report',
// //         children: null,
// //       },
// //       {
// //         name: 'Incident Report',
// //         icon: <Icon icon='ic:baseline-wysiwyg' />,
// //         path: '/elv/incident-report',
// //         children: null,
// //       },
// //       {
// //         name: 'Snag Report',
// //         icon: <Icon icon='ic:baseline-view-timeline' />,
// //         path: '/elv/snag',
// //         children: [
// //           {
// //             name: 'Snag Report',
// //             path: '/elv/snag/snag-report',
// //           },
// //         ],
// //       },
// //       {
// //         name: 'Enquiry',
// //         icon: <Icon icon='ic:outline-chat-bubble' />,
// //         count: '3',
// //         path: '/elv/enquiry',
// //         children: null,
// //       },
// //       {
// //         name: 'Estimation Sheet for AMC',
// //         icon: <Icon icon='ic:outline-menu-book' />,
// //         path: '/elv/estimation-sheet-amc',
// //         children: null,
// //       },
// //       {
// //         name: 'Estimation Installation',
// //         icon: <Icon icon='ic:outline-double-arrow' />,
// //         path: '/elv/estimation-installation',
// //         children: null,
// //       },
// //       {
// //         name: 'Site Visit & Follow Up',
// //         icon: <Icon icon='ic:round-description' />,
// //         path: '/elv/site-enquiry',
// //         children: null,
// //       },
// //       {
// //         name: 'Site Visit & CheckList',
// //         icon: <Icon icon='ic:round-chrome-reader-mode' />,
// //         path: '/elv/site-visit-checklist',
// //         children: null,
// //       },
// //       {
// //         name: 'Proposal',
// //         icon: <Icon icon='ic:outline-streetview' />,
// //         path: '/elv/proposal',
// //         children: null,
// //       },
// //       {
// //         name: 'Budget',
// //         icon: <Icon icon='ic:baseline-payments' />,
// //         path: '/elv/budget',
// //         children: null,
// //       },
// //       {
// //         name: 'Sub Contractors',
// //         icon: <Icon icon='ic:round-supervisor-account' />,
// //         path: '/elv/subcontractor',
// //         children: null,
// //       },
// //       {
// //         name: 'Sales Quotation',
// //         icon: <Icon icon='ic:round-file-open' />,
// //         path: '/elv/create-sales-quotation',
// //         children: null,
// //       },
// //       {
// //         name: 'Sales Orders',
// //         icon: <Icon icon='ic:round-table-chart' />,
// //         path: '/elv/create-sales-order',
// //         children: null,
// //       },
// //       {
// //         name: 'MR Request',
// //         icon: <Icon icon='ic:round-add-to-photos' />,
// //         path: '/elv/material-request',
// //         children: null,
// //       },
// //       {
// //         name: 'Company Legal Certificate',
// //         icon: <Icon icon='ic:round-workspace-premium' />,
// //         path: '/elv/company-certificate',
// //         children: null,
// //       },
// //       {
// //         name: 'Employee Certificate',
// //         path: '/elv/certificates',
// //         icon: <Icon icon='ic:round-sticky-note-2' />,
// //       },
// //       {
// //         name: 'Job Requisition',
// //         path: '/elv/job-requisition',
// //         icon: <Icon icon='ic:round-cases' />,
// //       },

// //       {
// //         name: 'View Service Request',
// //         path: '/elv/view-service-request',
// //         icon: <Icon icon='ic:round-adb' />,
// //       },
// //       {
// //         name: 'PPM planner',
// //         path: '/elv/ppm-planner',
// //         icon: <Icon icon='ic:sharp-app-registration' />,
// //       },
// //       {
// //         name: 'PPM workorders',
// //         path: '/elv/ppm-workorders',
// //         icon: <Icon icon='ic:round-fact-check' />,
// //       },
// //       {
// //         name: 'Asset Details',
// //         path: '/elv/asset-details',
// //         icon: <Icon icon='ic:round-room-preferences' />,
// //       },
// //       {
// //         name: 'Monthly Reports - Reactive, PPM',
// //         path: '/elv/monthly-reports',
// //         icon: <Icon icon='ic:round-equalizer' />,
// //       },
// //     ],
// //   },
// //   {
// //     id: 15, //14
// //     name: 'QA & QC',
// //     icon: <Icon icon='fluent:shield-person-20-filled' />,
// //     path: '/qaqc/',
// //     child: [
// //       {
// //         name: 'Dashboard',
// //         icon: <Icon icon='ic:round-dashboard' />,
// //         path: '/qaqc/dashboard',
// //         children: null,
// //       },
// //       {
// //         name: 'QA & QC Module ',
// //         icon: <Icon icon='ic:baseline-library-books' />,
// //         path: '/qaqc/qa-and-qc-module',
// //         children: null,
// //       },
// //       {
// //         name: 'Customer Satisfaction',
// //         icon: <Icon icon='ic:baseline-gpp-good' />,
// //         path: '/qaqc/customer-satisfaction',
// //         children: null,
// //       },
// //       {
// //         name: 'Customer Satisfaction Report',
// //         icon: <Icon icon='ic:baseline-data-thresholding' />,
// //         path: '/qaqc/customer-satisfaction-report',
// //         children: null,
// //       },
// //       {
// //         name: 'Masters',
// //         icon: <Icon icon='ic:round-settings' />,
// //         path: '/qaqc/masters',
// //         children: [
// //           {
// //             name: 'Customers',
// //             path: '/qaqc/masters/customers',
// //           },
// //           {
// //             name: 'Area',
// //             path: '/qaqc/masters/areas',
// //           },
// //           {
// //             name: 'Sub Area',
// //             path: '/qaqc/masters/sub-areas',
// //           },
// //         ],
// //       },
// //     ],
// //   },
// //   {
// //     id: 16, //15
// //     name: 'Reports',
// //     icon: <Icon icon='fluent:data-usage-24-filled' />,
// //     path: '/reports/',
// //     child: [
// //       {
// //         name: 'Dashboard',
// //         icon: <Icon icon='ic:round-dashboard' />,
// //         path: '/reports/dashboard',
// //         children: null,
// //       },

// //       {
// //         name: 'SAP Account Payable Open Items Report',
// //         icon: <Icon icon='ic:round-description' />,
// //         path: '/reports/sap-accounts-payable-open-items-report',
// //         children: null,
// //       },

// //       {
// //         name: 'Accounts Payable Reconciliation',
// //         icon: <Icon icon='ic:baseline-how-to-vote' />,
// //         path: '/reports/accounts-payable-reconciliation',
// //         children: null,
// //       },

// //       {
// //         name: 'Accounts Payable Trial Balance',
// //         icon: <Icon icon='ic:baseline-payments' />,
// //         path: '/reports/accounts-payable-trial-balance',
// //         children: null,
// //       },

// //       {
// //         name: 'Accounts Receivable Reconciliation',
// //         icon: <Icon icon='ic:baseline-join-full' />,
// //         path: '/reports/accounts-receivable-reconciliation',
// //         children: null,
// //       },

// //       {
// //         name: 'Bank Reconciliation Statement',
// //         icon: <Icon icon='ic:baseline-payment' />,
// //         path: '/reports/bank-reconciliation-statement',
// //         children: null,
// //       },

// //       {
// //         name: 'Cash Trial Balance',
// //         icon: <Icon icon='ic:round-request-quote' />,
// //         path: '/reports/cash-trial-balance',
// //         children: null,
// //       },

// //       {
// //         name: 'Cost Audit Report',
// //         icon: <Icon icon='ic:round-engineering' />,
// //         path: '/reports/cost-audit-report',
// //         children: null,
// //       },

// //       {
// //         name: 'Cost Center Report',
// //         icon: <Icon icon='ic:round-fact-check' />,
// //         path: '/reports/cost-center-report',
// //         children: null,
// //       },

// //       {
// //         name: 'Fixed Assets Depreciation Report',
// //         icon: <Icon icon='ic:round-token' />,
// //         path: '/reports/fixed-assets-depreciation-report',
// //         children: null,
// //       },

// //       {
// //         name: 'Balance Sheet Report',
// //         icon: <Icon icon='ic:round-equalizer' />,
// //         path: '/reports/balance-sheet-report',
// //         children: null,
// //       },

// //       {
// //         name: 'Trial Balance Report',
// //         icon: <Icon icon='ic:sharp-dynamic-form' />,
// //         path: '/reports/trial-balance-report',
// //         children: null,
// //       },

// //       {
// //         name: 'Financial Statement Of Accounts',
// //         icon: <Icon icon='ic:round-receipt' />,
// //         path: '/reports/financial-statement-of-accounts',
// //         children: null,
// //       },
// //     ],
// //   },
// // ];

// export const Nav:NavItem[]=[]

// export const populateNav = (apiData: any,dispatch:any) => {
//   const dynamicNav = apiData?.map((module: any) => ({
//     id: module?.moduleId,
//     name: module?.moduleName,
//     icon: module?.icon ? <Icon icon={module.icon} /> : null,
//     path: module?.url || '',
//     child: module?.menus?.map((menu: any) => ({
//       id: menu?.menuId,
//       name: menu?.menuName,
//       icon: menu?.icon ? <Icon icon={menu.icon} /> : null,
//       path: menu?.url,
//       children: menu?.childMenus?.map((child: any) => ({
//         id: child?.menuId,
//         name: child?.menuName,
//         icon: child?.icon ? <Icon icon={child.icon} /> : null,
//         path: child?.url,
//       })),
//     })),
//   }));
//   Nav.length = 0;
//   dynamicNav?.forEach((item) => {
//     dispatch(setMenu(item))
//     Nav.push(item)
//   });
// };

// export default Nav;

import { Icon } from '@iconify/react'; // Assuming Iconify is used for icons
import React from 'react';
import { Dispatch } from 'redux'; // Assuming you're using Redux
import { setMenu } from '../redux/menuSlice.ts'; // Replace with your actual action

export interface ChildMenu {
  id: string;
  name: string;
  icon: JSX.Element | null;
  path: string;
}

export interface Menu {
  id: string;
  name: string;
  icon: JSX.Element | null;
  path: string;
  children?: ChildMenu[];
}

export interface NavItem {
  id: string;
  name: string;
  icon: JSX.Element | null;
  path: string;
  child?: Menu[];
}

export const Nav: NavItem[] = [];

export const populateNav = (apiData: any[], dispatch: Dispatch) => {
  if (!Array.isArray(apiData)) return;

  const dynamicNav: NavItem[] = apiData.map((module) => ({
    id: module?.moduleId || '',
    name: module?.moduleName || '',
    icon: module?.icon ? <Icon icon={module.icon} /> : null,
    path: module?.url || '',
    child: module?.menus?.map((menu) => ({
      id: menu?.menuId || '',
      name: menu?.menuName || '',
      icon: menu?.icon ? <Icon icon={menu.icon} /> : null,
      path: menu?.url || '',
      children: menu?.childMenus?.map((child) => ({
        id: child?.menuId || '',
        name: child?.menuName || '',
        icon: child?.icon ? <Icon icon={child.icon} /> : null,
        path: child?.url || '',
      })),
    })),
  }));

  // Replace Nav content
  Nav.splice(0, Nav.length, ...dynamicNav);

  // Dispatch menu items to Redux store
  dynamicNav.forEach((item) => {
    if (item.id && item.name) {
      dispatch(setMenu(item));
    }
  });
};

export default Nav;
