import { Icon } from '@iconify/react/dist/iconify.js';
import { Box, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Crud_Service from '../../../apis/CrudService.jsx';
import TableActionButtons from '../../../components/_form/TableActionButtons.jsx';
import FmMRTDataTable from '../../../components/FmMRTDataTable.tsx';
import useTableLogic from '../../../components/helpers/MRTUseTableLogic.tsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import MRTExpandTableStyle from '../../../components/table/MRTExpandTableStyle.tsx';
import Icons from '../../../utils/Icon.jsx';
import { toast } from 'react-toastify';
import FmViewDateField from '../../../components/_mui/FmViewDateField.tsx';

const ListVendorDocuments = () => {
  const {
    rows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic(`vendordocuments`);
  const crud = new Crud_Service();
  const navigate = useNavigate();

  const handleViewClick = async (props, type) => {
    navigate(`${type}/${props?.vendorDocumentId}`);
  };

  const handleDelete = async (props) => {
    try {
      await crud.remove(
        `vendordocuments`,
        props?.vendorDocumentId,
        (err, res) => {
          if (res?.status === 204) {
            fetchData();
            toast.success('Vendor Document Deleted successfully');
          }
        }
      );
    } catch (error) {}
  };
  const updateStatus = async (statusId, data) => {
    try {
      const combinedData = {
        ...data,
        vendorDocumentStatusId: statusId,
      };
      await crud.update(
        `vendordocuments`,
        data.vendorDocumentId,
        combinedData,
        (err, res) => {
          if (res?.status === 204) {
            fetchData();
            toast.success('Vendor Document Status Updated successfully');
          }
        }
      );
    } catch (error) {}
  };
  const ActionData = [
    {
      name: 'View',
      icon: Icons.view,
      onClick: (props) => handleViewClick(props, 'view'),
    },
    {
      name: 'Edit',
      icon: Icons.edit,
      onClick: (props) => handleViewClick(props, 'edit'),
    },

    {
      name: 'Delete',
      icon: Icons.delete,
      onClick: (props) => handleDelete(props),
      danger: true,
    },
  ];

  const columns = [
    {
      header: 'Actions',
      size: 100,
      width: 210,

      Cell: ({ row }) => {
        const transferred = row?.original.vendorDocumentStatusId === 2;
        const acknowledged = row?.original.vendorDocumentStatusId === 3;
        const submitted = row?.original.vendorDocumentStatusId === 1;
        const notesSubmitted = row?.original.vendorDocumentStatusId === 5;
        const uploadDocument = row?.original.vendorDocumentStatusId === 4;
        console.log(row, 'transferred');

        return (
          <Box className='flex gap-2'>
            {' '}
            <TableActionButtons
              Actions={ActionData?.map((action) => ({
                ...action,
                onClick: () => action.onClick(row?.original),
              }))}
            />
            {transferred && (
              <Button
                className='whitespace-nowrap mx-2 p-1 px-3'
                onClick={() => updateStatus(3, row?.original)}
                variant='contained'
              >
                Acknowledge
              </Button>
            )}
          </Box>
        );
      },
    },
    {
      accessorKey: 'vendorDocumentStatusName',
      header: 'Status',
      size: 200,
    },
    {
      accessorKey: 'invoiceNumber',
      header: 'Document Ref. No./Invoice No.',
      size: 200,
    },

    {
      accessorKey: 'localPurchaseOrderNo',
      header: 'Local PO No.',
    },
    {
      accessorKey: 'receivedDate',
      header: 'Received Date',
      size: 150,
      cell: ({ params }) => {
        return FmViewDateField({ value: params?.value });
      },
    },
    {
      accessorKey: 'supplierName',
      header: 'Supplier',
      size: 200,
    },
    {
      accessorKey: 'documentTypeName',
      header: 'Document Type',
    },
    {
      accessorKey: 'documentTransferredToUserName',
      header: 'Document Transferred To',
      size: 200,
    },

    {
      accessorKey: 'createdDate',
      header: 'Created Date',
      size: 150,
      cell: ({ params }) => {
        return FmViewDateField({ value: params?.value });
      },
    },
    {
      accessorKey: 'updatedDate',
      header: 'Updated Date',
      size: 150,
      cell: ({ params }) => {
        return FmViewDateField({ value: params?.value });
      },
    },
  ];

  return (
    <>
      <GlassCard className='flex-1 min-h-0'>
        <MRTExpandTableStyle enableFullHeight>
          <FmMRTDataTable
            rows={rows}
            columns={columns}
            enableRowSelection={false}
            rowCount={tableRecordCounts}
            pagination={pagination}
            sorting={sorting}
            columnFilters={columnFilters}
            globalFilter={globalFilter}
            setPagination={setPagination}
            setSorting={setSorting}
            setColumnFilters={setColumnFilters}
            setGlobalFilter={setGlobalFilter}
            isLoading={isLoading}
            setSearchKeyword={setSearchKeyword}
            searchKeyword={searchKeyword}
            fetchData={fetchData}
            handleRowDoubleClick={(e) => {
              handleViewClick(e?.original, 'edit');
            }}
            muiTableContainerProps={{
              sx: {
                maxHeight: '100%',
                flex: 1,
              },
            }}
          />
        </MRTExpandTableStyle>
      </GlassCard>
    </>
  );
};

export default ListVendorDocuments;
