import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Crud_Service from '../../../apis/CrudService.jsx';
import MuiDialogOne from '../../../components/_mui/MuiDialogOne.jsx';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import CommonView from '../../../components/helpers/CommonView.jsx';
import DynamicViewFields from '../../../components/helpers/DynamicViewFields.jsx';
import useTableLogic from '../../../components/helpers/MRTUseTableLogic.tsx';
import { CreateButton } from '../../../components/small/Buttons.jsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { findNavByName } from '../../../utils/navUtils.ts';
import UniformReturnDataTable from './UniformReturnDatatTable.tsx';

const UniformReturn = () => {
  const [viewDetails, setViewdetails] = useState(false);
  const {
    rows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic(`uniformandaccessoriesrequisitionreturns`);
  const [editingRowId, setEditingRowId] = useState(null);
  const [editingRowData, setEditingRowData] = useState(null);
  const crud = new Crud_Service();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = `Uniforms Return`;
  }, []);

  const handleUpdateUniforms = async (values) => {
    const combinedData = {
      ...values,
      requistionDate: dayjs(values?.requistionDate).format('YYYY-MM-DD'),
    };

    await crud.update(
      'uniformandaccessoriesrequisitionreturns',
      editingRowId,
      combinedData,
      (err, res) => {
        if (res?.status === 200) {
          toast.success('uniform and Accessories return Updated Successfully');
          fetchData();
          setEditingRowId(null);
          setEditingRowData(null);
        } else {
        }
      }
    );
  };

  const excludeKeys = [
    'createdDate',
    'employeeId',
    'uniformAndAccessoriesRequisitionId',
    'uniformAndAccessoriesRequisitionReturnId',
    'uniformSizeName',
    'uniformTypeName',
    'status',
  ];

  const menu = useSelector((state) => state?.menu?.items);

  const SubMenu = useMemo(() => {
    return menu?.map((item) => item?.menus?.flat()).flat() || [];
  }, [menu]);

  const result = useMemo(
    () => findNavByName(SubMenu, 'Admin', 'Uniform & Accessories - Requisitions'),
    [SubMenu]
  );

  return (
    <>
      <DashboardLayout
        title='Uniforms Return'
        hasSubmenu
        actionButtons={
          <CreateButton name='Create' onClick={() => navigate('create')} />
        }
        menu={result}
      >
        <GlassCard className='h-full'>
          <UniformReturnDataTable
            rows={rows}
            editingRowId={editingRowId}
            isLoading={isLoading}
            editingRowData={editingRowData}
            handleUpdateUniforms={handleUpdateUniforms}
            setViewdetails={setViewdetails}
            setEditingRowId={setEditingRowId}
            setEditingRowData={setEditingRowData}
            crud={crud}
            fetchData={fetchData}
            pagination={pagination}
            sorting={sorting}
            columnFilters={columnFilters}
            globalFilter={globalFilter}
            tableRecordCounts={tableRecordCounts}
            setPagination={setPagination}
            setGlobalFilter={setGlobalFilter}
            setColumnFilters={setColumnFilters}
            setSorting={setSorting}
            setSearchKeyword={setSearchKeyword}
            searchKeyword={searchKeyword}
          />
        </GlassCard>
      </DashboardLayout>

      {/* view */}

      <MuiDialogOne
        title='View Details'
        open={viewDetails}
        onClose={() => setViewdetails(!viewDetails)}
      >
        {viewDetails &&
          viewDetails?.uniformAndAccessoriesRequisitionReturnId && (
            <CommonView
              url='uniformandaccessoriesrequisitionreturns'
              id={viewDetails?.uniformAndAccessoriesRequisitionReturnId}
              excludeKeys={excludeKeys}
              renderFields={(data, fields) => (
                <DynamicViewFields data={data} fields={fields} />
              )}
            />
          )}
      </MuiDialogOne>
      {/* view */}
    </>
  );
};

export default UniformReturn;
