import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Box, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import Crud_Service from '../../../../apis/CrudService.jsx';
import ActionButtons from '../../../../components/_form/ActionButtons.tsx';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import GlassCardCollapse from './../../../../components/small/GlassCardCollapse.tsx';
import ViewSalesOrderDistributionBudgetsTable from './ViewSalesOrderDistributionBudgetsTable.tsx';
import { SalesOrderBudgetvalidationSchema } from '../../../../utils/CommonvalidationSchemas.tsx';
import useTableLogic from '../../../../components/helpers/MRTUseTableLogic.tsx';

const MdItemValue = 2.4;

const ViewTotalValueList = ({ viewData, salesOrderDistributionId }) => {
  const { handleSubmit, control, reset, watch, setValue, getValues, trigger } =
    useForm({
      resolver: yupResolver(SalesOrderBudgetvalidationSchema),
      mode: 'onChange',
    });
  const {
    rows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
    data,
  } = useTableLogic(
    'salesorderdistributionbudgets',
    'salesOrderDistributionId',
    salesOrderDistributionId
  );

  const crud = new Crud_Service();
  const { createLoading, startCreateLoading, stopCreateLoading } = useLoader();

  const [warn, setWarn] = useState('');

  const handleBudgetChange = (fieldName, value) => {
    const totalContractValue = viewData?.totalContractValue || 0;
    const totalContractValueSum = rows?.reduce(
      (acc, row) => acc + (row?.totalContractValue || 0),
      0
    );
    const remainingContractValue = totalContractValue - totalContractValueSum;

    if (fieldName === 'totalContractValuePercentage') {
      const calculatedTotalContractValue = (totalContractValue * value) / 100;

      setValue(
        'totalContractValue',
        parseFloat(calculatedTotalContractValue.toFixed(2))
      );

      const totalContractValueField = getValues('totalContractValue');

      if (totalContractValueField > remainingContractValue) {
        setWarn(
          `Value exceeds the remaining contract value of ${remainingContractValue}`
        );
      } else {
        setWarn('');
      }
    } else if (fieldName === 'totalBudgetValuePercentage') {
      const totalContractValue = watch('totalContractValue');
      if (totalContractValue) {
        const calculatedTotalBudgetValue = (totalContractValue * value) / 100;

        setValue(
          'totalBudgetValue',
          parseFloat(calculatedTotalBudgetValue.toFixed(2))
        );
      }
    }
  };

  const handleSubmitSalesOrderBudget = async (values) => {
    const { totalContractValue } = values;

    if (
      viewData?.totalContractValue &&
      totalContractValue > viewData?.totalContractValue
    ) {
      toast.error('Total value exceeds Contract Value');
      return;
    }

    startCreateLoading();
    const combinedData = {
      ...values,
      salesOrderDistributionId,
    };

    await crud.create(
      'salesorderdistributionbudgets',
      combinedData,
      (err, res) => {
        if (err) {
          stopCreateLoading();
          return;
        }
        if (res?.status === 201) {
          toast.success('Sales Order Distribution Budget Created successfully');
          fetchData();
          reset();
          stopCreateLoading();
        } else {
          stopCreateLoading();
        }
      }
    );
  };

  const valueTotal = watch('totalContractValuePercentage');

  useEffect(() => {
    if (valueTotal) {
      handleBudgetChange('totalContractValuePercentage', valueTotal || 0);
    }
  }, [valueTotal]);

  return (
    <>
      <GlassCardCollapse title='Create New' className='my-2' collapsed>
        <form>
          <Box>
            <Grid container spacing={2}>
              <Grid item md={MdItemValue}>
                <FmSearchableSelect
                  name='divisionId'
                  control={control}
                  apiUrl='divisions'
                  valueField='divisionId'
                  labelField={['divisionCode', 'divisionName']}
                  showField={['divisionCode', 'divisionName']}
                  label='Division'
                  required
                />
              </Grid>

              <Grid item md={MdItemValue}>
                <FmTextField
                  name='totalContractValuePercentage'
                  control={control}
                  label='Total Contract Value Percentage'
                  pattern='Percentage'
                  required
                />
              </Grid>

              <Grid item md={MdItemValue}>
                <FmTextField
                  name='totalContractValue'
                  control={control}
                  label='Total Contract Value'
                  readOnly
                  pattern='Decimal'
                />
              </Grid>

              <Grid item md={MdItemValue}>
                <FmTextField
                  name='totalBudgetValuePercentage'
                  control={control}
                  label='Total Budget Value Percentage'
                  pattern='Decimal'
                  required
                  onChange={(e) =>
                    handleBudgetChange(
                      'totalBudgetValuePercentage',
                      parseFloat(e.target.value) || 0
                    )
                  }
                />
              </Grid>

              <Grid item md={MdItemValue}>
                <FmTextField
                  name='totalBudgetValue'
                  control={control}
                  label='Total Budget'
                  readOnly
                  pattern='Decimal'
                />
              </Grid>
            </Grid>

            {warn && <Alert severity='warning'>{warn}</Alert>}
            <ActionButtons
              onSubmit={handleSubmit(handleSubmitSalesOrderBudget)}
              onReset={() => {
                reset();
                setWarn('');
              }}
              submitLoading={createLoading}
              disabled={!viewData?.isWorkOrderCreated || !!warn}
              submitText='Create'
            />
          </Box>
        </form>
      </GlassCardCollapse>

      <GlassCard>
        <ViewSalesOrderDistributionBudgetsTable
          salesOrderDistributionId={salesOrderDistributionId}
          rows={rows}
          tableRecordCounts={tableRecordCounts}
          isLoading={isLoading}
          pagination={pagination}
          sorting={sorting}
          setPagination={setPagination}
          setSorting={setSorting}
          columnFilters={columnFilters}
          globalFilter={globalFilter}
          setColumnFilters={setColumnFilters}
          setGlobalFilter={setGlobalFilter}
          setSearchKeyword={setSearchKeyword}
          searchKeyword={searchKeyword}
          fetchData={fetchData}
          viewData={viewData}
        />
      </GlassCard>
    </>
  );
};

export default ViewTotalValueList;