import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Crud_Service from '../../../../apis/CrudService.jsx';
import MuiDialogOne from '../../../../components/_mui/MuiDialogOne.jsx';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import CommonView from '../../../../components/helpers/CommonView.jsx';
import DynamicViewFields from '../../../../components/helpers/DynamicViewFields.jsx';
import useTableLogic from '../../../../components/helpers/MRTUseTableLogic.tsx';
import { CreateButton } from '../../../../components/small/Buttons.jsx';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import { findNavByName } from '../../../../utils/navUtils.ts';
import HiredVechiclesDataTable from './HiredVechiclesDataTable.tsx';

const HiredVechicles = () => {
  const [viewDetails, setViewdetails] = useState(false);
  const {
    rows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic(`hiredvehicles`);
  const [editingRowId, setEditingRowId] = useState(null);
  const [editingRowData, setEditingRowData] = useState(null);
  const crud = new Crud_Service();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = `Hired Vechicles | Admin`;
  }, []);
  const handleUpdateHiredVechicles = async (values) => {
    const combinedData = {
      ...values,
      requistionDate: dayjs(values?.requistionDate).format('YYYY-MM-DD'),
    };
    await crud.update(
      'hiredvehicles',
      editingRowId,
      combinedData,
      (err, res) => {
        if (res?.status === 200) {
          toast.success('Hired Vechicles Updated Successfully');
          fetchData();
          setEditingRowId(null);
          setEditingRowData(null);
        } else {
        }
      }
    );
  };
  const excludeKeys = [
    'contactPersonId',
    'contractId',
    'divisionId',
    'driverId',
    'hiredVehicleId',
    'locationId',
    'projectId',
    'siteId',
    'stationId',
    'supplierId',
    'vehicleTypeId',
    'status',
  ];
  const menu = useSelector((state) => state?.menu?.items);

  const SubMenu = useMemo(() => {
    return menu?.map((item) => item?.menus?.flat()).flat() || [];
  }, [menu]);

  const result = useMemo(
    () => findNavByName(SubMenu, 'Admin', 'Vehicles'),
    [SubMenu]
  );

  return (
    <>
      <DashboardLayout
        title='Hired Vechicles'
        hasSubmenu
        actionButtons={
          <CreateButton name='Create' onClick={() => navigate('create')} />
        }
        menu={result}
      >
        {/* <AddHiredVechicles fetchData={fetchData} /> */}
        <GlassCard>
          <HiredVechiclesDataTable
            rows={rows}
            editingRowId={editingRowId}
            isLoading={isLoading}
            editingRowData={editingRowData}
            handleUpdateHiredVechicles={handleUpdateHiredVechicles}
            setViewdetails={setViewdetails}
            setEditingRowId={setEditingRowId}
            setEditingRowData={setEditingRowData}
            crud={crud}
            fetchData={fetchData}
            pagination={pagination}
            sorting={sorting}
            columnFilters={columnFilters}
            globalFilter={globalFilter}
            tableRecordCounts={tableRecordCounts}
            setPagination={setPagination}
            setGlobalFilter={setGlobalFilter}
            setColumnFilters={setColumnFilters}
            setSorting={setSorting}
            setSearchKeyword={setSearchKeyword}
            searchKeyword={searchKeyword}
          />
        </GlassCard>
      </DashboardLayout>
      {/* view */}
      <MuiDialogOne
        title='View Details'
        open={viewDetails}
        onClose={() => setViewdetails(!viewDetails)}
        width='800px'
      >
        {viewDetails && viewDetails?.hiredVehicleId && (
          <CommonView
            url='hiredvehicles'
            id={viewDetails?.hiredVehicleId}
            excludeKeys={excludeKeys}
            renderFields={(data, fields) => (
              <DynamicViewFields data={data} fields={fields} />
            )}
          />
        )}
      </MuiDialogOne>
      {/* view */}
    </>
  );
};
export default HiredVechicles;
