import { Box } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CreateButton } from '../../../components/small/Buttons.jsx';
import { findNavByName } from '../../../utils/navUtils.ts';
import DashboardLayout from './../../../components/DashboardLayout.tsx';
import ListSnagReport from './ListSnagReport.tsx';

const SnagReport = (props) => {
  const { module } = props;
  useEffect(() => {
    document.title = `Snag Report | ${module === 1 ? 'FM Division' : 'CAFM'}`;
  }, []);
  const navigate = useNavigate();

  const menu = useSelector((state) => state?.menu?.items);

  const SubMenu = useMemo(() => {
    return menu?.map((item) => item?.menus?.flat()).flat() || [];
  }, [menu]);

  const result = useMemo(
    () => findNavByName(SubMenu, 'CAFM', 'Snag Report'),
    [SubMenu]
  );

  return (
    <DashboardLayout
      title='Snag Report'
      hasSubmenu
      menu={result}
      actionButtons={
        <>
          <Box className='flex flex-row items-center whitespace-nowrap gap-3'>
            <CreateButton name='Create' onClick={() => navigate('create')} />
          </Box>
        </>
      }
    >
      <ListSnagReport />
    </DashboardLayout>
  );
};

export default SnagReport;
