import { yupResolver } from '@hookform/resolvers/yup';
import { Icon } from '@iconify/react';
import {
  Box,
  Button,
  CardContent,
  CircularProgress,
  FormControlLabel,
  Grid,
  Switch,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import Card from '@mui/material/Card';
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import apiInstance from '../../../../apis/ApiService.jsx';
import Crud_Service from '../../../../apis/CrudService.jsx';
import ActionButtons from '../../../../components/_form/ActionButtons.tsx';
import TableActionButtons from '../../../../components/_form/TableActionButtons.jsx';
import FmDatePicker from '../../../../components/_mui/FmDatePicker.tsx';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import MuiDialogOne from '../../../../components/_mui/MuiDialogOne.jsx';
import FmMRTDataTable from '../../../../components/FmMRTDataTable.tsx';
import useTableLogic from '../../../../components/helpers/MRTUseTableLogic.tsx';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import GlassCardCollapse from '../../../../components/small/GlassCardCollapse.tsx';
import UploadModalBox from '../../../../components/upload/UploadModalBox.tsx';
import { useHotkeys } from 'react-hotkeys-hook';
import { ViewField } from '../../../../components/_form/FormElements.jsx';
import ViewTenderBuildingImages from './ViewTenderBuildingImages.tsx';
import ViewTenderSiteVisitImages from './ViewTenderSiteVisitImages.tsx';

const navMenu = [
  {
    id: 1,
    name: 'Checklist Items',
  },
  {
    id: 2,
    name: 'Building Images',
  },
  {
    id: 3,
    name: 'Site Visit Images',
  },
];

const ViewTenderBasedCheckListDetail = () => {
  const { id } = useParams();
  const [updateView, setUpdateView] = useState(false);
  const [tenderChecklist, setTenderChecklist] = useState(null);
  const [tenderChecklistDetailId, setTenderChecklistDetailId] = useState(null);
  const [scopeNotes, setScopeNotes] = useState([]);
  const [uploadedFileIds, setUploadedFileIds] = useState([]);
  const [files, setFiles] = useState([]);
  const [editingRowIndex, setEditingRowIndex] = useState(null);
  const { startCreateLoading, stopCreateLoading, createLoading } = useLoader();
  const [editData, setEditData] = useState(null);
  const firstColumnInputRef = useRef(null);
  const [previewdData, setPreviewData] = useState([]);
  const [previewCheckListData, setPreviewCheckListData] = useState([]);
  const [stateError, setStateError] = useState(false);
  const { startEditLoading, stopEditLoading } = useLoader();
  const [loader, setLoader] = useState(false);
  const [tabValue, setTabValue] = useState(navMenu[0]?.id || 0);
  const clientDetails = [
    { key: 'clientName', title: 'Client Name' },
    { key: 'clientContactPerson', title: 'Contact Person' },
    { key: 'clientMail', title: 'Email' },
    { key: 'clientContactNo', title: 'Contact Number' },
  ];
  const {
    rows,
    isLoading,
    pagination,
    setRows,
    sorting,
    setSearchKeyword,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    searchKeyword,
    fetchData,
  } = useTableLogic(
    `tenderinspectionchecklistdetail`,
    'tenderInspectionCheckListId',
    tenderChecklistDetailId
  );
  const crud = new Crud_Service();
  const navigate = useNavigate();
  const [rowState, setRowState] = useState({
    newRowId: null,
    isCreatingRow: false,
  });
  const validationSchema = yup.object().shape({
    sspName: yup.string().required('sspName is required'),
  });
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    getValues,
    formState: { isValid },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  const {
    handleSubmit: creatCheckListSubmit,
    control: creatCheckListControl,
    setValue: creatCheckListSetValue,
  } = useForm();

  const handleAddNewRow = () => {
    const newId = 'new_row_' + Date.now();
    reset();
    setEditingRowData(null);
    setEditingRowId(null);
    setRowState({
      newRowId: newId,
      isCreatingRow: true,
    });
    setEditingRowIndex(
      editingRowIndex === rows.length ? editingRowIndex + 1 : rows.length
    );

    setRows((prevRows) => [
      ...prevRows,
      {
        tenderInspectionCheckListDetailId: newId,
        assetName: '',
        subLocation: '',
        quantity: '',
        make: '',
        sspName: '',
        condition: '',
        remarks: '',
        isEditable: true,
        isNew: true,
      },
    ]);
    reset();
  };

  const handleChange = (_event, newValue) => {
    setTabValue(newValue);
  };

  //Update Assests
  const handleEditCheckListDetail = async (values) => {
    startEditLoading();

    const convertedValues = {
      ...values,
      quantity: values?.quantity,
      sspName: values?.sspName,
      remarks: values?.remarks,
      isAvailable: values?.availability,
    };
    const combinedData = {
      tenderInspectionCheckListId: Number(tenderChecklistDetailId) || null,
      tenderInspectionCheckListDetails: [convertedValues],
    };
    const updateCombinedData = {
      ...values,
      quantity: values?.quantity,
      remarks: values?.remarks,
      sspName: values?.sspName,
      isAvailable: values?.availability,
      status: 2,
    };

    if (rowState?.newRowId) {
      await crud.create(
        'tenderinspectionchecklistdetail',

        combinedData,
        (err, res) => {
          if (err) {
            toast.error(err);
          }
          if (res?.status === 201) {
            toast.success('CheckList Created Successfully');

            setEditingRowId(null);
            setRowState((prevState) => ({
              ...prevState,
              newRowId: null,
              isCreatingRow: false,
            }));
            setEditingRowData(null);
            fetchData();
          }
        }
      );
    } else {
      try {
        await crud.update(
          'tenderinspectionchecklistdetail',
          editingRowId,
          updateCombinedData,
          (err, res) => {
            if (err) {
              toast.error(err);
            }
            if (res?.status === 200) {
              toast.success('CheckList Updated Successfully');
              setUpdateView(false);
              setEditingRowId(null);
              setEditingRowData(null);
              fetchData();
            }
          }
        );
      } catch (error) {
        toast.error('An error occurred while updating:', error);
      } finally {
        stopEditLoading();
      }
    }
  };

  //Delete Assets
  const handleDeleteClick = async (props) => {
    await crud.remove(
      'tenderinspectionchecklistdetail',
      props?.tenderInspectionCheckListDetailId,
      (err, res) => {
        if (res?.status === 204) {
          toast.success('CheckList Deleted Successfully');
          fetchData();
        } else {
        }
      }
    );
  };
  const [editingRowData, setEditingRowData] = useState(null);
  const handleEdit = async (row) => {
    setEditingRowId(row?.tenderInspectionCheckListDetailId);
    setEditingRowData({ ...row });

    Object.entries({
      ...row,
    }).forEach(([key, value]) => {
      setValue(key, value);
    });
  };
  const handleDownloadFile = async (fileId) => {
    try {
      // Check if fileId is null, undefined, or an empty string
      if (!fileId) {
        return; // Exit early without calling the API
      }

      const imageUrl = await apiInstance.getFiles(`files/download/${fileId}`);

      setPreviewData((prevData) => [...prevData, imageUrl]);
    } catch (err) {
      toast.error(`Error fetching image: ${err.message}`);
    }
  };
  useEffect(() => {
    if (scopeNotes.length > 0) {
      scopeNotes?.forEach((image) => {
        handleDownloadFile(image?.fileId);
      });
    }
  }, [scopeNotes]);

  const ActionData = [
    {
      name: 'Delete',
      icon: <Icon icon='mi:delete' />,
      onClick: (props) => handleDeleteClick(props),
      danger: true,
    },
  ];

  const [editingRowId, setEditingRowId] = useState(null);
  const [openUpload, setOpenUpload] = useState(false);

  const handleCancel = (row) => {
    if (
      row?.original?.tenderInspectionCheckListDetailId === rowState?.newRowId
    ) {
      setRows((prevRows) =>
        prevRows.filter(
          (r) => r?.tenderInspectionCheckListDetailId !== rowState?.newRowId
        )
      );
      setRowState((prevState) => ({
        ...prevState,
        newRowId: null,
        isCreatingRow: false,
      }));
    } else {
      setEditingRowId(null);
    }
    reset();
  };
  const previousValues = useRef(getValues());
  const handleBlur = () => {
    const currentValues = getValues();
    const value = getValues('sspName');
    if (!value) {
      // Refocus the field if empty
      firstColumnInputRef.current.focus();
      return;
    }
    // Compare the previous values with the current values
    const hasChanges = Object.keys(currentValues).some(
      (key) => currentValues[key] !== previousValues.current[key]
    );

    if ((hasChanges && isValid) || (hasChanges && editingRowId)) {
      apiCallAsync(currentValues); // Call the API with the new values
      previousValues.current = currentValues; // Update previous values
    }
  };

  const apiCallAsync = async (values) => {
    setLoader(true);
    const convertedValues = {
      ...values,
      quantity: values?.quantity,
      sspName: values?.sspName,
      remarks: values?.remarks,
      isAvailable: values?.availability,
    };
    const combinedData = {
      tenderInspectionCheckListId: Number(tenderChecklistDetailId) || null,
      tenderInspectionCheckListDetails: [convertedValues],
    };
    const updateCombinedData = {
      ...values,
      quantity: values?.quantity,
      remarks: values?.remarks,
      sspName: values?.sspName,
      isAvailable: values?.availability,
      status: 2,
    };

    if (editingRowId === null) {
      await crud.create(
        'tenderinspectionchecklistdetail',
        combinedData,
        (err, res) => {
          if (res?.status === 201) {
            setEditingRowId(res?.data?.tenderInspectionCheckListDetailId);
            setValue(
              'tenderInspectionCheckListDetailId',
              res?.data?.tenderInspectionCheckListDetailId
            );
          } else {
          }
        }
      );
    } else {
      await crud.update(
        'tenderinspectionchecklistdetail',
        editingRowId,
        updateCombinedData,
        (err, res) => {
          if (res?.status === 200) {
            if (rows.some((row) => row.isNew)) {
              const updatedRow = res?.data;
              const updatedRows = [...rows];
              updatedRows[editingRowIndex] = {
                ...updatedRow,
                isEditable: true,
                isNew: true,
              };
              setRows(updatedRows);
              setStateError(false);
              setLoader(false);
            } else {
              setStateError(false);
              setLoader(false);
              const updatedRow = res?.data;
              const updatedRows = [...rows];

              updatedRows[editingRowIndex] = updatedRow;

              setRows(updatedRows);
            }
          } else {
            setStateError(true);
          }
        }
      );
    }
  };

  useHotkeys(
    'Enter',
    () => handleAddNewRow(),
    {
      enableOnTags: ['INPUT', 'TEXTAREA'], // Optional: Enable hotkey only when focused on specific tags
    },
    [editingRowIndex, rows, stateError, loader]
  );

  useHotkeys(
    'Tab',
    (event) => {
      if (event.key !== 'Tab') return;
      if (stateError) {
        event.preventDefault();
        return;
      }
      const totalRows = rows.length;
      const nextRowIndex = editingRowIndex + 1;

      if (
        nextRowIndex < totalRows &&
        stateError === false &&
        loader === false
      ) {
        handleEdit(rows[nextRowIndex]);
        setEditingRowIndex(nextRowIndex);
      }
    },
    [editingRowIndex, rows, stateError, loader, isValid]
  );

  useEffect(() => {
    if (editingRowIndex !== null && firstColumnInputRef.current && !isLoading) {
      firstColumnInputRef.current.focus();
    }
  }, [editingRowIndex, isLoading]);

  const handleCancelAndAddNewRow = () => {
    const newId = 'new_row_' + Date.now();
    reset();
    setEditingRowData(null);
    setEditingRowId(null);
    setRowState({
      newRowId: newId,
      isCreatingRow: true,
    });
    setEditingRowIndex(
      editingRowIndex === rows.length ? editingRowIndex + 1 : rows.length
    );
    setRows((prevRows) => [
      ...prevRows,
      {
        tenderInspectionCheckListDetailId: newId,
        assetName: '',
        subLocation: '',
        quantity: '',
        make: '',
        sspName: '',
        condition: '',
        remarks: '',
        isEditable: true,
        isNew: true,
      },
    ]);
    reset();
  };

  const columns = [
    {
      header: 'Action',
      enableColumnPinning: true,
      enableEditing: false,
      enableSorting: false,
      size: 80,
      Cell: ({ row, table }) => {
        const isNewRow =
          row?.original?.tenderInspectionCheckListDetailId ===
          rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.tenderInspectionCheckListDetailId ||
          isNewRow;
        const isLastRow = row.index === table.getRowModel().rows.length - 1;
        return (
          <Box className='flex gap-2'>
            {isEditing ? (
              <>
                <Button onClick={() => handleCancel(row)}>Close</Button>
                {isLastRow && rowState?.isCreatingRow === false && (
                  <Button onFocus={() => handleCancelAndAddNewRow()}></Button>
                )}
              </>
            ) : (
              <Box className='flex gap-2'>
                <TableActionButtons
                  Actions={ActionData?.map((action) => ({
                    ...action,
                    onClick: () => action.onClick(row?.original),
                  }))}
                />
                {isLastRow && (
                  <Button
                    variant='contained'
                    color='primary'
                    className='px-3 py-1'
                    onClick={() => {
                      setEditingRowIndex(row?.index + 1);
                      handleAddNewRow(row);
                    }}
                  >
                    Add
                  </Button>
                )}
              </Box>
            )}
          </Box>
        );
      },
    },
    {
      accessorKey: 'sspName',
      header: 'SSP Name',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.tenderInspectionCheckListDetailId ===
          rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.tenderInspectionCheckListDetailId ||
          isNewRow;
        return isEditing ? (
          <FmTextField
            name='sspName'
            onBlur={handleBlur}
            control={control}
            inputRef={firstColumnInputRef}
          />
        ) : (
          row?.original?.sspName || ''
        );
      },
    },

    {
      accessorKey: 'description',
      header: 'Description',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.tenderInspectionCheckListDetailId ===
          rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.tenderInspectionCheckListDetailId ||
          isNewRow;
        return isEditing ? (
          <FmTextField
            name='description'
            control={control}
            onBlur={handleBlur}
          />
        ) : (
          row?.original?.description || ''
        );
      },
    },
    {
      accessorKey: 'isAvailable',
      header: 'Availability',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.tenderInspectionCheckListDetailId ===
          rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.tenderInspectionCheckListDetailId ||
          isNewRow;
        return isEditing ? (
          <FormControlLabel
            value='availability'
            control={
              <Switch
                color='warning'
                size='small'
                defaultChecked={row?.original?.isAvailable || false}
                onChange={(e) => setValue('availability', e.target.checked)}
              />
            }
            label='Yes/No'
            // labelPlacement='end'
          />
        ) : (
          (row?.original?.isAvailable ? 'Yes' : 'No') || ''
        );
      },
    },
    {
      accessorKey: 'quantity',
      header: 'Quantity',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.tenderInspectionCheckListDetailId ===
          rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.tenderInspectionCheckListDetailId ||
          isNewRow;
        return isEditing ? (
          <FmTextField
            pattern='Number'
            name='quantity'
            onBlur={handleBlur}
            control={control}
          />
        ) : (
          row?.original?.quantity || ''
        );
      },
    },
    {
      accessorKey: 'remarks',
      header: 'Remarks',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.tenderInspectionCheckListDetailId ===
          rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.tenderInspectionCheckListDetailId ||
          isNewRow;
        return isEditing ? (
          <FmTextField name='remarks' control={control} onBlur={handleBlur} />
        ) : (
          row?.original?.remarks || ''
        );
      },
    },
  ];

  const handleExport = async () => {
    const downloadUrl = await apiInstance.getFiles(
      `tenderinspectionchecklistdetail/${tenderChecklistDetailId}/export-excel`
    );

    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', 'CheckList.xlsx');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const handleUploadSheet = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileFormData = new FormData();
      fileFormData.append('file', file);
      fileFormData.append('fileTypeId', 4);

      crud.create('files/upload', fileFormData, (_err, res) => {
        if (res?.status === 200) {
          crud.create(
            `tenderinspectionchecklistdetail/${id}/process/${res.data?.fileId}`,
            '',
            (_err, res) => {
              if (res?.status === 200) {
                fetchData();
                setOpenUpload(false);
              }
            }
          );
        } else {
          setOpenUpload(false);
        }
      });
    }
  };
  const handleClose = () => {
    setOpenUpload(false);
  };

  const GridValue = 3;

  const handleSubmitTenderChecklist = async (values) => {
    startCreateLoading();

    const combinedData = {
      ...values,
      siteVisitDate: dayjs(values?.siteVisitDate).format('YYYY-MM-DD'),
      tenderId: Number(id),
      fileUploadIds: uploadedFileIds || [],
    };

    const updateCombinedData = {
      ...values,
      siteVisitDate: dayjs(values?.siteVisitDate).format('YYYY-MM-DD'),
      tenderId: Number(id),
      updateAttachments: uploadedFileIds?.length
        ? uploadedFileIds.map((fileId, index) => ({
            fileId: fileId || values.fileUploadIds?.[index], // Map fileId or fallback to fileUploadIds
            status: 2, // Include status as part of the mapped object
            tenderInspectionCheckListAttachmentId:
              values?.updateAttachments?.[index]
                ?.tenderInspectionCheckListAttachmentId || null, // Ensure correct mapping
          }))
        : [], // Send values?.updateAttachments directly if uploadedFileIds is empty
    };

    if (!tenderChecklistDetailId) {
      await crud.create(
        `tenderinspectionchecklists`,
        combinedData,
        (err, res) => {
          if (err) {
            stopCreateLoading();
            toast.error(err);
          }
          if (res?.status === 201) {
            stopCreateLoading();

            toast.success('Tender Checklist Created Successfully');
            window.location.reload();
          } else {
            stopCreateLoading();
          }
        }
      );
    } else {
      await crud.update(
        `tenderinspectionchecklists`,
        tenderChecklistDetailId,
        updateCombinedData,

        (err, res) => {
          if (err) {
            stopCreateLoading();
            toast.error(err);
          }
          if (res?.status === 200) {
            stopCreateLoading();
            navigate(-1);
            toast.success('Tender Checklist Updated Successfully');
          } else {
            stopCreateLoading();
          }
        }
      );
    }
  };

  useEffect(() => {
    const fetchTenderChecklist = async () => {
      if (id) {
        await crud.getSingle(`tenders`, id, (err, res) => {
          if (res?.status === 200) {
            setTenderChecklist(res?.data);
            const value = res?.data;
            creatCheckListSetValue('clientId', value?.clientId);
            creatCheckListSetValue('clientName', value?.clientName);
            creatCheckListSetValue(
              'ClientContactPerson ',
              value?.ClientContactPerson
            );
            creatCheckListSetValue('ClientMail', value?.ClientMail);
            creatCheckListSetValue('ClientContactNo', value?.ClientContactNo);
          }
        });
      }
    };

    fetchTenderChecklist();
  }, [id]);

  useEffect(() => {
    const CheckTenderChecklist = async () => {
      if (id) {
        await crud.getAll(
          `tenderinspectionchecklists?tenderId=${id}`,
          {},
          (err, res) => {
            if (res?.status === 200) {
              setTenderChecklistDetailId(
                res?.data?.data[0]?.tenderInspectionCheckListId || null
              );
              setEditData(res?.data?.data[0]);
              // Corrected the Object.entries statement
              if (res?.data?.data[0]) {
                Object.entries(res.data.data[0]).forEach(([key, value]) => {
                  creatCheckListSetValue(key, value);
                });
              }
            }
          }
        );

        await crud.getAll(`tenderscopes?tenderId=${id}`, {}, (err, res) => {
          if (res?.status === 200) {
            setScopeNotes(res?.data?.data);
          }
        });
      }
    };

    CheckTenderChecklist();
  }, [id]);

  useEffect(() => {
    if (tenderChecklistDetailId) {
      fetchData(); // Or any logic that needs to be triggered
    }
  }, [tenderChecklistDetailId]);

  const handleFileIds = (fileIds) => {
    setUploadedFileIds(fileIds);
  };

  useEffect(() => {
    if (editData && editData?.tenderInspectionCheckListAttachments) {
      editData?.tenderInspectionCheckListAttachments?.forEach((image) => {
        handleDownloadFileChecklist(image?.fileId);
      });
    }
  }, [editData]);
  const handleDownloadFileChecklist = async (fileId) => {
    try {
      // Check if fileId is null, undefined, or an empty string
      if (!fileId) {
        return; // Exit early without calling the API
      }

      const imageUrl = await apiInstance.getFiles(`files/download/${fileId}`);

      setPreviewCheckListData((prevData) => [...prevData, imageUrl, fileId]);
    } catch (err) {
      toast.error(`Error fetching image: ${err.message}`);
    }
  };

  return (
    <>
      {tenderChecklist ? (
        <form onSubmit={creatCheckListSubmit(handleSubmitTenderChecklist)}>
          <GlassCard className='p-4 mb-4'>
            <Grid container spacing={2}>
              {/* Tender Selection */}
              <Grid item md={GridValue}>
                <FmSearchableSelect
                  name='tenderId'
                  control={creatCheckListControl}
                  apiUrl='tenders'
                  valueField='tenderId'
                  labelField={['tenderCode', 'tenderName']}
                  showField={['tenderCode', 'tenderName']}
                  disabled
                  defaultValue={{
                    tenderId: tenderChecklist?.tenderId,
                    tenderCode: tenderChecklist?.tenderCode,
                    tenderName: tenderChecklist?.tenderName,
                  }}
                  label={'Tenders'}
                />
              </Grid>

              {/* Tender Reference Number */}
              <Grid item md={GridValue}>
                <FmTextField
                  defaultValue={tenderChecklist?.tenderRefNo}
                  name='tenderRegNo'
                  label='Tender Reg No'
                  control={creatCheckListControl}
                  disabled
                />
              </Grid>

              <Grid item md={12}>
                {/* Client Details */}
                <GlassCard className='p-2' bgLight={true}>
                  <Grid container spacing={2}>
                    {clientDetails?.map(
                      (detail) =>
                        tenderChecklist[detail.key] && (
                          <Grid item md={GridValue} key={detail.key}>
                            <ViewField
                              label={detail?.title}
                              title={tenderChecklist[detail?.key]}
                            />
                          </Grid>
                        )
                    )}
                  </Grid>
                </GlassCard>
              </Grid>

              {/* Site Visit Details */}
              <Grid item md={GridValue}>
                <FmDatePicker
                  name='siteVisitDate'
                  label='Site Visit Date'
                  control={creatCheckListControl}
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmSearchableSelect
                  name='siteVisitDoneBy'
                  control={creatCheckListControl}
                  apiUrl='loginusers'
                  valueField='loginUserId'
                  defaultValue={{ loginUserName: editData?.siteVisitorName }}
                  labelField={['employeeCode', 'loginUserName']}
                  showField={['employeeCode', 'loginUserName']}
                  label={'Site Visited Done By'}
                />
              </Grid>

              {/* Location Details */}
              <Grid item md={GridValue}>
                <FmTextField
                  name='locationName'
                  label='Location Name'
                  control={creatCheckListControl}
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmTextField
                  name='buildingName'
                  label='Building Name'
                  control={creatCheckListControl}
                />
              </Grid>

              {/* Upload Modal */}
              <Grid item md={GridValue}>
                <UploadModalBox
                  onSubmitFiles={handleFileIds}
                  files={files}
                  setFiles={setFiles}
                  previewdData={previewCheckListData}
                />
              </Grid>

              {/* Additional Details */}
              <Grid item md={GridValue}>
                <FmTextField
                  name='existingManpowerDetail'
                  label='Existing Manpower Detail'
                  control={creatCheckListControl}
                  rows={3}
                  multiline
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmTextField
                  name='existServiceProvider'
                  label='Existing Contract Name'
                  control={creatCheckListControl}
                  rows={3}
                  multiline
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmTextField
                  name='remarks'
                  label='Remarks'
                  control={creatCheckListControl}
                  rows={3}
                  multiline
                />
              </Grid>
            </Grid>
          </GlassCard>

          {/* Scope Notes Section */}
          <GlassCardCollapse className='mb-2' title='Scope Notes'>
            <Grid container spacing={2}>
              {scopeNotes?.map((subject, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Card
                    sx={{
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <CardContent sx={{ flexGrow: 1 }}>
                      <Typography
                        variant='h6'
                        sx={{ fontSize: '1rem', fontWeight: 'bold' }}
                      >
                        {subject?.subject || 'No Subject'}
                      </Typography>
                      {previewdData[index] ? (
                        <img
                          src={previewdData[index]}
                          alt={`Preview for ${
                            subject?.subject || 'unknown subject'
                          }`}
                          style={{
                            width: '100%',
                            marginTop: '1rem',
                            borderRadius: '4px',
                          }}
                        />
                      ) : (
                        <Typography
                          variant='body2'
                          sx={{ color: 'gray', marginTop: '1rem' }}
                        >
                          No preview available
                        </Typography>
                      )}
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </GlassCardCollapse>

          {/* Action Buttons */}
          <Box className='mb-4'>
            <ActionButtons
              onSubmit={creatCheckListSubmit(handleSubmitTenderChecklist)}
              onCancel={() => navigate(-1)}
              onReset={reset}
              submitLoading={createLoading}
              cancelLoading={false}
              submitText={tenderChecklistDetailId ? 'Update' : 'Save'}
            />
          </Box>
        </form>
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%', // Full width of the container
          }}
        >
          <CircularProgress />
        </Box>
      )}

      {/* Tabs */}
      <GlassCard className='mb-2 shadow-none' bgLight>
        <Tabs value={tabValue} onChange={handleChange} centered>
          {navMenu?.map((item) => {
            return <Tab value={item?.id} label={item?.name} key={item?.name} />;
          })}
        </Tabs>
      </GlassCard>
      {tabValue === 1 && (
        <GlassCard className='overflow-hidden mt-4'>
          <FmMRTDataTable
            columns={columns}
            enableRowSelection={false}
            rows={rows || []}
            isCreatingRow={rowState?.isCreatingRow}
            rowCount={tableRecordCounts}
            editingRow={editingRowId}
            pagination={pagination}
            setSearchKeyword={setSearchKeyword}
            sorting={sorting}
            columnFilters={columnFilters}
            globalFilter={globalFilter}
            setPagination={setPagination}
            setSorting={setSorting}
            setColumnFilters={setColumnFilters}
            setGlobalFilter={setGlobalFilter}
            isLoading={isLoading}
            searchKeyword={searchKeyword}
            fetchData={fetchData}
            handleExport={handleExport}
            handleImport={() => setOpenUpload(true)}
            fetchData={fetchData}
            handleRowDoubleClick={(row) => {
              handleEdit(row?.original);
              setEditingRowIndex(row?.index);
            }}
            muiTableContainerProps={{
              sx: {
                maxHeight: '400px',
              },
            }}
          />
          <MuiDialogOne
            title='Upload Sheet'
            open={openUpload}
            onClose={handleClose}
          >
            <input
              type='file'
              onChange={handleUploadSheet}
              id='file'
              accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
            />
          </MuiDialogOne>
        </GlassCard>
      )}

      {tabValue === 2 && (
        <ViewTenderBuildingImages
          tenderChecklistDetailId={tenderChecklistDetailId}
        />
      )}
      {tabValue === 3 && (
        <ViewTenderSiteVisitImages
          tenderChecklistDetailId={tenderChecklistDetailId}
        />
      )}
    </>
  );
};

export default ViewTenderBasedCheckListDetail;
