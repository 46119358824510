import React, { useState, useEffect, useRef } from 'react';
import FileManager from './../../lib/FileManager/';
import { Button, Box, Fade } from '@mui/material';
import Icons from '../../utils/Icon';
import { store } from '../../store/store.ts';
import Crud_Service from '../../apis/CrudService.jsx';

const FileManagerOne = ({ id }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const crud = new Crud_Service();
  const isMountRef = useRef(false);

  // Function to convert the file structure from API response to the required format
  function convertFileStructure(input, updatedAt = new Date().toISOString()) {
    const result = [];

    function traverse(node, currentPath) {
      const isDirectory = node.typeName === 'FOLDER';

      // Construct the item
      const item = {
        name: isDirectory ? node.name : node.nameWithExtension || node.name, // Use nameWithExtension for files, fallback to name
        isDirectory: isDirectory,
        path: isDirectory ? currentPath : `${currentPath}`, // Do not append name again for files
        updatedAt: updatedAt,
      };

      // Add size property for files
      if (!isDirectory && node.fileType) {
        item.size = 2048; // Assign a default size or compute dynamically if needed
      }

      result.push(item);

      // Traverse child structures if available
      if (
        isDirectory &&
        node.childFileStructures &&
        Array.isArray(node.childFileStructures)
      ) {
        for (const child of node.childFileStructures) {
          traverse(
            child,
            `${currentPath}/${child.nameWithExtension || child.name}`
          );
        }
      }
    }

    traverse(input, `/${input.name}`);
    return result;
  }

  // Fetch files from API
  const fetchFiles = async () => {
    setLoading(true);

    try {
      await crud.getAll(`/employees/${id}/file-structures`, '', (err, res) => {
        if (res) {
          console.log('files', res.data);
          const flatStructure = convertFileStructure(
            res.data,
            '2024-09-09T10:30:00Z'
          );
          setFiles(flatStructure);
        }
        setLoading(false);
      });
    } catch (error) {
      console.error('Error fetching files:', error);
    }
  };

  useEffect(() => {
    if (isMountRef.current) return;
    isMountRef.current = true;
  }, [id]);

  console.log(files);

  return (
    <>
      <Button
        variant='outlined'
        onClick={() => {
          setOpen(true);
          fetchFiles();
        }}
        color='primary'
      >
        Employee Files
      </Button>

      <Box
        className={`fixed top-0 left-0 z-20 w-full py-5 h-full overflow-auto  ${
          open ? 'd-block' : 'hidden'
        }`}
        sx={{ bgcolor: 'rgb(0 0 0 / 45%)' }}
      >
        <Fade in={open}>
          <Box className='px-5'>
            <Box className='overflow-auto rounded-lg shadow-xl'>
              <Box
                className='flex justify-between items-center p-3'
                bgcolor={'background.white'}
                sx={{ borderBottom: '1px solid', borderColor: 'border.main' }}
              >
                <Box className='font-bold text-sm'>File Manager</Box>
                <Box
                  className='cursor-pointer text-xl hover:bg-slate-100'
                  onClick={() => {
                    setOpen(false);
                    setFiles([]);
                  }}
                >
                  {Icons.clear}
                </Box>
              </Box>
              <Box
                sx={{
                  '& .files.grid': {
                    display: 'flex !important',
                  },
                }}
              >
                <FileManager
                  files={files}
                  height={'88vh'}
                  isLoading={loading}
                  filePreviewPath={`${process.env.REACT_APP_BASE}/filestructures/`}
                />
              </Box>
            </Box>
          </Box>
        </Fade>
      </Box>
    </>
  );
};

export default FileManagerOne;
