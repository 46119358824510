import { useState } from 'react';
import { Icon } from '@iconify/react/dist/iconify.js';
import LayoutToggler from './LayoutToggler';
import { useFileNavigation } from '../contexts/FileNavigationContext';
import { useSelection } from '../contexts/SelectionContext';
import { useClipBoard } from '../contexts/ClipboardContext';
import { useLayout } from '../contexts/LayoutContext';
import { validateApiCallback } from '../utils/validateApiCallback';
import './Toolbar.scss';
import { Box } from '@mui/material';
import FileManagerIcons from '../FileManagerIcons';

const Toolbar = ({
  allowCreateFolder = true,
  allowUploadFile = true,
  onLayoutChange,
  onRefresh,
  triggerAction,
}) => {
  const [showToggleViewMenu, setShowToggleViewMenu] = useState(false);
  const { currentFolder } = useFileNavigation();
  const { selectedFiles, setSelectedFiles, handleDownload } = useSelection();
  const { clipBoard, setClipBoard, handleCutCopy, handlePasting } =
    useClipBoard();
  const { activeLayout } = useLayout();

  // Toolbar Items
  const toolbarLeftItems = [
    // {
    //   icon: FileManagerIcons.add,
    //   text: 'New folder',
    //   permission: allowCreateFolder,
    //   onClick: () => triggerAction.show('createFolder'),
    // },
    // {
    //   icon: FileManagerIcons.upload,
    //   text: 'Upload',
    //   permission: allowUploadFile,
    //   onClick: () => triggerAction.show('uploadFile'),
    // },
    // {
    //   icon: FileManagerIcons.paste,
    //   text: 'Paste',
    //   permission: !!clipBoard,
    //   onClick: handleFilePasting,
    // },
  ];

  const toolbarRightItems = [
    {
      icon:
        activeLayout === 'grid' ? FileManagerIcons.grid : FileManagerIcons.list,
      title: 'Change View',
      onClick: () => setShowToggleViewMenu((prev) => !prev),
    },
    {
      icon: FileManagerIcons.reload,
      title: 'Refresh',
      onClick: () => {
        validateApiCallback(onRefresh, 'onRefresh');
        setClipBoard(null);
      },
    },
  ];

  function handleFilePasting() {
    handlePasting(currentFolder);
  }

  const handleDownloadItems = () => {
    handleDownload();
    setSelectedFiles([]);
  };

  // Selected File/Folder Actions
  if (selectedFiles.length > 0) {
    return (
      <Box
        className='toolbar file-selected'
        sx={{
          borderBottom: '1px solid',
          borderColor: 'border.main',
        }}
      >
        <div className='file-action-container'>
          <div>
            {/* <button
              className='item-action file-action'
              onClick={() => handleCutCopy(true)}
            >
              {FileManagerIcons.cut}
              <span>Cut</span>
            </button>
            <button
              className='item-action file-action'
              onClick={() => handleCutCopy(false)}
            >
              {FileManagerIcons.copy}
              <span>Copy</span>
            </button> */}
            {/* {clipBoard?.files?.length > 0 && (
              <button
                className='item-action file-action'
                onClick={handleFilePasting}
                 disabled={!clipBoard}
              >
                {FileManagerIcons.paste}
                <span>Paste</span>
              </button>
            )} */}
            {/* {selectedFiles.length === 1 && (
              <button
                className='item-action file-action'
                onClick={() => triggerAction.show('rename')}
              >
                {FileManagerIcons.rename}
                <span>Rename</span>
              </button>
            )} */}
            {!selectedFiles.isDirectory && (
              <button
                className='item-action file-action'
                onClick={handleDownloadItems}
              >
                {FileManagerIcons.download}
                <span>Download</span>
              </button>
            )}
            {/* <button
              className='item-action file-action'
              onClick={() => triggerAction.show('delete')}
            >
              {FileManagerIcons.delete}
              <span>Delete</span>
            </button> */}
          </div>
          <button
            className='item-action file-action'
            title='Clear selection'
            onClick={() => setSelectedFiles([])}
          >
            <span>
              {selectedFiles.length} item{selectedFiles.length > 1 && 's'}{' '}
              selected
            </span>
            {FileManagerIcons.close}
          </button>
        </div>
      </Box>
    );
  }
  //

  return (
    <Box
      className='toolbar'
      sx={{
        borderBottom: '1px solid',
        borderColor: 'border.main',
        //bgcolor: 'background.light',
      }}
    >
      <div className='fm-toolbar'>
        <div>
          {toolbarLeftItems
            .filter((item) => item.permission)
            .map((item, index) => (
              <button
                className='item-action'
                key={index}
                onClick={item.onClick}
              >
                {item.icon}
                <span>{item.text}</span>
              </button>
            ))}
        </div>
        <div>
          {toolbarRightItems.map((item, index) => (
            <div key={index} className='toolbar-left-items'>
              <button
                className='item-action icon-only'
                title={item.title}
                onClick={item.onClick}
              >
                {item.icon}
              </button>
              {index !== toolbarRightItems.length - 1 && (
                <div className='item-separator'></div>
              )}
            </div>
          ))}

          {showToggleViewMenu && (
            <LayoutToggler
              setShowToggleViewMenu={setShowToggleViewMenu}
              onLayoutChange={onLayoutChange}
            />
          )}
        </div>
      </div>
    </Box>
  );
};

export default Toolbar;
