import React from 'react';
import { Box, Button } from '@mui/material';

const EnquiryButtonShape = ({ name, icon, count, active, onClick }) => {
  return (
    <Box
      className={`flex gap-1 items-center relative`}
      sx={{
        bgcolor: active ? 'background.white' : 'none',
        padding: '2px 6px',
        borderRadius: '5px',
        fontWeight: active ? 600 : 500,
        color: active ? 'text.main' : 'text.main',
        opacity: active ? 1 : 0.7,
        //border: '1px solid',
        //borderColor: active ? 'primary.main' : 'border.main',
        boxShadow:
          active && '0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px -1px rgba(0,0,0,.1)',
        bgcolor: active ? 'background.white' : '',
        '&:hover': {
          bgcolor: active ? 'background.white' : 'background.light',
          opacity: 1,
        },
      }}
      component={Button}
      type='button'
      onClick={onClick}
    >
      <Box className='text-xl'>{icon}</Box>
      <Box>{name}</Box>
      <Box className='count'>({count})</Box>
    </Box>
  );
};

export default EnquiryButtonShape;
