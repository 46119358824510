import { yupResolver } from '@hookform/resolvers/yup';
import { Icon } from '@iconify/react';
import { Box, Grid } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Crud_Service from '../../../apis/CrudService.jsx';
import ActionButtons from '../../../components/_form/ActionButtons.tsx';
import { StatusBadge } from '../../../components/_form/FormElements.jsx';
import TableActionButtons from '../../../components/_form/TableActionButtons.jsx';
import TableSearch from '../../../components/_form/TableSearch.jsx';
import FmAutoComplete from '../../../components/_mui/FmAutoComplete.tsx';
import FmSearchableSelect from '../../../components/_mui/FmSearchableSelect.tsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import MuiDialogOne from '../../../components/_mui/MuiDialogOne.jsx';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import DataTable from '../../../components/DataTable.tsx';
import CommonView from '../../../components/helpers/CommonView.jsx';
import DynamicViewFields from '../../../components/helpers/DynamicViewFields.jsx';
import useCommonFetchApi from '../../../components/helpers/useCommonFetchApi.tsx';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import useTableLogic from '../../../components/helpers/UseTableLogic.tsx';
import CommonLoader from '../../../components/page/CommonLoader.jsx';
import PageHeader from '../../../components/PageHeader.jsx';
import { CreateButton } from '../../../components/small/Buttons.jsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { DriverMastervalidationSchema } from '../../../utils/CommonvalidationSchemas.tsx';
import { statusOptions } from '../../../utils/CommonVariables.tsx';
import { findNavByName } from '../../../utils/navUtils.ts';

const Drivers = () => {
  const { t } = useTranslation();
  const [openCreate, setOpenCreate] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const { handleSubmit, setValue, reset, control } = useForm({
    resolver: yupResolver(DriverMastervalidationSchema),
  });
  const {
    createLoading,
    startCreateLoading,
    stopCreateLoading,
    editLoading,
    startEditLoading,
    stopEditLoading,
  } = useLoader();
  const {
    rows,
    pageSize,
    pageNumber,
    setSearchKeyword,
    isLoading,
    tableRecordCounts,
    handlePaginationModelChange,
    handleSortModelChange,
    searchKeyword,
    setRows,
    fetchData,
  } = useTableLogic('drivers');
  const crud = new Crud_Service();
  const [viewDetails, setViewdetails] = useState(false);
  const handleViewClick = async (props) => {
    setViewdetails(props);
  };

  const { data: loginusers } = useCommonFetchApi('loginusers') || {
    data: [],
  };

  useEffect(() => {
    document.title = `Admin-Drivers`;
  }, []);

  //create the Card Type
  const handleSubmitDrivers = async (values) => {
    startCreateLoading();
    await crud.create('drivers', values, (err, res) => {

      if (res?.status === 201) {
        toast.success('Driver Created Successfully');
        setOpenCreate(false);
        stopCreateLoading();
        fetchData();
      } else {
        stopCreateLoading();
      }
    });
  };

  const handleCreateClickOpen = () => {
    reset();
    setOpenCreate(true);
  };
  const handleCreateClose = () => {
    reset();
    setOpenCreate(false);
  };

  const counters = [
    {
      name: 'All',
      count: tableRecordCounts?.total || 0,
    },
    {
      name: 'Active',
      count: tableRecordCounts?.active || 0,
    },
    {
      name: 'Deactivated',
      count: tableRecordCounts?.inActive || 0,
    },
  ];

  //handle Delete Record
  const handleDeleteClick = async (view) => {
    await crud.remove('drivers', view?.driverId, (err, res) => {
      if (res?.status === 204) {
        toast.success('Driver Deleted Successfully');
        fetchData();
      }
    });
  };

  //SetValues to the Form
  const handleEditClick = async (view) => {
    setOpenEdit(true);
    setLoading(true);
    await crud.getSingle('drivers', view?.driverId, (err, res) => {
      if (res?.status === 200) {
        Object.entries({
          ...res?.data,
        }).forEach(([key, value]) => {
          setValue(key, value);
        });
        setLoading(false);
      }
    });
  };

  //handle Update
  const handleEditVehicleTypes = async (values) => {
    startEditLoading();
    await crud.update('drivers', values?.driverId, values, (err, res) => {
      if (res?.status === 200) {
        toast.success('Driver Updated Successfully');
        setOpenEdit(false);
        stopEditLoading();
        fetchData();
      } else {
        stopEditLoading();
        setOpenEdit(true);
      }
    });
  };

  const ActionData = [
    {
      name: 'View',
      icon: <Icon icon='solar:eye-linear' />,
      onClick: (props) => handleViewClick(props),
    },
    {
      name: 'Edit',
      icon: <Icon icon='solar:pen-new-round-linear' />,
      onClick: (props) => handleEditClick(props),
    },
    {
      name: 'Delete',
      icon: <Icon icon='solar:trash-bin-2-linear' />,
      onClick: (props) => handleDeleteClick(props),
      danger: true,
    },
  ];

  const columns = [
    {
      field: 'action',
      headerName: '',
      width: 140,
      sortable: false,
      disableClickEventBubbling: false,

      renderCell: (params) => {
        return (
          <TableActionButtons
            Actions={ActionData?.map((action) => ({
              ...action,
              onClick: () => action.onClick(params.row),
            }))}
          />
        );
      },
    },
    {
      field: 'driverCode',
      headerName: 'Driver Code',
      flex: 1,
    },
    {
      field: 'driverName',
      headerName: 'Driver Name',
      flex: 1,
    },
    {
      field: 'licenseNumber',
      headerName: 'License Number',
      flex: 1,
    },
    {
      field: 'loginUserName',
      headerName: 'Login User',
      flex: 1,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderCell: (params) => {
        return (
          <StatusBadge
            title={params.value === 2 ? 'Active' : 'Inactive'}
            type={params.value === 2 ? 'green' : 'red'}
          />
        );
      },
    },
  ];
  const excludeKeys = [
    'driverId',
    'loginUserId',
    'driverContactNumber',
    'status',
  ];
  const menu = useSelector((state) => state?.menu?.items);

  const SubMenu = useMemo(() => {
    return menu?.map((item) => item?.menus?.flat()).flat() || [];
  }, [menu]);

  const result = useMemo(
    () => findNavByName(SubMenu, 'Admin', 'All Masters'),
    [SubMenu]
  );

  return (
    <DashboardLayout
      title='Drivers'
      actionButtons={
        <>
          <PageHeader counters={counters} />
        </>
      }
      menu={result}
      hasSubmenu
    >
      <GlassCard>
        <Box sx={{ height: 'calc(100vh - 200px)' }}>
          <DataTable
            rows={rows}
            columns={columns}
            loading={isLoading}
            getRowClassName={(params) =>
              `${params.row.status === 0 && 'bg-red-50'}`
            }
            sortingMode='server'
            paginationMode='server'
            onPaginationModelChange={(model) =>
              handlePaginationModelChange(model)
            }
            onSortModelChange={(model) => handleSortModelChange(model)}
            page={pageNumber - 1}
            pageSize={pageSize}
            rowCount={tableRecordCounts?.total}
            slots={{
              toolbar: () => (
                <Box
                  className='p-2 w-full flex justify-between items-center'
                  sx={{
                    borderBottom: '1px solid',
                    borderColor: 'border.main',
                  }}
                >
                  <Box sx={{ maxWidth: '250px' }}>
                    <TableSearch
                      placeholder='Search'
                      fullWidth
                      setSearchKeyword={setSearchKeyword}
                      searchValue={searchKeyword}
                    />
                  </Box>
                  <CreateButton name='New' onClick={handleCreateClickOpen} />
                </Box>
              ),
            }}
          />
        </Box>
      </GlassCard>

      {/* create */}

      <MuiDialogOne
        title='Create New'
        open={openCreate}
        onClose={handleCreateClose}
      >
        <form onSubmit={handleSubmit(handleSubmitDrivers)}>
          <Grid container spacing={3}>
            <Grid item md={12}>
              <FmSearchableSelect
                name='loginUserId'
                control={control}
                apiUrl='loginusers'
                valueField='loginUserId'
                labelField={['employeeCode', 'loginUserName']}
                showField={['employeeCode', 'loginUserName']}
                pageSize={50}
                label='Prepared By'
              />
            </Grid>
            <Grid item md={12}>
              <FmTextField
                name='driverName'
                control={control}
                label='Driver Name'
              />
            </Grid>
            <Grid item md={12}>
              <FmTextField
                name='licenseNumber'
                control={control}
                label='License Number'
              />
            </Grid>

            <Grid item md={12}>
              <ActionButtons
                onSubmit={handleSubmit(handleSubmitDrivers)}
                onCancel={handleCreateClose}
                onReset={reset}
                submitLoading={createLoading}
                cancelLoading={false}
                submitText='Create'
              />
            </Grid>
          </Grid>
        </form>
      </MuiDialogOne>

      {/* create */}

      {/* view */}
      <MuiDialogOne
        title='View Details'
        open={viewDetails}
        onClose={() => setViewdetails(!viewDetails)}
      >
        {viewDetails && viewDetails?.driverId && (
          <CommonView
            url='drivers'
            id={viewDetails?.driverId}
            excludeKeys={excludeKeys}
            renderFields={(data, fields) => (
              <DynamicViewFields data={data} fields={fields} />
            )}
          />
        )}
      </MuiDialogOne>

      {/* edit */}
      <MuiDialogOne
        title='Update'
        open={openEdit}
        onClose={() => setOpenEdit(false)}
      >
        {loading ? (
          <CommonLoader />
        ) : (
          <form onSubmit={handleSubmit(handleEditVehicleTypes)}>
            <Grid container spacing={3}>
              <Grid item md={12}>
                <FmAutoComplete
                  name='loginUserId'
                  control={control}
                  options={loginusers}
                  label='Login User'
                  displayField='loginUserName'
                  optionFields={['loginUserName']}
                  valueKey='loginUserId'
                />
              </Grid>
              <Grid item md={12}>
                <FmTextField
                  name='driverName'
                  control={control}
                  label='Driver Name'
                  rules={{ required: 'Driver Name is required' }}
                />
              </Grid>
              <Grid item md={12}>
                <FmTextField
                  name='licenseNumber'
                  control={control}
                  label='License Number'
                  rules={{ required: 'License Number is required' }}
                />
              </Grid>
              <Grid item md={12}>
                <FmAutoComplete
                  name='status'
                  control={control}
                  options={statusOptions}
                  label='Status'
                  displayField='statusName'
                  optionFields={['statusName']}
                  valueKey='statusId'
                />
              </Grid>
              <Grid item md={12}>
                <ActionButtons
                  onSubmit={handleSubmit(handleEditVehicleTypes)}
                  onCancel={() => setOpenEdit(false)}
                  onReset={reset}
                  submitLoading={editLoading}
                  cancelLoading={false}
                  submitText='Update'
                />
              </Grid>
            </Grid>
          </form>
        )}
      </MuiDialogOne>
      {/* edit */}
    </DashboardLayout>
  );
};

export default Drivers;
