/* eslint-disable */
import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import { Box, Grid, TextField, Autocomplete } from '@mui/material';
import { ActionButtons } from '../../../components/_form';
import { useNavigate, useParams } from 'react-router-dom';
import Crud_Service from '../../../apis/CrudService';
import CommonLoader from '../../../components/page/CommonLoader';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import apiInstance from '../../../apis/ApiService';
import Containers from '../../../components/helpers/Containers';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { EstimationMEPSchema } from '../../../utils/CommonvalidationSchemas.tsx';
import { toast } from 'react-toastify';
import useCommonFetchApi from '../../../components/helpers/useCommonFetchApi.tsx';
import FmAutoComplete from '../../../components/_mui/FmAutoComplete.tsx';

const EditEstimationSheetMEP = () => {
  const navigate = useNavigate();
  const crud = new Crud_Service();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const {
    setValue,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(EstimationMEPSchema),
    mode: 'onChange',
  });
  const { editLoading, startEditLoading, stopEditLoading } = useLoader();
  const [employees, setEmployees] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    getEstimationAMC();
    getEmployees('');
  }, []);

  const getEstimationAMC = async () => {
    setLoading(true);
    await crud.getSingle('estimationmepsheets', id, (_err, res) => {
      if (res?.status === 200) {
        Object.entries({
          ...res?.data,
        }).forEach(([key, value]) => {
          setValue(key, value);
        });
        setLoading(false);
      } else {
      }
    });
  };

  const { data: projects } = useCommonFetchApi('projects');
  const { data: clients } = useCommonFetchApi('clients');
  const { data: tenders } = useCommonFetchApi('tenders');
  const { data: buildings } = useCommonFetchApi('buildings');
  const { data: loginUsers } = useCommonFetchApi('loginusers');
  const { data: estimationStatuses } = useCommonFetchApi(
    'estimationmepsheets/estimation-statuses'
  );

  const getEmployees = async (employeeSearchValue) => {
    await apiInstance.getAllEmployee(employeeSearchValue).then((res) => {
      if (res?.status === 200) {
        setEmployees(res?.data?.data);
      } else {
      }
    });
  };

  const handleEditTenders = async (values) => {
    startEditLoading();
    const combinedData = {
      ...values,
      dateOfEnquiry: dayjs(values?.dateOfEnquiry).format('YYYY-MM-DD'),
      contractPeriod: Number(values?.contractPeriod),
      otherPercent: Number(values?.otherPercent),
      discountOverAllPercent: Number(values?.discountOverAllPercent),
      projectId: null,
      status: Number(values?.status),
    };

    await crud.update('estimationmepsheets', id, combinedData, (err, res) => {
      if (err) {
        stopEditLoading();

        return;
      }
      if (res?.status === 200) {
        stopEditLoading();
        toast.success('Estimation Sheet MEP Updated Successfully');
        navigate(-1);
      } else {
        stopEditLoading();
      }
    });
  };

  return (
    <DashboardLayout title='Edit Estimation Sheet MEP'>
      <Box className='mt-10'>
        <Containers width='1000'>
          {loading ? (
            <CommonLoader />
          ) : (
            <GlassCard className='p-5'>
              <form onSubmit={handleSubmit(handleEditTenders)}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Grid container spacing={2}>
                    <Grid item md={6}>
                      <Controller
                        name='projectId'
                        control={control}
                        render={({ field }) => (
                          <Autocomplete
                            {...field}
                            options={projects}
                            getOptionLabel={(option) =>
                              option?.projectName || ''
                            }
                            value={
                              projects?.find(
                                (emp) => emp?.projectId === field?.value
                              ) || null
                            }
                            onChange={(_event, value) =>
                              field.onChange(value?.projectId || null)
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label='Projects'
                                variant='filled'
                                fullWidth
                                required
                                error={Boolean(errors.projectId)} // Set error prop
                                helperText={
                                  errors.projectId
                                    ? errors.projectId.message
                                    : ''
                                }
                              />
                            )}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <Controller
                        name='clientId'
                        control={control}
                        render={({ field }) => (
                          <Autocomplete
                            {...field}
                            options={clients}
                            getOptionLabel={(option) =>
                              option?.clientName || ''
                            }
                            value={
                              clients?.find(
                                (emp) => emp?.clientId === field?.value
                              ) || null
                            }
                            onChange={(_event, value) =>
                              field.onChange(value?.clientId || null)
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label='Clients'
                                variant='filled'
                                fullWidth
                                required
                                error={Boolean(errors.clientId)} // Set error prop
                                helperText={
                                  errors.clientId ? errors.clientId.message : ''
                                }
                              />
                            )}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <Controller
                        name='tenderId'
                        control={control}
                        render={({ field }) => (
                          <Autocomplete
                            {...field}
                            options={tenders}
                            getOptionLabel={(option) =>
                              option?.tenderName || ''
                            }
                            value={
                              tenders?.find(
                                (emp) => emp?.tenderId === field?.value
                              ) || null
                            }
                            onChange={(_event, value) =>
                              field.onChange(value?.tenderId || null)
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label='Tenders'
                                variant='filled'
                                fullWidth
                                required
                                error={Boolean(errors.tenderId)} // Set error prop
                                helperText={
                                  errors.tenderId ? errors.tenderId.message : ''
                                }
                              />
                            )}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <Controller
                        name='buildingId'
                        control={control}
                        render={({ field }) => (
                          <Autocomplete
                            {...field}
                            options={buildings}
                            getOptionLabel={(option) =>
                              option?.buildingName || ''
                            }
                            value={
                              buildings?.find(
                                (emp) => emp?.buildingId === field?.value
                              ) || null
                            }
                            onChange={(_event, value) =>
                              field.onChange(value?.buildingId || null)
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label='Buildings'
                                variant='filled'
                                fullWidth
                                required
                                error={Boolean(errors.buildingId)} // Set error prop
                                helperText={
                                  errors.buildingId
                                    ? errors.buildingId.message
                                    : ''
                                }
                              />
                            )}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item md={3}>
                      <Controller
                        name='dateOfEnquiry'
                        control={control}
                        defaultValue={null}
                        render={({ field: { onChange, value } }) => (
                          <DatePicker
                            label='Date Of Enquiry'
                            value={value ? dayjs(value) : null}
                            format='DD/MM/YYYY'
                            onChange={(newValue) => {
                              onChange(newValue);
                            }}
                            sx={{ width: '100%' }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item md={3}>
                      <FmTextField
                        name='contractPeriod'
                        control={control}
                        label='Contract Period'
                        required
                      />
                    </Grid>
                    <Grid item md={3}>
                      <FmTextField
                        name='otherPercent'
                        control={control}
                        label='Other Percent(%)'
                        required
                        pattern='Percentage'
                      />
                    </Grid>
                    <Grid item md={3}>
                      <FmTextField
                        name='discountOverAllPercent'
                        control={control}
                        label='Discount Percent(%)'
                        required
                        pattern="Percentage"
                      />
                    </Grid>
                    <Grid item md={6}>
                      <FmTextField
                        name='existContract'
                        control={control}
                        label='Exist Contract'
                        required
                      />
                    </Grid>

                    <Grid item md={6}>
                      <Controller
                        name='siteVisitDoneBy'
                        control={control}
                        render={({ field }) => (
                          <Autocomplete
                            {...field}
                            options={employees}
                            getOptionLabel={(option) => option?.firstName || ''}
                            value={
                              employees?.find(
                                (emp) => emp?.employeeId === field?.value
                              ) || null
                            }
                            onChange={(_event, value) =>
                              field.onChange(value?.employeeId || null)
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label='Site Visit Done BY'
                                variant='filled'
                                fullWidth
                                required
                                error={Boolean(errors.siteVisitDoneBy)} // Set error prop
                                helperText={
                                  errors.siteVisitDoneBy
                                    ? errors.siteVisitDoneBy.message
                                    : ''
                                }
                              />
                            )}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <Controller
                        name='preparedBy'
                        control={control}
                        render={({ field }) => (
                          <Autocomplete
                            {...field}
                            options={loginUsers}
                            getOptionLabel={(option) =>
                              option?.loginUserName || ''
                            }
                            value={
                              loginUsers?.find(
                                (emp) => emp?.loginUserId === field?.value
                              ) || null
                            }
                            onChange={(_event, value) =>
                              field.onChange(value?.loginUserId || null)
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label='Prepared By'
                                variant='filled'
                                fullWidth
                                required
                                error={Boolean(errors.preparedBy)}
                                helperText={
                                  errors.preparedBy
                                    ? errors.preparedBy.message
                                    : ''
                                }
                              />
                            )}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <FmAutoComplete
                        name='estimationStatusId'
                        control={control}
                        options={estimationStatuses}
                        label='Estimation Status'
                        displayField='estimationStatusName'
                        optionFields={['estimationStatusName']}
                        valueKey='estimationStatusId'
                        required
                      />
                    </Grid>
                  </Grid>
                  <ActionButtons
                    onSubmit={handleSubmit(handleEditTenders)}
                    onCancel={() => navigate(-1)}
                    onReset={reset}
                    submitLoading={editLoading}
                    cancelLoading={false}
                    className='mt-10'
                    submitText='Update'
                  />
                </LocalizationProvider>
              </form>
            </GlassCard>
          )}
        </Containers>
      </Box>
    </DashboardLayout>
  );
};

export default EditEstimationSheetMEP;
