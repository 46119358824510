import React from 'react';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import { useNavigate, useParams } from 'react-router-dom';
import CommonView from '../../../../components/helpers/CommonView.jsx';
import DynamicViewFields from '../../../../components/helpers/DynamicViewFields.jsx';
import { CreateButton } from '../../../../components/small/Buttons.jsx';
import ActionButtons from '../../../../components/_form/ActionButtons.tsx';

const ViewEmployeeWiseMIPCalculator = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const excludeKeys = [
    'companyMedicalInsuranceAgeBandId',
    'costCenterId',
    'employeeId',
    'employeeMedicalInsurancePolicyId',
    'regionId',
    'createdDate',
    'updatedDate',
    'statusName',
    'minimumAge',
    'maximumAge',
    'employeeCode',
    'status',
  ];

  return (
    <DashboardLayout
      title='View Employee Wise Policy'
      actionButtons={
        <CreateButton
          name='Create'
          onClick={() =>
            navigate(
              '/admin/company-medical-insurance-policy/employee-wise-premium-calculator/create'
            )
          }
        />
      }
    >
      <GlassCard className='p-5'>
        <CommonView
          url='employeemedicalinsurancepolicies'
          id={id}
          excludeKeys={excludeKeys}
          renderFields={(data, fields) => (
            <DynamicViewFields data={data} fields={fields} />
          )}
        />
        <ActionButtons
          cancelLoading={false}
          cancelText='Cancel'
          submitText='Edit'
          onSubmit={() =>
            navigate(
              `/admin/company-medical-insurance-policy/employee-wise-premium-calculator/edit/${id}`
            )
          }
          onCancel={() =>
            navigate(
              '/admin/company-medical-insurance-policy/employee-wise-premium-calculator'
            )
          }
        />
      </GlassCard>
    </DashboardLayout>
  );
};

export default ViewEmployeeWiseMIPCalculator;
