/* eslint-disable */
import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import {
  Box,
  Grid,
  Button,
  TextField,
  Autocomplete,
  FormControlLabel,
  Checkbox,
  Stack,
  CircularProgress,
} from '@mui/material';
import ActionButtons from '../../../components/_form/ActionButtons.tsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { Icon } from '@iconify/react';
import MuiDialogOne from '../../../components/_mui/MuiDialogOne.jsx';
import SortableTree from '@nosferatu500/react-sortable-tree';
import '@nosferatu500/react-sortable-tree/style.css';
import {
  DragAndDropBox,
  DragCards,
} from '../../../components/_form/DragAndDropBox.jsx';
import Crud_Service from '../../../apis/CrudService';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import TableSearch from '../../../components/_form/TableSearch.jsx';
import { CreateButton } from '../../../components/small/Buttons.jsx';
import SortableTreeBoxStyle from '../../../components/page/SortableTreeBoxStyle.tsx';

const initialCompletedTasks = [];

const ProposalLineItemsMasterCreate = () => {
  const [existingDocuments, setExistingDocuments] = useState([]);
  const [selectedDocumentTypes, setSelectedDocumentTypes] = useState([]);
  const [createDivision, setCreateDivision] = useState(false);
  const [createSubDivision, setCreateSubDivision] = useState(false);
  const [pageSize] = useState(100);
  const [pageNumber] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const crud = new Crud_Service();
  const [documents, setDocuments] = useState([]);
  const [documentTypeId] = useState(2);
  const [localLoader, setLocalLoader] = useState(true);
  const dispatch = useDispatch();
  const documentTypeCategoryId = 4;
  const [tenders, setTenders] = useState([]);
  const [selectedTender, setSelectedTender] = useState(null);
  const { handleSubmit, control, reset } = useForm();
  const [searchKeyword, setSearchKeyword] = useState('');
  const [doc, setDoc] = useState({});
  const navigate = useNavigate();
  const [selectedDocumentIds, setSelectedDocumentIds] = useState(new Set());

  useEffect(() => {
    fetchData();
    fetchEnquiry();
  }, [searchKeyword]);

  const toggleLoading = (loading) => {
    setIsLoading(loading);
  };

  const handleCheckboxChange = (event, documentId) => {
    setSelectedDocumentIds((prevIds) => {
      const newIds = new Set(prevIds);
      if (event.target.checked) {
        newIds.add(documentId);
      } else {
        newIds.delete(documentId);
      }
      return newIds;
    });
  };

  const createDocumentType = async (values) => {
    const data = {
      documentTypeName: values.documentTypeName,
      documentTypeCategoryId,
      tagId: 2,
    };
    await crud.create('documenttypes', data, (err, res) => {
      if (err) {
        toast.error('Error creating document type', err);
      }
      if (res?.status === 201) {
        fetchData();
        setCreateDivision(false);
      }
    });
  };

  const fetchEnquiry = async () => {
    await crud.getAll('tenders', { pageSize, pageNumber }, (err, res) => {
      if (err) {
        toast.error('Error fetching Tenders:', err);
      }
      if (res?.status === 200) {
        setTenders(res.data.data);
        setCreateDivision(false);
      }
    });
  };

  const fetchData = async () => {
    await crud.getAll(
      'documenttypes',
      {
        pageSize,
        pageNumber,
        documentTypeId,
        documentTypeCategoryId,
        searchKeyword: searchKeyword,
      },
      (err, res) => {
        if (err) {
          toast.error('Error fetching data', err);
          return;
        }
        if (res?.status === 200) {
          setExistingDocuments(res.data.data);
          toggleLoading(false);
        }
      }
    );
  };

  const handleTenderChange = (event) => {
    setSelectedTender(event);
  };

  const fetchDocuments = async (id) => {
    await crud.getAll(
      'documents',
      { pageSize, pageNumber, documentTypeId: id },
      (err, res) => {
        if (err) {
          toast.error('Error getting document', err);
          return;
        }
        if (res?.status === 200) {
          const data = res.data.data.map((row, index) => ({
            ...row,
            id: index + 1,
            selected: false,
          }));
          setDocuments(data);
          setLocalLoader(false);
        }
      }
    );
  };

  const handleAddTask = (doc) => {
    setExistingDocuments((prev) =>
      prev.filter((task) => task.documentTypeId !== doc.documentTypeId)
    );
    setSelectedDocumentTypes((prev) => [...prev, { ...doc, children: [] }]);
  };

  const handleDeleteTask = (doc) => {
    setSelectedDocumentTypes((prev) =>
      prev.filter((task) => task.documentTypeId !== doc.documentTypeId)
    );
    setExistingDocuments((prev) => [...prev, doc]);
  };

  const handleTreeChange = (newTreeData) => {
    setSelectedDocumentTypes(newTreeData);
  };

  const handleDocSubmit = async () => {
    const data = {
      tenderId: selectedTender,
      tenderProposalJson: {
        documents: selectedDocumentTypes,
      },
    };
    await crud.create('tenderproposals', data, (err, res) => {
      if (err) {
        toast.error(err);
        return;
      }
      if (res.data) {
        toast.success('Proposal Created Successfully');
        navigate(-1);
      }
    });
  };

  const handleDocumentTypeChange = (id) => {
    setLocalLoader(true);
    fetchDocuments(id);
  };

  const addDocumentsToTree = (documents, nodeId, tree) => {
    return tree.map((node) => {
      if (node.documentTypeId === nodeId) {
        return {
          ...node,
          children: [
            ...node.children,
            ...documents.map((document) => ({
              documentId: document.documentId,
              documentName: document.documentName,
              documentTypeId: node.documentTypeId,
              documentTypeName: node.documentTypeName,
            })),
          ],
        };
      }
      if (node.children) {
        return {
          ...node,
          children: addDocumentsToTree(documents, nodeId, node.children),
        };
      }
      return node;
    });
  };

  const handleAddDocumentToType = () => {
    const updatedTree = addSelectedDocumentsToTree(
      Array.from(selectedDocumentIds),
      doc.documentTypeId,
      selectedDocumentTypes
    );
    setSelectedDocumentTypes(updatedTree);
    setCreateSubDivision(false);
    setSelectedDocumentIds(new Set());
  };

  const addSelectedDocumentsToTree = (selectedDocumentIds, nodeId, tree) => {
    const selectedDocs = documents.filter((doc) =>
      selectedDocumentIds.includes(doc.documentId)
    );
    const newTree = addDocumentsToTree(selectedDocs, nodeId, tree);
    return newTree;
  };

  return (
    <DashboardLayout title='Create New Proposal'>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <Box className='flex flex-col gap-2 h-full'>
          <Grid container spacing={3}>
            <Grid item md={4}>
              <Autocomplete
                id='free-solo-demo'
                options={tenders}
                getOptionLabel={(option) => option.tenderName}
                onChange={(event, value) => {
                  if (value) {
                    handleTenderChange(value.tenderId);
                  } else if (!value) {
                    setSelectedTender(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    label='Choose Tender'
                    {...params}
                    variant='filled'
                  />
                )}
              />
            </Grid>
          </Grid>

          {selectedTender !== null && (
            <GlassCard className='flex flex-row items-center justify-between p-3'>
              <Box className='flex items-center'>
                <Box className='text-4xl me-5'>
                  <Icon icon='emojione-v1:memo' />
                </Box>
                <Box>
                  <Box>Enquiry Id</Box>
                  <Box className='font-semibold'>
                    {
                      tenders.find((item) => item.tenderId === selectedTender)
                        ?.tenderId
                    }
                  </Box>
                </Box>
              </Box>
              <Box>
                <Box>Proposal Id</Box>
                <Box className='font-semibold'>
                  {
                    tenders.find((item) => item.tenderId === selectedTender)
                      ?.tenderProjectTypeId
                  }
                </Box>
              </Box>
              <Box>
                <Box>Client Name</Box>
                <Box className='font-semibold'>
                  {
                    tenders.find((item) => item.tenderId === selectedTender)
                      ?.clientName
                  }
                </Box>
              </Box>
              <Box>
                <Box>Tender Name</Box>
                <Box className='font-semibold'>
                  {
                    tenders.find((item) => item.tenderId === selectedTender)
                      ?.tenderName
                  }
                </Box>
              </Box>
              <Box>
                <Box>Status</Box>
                <Box className='font-semibold text-green-600'>
                  {
                    tenders.find((item) => item.tenderId === selectedTender)
                      ?.statusName
                  }
                </Box>
              </Box>
            </GlassCard>
          )}

          <Box className='font-bold' sx={{ color: 'text.dark' }}>
            Facility Management Division
          </Box>

          <Box className='flex-1 min-h-0'>
            <Box className='h-full-css'>
              <Grid container className='h-full'>
                <Grid item md={4} className='h-full flex flex-col'>
                  <Box
                    sx={{
                      border: '1px solid',
                      borderColor: 'border.main',
                      bgcolor: 'background.white',
                    }}
                    className='flex flex-col h-full'
                  >
                    <Box
                      className='p-2 flex gap-2 items-center'
                      sx={{
                        borderBottom: '1px solid',
                        borderColor: 'border.main',
                      }}
                    >
                      <TableSearch
                        placeholder='Search'
                        fullWidth
                        setSearchKeyword={setSearchKeyword}
                        searchValue={searchKeyword}
                      />
                      <CreateButton
                        className='font-semibold py-1 px-4'
                        size='small'
                        name='New'
                        onClick={() => setCreateDivision(!createDivision)}
                      />
                    </Box>
                    <Box className='flex-1 min-h-0'>
                      <Box className='h-full overflow-auto'>
                        <Box className='p-3 flex flex-col gap-3'>
                          {existingDocuments?.map((item) => (
                            <Box key={item.documentTypeId}>
                              <DragAndDropBox
                                name={item.documentTypeName}
                                id={item.documentTypeId}
                                icon={<Icon icon='majesticons:plus-line' />}
                                onClick={() => handleAddTask(item)}
                              />
                            </Box>
                          ))}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={8} className='h-full flex flex-col'>
                  <SortableTreeBoxStyle>
                    {selectedDocumentTypes.length === 0 ? (
                      <Box className='flex flex-col justify-center items-center text-center h-full'>
                        <p className='text-6xl mb-5 opacity-50'>
                          <Icon icon='teenyicons:drag-outline' />
                        </p>
                        <p className='text-xl opacity-50 font-light'>
                          No Items Found
                        </p>
                      </Box>
                    ) : (
                      <SortableTree
                        treeData={selectedDocumentTypes}
                        onChange={handleTreeChange}
                        generateNodeProps={({ node }) => ({
                          title: (
                            <>
                              <DragCards
                                name={node.documentTypeName}
                                id={node.documentTypeId}
                                type={node.isDocument ? 'document' : 'file'}
                                icon={<Icon icon='majesticons:plus-line' />}
                                edit={true}
                                onAdd={() => {
                                  handleDocumentTypeChange(node.documentTypeId);
                                  setCreateSubDivision(true);
                                  setDoc(node);
                                }}
                                onDelete={() => handleDeleteTask(node)}
                                childData={node.children}
                              />
                            </>
                          ),
                        })}
                      />
                    )}
                  </SortableTreeBoxStyle>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <GlassCard className='p-2 rounded-none'>
            <ActionButtons
              errorFields={!selectedTender}
              onSubmit={handleDocSubmit}
              cancelText='Cancel'
              onCancel={() => navigate(-1)}
              onReset={reset}
              removeSpacing
            >
              <Button variant='outlined'>Cancel</Button>
              <Button variant='contained'>Create</Button>
            </ActionButtons>
          </GlassCard>
        </Box>
      )}

      <MuiDialogOne
        title={`${doc.documentTypeName} (Add Extra)`}
        open={createSubDivision}
        load
        onClose={() => setCreateSubDivision(false)}
      >
        {localLoader ? (
          <CircularProgress />
        ) : (
          <>
            <Stack>
              {documents?.map((item) => (
                <FormControlLabel
                  key={item.documentId}
                  control={
                    <Checkbox
                      checked={selectedDocumentIds.has(item.documentId)}
                      onChange={(event) =>
                        handleCheckboxChange(event, item.documentId)
                      }
                    />
                  }
                  label={item.documentName}
                  sx={{ fontSize: '15px' }}
                />
              ))}
            </Stack>

            <Box className='mt-5 text-end'>
              <Button
                variant='contained'
                className='font-bold min-w-36'
                onClick={handleAddDocumentToType}
              >
                Submit
              </Button>
            </Box>
          </>
        )}
      </MuiDialogOne>

      <MuiDialogOne
        title='Create New'
        open={createDivision}
        onClose={() => setCreateDivision(false)}
        width='500px'
      >
        <form onSubmit={handleSubmit(createDocumentType)}>
          <Stack spacing={3}>
            <FmTextField
              label='Document Name'
              name='documentTypeName'
              control={control}
              required
              rules={{
                required: 'Document Name is required',
              }}
            />
            <ActionButtons
              onSubmit={handleSubmit(createDocumentType)}
              onCancel={() => {}}
              submitLoading={false}
              cancelLoading={false}
              onReset={reset}
            />
          </Stack>
        </form>
      </MuiDialogOne>
    </DashboardLayout>
  );
};

export default ProposalLineItemsMasterCreate;
