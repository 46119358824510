import React, { useState } from 'react';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import MRTExpandTableStyle from '../../../../components/table/MRTExpandTableStyle.tsx';
import MuiDialogOne from '../../../../components/_mui/MuiDialogOne.jsx';
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { ViewTable } from '../../../../components/_form/FormElements.jsx';
import { Grid } from '@mui/material';
import { StatusBadge } from '../../../../components/_form/FormElements.jsx';
import Crud_Service from '../../../../apis/CrudService.jsx';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import ActionButtons from '../../../../components/_form/ActionButtons.tsx';
import { toast } from 'react-toastify';
import useTableLogic from '../../../../components/helpers/MRTUseTableLogic.tsx';
import ContractScheduleListTable from './ContractScheduleListTable.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { contractScheduleSchema } from '../../../../utils/CommonvalidationSchemas.tsx';
import FmDatePicker from '../../../../components/_mui/FmDatePicker.tsx';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';

const gridValue = 2.4;

const AddFields = (props) => {
  const { fetchData } = props;
  const crud = new Crud_Service();
  const { startCreateLoading, stopCreateLoading, createLoading } = useLoader();
  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(contractScheduleSchema),
    mode: 'onChange',
  });
  const [radioChange, setRadioChange] = useState(1);

  const handleSubmitContractSchedule = async (data) => {
    startCreateLoading();
    const combinedData = {
      ...data,
      paymentReceived: radioChange,
      invoiceRef: data?.invoiceRef,
      startDate: dayjs(data.date).format('YYYY-MM-DD'),
      endDate: dayjs(data.date).format('YYYY-MM-DD'),
    };
    await crud.create('contractschedules', combinedData, (err, res) => {
      stopCreateLoading();
      if (err) {
        return;
      }
      if (res?.status === 201) {
        toast.success('Contract Schedule Created successfully');
        fetchData();
        reset();
      } else {
      }
    });
  };
  return (
    <GlassCard className='mb-3 p-3'>
      <form onSubmit={handleSubmit(handleSubmitContractSchedule)}>
        <Grid container spacing={2}>
          <Grid item md={gridValue}>
            <FmSearchableSelect
              name='contractId'
              control={control}
              apiUrl='contracts'
              valueField='contractId'
              required
              headerField={['Code', 'Name']}
              labelField={['contractCode', 'contractName']}
              showField={['contractCode', 'contractName', '']}
              label='Contract'
            />
          </Grid>
          <Grid item md={gridValue}>
            <FmSearchableSelect
              name='billingFrequencyId'
              control={control}
              apiUrl='billingfrequencies'
              required
              label='Billing Frequency'
              headerField={['Code', 'Name']}
              labelField={['billingFrequencyCode', 'billingFrequencyName']}
              showField={['billingFrequencyCode', 'billingFrequencyName']}
              valueField='billingFrequencyId'
            />
          </Grid>
          <Grid item md={gridValue}>
            <FmDatePicker
              name='startDate'
              control={control}
              label='Start Date'
            />
          </Grid>
          <Grid item md={gridValue}>
            <FmDatePicker name='endDate' control={control} label='End Date' />
          </Grid>
          <Grid item md={gridValue}>
            <FmTextField
              name='amountToBeInvoiced'
              label='Amount to be invoiced'
              type='number'
              required
              control={control}
            />
          </Grid>
          <Grid item md={gridValue}>
            <FmTextField
              name='invoicedAmount'
              label='Amount Invoiced'
              type='number'
              required
              control={control}
            />
          </Grid>
          <Grid item md={gridValue}>
            <FmTextField
              name='invoiceRef'
              label='Invoice Ref'
              required
              control={control}
              type='string'
            />
          </Grid>

          <Grid item md={gridValue}>
            <FmTextField
              name='paymentAmount'
              label='Payment Amount'
              control={control}
              type='number'
              required
            />
          </Grid>
          <Grid item md={gridValue}>
            <FmTextField
              name='paymentRef'
              required
              label='Payment Ref'
              control={control}
            />
          </Grid>
          <Grid item md={gridValue}>
            <FmTextField
              name='deductionAmount'
              label='Deductions'
              type='number'
              required
              control={control}
            />
          </Grid>
          <Grid item md={gridValue}>
            <FmTextField name='remarks' label='Remarks' control={control} />
          </Grid>
          <Grid item md={gridValue}>
            <FormControl>
              Payment Received
              <RadioGroup
                sx={{ flexDirection: 'row' }}
                defaultValue={1}
                onChange={(e) => setRadioChange(Number(e.target.value))}
              >
                <FormControlLabel value={1} control={<Radio />} label='YES' />
                <FormControlLabel value={2} control={<Radio />} label='NO' />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        <ActionButtons
          onSubmit={handleSubmit(handleSubmitContractSchedule)}
          onReset={reset}
          submitLoading={createLoading}
        />
      </form>
    </GlassCard>
  );
};

const BoldBox = ({ title, primary }) => {
  return (
    <Box className='font-semibold' sx={{ color: primary && 'primary.main' }}>
      {title}
    </Box>
  );
};

const generateData = () => {
  const data = [];
  for (let i = 1; i <= 15; i++) {
    data.push({
      id: i,
      contract: 'contract 1',
      frequency: 'Frequency 1',
      date1: '25-8-2024',
      date2: '25-10-2024',
      amounttobeInvoiced: '15000',
      amountInvoiced: '15000',
      invoiceRef: 'Invoice Ref',
      paymentReceived: 'Yes',
      payAmount: '12500',
      payRef: 'Refference 1',
      deducations: 'Deducations 1',
      remarks: 'Payment to be made in three installments',
    });
  }
  return data;
};

const initialRows = generateData();

const ViewContractSchedule = (props) => {
  const { contractId } = props;
  const {
    rows,
    rowCount,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic('contractschedules', 'contractId', contractId);
  const [data, setData] = useState(initialRows);
  const [, setCreatingRow] = useState(null);
  const [view, setView] = useState(false);
  const [viewRow, setViewRow] = useState([]);

  return (
    <>
      {/* TODO */}
      {/* <AddFields fetchData={fetchData} /> */}

      <GlassCard>
        <MRTExpandTableStyle>
          <ContractScheduleListTable
            rows={rows}
            tableRecordCounts={tableRecordCounts}
            pagination={pagination}
            sorting={sorting}
            fetchData={fetchData}
            setPagination={setPagination}
            setSorting={setSorting}
            columnFilters={columnFilters}
            globalFilter={globalFilter}
            setColumnFilters={setColumnFilters}
            setGlobalFilter={setGlobalFilter}
            isLoading={isLoading}
            setSearchKeyword={setSearchKeyword}
            searchKeyword={searchKeyword}
          />
        </MRTExpandTableStyle>
      </GlassCard>

      <MuiDialogOne
        title='View details'
        open={view}
        onClose={() => setView(!view)}
      >
        <ViewTable
          rows={[
            {
              label: 'Contract',
              title: viewRow?.contract,
            },
            {
              label: 'Frequency',
              title: viewRow?.frequency,
            },
            {
              label: 'Date 1',
              title: viewRow?.date1,
            },
            {
              label: 'Date 2',
              title: viewRow?.date2,
            },
            {
              label: 'Amount To Be Invoiced',
              title: viewRow?.amounttobeInvoiced,
            },
            {
              label: 'Amount Invoiced',
              title: viewRow?.amountInvoiced,
            },
            {
              label: 'Invoice Ref',
              title: viewRow?.invoiceRef,
            },
            {
              label: 'Payment Received',
              title: <StatusBadge title={'Yes'} type='green' />,
            },
            {
              label: 'Pay Amount',
              title: viewRow?.payAmount,
            },
            {
              label: 'Pay Ref',
              title: viewRow?.payRef,
            },
            {
              label: 'Deductions (if any)',
              title: viewRow?.deducations,
            },
            {
              label: 'Remarks',
              title: viewRow?.remarks,
            },
          ]}
        />
      </MuiDialogOne>
    </>
  );
};

export default ViewContractSchedule;
