import React, { useEffect } from 'react';
import GlassCard from '../../../../../components/small/GlassCard.tsx';
import { TextField, Button, Grid, MenuItem } from '@mui/material';
import FmTextField from '../../../../../components/_mui/FmTextField.tsx';
import FmSearchableSelect from '../../../../../components/_mui/FmSearchableSelect.tsx';
import useLoader from '../../../../../components/helpers/UseLoader.tsx';
import { Controller, useForm } from 'react-hook-form';
import Crud_Service from '../../../../../apis/CrudService.jsx';
import MuiDatePickerOne from '../../../../../components/_mui/MuiDatePickerOne.tsx';
import ActionButtons from '../../../../../components/_form/ActionButtons.tsx';
import FmAutoComplete from '../../../../../components/_mui/FmAutoComplete.tsx';
import { statusOptions } from '../../../../../utils/CommonVariables.tsx';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import FmDatePicker from '../../../../../components/_mui/FmDatePicker.tsx';
import DashboardLayout from '../../../../../components/DashboardLayout.tsx';
import { use } from 'i18next';
import { useParams } from 'react-router-dom';
const GridSize = 2.4;

const EditCheckOutForm = () => {
  const crud = new Crud_Service();
  const id = useParams().id;
  const { createLoading, startCreateLoading, stopCreateLoading } = useLoader();
  const [editData, setEditData] = React.useState(null);

  const { handleSubmit, control, setValue, getValues, reset } = useForm();

  useEffect(() => {
    if (id) {
      (async () => {
        await crud.getSingle('checkoutforms', id, (err, res) => {
          if (res?.status === 200) {
            setEditData(res.data);
            Object.entries({
              ...res.data,
            }).forEach(([key, value]) => {
              // Add your logic here for handling key-value pairs
            });
          } else {
            // Handle error response here
          }
        });
      })();
    }
  }, [id]);

  const handleSubmitCheckoutForm = async (data) => {
    startCreateLoading();
    const combinedData = {
      ...data,
      checkOutDate: dayjs(data?.checkOutDate).format('YYYY-MM-DD'),
      inChargeId: Number(data?.loginUserId),
      status: 2,
    };
    await crud.update('checkoutforms', id, combinedData, (err, res) => {
      if (err) {
        stopCreateLoading();

        return;
      }
      if (res?.status === 200) {
        toast.success('Item Created successfully');
        reset();
        stopCreateLoading();
      } else {
        stopCreateLoading();
      }
    });
  };

  return (
    <GlassCard className='p-3'>
      <form onSubmit={handleSubmit(handleSubmitCheckoutForm)}>
        <Grid container spacing={2}>
          <Grid item md={GridSize}>
            <FmDatePicker
              name='checkOutDate'
              label='Check Out Date'
              control={control}
              required
            />
          </Grid>
          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='loginUserId'
              control={control}
              apiUrl='loginusers'
              valueField='loginUserId'
              labelField={['employeeCode', 'loginUserName']}
              showField={['employeeCode', 'loginUserName']}
              label='In Charge Name'
              required
              defaultValue={{
                loginUserId: editData?.inChargeId || null,
                loginUserName: editData?.inchargeName || '',
              }}
            />
          </Grid>
          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='contractId'
              control={control}
              apiUrl='contracts'
              valueField='contractId'
              headerField={['Contract Code']}
              labelField={['contractCode']}
              showField={['contractCode']}
              label='Contract'
              required
              defaultValue={{
                contractId: editData?.contractId || null,
                contractCode: editData?.contractCode || '',
              }}
            />
          </Grid>
          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='companyId'
              control={control}
              apiUrl='companies'
              valueField='companyId'
              headerField={['Code']}
              labelField={['companyCode']}
              showField={['companyCode']}
              label='Company'
              required
              defaultValue={{
                companyId: editData?.companyId || null,
                companyCode: editData?.companyCode || '',
              }}
            />
          </Grid>

          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='clientId'
              control={control}
              apiUrl='clients'
              valueField='clientId'
              headerField={['Client Name']}
              labelField={['clientName']}
              showField={['clientName']}
              label='Client'
              required
              defaultValue={{
                clientId: editData?.clientId || null,
                clientName: editData?.clientName || '',
              }}
            />
          </Grid>
          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='projectId'
              control={control}
              apiUrl='projects'
              valueField='projectId'
              headerField={['Project Code']}
              labelField={['projectCode']}
              showField={['projectCode']}
              label='Project Code'
              required
              defaultValue={{
                projectId: editData?.projectId || null,
                projectCode: editData?.projectCode || '',
              }}
            />
          </Grid>

          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='workOrderId'
              control={control}
              apiUrl='workorders'
              valueField='workOrderId'
              headerField={['User Name']}
              labelField={['workOrderName', 'workOrderCode']}
              showField={['workOrderName', 'workOrderCode']}
              label='work Order'
              required
              defaultValue={{
                workOrderId: editData?.workOrderId || null,
                workOrderName: editData?.workOrderName || '',
              }}
            />
          </Grid>
        </Grid>
        <Grid item md={GridSize}>
          <ActionButtons
            onSubmit={handleSubmit(handleSubmitCheckoutForm)}
            // onCancel={() => navigate(-1)}
            onReset={reset}
            // submitLoading={createLoading}
            cancelLoading={false}
          />
        </Grid>
      </form>
    </GlassCard>
  );
};

export default EditCheckOutForm;
