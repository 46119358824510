import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import { Button, Divider, Menu } from '@mui/material/';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
import MuiMenuItem from '../_mui/MuiMenuItem';
import Icons from '../../utils/Icon';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import HeaderMenuImpersonator from './HeaderMenuImpersonator.tsx';
import { userLogout } from '../../redux/loginSlice.ts';
import { toast } from 'react-toastify';
import MuiAlertModal from '../_mui/MuiAlertModal.tsx';

const HeaderMenu = ({ headerStyle = false }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const { t } = useTranslation();
  const open = Boolean(anchorEl);
  const user = useSelector((state) => state.auth.user);
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  const [impersonView, setImpersonView] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogout = async () => {
    try {
      await dispatch(userLogout({ user, token, dispatch, navigate }));
      handleClose();
      setOpenDialog(false);
      // navigate('/');
    } catch (error) {
      toast.error('Logout error:', error);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDialogOpen = () => {
    setOpenDialog(true);
    setAnchorEl(null);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const AccountMenu = [
    {
      title: t('my_profile'),
      icon: Icons.profile,
      onClick: null,
    },
    {
      title: t('settings'),
      icon: Icons.settings,
      onClick: null,
    },
    {
      title: t('logout'),
      icon: Icons.logout,
      onClick: handleDialogOpen,
    },
  ];

  if (!user?.impersonatorUserName) {
    AccountMenu.splice(2, 0, {
      title: 'Impersonator Mode',
      icon: Icons.impersonator,
      onClick: () => {
        setImpersonView((prev) => !prev);
        setAnchorEl(null);
      },
      danger: true,
    });
  }

  return (
    <Box>
      <Button
        id='demo-positioned-button'
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{
          p: !headerStyle ? '0px 10px' : '0px 10px',
          textTransform: 'none',
          gap: '10px',
          fontSize: 'inherit',
          color: 'inherit',
          lineHeight: 1,
          padding: '2px 10px',
          textAlign: 'left',
          fontWeight: 600,
          justifyContent: 'space-between',
          width: '100%',
          borderRadius: '5px',
          '&:hover': {
            bgcolor: !headerStyle ? 'background.overlayTwo' : 'none',
          },
        }}
      >
        <Box className='flex gap-3 items-center'>
          {user?.impersonatorUserName ? (
            <Avatar
              alt={user?.impersonatorUserName || user?.userName}
              sx={{
                width: '30px',
                height: '30px',
                bgcolor: 'error.main',
                color: 'text.white',
              }}
            >
              {Icons.impersonator}
            </Avatar>
          ) : (
            <Avatar
              alt={user?.userName}
              sx={{
                width: '30px',
                height: '30px',
                bgcolor: !headerStyle
                  ? 'background.overlayTwo'
                  : 'background.white',
                color: !headerStyle ? 'text.white' : 'text.dark',
              }}
            />
          )}

          <Box className='sm:hidden'>
            <Box>{user?.impersonatorUserName || user?.userName}</Box>
          </Box>
        </Box>
        <Icon icon='fluent:chevron-up-down-24-regular' />
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{ sx: { pt: 0 } }}
        disableScrollLock={true}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          sx: {
            mt: '5px',
          },
        }}
      >
        <li className='w-full p-3'>
          <Box
            className='w-full flex text-left gap-4 justify-center items-center'
            sx={{ color: 'text.dark' }}
          >
            <div className='inline-block'>
              <Avatar
                alt='Cindy Baker'
                sx={{ width: 35, height: 35, bgcolor: 'primary.main' }}
              />
            </div>
            <div>
              <h3 className='font-semibold'>
                {user?.impersonatorUserName || user?.userName}
              </h3>
              <p className='opacity-70'>{user?.email}</p>

              {user?.impersonatorUserName && (
                <Box
                  sx={{
                    display: 'inline-block',
                    bgcolor: 'error.main',
                    padding: '3px 15px',
                    borderRadius: '35px',
                    color: '#fff',
                    fontWeight: 700,
                    marginTop: '5px',
                  }}
                >
                  Impersonator Mode
                </Box>
              )}
            </div>
          </Box>
        </li>

        <Divider sx={{ mb: 1 }} />

        {AccountMenu?.map((menu, i) => (
          <MuiMenuItem
            onClick={menu.onClick}
            key={i}
            name={menu.title}
            icon={menu.icon}
            danger={menu.danger ? true : false}
          />
        ))}
      </Menu>

      <HeaderMenuImpersonator
        open={impersonView}
        onClose={() => setImpersonView(!impersonView)}
      />

      <MuiAlertModal
        open={openDialog}
        close={handleDialogClose}
        submitOnclick={handleLogout}
        submitText='Confirm Logout'
        desc={'Are you sure you want to logout?'}
        title={'Logout Confirmation'}
      />
    </Box>
  );
};

export default HeaderMenu;
