import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import BackToButton from '../../../../../components/small/BackToButton.jsx';

import Crud_Service from '../../../../../apis/CrudService.jsx';
import DashboardLayout from './../../../../../components/DashboardLayout.tsx';
import ViewCashBankReceiptList from './ViewCashBankReceiptList.tsx';

import EditCBR from '../EditCBR.tsx';

const ViewCashBankReceipt = (props) => {
  const { type } = props;
  const navigation = useNavigate();
  const { id } = useParams();
  const crud = new Crud_Service();
  const [viewData, setViewData] = useState({});

  const viewDataFetch = async () => {
    crud.getSingle('financialtransactions', id, (err, res) => {
      if (res?.status === 200) {
        setViewData(res?.data);
      } else {
        setViewData([]);
      }
    });
  };
  useEffect(() => {
    if (id) {
      viewDataFetch();
    }
  }, [id]);

  useEffect(() => {
    document.title = `View | ${
      type === 1 ? ' Receipt' : type === 2 ? ' Payment' : 'Petty Cash'
    }| Cash and Bank | Financial`;
  });

  return (
    <>
      <DashboardLayout
        title={`View ${
          type === 1 ? 'Receipt' : type === 2 ? 'Payment' : 'Petty Cash'
        }`}
        actionButtons={
          <BackToButton
            title='Back to List'
            onClick={() => navigation(-1)}
            className='font-bold py-0 px-1'
          />
        }
        titleReverse
      >
        <EditCBR type={type} />

        <ViewCashBankReceiptList financialTransactionId={id} />
      </DashboardLayout>
    </>
  );
};

export default ViewCashBankReceipt;
