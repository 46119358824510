import React, { useState } from 'react';
import GlassCard from '../../../../../components/small/GlassCard.tsx';
import { MaterialReactTable } from 'material-react-table';
import MRTExpandTableStyle from '../../../../../components/table/MRTExpandTableStyle.tsx';
import Icons from '../../../../../utils/Icon.jsx';
import { Box, Grid, TextField, MenuItem, Button } from '@mui/material';
import MRTIconButton from '../../../../../components/table/MRTIconButton.tsx';
import { StatusBadge } from '../../../../../components/_form/FormElements.jsx';
import { useForm } from 'react-hook-form';
import FmSearchableSelect from '../../../../../components/_mui/FmSearchableSelect.tsx';
import { toast } from 'react-toastify';
import useLoader from '../../../../../components/helpers/UseLoader.tsx';
import Crud_Service from '../../../../../apis/CrudService.jsx';
import ActionButtons from '../../../../../components/_form/ActionButtons.tsx';
import { useNavigate } from 'react-router-dom';
import ItemProjectListTable from './ItemProjectListTable.tsx';
import useTableLogic from '../../../../../components/helpers/MRTUseTableLogic.tsx';
import FmMRTDataTable from '../../../../../components/FmMRTDataTable.tsx';
import TableActionButtons from '../../../../../components/_form/TableActionButtons.jsx';
import FmTextField from '../../../../../components/_mui/FmTextField.tsx';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const BoldBox = ({ title }) => {
  return <Box className='font-semibold'>{title}</Box>;
};

const MdItemValue = 2;

const ItemsPropertyList = ({ CheckInFormId }) => {
  const {
    rows,
    rowCount,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic('checkinformitems');
  const [loading, setLoading] = useState(false);
  const [editingRowId, setEditingRowId] = useState(null);
  const [editingRowData, setEditingRowData] = useState(null);
  const validationSchema = Yup.object().shape({
    propertyId: Yup.number()
      .typeError('Property ID must be a number')
      .required('Property ID is required')
      .min(1, 'Property must be greater than 0'),
    checkInLocationId: Yup.number()
      .required('Location ID is required')
      .min(1, 'Location must be greater than 0'),
  });

  const { handleSubmit, control, reset } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });
  const {
    handleSubmit: updateHandleSubmit,
    control: updateControl,
    reset: updateReset,
    setValue,
    getValues,
  } = useForm({});

  const navigate = useNavigate();
  const { startEditLoading, stopEditLoading } = useLoader();
  const crud = new Crud_Service();
  const handleSubmitItemProperty = async (data) => {
    setLoading(true);
    const combinedData = {
      checkInFormId: CheckInFormId,
      createCheckInFormItems: [
        {
          propertyId: Number(data?.propertyId),
          checkInLocationId: Number(data?.checkInLocationId),
          quantity: null,
          codeNumber: null,
          remarks: null,
        },
      ],
    };
    await crud.create('checkinformitems', combinedData, (err, res) => {
      if (err) {
        setLoading(false);

        return;
      }
      if (res?.status === 201) {
        toast.success('Item Created successfully');
        fetchData();
        reset();
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  const handleEdit = (row) => {
    setEditingRowId(row?.checkInFormItemId);
    setEditingRowData({ ...row });
    setValue('propertyId', row?.propertyId);
    setValue('checkInLocationId', row?.checkInLocationId);
  };

  const handleCancel = () => {
    setEditingRowId(null);
    setEditingRowData(null);
  };

  const handleDeleteItem = async (view) => {
    await crud.remove(
      'checkinformitems',
      view?.checkInFormItemId,
      (err, res) => {
        if (res?.status === 204) {
          toast.success('Item Details Deleted Successfully');
          fetchData();
        } else {
        }
      }
    );
  };

  // const handleUpdateItem = async (values) => {
  //   startEditLoading();

  // const combinedData = {
  //   ...values,
  //   quantity: null,
  //   codeNumber: null,
  //   remarks: null,
  //   status: 2,
  // };

  //   try {
  //     const res = await crud.update('checkinformitems', editingRowId, combinedData);

  //     if (res?.status === 200) {
  //       toast.success('Form Updated Successfully');
  //       stopEditLoading();
  //       fetchData();
  //       setEditingRowData(null);
  //       setEditingRowId(null);
  //     } else {
  //       stopEditLoading();
  //
  //     }
  //   } catch (err) {
  //     toast.error(err);
  //   } finally {
  //     stopEditLoading();
  //   }
  // };

  const handleUpdateItem = async (values) => {
    startEditLoading();
    const combinedData = {
      ...values,
      quantity: null,
      codeNumber: null,
      remarks: null,
      status: 2,
    };

    await crud.update(
      'checkinformitems',
      editingRowId,
      combinedData,
      (err, res) => {
        if (res?.status === 200) {
          stopEditLoading();
          fetchData();
          toast.success('Item Property Updated Successfully');
          setEditingRowData(null);
          setEditingRowId(null);
        } else {
          stopEditLoading();
        }
      }
    );
  };

  const ActionData = [
    {
      name: 'Edit',
      icon: Icons.edit,
      onClick: (props) => {
        handleEdit(props);
      },
    },
    {
      name: 'Delete',
      icon: Icons.delete,
      onClick: (props) => handleDeleteItem(props),
      danger: true,
    },
  ];

  const columns = [
    {
      header: 'Actions',
      Cell: ({ row }) => (
        <Box className='flex gap-2'>
          {editingRowId === row?.original?.checkInFormItemId ? (
            <>
              <Button onClick={updateHandleSubmit(handleUpdateItem)}>
                Save
              </Button>
              <Button onClick={handleCancel}>Cancel</Button>
            </>
          ) : (
            <TableActionButtons
              Actions={ActionData?.map((action) => ({
                ...action,
                onClick: () => action.onClick(row?.original),
                // onClick: () => {
                //   setEditingRowId(row?.original?.checkInFormItemId);
                //   setEditingRowData(row?.original);
                //   action.onClick(row?.original);
                // },
              }))}
            />
          )}
        </Box>
      ),
    },

    {
      accessorKey: 'propertyId',
      header: 'Property Name',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.checkInFormItemId ? (
          <FmSearchableSelect
            name='propertyId'
            control={updateControl}
            apiUrl='properties'
            valueField='propertyId'
            defaultValue={{
              propertyId: editingRowData?.propertyId,
              propertyName: editingRowData?.propertyName,
            }}
            labelField={['propertyCode', 'propertyName']}
            showField={['propertyName']}
            label={'Property Name'}
          />
        ) : (
          row.original.propertyName
        );
      },
    },

    {
      accessorKey: 'checkInLocationId',
      header: 'Location name',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.checkInFormItemId ? (
          <FmSearchableSelect
            name='checkInLocationId'
            control={updateControl}
            apiUrl='checkinlocations'
            valueField='checkInLocationId'
            defaultValue={{
              checkInLocationId:
                editingRowData?.checkInLocationId ||
                row?.original?.checkInLocationId,
              checkInLocationName:
                editingRowData?.checkInLocationName ||
                row?.original?.checkInLocationName,
            }}
            labelField={['checkInLocationCode', 'checkInLocationName']}
            showField={['checkInLocationName']}
            label={'Location'}
          />
        ) : (
          row.original.checkInLocationName
        );
      },
    },
  ];

  return (
    <>
      <h3 className='mb-3 text-sm font-bold'>Items Property</h3>

      <GlassCard className='my-4'>
        <form onSubmit={handleSubmit(handleSubmitItemProperty)}>
          <Box className='p-3'>
            <Grid container spacing={2}>
              <Grid item md={MdItemValue}>
                <FmSearchableSelect
                  name='propertyId'
                  control={control}
                  apiUrl='properties'
                  valueField='propertyId'
                  labelField={['propertyCode', 'propertyName']}
                  showField={['propertyName']}
                  label={'Property Name'}
                />
              </Grid>
              <Grid item md={MdItemValue}>
                <FmSearchableSelect
                  name='checkInLocationId'
                  control={control}
                  apiUrl='checkinlocations'
                  valueField='checkInLocationId'
                  labelField={['checkInLocationCode', 'checkInLocationName']}
                  showField={['checkInLocationName']}
                  label={'Location'}
                />
              </Grid>
              {/* TODO */}
              {/* <Grid item md={MdItemValue * 2}>
                <TextField
                  label='Accepted by Outgoing vendor (Y/N)'
                  fullWidth
                  variant='filled'
                  select
                >
                  <MenuItem value={10}>Yes</MenuItem>
                  <MenuItem value={20}>No</MenuItem>
                </TextField>
              </Grid> */}
            </Grid>
            <ActionButtons
              onSubmit={handleSubmit(handleSubmitItemProperty)}
              onReset={reset}
              submitText='create'
              submitLoading={loading}
              cancelLoading={false}
            />
          </Box>
        </form>
        <>
          <FmMRTDataTable
            columns={columns}
            enableRowSelection={false}
            rows={rows}
            rowCount={tableRecordCounts}
            editingRow={editingRowId}
            pagination={pagination}
            sorting={sorting}
            columnFilters={columnFilters}
            globalFilter={globalFilter}
            setPagination={setPagination}
            setSorting={setSorting}
            setColumnFilters={setColumnFilters}
            setGlobalFilter={setGlobalFilter}
            isLoading={isLoading}
            setSearchKeyword={setSearchKeyword}
            searchKeyword={searchKeyword}
            handleRowDoubleClick={(e) => handleEdit(e?.original)}
          />
        </>
      </GlassCard>
    </>
  );
};

export default ItemsPropertyList;
