import React, { useState } from 'react';
import GlassCard from '../../../../../components/small/GlassCard.tsx';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@mui/material';
import FmSearchableSelect from '../../../../../components/_mui/FmSearchableSelect.tsx';
import { useForm } from 'react-hook-form';
import FmTextField from '../../../../../components/_mui/FmTextField.tsx';
import ActionButtons from '../../../../../components/_form/ActionButtons.tsx';
import TenantVehicleTable from './TenantVehicleTable.tsx';
import { toast } from 'react-toastify';
import Crud_Service from '../../../../../apis/CrudService.jsx';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
const BoldBox = ({ title }) => {
  return <Box className='font-semibold'>{title}</Box>;
};

const MdItemValue = 2;

const generateData = () => {
  const data = [];
  for (let i = 1; i <= 4; i++) {
    data.push({
      id: i,
      vehicleBrand: 'Maruti Suzuki',
      vehicleColor: 'White',
      regNo: '5054695994',
      parkingSlot: '2',
      locationFloor: '2nd Floor',
    });
  }
  return data;
};

const initialRows = generateData();

const TenantVehicleList = (props) => {
  const {
    rows,
    columns,
    tableRecordCounts,
    pagination,
    sorting,
    setPagination,
    setSorting,
    columnFilters,
    globalFilter,
    setColumnFilters,
    setGlobalFilter,
    isLoading,
    setSearchKeyword,
    searchKeyword,
    fetchData,
    checkInFormId,
  } = props;
  const [data, setData] = useState(initialRows);
  const [, setCreatingRow] = useState(null);
  const [editingRowId, setEditingRowId] = useState(null);
  const [parkingSlot, setParkingSlot] = useState(1);
  const [loading, setLoading] = useState(false);
  const crud = new Crud_Service();

  const validationSchema = Yup.object().shape({
    vehicleBrandId: Yup.number()
      .required('Vehicle brand ID is required')
      .min(1, 'Vehicle brandID must be greater than 0'),
  });

  const { handleSubmit, control, reset } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });
  const handleSubmitVehicle = async (data) => {
    setLoading(true);
    const combinedData = {
      checkInFormId: checkInFormId,
      vehicleBrandId: Number(data?.vehicleBrandId),
      vehicleColor: data?.vehicleColor || '',
      registrationNumber: data?.registrationNumber || '',
      parkingId: parkingSlot,
    };
    await crud.create('checkinformtenantvehicles', combinedData, (err, res) => {
      if (err) {
        setLoading(false);

        return;
      }
      if (res?.status === 201) {
        toast.success('Vehicle Created successfully');
        fetchData();
        reset();
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  return (
    <>
      <h3 className='mb-3 text-sm font-bold pt-4'>
        Details of Tenant's Vehicle (s)
      </h3>

      <GlassCard className='my-4'>
        <form onSubmit={handleSubmit(handleSubmitVehicle)}>
          <Box className='p-3'>
            <Grid container spacing={2}>
              <Grid item md={MdItemValue}>
                <FmSearchableSelect
                  name='vehicleBrandId'
                  control={control}
                  apiUrl='vehiclebrands'
                  valueField='vehicleBrandId'
                  labelField={['vehicleBrandCode', 'vehicleBrandName']}
                  showField={['vehicleBrandName']}
                  label={'Vehicle Brand'}
                />
              </Grid>
              <Grid item md={MdItemValue}>
                <FmTextField
                  name='vehicleColor'
                  label='Vehicle Color'
                  control={control}
                />
              </Grid>
              <Grid item md={MdItemValue}>
                <FmTextField
                  name='registrationNumber'
                  label='Registration Number'
                  control={control}
                />
              </Grid>
              <Grid item md={MdItemValue}>
                <FormControl>
                  <FormLabel
                    id='demo-row-radio-buttons-group-label'
                    className='font-bold mb-2'
                    sx={{ color: 'primary.main' }}
                  >
                    Parking Slot
                  </FormLabel>
                  <RadioGroup
                    sx={{ flexDirection: 'row' }}
                    defaultValue={1}
                    onChange={(e) => setParkingSlot(Number(e.target.value))}
                  >
                    <FormControlLabel
                      value={1}
                      control={<Radio />}
                      label='Yes'
                    />
                    <FormControlLabel
                      value={2}
                      control={<Radio />}
                      label='No'
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            <ActionButtons
              onSubmit={handleSubmit(handleSubmitVehicle)}
              onReset={reset}
              submitLoading={loading}
              cancelLoading={false}
              submitText='Create'
            />
          </Box>
        </form>
        <>
          <TenantVehicleTable
            columns={columns}
            enableRowSelection={false}
            rows={rows}
            rowCount={tableRecordCounts}
            editingRow={editingRowId}
            pagination={pagination}
            sorting={sorting}
            columnFilters={columnFilters}
            globalFilter={globalFilter}
            setPagination={setPagination}
            setSorting={setSorting}
            setColumnFilters={setColumnFilters}
            setGlobalFilter={setGlobalFilter}
            isLoading={isLoading}
            setSearchKeyword={setSearchKeyword}
            searchKeyword={searchKeyword}
            fetchData={fetchData}
            checkInFormId={checkInFormId}
          />
        </>
      </GlassCard>
    </>
  );
};

export default TenantVehicleList;
