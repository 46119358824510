import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import { Box, Grid } from '@mui/material';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import ActionButtons from '../../../../components/_form/ActionButtons.tsx';
import { useNavigate, useParams } from 'react-router-dom';
import Crud_Service from '../../../../apis/CrudService.jsx';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import CommonLoader from '../../../../components/page/CommonLoader.jsx';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import { toast } from 'react-toastify';
import { CreateButton } from '../../../../components/small/Buttons.jsx';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import { CampValidationSchema } from '../../../../utils/CommonvalidationSchemas.tsx';
import { yupResolver } from '@hookform/resolvers/yup';

const EditAccomCamps = () => {
  const navigate = useNavigate();
  const crud = new Crud_Service();
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [editData, setEditData] = useState(null);
  const { handleSubmit, control, setValue, reset } = useForm({
    mode: 'onChange',
    resolver: yupResolver(CampValidationSchema),
  });
  const { editLoading, startEditLoading, stopEditLoading } = useLoader();
  const { t } = useTranslation();

  useEffect(() => {
    getCamps();
  }, []);

  //   Setting the updated client values
  const getCamps = async () => {
    setLoading(true);
    await crud.getSingle('camps', id, (_err, res) => {
      if (res?.status === 200) {
        const data = res?.data;
        Object.entries({
          ...data,
        }).forEach(([key, value]) => {
          setValue(key, value);
        });
        setEditData(res?.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  const handleUpdateCamps = async (values) => {
    try {
      startEditLoading();
      await crud.update('camps', values?.campId, values, (_err, res) => {
        if (res?.status === 200) {
          stopEditLoading();
          navigate(-1);
          toast.success('Camp Updated Successfully');
        } else {
          stopEditLoading();
        }
      });
    } catch (error) {
      stopEditLoading();
    }
  };

  return (
    <DashboardLayout
      title='Edit Accommodation Camp'
      actionButtons={
        <>
          <Box className='flex flex-row items-center whitespace-nowrap gap-3'>
            <CreateButton
              name='Create'
              onClick={() =>
                navigate('/admin/accommodation/camp/create')
              }
            />
          </Box>
        </>
      }
    >
      <GlassCard className='p-5'>
        {loading ? (
          <CommonLoader />
        ) : (
          <form onSubmit={handleSubmit(handleUpdateCamps)}>
            <Grid container spacing={2}>
              <Grid item md={2.4}>
                <FmTextField
                  label='Camp Name'
                  name='campName'
                  control={control}
                />
              </Grid>

              <Grid item md={2.4}>
                <FmSearchableSelect
                  name='accommodationId'
                  control={control}
                  apiUrl='accommodations'
                  valueField='accommodationId'
                  headerField={['Accommodation Name']}
                  labelField={['accommodationName']}
                  showField={['accommodationName']}
                  label='Accommodation'
                  required
                  defaultValue={{
                    accommodationId: editData?.accommodationId,
                    accommodationName: editData?.accommodationName,
                  }}
                />
              </Grid>

              <Grid item md={2.4}>
                <FmSearchableSelect
                  name='employeeId'
                  control={control}
                  apiUrl='employees'
                  valueField='employeeId'
                  headerField={['Employee Code', 'Employee Name']}
                  labelField={['employeeCode', 'firstName']}
                  showField={['firstName']}
                  label='Employees'
                  defaultValue={{
                    employeeId: editData?.employeeId,
                    firstName: editData?.employeeName,
                  }}
                />
              </Grid>

              <Grid item md={2.4}>
                <FmSearchableSelect
                  name='blockId'
                  control={control}
                  apiUrl='blocks'
                  valueField='blockId'
                  headerField={['Block Name']}
                  labelField={['blockName']}
                  showField={['blockName']}
                  label='Block'
                  defaultValue={{
                    blockId: editData?.blockId,
                    blockName: editData?.blockName,
                  }}
                />
              </Grid>

              <Grid item md={2.4}>
                <FmSearchableSelect
                  name='roomId'
                  control={control}
                  apiUrl='rooms'
                  valueField='roomId'
                  headerField={['Room No']}
                  labelField={['roomNo']}
                  showField={['roomNo']}
                  label='Room No'
                  defaultValue={{
                    roomId: editData?.roomId,
                    roomNo: editData?.roomNo,
                  }}
                />
              </Grid>

              <Grid item md={2.4}>
                <FmTextField
                  label='Occupied'
                  name='occupied'
                  control={control}
                />
              </Grid>
              {/* TODO */}
              {/* <Grid item md={2.4}>
              <FmSearchableSelect
                name='employeeRoomStatusId'
                control={control}
                apiUrl='rooms'
                valueField='employeeRoomStatusId'
                headerField={['Room Name']}
                labelField={['roomName']}
                showField={['roomName']}
                
                label='Employee Room Status'
                required
              />
            </Grid> */}

              <Grid item md={4}>
                <FmTextField
                  label='Remarks'
                  name='remarks'
                  control={control}
                  multiline={true}
                  rows={2}
                  maxRows={3}
                />
              </Grid>
            </Grid>
            <ActionButtons
              onSubmit={handleSubmit(handleUpdateCamps)}
              onReset={reset}
              submitText='Update'
              onCancel={() => navigate(-1)}
              cancelText='Cancel'
              submitLoading={editLoading}
            />
          </form>
        )}
      </GlassCard>
    </DashboardLayout>
  );
};

export default EditAccomCamps;
