// const UploadSquare = ({
//   onFilesSelected,
//   previewdData,
//   uploadSingle = false,
//   name = 'Multiple Upload File',
//   fileType,
// }) => {
//   // const [uploadedFiles, setUploadedFiles] = useState([]);
//   const [files, setFiles] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const crud = new Crud_Service();
//   const [singleFile, setSingleFile] = useState(false);
//   const [errorMessage, setErrorMessage] = useState('');

//   //fileTypes
//   const allowedTypes = {
//     image: 'image/jpeg,image/png,image/jpg,image/gif,image/webp',
//     all: '*',
//   };

// const { getRootProps, getInputProps, isDragActive } = useDropzone({
//   accept: allowedTypes[fileType] || allowedTypes.all,
//   multiple: true,
//   onDrop: async (acceptedFiles, rejectedFiles) => {
//     setLoading(true);

//     try {
//       let filteredFiles = acceptedFiles;

//       // Filter files based on fileType
//       if (fileType === 'image') {
//         filteredFiles = acceptedFiles.filter((file) => {
//           const isImage = [
//             'image/jpeg',
//             'image/png',
//             'image/jpg',
//             'image/gif',
//             'image/webp',
//           ].includes(file.type);
//           if (!isImage) {
//             toast.error(
//               `${file.name} is not a valid image type. only images are allowed`,
//               { position: 'top-right' }
//             );
//           }
//           return isImage;
//         });
//       }

//       // Add a preview for each file
//       const newFiles = filteredFiles?.map((file) =>
//         Object.assign(file, { preview: URL.createObjectURL(file) })
//       );

//       // Merge new files with existing files, ensuring uniqueness by file name
//       const uniqueFiles = Array.from(
//         new Map(
//           [...(files || []), ...newFiles].map((file) => [file?.name, file])
//         ).values()
//       );

//       // Update state with unique files
//       setFiles(uniqueFiles);

//       // Notify parent about the new files
//       if (typeof onFilesSelected === 'function') {
//         onFilesSelected(uniqueFiles);
//       }

//       // Handle rejected files (if any)
//       if (rejectedFiles?.length > 0) {
//         console.warn('Rejected files:', rejectedFiles);
//       }
//     } catch (error) {
//       console.error('Error processing files:', error);
//     } finally {
//       setLoading(false);
//     }
//   },
// });

//   useEffect(() => {
//     if (files?.length > 1 && uploadSingle) {
//       setSingleFile(true);
//       setErrorMessage('Only one file is allowed');
//     } else {
//       setSingleFile(false);
//       setErrorMessage('');
//     }
//   }, [files]);

//   const removeFile = (fileName) => {
//     const updatedFiles = files?.filter((file) => file.name !== fileName);
//     setFiles(updatedFiles);
//     if (typeof onFilesSelected === 'function') {
//       onFilesSelected(updatedFiles);
//     }
//   };

//   const uploadFile = async (file) => {
//     const formData = new FormData();
//     formData.append('file', file);
//     formData.append('fileTypeId', 50);

//     try {
//       return await new Promise((resolve, reject) => {
//         crud.create('/files/upload', formData, (_err, res) => {
//           if (res?.status === 200) {
//             const newFile = {
//               fileName: res?.data?.originalFileName,
//               fileId: res?.data?.fileId,
//             };
//             resolve(newFile.fileId); // Resolve with fileId
//             toast.success('File uploaded successfully');

//             setLoading(false);
//           } else {
//             toast.error('Failed to upload file.');
//             reject(new Error('Failed to upload file.'));
//             setLoading(false);
//           }
//         });
//       });
//     } catch (error) {
//       setLoading(false);

//       return null;
//     }
//   };

//   useEffect(() => {
//     if (previewdData) {
//       const parsedFiles = previewdData
//         .map((data, index) => {
//           if (typeof data === 'string' && data.includes(';base64,')) {
//             const [type] = data.split(';base64,');
//             const fileType = type.split(':')[1];
//             return {
//               type: fileType,
//               preview: data,
//               name: `File-${index + 1}`,
//               apiCallNo: false,
//             };
//           }
//           return null;
//         })
//         .filter((file) => file !== null);

//       setFiles(parsedFiles);
//     }
//   }, [previewdData]);

//   return (
//     <GlassCard>
//       {name && (
//         <Box
//           className='font-semibold p-2'
//           sx={{ borderBottom: '1px solid', borderColor: 'border.main' }}
//         >
//           {name}
//         </Box>
//       )}

//       {/* Upload Area */}
//       <Box className='flex flex-wrap gap-2 p-2'>
//         {/* Uploaded Files Preview */}

//         {files?.length > 0 &&
//           files?.map((file, index) => (
//             <Box
//               key={index}
//               sx={{
//                 width: '150px',
//                 height: '150px',
//                 border: '1px solid',
//                 borderColor: 'border.main',
//                 overflow: 'hidden',
//                 borderRadius: '5px',
//                 position: 'relative',
//                 backgroundColor: isDragActive
//                   ? 'background.light'
//                   : 'transparent',
//                 '&:hover .titles': {
//                   opacity: 1,
//                   bottom: 0,
//                   visibility: 'visible',
//                 },
//                 '&:hover .remove-icon': {
//                   opacity: 1,
//                   transform: 'scale(1)',
//                 },
//               }}
//               className='flex justify-center items-center'
//             >
//               {/* Preview for images */}
//               {file.type.startsWith('image/') ? (
//                 <Box
//                   src={file.preview}
//                   alt={file.name}
//                   sx={{
//                     width: '100%',
//                     height: '100%',
//                     objectFit: 'cover',
//                     objectPosition: 'top',
//                     marginBottom: '8px',
//                     position: 'absolute',
//                     left: 0,
//                     top: 0,
//                   }}
//                   component={'img'}
//                 />
//               ) : file.name?.endsWith('.pdf') ? (
//                 <Icon
//                   icon='teenyicons:pdf-solid'
//                   width='60'
//                   height='60'
//                   style={{ color: 'red' }}
//                 />
//               ) : (
//                 <Box color='text.light' title='Unsupported file type'>
//                   <Icon icon='lucide:file-question' width='60' height='60' />
//                 </Box>
//               )}
//               <Box
//                 sx={{
//                   overflow: 'hidden',
//                   position: 'absolute',
//                   left: '0',
//                   bottom: '0',
//                   bgcolor: 'rgb(0 0 0 / 47%)',
//                   backdropFilter: 'blur(20px)',
//                   color: '#fff',
//                   padding: '5px 6px',
//                   whiteSpace: 'nowrap',
//                   textOverflow: 'ellipsis',
//                   width: '100%',
//                   transition: '0.3s all',
//                   opacity: 0,
//                   textAlign: 'center',
//                   fontWeight: 600,
//                 }}
//                 className='titles'
//               >
//                 {file.name}
//               </Box>

//               <IconButton
//                 onClick={() => removeFile(file.name)}
//                 className='absolute top-2 right-2 p-1 remove-icon'
//                 sx={{
//                   bgcolor: 'error.main',
//                   color: '#fff',
//                   transition: '0.3s all',
//                   opacity: 0,
//                   '&:hover': {
//                     bgcolor: 'error.main',
//                     color: '#ffffff',
//                   },
//                 }}
//               >
//                 <Icon icon='material-symbols:close' width='20' height='20' />
//               </IconButton>
//             </Box>
//           ))}

//         <Button
//           {...getRootProps()}
//           className='flex justify-center items-center flex-col text-center gap-2 p-0'
//           sx={{
//             width: '150px',
//             height: '150px',
//             border: '1px dashed',
//             borderColor: 'primary.main',
//             borderRadius: '5px',
//             cursor: 'pointer',
//             bgcolor: isDragActive ? 'background.light' : 'background.mainLight',
//           }}
//         >
//           <input {...getInputProps()} />
//           <Box color={'primary.main'}>
//             <Icon icon='ph:plus-light' width='45' height='45' />
//           </Box>
//           <Box>Add Upload</Box>
//         </Button>
//       </Box>
//     </GlassCard>
//   );
// };

// export default UploadSquare;

import React, { useEffect, useState } from 'react';
import { Box, IconButton, Button, CircularProgress } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { Icon } from '@iconify/react/dist/iconify.js';
import GlassCard from '../small/GlassCard.tsx';
import { toast } from 'react-toastify';
import UploadModalFileViewer from './UploadModalFileViewer.tsx';

const UploadSquare = ({
  onFilesSelected,
  previewdData = [],
  uploadSingle = false,
  name = 'Multiple Upload File',
  fileType,
  glassCard,
  setRemovedFiles,
  disableUpload = true,
  loadingFiles = false,
}) => {
  const [files, setFiles] = useState([]);
  const [existingFiles, setExistingFiles] = useState([]);
  const allowedTypes = {
    image: 'image/jpeg,image/png,image/jpg,image/gif,image/webp',
    all: '*',
  };

  const [openFile, setOpenFile] = useState(false);

  useEffect(() => {
    if (previewdData?.length > 0) {
      const parsedFiles = previewdData
        ?.map((data, index) => {
          if (
            typeof data?.fileUrl === 'string' &&
            data?.fileUrl?.includes(';base64,')
          ) {
            const [type] = data?.fileUrl?.split(';base64,');
            const fileType = type.split(':')[1];

            return {
              type: fileType,
              preview: data?.fileUrl,
              name: `File-${index + 1}`,
              apiCallNo: false,
              isExisting: true, // Indicate it's an existing file
              fileId: data?.fileId,
            };
          }
          return null;
        })
        .filter(Boolean);
      setFiles(parsedFiles);
    }
  }, [previewdData]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: allowedTypes[fileType] || allowedTypes.all,
    multiple: true,
    onDrop: async (acceptedFiles, rejectedFiles) => {
      try {
        let filteredFiles = acceptedFiles;

        // Filter files based on fileType
        if (fileType === 'image') {
          filteredFiles = acceptedFiles.filter((file) => {
            const isImage = [
              'image/jpeg',
              'image/png',
              'image/jpg',
              'image/gif',
              'image/webp',
            ].includes(file.type);
            if (!isImage) {
              toast.error(
                `${file.name} is not a valid image type. only images are allowed`,
                { position: 'top-right' }
              );
            }
            return isImage;
          });
        }

        // Add a preview for each file
        const newFiles = filteredFiles?.map((file) =>
          Object.assign(file, { preview: URL.createObjectURL(file) })
        );

        // Merge new files with existing files, ensuring uniqueness by file name
        const uniqueFiles = Array.from(
          new Map(
            [...(files || []), ...newFiles].map((file) => [file?.name, file])
          ).values()
        );

        // Update state with unique files
        setFiles(uniqueFiles);

        // Notify parent about the new files
        if (typeof onFilesSelected === 'function') {
          onFilesSelected(uniqueFiles);
        }

        // Handle rejected files (if any)
        if (rejectedFiles?.length > 0) {
          console.warn('Rejected files:', rejectedFiles);
        }
      } catch (error) {
        console.error('Error processing files:', error);
      }
    },
  });

  const removeExistingFile = (fileName) => {
    const updatedFiles = existingFiles.filter((file) => file.name !== fileName);
    setExistingFiles(updatedFiles);
    if (typeof onFilesSelected === 'function') {
      onFilesSelected([...updatedFiles, ...files]);
    }
  };

  const removeNewFile = (file) => {
    const updatedFiles = files?.filter((f) =>
      previewdData?.length > 0
        ? f?.fileId !== file?.fileId
        : f?.name === file?.name
    );

    setFiles(updatedFiles);

    if (typeof onFilesSelected === 'function') {
      onFilesSelected(updatedFiles);
    }
  };

  return glassCard ? (
    <>
      <GlassCard>
        {name && (
          <Box
            className='font-semibold p-2'
            sx={{ borderBottom: '1px solid', borderColor: 'border.main' }}
          >
            {name}
          </Box>
        )}
        <Box className='flex flex-wrap gap-2 p-2'>
          {/* New Files Preview */}
          {files?.map((file, index) => (
            <Box
              key={`new-${index}`}
              sx={{
                position: 'relative',
                width: '150px',
                height: '150px',
                border: '1px solid',
                borderColor: 'border.main',
                borderRadius: '5px',
                overflow: 'hidden',
              }}
            >
              {file.type.startsWith('image/') ? (
                <Box
                  component='img'
                  src={file?.preview}
                  alt={file?.name}
                  sx={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                  }}
                />
              ) : (
                <Icon
                  icon='teenyicons:pdf-solid'
                  width='60'
                  height='60'
                  style={{ color: 'red' }}
                />
              )}
              <IconButton
                onClick={() => removeNewFile(file)}
                sx={{
                  position: 'absolute',
                  top: '8px',
                  right: '8px',
                  backgroundColor: 'error.main',
                  color: '#fff',
                  '&:hover': {
                    backgroundColor: 'error.dark',
                  },
                }}
              >
                <Icon icon='material-symbols:close' width='20' height='20' />
              </IconButton>
            </Box>
          ))}
          {/* Upload Button */}
          <Button
            {...getRootProps()}
            sx={{
              width: '150px',
              height: '150px',
              border: '1px dashed',
              borderColor: 'primary.main',
              borderRadius: '5px',
              cursor: 'pointer',
              bgcolor: isDragActive
                ? 'background.light'
                : 'background.mainLight',
            }}
          >
            <input {...getInputProps()} />
            <Box>
              <Icon icon='ph:plus-light' width='45' height='45' />
            </Box>
            <Box>Add Upload</Box>
          </Button>
        </Box>
      </GlassCard>
    </>
  ) : (
    <>
      <Box className='flex flex-wrap gap-2'>
        {/* New Files Preview */}
        {loadingFiles ? (
          <CircularProgress />
        ) : (
          files?.map((file, index) => (
            <Box
              key={`new-${index}`}
              sx={{
                position: 'relative',
                width: '100px',
                height: '100px',
                border: '1px solid',
                borderColor: 'border.main',
                borderRadius: '5px',
                overflow: 'hidden',
              }}
              className='flex justify-center items-center'
            >
              {file.type.startsWith('image/') ? (
                <Box
                  component='img'
                  src={file?.preview}
                  alt={file?.name}
                  sx={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                  }}
                />
              ) : (
                <>
                  <UploadModalFileViewer
                    open={openFile}
                    onClose={() => setOpenFile(!openFile)}
                    documents={[
                      {
                        uri: file?.preview,
                      },
                    ]}
                    titleEnable={file?.name}
                  />
                  <Icon
                    icon='teenyicons:pdf-solid'
                    width='60'
                    height='60'
                    style={{ color: 'red' }}
                    onClick={() => setOpenFile(!openFile)}
                    className='cursor-pointer'
                  />
                </>
              )}
              {disableUpload && (
                <IconButton
                  onClick={() => removeNewFile(file?.fileId)}
                  sx={{
                    position: 'absolute',
                    top: '8px',
                    right: '8px',
                    backgroundColor: 'error.main',
                    color: '#fff',
                    '&:hover': {
                      backgroundColor: 'error.dark',
                    },
                  }}
                  className='p-0'
                >
                  <Icon icon='material-symbols:close' width='20' height='20' />
                </IconButton>
              )}
            </Box>
          ))
        )}
        {/* Upload Button */}
        {disableUpload && (
          <Button
            {...getRootProps()}
            sx={{
              width: '100px',
              height: '100px',
              border: '1px dashed',
              borderColor: 'border.main',
              borderRadius: '5px',
              cursor: 'pointer',
              bgcolor: isDragActive
                ? 'background.mainLight'
                : 'background.light',
            }}
            className='flex flex-col justify-center items-center gap-2 p-0'
          >
            <input {...getInputProps()} />
            <Box>
              <Icon icon='ph:plus-light' width='45' height='45' />
            </Box>
            <Box>Add Upload</Box>
          </Button>
        )}
      </Box>
    </>
  );
};

export default UploadSquare;
