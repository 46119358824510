import React, { useState } from 'react';
import FmTextField from '../../../../../components/_mui/FmTextField.tsx';
import { Grid } from '@mui/material';
import GlassCard from '../../../../../components/small/GlassCard.tsx';
import { useForm } from 'react-hook-form';
import Crud_Service from '../../../../../apis/CrudService.jsx';
import { toast } from 'react-toastify';
import FmSearchableSelect from '../../../../../components/_mui/FmSearchableSelect.tsx';
import { ActionButtons } from '../../../../../components/_form';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { SalesRetentionItemvalidationSchema } from '../../../../../utils/CommonvalidationSchemas.tsx';
import EditSalesRetention from '../EditSalesRetention.tsx';
const GridSize = 2.4;

const AddViewSalesRetention = (props) => {
  const { fetchData, setEditingRowId, setEditingRowData } = props;
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(SalesRetentionItemvalidationSchema),
  });
  const crud = new Crud_Service();

  const handleSubmitSalesRetention = async (values) => {
    setLoading(true);
    const combinedData = {
      ...values,
      salesRetentionId: id,
    };

    await crud.create('salesretentionitems', combinedData, (err, res) => {
      setLoading(false);

      if (res?.status === 201) {
        toast.success('Sales Retention Item Created Successfully');
        setLoading(false);
        fetchData();
        reset();
        setEditingRowId(null);
        setEditingRowData(null);
      } else {
        setLoading(false);
      }
    });
  };

  return (
    <>
      <GlassCard className='p-3'>
        <EditSalesRetention fetchData={fetchData} id={id} />
      </GlassCard>
      <GlassCard className='p-3'>
        <form onSubmit={handleSubmit(handleSubmitSalesRetention)}>
          <Grid container spacing={2}>
            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='companyId'
                control={control}
                apiUrl='companies'
                valueField='companyId'
                headerField={['Company Code', 'Company Name']}
                labelField={['companyCode', 'companyName']}
                showField={['companyName']}
                required
                label='Company'
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='itemId'
                control={control}
                apiUrl='item'
                valueField='itemId'
                headerField={['Item Code']}
                labelField={['itemCode']}
                showField={['itemCode']}
                required
                label='Items'
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmTextField
                name='description'
                control={control}
                label='Description'
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='unitOfMeasurementId'
                control={control}
                apiUrl='unitOfMeasurements'
                valueField='unitOfMeasurementId'
                labelField={['unitOfMeasurementCode', 'unitOfMeasurementName']}
                showField={['unitOfMeasurementName']}
                required
                label={'Units'}
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmTextField
                name='quantity'
                required
                control={control}
                label='Quantity'
              />
            </Grid>

            <Grid item md={GridSize}>
              <FmTextField name='rate' control={control} label='Rate' />
            </Grid>
            <Grid item md={GridSize}>
              <FmTextField name='gross' control={control} label='Gross' />
            </Grid>
            <Grid item md={GridSize}>
              <FmTextField name='discount' control={control} label='Discount' />
            </Grid>
            <Grid item md={GridSize}>
              <FmTextField
                name='discountPercent'
                control={control}
                pattern='PercentageWithoutDecimal'
                label='Discount Percent'
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmTextField
                name='vat'
                pattern='PercentageWithoutDecimal'
                label='VAT'
                control={control}
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmTextField
                name='amountWithTax'
                label='Amount With Tax'
                control={control}
              />
            </Grid>

            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='taxId'
                control={control}
                apiUrl='taxcodes'
                valueField='taxCodeId'
                headerField={['Tax Code']}
                labelField={['taxCodeCode']}
                showField={['taxCodeCode']}
                label='Tax Code'
              />
            </Grid>

            <Grid item md={GridSize}>
              <FmTextField
                name='remarks'
                multiline={true}
                rows={2}
                maxRows={3}
                label='Remarks'
                control={control}
              />
            </Grid>
          </Grid>
          <ActionButtons
            onSubmit={handleSubmit(handleSubmitSalesRetention)}
            onReset={reset}
            submitLoading={loading}
            submitText='create'
          />
        </form>
      </GlassCard>
    </>
  );
};

export default AddViewSalesRetention;
