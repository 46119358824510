import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchMenu } from './loginSlice.ts';

const menuSlice = createSlice({
  name: 'menu',
  initialState: {
    items: null,
    loading: false,
    error: null,
  },
  reducers: {
    resetMenu: (state) => {
      state.items = null;
      state.loading = false;
      state.error = null;
    },
    setMenu: (state, action) => {
      state.items = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMenu.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchMenu.fulfilled, (state, action) => {
        state.loading = false;
        state.items = action.payload;
      })
      .addCase(fetchMenu.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { resetMenu,setMenu} = menuSlice.actions;
export default menuSlice.reducer;

