import { Icon } from '@iconify/react';
import { Box, Stack } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useLocation } from 'react-router-dom';
import { Nav } from '../utils';
import SidebarMenu from './helpers/SidebarMenu';
import SidebarSettingsDropdown from './helpers/SidebarSettingsDropdown.jsx';
import PageMenu from './PageMenu';
import { useSelector, useDispatch } from 'react-redux';
import HeaderAppDropdown from './helpers/HeaderAppDropdown.jsx';

const Sidebar = (props) => {
  const { type, open } = props;
  const menu = useSelector((state) => state?.menu?.items);
  const [activeMenuOpen, setActiveMenuOpen] = React.useState(false);
  const scrollbarRef = useRef(null);
  const location = useLocation();
  const dispatch = useDispatch();

  const handleActiveMenuLeaveOpen = () => {
    setActiveMenuOpen(false);
  };

  useEffect(() => {
    const container = scrollbarRef.current;

    if (container) {
      const activeItem = container.querySelector('.active');

      if (activeItem) {
        const { offsetTop } = activeItem;
        const { clientHeight } = container;
        container.scrollTop = offsetTop - clientHeight / 2;
      }
    }
  }, [location.pathname]);

  return (
    <>
      <Box
        sx={{
          minWidth: !open ? '220px' : '60px',
          flexBasis: !open ? '220px' : '60px',
          display: 'flex',
          height: '100%',
          flexDirection: 'column',
          zIndex: 3,
          overflow: !open && 'hidden',
          borderRadius: !open && '0px',
          borderRight: '1px solid',
          //color: '#D9D9D9',
          borderColor: 'border.main',
          // bgcolor: 'background.light',
        }}
        className='sm:hidden '
      >
        {/* <Box className='flex flex-col gap-2 p-2'>
          <Box className='flex px-2 '>
            <SidebarLogo />
          </Box>
          <HeaderSearchButton />
        </Box> */}

        <Box className='flex-1 min-h-0 h-full overflow-auto'>
          <PerfectScrollbar
            options={{ wheelPropagation: false }}
            containerRef={(ref) => (scrollbarRef.current = ref)}
          >
            <Box className='p-1.5'>
              <Stack direction='column' className='w-full' spacing={0.3}>
                {menu &&
                  menu[localStorage.getItem('menu') || 0]?.menus?.map(
                    (item, i) => {
                      return (
                        item.menuName && (
                          <SidebarMenu
                            icon={item.icon}
                            key={i}
                            name={item.menuName}
                            to={item.url}
                            open={open}
                          />
                        )
                      );
                    }
                  )}
              </Stack>
            </Box>
          </PerfectScrollbar>
        </Box>
        <Box
          className='flex flex-col pt-1'
          sx={{ borderTop: '1px solid', borderColor: 'border.main' }}
        >
          <SidebarSettingsDropdown />
        </Box>
      </Box>
      <PageMenu open={activeMenuOpen} leavePanel={handleActiveMenuLeaveOpen} />
    </>
  );
};

export default Sidebar;
