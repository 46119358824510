import React from 'react';
import ReactDOM from 'react-dom/client';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import ErrorBoundary from './components/ErrorBoundary.tsx';
import PageLoader from './components/page/PageLoader.jsx';
import './i18n.js';
import './index.css';
import { persistor, store } from './store/store.ts';
import { ThemeProvider } from './ThemeContext.jsx';
import { NavProvider } from './utils/NavProvider.tsx';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <PersistGate loading={<PageLoader />} persistor={persistor}>
      <ErrorBoundary>
        <ThemeProvider>
          <NavProvider>
            <React.Suspense fallback={<PageLoader />}>
              <App />
              <ToastContainer
                limit={3}
                autoClose={5000}
                closeOnClick
                theme='light'
              />
            </React.Suspense>
          </NavProvider>
        </ThemeProvider>
      </ErrorBoundary>
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
