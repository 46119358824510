import React from 'react';
import { Box } from '@mui/material';
import { Icon } from '@iconify/react/dist/iconify.js';

const ExpiryDateIconsImage = (value) => {
  if (value === null) {
    return {
      className: '',
      icon: '',
    };
  }

  const currentDate = new Date();
  const targetDate = new Date(value);

  const timeDiff = targetDate - currentDate;
  const diffDays = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

  if (diffDays < 0) {
    return {
      className: 'text-red-500',
      icon: <Icon icon='mdi:announcement-outline' width='18' height='18' />,
    };
  } else if (diffDays <= 180) {
    return {
      className: 'text-blue-700',
      icon: (
        <Icon icon='material-symbols:warning-outline' width='18' height='18' />
      ),
    };
  } else {
    return {
      className: 'text-green-600',
      icon: <Icon icon='line-md:check-all' width='18' height='18' />,
    };
  }
};

const TrackerExpiryDate = ({ date, value }) => {
  const ValueTracker = ExpiryDateIconsImage(value);

  return (
    <Box className={`${ValueTracker.className} flex gap-2 items-center`}>
      {ValueTracker.icon} {date}
    </Box>
  );
};

export const TrackerExpiryDateLegend = () => {
  return (
    <Box className='flex justify-end items-center gap-5 whitespace-nowrap'>
      <Box className='flex items-center gap-2 bg-red-50 text-red-500 py-1 px-2'>
        <Icon icon='mdi:announcement-outline' width='18' height='18' />{' '}
        <span className='opacity-70'>Expired</span>{' '}
      </Box>
      <Box className='flex items-center gap-2 text-blue-700 bg-blue-50 py-1 px-2 '>
        <Icon icon='material-symbols:warning-outline' width='18' height='18' />
        <span className='opacity-70'>Near to Expiry</span>{' '}
      </Box>
    </Box>
  );
};

export default TrackerExpiryDate;
