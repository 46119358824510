import { Box } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import { CreateButton } from '../../../../components/small/Buttons.jsx';
import { findNavByName } from '../../../../utils/navUtils.ts';
import WorkOrderList from './WorkOrderList.tsx';

const WorkOrder = () => {
  const [getData] = React.useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Work Order | Work Order | CAFM ';
  });
  const menu = useSelector((state) => state?.menu?.items);

  const SubMenu = useMemo(() => {
    return menu?.map((item) => item?.menus?.flat()).flat() || [];
  }, [menu]);

  const result = useMemo(
    () => findNavByName(SubMenu, 'FM Division', 'Work Order'),
    [SubMenu]
  );

  return (
    <DashboardLayout
      title='Work Order'
      hasSubmenu
      menu={result}
      actionButtons={
        <>
          <Box className='flex flex-row items-center whitespace-nowrap gap-3'>
            <CreateButton name='Create' onClick={() => navigate('create')} />
          </Box>
        </>
      }
    >
      <WorkOrderList getData={getData} />
    </DashboardLayout>
  );
};

export default WorkOrder;
