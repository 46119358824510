import { Icon } from '@iconify/react';
import { Box, Button } from '@mui/material';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Crud_Service from '../../../../apis/CrudService.jsx';
import { ViewTable } from '../../../../components/_form/FormElements.jsx';
import TableActionButtons from '../../../../components/_form/TableActionButtons.jsx';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import FmViewDateField from '../../../../components/_mui/FmViewDateField.tsx';
import MuiDialogOne from '../../../../components/_mui/MuiDialogOne.jsx';
import TableExtraContentTooltip from '../../../../components/datagrid/TableExtraContentTooltip.tsx';
import FmMRTDataTable from '../../../../components/FmMRTDataTable.tsx';
import useTableLogic from '../../../../components/helpers/MRTUseTableLogic.tsx';
import ViewColoredDate from '../../../../components/view/ViewColoredDate.tsx';
import ViewTableLightText from '../../../../components/view/ViewTableLightText.tsx';
import Icons from '../../../../utils/Icon.jsx';
import GlassCard from './../../../../components/small/GlassCard.tsx';

const BoldBox = ({ title, primary }) => {
  return (
    <Box className='font-semibold' sx={{ color: primary && 'primary.main' }}>
      {title}
    </Box>
  );
};

const CreateSalesOrderList = (props) => {
  const { type, getData } = props;
  const [viewDetails, setViewdetails] = useState(null);
  const [editingRowData, setEditingRowData] = useState(null);
  const [editingRowId, setEditingRowId] = useState(null);
  const crud = new Crud_Service();
  const [viewRow, setViewRow] = useState([]);

  const { handleSubmit, control } = useForm();
  const {
    rows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic(`salesorders?salesOrderTypeId=${type}`);

  useEffect(() => {
    fetchData();
  }, [type]);

  useEffect(() => {
    viewDetails && fetchSalesOrder();
  }, [viewDetails]);

  useEffect(() => {
    getData && fetchData();
  }, [getData]);
  const fetchSalesOrder = async () => {
    await crud.getSingle('salesorders', viewDetails, (_err, res) => {
      if (res?.status === 200) {
        setViewRow(res?.data);
      } else {
      }
    });
  };

  const handleEditItem = async (values) => {
    const data = {
      ...values,
      orderDate: dayjs(values?.orderDate).format('YYYY-MM-DD'),
      contactPersonId:
        values.contactPersonId || editingRowData?.contactPersonId,
      locationId: values.locationId || editingRowData?.locationId,
      regionId: values.regionId || editingRowData?.regionId,
      salesQuotationId:
        values.salesQuotationId || editingRowData?.salesQuotationId,
      orderStatusId: values.orderStatusId || editingRowData?.orderStatusId,
      status: 2,
      salesOrderTypeId: type,
    };
    setEditingRowId(values?.salesOrderId);
    setEditingRowData(values);
    await crud.update(
      'salesorders',
      editingRowData?.salesOrderId,
      data,
      (_err, res) => {
        if (res?.status === 200) {
          toast.success('Sales Order Updated Successfully');
          fetchData();
        } else {
        }
      }
    );
  };

  const handleDeleteSalesOrder = async (view) => {
    await crud.remove('salesorders', view?.salesOrderId, (_err, res) => {
      if (res?.status === 204) {
        fetchData();
        toast.success('Deleted Successfully');
      } else {
      }
    });
  };

  const navigate = useNavigate();
  const location = useLocation();
  const handleCancel = () => {
    setEditingRowId(null);
    setEditingRowData(null);
  };

  const ActionData = [
    {
      name: 'Edit',
      icon: Icons.edit,
      onClick: (_props) => {
        navigate(`${location.pathname}/view/${_props?.salesOrderId}`);
      },
    },
    {
      name: 'Delete',
      icon: Icons.delete,
      onClick: (props) => handleDeleteSalesOrder(props),
      danger: true,
    },
  ];

  const columns = [
    {
      header: 'Actions',
      Cell: ({ row }) => (
        <Box className='flex gap-2'>
          {editingRowId === row?.original?.salesOrderId ? (
            <>
              <Button onClick={handleSubmit(handleEditItem)}>Save</Button>
              <Button onClick={handleCancel}>Cancel</Button>
            </>
          ) : (
            <TableActionButtons
              Actions={ActionData?.map((action) => ({
                ...action,
                onClick: () => action.onClick(row?.original),
              }))}
            />
          )}
        </Box>
      ),
    },
    {
      accessorKey: 'salesOrderName',
      header: 'Sales Order',
      enableEditing: true,
      renderCell: (params) => {
        return (
          <ViewTableLightText
            name={
              <Box>
                <span className='font-semibold'>Name:</span>{' '}
                {params?.row?.salesOrderName}
              </Box>
            }
          />
        );
      },
    },
    {
      accessorKey: 'salesQuotationCode', // Searchable select for Sales Quotation
      header: 'Sales Quotation',
      enableEditing: true,

      renderCell: (params) => {
        return (
          <ViewTableLightText
            name={
              <Box>
                <span className='font-semibold'>Code:</span>{' '}
                {params?.row?.salesQuotationCode}
              </Box>
            }
          />
        );
      },
    },
    {
      accessorKey: 'divisionName', // Searchable select for Sales Quotation
      header: 'Division',
      enableEditing: true,

      renderCell: (params) => {
        return (
          <ViewTableLightText
            name={
              <Box>
                <span className='font-semibold'>Name:</span>{' '}
                {params?.row?.divisionName}
              </Box>
            }
          />
        );
      },
    },
    {
      accessorKey: 'customerId', // Searchable select for Sales Quotation
      header: 'Customer',
      enableEditing: true,
      Cell: ({ cell }) => {
        const order = rows?.find(
          (row) => row?.salesOrderId === cell?.row?.original?.salesOrderId
        );
        return editingRowId === cell?.row?.original?.salesOrderId ? (
          <FmSearchableSelect
            name='customerId'
            label='Customer'
            apiUrl={'customers'}
            valueField='customerId'
            labelField={['customerName', 'customerCode']}
            required
            showField={['customerName', 'customerCode']}
            control={control}
            defaultValue={editingRowData}
          />
        ) : (
          <BoldBox title={order?.customerName} />
        );
      },
    },
    {
      accessorKey: 'regionId', // Searchable select for Region
      header: 'Region',
      enableEditing: true,
      Cell: ({ cell }) => {
        const order = rows?.find(
          (row) => row?.salesOrderId === cell?.row?.original?.salesOrderId
        );
        return editingRowId === cell?.row?.original?.salesOrderId ? (
          <FmSearchableSelect
            name='regionId'
            control={control}
            apiUrl='regions'
            valueField='regionId'
            headerField={['regionCode', 'regionName']}
            defaultValue={editingRowData}
            labelField={['regionCode', 'regionName']}
            showField={['regionName']}
            label={'Region'}
          />
        ) : (
          <BoldBox title={order?.regionName} />
        );
      },
    },
    {
      accessorKey: 'workOrderId',
      header: 'Work Order',
      enableEditing: true,
      Cell: ({ cell }) => {
        const order = rows?.find(
          (row) => row?.salesOrderId === cell?.row?.original?.salesOrderId
        );
        return <BoldBox title={order?.workOrderName} />;
      },
    },
    {
      accessorKey: 'orderDate',
      header: 'Date',
      enableEditing: true,
      renderCell: (params) => {
        return (
          <TableExtraContentTooltip
            titleRows={[
              { label: 'Order Date', title: params?.row?.orderDate },
              { label: 'Created Date', title: params?.row?.createdDate },
              { label: 'Updated Value', title: params?.row?.updatedDate },
            ]}
          >
            <ViewColoredDate
              date={FmViewDateField({ value: params?.value })}
              originalDate={params?.value}
              icon={<Icon icon='mdi-light:calendar' />}
            />
          </TableExtraContentTooltip>
        );
      },
    },

    {
      accessorKey: 'regionId',
      header: 'Region',
      enableEditing: true,
      Cell: ({ cell }) => {
        const order = rows?.find(
          (row) => row?.salesOrderId === cell?.row?.original?.salesOrderId
        );
        return editingRowId === cell?.row?.original?.salesOrderId ? (
          <FmSearchableSelect
            name='regionId'
            control={control}
            apiUrl='regions'
            valueField='regionId'
            headerField={['regionCode', 'regionName']}
            defaultValue={editingRowData}
            labelField={['regionCode', 'regionName']}
            showField={['regionName']}
            label={'Region'}
          />
        ) : (
          <BoldBox title={order?.regionName} />
        );
      },
    },
    {
      accessorKey: 'locationId',
      header: 'Location',
      enableEditing: true,
      Cell: ({ cell }) => {
        const order = rows?.find(
          (row) => row?.salesOrderId === cell?.row?.original?.salesOrderId
        );
        return editingRowId === cell?.row?.original?.salesOrderId ? (
          <FmSearchableSelect
            name='locationId'
            control={control}
            apiUrl='locations'
            valueField='locationId'
            headerField={['locationCode', 'locationName']}
            defaultValue={editingRowData}
            labelField={['locationCode', 'locationName']}
            showField={['locationName']}
            label={'Location'}
          />
        ) : (
          <BoldBox title={order?.locationName} />
        );
      },
    },
    {
      accessorKey: 'paymentTerms', // Payment Terms field
      header: 'Payment Terms',
      enableEditing: true,
      Cell: ({ cell }) => {
        const order = rows?.find(
          (row) => row?.salesOrderId === cell?.row?.original?.salesOrderId
        );
        return editingRowId === cell?.row?.original?.salesOrderId ? (
          <FmTextField
            name='paymentTerms'
            control={control}
            defaultValue={editingRowData?.paymentTerms}
            label='Payment Terms'
          />
        ) : (
          <BoldBox title={order?.paymentTerms} />
        );
      },
    },
    {
      accessorKey: 'narration',
      header: 'Narration',
      enableEditing: true,
      Cell: ({ cell }) => {
        const order = rows?.find(
          (row) => row?.salesOrderId === cell?.row?.original?.salesOrderId
        );
        return editingRowId === cell?.row?.original?.salesOrderId ? (
          <FmTextField
            name='narration'
            control={control}
            defaultValue={editingRowData?.narration}
            label='Narration'
          />
        ) : (
          <BoldBox title={order?.narration} />
        );
      },
    },
    {
      accessorKey: 'deliveryTerms',
      header: 'Delivery Terms',
      enableEditing: true,
      Cell: ({ cell }) => {
        const order = rows?.find(
          (row) => row?.salesOrderId === cell?.row?.original?.salesOrderId
        );
        return editingRowId === cell?.row?.original?.salesOrderId ? (
          <FmTextField
            name='deliveryTerms'
            control={control}
            defaultValue={editingRowData?.deliveryTerms}
            label='Delivery Terms'
          />
        ) : (
          <BoldBox title={order?.deliveryTerms} />
        );
      },
    },
    {
      accessorKey: 'subject',
      header: 'Subject',
      enableEditing: true,
      Cell: ({ cell }) => {
        const order = rows?.find(
          (row) => row?.salesOrderId === cell?.row?.original?.salesOrderId
        );
        return editingRowId === cell?.row?.original?.salesOrderId ? (
          <FmTextField
            name='subject'
            control={control}
            defaultValue={editingRowData?.subject}
            label='Subject'
          />
        ) : (
          <BoldBox title={order?.subject} />
        );
      },
    },
    {
      accessorKey: 'notes',
      header: 'Notes',
      enableEditing: true,
      Cell: ({ cell }) => {
        const order = rows?.find(
          (row) => row?.salesOrderId === cell?.row?.original?.salesOrderId
        );
        return editingRowId === cell?.row?.original?.salesOrderId ? (
          <FmTextField
            name='notes'
            control={control}
            defaultValue={editingRowData?.notes}
            label='Notes'
          />
        ) : (
          <BoldBox title={order?.notes} />
        );
      },
    },
    {
      accessorKey: 'validity',
      header: 'Validity',
      enableEditing: true,
      Cell: ({ cell }) => {
        const order = rows?.find(
          (row) => row?.salesOrderId === cell?.row?.original?.salesOrderId
        );
        return editingRowId === cell?.row?.original?.salesOrderId ? (
          <FmTextField
            name='validity'
            control={control}
            defaultValue={editingRowData?.validity}
            label='Validity'
          />
        ) : (
          <BoldBox title={order?.validity} />
        );
      },
    },

    {
      accessorKey: 'remarks',
      header: 'Remarks',
      enableEditing: true,
      Cell: ({ cell }) => {
        const order = rows?.find(
          (row) => row?.salesOrderId === cell?.row?.original?.salesOrderId
        );
        return editingRowId === cell?.row?.original?.salesOrderId ? (
          <FmTextField
            name='remarks'
            control={control}
            defaultValue={editingRowData?.remarks}
            label='Remarks'
          />
        ) : (
          <BoldBox title={order?.remarks} />
        );
      },
    },
    {
      accessorKey: 'attn',
      header: 'Attn',
      enableEditing: true,
      Cell: ({ cell }) => {
        const order = rows?.find(
          (row) => row?.salesOrderId === cell?.row?.original?.salesOrderId
        );
        return editingRowId === cell?.row?.original?.salesOrderId ? (
          <FmTextField
            name='attn'
            control={control}
            defaultValue={editingRowData?.attn}
            label='Attn'
          />
        ) : (
          <BoldBox title={order?.attn} />
        );
      },
    },
    {
      accessorKey: 'contactPersonId',
      header: 'Contact Person',
      enableEditing: true,
      Cell: ({ cell }) => {
        const order = rows?.find(
          (row) => row?.salesOrderId === cell?.row?.original?.salesOrderId
        );
        return editingRowId === cell?.row?.original?.salesOrderId ? (
          <FmSearchableSelect
            name='contactPersonId'
            control={control}
            apiUrl='loginusers'
            valueField='loginUserId'
            defaultValue={editingRowData}
            labelField={['employeeCode', 'loginUserName']}
            showField={['employeeCode', 'loginUserName']}
            label={'Contact Person'}
          />
        ) : (
          <BoldBox title={order?.contactPersonName} />
        );
      },
    },
    {
      accessorKey: 'contactPersonId',
      header: 'Contact Person',
      enableEditing: true,
      Cell: ({ cell }) => {
        const order = rows?.find(
          (row) => row?.salesOrderId === cell?.row?.original?.salesOrderId
        );
        return editingRowId === cell?.row?.original?.salesOrderId ? (
          <FmSearchableSelect
            name='contactPersonId'
            control={control}
            apiUrl='loginusers'
            valueField='loginUserId'
            defaultValue={{
              loginUserId: editingRowData?.contactPersonId,
              loginUserName: editingRowData?.contactPersonName,
            }}
            labelField={['employeeCode', 'loginUserName']}
            showField={['employeeCode', 'loginUserName']}
            label={'Contact Person'}
          />
        ) : (
          <BoldBox title={order?.contactPersonName} />
        );
      },
    },
    {
      accessorKey: 'orderStatusId',
      header: 'Order Status',
      enableEditing: true,
      Cell: ({ cell }) => {
        const order = rows?.find(
          (row) => row?.salesOrderId === cell?.row?.original?.salesOrderId
        );
        return editingRowId === cell?.row?.original?.salesOrderId ? (
          <FmSearchableSelect
            name='orderStatusId'
            control={control}
            apiUrl='salesorders/statuses'
            valueField='salesOrderStatusId'
            headerField={['salesOrderStatusName']}
            defaultValue={{
              salesOrderStatusId: editingRowData?.orderStatusId,
              salesOrderStatusName: editingRowData?.orderStatusName,
            }}
            labelField={['salesOrderStatusName']}
            showField={['salesOrderStatusName']}
            label={'Order Status'}
          />
        ) : (
          <BoldBox title={order?.orderStatusName} />
        );
      },
    },
  ];

  return (
    <>
      <GlassCard className='h-full-css'>
        <FmMRTDataTable
          columns={columns}
          enableRowSelection={false}
          rows={rows}
          rowCount={tableRecordCounts}
          editingRow={editingRowId}
          pagination={pagination}
          sorting={sorting}
          fetchData={fetchData}
          columnFilters={columnFilters}
          globalFilter={globalFilter}
          setPagination={setPagination}
          setSorting={setSorting}
          setColumnFilters={setColumnFilters}
          setGlobalFilter={setGlobalFilter}
          isLoading={isLoading}
          setSearchKeyword={setSearchKeyword}
          searchKeyword={searchKeyword}
          muiTableContainerProps={{
            sx: {
              maxHeight: '100%',
              flex: 1,
            },
          }}
        />
      </GlassCard>
      <MuiDialogOne
        title='View Sales Order Details'
        open={viewDetails !== null}
        onClose={() => setViewdetails(null)}
      >
        <ViewTable
          rows={[
            {
              label: 'Sales Order Name',
              title: viewRow?.salesOrderName,
            },
            {
              label: 'Order Date',
              title: viewRow?.orderDate,
            },
            {
              label: 'Region',
              title: viewRow?.regionName,
            },
            {
              label: 'Payment Terms',
              title: viewRow?.paymentTerms,
            },
          ]}
        />
      </MuiDialogOne>
    </>
  );
};

export default CreateSalesOrderList;
