import React, { useEffect } from 'react';
import DashboardLayout from './../../../components/DashboardLayout.tsx';
import DataTable from './../../../components/DataTable.tsx';
import GlassCard from './../../../components/small/GlassCard.tsx';
import { CreateButton } from '../../../components/small/Buttons.jsx';
import { Box } from '@mui/material';
import Crud_Service from '../../../apis/CrudService.jsx';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useTableLogic from '../../../components/helpers/UseTableLogic.tsx';
import TableSearch from '../../../components/_form/TableSearch.jsx';
import FmViewDateField from '../../../components/_mui/FmViewDateField.tsx';
import { toast } from 'react-toastify';
import TableActionButtons from './../../../components/_form/TableActionButtons.jsx';
import Icons from '../../../utils/Icon.jsx';

const TenderInspectionChecklist = () => {
  const crud = new Crud_Service();
  const { t } = useTranslation();
  const {
    rows,
    pageSize,
    pageNumber,
    setSearchKeyword,
    isLoading,
    tableRecordCounts,
    searchKeyword,
    handlePaginationModelChange,
    handleSortModelChange,
    fetchData,
  } = useTableLogic('tenderinspectionchecklists');
  const navigate = useNavigate();

  useEffect(() => {
    document.title = `Presales | Tender Inspection Checklist`;
  }, []);

  const handleEditClick = async (props) => {
    navigate(`edit/${props?.tenderInspectionCheckListId}`);
  };

  const handleViewClick = async (view) => {
    navigate(`view/${view?.tenderInspectionCheckListId}`);
  };

  //Delete The Tender CheckList
  const handleDelete = async (props) => {
    await crud.remove(
      `tenderinspectionchecklists`,
      props?.tenderInspectionCheckListId,
      (_err, res) => {
        if (res?.status === 204) {
          fetchData();
          toast.success('Tender Checklist Deleted successfully');
        } else {
        }
      }
    );
  };

  const ActionData = [
    {
      name: 'View Checklist',
      icon: Icons.view,
      onClick: (props) => handleViewClick(props),
    },
    {
      name: t('edit'),
      icon: Icons.edit,
      onClick: (props) => handleEditClick(props),
    },
    {
      name: 'Delete',
      icon: Icons.delete,
      onClick: (props) => handleDelete(props),
      danger: true,
    },
  ];

  const columns = [
    {
      field: 'action',
      headerName: '',
      width: 130,
      sortable: false,
      hideable: false,
      disableClickEventBubbling: false,
      renderCell: (params) => {
        return (
          <TableActionButtons
            Actions={ActionData?.map((action) => ({
              ...action,
              onClick: () => action.onClick(params.row),
            }))}
            selectedRow={params.row}
            setSelectedRow={() => {}}
          />
        );
      },
    },
    {
      field: 'tenderName',
      headerName: 'Tender',
      minWidth: '200',
    },
    {
      field: 'tenderScopeSubject',
      headerName: 'Tender Scope Subject',
      minWidth: '200',
    },
    {
      field: 'tenderRefNo',
      headerName: 'Tender Ref.No',
      minWidth: '200',
    },
    {
      field: 'siteVisitDate',
      headerName: 'Site Visited Date',
      width: '150',
      renderCell: (params) => {
        return FmViewDateField({ value: params?.value });
      },
    },
    {
      field: 'clientName',
      headerName: 'Client',
      width: '200',
    },
    {
      field: 'existServiceProvider',
      headerName: 'Exist Service Provider',
      minWidth: '200',
    },

    {
      field: 'locationName',
      headerName: 'Location',
      width: 220,
    },
    {
      field: 'buildingName',
      headerName: 'Building',
      width: 220,
    },
    {
      field: 'existingManpowerDetail',
      headerName: 'Existing Manpower Detail',
      width: 220,
    },
    {
      field: 'createdDate',
      headerName: 'Created Date',
      width: '160',
      renderCell: (params) => {
        return FmViewDateField({ value: params?.value });
      }
    },
    {
      field: 'updatedDate',
      headerName: 'Updated Date',
      width: '160',
      renderCell: (params) => {
        return FmViewDateField({ value: params?.value });
      }
    },
  ];

  const onRowDoubleClick = (e) => {
    if (e?.row) {
      handleEditClick(e?.row);
    }
  };

  return (
    <DashboardLayout
      title={'Tender Inspection Checklist'}
      actionButtons={
        <>
          <CreateButton
            name='Create'
            onClick={() => {
              navigate('create');
            }}
          />
        </>
      }
    >
      {!false && (
        <GlassCard className='overflow-hidden h-full-css'>
          <Box className='h-full-css'>
            <DataTable
              rows={rows}
              columns={columns}
              loading={isLoading}
              onRowDoubleClick={(e) => onRowDoubleClick(e)}
              sortingMode='server'
              paginationMode='server'
              onPaginationModelChange={(model) =>
                handlePaginationModelChange(model)
              }
              onSortModelChange={(model) => handleSortModelChange(model)}
              page={pageNumber - 1}
              pageSize={pageSize}
              rowCount={tableRecordCounts?.total}
              slots={{
                toolbar: () => (
                  <Box
                    className='p-2 w-full flex justify-between items-center'
                    sx={{
                      borderBottom: '1px solid',
                      borderColor: 'border.main',
                    }}
                  >
                    <Box sx={{ maxWidth: '350px', width: '100%' }}>
                      <TableSearch
                        placeholder='Search'
                        fullWidth
                        setSearchKeyword={setSearchKeyword}
                        searchValue={searchKeyword}
                      />
                    </Box>
                  </Box>
                ),
              }}
            />
          </Box>
        </GlassCard>
      )}
    </DashboardLayout>
  );
};

export default TenderInspectionChecklist;
