import { Box } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import useTableLogic from '../../../../components/helpers/MRTUseTableLogic.tsx';
import { CreateButton } from '../../../../components/small/Buttons.jsx';
import Nav from '../../../../utils/Nav.tsx';
import ListCashBankReceipt from './ListCashBankReceipt.tsx';
import { useSelector } from 'react-redux';
import { findNavByName } from '../../../../utils/navUtils.ts';

const CashBankReceipts = (props) => {
  const { type } = props;
  const {
    rows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic(`financialtransactions?typeId=${type}`);

  useEffect(() => {
    document.title = (() => {
      switch (type) {
        case 1:
          return 'Receipts | Cash and Bank | Financial';
        case 2:
          return 'Payments | Cash and Bank | Financial';
        case 3:
          return 'Petty Cash | Cash and Bank | Financial';
        case 4:
          return 'PDReceipt | Cash and Bank | Financial';
        case 5:
          return 'PDPayment | Cash and Bank | Financial';
        case 6:
          return 'Petty Cash Project | Cash and Bank | Financial';
        case 7:
          return 'Matured PDC PV | Cash and Bank | Financial';
        default:
          return 'Cash and Bank | Financial';
      }
    })();
  });
  const navigate = useNavigate();
  useEffect(() => {
    fetchData();
  }, [type]);
  const menu = useSelector((state) => state?.menu?.items);
  
  // Flatten menu only once and store it for reuse
  const SubMenu = useMemo(() => {
    return menu?.map(item => item?.menus?.flat()).flat() || [];
  }, [menu]);

  // Use findNavByName to get the "Accounts Receivable" menu or its child menus
  const result = useMemo(() => findNavByName(SubMenu,"Financial", "Cash and Bank"), [SubMenu]);

  return (
    <DashboardLayout
      title={`${[
        'Receipts',
        'Payments',
        'Petty Cash',
        'Post Dated Receipt',
        'Post Dated Payment',
        'Petty Cash - Project',
        'Matured PDC PV',
      ][type - 1]
        }`}
      hasSubmenu
      menu={result}
      actionButtons={
        <>
          <Box className='flex flex-row items-center whitespace-nowrap gap-3'>
            <CreateButton name='Create' onClick={() => navigate('create')} />
          </Box>
        </>
      }
    >
      <ListCashBankReceipt
        type={type}
        rows={rows}
        tableRecordCounts={tableRecordCounts}
        pagination={pagination}
        sorting={sorting}
        fetchData={fetchData}
        setPagination={setPagination}
        setSorting={setSorting}
        columnFilters={columnFilters}
        globalFilter={globalFilter}
        setColumnFilters={setColumnFilters}
        setGlobalFilter={setGlobalFilter}
        isLoading={isLoading}
        setSearchKeyword={setSearchKeyword}
        searchKeyword={searchKeyword}
      />
    </DashboardLayout>
  );
};

export default CashBankReceipts;
