import React, { useMemo } from 'react';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import HRMSEmployeeDashboard from './HRMSEmployeeDashboard';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { findNavByName } from '../../../utils/navUtils.ts';
const HRMSEmployeeMasterDashboard = () => {
  const { t } = useTranslation();
  const menu = useSelector((state) => state?.menu?.items);

  const SubMenu = useMemo(() => {
    return menu?.map((item) => item?.menus?.flat()).flat() || [];
  }, [menu]);


  const result = useMemo(() => findNavByName(SubMenu, 'Employee'), [SubMenu]);

  return (
    <DashboardLayout title={t('employee_dashboard')} menu={result} hasSubmenu>
      <HRMSEmployeeDashboard />
    </DashboardLayout>
  );
};

export default HRMSEmployeeMasterDashboard;
