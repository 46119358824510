import React from 'react';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { useNavigate, useParams } from 'react-router-dom';
import CommonView from '../../../components/helpers/CommonView.jsx';
import DynamicViewFields from '../../../components/helpers/DynamicViewFields.jsx';
import { CreateButton } from '../../../components/small/Buttons.jsx';
import ActionButtons from '../../../components/_form/ActionButtons.tsx';

const ViewUniformReturn = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const excludeKeys = [
    'employeeId',
    'uniformAndAccessoriesRequisitionId',
    'uniformAndAccessoriesRequisitionReturnId',
    'createdDate',
    'updatedDate',
    'uniformSizeName',
    'quantity',
    'uniformTypeName',
    'statusName',
    'status',
  ];

  return (
    <DashboardLayout
      title='View Uniform and Accessories-Return'
      actionButtons={
        <CreateButton
          name='Create'
          onClick={() =>
            navigate('/admin/uniform-accessories-requistions/return/create')
          }
        />
      }
    >
      <GlassCard className='p-5'>
        <CommonView
          url='uniformandaccessoriesrequisitionreturns'
          id={id}
          excludeKeys={excludeKeys}
          renderFields={(data, fields) => (
            <DynamicViewFields data={data} fields={fields} />
          )}
        />
        <ActionButtons
          cancelLoading={false}
          cancelText='Cancel'
          submitText='Edit'
          onSubmit={() =>
            navigate(`/admin/uniform-accessories-requistions/return/edit/${id}`)
          }
          onCancel={() =>
            navigate('/admin/uniform-accessories-requistions/return')
          }
        />
      </GlassCard>
    </DashboardLayout>
  );
};

export default ViewUniformReturn;
