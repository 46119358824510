import React from 'react';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { Grid } from '@mui/material';
import Crud_Service from '../../../apis/CrudService.jsx';
import FmSearchableSelect from '../../../components/_mui/FmSearchableSelect.tsx';
import dayjs from 'dayjs';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import ActionButtons from '../../../components/_form/ActionButtons.tsx';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import { toast } from 'react-toastify';
import FmDatePicker from '../../../components/_mui/FmDatePicker.tsx';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { uniformRequsitionReturnSchema } from '../../../utils/CommonvalidationSchemas.tsx';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import { useNavigate } from 'react-router-dom';

const GridValue = 2.4;

const AddUniformReturn = (props) => {
  const crud = new Crud_Service();
  const { handleSubmit, control, reset } = useForm({
    resolver: yupResolver(uniformRequsitionReturnSchema),
    mode: 'onChange',
  });
  const navigate = useNavigate();
  const { startCreateLoading, stopCreateLoading, createLoading } = useLoader();

  const handleSUbmitUniformReturn = async (values) => {
    startCreateLoading();
    const combinedData = {
      ...values,
      requisitionDate: dayjs(values?.requisitionDate).format('YYYY-MM-DD'),
    };
    await crud.create(
      'uniformandaccessoriesrequisitionreturns',
      combinedData,
      (err, res) => {
        if (res?.status === 201) {
          toast.success('Uniform and Accessories Return Created Successfully');
          reset();
          stopCreateLoading();
          navigate(-1);
        } else {
          stopCreateLoading();
        }
      }
    );
  };

  return (
    <DashboardLayout title='Add Uniform and Accessories-Return'>
      <GlassCard className='mb-3 p-3'>
        <form onSubmit={handleSubmit(handleSUbmitUniformReturn)}>
          <Grid container spacing={2}>
            <Grid item md={GridValue}>
              <FmSearchableSelect
                name='employeeId'
                control={control}
                apiUrl='employees'
                valueField='employeeId'
                labelField={['employeeCode', 'firstName']}
                showField={['employeeCode', 'firstName']}
                label={'Employees'}
                required
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmSearchableSelect
                name='uniformAndAccessoriesRequisitionId'
                control={control}
                apiUrl='uniformandaccessoriesrequisitions'
                valueField='uniformAndAccessoriesRequisitionId'
                headerField={['uniformAndAccessoriesRequisitionCode']}
                labelField={['uniformAndAccessoriesRequisitionCode']}
                showField={['uniformAndAccessoriesRequisitionCode']}
                label={'Uniform And Accessories Requisition'}
                required
              />
            </Grid>

            <Grid item md={GridValue}>
              <FmDatePicker
                name='requisitionDate'
                control={control}
                label='Request Date'
              />
            </Grid>

            <Grid item md={GridValue}>
              <FmTextField name='qrCode' control={control} label='QR Code' />
            </Grid>
            <Grid item md={6}>
              <FmTextField
                name='remarks'
                control={control}
                label='Remarks'
                multiline={true}
                rows={2}
                maxRows={3}
              />
            </Grid>
          </Grid>
          <ActionButtons
            onSubmit={handleSubmit(handleSUbmitUniformReturn)}
            onReset={reset}
            submitLoading={createLoading}
            submitText='Create'
            cancelText='Cancel'
            onCancel={() => navigate(-1)}
          />
        </form>
      </GlassCard>
    </DashboardLayout>
  );
};

export default AddUniformReturn;
