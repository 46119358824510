import React from 'react';

const ViewFieldsHori = ({ label, value }) => {
  return (
    <div className='flex gap-1'>
      <span className='font-semibold'>{label}</span> : {value}
    </div>
  );
};

export default ViewFieldsHori;
