import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Crud_Service from '../../../../apis/CrudService.jsx';
import ActionButtons from '../../../../components/_form/ActionButtons.tsx';
import FmDatePicker from '../../../../components/_mui/FmDatePicker.tsx';
import FmFileInput from '../../../../components/_mui/FmFileInput.tsx';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import { CBPettyCashValidationSchema } from '../../../../utils/CommonvalidationSchemas.tsx';

const GridSize = 2.4;

const AddCashBankReceipt = (props) => {
  const { type } = props;
  const crud = new Crud_Service();
  const { startCreateLoading, stopCreateLoading } = useLoader();

  const { control, handleSubmit, reset, getValues, setValue } = useForm({
    resolver: yupResolver(CBPettyCashValidationSchema),
    mode: 'onChange',
  });
  const { createLoading } = useLoader();
  const navigate = useNavigate();
  const [fileId, setFileId] = useState(null);
  const handleSubmitData = async (values) => {
    startCreateLoading();
    const combinedData = {
      ...values,
      paymentStatusId: 1,
      requestDate: dayjs(values?.requestDate).format('YYYY-MM-DD'),
      chequeDate: dayjs(values?.chequeDate).format('YYYY-MM-DD'),
      maturityDate: dayjs(values?.maturityDate).format('YYYY-MM-DD'),
      fileId: fileId,
      typeId: type,
    };
    await crud.create('financialtransactions', combinedData, (err, res) => {
      stopCreateLoading();
      if (err) {
        return;
      }
      if (res?.status === 201) {
        toast.success('Added successfully');
        navigate('../view/' + res?.data?.financialTransactionId);
        reset();
      } else {
      }
    });
  };
  const [loading, setLoading] = useState(false);

  const handleFileUpload = async (event) => {
    setLoading(true);
    const file = event.target.files[0];
    if (file) {
      const fileFormData = new FormData();
      fileFormData.append('file', file);
      fileFormData.append('fileTypeId', 33);

      try {
        const response = await new Promise((resolve, reject) => {
          crud.create('files/upload', fileFormData, (_err, res) => {
            if (res?.status === 200) {
              resolve(res);

              setLoading(false);
            } else {
              reject(new Error('File upload failed'));
              setLoading(false);
            }
          });
        });

        setFileId(response?.data?.fileId);
        setValue(`documentName`, response?.data?.originalFileName);
      } catch (error) {
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
  };
  return (
    <DashboardLayout
      title={`${type === 1 ? 'Receipt' : type === 2 ? 'Payments' : 'Petty Cash'
        }`}
    >
      <GlassCard className='p-3'>
        <form onSubmit={handleSubmit(handleSubmitData)}>
          <Grid container spacing={2}>
            <Grid item md={GridSize}>
              <FmTextField
                name='financialTransactionCode'
                control={control}
                label='Code'
              />
            </Grid>

            <Grid item md={GridSize}>
              <FmDatePicker
                name='requestDate'
                label='Request Date'
                control={control}
                required
              />
            </Grid>

            <Grid item md={GridSize}>
              <FmDatePicker
                name='maturityDate'
                label='Maturity Date'
                control={control}
                required
              />
            </Grid>

            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='bankId'
                control={control}
                apiUrl='banks'
                valueField='bankId'
                headerField={['Bank Code', 'Bank Name']}
                labelField={['bankCode', 'bankName']}
                showField={['bankName']}
                label='Bank'
              />
            </Grid>

            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='companyId'
                control={control}
                apiUrl='companies'
                valueField='companyId'
                headerField={['Company Code', 'Company Name']}
                labelField={['companyCode', 'companyName']}
                showField={['companyName']}
                label='Company'
              />
            </Grid>

            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='branchId'
                control={control}
                apiUrl='branches'
                valueField='branchId'
                labelField={['branchName']}
                showField={['branchName']}
                label={'Branch'}
              />
            </Grid>
            {type === 1 && (
              <Grid item md={GridSize}>
                <FmSearchableSelect
                  name='salesInvoiceId'
                  control={control}
                  apiUrl='salesinvoices'
                  valueField='salesInvoiceId'
                  labelField={['salesInvoiceCode']}
                  showField={['salesInvoiceCode']}
                  label={'Sales Invoice'}
                />
              </Grid>
            )}
            {(type === 2 || type === 3) && (
              <>
                <Grid item md={GridSize}>
                  <FmSearchableSelect
                    name='placeOfSupplyId'
                    control={control}
                    apiUrl='placeofsupplies'
                    valueField='placeOfSupplyId'
                    labelField={['placeOfSupplyName']}
                    showField={['placeOfSupplyName']}
                    label={'Place Of Supply'}
                  />
                </Grid>

                <Grid item md={GridSize}>
                  <FmSearchableSelect
                    name='jurisdictionId'
                    control={control}
                    apiUrl='jurisdictions'
                    valueField='jurisdictionId'
                    labelField={['jurisdictionName']}
                    showField={['jurisdictionName']}
                    label={'Jurisdiction'}
                  />
                </Grid>
                <Grid item md={GridSize}>
                  <FmSearchableSelect
                    name='salesOrderId'
                    control={control}
                    apiUrl='salesorders'
                    valueField='salesOrderId'
                    labelField={['salesOrderName']}
                    showField={['salesOrderName']}
                    label={'Sales Order'}
                  />
                </Grid>
                <Grid item md={GridSize}>
                  <FmSearchableSelect
                    name='salesOrderDistributionId'
                    control={control}
                    apiUrl='salesorderdistributions'
                    valueField='salesOrderDistributionId'
                    labelField={['salesOrderDistributionCode']}
                    showField={['salesOrderDistributionCode']}
                    label={'Sales Order Distribution'}
                  />
                </Grid>
              </>
            )}

            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='payeeUserId'
                control={control}
                apiUrl='loginusers'
                valueField='loginUserId'
                labelField={['employeeCode', 'loginUserName']}
                showField={['employeeCode', 'loginUserName']}
                pageSize={50}
                label='Payee User'
              />
            </Grid>

            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='currencyId'
                control={control}
                apiUrl='currencies'
                valueField='currencyId'
                headerField={['Currency Code', 'Currency Name']}
                labelField={['currencyCode', 'currencyName']}
                showField={['currencyName']}
                label='Currency'
              />
            </Grid>

            <Grid item md={GridSize}>
              <FmTextField
                name='chequeNo'
                control={control}
                label='Cheque No'
              />
            </Grid>

            <Grid item md={GridSize}>
              <FmDatePicker
                name='chequeDate'
                label='Cheque Date'
                control={control}
                required
              />
            </Grid>

            <Grid item md={GridSize}>
              <FmTextField name='pdcNo' control={control} label='PDC No' />
            </Grid>
            <Grid item md={GridSize}>
              <FmTextField
                name='narration'
                multiline={true}
                rows={2}
                maxRows={3}
                control={control}
                label='Narration'
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmTextField
                name='existLock'
                multiline={true}
                rows={2}
                maxRows={3}
                control={control}
                label='Exist Lock'
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmFileInput
                name={`fileId`}
                documentName={getValues(`documentName`)}
                onChange={(e) => handleFileUpload(e)}
                loading={loading}
              />
            </Grid>
          </Grid>

          <ActionButtons
            onSubmit={handleSubmit(handleSubmitData)}
            onReset={reset}
            submitLoading={createLoading}
            onCancel={() => navigate('../')}
            cancelText='Go Back'
            submitText='Create'
          />
        </form>
      </GlassCard>
    </DashboardLayout>
  );
};

export default AddCashBankReceipt;
