import './Loader.scss';
import ElementLoader from '../../../../components/page/ElementLoader.tsx';

const Loader = ({ isLoading }) => {
  if (isLoading) {
    return (
      <div className='fm-loader'>
        <ElementLoader color='black' type='spokes' height={50} width={50} />
      </div>
    );
  }
};

export default Loader;
