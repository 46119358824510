import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import { Box, Grid } from '@mui/material';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import ActionButtons from '../../../../components/_form/ActionButtons.tsx';
import { useNavigate, useParams } from 'react-router-dom';
import Crud_Service from '../../../../apis/CrudService.jsx';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import CommonLoader from '../../../../components/page/CommonLoader.jsx';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import { toast } from 'react-toastify';
import FmAutoComplete from '../../../../components/_mui/FmAutoComplete.tsx';
import dayjs from 'dayjs';
import useCommonFetchApi from '../../../../components/helpers/useCommonFetchApi.tsx';
import { CreateButton } from '../../../../components/small/Buttons.jsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { companyinsurancepolicycomparisonsSchema } from '../../../../utils/CommonvalidationSchemas.tsx';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import FmDatePicker from '../../../../components/_mui/FmDatePicker.tsx';

const EditComparisonPolicy = () => {
  const navigate = useNavigate();
  const crud = new Crud_Service();
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const GridValue = 2.4;
  const [insurerSelections, setInsurerSelections] = useState([
    { id: '', amount: '' },
    { id: '', amount: '' },
    { id: '', amount: '' },
  ]);
  const [editData, setEditData] = useState(null);
  const {
    handleSubmit,
    control,
    setValue,
    reset,
    trigger,
    watch,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(companyinsurancepolicycomparisonsSchema),
  });
  const { editLoading, startEditLoading, stopEditLoading } = useLoader();
  const { t } = useTranslation();

  //get All Apis
  const { data: insurancePolicy } = useCommonFetchApi(
    'companyinsurancepolicies'
  );

  const { data: typeOfProduct } = useCommonFetchApi(
    'companyinsurancepolicytypeofproducts'
  );
  const { data: premiumAmount } = useCommonFetchApi(
    'companyinsurancepolicypremiums'
  );

  useEffect(() => {
    getComparisonPolicy();
  }, []);

  const handleFirstInsurerChange = (event) => {
    const selectedInsurerId = event?.companyInsurancePolicyInsurerId;
    const selectedPremium = premiumAmount.find(
      (premium) => premium.companyInsurancePolicyInsurerId === selectedInsurerId
    );
    setValue('firstInsurerAmount', selectedPremium?.amount || 0);
  };
  const handleSecondInsurerChange = (event) => {
    const selectedInsurerId = event?.companyInsurancePolicyInsurerId;
    const selectedPremium = premiumAmount.find(
      (premium) => premium.companyInsurancePolicyInsurerId === selectedInsurerId
    );
    setValue('secondInsurerAmount', selectedPremium?.amount || 0);
  };
  const handleThirdInsurerChange = (event) => {
    const selectedInsurerId = event?.companyInsurancePolicyInsurerId;
    const selectedPremium = premiumAmount.find(
      (premium) => premium.companyInsurancePolicyInsurerId === selectedInsurerId
    );
    setValue('thirdInsurerAmount', selectedPremium?.amount || 0);
  };
  //   Setting the updated client values
  const getComparisonPolicy = async () => {
    setLoading(true);
    await crud.getSingle(
      'companyinsurancepolicycomparisons',
      id,
      (_err, res) => {
        if (res?.status === 200) {
          const data = res?.data;
          Object.entries({
            ...data,
          }).forEach(([key, value]) => {
            setValue(key, value);
          });

          // Set insurer selections
          setInsurerSelections([
            { id: data.firstInsurer, amount: data.firstInsurerAmount },
            { id: data.secondInsurer, amount: data.secondInsurerAmount },
            { id: data.thirdInsurer, amount: data.thirdInsurerAmount },
          ]);
          setEditData(data);
          setLoading(false);
        } else {
          setLoading(false);

        }
      }
    );
  };

  const handleUpdateComparisonPolicy = async (values) => {
    startEditLoading();
    const payload = {
      companyInsurancePolicyId: values.companyInsurancePolicyId,
      description: values.description,
      companyInsurancePolicyTypeOfProductId:
        values.companyInsurancePolicyTypeOfProductId,
      policyDetail: values.policyDetail,
      startDate: values.startDate
        ? dayjs(values.startDate).format('YYYY-MM-DD')
        : null,
      endDate: values.endDate
        ? dayjs(values.endDate).format('YYYY-MM-DD')
        : null,
      firstInsurer: values?.firstInsurer || null,
      firstInsurerAmount: values?.firstInsurerAmount || 0,
      secondInsurer: values?.secondInsurer || null,
      secondInsurerAmount: values?.secondInsurerAmount || 0,
      thirdInsurer: values?.thirdInsurer || null,
      thirdInsurerAmount: values?.thirdInsurerAmount || 0,
      status: 2,
    };

    if (!payload.firstInsurer) {
      stopEditLoading();
      toast.error("'First Insurer' must not be empty.");
      return;
    }

    await crud.update(
      'companyinsurancepolicycomparisons',
      values?.companyInsurancePolicyComparisonId,
      payload,
      (_err, res) => {
        if (res?.status === 200) {
          stopEditLoading();
          toast.success('Updated successfully!');
          navigate(-1);
        } else {
          stopEditLoading();
          toast.error('Something went wrong during the update!');
        }
      }
    );
  };
  const startDate = watch('startDate');
  return (
    <DashboardLayout
      title='Edit Company Insurance Policy - Comparison'
      actionButtons={
        <>
          <Box className='flex flex-row items-center whitespace-nowrap gap-3'>
            <CreateButton
              name='Create'
              onClick={() =>
                navigate('/admin/company-insurance-policy/comparison/create')
              }
            />
          </Box>
        </>
      }
    >
      <GlassCard className='p-5'>
        {loading ? (
          <CommonLoader />
        ) : (
          <form onSubmit={handleSubmit(handleUpdateComparisonPolicy)}>
            <Grid container spacing={3}>
              <Grid item md={GridValue}>
                <FmAutoComplete
                  name='companyInsurancePolicyId'
                  control={control}
                  options={insurancePolicy}
                  label='Policy / Vehicle Details'
                  displayField='companyInsurancePolicyName'
                  optionFields={['companyInsurancePolicyName']}
                  valueKey='companyInsurancePolicyId'
                  required
                />
              </Grid>

              <Grid item md={GridValue}>
                <FmAutoComplete
                  name='companyInsurancePolicyTypeOfProductId'
                  control={control}
                  options={typeOfProduct}
                  label='Type of Product'
                  displayField='companyInsurancePolicyTypeOfProductName'
                  optionFields={['companyInsurancePolicyTypeOfProductName']}
                  valueKey='companyInsurancePolicyTypeOfProductId'
                  required
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmTextField
                  name='policyDetail'
                  control={control}
                  label='policy Detail'
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmDatePicker
                  name='startDate'
                  control={control}
                  label='Start Date'
                  onChangeProp={() => trigger('endDate')}
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmDatePicker
                  name='endDate'
                  control={control}
                  label='End Date'
                  onChangeProp={() => trigger('startDate')}
                  minDate={startDate ? dayjs(startDate) : null}
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmSearchableSelect
                  name='firstInsurer'
                  control={control}
                  apiUrl='companyinsurancepolicyinsurers'
                  valueField='companyInsurancePolicyInsurerId'
                  labelField={[
                    'companyInsurancePolicyInsurerCode',
                    'companyInsurancePolicyInsurerName',
                  ]}
                  showField={[
                    'companyInsurancePolicyInsurerCode',
                    'companyInsurancePolicyInsurerName',
                  ]}
                  label='First Insurer'
                  onChangeProp={handleFirstInsurerChange}
                  defaultValue={{
                    companyInsurancePolicyInsurerId: editData?.firstInsurer,
                    companyInsurancePolicyInsurerName: editData?.firstInsurerName,
                  }}
                  required
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmTextField
                  name='firstInsurerAmount'
                  control={control}
                  label='First Insurer Amount'
                  pattern='Number'
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmSearchableSelect
                  name='secondInsurer'
                  control={control}
                  apiUrl='companyinsurancepolicyinsurers'
                  valueField='companyInsurancePolicyInsurerId'
                  labelField={[
                    'companyInsurancePolicyInsurerCode',
                    'companyInsurancePolicyInsurerName',
                  ]}
                  showField={[
                    'companyInsurancePolicyInsurerCode',
                    'companyInsurancePolicyInsurerName',
                  ]}
                  label='Second Insurer'
                  onChangeProp={handleSecondInsurerChange}
                  defaultValue={{
                    companyInsurancePolicyInsurerId: editData?.secondInsurer,
                    companyInsurancePolicyInsurerName: editData?.secondInsurerName,
                  }}
                  required
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmTextField
                  name='secondInsurerAmount'
                  control={control}
                  label='Second Insurer Amount'
                  pattern='Number'
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmSearchableSelect
                  name='thirdInsurer'
                  control={control}
                  apiUrl='companyinsurancepolicyinsurers'
                  valueField='companyInsurancePolicyInsurerId'
                  labelField={[
                    'companyInsurancePolicyInsurerCode',
                    'companyInsurancePolicyInsurerName',
                  ]}
                  showField={[
                    'companyInsurancePolicyInsurerCode',
                    'companyInsurancePolicyInsurerName',
                  ]}
                  label='Third Insurer'
                  onChangeProp={handleThirdInsurerChange}
                  defaultValue={{
                    companyInsurancePolicyInsurerId: editData?.thirdInsurer,
                    companyInsurancePolicyInsurerName: editData?.thirdInsurerName,
                  }}
                  required
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmTextField
                  name='thirdInsurerAmount'
                  control={control}
                  label='Third Insurer Amount'
                  pattern='Number'
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmTextField
                  name='description'
                  control={control}
                  label='Description'
                  rules={{
                    required: 'Remarks is required',
                  }}
                  multiline={true}
                  rows={4}
                  maxRows={3}
                />
              </Grid>
            </Grid>
            <ActionButtons
              onSubmit={handleSubmit(handleUpdateComparisonPolicy)}
              onCancel={() => navigate(-1)}
              onReset={reset}
              submitText='Update'
              cancelText='Cancel'
              submitLoading={editLoading}
              cancelLoading={false}
            />
          </form>
        )}
      </GlassCard>
    </DashboardLayout>
  );
};

export default EditComparisonPolicy;
