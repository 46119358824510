export const statusOptions = [
  { statusId: 2, statusName: 'Active' },
  { statusId: 1, statusName: 'Inactive' },
];

export const projectTypes = [
  {
    projectTypeId: 1,
    projectTypeName: 'MEP',
  },
  {
    projectTypeId: 2,
    projectTypeName: 'AMC',
  },
  {
    projectTypeId: 3,
    projectTypeName: 'Project Division',
  },
];

export const attachments = [
  { id: 1, file: null, fileName: '', fileId: null },
  { id: 2, file: null, fileName: '', fileId: null },
  { id: 3, file: null, fileName: '', fileId: null },
];

export const BooleanOptions = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];
export const GoodsReceivedNoteTypeStatus = [
  { value: 1, label: ' DELIVERED' },
  { value: 2, label: 'SHORTCLOSE' },
  { value: 3, label: 'DELIVERYTERMS' },
];

export const YesNoOptions = [
  { value: 1, label: 'Yes' },
  { value: 2, label: 'No' },
];

export const BooleanOptionsWithInt = [
  { value: 1, label: 'No' },
  { value: 2, label: 'Yes' },
];

export const paymentStatus = [
  {
    value: 1,
    statusName: 'Sended',
  },
  {
    value: 2,
    statusName: 'Pending',
  },
];
export const formatStatus = [
  {
    value: 1,
    label: 'Specific',
  },
  {
    value: 2,
    label: 'Bank Standard Format',
  },
];

export const estimationStatusOptions = [
  { statusId: 1, statusName: 'In-Progress' },
  { statusId: 2, statusName: 'Approved' },
  { statusId: 3, statusName: 'Rejected' },
];

export const requisitionDuration = [
  {
    label: 'Temporary',
    value: 1,
  },
  {
    label: 'Permanent',
    value: 2,
  },
];

export const gender = [
  { genderId: 1, genderName: 'Male' },
  { genderId: 2, genderName: 'Female' },
];

export const candidateCategories = [
  {
    label: 'Work man',
    value: 1,
  },
  {
    label: 'Staff',
    value: 2,
  },
];

export const candidateRegion = [
  {
    label: 'Overseas',
    value: 1,
  },
  {
    label: 'Local',
    value: 2,
  },
];
export const passportSubmissionStatus = [
  {
    label: 'Normal',
    value: 1,
  },
  {
    label: 'Renewal',
    value: 2,
  },
];
export const drivingLicense = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
];

export const CommonTrueFalse = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
];

export const ticketsNoteStatusOptions = [
  { statusId: 1, statusName: 'Waiting for Customer Response' },
  { statusId: 2, statusName: 'Waiting for Technician Response' },
  { statusId: 3, statusName: 'Waiting for Approval' },
  { statusId: 4, statusName: 'Inspection Scheduled' },
  { statusId: 5, statusName: 'Materials Ordered' },
  { statusId: 6, statusName: 'Pending' },
  { statusId: 7, statusName: 'Resolved' },
];
export const InOutOptions = [
  { value: 1, label: 'In' },
  { value: 2, label: 'Out' },
];

export const previousVisaStatusOptions = [
  { statusId: 1, statusName: 'Visit visa' },
  { statusId: 2, statusName: 'Cancelled' },
  { statusId: 3, statusName: 'Outside country visa' },
  { statusId: 4, statusName: 'Visa hold for family' },
];
export const installationStatusEnum = [
  { statusId: 1, statusName: 'Pending' },
  { statusId: 2, statusName: 'Review' },
  { statusId: 3, statusName: 'Draft' },
  { statusId: 4, statusName: 'Ready' },
  { statusId: 5, statusName: 'Close' },
];
export const InspectionTypeEnum = [
  {
    label: 'MONTHLY_OR_ROUTINE',
    value: 1,
  },
  {
    label: 'WEEKLY',
    value: 2,
  },
];

export const OperationalStatusEnum = [
  { value: 1, label: ' GOOD ' },
  { value: 2, label: 'ACTIVE' },
  { value: 3, label: 'PENDING' },
  { value: 4, label: 'READY' },
];

export const insuranceEnum = [
  {
    value: 1,
    label: 'SELF',
  },
  {
    value: 2,
    label: 'SELF AND FAMILY',
  },
];

export const SkillAssessmentEnum = [
  {
    value: 1,
    label: 'VERYGOOD',
  },
  {
    value: 2,
    label: 'GOOD',
  },
  {
    value: 3,
    label: 'AVERAGE',
  },
  {
    value: 4,
    label: 'POOR',
  },
];


export const LOAStatusOptions = [
  { statusId: 1, statusName: 'Pending' },
  { statusId: 2, statusName: 'Approved' },
  { statusId: 3, statusName: 'Rejected' },
  { statusId: 4, statusName: 'Cancelled' },
  { statusId: 5, statusName: 'Completed' },
];


export const settlementStatusEnum = [
  { value: 1, label: 'IN_PROGRESS' },
  { value: 2, label: 'SETTLED' },
  { value: 3, label: 'DISPUTED' },
  { value: 4, label: 'CLOSED' },
];

export const maritalStatus = [
  { maritalStatus: 1, maritalStatusName: 'Married' },
  { maritalStatus: 2, maritalStatusName: 'Unmarried' },
];

