import React from 'react';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import { Grid } from '@mui/material';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import { useForm } from 'react-hook-form';
import Crud_Service from '../../../../apis/CrudService.jsx';
import ActionButtons from '../../../../components/_form/ActionButtons.tsx';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import FmDatePicker from '../../../../components/_mui/FmDatePicker.tsx';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import { addCheckOutvalidationSchema } from '../../../../utils/CommonvalidationSchemas.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
const GridSize = 2.4;

const AddCheckOutForm = ({ fetchData }) => {
  const crud = new Crud_Service();
  const { createLoading, startCreateLoading, stopCreateLoading } = useLoader();
  const navigate = useNavigate();
  const { handleSubmit, control, reset } = useForm({
    resolver: yupResolver(addCheckOutvalidationSchema),
    mode: 'onChange',
  });

  const handleSubmitCheckoutForm = async (data) => {
    startCreateLoading();
    const combinedData = {
      ...data,
      checkOutDate: dayjs(data?.checkOutDate).format('YYYY-MM-DD'),
      inChargeId: Number(data?.loginUserId),
    };
    await crud.create('checkoutforms', combinedData, (err, res) => {
      if (res?.status === 201) {
        toast.success('Item Created successfully');
        fetchData();
        reset();
        navigate(-1);
        stopCreateLoading();
      } else {
        stopCreateLoading();
      }
    });
  };

  return (
    <DashboardLayout title='Add Check Out Form' hasSubmenu>
      <GlassCard className='p-3'>
        <form onSubmit={handleSubmit(handleSubmitCheckoutForm)}>
          <Grid container spacing={2}>
            {/* <Grid item md={GridSize}>
            <Controller
              name='checkOutDate'
              control={control}
              defaultValue={null}
              render={({ field: { onChange, value } }) => (
                <MuiDatePickerOne
                  label='checkOutDate'
                  value={value}
                  TextRequired={true}
                  format='DD/MM/YYYY'
                  onChange={(newValue) => {
                    onChange(newValue);
                  }}
                />
              )}
            />
          </Grid> */}
            <Grid item md={GridSize}>
              <FmDatePicker
                name='checkOutDate'
                label='Check Out Date'
                control={control}
                required
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='loginUserId'
                control={control}
                apiUrl='loginusers'
                valueField='loginUserId'
                labelField={['employeeCode', 'loginUserName']}
                showField={['employeeCode', 'loginUserName']}
                label='In Charge Name'
                required
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='contractId'
                control={control}
                apiUrl='contracts'
                valueField='contractId'
                headerField={['Contract Code']}
                labelField={['contractCode']}
                showField={['contractCode']}
                label='Contract'
                required
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='companyId'
                control={control}
                apiUrl='companies'
                valueField='companyId'
                headerField={['Code']}
                labelField={['companyCode']}
                showField={['companyCode']}
                label='Company'
                required
              />
            </Grid>

            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='clientId'
                control={control}
                apiUrl='clients'
                valueField='clientId'
                headerField={['Client Name']}
                labelField={['clientName']}
                showField={['clientName']}
                label='Client'
                required
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='projectId'
                control={control}
                apiUrl='projects'
                valueField='projectId'
                headerField={['Project Code']}
                labelField={['projectCode']}
                showField={['projectCode']}
                label='Project Code'
                required
              />
            </Grid>

            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='workOrderId'
                control={control}
                apiUrl='workorders'
                valueField='workOrderId'
                headerField={['User Name']}
                labelField={['workOrderName', 'workOrderCode']}
                showField={['workOrderName', 'workOrderCode']}
                label='work Order'
                required
              />
            </Grid>
          </Grid>
          <Grid item md={GridSize}>
            <ActionButtons
              onSubmit={handleSubmit(handleSubmitCheckoutForm)}
              onReset={reset}
              cancelLoading={false}
            />
          </Grid>
        </form>
      </GlassCard>
    </DashboardLayout>
  );
};

export default AddCheckOutForm;
