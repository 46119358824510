/* eslint-disable */
import { yupResolver } from '@hookform/resolvers/yup';
import { Icon } from '@iconify/react';
import { Box, Button } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHotkeys } from 'react-hotkeys-hook';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import apiInstance from '../../../apis/ApiService.jsx';
import Crud_Service from '../../../apis/CrudService.jsx';
import FmMRTDataTable from '../../../components/FmMRTDataTable.tsx';
import ActionButtons from '../../../components/_form/ActionButtons.tsx';
import TableActionButtons from '../../../components/_form/TableActionButtons.jsx';
import FmAutoComplete from '../../../components/_mui/FmAutoComplete.tsx';
import FmFileInput from '../../../components/_mui/FmFileInput.tsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import MuiDialogOne from '../../../components/_mui/MuiDialogOne.jsx';
import useTableLogic from '../../../components/helpers/MRTUseTableLogic.tsx';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import useCommonFetchApi from '../../../components/helpers/useCommonFetchApi.tsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { MEPTableValidationSchema } from '../../../utils/CommonvalidationSchemas.tsx';
import TotalBox from '../EstimationSheetForAMC/TotalBox.jsx';
import Dialogs from '../EstimationSheetForMEP/Dialogs.jsx';
import axios from 'axios';

const ViewEstimationMEP = () => {
  const [total, setTotalAmount] = useState(0);
  const [fieldEdited, setFieldEdited] = useState(false);
  const [estimationMEP, setEstimationMEP] = useState<any>({});
  const id = useParams()?.id;
  useEffect(() => {
    getEstimationMEP();
    getAllAMC();
    getEstimationTypes();
  }, []);
  const getEstimationMEP = async () => {
    // setTableLoad(false);
    setTotalBoxLoader(true);
    await crud.getAll(`estimationmepsheets`, { tenderId: id }, (err, res) => {
      if (err) {
        toast.error(err);
      }
      if (res?.status === 200) {
        setEstimationMEP(res?.data?.data[0]);

        setAmcSheetsPercent((prev) => ({
          ...prev,
          overHeadOverAllPercent: res?.data?.overHeadPercent || 0,
          overHeadCost: res?.data?.overHeadUnitPrice || 0,
          contingencyOverAllPercent: res?.data?.contingencyOverAllPercent || 0,
          contingencyCost: res?.data?.contingencyCost || 0,
          otherOverAllPercent: res?.data?.otherOverAllPercent,
          otherCost: res?.data?.otherCost,
          markUpOverAllPercent: res?.data?.markUpOverAllPercent || 0,
          markUpCost: res?.data?.markUpCost || 0,
          discountOverAllPercent: res?.data?.discountOverAllPercent || 0,
          discountCost: res?.data?.discountCost,
          negotiationPercent: res?.data?.negotiationPercent || 0,
          negotiationCost: res?.data?.negotiationCost,
          totalEstimationCost: res?.data?.totalEstimationCost || 0,
          note: res?.data?.note || '',
        }));
        // setTableLoad(true);
        setTotalBoxLoader(false);
      }
    });
  };

  const {
    rows,
    setRows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setSearchKeyword,
    searchKeyword,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
  } = useTableLogic(
    `estimationmepsheetdetails?estimationMepSheetId=${
      estimationMEP?.estimationMepSheetId || 0
    }&sortDirection=asc`,
    '',
    ''
  );

  const [viewData, setViewData] = useState([]);
  const [productView, setProductView] = useState(false);

  const [pageSize, setPageSize] = useState(20);
  const [pageNumber, setPageNumber] = useState(1);
  const [sortBy, setSortBy] = useState('');
  const [sortDirection, setSortDirection] = useState('');
  const {
    reset,
    setValue,
    control,
    getValues,
    formState: { isValid },
  } = useForm({
    resolver: yupResolver(MEPTableValidationSchema),
    mode: 'all',
  });

  const [amcSheetsPercent, setAmcSheetsPercent] = useState({
    note: '',
    overHeadOverAllPercent: 0,
    overHeadCost: 0,
    contingencyOverAllPercent: 0,
    contingencyCost: 0,
    otherOverAllPercent: 0,
    otherCost: 0,
    markUpOverAllPercent: 0,
    markUpCost: 0,
    discountOverAllPercent: 0,
    discountCost: 0,
    negotiationPercent: 0,
    negotiationCost: 0,
    totalEstimationCost: 0,
  });

  const [openUpload, setOpenUpload] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadedfileData, setUploadedfileData] = useState(null);
  const [amcAllEstimationSheets, setAllEstimationAMC] = useState([]);
  const [tablerecordCounts, setTableRecordCounts] = useState(null);
  const navigate = useNavigate();
  const { startCreateLoading, stopCreateLoading, createLoading } = useLoader();
  const crud = new Crud_Service();
  const [estimationTypes, setEstimationTypes] = useState([]);
  const [manPower, setManPower] = useState(false);
  const [manPowerData, setManPowerData] = useState<any>([]);
  const [nextId, setNextId] = useState();
  const [tableLoad, setTableLoad] = useState(false);
  const [editingRowId, setEditingRowId] = useState(null);
  const [selectedRate, setSelectedRate] = useState(null);
  const [editingRowData, setEditingRowData] = useState(null);
  const [currentRowIndex, setCurrentRowIndex] = useState(0);
  const [activeRowIndex, setActiveRowIndex] = useState(null);
  const [activeField, setActiveField] = useState(null);
  const [editingRowIndex, setEditingRowIndex] = useState(null);
  // new states
  const [newRowCreated, setNewRowCreated] = useState(false);
  const [stateError, setStateError] = useState(false);
  const [totalBoxLoader, setTotalBoxLoader] = useState(true);
  const [amcSubtotal, setAmcSubtotal] = useState(0);
  const [rowSelection, setRowSelection] = useState({});
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [rowState, setRowState] = useState({
    newRowId: null,
    isCreatingRow: false,
  });
  const firstColumnInputRef = useRef(null);

  useEffect(() => {
    if (estimationMEP?.estimationMepSheetId) {
      fetchData();
    }
  }, [estimationMEP]);

  useEffect(() => {
    setTotalAmount(rows[0]?.totalAmount);
  }, [rows]);
  useEffect(() => {
    if (editingRowData) {
      const amcSubtotal = rows[0]?.estimationMepSheetDetails?.reduce(
        (total, row, index) => {
          const amountToAdd =
            index === editingRowIndex
              ? Number(editingRowData?.totalMepAmount) || 0
              : Number(row.totalMepAmount) || 0;

          return total + amountToAdd;
        },
        0
      );
      setAmcSubtotal(amcSubtotal);
    }
  }, [editingRowData]);

  const handleAddNewRow = () => {
    const newId = 'new_row_' + Date.now();

    // Reset any existing editing or form states
    reset();
    setEditingRowData(null);
    setEditingRowId(null);

    // Set row state for tracking the new row
    setRowState({
      newRowId: newId,
      isCreatingRow: true,
    });

    // Determine the index for editing
    setEditingRowIndex(
      editingRowIndex === rows[0]?.estimationMepSheetDetails?.length
        ? editingRowIndex + 1
        : rows[0]?.estimationMepSheetDetails?.length
    );

    // Mark that a new row has not been finalized yet
    setNewRowCreated(false);

    // Update rows with a new entry in estimationAmcSheetDetails
    setRows((prevRows) =>
      prevRows.map((row, index) => {
        if (index === 0) {
          // Add a new row to the estimationAmcSheetDetails of the first row
          return {
            ...row,
            estimationMepSheetDetails: [
              ...row.estimationMepSheetDetails,
              {
                estimationMepSheetId: newId,
                estimationMepSheetDetailId: id,
                bill: '',
                estimationTypeId: '',
                lineItem: '',
                unitSellingPrice: 0,
                subHead: '',
                quantity: 0,
                unitOfMeasurementId: '',
                currencyId: '',
                materialCost: 0,
                installationCost: 0,
                subTotalAmount: 0,
                actualUnitPrice: 0,
                overHeadPercent: 0,
                overHeadUnitPrice: 0,
                contingencyPercent: 0,
                contingencyUnitPrice: 0,
                markUpPercent: 0,
                markupUnitPrice: 0,
                discountPercent: 0,
                discountUnitPrice: 0,
                negotiationPercent: 0,
                negotiationUnitPrice: 0,
                totalMepAmount: 0,
                isEditable: true,
                isNew: true,
              },
            ],
          };
        }
        return row; // Return other rows unchanged
      })
    );

    // Reset the form again after row addition
    reset();
  };
  const previousValues = useRef(getValues()); // store previous values to compare with

  const handleBlur = () => {
    const currentValues = getValues();

    // Compare the previous values with the current values
    const hasChanges = Object.keys(currentValues).some(
      (key) => currentValues[key] !== previousValues.current[key]
    );
    if ((hasChanges && isValid) || (hasChanges && editingRowId)) {
      setFieldEdited(true);
      apiCallAsync(currentValues); // Call the API with the new values
      previousValues.current = currentValues; // Update previous values
    }
  };

  const apiCallAsync = async (values) => {
    const convertedValues = {
      ...values,
      status: 2, // Static status value
      discountUnitPrice: Number(editingRowData?.discountUnitPrice),
      markupUnitPrice: Number(editingRowData?.markupUnitPrice),
      negotiationUnitPrice: Number(editingRowData?.negotiationUnitPrice),
      overHeadUnitPrice: Number(editingRowData?.overHeadUnitPrice),
      subTotalAmount: Number(editingRowData?.subTotalAmount),
      totalMepAmount: Number(editingRowData?.totalMepAmount),
      actualUnitPrice: Number(editingRowData?.actualUnitPrice),
      contingencyUnitPrice: Number(editingRowData?.contingencyUnitPrice),
      unitPrice: Number(editingRowData?.materialCost),
      contingencyPercent: Number(editingRowData?.contingencyPercent),
      discountPercent: Number(editingRowData?.discountPercent),
      installationCost: Number(editingRowData?.installationCost),
      negotiationPercent: Number(editingRowData?.negotiationPercent),
      overHeadPercent: Number(editingRowData?.overHeadPercent),
      quantity: Number(editingRowData?.quantity),
      markUpPercent: Number(editingRowData?.markUpPercent),
    };

    const combinedData = {
      estimationMepSheetId: estimationMEP?.estimationMepSheetId,
      ...convertedValues,
      // estimationMepSheetDetails: [convertedValues], // Combine to match API format
    };

    const updateCombinedData = {
      ...values, // Same as above, combining updated values
      estimationMepSheetDetailId: values?.estimationMepSheetDetailId, // Ensure proper ID is passed
      status: 2, // Static status value
      discountUnitPrice: Number(editingRowData?.discountUnitPrice),
      markupUnitPrice: Number(editingRowData?.markupUnitPrice),
      negotiationUnitPrice: Number(editingRowData?.negotiationUnitPrice),
      overHeadUnitPrice: Number(editingRowData?.overHeadUnitPrice),
      subTotalAmount: Number(editingRowData?.subTotalAmount),
      totalMepAmount: Number(editingRowData?.totalMepAmount),
      actualUnitPrice: Number(editingRowData?.actualUnitPrice),
      contingencyUnitPrice: Number(editingRowData?.contingencyUnitPrice),
      unitPrice: Number(editingRowData?.materialCost),
      contingencyPercent: Number(editingRowData?.contingencyPercent),
      discountPercent: Number(editingRowData?.discountPercent),
      installationCost: Number(editingRowData?.installationCost),
      negotiationPercent: Number(editingRowData?.negotiationPercent),
      overHeadPercent: Number(editingRowData?.overHeadPercent),
      quantity: Number(editingRowData?.quantity),
      markUpPercent: Number(editingRowData?.markUpPercent),
    };

    // Conditional API call based on whether this is a new row or an update
    if (editingRowId === null) {
      await crud.create(
        'estimationmepsheetdetails',
        combinedData,
        (err, res) => {
          if (res?.status === 201) {
            setEditingRowId(res?.data?.estimationMepSheetDetailId);
            setValue(
              'estimationMepSheetDetailId',
              res?.data?.estimationMepSheetDetailId
            );
          } else {
            // Handle error
          }
        }
      );
    } else {
      await crud.update(
        'estimationmepsheetdetails',
        editingRowId,
        updateCombinedData,
        (err, res) => {
          if (res?.status === 200) {
            if (rows[0]?.estimationMepSheetDetails?.some((row) => row.isNew)) {
              const updatedRow = res?.data;
              const updatedRows = [...rows[0]?.estimationMepSheetDetails];
              updatedRows[editingRowIndex] = {
                ...updatedRow,
                isEditable: true,
                isNew: true,
              };
              setRows([
                {
                  ...rows[0],
                  estimationMepSheetDetails: updatedRows,
                },
              ]);
            } else {
              const updatedRow = res?.data;
              const updatedRows = [...rows[0]?.estimationMepSheetDetails];

              updatedRows[editingRowIndex] = updatedRow;

              setRows([
                {
                  ...rows[0],
                  estimationMepSheetDetails: updatedRows,
                },
              ]);
            }
          } else {
            // Handle error
          }
        }
      );
    }
  };
  useEffect(() => {
    document.title = `Presales | Estimation Sheet Details`;
  }, [pageNumber, pageSize, searchKeyword, sortBy, sortDirection]);

  const [loader, setLoader] = useState(false);
  useEffect(() => {
    manPower && getManPower();
  }, [manPower]);

  const getManPower = async () => {
    await crud
      .getAll(`manpowerrates?pageSize=100&pageNumber=1`, '', (err, res) => {
        if (res?.data) {
          setManPowerData(res?.data?.data);
        }
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  const getEstimationTypes = async () => {
    await crud.getAll(
      'estimationtypes?pageSize=100&pageNumber=1',
      '',
      (err, res) => {
        if (err) {
          toast.error(err);
          return;
        }
        if (res?.status === 200) {
          setEstimationTypes(res?.data.data);
        }
      }
    );
  };

  const getAllAMC = async () => {
    await crud.getAll(`estimationmepsheets`, {}, (err, res) => {
      if (err) {
        toast.error(err);
      }
      if (res?.status === 200) {
        setAllEstimationAMC(res?.data?.data);
      }
    });
  };

  const handleDeleteSheet = async (props) => {
    await crud.remove(
      `estimationmepsheetdetails?estimationMepSheetDetailIds=${props?.estimationMepSheetDetailId}`,
      '',
      (err, res) => {
        if (res?.status === 204) {
          toast.success('MEP Sheet deleted Successfully');
          fetchData();
        } else {
          toast.error('Something Went Wrong!');
        }
      }
    );
  };

  const handleEdit = (row) => {
    if (rowState?.newRowId) {
      setRows((prevRows) =>
        prevRows.filter((r) => r?.estimationMepSheetId !== rowState?.newRowId)
      );
      setRowState((prevState) => ({
        ...prevState,
        newRowId: null,
        isCreatingRow: false,
      }));
      // setEditingRowIndex(null);
    }
    setEditingRowId(row?.estimationMepSheetDetailId);
    Object.entries({
      ...row,
    }).forEach(([key, value]) => {
      setValue(key, value);
    });
    setEditingRowData({ ...row });
  };

  const { data: unitofmeasurements } =
    useCommonFetchApi('unitofmeasurements') || [];
  const { data: manpowerrates } = useCommonFetchApi('manpowerrates');
  const { data: currencies } = useCommonFetchApi('currencies');

  const handleExportMEP = async () => {
    try {
      const downloadUrl = await apiInstance.getFiles(
        `estimationmepsheetdetails/${id}/exportexcel`
      );
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', 'MEP Details.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Failed to export MEP details:', error);
    }
  };
  const handleTotalSubmit = () => {
    const data = {
      ...amcSheetsPercent,
      contingencyCost: Number(amcSheetsPercent.contingencyCost),
      discountCost: Number(amcSheetsPercent.discountCost),
      markUpCost: Number(amcSheetsPercent.markUpCost),
      otherCost: Number(amcSheetsPercent.otherCost),
      overHeadCost: Number(amcSheetsPercent.overHeadCost),
      negotiationCost: Number(amcSheetsPercent.negotiationCost),
      totalEstimationCost: Number(amcSheetsPercent.totalEstimationCost),
      actualUnitPrice: Number(amcSheetsPercent.actualUnitPrice),
    };
    crud.update(
      `estimationmepsheets/${id}/update-note`,
      '',
      data,
      (err, res) => {
        if (err) {
          toast.error(err);
          return;
        }
        if (res?.status === 200) {
          toast.success('Estimation Sheet Detail Created Successfully');
          fetchData();
        } else {
        }
      }
    );
  };
  const handleFileUpload = async (e) => {
    setUploadLoading(true);
    const file = e.target.files[0];
    if (file) {
      const fileFormData = new FormData();
      fileFormData.append('file', file);
      fileFormData.append('fileTypeId', 41);

      await crud.create(`/files/upload`, fileFormData, (err, res) => {
        if (res?.status === 200) {
          setUploadLoading(false);
          setUploadedfileData(res?.data);
        } else {
          setUploadLoading(false);
        }
      });
    }
  };

  const handleSubmitImport = async () => {
    startCreateLoading();
    await crud.create(
      `estimationmepsheetdetails/${id}/process/${uploadedfileData?.fileId}`,
      '',
      (err, res) => {
        if (res?.status === 200) {
          toast.success('Estimation MEP Sheet Detail Created Successfully');
          stopCreateLoading();
          setOpenUpload(false);
          setUploadedfileData(null);
          fetchData();
        } else {
          stopCreateLoading();
        }
      }
    );
  };

  const handleCancel = (row) => {
    if (row?.original?.estimationMepSheetId === rowState?.newRowId) {
      setRows((prevRows) =>
        prevRows.filter((r) => r?.estimationMepSheetId !== rowState?.newRowId)
      );
      setRowState((prevState) => ({
        ...prevState,
        newRowId: null,
        isCreatingRow: false,
      }));
      setEditingRowIndex(null);
    } else {
      setEditingRowId(null);
      setEditingRowIndex(null);
    }
    reset();
  };

  // useEffect(() => {
  //   const updatedRowData = { ...editingRowData };

  //   // Step 1: Update materialCost based on selectedRate
  //   if (selectedRate) {
  //     const selectedManPowerRate = manpowerrates.find(
  //       (item) => item.manPowerRateId === selectedRate
  //     );
  //     if (selectedManPowerRate?.ctcPerMonth) {
  //       updatedRowData.materialCost = selectedManPowerRate.ctcPerMonth;
  //     }
  //   }

  //   // Step 2: Calculate subTotalAmount
  //   const quantity = parseFloat(updatedRowData?.quantity || 0);
  //   const materialCost = parseFloat(updatedRowData?.materialCost || 0);
  //   const installationCost = parseFloat(updatedRowData?.installationCost || 0);

  //   if (quantity && materialCost && installationCost) {
  //     updatedRowData.subTotalAmount = (materialCost + installationCost) * quantity;
  //   }

  //   // Step 3: Calculate actualUnitPrice
  //   if (materialCost && installationCost) {
  //     updatedRowData.actualUnitPrice = materialCost + installationCost;
  //   }

  //   // Step 4: Update overHeadUnitPrice
  //   const overHeadPercent = parseFloat(editingRowData?.overHeadPercent || 0);
  //   const actualUnitPrice = parseFloat(updatedRowData.actualUnitPrice || 0);

  //   if (overHeadPercent && updatedRowData.actualUnitPrice) {
  //     updatedRowData.overHeadUnitPrice = (
  //       updatedRowData.actualUnitPrice * (overHeadPercent / 100) +
  //       actualUnitPrice
  //     ).toFixed(2);
  //   }

  //   // Step 5: Update contingencyUnitPrice
  //   const contingencyPercent = parseFloat(editingRowData?.contingencyPercent || 0);
  //   const overHeadUnitPrice = parseFloat(updatedRowData?.overHeadUnitPrice || 0);

  //   if (contingencyPercent && (overHeadUnitPrice || updatedRowData.actualUnitPrice)) {
  //     const basePrice = overHeadUnitPrice || updatedRowData.actualUnitPrice;
  //     updatedRowData.contingencyUnitPrice = (
  //       basePrice * (contingencyPercent / 100) +
  //       overHeadUnitPrice
  //     ).toFixed(2);
  //   }

  //   // Step 6: Update markupUnitPrice
  //   const markUpPercent = parseFloat(editingRowData?.markUpPercent || 0);
  //   const contingencyUnitPrice = parseFloat(updatedRowData?.contingencyUnitPrice || 0);

  //   if (markUpPercent && contingencyUnitPrice) {
  //     updatedRowData.markupUnitPrice = (
  //       contingencyUnitPrice * (markUpPercent / 100) +
  //       contingencyUnitPrice
  //     ).toFixed(2);
  //   }

  //   // Step 7: Update negotiationUnitPrice
  //   const negotiationPercent = parseFloat(editingRowData?.negotiationPercent || 0);
  //   const markupUnitPrice = parseFloat(updatedRowData?.markupUnitPrice || 0);

  //   if (negotiationPercent && markupUnitPrice) {
  //     updatedRowData.negotiationUnitPrice = (
  //       markupUnitPrice * (negotiationPercent / 100) +
  //       markupUnitPrice
  //     ).toFixed(2);
  //   }

  //   // Step 8: Update discountUnitPrice
  //   const discountPercent = parseFloat(editingRowData?.discountPercent || 0);
  //   const negotiationUnitPrice = parseFloat(updatedRowData?.negotiationUnitPrice || 0);

  //   if (discountPercent && negotiationUnitPrice) {
  //     updatedRowData.discountUnitPrice = (
  //       negotiationUnitPrice +
  //       negotiationUnitPrice * (discountPercent / 100)
  //     ).toFixed(2);
  //   }

  //   // Step 9: Update totalAmcAmount
  //   // const months = parseFloat(editingRowData?.months || 0);
  //   const discountUnitPrice = parseFloat(updatedRowData?.discountUnitPrice || 0);

  //   if (quantity && discountUnitPrice) {
  //     updatedRowData.totalMepAmount = (
  //       quantity * discountUnitPrice
  //     ).toFixed(2);
  //   }

  //   // Update the editing row data in the state
  //   setEditingRowData(updatedRowData);
  // }, [selectedRate, manpowerrates, editingRowData]);

  useEffect(() => {
    if (!editingRowData) return;

    // Clone the data to avoid direct mutation of state
    const updatedRowData = { ...editingRowData };

    // Step 1: Update `materialCost` based on `selectedRate`
    if (selectedRate) {
      const selectedManPowerRate = manpowerrates.find(
        (item) => item.manPowerRateId === selectedRate
      );
      if (selectedManPowerRate?.ctcPerMonth) {
        updatedRowData.materialCost = selectedManPowerRate.ctcPerMonth;
      }
    }

    // Step 2: Calculate `subTotalAmount`
    const quantity = parseFloat(updatedRowData?.quantity || 0);
    const materialCost = parseFloat(updatedRowData?.materialCost || 0);
    const installationCost = parseFloat(updatedRowData?.installationCost || 0);

    if (quantity && materialCost && installationCost) {
      updatedRowData.subTotalAmount = (
        (materialCost + installationCost) *
        quantity
      ).toFixed(2);
    }

    // Step 3: Calculate `actualUnitPrice`
    if (materialCost && installationCost) {
      updatedRowData.actualUnitPrice = (
        materialCost + installationCost
      ).toFixed(2);
    }

    // Step 4: Calculate `overHeadUnitPrice`
    const overHeadPercent = parseFloat(updatedRowData?.overHeadPercent || 0);
    const actualUnitPrice = parseFloat(updatedRowData.actualUnitPrice || 0);

    if (overHeadPercent && actualUnitPrice) {
      updatedRowData.overHeadUnitPrice = (
        actualUnitPrice * (overHeadPercent / 100) +
        actualUnitPrice
      ).toFixed(2);
    }

    // Step 5: Calculate `contingencyUnitPrice`
    const contingencyPercent = parseFloat(
      updatedRowData?.contingencyPercent || 0
    );
    const overHeadUnitPrice = parseFloat(
      updatedRowData?.overHeadUnitPrice || 0
    );

    if (contingencyPercent && (overHeadUnitPrice || actualUnitPrice)) {
      const basePrice = overHeadUnitPrice || actualUnitPrice;
      updatedRowData.contingencyUnitPrice = (
        basePrice * (contingencyPercent / 100) +
        basePrice
      ).toFixed(2);
    }

    // Step 6: Calculate `markupUnitPrice`
    const markUpPercent = parseFloat(updatedRowData?.markUpPercent || 0);
    const contingencyUnitPrice = parseFloat(
      updatedRowData?.contingencyUnitPrice || 0
    );

    if (markUpPercent && contingencyUnitPrice) {
      updatedRowData.markupUnitPrice = (
        contingencyUnitPrice * (markUpPercent / 100) +
        contingencyUnitPrice
      ).toFixed(2);
    }

    // Step 7: Calculate `negotiationUnitPrice`
    const negotiationPercent = parseFloat(
      updatedRowData?.negotiationPercent || 0
    );
    const markupUnitPrice = parseFloat(updatedRowData?.markupUnitPrice || 0);

    if (negotiationPercent && markupUnitPrice) {
      updatedRowData.negotiationUnitPrice = (
        markupUnitPrice * (negotiationPercent / 100) +
        markupUnitPrice
      ).toFixed(2);
    }

    // Step 8: Calculate `discountUnitPrice`
    const discountPercent = parseFloat(updatedRowData?.discountPercent || 0);
    const negotiationUnitPrice = parseFloat(
      updatedRowData?.negotiationUnitPrice || 0
    );

    if (discountPercent && negotiationUnitPrice) {
      updatedRowData.discountUnitPrice = (
        negotiationUnitPrice * (1 - discountPercent / 100) +
        negotiationUnitPrice
      ).toFixed(2);
    }
    const discountUnitPrice = parseFloat(
      updatedRowData?.discountUnitPrice || 0
    );
    if (updatedRowData?.quantity && discountUnitPrice) {
      updatedRowData.unitPrice = (
        updatedRowData.quantity * discountUnitPrice
      ).toFixed(2);
      setValue('unitPrice', updatedRowData?.unitPrice);
    }

    // Step 9: Calculate `totalMepAmount`
    if (
      updatedRowData?.unitPrice &&
      updatedRowData?.materialCost &&
      updatedRowData?.installationCost
    ) {
      // Ensure all values are parsed as floats
      updatedRowData.totalMepAmount = (
        parseFloat(updatedRowData.unitPrice) +
        parseFloat(updatedRowData.materialCost) +
        parseFloat(updatedRowData.installationCost)
      ).toFixed(2);
    }

    // Update the editing row data in the state if any changes occurred
    setEditingRowData((prevData) => {
      if (JSON.stringify(prevData) !== JSON.stringify(updatedRowData)) {
        return updatedRowData;
      }
      return prevData;
    });
  }, [
    selectedRate,
    manpowerrates,
    editingRowData?.quantity,
    editingRowData?.materialCost,
    editingRowData?.installationCost,
    editingRowData?.overHeadPercent,
    editingRowData?.contingencyPercent,
    editingRowData?.markUpPercent,
    editingRowData?.negotiationPercent,
    editingRowData?.discountPercent,
  ]);

  const handleBlurAndFetch = async () => {
    setLoader(true);
    const updatedRow = getValues(); // Get the latest values from the form
    const updatedRows = [...rows[0]?.estimationMepSheetDetails];

    updatedRows[editingRowIndex] = updatedRow;
    setRows([
      {
        ...rows[0],
        estimationMepSheetDetails: updatedRows,
      },
    ]);

    const values = getValues();
    const updateCombinedData = {
      ...values,
      estimationMepSheetDetailId: id,
      status: 2,
      discountUnitPrice: Number(editingRowData?.discountUnitPrice),
      markupUnitPrice: Number(editingRowData?.markupUnitPrice),
      negotiationUnitPrice: Number(editingRowData?.negotiationUnitPrice),
      overHeadUnitPrice: Number(editingRowData?.overHeadUnitPrice),
      subTotalAmount: Number(editingRowData?.subTotalAmount),
      totalMepAmount: Number(editingRowData?.totalMepAmount),
      actualUnitPrice: Number(editingRowData?.actualUnitPrice),
      contingencyUnitPrice: Number(editingRowData?.contingencyUnitPrice),
      unitPrice: Number(editingRowData?.materialCost),
      contingencyPercent: Number(editingRowData?.contingencyPercent),
      discountPercent: Number(editingRowData?.discountPercent),
      installationCost: Number(editingRowData?.installationCost),
      negotiationPercent: Number(editingRowData?.negotiationPercent),
      overHeadPercent: Number(editingRowData?.overHeadPercent),
      quantity: Number(editingRowData?.quantity),
      markUpPercent: Number(editingRowData?.markUpPercent),
    };
    await crud.update(
      'estimationMepSheetDetails',
      editingRowId,
      updateCombinedData,
      (err, res) => {
        if (res?.status === 200) {
          const totalRows = rows[0]?.estimationMepSheetDetails?.length;
          if (
            editingRowIndex <= totalRows &&
            rows[0]?.estimationMepSheetDetails?.some((row) => row.isNew)
          ) {
            setNewRowCreated(true);
            const updatedRow = res?.data;
            const updatedRows = [...rows[0]?.estimationMepSheetDetails];
            updatedRows[editingRowIndex] = {
              ...updatedRow,
              isEditable: true,
              isNew: true,
            };
            setRows([
              {
                ...rows[0],
                estimationMepSheetDetails: updatedRows,
              },
            ]);

            handleAddNewRow();
          } else {
            setStateError(false);
            setLoader(false);
          }
        } else {
          setStateError(true);
        }
      }
    );
  };

  const ActionData = [
    // {
    //   name: 'Edit',
    //   icon: <Icon icon='solar:pen-2-broken' />,
    //   onClick: (props) => handleEdit(props),
    // },
    {
      name: 'Delete',
      icon: <Icon icon='mi:delete' />,
      onClick: (props) => handleDeleteSheet(props),
      danger: true,
    },
  ];
  useHotkeys(
    'Enter',
    () => handleAddNewRow(),
    {
      enableOnTags: ['INPUT', 'TEXTAREA'], // Optional: Enable hotkey only when focused on specific tags
    },
    [editingRowIndex, rows[0]?.estimationMepSheetDetails, stateError, loader]
  );

  useHotkeys(
    'Tab',
    (event) => {
      if (event.key !== 'Tab') return;

      // Prevent default tab behavior if there is an error
      if (stateError) {
        event.preventDefault();
        return;
      }
      const totalRows = rows[0]?.estimationMepSheetDetails?.length;
      const nextRowIndex = editingRowIndex + 1;

      if (
        nextRowIndex < totalRows &&
        stateError === false &&
        loader === false
      ) {
        setCurrentRowIndex(nextRowIndex);
        handleEdit(rows[0]?.estimationMepSheetDetails[nextRowIndex]);
        setEditingRowIndex(nextRowIndex);
      }
    },
    [editingRowIndex, rows[0]?.estimationMepSheetDetails, stateError, loader]
  );
  useEffect(() => {
    if (editingRowIndex !== null && firstColumnInputRef.current && !isLoading) {
      firstColumnInputRef.current.focus();
    }
  }, [editingRowIndex, isLoading]);

  useEffect(() => {
    // Filter rows[0]?.estimationAmcSheetDetails? based on rowSelection keys
    if (rowSelection) {
      const selectedRowIds = rows[0]?.estimationMepSheetDetails
        ?.filter((_, index) => rowSelection[index]) // Check if row index is selected in rowSelection
        .map((row) => row?.estimationMepSheetDetailId); // Get the `estimationAmcSheetDetailId` of selected rows[0]?.estimationAmcSheetDetails?

      setSelectedRowIds(selectedRowIds);
    }
  }, [rowSelection]);

  const handleCancelAndAddNewRow = () => {
    const newId = 'new_row_' + Date.now();
    reset();
    setEditingRowData(null);
    setEditingRowId(null);
    setRowState({
      newRowId: newId,
      isCreatingRow: true,
    });

    setEditingRowIndex(
      editingRowIndex === rows[0]?.estimationMepSheetDetails?.length
        ? editingRowIndex + 1
        : rows[0]?.estimationMepSheetDetails?.length
    );
    setNewRowCreated(false);
    setRows((prevRows) =>
      prevRows.map((row, index) => {
        if (index === 0) {
          // Add a new row to the estimationAmcSheetDetails of the first row
          return {
            ...row,
            estimationMepSheetDetails: [
              ...row.estimationMepSheetDetails,
              {
                estimationMepSheetId: newId,
                estimationMepSheetDetailId: id,
                bill: '',
                estimationTypeId: '',
                lineItem: '',
                unitSellingPrice: 0,
                subHead: '',
                quantity: 0,
                unitOfMeasurementId: '',
                currencyId: '',
                materialCost: 0,
                installationCost: 0,
                subTotalAmount: 0,
                actualUnitPrice: 0,
                overHeadPercent: 0,
                overHeadUnitPrice: 0,
                contingencyPercent: 0,
                contingencyUnitPrice: 0,
                markUpPercent: 0,
                markupUnitPrice: 0,
                discountPercent: 0,
                discountUnitPrice: 0,
                negotiationPercent: 0,
                negotiationUnitPrice: 0,
                totalMepAmount: 0,
                isEditable: true,
                isNew: true,
              },
            ],
          };
        }
        return row; // Return other rows unchanged
      })
    );
    reset();
  };

  const columns = [
    {
      accessorKey: 'bill',
      header: 'Bill',
      enableEditing: true,
      Cell: ({ cell }) => {
        const isNewRow =
          cell?.row?.original?.estimationMepSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === cell?.row?.original?.estimationMepSheetDetailId ||
          isNewRow;

        return isEditing ? (
          <FmTextField
            name='bill'
            control={control}
            label='Bill'
            onBlur={handleBlur}
            inputRef={firstColumnInputRef}
          />
        ) : (
          cell?.row?.original?.bill
        );
      },
    },
    {
      accessorKey: 'head',
      header: 'Head',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationMepSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationMepSheetDetailId ||
          isNewRow;
        return isEditing ? (
          <FmTextField
            name='head'
            control={control}
            label='Head'
            onBlur={handleBlur}
          />
        ) : (
          row?.original?.head
        );
      },
    },
    {
      accessorKey: 'subHead',
      header: 'Sub Head',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationMepSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationMepSheetDetailId ||
          isNewRow;
        return isEditing ? (
          <FmTextField
            name='subHead'
            control={control}
            label='subHead'
            onBlur={handleBlur}
          />
        ) : (
          row?.original?.subHead
        );
      },
    },

    {
      accessorKey: 'lineItem',
      header: 'Line Item',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationMepSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationMepSheetDetailId ||
          isNewRow;
        return isEditing ? (
          <FmTextField
            name='lineItem'
            control={control}
            label='Line Item'
            onBlur={handleBlur}
          />
        ) : (
          row?.original?.lineItem
        );
      },
    },

    {
      accessorKey: 'lineItemShort',
      header: 'Line Item Short',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationMepSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationMepSheetDetailId ||
          isNewRow;
        return isEditing ? (
          <FmTextField
            name='lineItemShort'
            control={control}
            label='Line Item Short'
            onBlur={handleBlur}
          />
        ) : (
          row?.original?.lineItemShort
        );
      },
    },
    {
      accessorKey: 'quantity',
      header: 'Quantity',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationMepSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationMepSheetDetailId ||
          isNewRow;
        return isEditing ? (
          <FmTextField
            name='quantity'
            control={control}
            label='Quantity'
            pattern='Number'
            defaultValue={editingRowData?.quantity || ''}
            onChange={(e) => {
              setEditingRowData((prev) => ({
                ...prev,
                quantity: e.target.value,
              }));
            }}
            onBlur={handleBlur}
          />
        ) : (
          row?.original?.quantity
        );
      },
    },
    {
      accessorKey: 'currencyId',
      header: 'Currency',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationMepSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationMepSheetDetailId ||
          isNewRow;
        return isEditing ? (
          <FmAutoComplete
            name='currencyId'
            control={control}
            options={currencies}
            label='currency'
            displayField='currencyName'
            optionFields={['currencyName']}
            valueKey='currencyId'
            onBlur={handleBlur}
          />
        ) : (
          row?.original?.currencyName || ''
        );
      },
    },
    {
      accessorKey: 'unitOfMeasurementId',
      header: 'Unit Of Measurement',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationMepSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationMepSheetDetailId ||
          isNewRow;
        return isEditing ? (
          <FmAutoComplete
            name='unitOfMeasurementId'
            control={control}
            options={unitofmeasurements}
            label='UOM'
            displayField='unitOfMeasurementName'
            optionFields={['unitOfMeasurementName']}
            valueKey='unitOfMeasurementId'
            onBlur={handleBlur}
          />
        ) : (
          row?.original?.unitOfMeasurementName || ''
        );
      },
    },
    {
      accessorKey: 'materialCost',
      header: 'Material Cost',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationMepSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationMepSheetDetailId ||
          isNewRow;
        return isEditing ? (
          <FmTextField
            name='materialCost'
            control={control}
            label='Material Cost'
            pattern='Number'
            defaultValue={editingRowData?.materialCost || ''}
            onChange={(e) => {
              setEditingRowData((prev) => ({
                ...prev,
                materialCost: e.target.value,
              }));
            }}
            onBlur={handleBlur}
          />
        ) : (
          row?.original?.materialCost
        );
      },
    },

    {
      accessorKey: 'installationCost',
      header: 'Installation Cost',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationMepSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationMepSheetDetailId ||
          isNewRow;
        return isEditing ? (
          <FmTextField
            name='installationCost'
            control={control}
            label='Installation Cost'
            pattern='Number'
            defaultValue={editingRowData?.installationCost || ''}
            onChange={(e) => {
              setEditingRowData((prev) => ({
                ...prev,
                installationCost: e.target.value,
              }));
            }}
            onBlur={handleBlur}
          />
        ) : (
          row?.original?.installationCost
        );
      },
    },

    {
      accessorKey: 'subTotalAmount',
      header: 'Sub Total',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationMepSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationMepSheetDetailId ||
          isNewRow;
        return isEditing
          ? editingRowData?.subTotalAmount || ''
          : row?.original?.subTotalAmount || 0;
      },
    },
    {
      accessorKey: 'actualUnitPrice',
      header: 'Actual Unit Price',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationMepSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationMepSheetDetailId ||
          isNewRow;
        return isEditing
          ? editingRowData?.actualUnitPrice || 0
          : row?.original?.actualUnitPrice || 0;
      },
    },
    {
      accessorKey: 'overHeadPercent',
      header: 'Over Head(%)',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationMepSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationMepSheetDetailId ||
          isNewRow;
        return isEditing ? (
          <FmTextField
            name='overHeadPercent'
            control={control}
            label='Over Head(%)'
            pattern='Number'
            defaultValue={editingRowData?.overHeadPercent || ''}
            onChange={(e) => {
              setEditingRowData((prev) => ({
                ...prev,
                overHeadPercent: e.target.value,
              }));
            }}
            onBlur={handleBlur}
          />
        ) : (
          row?.original?.overHeadPercent
        );
      },
    },
    {
      accessorKey: 'overHeadUnitPrice',
      header: 'Over Head With Unit Price',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationMepSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationMepSheetDetailId ||
          isNewRow;
        return isEditing
          ? editingRowData?.overHeadUnitPrice || 0
          : row?.original?.overHeadUnitPrice || 0;
      },
    },
    {
      accessorKey: 'contingencyPercent',
      header: 'Contingency (%)',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationMepSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationMepSheetDetailId ||
          isNewRow;
        return isEditing ? (
          <FmTextField
            name='contingencyPercent'
            control={control}
            label='Contingency (%)'
            pattern='Number'
            defaultValue={editingRowData?.contingencyPercent || ''}
            onChange={(e) => {
              setEditingRowData((prev) => ({
                ...prev,
                contingencyPercent: e.target.value,
              }));
            }}
            onBlur={handleBlur}
          />
        ) : (
          row?.original?.contingencyPercent
        );
      },
    },
    {
      accessorKey: 'contingencyUnitPrice',
      header: 'Contingency With Unit Price',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationMepSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationMepSheetDetailId ||
          isNewRow;
        return isEditing
          ? editingRowData?.contingencyUnitPrice || 0
          : row?.original?.contingencyUnitPrice || 0;
      },
    },
    {
      accessorKey: 'markUpPercent',
      header: 'Mark Up (%)',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationMepSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationMepSheetDetailId ||
          isNewRow;
        return isEditing ? (
          <FmTextField
            name='markUpPercent'
            control={control}
            label='Mark Up (%)'
            pattern='Number'
            defaultValue={editingRowData?.markUpPercent || ''}
            onChange={(e) => {
              setEditingRowData((prev) => ({
                ...prev,
                markUpPercent: e.target.value,
              }));
            }}
            onBlur={handleBlur}
          />
        ) : (
          row?.original?.markUpPercent
        );
      },
    },
    {
      accessorKey: 'markupUnitPrice',
      header: 'Mark Up With Unit Price',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationMepSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationMepSheetDetailId ||
          isNewRow;
        return isEditing
          ? editingRowData?.markupUnitPrice || 0
          : row?.original?.markupUnitPrice || 0;
      },
    },

    {
      accessorKey: 'negotiationPercent',
      header: 'Negotiation (%)',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationMepSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationMepSheetDetailId ||
          isNewRow;
        return isEditing ? (
          <FmTextField
            name='negotiationPercent'
            control={control}
            label='Negotiation (%)'
            pattern='Percentage'
            defaultValue={editingRowData?.negotiationPercent || ''}
            onChange={(e) => {
              setEditingRowData((prev) => ({
                ...prev,
                negotiationPercent: e.target.value,
              }));
            }}
            onBlur={handleBlur}
          />
        ) : (
          row?.original?.negotiationPercent
        );
      },
    },
    {
      accessorKey: 'negotiationUnitPrice',
      header: 'Negotiation With Unit Price',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationMepSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationMepSheetDetailId ||
          isNewRow;
        return isEditing
          ? editingRowData?.negotiationUnitPrice || 0
          : row?.original?.negotiationUnitPrice;
      },
    },
    {
      accessorKey: 'discountPercent',
      header: 'Discount (%)',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationMepSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationMepSheetDetailId ||
          isNewRow;
        return isEditing ? (
          <FmTextField
            name='discountPercent'
            control={control}
            label='Discount (%)'
            pattern='Percentage'
            defaultValue={editingRowData?.discountPercent || 0}
            onChange={(e) => {
              setEditingRowData((prev) => ({
                ...prev,
                discountPercent: e.target.value,
              }));
            }}
            onBlur={handleBlurAndFetch}
          />
        ) : (
          row?.original?.discountPercent
        );
      },
    },
    {
      accessorKey: 'discountUnitPrice',
      header: 'Discount With Unit Price',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationMepSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationMepSheetDetailId ||
          isNewRow;
        return isEditing
          ? editingRowData?.discountUnitPrice || 0
          : row?.original?.discountUnitPrice || 0;
      },
    },
    {
      accessorKey: 'unitPrice',
      header: 'Unit Price',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationMepSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationMepSheetDetailId ||
          isNewRow;
        return isEditing
          ? editingRowData?.unitPrice || 0
          : row?.original?.unitPrice || 0;
      },
    },
    {
      accessorKey: 'totalMepAmount',
      header: 'Total MEP Amount',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationMepSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationMepSheetDetailId ||
          isNewRow;
        return isEditing
          ? editingRowData?.totalMepAmount || 0
          : row?.original?.totalMepAmount || 0;
      },
    },

    {
      header: 'Action',
      size: 60,
      // enableColumnPinning: true,
      // enableEditing: false,
      // enableSorting: false,
      Cell: ({ row, table }) => {
        const isNewRow =
          row?.original?.estimationMepSheetId === rowState?.newRowId;

        const isEditing =
          editingRowId === row?.original?.estimationMepSheetDetailId ||
          isNewRow;

        // Determine if the current row is the last row in the table
        const isLastRow =
          row.index === rows[0]?.estimationMepSheetDetails?.length - 1;

        return (
          <Box className='flex gap-2'>
            {isEditing ? (
              <>
                {/* <Button onClick={handleSubmit(handleUpdateAMCDetails)}>
                  Save
                </Button> */}
                <Button onClick={() => handleCancel(row)}>Close</Button>
                {isLastRow && rowState?.isCreatingRow === false && (
                  <Button
                    onFocus={() => {
                      handleCancelAndAddNewRow(row);
                    }}
                  ></Button>
                )}
                {isLastRow && rowState?.isCreatingRow === true && (
                  <Button
                    onFocus={() => {
                      handleAddNewRow();
                    }}
                  ></Button>
                )}
              </>
            ) : (
              <Box className='flex gap-2'>
                {/* Show Add button only for the last row */}
                {isLastRow && (
                  <Button
                    variant='contained'
                    color='primary'
                    className='px-3 p-1 ms-3'
                    onClick={() => {
                      setEditingRowIndex(row?.index + 1);
                      handleAddNewRow(row);
                    }}
                  >
                    Add
                  </Button>
                )}
              </Box>
            )}
          </Box>
        );
      },
    },
  ];

  useEffect(() => {
    if (
      amcSheetsPercent.overHeadOverAllPercent ||
      amcSheetsPercent.contingencyOverAllPercent ||
      amcSheetsPercent.markUpOverAllPercent ||
      amcSheetsPercent.discountOverAllPercent ||
      amcSheetsPercent.negotiationPercent
    ) {
      const updatedRows = rows.map((row) => ({
        ...row,
        estimationMepSheetDetails: row.estimationMepSheetDetails?.map(
          (item) => {
            const updatedItem = { ...item }; // Avoid mutating the original item

            // Apply overHeadOverAllPercent if available
            if (amcSheetsPercent.overHeadOverAllPercent !== undefined) {
              updatedItem.overHeadPercent =
                amcSheetsPercent.overHeadOverAllPercent;
              updatedItem.overHeadUnitPrice =
                (updatedItem.actualUnitPrice * updatedItem.overHeadPercent) /
                  100 +
                updatedItem.actualUnitPrice;
            }

            // Apply contingencyOverAllPercent with condition
            if (amcSheetsPercent.contingencyOverAllPercent !== undefined) {
              updatedItem.contingencyPercent =
                amcSheetsPercent.contingencyOverAllPercent === 0
                  ? updatedItem.contingencyPercent
                  : amcSheetsPercent.contingencyOverAllPercent;
              updatedItem.contingencyUnitPrice =
                (updatedItem.overHeadUnitPrice *
                  updatedItem.contingencyPercent) /
                  100 +
                updatedItem.overHeadUnitPrice;
            }

            // Apply markUpOverAllPercent with condition
            if (amcSheetsPercent.markUpOverAllPercent !== undefined) {
              updatedItem.markUpPercent =
                amcSheetsPercent.markUpOverAllPercent === 0
                  ? updatedItem.markUpPercent
                  : amcSheetsPercent.markUpOverAllPercent;
              updatedItem.markupUnitPrice =
                (updatedItem.contingencyUnitPrice * updatedItem.markUpPercent) /
                  100 +
                updatedItem.contingencyUnitPrice;
            }

            // Apply negotiationPercent with condition
            if (amcSheetsPercent.negotiationPercent !== undefined) {
              updatedItem.negotiationPercent =
                amcSheetsPercent.negotiationPercent === 0
                  ? updatedItem.negotiationPercent
                  : amcSheetsPercent.negotiationPercent;
              updatedItem.negotiationUnitPrice =
                (updatedItem.markupUnitPrice * updatedItem.negotiationPercent) /
                  100 +
                updatedItem.markupUnitPrice;
            }

            // Apply discountOverAllPercent with condition
            if (amcSheetsPercent.discountOverAllPercent !== undefined) {
              updatedItem.discountPercent =
                amcSheetsPercent.discountOverAllPercent === 0
                  ? updatedItem.discountPercent
                  : amcSheetsPercent.discountOverAllPercent;
              updatedItem.discountUnitPrice =
                (updatedItem.negotiationUnitPrice *
                  updatedItem.discountPercent) /
                  100 +
                updatedItem.negotiationUnitPrice;
            }

            if (updatedItem?.discountUnitPrice && updatedItem?.quantity) {
              updatedItem.unitPrice = parseFloat(
                (updatedItem.discountUnitPrice * updatedItem.quantity).toFixed(
                  2
                )
              );
            }

            // Calculate total Mep amount
            updatedItem.totalMepAmount =
              updatedItem.unitPrice +
              updatedItem.installationCost +
              updatedItem?.materialCost;

            return updatedItem;
          }
        ),
      }));

      setRows(updatedRows);
    }
  }, [amcSheetsPercent]);

  const delectCheckBoxClicked = async () => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BASE}/estimationmepsheetdetails`,
        {
          headers: {
            accept: '*/*',
            'Content-Type': 'application/json-patch+json',
          },
          data: {
            estimationMepSheetDetailIds: selectedRowIds,
          },
        }
      );
      toast.success('Data Deleted Successfully');
      fetchData();
      setRowSelection({});
      setSelectedRowIds([]);
    } catch (error) {
      console.error('Error deleting data:', error);
    }
  };

  return (
    <>
      <Box>
        <GlassCard className='overflow-hidden'>
          {/* New Table */}

          <FmMRTDataTable
            muiTableContainerProps={{
              sx: {
                maxHeight: '300px',
              },
            }}
            columns={columns}
            enableRowSelection
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            delectCheckBoxClicked={delectCheckBoxClicked}
            rows={rows[0]?.estimationMepSheetDetails || []}
            onCreateRow={handleAddNewRow}
            isCreatingRow={rowState?.isCreatingRow}
            rowCount={rows[0]?.estimationMepSheetDetails?.length}
            editingRow={editingRowId}
            pagination={pagination}
            setSearchKeyword={setSearchKeyword}
            sorting={sorting}
            columnFilters={columnFilters}
            globalFilter={globalFilter}
            setPagination={setPagination}
            setSorting={setSorting}
            setColumnFilters={setColumnFilters}
            setGlobalFilter={setGlobalFilter}
            isLoading={isLoading}
            fetchData={fetchData}
            handleRowDoubleClick={(row) => {
              handleEdit(row?.original);
              setEditingRowIndex(row?.index);
            }}
            disableNewButton={false}
            setSearchKeyword={setSearchKeyword}
            searchKeyword={searchKeyword}
            disableNewButton={rows[0]?.estimationMepSheetDetails?.length === 0}
            handleExport={handleExportMEP}
            handleImport={() => {
              setOpenUpload(true);
              setUploadedfileData(null);
            }}
          />
        </GlassCard>
        {!totalBoxLoader && (
          <TotalBox
            rows={rows[0]?.estimationMepSheetDetails || []}
            amcSheetsPercent={amcSheetsPercent}
            editedingRowData={editingRowData}
            total={amcSubtotal != 0 ? amcSubtotal : total}
            setAmcSheetsPercent={setAmcSheetsPercent}
            handleTotalSubmit={handleTotalSubmit}
            fieldEdited={fieldEdited}
          />
        )}{' '}
      </Box>
      <Dialogs
        productView={productView}
        setProductView={setProductView}
        viewData={viewData}
      />
      <MuiDialogOne
        open={openUpload}
        onClose={() => setOpenUpload(false)}
        title='Import MEP Details'
        subHead='Upload your amc details Xlsx file'
      >
        <FmFileInput
          name='file'
          documentName={uploadedfileData?.originalFileName || ''}
          onChange={handleFileUpload}
          loading={uploadLoading}
        />
        <ActionButtons
          onSubmit={handleSubmitImport}
          onCancel={() => setOpenUpload(false)}
          onReset={reset}
          submitLoading={createLoading}
          cancelLoading={false}
        />
      </MuiDialogOne>
    </>
  );
};

export default ViewEstimationMEP;
