import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import Crud_Service from '../../../apis/CrudService';
import { ActionButtons } from '../../../components/_form';
import FmAutoComplete from '../../../components/_mui/FmAutoComplete.tsx';
import FmSearchableSelect from '../../../components/_mui/FmSearchableSelect.tsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import CommonLoader from '../../../components/page/CommonLoader.jsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import {
  CommonTrueFalse,
  statusOptions,
} from '../../../utils/CommonVariables.tsx';

const EditClients = () => {
  const navigate = useNavigate();
  const crud = new Crud_Service();
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const validationSchema = Yup.object().shape({
    clientName: Yup.string()
      .required('Client Name is required')
      .min(2, 'Client Name must be at least 2 characters')
      .max(100, 'Client Name must not exceed 100 characters'),
    contactEmailId: Yup.string().email(
      'Contact email ID must be a valid email address'
    ),
    contactNumber: Yup.string()
      .min(8, 'Contact Number must be at least 8 characters')
      .max(12, 'Contact Number must be at less than 12 characters')
      .nullable()
      .transform((value, originalValue) =>
        originalValue === '' ? null : value
      ),
  });
  const { handleSubmit, control, setValue, reset } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });
  const { createLoading, startCreateLoading, stopCreateLoading } = useLoader();
  const { t } = useTranslation();
  const [editData, setEditData] = useState(null);
  const [COA, setCOA] = React.useState(false);
  const [ICV, setICV] = React.useState(false);

  useEffect(() => {
    !COA && setValue('advanceAccountNeededInChartOfAccount', null);
    !COA && setValue('retentionAccountNeededInChartOfAccount', null);
    !ICV && setValue('icvCertifiedScore', '');
  }, [COA, ICV]);

  useEffect(() => {
    getAllClients();
  }, []);

  //   Setting the updated client values
  const getAllClients = async () => {
    setLoading(true);
    await crud.getSingle('clients', id, (_err, res) => {
      if (res?.status === 200) {
        Object.entries({
          ...res?.data,
        }).forEach(([key, value]) => {
          setValue(key, value);
          if (key === 'icvCertified') {
            setICV(value);
          }
          if (key === 'accountNeededInChartOfAccount') {
            setCOA(value);
          }
        });
        setEditData(res?.data);
        setLoading(false);
      } else {
      }
    });
  };

  const handleEditClients = async (values) => {
    startCreateLoading();

    const combinedData = {
      ...values,
      tradeLicenseStartDate: dayjs(values?.tradeLicenseStartDate).format(
        'YYYY-MM-DD'
      ),
      tradeLicenseEndDate: dayjs(values?.tradeLicenseEndDate).format(
        'YYYY-MM-DD'
      ),
    };

    await crud.update(`clients`, id, combinedData, (_err, res) => {
      if (res?.status === 200) {
        toast.success(t('client Updated Successfully'));
        navigate(-1);
        stopCreateLoading();
      } else {
        stopCreateLoading();
      }
    });
  };

  return (
    <DashboardLayout title='Edit Clients'>
      <Box className='mx-auto' sx={{ maxWidth: '800px' }}>
        <GlassCard className='p-5'>
          {loading ? (
            <CommonLoader />
          ) : (
            <form onSubmit={handleSubmit(handleEditClients)}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Grid container spacing={3}>
                  <Grid item md={6}>
                    <FmTextField
                      name='clientName'
                      control={control}
                      label='Name'
                      required
                    />
                  </Grid>
                  <Grid item md={6}>
                    <FmTextField
                      name='manualClientCode'
                      control={control}
                      label='Manual Client Code'
                    />
                  </Grid>
                  <Grid item md={6}>
                    <FmSearchableSelect
                      name='parentClientId'
                      control={control}
                      apiUrl='clients'
                      valueField='clientId'
                      headerField={['Code', 'Name']}
                      labelField={['clientCode', 'clientName']}
                      showField={['clientName']}
                      label='Parent Client'
                      defaultValue={{
                        clientId: editData?.parentClientId,
                        clientName: editData?.parentClientName,
                      }}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <FmTextField
                      name='clientIndustry'
                      control={control}
                      label='Client Industry'
                    />
                  </Grid>
                  <Grid item md={12}>
                    <FmTextField
                      name='headOfficeAddress'
                      control={control}
                      label='Head Office Address'
                      multiline
                      rows={3}
                      maxRows={4}
                    />
                  </Grid>

                  <Grid item md={6}>
                    <FmTextField
                      name='contactPerson'
                      control={control}
                      label='Contact Person'
                    />
                  </Grid>

                  <Grid item md={6}>
                    <FmTextField
                      name='contactNumber'
                      control={control}
                      label='Contact Number'
                      type='number'
                    />
                  </Grid>
                  <Grid item md={6}>
                    <FmTextField
                      name='contactEmailId'
                      control={control}
                      label='Contact Email'
                      type='email'
                    />
                  </Grid>
                  <Grid item md={6}>
                    <FmTextField
                      name='ownerName'
                      control={control}
                      label='Owner Name'
                    />
                  </Grid>
                  <Grid item md={6}>
                    <FmTextField
                      name='trnNumber'
                      control={control}
                      label='TRN Number'
                    />
                  </Grid>
                  <Grid item md={6}>
                    <FmAutoComplete
                      name='accountNeededInChartOfAccount'
                      control={control}
                      options={CommonTrueFalse}
                      label='Account Needed In Chart Of Account'
                      displayField='label'
                      onChange={(e) => setCOA(e)}
                      optionFields={['label']}
                      valueKey='value'
                    />
                  </Grid>
                  {COA && (
                    <>
                      <Grid item md={6}>
                        <FmAutoComplete
                          name='advanceAccountNeededInChartOfAccount'
                          control={control}
                          options={CommonTrueFalse}
                          label='Advance Account Needed In Chart Of Account'
                          displayField='label'
                          optionFields={['label']}
                          valueKey='value'
                        />
                      </Grid>
                      <Grid item md={6}>
                        <FmAutoComplete
                          name='retentionAccountNeededInChartOfAccount'
                          control={control}
                          options={CommonTrueFalse}
                          label='Retention Account Needed In Chart Of Account'
                          displayField='label'
                          optionFields={['label']}
                          valueKey='value'
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item md={6}>
                    <FmAutoComplete
                      name='icvCertified'
                      control={control}
                      options={CommonTrueFalse}
                      label='ICV Certified'
                      displayField='label'
                      onChange={(e) => setICV(e)}
                      optionFields={['label']}
                      valueKey='value'
                    />
                  </Grid>
                  {ICV && (
                    <Grid item md={6}>
                      <FmTextField
                        name='icvCertifiedScore'
                        control={control}
                        label='ICV Certified Score'
                      />
                    </Grid>
                  )}
                  <Grid item md={6}>
                    <FmAutoComplete
                      name='status'
                      control={control}
                      options={statusOptions}
                      label='Status'
                      displayField='statusName'
                      optionFields={['statusName']}
                      valueKey='statusId'
                    />
                  </Grid>
                </Grid>
                <br />
                <ActionButtons
                  onSubmit={handleSubmit(handleEditClients)}
                  onCancel={() => navigate(-1)}
                  onReset={reset}
                  submitLoading={createLoading}
                  cancelLoading={false}
                  submitText='Update'
                />
              </LocalizationProvider>
            </form>
          )}
        </GlassCard>
      </Box>
    </DashboardLayout>
  );
};

export default EditClients;
