import React, { memo } from 'react';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import '@cyntler/react-doc-viewer/dist/index.css';
import { Box, IconButton } from '@mui/material';
import { Icon } from '@iconify/react/dist/iconify.js';

const FileViewer = memo(
  ({ documents, onClose, name, disableClose = 'false' }) => {
    return (
      <Box
        className='flex flex-col h-full relative'
        sx={{
          '& #header-bar': {
            padding: 0,
            fontSize: '15px',
            minHeight: '45px',
            borderBottom: '1px solid',
            borderColor: 'border.main',
            '& #doc-nav': {
              '& #doc-nav-info': {
                fontSize: '13px',
                opacity: 0.5,
              },
              '& button': {
                bgcolor: 'primary.main',
                boxShadow: 'none',
              },
            },
          },

          '& #pdf-controls': {
            boxShadow: 'none',
            background: 'none',
            borderColor: '#dae0ed',
            zIndex: 10,
            display: 'inline-flex',
            maxWidth: 'max-content',
            left: 'auto',
            right: !disableClose ? '80px' : '0px',
            border: 'none',
            padding: '7px 25px',
            borderTop: 'none',
            position: 'absolute',
            color: 'text.main',
            '& button, & a': {
              boxShadow: 'none',
              color: 'inherit',
              background: 'none',
            },
            '& #pdf-pagination-info, & button, & a': {
              opacity: 1,
              color: 'inherit',
              '&:disabled': {
                opacity: 0.3,
              },
              '& #search-plus-icon': {
                fill: 'text.main',
              },
            },
            '& #pdf-download svg *, & #pdf-zoom-reset svg *, & #pdf-toggle-pagination svg *':
              {
                fill: 'text.main',
              },
            '& #pdf-pagination svg path': {
              fill: 'text.main',
            },
          },

          '& #proxy-renderer': {
            minHeight: '100%',
            '& #msdoc-renderer': {
              minHeight: '100%',
            },
            '& table': {
              '& td, & th': {
                border: '1px solid',
                borderColor: 'border.main',
              },
            },
          },
        }}
      >
        <Box
          className='flex justify-between items-center p-1 px-2'
          sx={{
            borderBottom: '1px solid',
            borderColor: 'border.main',
            minHeight: '45px',
          }}
        >
          <Box className='font-bold text-sm'>{name}</Box>
          {!disableClose && (
            <Box>
              <IconButton
                aria-label='close'
                onClick={onClose}
                sx={{ color: 'inherit' }}
              >
                <Icon icon='material-symbols:close' />
              </IconButton>
            </Box>
          )}
        </Box>
        <Box className='overflow-auto flex-1 min-h-0'>
          <DocViewer
            documents={documents}
            style={{ width: '100%' }}
            pluginRenderers={DocViewerRenderers}
            config={{
              header: { disableHeader: true },
            }}
          />
        </Box>
      </Box>
    );
  }
);

export default FileViewer;
