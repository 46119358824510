import React, { useState, useEffect } from 'react';
import DataTable from '../../../components/DataTable.tsx';
import { Icon } from '@iconify/react';
import { Box } from '@mui/material';
import GlassCard from '../../../components/small/GlassCard.tsx';
import TableDropDown from '../../../components/helpers/TableDropDown.tsx';
import { CreateButton } from '../../../components/small/Buttons.jsx';
import { useNavigate } from 'react-router-dom';
import Crud_Service from '../../../apis/CrudService.jsx';
import { toast } from 'react-toastify';
import {
  StatusBadge,
  TextColor,
} from '../../../components/_form/FormElements.jsx';
import { useTranslation } from 'react-i18next';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import useTableLogic from '../../../components/helpers/UseTableLogic.tsx';
import DataTableTopBar from '../../../components/datagrid/DataTableTopBar.tsx';
import TableExtraContentTooltip from '../../../components/datagrid/TableExtraContentTooltip.tsx';
import TextElipseTooltip from '../../../components/datagrid/TextElipseTooltip.tsx';

const Suppliers = () => {
  const navigate = useNavigate();
  const [assetView] = useState(false);
  const {
    rows,
    pageSize,
    pageNumber,
    setSearchKeyword,
    isLoading,
    tableRecordCounts,
    searchKeyword,
    handlePaginationModelChange,
    handleSortModelChange,
    fetchData,
  } = useTableLogic('suppliers');

  const crud = new Crud_Service();
  const { t } = useTranslation();

  useEffect(() => {
    document.title = `Presales | Suppliers`;
  }, []);

  // View Details
  const handleViewClick = async (props) => {
    navigate(`view/${props?.supplierId}`);
  };

  //Navigate Edit Page
  const handleEditClick = async (props) => {
    navigate(`edit/${props?.supplierId}`);
  };
  const handleAdddContacts = async (props) => {
    navigate(`add-contacts/${props?.supplierId}`, {
      state: { supplierData: props },
    });
  };
  const handleAddDocuments = async (props) => {
    navigate(`add-documents/${props?.supplierId}`, {
      state: { supplierData: props },
    });
  };

  const handledeleteClick = async (props) => {
    await crud.remove(`suppliers`, props?.supplierId, (err, res) => {
      if (err) {
        toast.error(err);
        return;
      }
      if (res?.status === 204) {
        toast.success('Suppliers Deleted Successfully');
        fetchData();
      }
    });
  };

  const ActionData = [
    {
      name: 'Add Contacts',
      icon: <Icon icon='pixelarticons:contact-plus' />,
      onClick: (props) => handleAdddContacts(props),
    },
    {
      name: 'Add Documents',
      icon: <Icon icon='fluent:document-add-48-regular' />,
      onClick: (props) => handleAddDocuments(props),
    },
    {
      name: 'View Details',
      icon: <Icon icon='solar:eye-outline' />,
      onClick: (props) => handleViewClick(props),
    },
    {
      name: t('edit'),
      icon: <Icon icon='solar:pen-new-round-linear' />,
      onClick: (props) => handleEditClick(props),
    },
    {
      name: 'Delete',
      icon: <Icon icon='mi:delete' />,
      onClick: (props) => handledeleteClick(props),
      danger: true,
    },
  ];

  const columns = [
    {
      field: 'action',
      headerName: '',
      width: 70,
      sortable: false,
      hideable: false,
      disableClickEventBubbling: false,
      renderCell: (params) => {
        return (
          <TableDropDown
            Actions={ActionData?.map((action) => ({
              ...action,
              onClick: () => action.onClick(params.row),
            }))}
            selectedRow={params.row}
            setSelectedRow={() => { }}
          />
        );
      },
    },
    {
      field: 'supplierCode',
      headerName: 'Supplier',
      width: 150,
      renderCell: (params) => {
        return (
          <TableExtraContentTooltip
            titleRows={[
              { label: 'Supplier Code', title: params?.row?.supplierCode },
              { label: 'Supplier', title: params?.row?.officialSupplierName },
              { label: 'Supplier Type', title: params?.row?.supplierTypeName },
            
            ]}
          >
           <TextElipseTooltip name={params.value} twoline />
          </TableExtraContentTooltip>
        );
      },
    },
 
    {
      field: 'address',
      headerName: 'Address',
      width: 200,
    },
    {
      field: 'headOfficeAddress',
      headerName: 'Head Office Address',
      width: 200,
    },
    {
      field: 'regionName',
      headerName: 'Region',
      width: 200,
    },
    {
      field: 'countryName',
      headerName: 'Country',
      minWidth: 220,
      cellClassName: 'font-semibold',
    },
    {
      field: 'taxNumber',
      headerName: 'Tax',
      width: 150,
      renderCell: (params) => {
        return (
          <TableExtraContentTooltip
            titleRows={[
              { label: 'Tax Number', title: params?.row?.taxNumber },
              { label: 'Tax Percentage', title: params?.row?.taxPercentage },
            
            ]}
          >
           <TextElipseTooltip name={params.value} twoline />
          </TableExtraContentTooltip>
        );
      },
    },
   
    {
      field: 'tradeLicenseNumber',
      headerName: 'Trade License Number',
      width: 200,
    },
    {
      field: 'agreement',
      headerName: 'Agreement',
      width: 150,
      renderCell: (params) => {
        return (
          <TextColor
            title={params.value === true ? 'Yes' : 'No'}
            color={params.value === true ? 'green' : 'red'}
          />
        );
      },
    },
    // {
    //   field: 'debitORCredit',
    //   headerName: 'Debit/Credit',
    //   width: 200,
    //   renderCell: (params) => {
    //     const isCredit = params.value === true;
    //     const creditLimit = params.row?.creditLimit; // Access the creditLimit value from the row data
    //     const displayText = isCredit
    //       ? `Credit${creditLimit ? ` (${creditLimit})` : ''}`
    //       : 'Debit';

    //     return (
    //       <TextColor
    //         title={displayText}
    //         color={isCredit ? 'green' : 'red'}
    //       />
    //     );
    //   },
    // }
    {
      field: 'debitORCredit',
      headerName: 'Debit/Credit',
      width: 250,
      renderCell: (params) => {
        const isCredit = params.value === true;
        const creditLimit = params.row?.creditLimit;
        const displayText = isCredit ? 'Credit' : 'Debit';

        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <TextColor title={displayText} color={isCredit ? 'green' : 'red'} />
            {isCredit && creditLimit !== undefined && (
              <Box
                sx={{
                  fontWeight: 'bold',
                  marginTop: '2px',
                }}
              >
                {creditLimit}
              </Box>
            )}
          </Box>
        );
      },
    },
  ];

  const onRowDoubleClick = (e) => {
    if (e?.row) {
      handleEditClick(e?.row);
    }
  };

  return (
    <DashboardLayout
      title='Suppliers'
      actionButtons={
        <CreateButton name='New' onClick={() => navigate('create')} />
      }
    >
      {!assetView && (
        <GlassCard className='overflow-hidden h-full-css'>
          <Box className='h-full-css'>
            <DataTable
              rows={rows}
              columns={columns}
              loading={isLoading}
              onRowDoubleClick={(e) => onRowDoubleClick(e)}
              sortingMode='server'
              paginationMode='server'
              onPaginationModelChange={(model) =>
                handlePaginationModelChange(model)
              }
              onSortModelChange={(model) => handleSortModelChange(model)}
              page={pageNumber - 1}
              pageSize={pageSize}
              rowCount={tableRecordCounts?.total}
              slots={{
                toolbar: () => (
                  <DataTableTopBar
                    setSearchKeyword={setSearchKeyword}
                    searchValue={searchKeyword}
                  />
                ),
              }}
            />
          </Box>
        </GlassCard>
      )}
    </DashboardLayout>
  );
};

export default Suppliers;
