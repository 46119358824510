import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Crud_Service from '../../../../apis/CrudService.jsx';
import ActionButtons from '../../../../components/_form/ActionButtons.tsx';
import FmDatePicker from '../../../../components/_mui/FmDatePicker.tsx';
import FmRadioButton from '../../../../components/_mui/FmRadioButton.tsx';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import FmTimePicker from '../../../../components/_mui/FmTimePicker.tsx';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import { vehiclemonthlyupdatesSchema } from '../../../../utils/CommonvalidationSchemas.tsx';

const GridValue = 3;
const AddMonthUpdate = (props) => {
  // const { fetchData } = props;
  const crud = new Crud_Service();
  const {
    handleSubmit,
    control,
    reset,
    watch,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(vehiclemonthlyupdatesSchema),
    mode: 'onChange',
  });
  const navigate = useNavigate();
  const { startCreateLoading, stopCreateLoading, createLoading } = useLoader();
  const vehicleMethodChange = watch('vehicleMethod') === 'true' ? true : false;

  const handleSubmitMonthUpdate = async (values) => {
    startCreateLoading();

    const combinedData = {
      ...values,
      ownVehicleId: values?.ownVehicleId || null,
      hiredVehicleId: values?.hiredVehicleId || null,
      contractStartingPeriod: dayjs(values?.contractStartingPeriod).format(
        'YYYY-MM-DD'
      ),
      contractEndingPeriod: dayjs(values?.contractEndingPeriod).format(
        'YYYY-MM-DD'
      ),
    };

    await crud.create('vehiclemonthlyupdates', combinedData, (_err, res) => {
      if (res?.status === 201) {
        toast.success('MonthlyUpdate  Created Successfully');
        navigate(-1);
        stopCreateLoading();
      } else {
        stopCreateLoading();
      }
    });
  };
  const contractStartingPeriod = watch('contractStartingPeriod');

  return (
    <DashboardLayout title='Create Monthly Update'>
      <GlassCard className='mb-3 p-3'>
        <form onSubmit={handleSubmit(handleSubmitMonthUpdate)}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid container spacing={2}>
              <Grid item md={GridValue}>
                <FmSearchableSelect
                  name='divisionId'
                  control={control}
                  apiUrl='divisions'
                  valueField='divisionId'
                  labelField={['divisionName']}
                  showField={['divisionName']}
                  label='Division'
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmRadioButton
                  name='vehicleMethod'
                  control={control}
                  options={[
                    { value: false, label: 'No' },
                    { value: true, label: 'Yes' },
                  ]}
                  label='Vehicle Method'
                />
              </Grid>
              <Grid item md={GridValue}>
                {vehicleMethodChange === true ? (
                  <FmSearchableSelect
                    name='ownVehicleId'
                    control={control}
                    apiUrl='ownvehicles'
                    valueField='ownVehicleId'
                    labelField={['vehicleName']}
                    showField={['vehicleName']}
                    label='Own Vehicle'
                  />
                ) : (
                  <FmSearchableSelect
                    name='hiredVehicleId'
                    control={control}
                    apiUrl='hiredVehicles'
                    valueField='hiredVehicleId'
                    labelField={['hiredVehicleCode']}
                    showField={['hiredVehicleCode']}
                    label='Hired Vehicle'
                  />
                )}
              </Grid>
              <Grid item md={GridValue}>
                <FmSearchableSelect
                  name='month'
                  control={control}
                  apiUrl='months'
                  valueField='monthId'
                  labelField={['monthName']}
                  showField={['monthName']}
                  label='Month'
                />
              </Grid>
              <Grid item md={GridValue}>
                <Controller
                  name='year'
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <DatePicker
                      label='Year'
                      views={['year']}
                      value={value ? dayjs().set('year', value) : null}
                      onChange={(newValue) => {
                        const year = newValue ? dayjs(newValue).year() : null;
                        onChange(year);
                      }}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: !!error,
                          helperText: error ? error.message : null,
                          required: true,
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmSearchableSelect
                  name='locationId'
                  control={control}
                  apiUrl='locations'
                  valueField='locationId'
                  labelField={['locationName']}
                  showField={['locationName']}
                  label='Location'
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmSearchableSelect
                  name='projectId'
                  control={control}
                  apiUrl='projects'
                  valueField='projectId'
                  labelField={['projectName']}
                  showField={['projectName']}
                  label='Project'
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmSearchableSelect
                  name='siteId'
                  control={control}
                  apiUrl='buildings'
                  valueField='buildingId'
                  labelField={['buildingName']}
                  showField={['buildingName']}
                  label='Site'
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmTextField
                  name='currentKilometer'
                  label='Current Kilometer'
                  control={control}
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmTimePicker
                  name='pickUpTime'
                  label='Pick-Up Time'
                  control={control}
                  required
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmTimePicker
                  name='dropoffTime'
                  label='Drop-Off Time'
                  control={control}
                  required
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmTextField
                  name='transporterName'
                  label='Transporter Name'
                  control={control}
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmDatePicker
                  name='contractStartingPeriod'
                  label='Contract Starting Period'
                  control={control}
                  onChangeProp={() => trigger('contractEndingPeriod')}
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmDatePicker
                  name='contractEndingPeriod'
                  label='Contract Ending Period'
                  control={control}
                  onChangeProp={() => trigger('contractStartingPeriod')}
                  defaultValue={dayjs().add(1, 'day')}
                  minDate={contractStartingPeriod}
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmTextField
                  name='rentAmount'
                  label='Rent Amount'
                  control={control}
                  pattern='Number'
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmSearchableSelect
                  name='contactPersonId'
                  control={control}
                  apiUrl='loginusers'
                  valueField='loginUserId'
                  labelField={['employeeCode', 'loginUserName']}
                  showField={['employeeCode', 'loginUserName']}
                  label='Contact Person'
                />
              </Grid>
              <Grid item md={6}>
                <FmTextField
                  name='remarks'
                  control={control}
                  label='Remarks'
                  multiline={true}
                  rows={2}
                  maxRows={3}
                />
              </Grid>
            </Grid>
            <ActionButtons
              onSubmit={handleSubmit(handleSubmitMonthUpdate)}
              onReset={reset}
              submitLoading={createLoading}
              submitText='Create'
              cancelText='Cancel'
              onCancel={() => navigate(-1)}
            />
          </LocalizationProvider>
        </form>
      </GlassCard>
    </DashboardLayout>
  );
};
export default AddMonthUpdate;
