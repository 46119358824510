import React, { useState } from 'react';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { MaterialReactTable } from 'material-react-table';
import MRTExpandTableStyle from '../../../components/table/MRTExpandTableStyle.tsx';
import { Box } from '@mui/material';
import useTableLogic from '../../../components/helpers/MRTUseTableLogic.tsx';
import FmMRTDataTable from '../../../components/FmMRTDataTable.tsx';
import dayjs from 'dayjs';

const GratuityAccruals = () => {
  const {
    rows,
    rowCount,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic(
    'employeeaccuralsgratuities',
    ['fromDate'],
    [dayjs().format('YYYY-MM-DD')]
  );

  // Define the columns
  const columns = [
    {
      accessorKey: 'employeeName',
      header: 'Employee Name',
    },
    {
      accessorKey: 'employeeCode',
      header: 'Employee Code',
    },

    {
      accessorKey: 'joiningDate',
      header: 'Joining Date',
      size: 310,
    },

    {
      accessorKey: 'startDate',
      header: 'Start Date',
    },

    {
      accessorKey: 'endDate',
      header: 'End Date',
    },

    {
      accessorKey: 'gratuityPerDay',
      header: 'Gratuity Per Day',
    },

    {
      accessorKey: 'gratuityTotal',
      header: 'Gratuity Total',
    },

    {
      accessorKey: 'lastGenerationDate',
      header: 'Last Generation Date',
    },

    {
      accessorKey: 'lastGenerationDays',
      header: 'Last Generation Days',
    },

    {
      accessorKey: 'runningStatusName',
      header: 'Running Status',
    },
  ];

  return (
    <GlassCard className='overflow-hidden h-full-css'>
      <MRTExpandTableStyle enableFullHeight>
        <Box>
          <FmMRTDataTable
            columns={columns}
            enableRowSelection={false}
            rows={rows}
            rowCount={tableRecordCounts}
            // editingRow={editingRowId}
            pagination={pagination}
            sorting={sorting}
            columnFilters={columnFilters}
            globalFilter={globalFilter}
            setPagination={setPagination}
            setSorting={setSorting}
            fetchData={fetchData}
            setColumnFilters={setColumnFilters}
            setGlobalFilter={setGlobalFilter}
            isLoading={isLoading}
            setSearchKeyword={setSearchKeyword}
            searchKeyword={searchKeyword}
          />
        </Box>
      </MRTExpandTableStyle>
    </GlassCard>
  );
};

export default GratuityAccruals;
