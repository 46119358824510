import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Crud_Service from '../../../../../apis/CrudService';
import { ActionButtons } from '../../../../../components/_form';
import FmDatePicker from '../../../../../components/_mui/FmDatePicker.tsx';
import FmFileInput from '../../../../../components/_mui/FmFileInput.tsx';
import FmSearchableSelect from '../../../../../components/_mui/FmSearchableSelect.tsx';
import FmTextField from '../../../../../components/_mui/FmTextField.tsx';
import GlassCard from '../../../../../components/small/GlassCard.tsx';
import { PurchaseVoucherSchema } from '../../../../../utils/CommonvalidationSchemas.tsx';
import CommonLoader from '../../../../../components/page/CommonLoader.jsx';
import useLoader from '../../../../../components/helpers/UseLoader.tsx';
import FmCurrencyTextField from '../../../../../components/_mui/FmCurrencyTextField.tsx';

const GridSize = 2.4;

const EditPV = (props) => {
  const { type, id } = props;
  const navigate = useNavigate();

  const crud = new Crud_Service();
  const [salesOrder, setSalesOrder] = React.useState();
  const { control, handleSubmit, reset, getValues, setValue } = useForm({
    resolver: yupResolver(PurchaseVoucherSchema),
    mode: 'onChange',
  });
  const { editLoading, stopEditLoading, startEditLoading } = useLoader();
  const [loading, setLoading] = useState(false);
  const [fileId, setFileId] = useState(null);
  const [pVData, setPVData] = useState({});
  const [dataLoad, setDataLoad] = useState(false);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setDataLoad(true);
    await crud.getSingle('purchasevouchers', id, (_err, res) => {
      if (res) {
        setPVData(res?.data);
        Object.entries(res?.data).map(([key, value]) => {
          setValue(key, value);
          if (key === 'vendorId') {
            setValue('supplierId', value);
          }
        });
      }
      setDataLoad(false);
    });
  };
  const submitPurchaseVoucher = async (values) => {
    startEditLoading();
    const data = {
      ...values,
      purchaseVoucherDate: dayjs(values?.purchaseVoucherDate).format(
        'YYYY-MM-DD'
      ),
      paymentDueDate: dayjs(values?.paymentDueDate).format('YYYY-MM-DD'),
      invoiceDate: dayjs(values?.invoiceDate).format('YYYY-MM-DD'),
      purchaseVoucherTypeId: type,
      purchaseVoucherStatusId: 1,
      vendorId: values.supplierId,
      fileId: fileId,
    };
    await crud.update('purchasevouchers', id, data, (_err, res) => {
      if (res?.status === 200) {
        toast.success('Purchase Voucher Updated Successfully');
        stopEditLoading();
        fetchData();
      } else {
        stopEditLoading();
      }
    });
  };

  const handleSalesOrderChange = (ev) => {
    setSalesOrder(ev);
    setValue('locationId', ev?.locationId ?? null, { shouldValidate: true });
    setValue('approvedBy', ev?.approvedBy ?? null, { shouldValidate: true });
    setValue('companyId', ev?.companyId ?? null, { shouldValidate: true });
    setValue('currencyId', ev?.currencyId ?? '', { shouldValidate: true });
    setValue('paymentTypeId', ev?.paymentTypeId ?? null, {
      shouldValidate: true,
    });
  };
  const handleFileUpload = async (event) => {
    setLoading(true);
    const file = event.target.files[0];
    if (file) {
      const fileFormData = new FormData();
      fileFormData.append('file', file);
      fileFormData.append('fileTypeId', 33);
      try {
        const response = await new Promise((resolve, reject) => {
          crud.create('files/upload', fileFormData, (_err, res) => {
            if (res?.status === 200) {
              resolve(res);
              setLoading(false);
            } else {
              reject(new Error('File upload failed'));
              setLoading(false);
            }
          });
        });
        setFileId(response?.data?.fileId);
        setValue(`documentName`, response?.data?.originalFileName);
      } catch (error) {
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
  };
  const [selectedVendorInvoice, setSelectedVendorInvoice] = useState(null);
  const handleVendorInvoiceChange = (ev) => {
    setSelectedVendorInvoice(ev);
  };
  return (
    <GlassCard className='p-3'>
      <form onSubmit={handleSubmit(submitPurchaseVoucher)}>
        {dataLoad ? (
          <CommonLoader />
        ) : (
          <Grid container spacing={2}>
            <Grid item md={GridSize}>
              <FmTextField
                name='purchaseVoucherName'
                label='Name'
                control={control}
              />
            </Grid>

            <Grid item md={GridSize}>
              <FmTextField
                name='purchaseVoucherCode'
                label='Code'
                control={control}
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='salesOrderId'
                control={control}
                apiUrl='salesorders'
                valueField='salesOrderId'
                labelField={['salesOrderCode', 'salesOrderName']}
                defaultValue={{
                  salesOrderId: pVData?.salesOrderId,
                  salesOrderName: pVData?.salesOrderName,
                }}
                showField={['salesOrderCode', 'salesOrderName']}
                onChangeProp={(ev) => handleSalesOrderChange(ev)}
                label='Sales Order'
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='companyId'
                label='Company'
                apiUrl={'companies'}
                valueField='companyId'
                labelField={['companyName', 'companyCode']}
                showField={['companyName', 'companyCode']}
                control={control}
                defaultValue={{
                  companyId: pVData?.companyId,
                  companyName: pVData?.companyName,
                }}
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='divisionId'
                control={control}
                apiUrl='divisions'
                valueField='divisionId'
                labelField={['divisionName']}
                showField={['divisionName']}
                label='Division'
                defaultValue={{
                  divisionId: pVData?.divisionId,
                  divisionName: pVData?.divisionName,
                }}
              />
            </Grid>

            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='supplierId'
                control={control}
                apiUrl='suppliers'
                valueField='supplierId'
                defaultValue={{
                  supplierId: pVData?.vendorId,
                  officialSupplierName: pVData?.vendorName,
                  supplierCode: pVData?.vendorInvoiceItemCode,
                }}
                labelField={['supplierCode', 'officialSupplierName']}
                showField={['supplierCode', 'officialSupplierName']}
                required
                label={'Vendor'}
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='vendorDocumentId'
                control={control}
                apiUrl='vendordocuments'
                valueField='vendorDocumentId'
                headerField={['Invoice No', 'LPO No']}
                labelField={['invoiceNumber', 'localPurchaseOrderNo']}
                showField={['invoiceNumber', 'localPurchaseOrderNo']}
                defaultValue={{
                  vendorDocumentId: pVData?.vendorDocumentId,
                  invoiceNumber: pVData?.vendorDocumentCode,
                }}
                onChangeProp={(ev) => handleVendorInvoiceChange(ev)}
                label={'Vendor Document'}
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='locationId'
                label='Location'
                required
                apiUrl={'locations'}
                valueField='locationId'
                labelField={['locationName', 'locationCode']}
                showField={['locationName', 'locationCode']}
                control={control}
                defaultValue={{
                  locationId: salesOrder?.locationId || pVData?.locationId,
                  locationName:
                    salesOrder?.locationName || pVData?.locationName,
                }}
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='supplyLocationId'
                label='Supply Location'
                apiUrl='locations'
                valueField='locationId'
                required
                labelField={['locationName', 'locationCode']}
                showField={['locationName', 'locationCode']}
                control={control}
                defaultValue={{
                  locationId: pVData?.supplyLocationId,
                  locationName: pVData?.supplyLocationName,
                }}
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmDatePicker
                name='invoiceDate'
                label='Supplier Invoice Date'
                control={control}
              />
            </Grid>

            {/* <Grid item md={GridSize}>
              <FmDatePicker
                name='purchaseVoucherDate'
                label='Purchase Voucher Date'
                control={control}
              />
            </Grid> */}

            <Grid item md={GridSize}>
              <FmTextField
                name='invoiceNo'
                label='Invoice No'
                control={control}
              />
            </Grid>

            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='currencyId'
                label='Currency'
                apiUrl={'currencies'}
                valueField='currencyId'
                required
                labelField={['currencyName', 'currencyCode']}
                showField={['currencyName', 'currencyCode']}
                control={control}
                defaultValue={{
                  currencyId: salesOrder?.currencyId || pVData?.currencyId,
                  currencyName:
                    salesOrder?.currencyName || pVData?.currencyName,
                }}
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='paymentTermId'
                control={control}
                apiUrl='paymentterms'
                valueField='paymentTermId'
                headerField={['Payment Term']}
                labelField={['paymentTermName']}
                showField={['paymentTermName']}
                defaultValue={pVData}
                label='Payment Term'
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='paymentTypeId'
                control={control}
                apiUrl='paymenttypes'
                valueField='paymentTypeId'
                headerField={['Payment Type']}
                labelField={['paymentTypeName']}
                showField={['paymentTypeName']}
                label='Payment Type'
                defaultValue={{
                  paymentTypeId:
                    salesOrder?.paymentTypeId || pVData?.paymentTypeId,
                  paymentTypeName:
                    salesOrder?.paymentTypeName || pVData?.paymentTypeName,
                }}
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmDatePicker
                name='paymentDueDate'
                label='Payment Due Date'
                control={control}
              />
            </Grid>

            <Grid item md={GridSize}>
              <FmTextField
                name='exchangeRate'
                label='Exchange Rate'
                control={control}
                type='Number'
              />
            </Grid>

            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='inCharge'
                control={control}
                apiUrl='loginusers'
                valueField='loginUserId'
                labelField={['employeeCode', 'loginUserName']}
                showField={['employeeCode', 'loginUserName']}
                defaultValue={{
                  loginUserId: pVData?.inCharge,
                  loginUserName: pVData?.inChargeUserName,
                }}
                label='In Charge'
              />
            </Grid>

            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='requestorId'
                control={control}
                apiUrl='loginusers'
                valueField='loginUserId'
                labelField={['employeeCode', 'loginUserName']}
                showField={['employeeCode', 'loginUserName']}
                label='Requestor'
                defaultValue={{
                  loginUserId: pVData?.requestorId,
                  loginUserName: pVData?.requestorName,
                }}
              />
            </Grid>

            <Grid item md={GridSize}>
              <FmTextField
                name='billStatus'
                label='Bill Status'
                control={control}
              />
            </Grid>

            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='approvedBy'
                control={control}
                apiUrl='loginusers'
                valueField='loginUserId'
                labelField={['employeeCode', 'loginUserName']}
                showField={['employeeCode', 'loginUserName']}
                label='Approved By'
                defaultValue={{
                  loginUserId: pVData?.approvedBy,
                  loginUserName: pVData?.approvedByName,
                }}
              />
            </Grid>

            <Grid item md={GridSize}>
              <FmFileInput
                name={`fileId`}
                documentName={getValues(`documentName`)}
                onChange={(e) => handleFileUpload(e)}
                loading={loading}
              />
            </Grid>

            <Grid item md={GridSize * 2}>
              <FmTextField
                name='remarks'
                multiline
                rows={4}
                label='Remarks'
                control={control}
              />
            </Grid>

            <Grid item md={12}>
              <ActionButtons
                onSubmit={handleSubmit(submitPurchaseVoucher)}
                onReset={reset}
                submitLoading={editLoading}
                cancelLoading={false}
                onCancel={() => navigate(-1)}
                cancelText='Go Back'
                submitText='Update'
              />
            </Grid>
          </Grid>
        )}
      </form>
    </GlassCard>
  );
};

export default EditPV;
