//TODO
// import React, { useState } from 'react';
// import { Controller } from 'react-hook-form';
// import Autocomplete from '@mui/material/Autocomplete';
// import TextField from '@mui/material/TextField';
// import { Box } from '@mui/material';

// interface FmAutoCompleteProps {
//   name: string;
//   control: any;
//   required?: boolean;
//   label: string;
//   options: Array<{ [key: string]: any }>;
//   rules?: { [key: string]: any };
//   displayField: string;
//   value?: any;
//   onChange?: (value: any) => void;
//   valueKey: string;
//   optionFields?: Array<string>;
//   disabled?: boolean;
//   defaultValue?: any;
//   onChangeValue?: Boolean;
//   readOnly?: Boolean;
//   isMultiSelect?: boolean; // New prop for multi-select
//   onBlur?: () => void; // Add onBlur prop
//   inputRef?: any;
//   sortKey?: string;
// }

// const FmAutoComplete = (props: FmAutoCompleteProps) => {
//   const {
//     name,
//     control,
//     label,
//     options,
//     rules = {},
//     displayField,
//     onChange,
//     onChangeValue,
//     valueKey,
//     optionFields = [],
//     disabled,
//     defaultValue,
//     readOnly,
//     isMultiSelect = false,
//     required = false,
//     onBlur,
//     inputRef,
//     sortKey,
//   } = props;

//   const [inputValue, setInputValue] = useState('');

//   // Filter options based on input value
//   const filterOptions = (options, { inputValue }) => {
//     return options.filter((option) =>
//       optionFields.some((field) =>
//         (option[field]?.toLowerCase() || '').includes(inputValue.toLowerCase())
//       )
//     );
//   };

//   const sortedOptions = sortKey
//     ? [...options].sort((a, b) => {
//         if (a[sortKey] && b[sortKey]) {
//           return a[sortKey].localeCompare(b[sortKey]);
//         }
//         return 0;
//       })
//     : options;

//   return (
//     <Controller
//       name={name}
//       control={control}
//       rules={rules}
//       render={({ field, fieldState: { error } }) => (
//         <>
//           <Autocomplete
//             {...field}
//             multiple={isMultiSelect}
//             value={
//               isMultiSelect
//                 ? options?.filter((opt) => field.value?.includes(opt[valueKey])) // for Multi-Select
//                 : options?.find((opt) => opt[valueKey] == field.value) || null // TODO change to === issue in AMC MEP MANPOWER
//             }
//             defaultValue={defaultValue}
//             onChange={(_event, newValue) => {
//               if (isMultiSelect) {
//                 const selectedValues = newValue
//                   ? newValue.map((item) => item[valueKey])
//                   : [];
//                 field.onChange(selectedValues);
//                 if (onChange)
//                   onChange(onChangeValue ? newValue : selectedValues);
//               } else {
//                 field.onChange(newValue ? newValue[valueKey] : null);
//                 if (onChange)
//                   onChange(
//                     onChangeValue
//                       ? newValue
//                       : newValue
//                       ? newValue[valueKey]
//                       : null
//                   );
//               }
//             }}
//             inputValue={inputValue}
//             onInputChange={(_event, newInputValue) => {
//               setInputValue(newInputValue);
//             }}
//             options={sortedOptions || []}
//             getOptionLabel={(option) => option[displayField] || ''}
//             filterOptions={filterOptions}
//             renderInput={(params) => (
//               <TextField
//                 {...params}
//                 label={<>{label || 'Select Option'}</>}
//                 variant='outlined'
//                 fullWidth
//                 error={!!error}
//                 disabled={disabled || false}
//                 required={required}
//                 inputRef={inputRef}
//                 aria-readonly={readOnly || false}
//                 onBlur={() => {
//                   field.onBlur(); // Trigger react-hook-form's internal blur handler
//                   onBlur?.(); // Trigger the passed onBlur function from parent
//                 }}
//               />
//             )}
//             disabled={disabled}
//             readOnly={readOnly || false}
//             noOptionsText='No results found'
//           />
//           {error ? (
//             <Box sx={{ marginTop: 0.2, color: 'error.main', fontSize: '8px' }}>
//               {error?.message}
//             </Box>
//           ) : null}
//         </>
//       )}
//     />
//   );
// };

// export default FmAutoComplete;

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Box } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import React, { useState } from 'react';
import { Controller } from 'react-hook-form';

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

interface FmAutoCompleteProps {
  name: string;
  control: any;
  required?: boolean;
  label: string;
  options: Array<{ [key: string]: any }>;
  rules?: { [key: string]: any };
  displayField: string;
  valueKey: string;
  optionFields?: Array<string>;
  disabled?: boolean;
  defaultValue?: any;
  onChange?: (value: any) => void;
  onChangeValue?: boolean;
  readOnly?: boolean;
  isMultiSelect?: boolean;
  onBlur?: () => void;
  inputRef?: any;
  sortKey?: string;
}

const FmAutoComplete = (props: FmAutoCompleteProps) => {
  const {
    name,
    control,
    label,
    options,
    rules = {},
    displayField,
    onChange,
    onChangeValue,
    valueKey,
    optionFields = [],
    disabled,
    defaultValue,
    readOnly,
    isMultiSelect = false,
    required = false,
    onBlur,
    inputRef,
    sortKey,
  } = props;

  const [inputValue, setInputValue] = useState('');

  const filterOptions = (options, { inputValue }) =>
    options.filter((option) =>
      optionFields.some((field) =>
        (option[field]?.toLowerCase() || '').includes(inputValue.toLowerCase())
      )
    );

  const sortedOptions = sortKey
    ? [...options].sort((a, b) => {
        if (a[sortKey] && b[sortKey]) {
          return a[sortKey].localeCompare(b[sortKey]);
        }
        return 0;
      })
    : options;

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState: { error } }) => (
        <>
          <Autocomplete
            {...field}
            multiple={isMultiSelect}
            value={
              isMultiSelect
                ? options?.filter((opt) => field.value?.includes(opt[valueKey]))
                : options?.find((opt) => opt[valueKey] == field.value) || null
            }
            defaultValue={defaultValue}
            onChange={(_event, newValue) => {
              if (isMultiSelect) {
                const selectedValues = newValue
                  ? newValue.map((item) => item[valueKey])
                  : [];
                field.onChange(selectedValues);
                onChange?.(onChangeValue ? newValue : selectedValues);
              } else {
                field.onChange(newValue ? newValue[valueKey] : null);
                onChange?.(
                  onChangeValue
                    ? newValue
                    : newValue
                    ? newValue[valueKey]
                    : null
                );
              }
            }}
            inputValue={inputValue}
            onInputChange={(_event, newInputValue) =>
              setInputValue(newInputValue)
            }
            options={sortedOptions || []}
            getOptionLabel={(option) => option[displayField] || ''}
            filterOptions={filterOptions}
            disableCloseOnSelect={isMultiSelect}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                {isMultiSelect && (
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                )}
                {option[displayField]}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label={<>{label || 'Select Option'}</>}
                variant='filled'
                fullWidth
                error={!!error}
                disabled={disabled}
                sx={{
                  '& .MuiInputLabel-root': {
                    '& .MuiInputLabel-asterisk': {
                      color: 'red',
                    },
                  },
                }}
                required={required}
                inputRef={inputRef}
                aria-readonly={readOnly}
                onBlur={() => {
                  field.onBlur();
                  onBlur?.();
                }}
              />
            )}
            disabled={disabled}
            readOnly={readOnly}
            noOptionsText='No results found'
          />
          {error && (
            <Box sx={{ marginTop: 0.2, color: 'error.main', fontSize: '8px' }}>
              {error.message}
            </Box>
          )}
        </>
      )}
    />
  );
};

export default FmAutoComplete;
