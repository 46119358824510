import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import GlassCard from '../small/GlassCard.tsx';

const MuiTabs = ({ tabs, enabledTabs, value, setValues }) => {
  const handleChange = (event, newValue) => {
    setValues && setValues(newValue);
  };

  return (
    <>
      <TabContext value={value}>
        <Grid container spacing={3}>
          <Grid item md={3}>
            <TabList
              onChange={handleChange}
              aria-label='lab API tabs example'
              orientation='vertical'
              TabIndicatorProps={{ hidden: true }}
              sx={{
                '& .MuiButtonBase-root': {
                  borderRadius: '5px',
                  padding: '5px 10px',
                },
                '.Mui-selected': {
                  bgcolor: 'primary.light',
                  color: 'primary.main',
                },
              }}
            >
              {tabs.map((tab, index) => (
                <Tab
                  disabled={enabledTabs < index}
                  key={index}
                  icon={tab.icon}
                  label={tab.label}
                />
              ))}
            </TabList>
          </Grid>
          <Grid item md={9}>
            {tabs.map((tab, index) => (
              <TabPanel
                key={index}
                value={value}
                index={index}
                hidden={value !== index}
                sx={{ p: 0 }}
              >
                {tab.content}
              </TabPanel>
            ))}
          </Grid>
        </Grid>
      </TabContext>
    </>
  );
};

export default MuiTabs;
