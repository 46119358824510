import React, { useEffect, useState } from 'react';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import Crud_Service from '../../../../apis/CrudService.jsx';
import ActionButtons from '../../../../components/_form/ActionButtons.tsx';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import { useNavigate, useParams } from 'react-router-dom';
import CommonLoader from '../../../../components/page/CommonLoader.jsx';
import { CreateButton } from '../../../../components/small/Buttons.jsx';
import { ViewField } from '../../../../components/_form/FormElements.jsx';
import FmViewDateField from '../../../../components/_mui/FmViewDateField.tsx';
import apiInstance from '../../../../apis/ApiService.jsx';
import { toast } from 'react-toastify';
import { Icon } from '@iconify/react/dist/iconify.js';
import ViewAttachmentModal from '../../../../components/view/ViewAttachmentModal.tsx';
import ViewFileCard from '../../../../components/view/ViewFileCard.tsx';

const GridSize = 2.4;
const ViewCompanyCertificate = () => {
  const crud = new Crud_Service();
  const id = useParams().id;
  const [viewData, setViewData] = useState({});
  const [previewdData, setPreviewData] = useState({
    receiptFile: null,
    certificateFile: null,
  });
  const [loader, setLoader] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    setLoader(true);
    crud.getSingle('certificatecatalogpayments', id, (_err, res) => {
      if (res?.status === 200) {
        setViewData(res?.data);
        InitialDownloadFile(
          res?.data?.receiptFileId,
          res?.data?.certificateCatalog?.certificationFileId
        );
        setLoader(false);
      } else {
        setViewData({});
        setLoader(false);
      }
    });
  }, [id]);

  const InitialDownloadFile = async (receiptFileId, certificateFileId) => {
    if (!receiptFileId && !certificateFileId) {
      return;
    }

    try {
      const filePromises = [];
      if (receiptFileId) {
        filePromises.push(
          apiInstance
            .getFiles(`files/download/${receiptFileId}`)
            .then((imageUrl) => ({ receiptFile: imageUrl }))
        );
      }
      if (certificateFileId) {
        filePromises.push(
          apiInstance
            .getFiles(`files/download/${certificateFileId}`)
            .then((imageUrl) => ({ certificateFile: imageUrl }))
        );
      }

      // Wait for all files to be fetched
      const files = await Promise.all(filePromises);
      const updatedPreviewData = files.reduce(
        (acc, file) => ({ ...acc, ...file }),
        {}
      );

      // Update preview data
      setPreviewData((prevData) => ({ ...prevData, ...updatedPreviewData }));
    } catch (err) {
      toast.error(`Error fetching images: ${err.message}`);
    }
  };

  const handleDownload = async (id, fileName) => {
    try {
      const imageUrl = await apiInstance.getFiles(`files/download/${id}`);
      const link = document.createElement('a');
      link.href = imageUrl;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      toast.error('Failed to download the file. Please try again.');
    }
  };

  const renderAttachment = (fileId, attachmentName, previewUrl) => {
    let truncatedName;
    const fileName = attachmentName || '';
    if (fileName?.length > 15) {
      truncatedName = fileName.substring(0, 15) + '...';
    }
    const fileExtension = fileName?.split('.').pop()?.toLowerCase();
    const [type] = previewUrl?.split(';base64,');
    const fileType = type.split(':')[1];

    return (
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 1,
          border: '1px solid #ddd',
          borderRadius: '8px',
          overflow: 'hidden',
          '&:hover .download-icon': {
            display: 'block',
          },
          width: '140px',
          height: '140px',
          justifyContent: 'center',
        }}
      >
        {/* Download Icon (Initially Hidden, Visible on Hover) */}
        <IconButton
          className='download-icon'
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
            display: 'none',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            color: '#fff',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.7)',
            },
          }}
          onClick={() => handleDownload(fileId, fileName)}
          size='small'
        >
          <Icon icon='mdi:download' />
        </IconButton>

        {/* Render File Type or Preview */}
        {fileType.startsWith('image/') ? (
          <>
            <Box
              component='img'
              src={previewUrl}
              alt={fileName}
              sx={{
                width: '120px',
                height: '120px',
                objectFit: 'cover',
                borderRadius: '8px',
              }}
            />
          </>
        ) : (
          <>
            <Icon icon='ic:sharp-insert-drive-file' width='60' height='60' />
            <Tooltip title={fileName}>
              <span style={{ textAlign: 'center' }}>
                {truncatedName || fileName}
              </span>
            </Tooltip>
          </>
        )}
      </Box>
    );
  };

  return (
    <DashboardLayout
      title='VIew Company Certificate'
      actionButtons={
        <>
          <Box className='flex flex-row items-center whitespace-nowrap gap-3'>
            <CreateButton
              name='Create'
              onClick={() => navigate('/hrms/company-certificate/create')}
            />
          </Box>
        </>
      }
    >
      <GlassCard className='p-3'>
        {loader ? (
          <CommonLoader />
        ) : (
          <>
            <Grid container spacing={2}>
              <Grid item md={GridSize}>
                <ViewField
                  label='Certificate Catalog Payment Code'
                  title={viewData?.certificateCatalogPaymentCode}
                />
              </Grid>
              <Grid item md={GridSize}>
                <ViewField
                  label='Certificate Catalog Code'
                  title={viewData?.certificateCatalogCode}
                />
              </Grid>
              <Grid item md={GridSize}>
                <ViewField
                  label='Expense Type'
                  title={viewData?.expenseTypeName}
                />
              </Grid>
              <Grid item md={GridSize}>
                <FmViewDateField
                  title='Payment Date'
                  value={viewData?.paymentDate}
                />
              </Grid>
              <Grid item md={GridSize}>
                <ViewField
                  label='Cost Center'
                  title={viewData?.costCenterName}
                />
              </Grid>
              <Grid item md={GridSize}>
                <ViewField
                  label='Transaction Number'
                  title={viewData?.transactionNumber}
                />
              </Grid>
              <Grid item md={GridSize}>
                <ViewField label='Expense Remarks' title={viewData?.remarks} />
              </Grid>
              <Grid item md={GridSize}>
                <ViewField
                  label='Certificate Type'
                  title={viewData?.certificateCatalog?.certificateTypeName}
                />
              </Grid>
              <Grid item md={GridSize}>
                <ViewField
                  label='Certificate'
                  title={viewData?.certificateCatalog?.certificationName}
                />
              </Grid>
              <Grid item md={GridSize}>
                <ViewField
                  label='Certificate Should Be Renewed'
                  title={
                    viewData?.certificateCatalog?.certificateShouldBeRenewed ===
                    true
                      ? 'Yes'
                      : 'No'
                  }
                />
              </Grid>
              <Grid item md={GridSize}>
                <ViewField
                  label='Certificate Status'
                  title={viewData?.certificateCatalog?.certificateStatusName}
                />
              </Grid>
              <Grid item md={GridSize}>
                <ViewField
                  label='Certificate Number'
                  title={viewData?.certificateCatalog?.certificationNumber}
                />
              </Grid>
              <Grid item md={GridSize}>
                <FmViewDateField
                  title='Issued Date'
                  value={viewData?.certificateCatalog?.issuedDate}
                />
              </Grid>
              <Grid item md={GridSize}>
                <FmViewDateField
                  title='Expiry Date'
                  value={viewData?.certificateCatalog?.expiryDate}
                />
              </Grid>
              <Grid item md={GridSize}>
                <FmViewDateField
                  title='Cancellation Date'
                  value={viewData?.certificateCatalog?.cancellationDate}
                />
              </Grid>
              <Grid item md={GridSize}>
                <FmViewDateField
                  title='Created Date'
                  value={viewData?.certificateCatalog?.createdDate}
                />
              </Grid>
              <Grid item md={GridSize}>
                <FmViewDateField
                  title='Updated Date'
                  value={viewData?.certificateCatalog?.updatedDate}
                />
              </Grid>
              <Grid item md={GridSize}>
                <ViewField
                  label='Certificate Remarks'
                  title={viewData?.certificateCatalog?.remarks}
                />
              </Grid>
            </Grid>
            <br />
            <Grid container spacing={2}>
              <Grid item md={2}>
                <Box display='flex' flexWrap='wrap' gap={2}>
                  <Box
                    sx={{
                      width: '140px',
                      height: '140px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column',
                      border: '1px solid #ddd',
                      borderRadius: '8px',
                      overflow: 'hidden',
                    }}
                  >
                    {previewdData?.receiptFile &&
                      renderAttachment(
                        viewData?.receiptFileId,
                        viewData?.receiptFileName,
                        previewdData?.receiptFile
                      )}
                  </Box>
                </Box>
              </Grid>
              <Grid item md={2}>
                <Box display='flex' flexWrap='wrap' gap={2}>
                  <Box
                    sx={{
                      width: '140px',
                      height: '140px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column',
                      border: '1px solid #ddd',
                      borderRadius: '8px',
                      overflow: 'hidden',
                    }}
                  >
                    {previewdData?.certificateFile &&
                      renderAttachment(
                        viewData?.certificateCatalog?.certificationFileId,
                        viewData?.certificateCatalog?.certificationFileName,
                        previewdData?.certificateFile
                      )}
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Grid item md={12}>
              <ActionButtons
                cancelLoading={false}
                cancelText='Go Back'
                submitText='Edit'
                onSubmit={() =>
                  navigate(`/hrms/company-certificate/edit/${id}`)
                }
                onCancel={() => navigate('/hrms/company-certificate')}
              />
            </Grid>
          </>
        )}
      </GlassCard>
    </DashboardLayout>
  );
};

export default ViewCompanyCertificate;
