import React from 'react';
import { Box } from '@mui/material';

const SimpleCard = ({ title, children }) => {
  return (
    <Box className='w-full mb-4 overflow-hidden'>
      {title && (
        <Box
          className='mb-4 text-sm font-semibold'
          //color={'text.dark'}
          //sx={{ borderBottom: '1px solid', borderColor: 'border.main' }}
        >
          {title}
        </Box>
      )}
      <Box>{children}</Box>
    </Box>
  );
};

export default SimpleCard;
