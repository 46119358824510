// import React from 'react';
// import { Button, Box } from '@mui/material';

// const SideBarMenuButton = ({ name, icon, ref, ...props }) => {
//   return (
//     <Button
//       sx={{
//         width: '100%',
//         justifyContent: 'flex-start',
//         px: '2px',
//         py: 0.3,
//         borderRadius: '7px',
//         textAlign: 'left',
//         fontWeight: 700,
//         color: 'inherit',
//         '&:hover': {
//           bgcolor: 'background.overlayTwo',
//         },
//         '&.active': {
//           bgcolor: 'primary.main',
//           color: 'text.white',
//           boxShadow: '0px 0px 5px 0px rgb(0 0 0 / 8%)',
//         },
//       }}
//       title={name}
//       ref={ref}
//       {...props}
//     >
//       <Box
//         className='icon flex justify-center items-center'
//         sx={{
//           minWidth: '30px',
//           height: '30px',
//           marginRight: '5px',
//           '& svg': {
//             fontSize: '20px',
//           },
//         }}
//       >
//          dangerouslySetInnerHTML={{ __html: icon }}
//       </Box>
//       <Box sx={{ width: 'calc(100% - 45px)' }}>
//         <Box className='w-full whitespace-nowrap overflow-hidden text-ellipsis'>
//           {name}
//         </Box>
//       </Box>
//     </Button>
//   );
// };

// export default SideBarMenuButton;
import React from 'react';
import { Button, Box } from '@mui/material';
import { Icon } from '@iconify/react';

const SideBarMenuButton = ({ name, icon, ref, ...props }) => {
  // Function to render the icon JSX from the string
  const renderIcon = (iconStr) => {
    try {
      // Check if icon is in the expected format
      const iconName = iconStr.match(/'(.+)'/)[1]; // Extract icon name from the string

      // Dynamically render the Icon component from Iconify using the icon name
      return <Icon icon={iconName} />;
    } catch (error) {
      console.error('Invalid icon string format:', error);
      return null;
    }
  };

  return (
    <Button
      sx={{
        width: '100%',
        justifyContent: 'flex-start',
        px: '2px',
        py: 0.1,
        textAlign: 'left',
        fontWeight: 500,
        color: 'inherit',
        '& .icon': {
          opacity: 0.5,
        },
        '&:hover': {
          bgcolor: 'background.overlayTwo',
          '& .icon': {
            opacity: 1,
          },
          '&:before': {
            height: '100%',
          },
        },
        '&:before': {
          content: '""',
          width: '2px',
          height: '0px',
          position: 'absolute',
          top: '0px',
          bgcolor: 'text.main',
          left: 0,
          transition: '0.2s all',
        },
        '&.active': {
          bgcolor: 'primary.main',
          color: '#fff',
          overflow: 'hidden',

          '& .icon': {
            opacity: 1,
          },
          '&:before': {
            height: '100%',
            bgcolor: 'primary.main',
          },
        },
      }}
      title={name}
      ref={ref}
      {...props}
    >
      <Box
        className='icon flex justify-center items-center'
        sx={{
          minWidth: '30px',
          height: '30px',
          marginRight: '2px',
          '& svg': {
            fontSize: '20px',
          },
        }}
      >
        {/* Render the icon dynamically using the renderIcon function */}
        {renderIcon(icon)}
      </Box>
      <Box sx={{ width: 'calc(100% - 45px)' }}>
        <Box className='w-full whitespace-nowrap overflow-hidden text-ellipsis'>
          {name}
        </Box>
      </Box>
    </Button>
  );
};

export default SideBarMenuButton;
