import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import HeaderMenu from './helpers/HeaderMenu';
import HeaderNotification from './helpers/HeaderNotification';
import HeaderHistoryDropdown from './helpers/HeaderHistoryDropdown';
import PropTypes, { string } from 'prop-types';
import Icons from '../utils/Icon';
import HeaderSearchButton from './helpers/HeaderSearchButton';
import HeaderAppDropdown from './helpers/HeaderAppDropdown';
import { IconButtonWithTooltip } from './small/Buttons';
import MenuPopper from './MenuPopper';
import HeaderLanguage from './helpers/HeaderLanguage';
import { useTheme } from '../ThemeContext.jsx';

const Header = (props) => {
  const { type, HandleMenuCollapse, collaspeMenuState } = props;
  const [showMenu, setShowMenu] = useState(false);
  const { toggleTheme, mode } = useTheme();

  const [activeMenuId, setactiveMenuId] = useState(0);

  const handleMenuOpen = (i) => {
    setShowMenu(!showMenu);
    setactiveMenuId(i);
  };

  return (
    <>
      <Box
        id='header_id'
        sx={{
          width: '100%',
          display: 'flex',
          px: '8px',
          py: '0px',
          justifyContent: 'space-between',
          alignItems: 'center',
          zIndex: 11,
          background: 'linear-gradient(to right, #1c5c62, #067e89, #192d2f)',
          color: 'rgb(255 255 255 / 85%)',
        }}
        className='sm:flex-col'
      >
        <Stack direction='row' alignItems='center' spacing={3}>
          {/* <Box className='sm:hidden'>
            <IconButtonWithTooltip
              name={!collaspeMenuState ? 'Collapse' : 'Expand'}
              icon={!collaspeMenuState ? Icons.menuClose : Icons.menu}
              onClick={() => HandleMenuCollapse()}
            />
          </Box> */}
          <Box
            component='img'
            src={
              mode === 'dark'
                ? '/images/logo-white.png'
                : '/images/logo-white.png'
            }
            sx={{ maxWidth: '100%', height: '35px' }}
          />
          {type !== 'ESS' && <HeaderAppDropdown />}
          <HeaderSearchButton />
        </Stack>
        <Stack direction='row' alignItems='center' spacing={6}>
          <Stack direction='row' alignItems='center' spacing={0.5}>
            <IconButtonWithTooltip
              name='Menu'
              icon={Icons.grid}
              onClick={() => handleMenuOpen()}
            />
            <HeaderLanguage />
            <IconButtonWithTooltip
              name={mode === 'dark' ? 'Light Mode' : 'Dark Mode'}
              icon={mode === 'dark' ? Icons.sun : Icons.moon}
              onClick={toggleTheme}
            />
            <HeaderHistoryDropdown />
            <HeaderNotification />
          </Stack>
          <HeaderMenu />
        </Stack>
      </Box>
      {/* <MenuPopper
        open={showMenu}
        handleOpen={handleMenuOpen}
        activeId={activeMenuId}
        handleClose={handleMenuOpen}
      /> */}
    </>
  );
};

Header.propTypes = {
  title: PropTypes.string,
  type: string,
};

export default Header;
