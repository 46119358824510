import React from 'react';
import { Box } from '@mui/material';
import TableDropDown from '../helpers/TableDropDown.tsx';
import { Icon } from '@iconify/react';

export const CardsBox = ({ name, count, handleStatusClick, active }) => {
  return (
    <Box
      className={`p-4 relative rounded-none min-h-16 flex items-center w-full relative ps-5 cursor-pointer ${
        active && 'activeClass'
      } `}
      sx={{
        borderRight: '1px solid',
        borderColor: 'border.main',
        bgcolor: 'background.white',
        '&:hover': {
          bgcolor: 'background.mainLightBase',
        },
        '&:before, &:after': {
          content: "''",
          borderTop: '15px solid transparent',
          borderBottom: '15px solid transparent',
          borderLeft: '15px solid #d1d5db',
          position: 'absolute',
          right: -15,
          top: '50%',
          transform: 'translateY(-50%)',
          zIndex: 1,
        },
        '&:after': {
          right: -14,
          zIndex: 1,
          borderLeft: '15px solid #fff',
        },
        '&.activeClass': {
          bgcolor: 'background.mainLightBase',
          color: 'primary.main',
          '& .font-bold': {
            color: 'primary.main',
          },
        },
        '&:hover:after, &.activeClass:after': {
          borderLeftColor: 'background.mainLightBase',
        },
      }}
      onClick={handleStatusClick}
    >
      <Box>
        <Box className='font-bold text-4xl mb-3' sx={{ color: 'text.dark' }}>
          {count}
        </Box>
        <Box>{name}</Box>
      </Box>
    </Box>
  );
};


export const FileCardGrid = ({
  name,
  size,
  format,
  Actions,
  selectedRow,
  onClickView,
  setSelectedRow,
}) => {
  return (
    <Box
      className='flex gap-2 items-center p-2 justify-between'
      sx={{
        bgcolor: 'background.white',
        border: '1px solid',
        borderColor: 'border.main',
        borderRadius: '10px',
        '&:hover': {
          bgcolor: 'background.light',
        },
      }}
    >
      <Box
        className='flex items-center justify-center relative overflow-hidden cursor-pointer'
        sx={{
          width: '65px',
          height: '65px',
          flex: '0 0 auto',
          borderRadius: '5px',
          border: '1px solid',
          borderColor: 'border.main',
        }}
        onClick={onClickView}
      >
        {format === 'PNG' ? (
          <Box
            component={'img'}
            src='https://images.pexels.com/photos/3584924/pexels-photo-3584924.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
            sx={{ width: '100%', height: '100%', objectFit: 'cover' }}
            className='absolute top-0 left-0'
          />
        ) : format === 'PDF' ? (
          <Box color={'error.main'}>
            <Icon
              icon='streamline:convert-pdf-2-solid'
              width='45'
              height='45'
            />
          </Box>
        ) : (
          <Box color={'primary.main'}>
            <Icon icon='pepicons-pencil:file' width='45' height='45' />
          </Box>
        )}
      </Box>

      <Box className='flex-1 min-w-0'>
        <p className='font-bold  w-full text-ellipsis whitespace-nowrap overflow-hidden mb-1'>
          {name}
        </p>
        <p className='flex gap-1 flex-col opacity-70'>
          <span>size : {size}</span> <span>Format : {format}</span>
        </p>
      </Box>

      <TableDropDown
        Actions={Actions}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
      />
    </Box>
  );
};
