import { Box, FormControl, FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material'
import React, { useState } from 'react'
import FmSearchableSelect from '../../../../../components/_mui/FmSearchableSelect.tsx';
import FmTextField from '../../../../../components/_mui/FmTextField.tsx';
import ActionButtons from '../../../../../components/_form/ActionButtons.tsx';
import { useNavigate } from 'react-router-dom';
import useLoader from '../../../../../components/helpers/UseLoader.tsx';
import { t } from 'i18next';
import { toast } from 'react-toastify';
import Crud_Service from '../../../../../apis/CrudService.jsx';
import { useForm } from 'react-hook-form';
import GlassCard from '../../../../../components/small/GlassCard.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { CheckoutItemsValidationSchema } from '../../../../../utils/CommonvalidationSchemas.tsx';
const MdItemValue = 2;

const AddViewCheckItems = ({
    fetchData,
    checkOutFormId
}) => {
    const navigate = useNavigate();
    const {
        createLoading,
        startCreateLoading,
        stopCreateLoading,
    } = useLoader();
    const crud = new Crud_Service();
    const [checkInConditionAccept, setCheckInConditionAccept] = useState(1);
    const [checkOutConditionAccept, setCheckOutConditionAccept] = useState(1);

    const { handleSubmit, control, reset } = useForm({
        resolver: yupResolver(CheckoutItemsValidationSchema),
        mode: 'onChange',
    });
    const handleSubmitItems = async (values) => {
        const payload = {
            checkOutFormId: checkOutFormId,
            createCheckOutItems: [
                {
                    checkInLocationId: Number(values?.checkInLocationId),
                    description: values?.description,
                    checkInConditionAccept: checkInConditionAccept,
                    checkInRemarks: values?.checkInRemarks,
                    checkOutConditionAccept: checkOutConditionAccept,
                    checkOutRemarks: values?.checkOutRemarks,
                    estimationCost: values?.estimationCost,
                }
            ]
        };
        startCreateLoading();
        await crud.create('checkoutformitems', payload, (err, res) => {
            if (res?.status === 201) {
                stopCreateLoading();
                toast.success('Checkout form Created Successfully');
                fetchData();
                reset();
            } else {
                stopCreateLoading();
                toast.error(t('something Went Wrong!'));
            }
        });
    };

    return (
        <>
            <GlassCard>
                <form onSubmit={handleSubmit(handleSubmitItems)}>
                    <Box className='p-3'>
                        <Grid container spacing={2}>

                            <Grid item md={MdItemValue}>
                                <FmSearchableSelect
                                    name='checkInLocationId'
                                    control={control}
                                    apiUrl='checkInLocations'
                                    valueField='checkInLocationId'
                                    headerField={['Location Name']}
                                    labelField={['checkInLocationName']}
                                    showField={['checkInLocationName']}
                                    label='Location'
                                    required
                                />

                            </Grid>
                            <Grid item md={MdItemValue}>
                                <FmTextField
                                    name='description'
                                    control={control}
                                    label='description'
                                />
                            </Grid>
                            <Grid item md={MdItemValue}>
                                <FmTextField
                                    name='checkInRemarks'
                                    control={control}
                                    label='Check In Remarks'

                                />
                            </Grid>
                            <Grid item md={MdItemValue}>
                                <FmTextField
                                    name='checkOutRemarks'
                                    control={control}
                                    label='CheckOut Remarks'

                                />
                            </Grid>
                            <Grid item md={MdItemValue}>
                                <FmTextField
                                    name='estimationCost'
                                    control={control}
                                    label='Estimation Cost'

                                />
                            </Grid>
                            <Grid item md={MdItemValue}>
                                <FormControl>
                                    <h3 className='mb-3 font-semibold'> Check In Accepted By</h3>
                                    <RadioGroup
                                        sx={{ flexDirection: 'row' }}
                                        defaultValue={1}
                                        onChange={(e) => setCheckInConditionAccept(Number(e.target.value))}
                                    >

                                        <FormControlLabel
                                            value={1}
                                            control={<Radio />}
                                            label='Yes'
                                        />
                                        <FormControlLabel
                                            value={2}
                                            control={<Radio />}
                                            label='No'
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item md={MdItemValue}>
                                <FormControl>
                                    <h3 className='mb-3 font-semibold'> Check Out Accepted By</h3>
                                    <RadioGroup
                                        sx={{ flexDirection: 'row' }}
                                        defaultValue={1}
                                        onChange={(e) => setCheckOutConditionAccept(Number(e.target.value))}
                                    >

                                        <FormControlLabel
                                            value={1}
                                            control={<Radio />}
                                            label='Yes'
                                        />
                                        <FormControlLabel
                                            value={2}
                                            control={<Radio />}
                                            label='No'
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <ActionButtons
                            onSubmit={handleSubmit(handleSubmitItems)}
                            onReset={reset}
                            submitLoading={createLoading}
                            cancelLoading={false}
                        />
                    </Box>
                </form>
            </GlassCard>

        </>
    )
}

export default AddViewCheckItems
