import { FormHelperText, Grid, TextField } from '@mui/material';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import { ActionButtons } from '../../../../components/_form';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import Crud_Service from '../../../../apis/CrudService';
import { useNavigate } from 'react-router-dom';
import FmAutoComplete from '../../../../components/_mui/FmAutoComplete.tsx';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import useCommonFetchApi from '../../../../components/helpers/useCommonFetchApi.tsx';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';

const CampDetails = (props) => {
  const { setValues, createdEmployee, value } = props;
  const validationSchema = Yup.object().shape({
    outSideLiving: Yup.boolean().required('Outside Living is required'),
    roomNo: Yup.string().when('outSideLiving', {
      is: (outSideLiving) => outSideLiving,
      then: (schema) => schema.required('Room is required'),
      otherwise: (schema) => schema.notRequired(),
    }),
    campLocationId: Yup.string().when('outSideLiving', {
      is: (outSideLiving) => outSideLiving,
      then: (schema) => schema.required('Camp is required'),
      otherwise: (schema) => schema.notRequired(),
    }),
  });
  const {
    handleSubmit,
    control,

    reset,
  } = useForm({ resolver: yupResolver(validationSchema), mode: 'onChange' });
  const [locations, setlocations] = useState([]);
  const { createLoading, startCreateLoading, stopCreateLoading } = useLoader();
  const crud = new Crud_Service();
  const [OL, setOL] = useState(0);
  const navigate = useNavigate();
  const outSideLiving = [
    {
      label: 'Yes',
      value: 1,
    },
    {
      label: 'No',
      value: 0,
    },
  ];

  useEffect(() => {
    if (value === 6) {
      getLocations();
    }
  }, [value]);

  const getLocations = async () => {
    await crud.getAll('locations', '', (_err, res) => {
      if (res?.status === 200) {
        setlocations(res?.data?.data);
      } else {
      }
    });
  };
  const { data: gatePasses } = useCommonFetchApi('gatepasses', {});

  const handleCampSubmit = (values) => {
    startCreateLoading();

    crud.update(
      `employees/${createdEmployee?.employeeId}/employee-details/${createdEmployee?.employeeDetailId}/camp-detail`,
      '',
      values,
      (err, res) => {
        stopCreateLoading();
        if (err) {
          toast.error('Error creating employee:', err);
          return;
        }
        if (res?.status === 200) {
          toast.success('Camp Details Created Successfully!');

          navigate(-1);
        }
      }
    );
  };

  return (
    <GlassCard className='p-4'>
      <form onSubmit={handleSubmit(handleCampSubmit)}>
        <Grid container spacing={3}>
          <Grid item md={6}>
            <FmAutoComplete
              name='outSideLiving'
              control={control}
              options={outSideLiving}
              label='OutSide Living'
              displayField='label'
              onChange={(ev) => {
                setOL(ev);
              }}
              optionFields={['label']}
              valueKey='value'
            />
          </Grid>
          {OL && (
            <>
              <Grid item md={6}>
                <FmAutoComplete
                  name='campLocationId'
                  control={control}
                  options={locations}
                  label='Camp Location'
                  displayField='locationName'
                  optionFields={['locationName']}
                  valueKey='locationId'
                />
              </Grid>
              <Grid item md={6}>
                <FmTextField name='roomNo' control={control} label='Room No.' />
              </Grid>

              <Grid item md={6}>
                <FmAutoComplete
                  name='gatePassId'
                  control={control}
                  options={gatePasses}
                  label='Gate Pass'
                  displayField='gatePassName'
                  optionFields={['gatePassName']}
                  valueKey='gatePassId'
                />
              </Grid>
            </>
          )}
        </Grid>

        <ActionButtons
          onSubmit={handleSubmit(handleCampSubmit)}
          onReset={reset}
          onCancel={() => setValues(5)}
          submitLoading={createLoading}
          cancelLoading={false}
        />
      </form>
    </GlassCard>
  );
};

export default CampDetails;
