import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, IconButton } from '@mui/material';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import Crud_Service from '../../../../apis/CrudService.jsx';
import { useFieldArray, useForm } from 'react-hook-form';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import { toast } from 'react-toastify';
import useCommonFetchApi from '../../../../components/helpers/useCommonFetchApi.tsx';
import FmAutoComplete from '../../../../components/_mui/FmAutoComplete.tsx';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import dayjs from 'dayjs';
import ActionButtons from '../../../../components/_form/ActionButtons.tsx';
import FmFileInput from '../../../../components/_mui/FmFileInput.tsx';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import { useNavigate } from 'react-router-dom';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { MIPPremiumCalculatorSchema } from '../../../../utils/CommonvalidationSchemas.tsx';
import { Icon } from '@iconify/react/dist/iconify.js';

const GridItem = 2.4;

const AddMIPPremiumCalculator = (props) => {
  const { fetchData } = props;
  const crud = new Crud_Service();
  const { startCreateLoading, stopCreateLoading, createLoading } = useLoader();
  const navigate = useNavigate();
  const { control, handleSubmit, setValue, reset } = useForm({
    mode: 'onChange',
    // resolver: yupResolver(MIPPremiumCalculatorSchema),
  });

  //get Apis
  const { data: ageBands } = useCommonFetchApi(
    'companymedicalinsuranceagebands'
  );
  const { data: regions } = useCommonFetchApi('regions');
  const { data: dependencies } = useCommonFetchApi(
    'companymedicalinsurancedependencies'
  );

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'createMedicalInsurancePolicyCategories',
  });

  const handleSubmitMIPPremium = (values) => {
    startCreateLoading();
    const appendFields = values?.createMedicalInsurancePolicyCategories?.map(
      (item) => ({
        ...item,
        gradeId: Number(item?.gradeId) || null,
        male: Number(item?.male) || null,
        female: Number(item?.female) || null,
      })
    );

    const combinedData = {
      ...values,
      companyMedicalInsuranceAgeBandId: Number(
        values?.companyMedicalInsuranceAgeBandId
      ),
      regionId: Number(values?.regionId),
      companyMedicalInsuranceDependencyId: Number(
        values?.companyMedicalInsuranceDependencyId
      ),
      maternity: Number(values?.maternity),
      principal: Number(values?.principal),
      createMedicalInsurancePolicyCategories: appendFields,
    };
    crud.create('medicalinsurancepolicies', combinedData, (err, res) => {
      if (err) {
        toast.error(err);
        reset();
      }
      if (res?.status === 201) {
        toast.success('Policy Created Successfully');
        stopCreateLoading();
        navigate(-1);
        fetchData();
        reset();
      } else {
        stopCreateLoading();
      }
    });
  };

  return (
    <DashboardLayout title='Create MIP Premium Calculator'>
      <GlassCard className='p-4 mb-4'>
        <form onSubmit={handleSubmit(handleSubmitMIPPremium)}>
          <Grid container spacing={2}>
            <Grid item md={GridItem}>
              <FmAutoComplete
                name='companyMedicalInsuranceAgeBandId'
                control={control}
                options={ageBands}
                label='Age Band'
                displayField='companyMedicalInsuranceAgeBandName'
                optionFields={['companyMedicalInsuranceAgeBandName']}
                valueKey='companyMedicalInsuranceAgeBandId'
              />
            </Grid>
            <Grid item md={GridItem}>
              <FmAutoComplete
                name='regionId'
                control={control}
                options={regions}
                label='Region'
                displayField='regionName'
                optionFields={['regionName']}
                valueKey='regionId'
              />
            </Grid>
            <Grid item md={GridItem}>
              <FmAutoComplete
                name='companyMedicalInsuranceDependencyId'
                control={control}
                options={dependencies}
                label='Dependency'
                displayField='companyMedicalInsuranceDependencyName'
                optionFields={['companyMedicalInsuranceDependencyName']}
                valueKey='companyMedicalInsuranceDependencyId'
              />
            </Grid>
            <Grid item md={GridItem}>
              <FmTextField
                name='principal'
                label='Principal'
                control={control}
                pattern='Number'
              />
            </Grid>

            <Grid item md={GridItem}>
              <FmTextField
                name='maternity'
                label='Maternity'
                control={control}
                pattern='Number'
              />
            </Grid>
            {fields?.map((field, index) => (
              <Grid item xs={12} container spacing={2}>
                <Grid item md={GridItem}>
                  <FmSearchableSelect
                    name={`createMedicalInsurancePolicyCategories[${index}].gradeId`}
                    control={control}
                    headerField={['Code', 'Name']}
                    apiUrl='grades'
                    valueField='gradeId'
                    labelField={['gradeCode', 'gradeName']}
                    showField={['gradeName']}
                    label={'Grade'}
                  />
                </Grid>
                <Grid item md={GridItem}>
                  <FmTextField
                    name={`createMedicalInsurancePolicyCategories[${index}].male`}
                    label='Male'
                    control={control}
                    pattern='Number'
                  />
                </Grid>
                <Grid item md={GridItem}>
                  <FmTextField
                    name={`createMedicalInsurancePolicyCategories[${index}].female`}
                    label='Female'
                    control={control}
                    pattern='Number'
                  />
                </Grid>
                <Grid item md={GridItem}>
                  <IconButton
                    onClick={() => remove(index)}
                    disabled={fields?.length === 1}
                    sx={{
                      padding: '1px',
                    }}
                    color='secondary'
                  >
                    <Icon icon='ic:baseline-clear' />
                  </IconButton>
                </Grid>
              </Grid>
            ))}

            <Box className='mt-5 w-full flex justify-end'>
              <Button
                variant='text'
                onClick={() => append({})}
                className='uppercase font-bold text-orange-500'
                startIcon={<Icon icon='ic:baseline-plus' />}
              >
                Add Field
              </Button>
            </Box>
          </Grid>
          <ActionButtons
            onSubmit={handleSubmit(handleSubmitMIPPremium)}
            onReset={reset}
            submitText='Create'
            onCancel={() => navigate(-1)}
            cancelText='Cancel'
            submitLoading={createLoading}
            cancelLoading={false}
          />
        </form>
      </GlassCard>
    </DashboardLayout>
  );
};

export default AddMIPPremiumCalculator;
