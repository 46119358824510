import React from 'react';
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';

interface DataTableProps {
  rows: any[];
  columns: any[];
  pageSize?: number;
  pageSizeOptions?: number[];
  onPaginationModelChange?: (model: { page: number; pageSize: number }) => void;
  onSortModelChange?: (model: any[]) => void;
  sortingMode?: 'client' | 'server';
  paginationMode?: 'client' | 'server';
  getRowClassName?: (params: any) => string;
  toolbarComponent?: React.ReactNode;
  columnHeaderHeight?: number;
  onRowDoubleClick?: (e: any) => void;
  page?: number;
  initialPageSize?: number;
  rowCount?: number;
  sortableKeys?: Record<string, string>;
  showSerialNumber?: boolean;
  borderEnabled?: boolean;
  shrinkColumns?: boolean;
  [key: string]: any;
}
const DataTable = (props: DataTableProps) => {
  const {
    rows,
    columns,
    pageSize = 50,
    pageSizeOptions = [10, 25, 50, 100],
    onPaginationModelChange,
    onSortModelChange,
    isLoading,
    sortingMode,
    paginationMode,
    getRowClassName,
    toolbarComponent,
    columnHeaderHeight,
    onRowDoubleClick,
    page = 0,
    initialPageSize,
    rowCount = 0,
    sortableKeys,
    onRowSelectionModelChange,
    checkboxSelection,
    showSerialNumber,
    borderEnabled = 'false',
    shrinkColumns = 'false',
    ...rest
  } = props;

  const autosizeOptions = {
    includeOutliers: true,
  };

  const apiRef = useGridApiRef();
  apiRef.current?.autosizeColumns &&
    apiRef.current?.autosizeColumns(autosizeOptions);

  const handlesortModelChange = (sortModel) => {
    onSortModelChange(sortModel);
  };
  if (showSerialNumber) {
    columns.splice(1, 0, {
      field: 'serialNumber',
      headerName: 'S No',
      flex: 1,
      minWidth: 60,
      align: 'center',
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const index = rows.findIndex((row) => row.id === params.id);
        return index + 1;
      },
    });
  }
  return (
    <DataGrid
      rows={rows}
      columns={columns}
      pageSize={pageSize}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 50,
          },
        },
      }}
      loading={isLoading}
      pageSizeOptions={pageSizeOptions}
      sortingMode={sortingMode}
      paginationMode={paginationMode}
      getRowClassName={getRowClassName}
      onPaginationModelChange={onPaginationModelChange}
      onSortModelChange={handlesortModelChange}
      onRowDoubleClick={onRowDoubleClick}
      page={page}
      disableColumnMenu={true}
      rowHeight={32}
      getRowHeight={() => 'auto'}
      localeText={{
        toolbarColumns: '',
        toolbarExport: '',
      }}
      disableRowSelectionOnClick
      disableVirtualization
      columnHeaderHeight={columnHeaderHeight}
      rowCount={rowCount || 0}
      sx={{
        '&.MuiDataGrid-root': {
          color: 'text.main',
          border: 'none',
          background: 'none',
          fontSize: '12.5px',

          '& .MuiDataGrid-cellContent': {
            whiteSpace: !shrinkColumns ? 'normal' : 'nowrap',
            wordBreak: !shrinkColumns && 'break-word',
          },

          '& .MuiDataGrid-columnHeaderTitleContainer': {
            position: 'relative',
            minHeight: '25px',
            '& .MuiDataGrid-iconButtonContainer': {
              position: 'absolute',
              right: 0,
              top: '50%',
              zIndex: 1,
              transform: 'translateY(-50%)',
              '& .MuiIconButton-root': {
                padding: '1px',
              },
            },
          },

          '& .MuiDataGrid-columnHeader': {
            '& .MuiDataGrid-columnHeaderTitleContainer': {
              alignItems: 'center',
              opacity: 1,
              '& .MuiDataGrid-iconButtonContainer': {
                position: 'relative',
                visibility: 'visible',
                width: 'auto',
                pointerEvents: 'none',
                opacity: 0.2,
                top: 0,
                transform: 'inherit',
                '& .MuiButtonBase-root': {
                  color: 'inherit',
                  padding: 0,
                  marginLeft: '4px',
                  '&:hover': {
                    opacity: 1,
                  },
                },
                '& .MuiDataGrid-overlay': {
                  bgcolor: 'background.overlayTwo',
                },
                '& .MuiSvgIcon-root': {
                  fontSize: '18px',
                  opacity: 1,
                },
              },

              '&:hover .MuiDataGrid-iconButtonContainer': {
                opacity: 0.8,
              },
            },
            '&.MuiDataGrid-columnHeader--sorted': {
              '& .MuiDataGrid-columnHeaderTitleContainer': {
                opacity: 1,
                '&:hover': {
                  opacity: 1,
                },
                '& .MuiDataGrid-iconButtonContainer': {
                  opacity: 1,
                },
              },
            },
          },

          '& .MuiDataGrid-overlayWrapper': {
            position: 'absolute',
            height: '100%',
            '& .MuiDataGrid-overlayWrapperInner': {
              height: '100%',
            },
          },
        },

        '& .MuiDataGrid-columnHeaderTitle': {
          fontWeight: 500,
          whiteSpace: shrinkColumns ? 'nowrap' : 'pre-line',
          wordBreak: !shrinkColumns && 'break-word',
          textOverflow: shrinkColumns ? 'ellipsis' : 'inherit',
        },
        '& .MuiDataGrid-cell': {
          border: '1px solid',
          borderColor: 'border.main',
          borderTop: 'none',
          padding: '3px 5px',
          borderLeft: 'none',
          '& .MuiInputBase-root': {
            fontSize: 'inherit',
            '& .MuiOutlinedInput-notchedOutline': {
              display: 'none',
            },
          },
        },
        '& .MuiDataGrid-cell:focus': {
          outline: 'inherit!important',
        },
        '& .MuiDataGrid-cell--editable:focus': {
          outline: 'inherit !important',
        },
        '& .MuiDataGrid-withBorderColor': {
          borderColor: 'border.main',
        },
        '& .MuiDataGrid-columnHeaders': {
          bgcolor: 'background.light',
        },
        '& .MuiDataGrid-columnHeader': {
          border: '1px solid',
          borderTop: 'none',
          padding: '3px 5px',
          borderLeft: 'none',
          borderColor: 'border.main',
          outline: 'inherit !important',
          color: 'text.main',
          fontWeight: 700,
        },
        '& .MuiCheckbox-root': {
          '& .MuiSvgIcon-root': {
            fontSize: '20px',
          },
        },
        '& .MuiDataGrid-cellCheckbox': {
          outline: 'inherit !important',
        },
        '& .MuiDataGrid-columnSeparator': {
          display: 'none',
        },
        '& .MuiDataGrid-footerContainer': {
          justifyContent: 'center',
          minHeight: '38px',
          '& .MuiToolbar-root.MuiToolbar-gutters': {
            minHeight: '38px',
          },
        },
        '& .MuiTablePagination-selectLabel, & .MuiTablePagination-root, & .MuiTablePagination-displayedRows':
          {
            fontSize: 'inherit',
            color: 'inherit',
          },
        '& .MuiDataGrid-row.Mui-hovered': {
          bgcolor: 'background.light',
        },
      }}
      slotProps={{
        basePopper: {
          placement: 'bottom-end',
        },
        columnsPanel: {
          sx: {
            '& .MuiDataGrid-columnsPanelRow': {
              padding: '4px 0',
            },
            '& .MuiDataGrid-panelHeader': {
              display: 'none',
            },
          },
        },
      }}
      checkboxSelection={checkboxSelection}
      onRowSelectionModelChange={onRowSelectionModelChange}
      {...rest}
    />
  );
};

export default DataTable;
