import React, { useEffect } from 'react';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { useNavigate, useParams } from 'react-router-dom';
import CommonView from '../../../components/helpers/CommonView.jsx';
import DynamicViewFields from '../../../components/helpers/DynamicViewFields.jsx';
import { CreateButton } from '../../../components/small/Buttons.jsx';
import ActionButtons from '../../../components/_form/ActionButtons.tsx';
import ViewAttachmentModal from '../../../components/view/ViewAttachmentModal.tsx';
import { Box, Button, Grid, IconButton, Tooltip } from '@mui/material';
import Crud_Service from '../../../apis/CrudService.jsx';
import MuiDialogOne from '../../../components/_mui/MuiDialogOne.jsx';
import { Icon } from '@iconify/react';
import apiInstance from '../../../apis/ApiService.jsx';
import { toast } from 'react-toastify';
import Icons from '../../../utils/Icon.jsx';
import { ViewField } from '../../../components/_form/FormElements.jsx';
import FmViewDateField from '../../../components/_mui/FmViewDateField.tsx';

const ViewVendorDocuments = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const crud = new Crud_Service();
  const [editData, setEditData] = React.useState<any>();
  const [loading, setLoading] = React.useState(false);
  const [openViewModal, setOpenViewModal] = React.useState(false);
  const [preViewData, setPreViewData] = React.useState(false);

  useEffect(() => {
    document.title = 'Edit Vendor Document | Financial';
    getVendorDocuments();
  }, []);

  const getVendorDocuments = async () => {
    setLoading(true);
    await crud.getSingle('vendordocuments', id, (_err, res) => {
      if (res?.status === 200) {
        setEditData(res?.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  const excludeKeys = [
    'accommodationImages',
    'accommodationId',
    'locationId',
    'premisePurposeId',
    'premiseTypeId',
    'startDate',
    'updatedDate',
    'status',
  ];

  const handleDownload = async (attachment) => {
    try {
      const docUrl = await apiInstance.getFiles(
        `files/download/${attachment?.fileId}`
      );
      const link = document.createElement('a');
      link.href = docUrl;
      link.setAttribute('download', `${attachment?.fileName}`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setPreViewData(docUrl)
    } catch (err) {
      toast.error('Error fetching image:', err);
    }
  };

  const renderAttachment = (attachment) => {
    let truncatedName;
    const fileName = attachment?.fileName || '';
    if (fileName?.length > 15) {
      truncatedName = fileName.substring(0, 15) + '...';
    }
    const fileExtension = fileName?.split('.').pop()?.toLowerCase();

    return (
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 1,
          border: '1px solid #ddd',
          borderRadius: '8px',
          overflow: 'hidden',
          '&:hover .download-icon': {
            display: 'block',
          },
          width: '140px',
          height: '140px',
          justifyContent: 'center',
        }}
      >
        {/* Download Icon (Initially Hidden, Visible on Hover) */}
        <IconButton
          className='download-icon'
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
            display: 'none',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            color: '#fff',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.7)',
            },
          }}
          onClick={() => handleDownload(attachment)}
          size='small'
        >
          <Icon icon='mdi:download' />
        </IconButton>

        {/* Render File Type or Preview */}
        {fileExtension === 'pdf' ? (
          <>
            <Icon
              icon='teenyicons:pdf-solid'
              width='60'
              height='60'
              style={{ color: 'red' }}
            />
            <Tooltip title={fileName}>
              <span style={{ textAlign: 'center' }}>{fileName}</span>
            </Tooltip>
          </>
        ) : ['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension) ? (
          <Box
            component='img'
            src={preViewData}
            alt={fileName}
            sx={{
              width: '120px',
              height: '120px',
              objectFit: 'cover',
              borderRadius: '8px',
            }}
          />
        ) : (
          <>
            <Icon
              icon='lucide:file-question'
              width='60'
              height='60'
              style={{ color: 'gray' }}
            />
            <Tooltip title={fileName}>
              <span>{truncatedName}</span>
            </Tooltip>
          </>
        )}
      </Box>
    );
  };

  return (
    <DashboardLayout
      title='View Vendor Documents'
      actionButtons={
        <CreateButton
          name='Create'
          onClick={() =>
            navigate('/financial/vendor-invoice-receivable/create')
          }
        />
      }
    >
      <GlassCard className='p-5'>
        <Grid container spacing={3}>
          <Grid item md={3}>
            <ViewField
              label='Vendor Document Code'
              title={editData?.vendorDocumentCode}
            />
          </Grid>
          <Grid item md={3}>
            <ViewField
              label='Document Ref. No./Invoice No.'
              title={editData?.invoiceNumber}
            />
          </Grid>
          <Grid item md={3}>
            <ViewField
              label='Local Purchase Order No'
              title={editData?.localPurchaseOrderNo}
            />
          </Grid>
          <Grid item md={3}>
            <ViewField
              label='Received Date'
              title={<FmViewDateField value={editData?.receivedDate} />}
            />
          </Grid>
          <Grid item md={3}>
            <ViewField label='Supplier' title={editData?.supplierName} />
          </Grid>
          <Grid item md={3}>
            <ViewField
              label='Document Type'
              title={editData?.documentTypeName}
            />
          </Grid>
          <Grid item md={3}>
            <ViewField
              label='Document Transferred To'
              title={editData?.documentTransferredToUserName}
            />
          </Grid>
        </Grid>

        <br />

        <Button
          variant='contained'
          color='primary'
          onClick={() => setOpenViewModal(true)}
        >
          {Icons.view} View Attachments
        </Button>

        <MuiDialogOne
          open={openViewModal}
          onClose={() => setOpenViewModal(false)}
          title='View Attachments'
        >
          <Box display='flex' flexWrap='wrap' gap={2}>
            {editData?.vendorDocumentAttachments?.length > 0 ? (
              editData?.vendorDocumentAttachments.map((attachment, index) => (
                <Box
                  key={index}
                  sx={{
                    width: '140px',
                    height: '140px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    border: '1px solid #ddd',
                    borderRadius: '8px',
                    overflow: 'hidden',
                  }}
                >
                  {renderAttachment(attachment)}
                </Box>
              ))
            ) : (
              <Box>No attachments available</Box>
            )}
          </Box>
        </MuiDialogOne>

        <ActionButtons
          cancelLoading={false}
          cancelText='Go Back'
          submitText='Edit'
          onSubmit={() =>
            navigate(`/financial/vendor-invoice-receivable/edit/${id}`)
          }
          onCancel={() => navigate('/financial/vendor-invoice-receivable')}
        />
      </GlassCard>
    </DashboardLayout>
  );
};

export default ViewVendorDocuments;
