import React, { useEffect, useState } from 'react';
import FmMRTDataTable from '../../../../components/FmMRTDataTable.tsx';
import { Icon } from '@iconify/react/dist/iconify.js';
import { Box, Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import MuiDialogOne from '../../../../components/_mui/MuiDialogOne.jsx';
import Crud_Service from '../../../../apis/CrudService.jsx';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import TableActionButtons from '../../../../components/_form/TableActionButtons.jsx';
import CommonView from '../../../../components/helpers/CommonView.jsx';
import DynamicViewFields from '../../../../components/helpers/DynamicViewFields.jsx';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import MRTIconButton from '../../../../components/table/MRTIconButton.tsx';
import apiInstance from '../../../../apis/ApiService.jsx';

const SubContractListTable = (props) => {
  const crud = new Crud_Service();
  const [editingRowId, setEditingRowId] = useState(null);
  const [editingRowData, setEditingRowData] = useState(null);
  const { startEditLoading, stopEditLoading } = useLoader();
  const { control, setValue, handleSubmit } = useForm();
  const [viewDetails, setViewdetails] = useState(false);

  const {
    rows,
    tableRecordCounts,
    pagination,
    sorting,
    setPagination,
    setSorting,
    columnFilters,
    globalFilter,
    setColumnFilters,
    setGlobalFilter,
    isLoading,
    setSearchKeyword,
    searchKeyword,
    fetchData,
  } = props;

  const handleDownload = async (subcontractItems) => {
    if (subcontractItems?.signContractDocumentId) {
      try {
        const imageUrl = await apiInstance.getFiles(
          `files/download/${subcontractItems?.signContractDocumentId}`
        );
        const link = document.createElement('a');
        link.href = imageUrl;
        link.setAttribute('download', `${subcontractItems?.signContractDocumentName}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        toast.error('File not found');
      }
    }
  };
  const handleDownloadScopeDoc = async (subcontractItems) => {
    if (subcontractItems?.signScopeDocumentId) {
      try {
        const imageUrl = await apiInstance.getFiles(
          `files/download/${subcontractItems?.signScopeDocumentId}`
        );
        const link = document.createElement('a');
        link.href = imageUrl;
        link.setAttribute('download', subcontractItems?.signScopeDocumentName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        toast.error('File not found');
      }
    } else {
      toast.warning('Document ID is missing');
    }
  };



  const excludeKeys = ['status'];
  const ActionData = [
    {
      name: 'Edit',
      icon: <Icon icon='solar:pen-2-broken' />,
      onClick: (props) => handleEdit(props),
    },
    {
      name: 'Delete',
      icon: <Icon icon='solar:trash-bin-minimalistic-broken' />,
      onClick: (props) => handleDelete(props),
      danger: true,
    },
  ];
  const columns = [
    {
      field: 'action',
      header: 'Actions',
      sortable: false,
      hideable: false,
      disableClickEventBubbling: false,

      Cell: (params) => {
        return (
          <Box className='flex gap-2'>
            {editingRowId === params?.row?.original?.subContractId ? (
              <>
                <Button onClick={handleSubmit(handleUpdateProjectTeam)}>
                  Save
                </Button>
                <Button onClick={handleCancel}>Cancel</Button>
              </>
            ) : (
              <TableActionButtons
                Actions={ActionData?.map((action) => ({
                  ...action,
                  onClick: () => action.onClick(params.row),
                }))}
                selectedRow={params.row}
                setSelectedRow={() => { }}
              />
            )}
          </Box>
        );
      },
    },
    {
      accessorKey: 'contractId',
      header: 'Contract Name',
      enableEditing: true,
      Cell: ({ cell }) => {
        const subcontractItems: any = rows?.find(
          (item) => item?.subContractId === cell?.row?.original?.subContractId
        );
        return editingRowId === cell?.row?.original?.subContractId ? (
          <FmSearchableSelect
            name='contractId'
            control={control}
            apiUrl='contracts'
            valueField='contractId'
            defaultValue={editingRowData}
            labelField={['contractCode', 'contractName']}
            headerField={['Code', 'Name']}
            showField={['contractCode', 'contractName',]}
            label='Contract'
            required
          />
        ) : (
          subcontractItems?.contractName || ''
        );
      },
    },
    {
      accessorKey: 'divisionId',
      header: 'Division',
      enableEditing: true,
      Cell: ({ cell }) => {
        const subcontractItems: any = rows?.find(
          (item) => item?.subContractId === cell?.row?.original?.subContractId
        );
        return editingRowId === cell?.row?.original?.subContractId ? (
          <FmSearchableSelect
            name='divisionId'
            control={control}
            apiUrl='divisions'
            valueField='divisionId'
            headerField={['Code', 'Name']}
            labelField={['divisionCode', 'divisionName']}
            showField={['divisionCode', 'divisionName']}
            defaultValue={{
              divisionId: editingRowData?.divisionId,
              divisionName: editingRowData?.divisionName,
            }}
          />
        ) : (
          subcontractItems?.divisionName || ''
        );
      },
    },

    {
      accessorKey: 'supplierId',
      header: 'Supplier',
      enableEditing: true,
      Cell: ({ cell }) => {
        const subcontractItems: any = rows?.find(
          (item) => item?.subContractId === cell?.row?.original?.subContractId
        );
        return editingRowId === cell?.row?.original?.subContractId ? (
          <FmSearchableSelect
            name='supplierId'
            control={control}
            apiUrl='suppliers'
            valueField='supplierId'
            headerField={['Code', 'Name']}
            labelField={['supplierCode', 'officialSupplierName']}
            showField={['supplierCode', 'officialSupplierName']}
            defaultValue={{
              officialSupplierName: editingRowData?.officialSupplierName,
              supplierCode: editingRowData?.supplierCode,
            }}
          />
        ) : (
          subcontractItems?.officialSupplierName || ''
        );
      },
    },

    {
      accessorKey: 'primaryAddress',
      header: 'Primary Address',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.subContractId ? (
          <FmTextField
            name='primaryAddress'
            control={control}
            defaultValue={editingRowData?.primaryAddress}
          />
        ) : (
          row?.original?.primaryAddress
        );
      },
    },
    {
      accessorKey: 'secondaryAddress',
      header: 'Secondary Address',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.subContractId ? (
          <FmTextField
            name='secondaryAddress'
            control={control}
            defaultValue={editingRowData?.secondaryAddress}
          />
        ) : (
          row?.original?.secondaryAddress
        );
      },
    },
    {
      accessorKey: 'thirdAddress',
      header: 'Third Address',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.subContractId ? (
          <FmTextField
            name='thirdAddress'
            control={control}
            defaultValue={editingRowData?.thirdAddress}
          />
        ) : (
          row?.original?.thirdAddress
        );
      },
    },
    {
      accessorKey: 'primaryContactPersonId',
      header: 'Primary Contact Person',
      enableEditing: true,
      Cell: ({ cell }) => {
        const subcontractItems: any = rows?.find(
          (item) => item?.subContractId === cell?.row?.original?.subContractId
        );
        return editingRowId === cell?.row?.original?.subContractId ? (
          <FmSearchableSelect
            name='primaryContactPersonId'
            control={control}
            apiUrl='supplierspocs'
            valueField='supplierSPOCId'
            headerField={['Name', 'Phone Number']}
            labelField={['supplierSPOCName', 'phoneNumber']}
            showField={['supplierSPOCName', 'phoneNumber']}
            label={'Primary Contact Person'}
            defaultValue={{
              supplierSPOCName: editingRowData?.primaryContactPersonId,
              phoneNumber: editingRowData?.thirdContactPersonPhoneNumber,
            }}
          />
        ) : (
          subcontractItems?.primaryContactPersonName || ''
        );
      },
    },
    {
      accessorKey: 'secondaryContactPersonId',
      header: 'Second Contact Person',
      enableEditing: true,
      Cell: ({ cell }) => {
        const subcontractItems: any = rows?.find(
          (item) => item?.subContractId === cell?.row?.original?.subContractId
        );
        return editingRowId === cell?.row?.original?.subContractId ? (
          <FmSearchableSelect
            name='secondaryContactPersonId'
            control={control}
            apiUrl='supplierspocs'
            valueField='supplierSPOCId'
            headerField={['Name', 'Phone Number']}
            labelField={['supplierSPOCName', 'phoneNumber']}
            showField={['supplierSPOCName', 'phoneNumber']}
            label={'Second Contact Person'}
            defaultValue={{
              supplierSPOCName: editingRowData?.secondaryContactPersonId,
              phoneNumber: editingRowData?.thirdContactPersonPhoneNumber,
            }}
          />
        ) : (
          subcontractItems?.secondaryContactPersonName || ''
        );
      },
    },
    {
      accessorKey: 'thirdContactPersonId',
      header: 'Third Contact Person',
      enableEditing: true,
      Cell: ({ cell }) => {
        const subcontractItems: any = rows?.find(
          (item) => item?.subContractId === cell?.row?.original?.subContractId
        );
        return editingRowId === cell?.row?.original?.subContractId ? (
          <FmSearchableSelect
            name='thirdContactPersonId'
            control={control}
            apiUrl='supplierspocs'
            valueField='supplierSPOCId'
            headerField={['Name', 'Phone Number']}
            labelField={['supplierSPOCName', 'phoneNumber']}
            showField={['supplierSPOCName', 'phoneNumber']}
            label={'Third Contact Person'}
            defaultValue={{
              supplierSPOCName: editingRowData?.thirdContactPersonName,
              phoneNumber: editingRowData?.thirdContactPersonPhoneNumber,
            }}
          />
        ) : (
          subcontractItems?.thirdContactPersonName || ''
        );
      },
    },
    {
      accessorKey: 'signContractDocumentId',
      header: 'Sign Contract Document',
      enableEditing: true,
      Cell: ({ cell }) => {
        const subcontractItems: any = rows?.find(
          (item) =>
            item?.subContractId ===
            cell?.row?.original?.subContractId
        );
        return (
          <MRTIconButton
            icon={<Icon icon='akar-icons:download' />}
            tooltip={subcontractItems?.signContractDocumentName}
            onClick={() => handleDownload(subcontractItems)}
          />
        );
      },
    },
    {
      accessorKey: 'signScopeDocumentId',
      header: 'Sign Scope Document',
      enableEditing: true,
      Cell: ({ cell }) => {
        const subcontractItems: any = rows?.find(
          (item) =>
            item?.subContractId ===
            cell?.row?.original?.subContractId
        );
        return (
          <MRTIconButton
            icon={<Icon icon='akar-icons:download' />}
            tooltip={subcontractItems?.signScopeDocumentName}
            onClick={() => handleDownloadScopeDoc(subcontractItems)}
          />
        );
      },
    },
  ];
  const handleEdit = (row) => {
    setEditingRowId(row?.original?.subContractId);
    setEditingRowData({ ...row?.original });
  };

  const handleCancel = () => {
    setEditingRowId(null);
    setEditingRowData(null);
  };

  const handleDelete = async (props) => {
    await crud.remove(
      'subcontracts',
      props?.original?.subContractId,
      (_err, res) => {
        if (res?.status === 204) {
          toast.success('SubContract Deleted Successfully');
          fetchData();
        } else {
        }
      }
    );
  };
  const handleUpdateProjectTeam = async (values) => {
    try {
      startEditLoading();
      await crud.update('subcontracts', editingRowId, values, (_err, res) => {
        if (res?.status === 200) {
          stopEditLoading();
          fetchData();
          toast.success('Sub contract Updated Successfully');
          setEditingRowData(null);
          setEditingRowId(null);
        } else {
          stopEditLoading();
          toast.error('Failed to update the Team');
        }
      });
    } catch (error) {
      stopEditLoading();
      toast.error('An error occurred while updating the Incident Report');
    }
  };
  useEffect(() => {
    if (editingRowData) {
      Object.entries({
        ...editingRowData,
      }).forEach(([key, value]) => {
        setValue(key, value);
      });
    }
  }, [editingRowData, setValue]);

  return (
    <>
      <FmMRTDataTable
        columns={columns}
        enableRowSelection={false}
        rows={rows}
        rowCount={tableRecordCounts}
        editingRow={editingRowId}
        pagination={pagination}
        sorting={sorting}
        columnFilters={columnFilters}
        globalFilter={globalFilter}
        setPagination={setPagination}
        setSorting={setSorting}
        setColumnFilters={setColumnFilters}
        setGlobalFilter={setGlobalFilter}
        isLoading={isLoading}
        setSearchKeyword={setSearchKeyword}
        searchKeyword={searchKeyword}
        muiTableContainerProps={{
          sx: {
            maxHeight: '300px',
          },
        }}
      />
      {/* view */}
      <MuiDialogOne
        title='View Details'
        open={viewDetails}
        onClose={() => setViewdetails(!viewDetails)}
      >
        {viewDetails && viewDetails?.subContractId && (
          <CommonView
            url='subcontracts'
            id={viewDetails?.subContractId}
            excludeKeys={excludeKeys}
            renderFields={(data, fields) => (
              <DynamicViewFields data={data} fields={fields} />
            )}
          />
        )}
      </MuiDialogOne>
    </>
  );
};

export default SubContractListTable;
