import React, { useEffect, useState } from 'react';
import { Box, Button, Avatar, IconButton } from '@mui/material';
import { Icon } from '@iconify/react/dist/iconify.js';
import GlassCard from '../small/GlassCard.tsx';
import FileViewer from './../elements/FileViewer.tsx';

const NoFiles = ({ icons, name, desc, button }) => {
  return (
    <Box className='flex flex-col items-center justify-center h-full gap-2 p-2 text-center'>
      <Box className='text-6xl opacity-50'>{icons}</Box>
      <Box className='text-base opacity-50 font-semibold'>{name}</Box>
      <Box className='opacity-50'>{desc}</Box>
      {button}
    </Box>
  );
};

const FilePreviewUpload = ({
  outerFile,
  name,
  desc,
  file,
  disableFileName,
}) => {
  const [uploadedFiles, setUploadedFiles] = useState<File | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  // console.log(file, selectedFile);

  useEffect(() => {
    console.log(selectedFile, 'selectedFile');
  }, [selectedFile]);

  useEffect(() => {
    console.log(file, 'file');
  }, [file]);

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setUploadedFiles(file);
      setSelectedFile(file);
      outerFile(file);
    }
  };

  const handleRemoveFile = () => {
    if (uploadedFiles) {
      setUploadedFiles(null);
      setSelectedFile(null);
      outerFile(null);
    }
  };

  const handleFileSelect = (file: File) => {
    setSelectedFile(file);
    outerFile(file);
  };

  return (
    <>
      <GlassCard className='flex w-full  min-h-[500px] overflow-hidden'>
        {/* Right Panel: File Viewer */}
        <Box className='flex-1' sx={{ bgcolor: 'background.white' }}>
          {selectedFile ? (
            <>
              <Box className='flex justify-end p-2'>
                <Button
                  variant='outlined'
                  color='error'
                  onClick={() => handleRemoveFile()}
                >
                  Remove
                </Button>
              </Box>

              <FileViewer
                documents={[
                  {
                    uri: URL.createObjectURL(selectedFile),
                    name: selectedFile.name,
                  },
                ]}
                name={disableFileName ? '' : selectedFile.name}
                disableClose={true}
              />
            </>
          ) : (
            <NoFiles
              icons={
                <Icon icon='mdi-light:file-multiple' width='64' height='64' />
              }
              name={'Select a file to preview.'}
              button={
                !selectedFile && (
                  <Box className='mb-3'>
                    {/* Hidden File Input */}
                    <input
                      type='file'
                      id='file-upload'
                      accept='application/pdf'
                      style={{ display: 'none' }}
                      onChange={handleFileUpload}
                    />

                    {/* Upload Button */}
                    <Button
                      variant='outlined'
                      startIcon={
                        <Icon icon='ic:baseline-plus' width='24' height='24' />
                      }
                      onClick={() =>
                        document.getElementById('file-upload')?.click()
                      }
                      className='h-[40px] font-semibold min-w-28'
                    >
                      Upload
                    </Button>
                  </Box>
                )
              }
            />
          )}
        </Box>
      </GlassCard>
    </>
  );
};

export default FilePreviewUpload;
