import { Icon } from '@iconify/react/dist/iconify.js';

const SubMenu = ({ subMenuRef, list, position = 'right' }) => {
  return (
    <ul ref={subMenuRef} className={`sub-menu ${position}`}>
      {list?.map((item) => (
        <li key={item.title} onClick={item.onClick}>
          <span className='item-selected'>
            {item.selected && (
              <Icon icon='mdi:check-bold' width='13' height='13' />
            )}
          </span>
          {item.icon}
          <span>{item.title}</span>
        </li>
      ))}
    </ul>
  );
};

export default SubMenu;
