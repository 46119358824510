// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-tooltip {
  position: absolute;
  z-index: 1;
  bottom: -68px;
  left: 16px;
  padding: 8px;
  width: 292px;
  border-radius: 5px;
  background-color: #696969;
  text-align: left;
  margin: 0;
  font-size: 0.9em;
}
.error-tooltip::before {
  content: "";
  position: absolute;
  top: -20%;
  rotate: -45deg;
  border: 15px solid #696969;
  border-color: transparent #696969 transparent transparent;
}

.error-tooltip.right {
  left: 16px;
}
.error-tooltip.right::before {
  left: 11%;
}

.error-tooltip.left {
  left: -180px;
}
.error-tooltip.left::before {
  left: 76%;
  transform: rotate(90deg) scaleX(-1);
}

.error-tooltip.top {
  bottom: unset !important;
  top: -68px;
}
.error-tooltip.top::before {
  content: none;
}
.error-tooltip.top:after {
  content: "";
  position: absolute;
  bottom: -20%;
  left: 11%;
  rotate: -45deg;
  border: 15px solid #696969;
  border-color: transparent transparent #696969 transparent;
}

.error-tooltip.top.left::after {
  left: 76%;
  transform: rotate(90deg) scaleX(-1);
}`, "",{"version":3,"sources":["webpack://./src/lib/FileManager/components/ErrorTooltip/ErrorTooltip.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,UAAA;EACA,aAAA;EACA,UAAA;EACA,YAAA;EACA,YAAA;EACA,kBAAA;EACA,yBAAA;EACA,gBAAA;EACA,SAAA;EACA,gBAAA;AACJ;AACI;EACI,WAAA;EACA,kBAAA;EACA,SAAA;EACA,cAAA;EACA,0BAAA;EACA,yDAAA;AACR;;AAGA;EACI,UAAA;AAAJ;AAEI;EACI,SAAA;AAAR;;AAIA;EACI,YAAA;AADJ;AAGI;EACI,SAAA;EACA,mCAAA;AADR;;AAKA;EACI,wBAAA;EACA,UAAA;AAFJ;AAII;EACI,aAAA;AAFR;AAKI;EACI,WAAA;EACA,kBAAA;EACA,YAAA;EACA,SAAA;EACA,cAAA;EACA,0BAAA;EACA,yDAAA;AAHR;;AAQI;EACI,SAAA;EACA,mCAAA;AALR","sourcesContent":[".error-tooltip {\n    position: absolute;\n    z-index: 1;\n    bottom: -68px;\n    left: 16px;\n    padding: 8px;\n    width: 292px;\n    border-radius: 5px;\n    background-color: #696969;\n    text-align: left;\n    margin: 0;\n    font-size: 0.9em;\n\n    &::before {\n        content: \"\";\n        position: absolute;\n        top: -20%;\n        rotate: -45deg;\n        border: 15px solid #696969;\n        border-color: transparent #696969 transparent transparent;\n    }\n}\n\n.error-tooltip.right {\n    left: 16px;\n\n    &::before {\n        left: 11%;\n    }\n}\n\n.error-tooltip.left {\n    left: -180px;\n\n    &::before {\n        left: 76%;\n        transform: rotate(90deg) scaleX(-1);\n    }\n}\n\n.error-tooltip.top {\n    bottom: unset !important;\n    top: -68px;\n\n    &::before {\n        content: none;\n    }\n\n    &:after {\n        content: \"\";\n        position: absolute;\n        bottom: -20%;\n        left: 11%;\n        rotate: -45deg;\n        border: 15px solid #696969;\n        border-color: transparent transparent #696969 transparent;\n    }\n}\n\n.error-tooltip.top.left {\n    &::after {\n        left: 76%;\n        transform: rotate(90deg) scaleX(-1);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
