import dayjs from 'dayjs';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Crud_Service from '../../../../apis/CrudService.jsx';
import ActionButtons from '../../../../components/_form/ActionButtons.tsx';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import UploadSquare from '../../../../components/upload/UploadSquare.tsx';

const CreateBuildingImages = () => {
  const navigate = useNavigate();
  const crud = new Crud_Service();
  const { startCreateLoading, stopCreateLoading, createLoading } = useLoader();
  const { handleSubmit, reset } = useForm({});

  const handleSubmitProject = async (values) => {
    startCreateLoading();
    const CombinedData = {
      ...values,
      contractStartDate: dayjs(values?.contractStartDate).format('YYYY-MM-DD'),
      contractEndDate: dayjs(values?.contractEndDate).format('YYYY-MM-DD'),
      guaranteeStartDate: dayjs(values?.guaranteeStartDate).format(
        'YYYY-MM-DD'
      ),
      guaranteeEndDate: dayjs(values?.guaranteeEndDate).format('YYYY-MM-DD'),
      expectedContractNumber: values?.expectedContractNumber,
      contractScopeIds: Array.isArray(values.contractScopeIds)
        ? values.contractScopeIds
        : [values.contractScopeIds],
    };
    await crud.create(`contracts`, CombinedData, (_err, res) => {
      if (res?.status === 201) {
        toast.success('Contract Created Successfully');
        navigate(-1);
        stopCreateLoading();
      } else {
        stopCreateLoading();
      }
    });
  };

  return (
    <DashboardLayout title='Create New Contract'>
      <form onSubmit={handleSubmit(handleSubmitProject)}>
        <UploadSquare />

        <ActionButtons
          onSubmit={handleSubmit(handleSubmitProject)}
          onCancel={() => navigate(-1)}
          onReset={reset}
          submitLoading={createLoading}
          cancelLoading={false}
          submitText='Create'
        />
      </form>
    </DashboardLayout>
  );
};

export default CreateBuildingImages;
