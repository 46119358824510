import { Box } from '@mui/material';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Crud_Service from '../../../../apis/CrudService.jsx';
import TableActionButtons from '../../../../components/_form/TableActionButtons.jsx';
import FmMRTDataTable from '../../../../components/FmMRTDataTable.tsx';
import useTableLogic from '../../../../components/helpers/MRTUseTableLogic.tsx';
import Icons from '../../../../utils/Icon.jsx';
import GlassCard from './../../../../components/small/GlassCard.tsx';

const ListSalesInvoicesAR = () => {
  const navigation = useNavigate();
  const location = useLocation();

  const [data, setData] = useState({});
  const crud = new Crud_Service();

  const [, setCreatingRow] = useState(null);
  const {
    rows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic(`salesinvoices`);

  const handleEdit = (id) => {
    navigation(`${location.pathname}/edit/${id}`);
  };
  const handleDelete = async (props) => {
    await crud.remove('salesinvoices', props, (err, res) => {
      if (res?.status === 204) {
        toast.success('Sales Invoice Deleted Successfully');
        fetchData();
      }
    });
  };
  const ActionData = [
    {
      name: 'Edit',
      icon: Icons.edit,
      onClick: (props) => {
        handleEdit(props.salesInvoiceId);
      },
    },
    {
      name: 'Delete',
      icon: Icons.delete,
      onClick: (props) => handleDelete(props.salesInvoiceId),
      danger: true,
    },
  ];
  // Define the columns
  const columns = [
    {
      header: 'Actions',
      Cell: ({ row }) => (
        <Box className='flex gap-2'>
          {
            <TableActionButtons
              Actions={ActionData?.map((action) => ({
                ...action,
                onClick: () => action.onClick(row?.original),
              }))}
            />
          }
        </Box>
      ),
    },

    {
      accessorKey: 'documentNo',
      header: 'Document No',
    },

    {
      accessorKey: 'salesInvoiceDate',
      header: 'Sales Invoice Date',
    },

    {
      accessorKey: 'placeOfSupply',
      header: 'Place of Supply',
    },

    {
      accessorKey: 'clientId',
      header: 'Client',
    },

    {
      accessorKey: 'juridicationName',
      header: 'Jurisdiction',
    },

    {
      accessorKey: 'companyName',
      header: 'Company',
    },
    {
      accessorKey: 'localPurchaseOrderNumber',
      header: 'LPO No',
    },
  ];

  return (
    <>
      <GlassCard className='overflow-hidden h-full-css'>
        <FmMRTDataTable
          columns={columns}
          rows={rows}
          rowCount={tableRecordCounts}
          pagination={pagination}
          sorting={sorting}
          columnFilters={columnFilters}
          globalFilter={globalFilter}
          setPagination={setPagination}
          setSorting={setSorting}
          setColumnFilters={setColumnFilters}
          setGlobalFilter={setGlobalFilter}
          isLoading={isLoading}
          setSearchKeyword={setSearchKeyword}
          searchKeyword={searchKeyword}
          fetchData={fetchData}
          handleRowDoubleClick={(ev) => handleEdit(ev?.original?.salesInvoiceId)}
          muiTableContainerProps={{ sx: { maxHeight: '100%', flex: 1 } }}
        />{' '}
      </GlassCard>
    </>
  );
};

export default ListSalesInvoicesAR;
