import { Icon } from '@iconify/react';
import { Avatar, Box, Divider, Grid, MenuItem, Stack } from '@mui/material';
import React, { useEffect } from 'react';
import Charts from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  CardDashboard,
  ProgressWithLabel,
  TextCountBox,
} from '../../components/dashboard/CardDashboard.jsx';
import DashboardLayout from '../../components/DashboardLayout.tsx';
import { ViewButton } from '../../components/small/Buttons.jsx';
import { setActiveTab } from '../../store/tabsSlice.ts';
import Nav from '../../utils/Nav.tsx';
import { StaticTableDashboard } from './../../components/_form/StaticTable';

const EVENTSDATATWO = [1, 2, 3, 4, 5, 6];
const EVENTSDATA = [1, 2, 3, 4];

const UPCOMONGHOLIDAYS = [
  {
    name: 'Kite Festival',
    date: 'Jan 14, 2024',
    icon: <Icon icon='hugeicons:kite' />,
  },
  {
    name: 'Music Concert',
    date: 'Feb 20, 2024',
    icon: <Icon icon='mdi:music-note' />,
  },
  {
    name: 'Tech Conference',
    date: 'Mar 15, 2024',
    icon: <Icon icon='mdi:laptop' />,
  },
  {
    name: 'Art Exhibition',
    date: 'Apr 10, 2024',
    icon: <Icon icon='mdi:palette' />,
  },
];

const EMPLOYEEAPI = [
  {
    name: 'Robert Fox',
    leaveDate: '22-6-2024',
    leaveReason: 'Went to Festival',
    position: 'IOS Developer',
    img: 'https://images.pexels.com/photos/1043474/pexels-photo-1043474.jpeg',
    location: 'Abudhabi',
    anniversary: '1 Years',
    birthday: 'Today',
    experience: '1 Year Exp',
  },
  {
    name: 'John Smith',
    leaveDate: '5-8-2024',
    leaveReason: 'Medical Leave',
    position: 'Frontend Developer',
    img: 'https://images.pexels.com/photos/1239291/pexels-photo-1239291.jpeg',
    location: 'London',
    anniversary: '2 Years',
    birthday: '22-06-2024',
    experience: '2 Years Exp',
  },
  {
    name: 'Alice Johnson',
    leaveDate: '25-6-2024',
    leaveReason: 'Vacation',
    position: 'Project Manager',
    img: 'https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg',
    location: 'San Francisco',
    anniversary: '5 Years',
    birthday: '23-06-2024',
    experience: '5 Years Exp',
  },
  {
    name: 'Michael Brown',
    leaveDate: '30-9-2024',
    leaveReason: 'Workshop',
    position: 'DevOps Engineer',
    img: 'https://images.pexels.com/photos/1043474/pexels-photo-1043474.jpeg',
    location: 'Berlin',
    anniversary: '2 Years',
    birthday: '24-06-2024',
    experience: '2 Years Exp',
  },
];

const radialOptions = {
  chart: {
    type: 'radialBar',
    offsetY: -20,
    sparkline: {
      enabled: true,
    },
  },
  plotOptions: {
    radialBar: {
      startAngle: -90,
      endAngle: 90,
      track: {
        background: '#e7e7e7',
        strokeWidth: '97%',
        margin: 5, // margin is in pixels
        dropShadow: {
          enabled: true,
          top: 2,
          left: 0,
          color: '#999',
          opacity: 1,
          blur: 2,
        },
      },
      dataLabels: {
        name: {
          show: false,
        },
        value: {
          offsetY: -2,
          fontSize: '22px',
        },
      },
    },
  },
  grid: {
    padding: {
      top: -10,
    },
  },
  fill: {
    type: 'gradient',
    gradient: {
      shade: 'light',
      shadeIntensity: 0.4,
      inverseColors: false,
      opacityFrom: 1,
      opacityTo: 1,
      stops: [0, 50, 53, 91],
    },
  },
  labels: ['Average Results'],
};

const Dashboard = () => {
  useTranslation();
  const dispatch = useDispatch();
  const activeMenu = useSelector((state) => state.tabs);
  const menu = useSelector((state) => state?.menu?.items);
  useEffect(() => {
    const storedTabValue = parseInt(sessionStorage.getItem('tab'), 10) || 0; // Fallback to 0 if 'tab' is not found
    const isValidTabIndex = Array.isArray(menu) && menu[storedTabValue]; // Check if menu is valid and index exists
    const moduleName = isValidTabIndex
      ? menu[storedTabValue].moduleName
      : 'Dashboard'; // Safely access moduleName
    document.title = `${moduleName} - Dashboard`; // Update document title
  }, [menu, activeMenu]);

  const setActiveMenu = (tab) => {
    dispatch(setActiveTab(tab));
  };

  return (
    <DashboardLayout
      title='Dashboard'
      activeMenu={activeMenu}
      setActiveMenu={setActiveMenu}
    >
      <Grid container spacing={1}>
        <Grid item md={12}>
          <CardDashboard title={false} color='bg-red-100'>
            <Box className='px-6 py-2'>
              <Stack
                direction={'row'}
                alignItems={'center'}
                spacing={4}
                justifyContent={'space-between'}
                divider={<Divider orientation='vertical' flexItem />}
              >
                <Box>
                  <h3 className='mb-3 font-semibold'>Today's Attendance</h3>
                  <Stack direction='row' spacing={8}>
                    <TextCountBox
                      name='Present'
                      count='460'
                      color='text-green-500'
                    />
                    <TextCountBox
                      name='Latecomers'
                      count='40'
                      color='text-red-500'
                    />
                    <TextCountBox
                      name='Absent'
                      count='10'
                      color='text-blue-500'
                    />
                  </Stack>
                </Box>

                <TextCountBox
                  name='Overtime Employee'
                  count='40'
                  color='text-teal-500'
                />

                <TextCountBox
                  name='Extend Break Hours'
                  count='33'
                  color='text-purple-500'
                />

                <Box sx={{ width: '200px' }}>
                  <h3 className='mb-3 font-semibold text-center'>
                    March's Attendance
                  </h3>

                  <Charts
                    options={radialOptions}
                    type='radialBar'
                    height={150}
                    series={[85]}
                  />
                </Box>

                <Box>
                  <h3 className='mb-3 font-semibold'>Joining this Month</h3>

                  <TextCountBox
                    name='Employees'
                    count='25'
                    color='text-indigo-500'
                  />
                </Box>
              </Stack>
            </Box>
          </CardDashboard>
        </Grid>
        <Grid item md={5}>
          <CardDashboard
            title='My Tasks'
            color='bg-red-100'
            action={<ViewButton name='View' />}
          >
            <StaticTableDashboard
              columns={[
                { field: 'name', headerName: 'Project Name' },
                { field: 'task', headerName: 'Task Name' },
                { field: 'log', headerName: 'Work Logs' },
                { field: 'date', headerName: 'Due Date' },
              ]}
              data={[
                {
                  name: 'UBS HRMS',
                  task: 'Invoice Design',
                  log: '01h 04m',
                  date: '6 july, 2020',
                },
                {
                  name: 'UBS HRMS',
                  task: 'Dashboard R&D',
                  log: '01h 04m',
                  date: '6 july, 2020',
                },
                {
                  name: 'UBS HRMS',
                  task: 'Dashboard Design',
                  log: '01h 04m',
                  date: '6 july, 2020',
                },
                {
                  name: 'UBS HRMS',
                  task: 'Admin Design',
                  log: '01h 04m',
                  date: '6 july, 2020',
                },
                {
                  name: 'Panda App',
                  task: 'App Design',
                  log: '01h 04m',
                  date: '6 july, 2020',
                },
              ]}
            />
          </CardDashboard>
        </Grid>
        <Grid item md={3}>
          <Stack spacing={3} direction='column'>
            <CardDashboard
              title='Employee Hired/Left'
              action='Last 3 Months'
              color='bg-orange-100'
            >
              <Box className='p-3'>
                <Box className='mb-2'>
                  <h3 className='font-semibold text-orange-500'>Nationality</h3>
                  <Box className='flex justify-between items-center'>
                    <Box className='text-lg'>
                      <span className='font-semibold'>150</span>{' '}
                      <span className='text-sm'>Hired</span>
                    </Box>
                    <Box className='text-lg'>
                      <span className='font-semibold'>40</span>{' '}
                      <span className='text-sm'>Exit</span>
                    </Box>
                  </Box>
                </Box>
                <Box>
                  <h3>Saudi</h3>
                  <Stack spacing={0.5} direction='column'>
                    <ProgressWithLabel
                      text='Hired 50%'
                      value={50}
                      light='bg-violet-100'
                      dark='bg-violet-700'
                    />
                    <ProgressWithLabel
                      text='Exit 20%'
                      value={20}
                      light='bg-green-100'
                      dark='bg-green-500'
                    />
                  </Stack>
                </Box>
                <Divider className='my-2' />

                <Box className='flex justify-between items-center'>
                  <Box className='text-lg'>
                    <span className='font-semibold'>100</span>{' '}
                    <span className='text-sm'>Hired</span>
                  </Box>
                  <Box className='text-lg'>
                    <span className='font-semibold'>20</span>{' '}
                    <span className='text-sm'>Exit</span>
                  </Box>
                </Box>

                <Box>
                  <h3>Non - Saudi</h3>
                  <Stack spacing={0.5} direction='column'>
                    <ProgressWithLabel
                      text='Hired 50%'
                      value={50}
                      light='bg-orange-100'
                      dark='bg-orange-500'
                    />
                    <ProgressWithLabel
                      text='Exit 20%'
                      value={20}
                      light='bg-blue-100'
                      dark='bg-blue-500'
                    />
                  </Stack>
                </Box>
              </Box>
            </CardDashboard>
          </Stack>
        </Grid>

        <Grid item md={4}>
          <CardDashboard
            title='Events'
            action={<ViewButton name='View' />}
            color='bg-fuchsia-100'
          >
            <Box>
              {EVENTSDATA.map((item) => {
                return (
                  <MenuItem
                    key={item}
                    className='p-2 flex justify-between items-center px-6'
                    component='div'
                  >
                    <Box className='text-3xl me-4'>
                      <Icon icon='flat-color-icons:combo-chart' />
                    </Box>
                    <Box className='flex-1'>
                      <h3 className='font-semibold'>
                        Office Management Meeting
                      </h3>
                      <p>Mar 21, 02:00 PM</p>
                    </Box>
                    <Box className='text-xl opacity-50'>
                      <Icon icon='ph:arrow-up-right-bold' />
                    </Box>
                  </MenuItem>
                );
              })}
            </Box>
          </CardDashboard>
        </Grid>
        <Grid item md={5}>
          <CardDashboard
            title='Current Employee (Cost Center)'
            color='bg-green-100'
          >
            <Charts
              type='donut'
              series={[200, 300, 500, 350, 150, 100, 110]}
              options={{
                chart: {
                  type: 'donut',
                  fontFamily: 'DM Sans,sans-serif',
                  dropShadow: {
                    enabled: false,
                  },
                },

                title: {
                  text: 'Cost Center',
                  align: 'right',
                  offsetX: -70,
                  offsetY: 40,
                  style: {
                    color: '#f97316',
                    fontSize: '16px',
                  },
                },
                labels: [
                  'Direct Cost 401',
                  'Indirect Cost 402',
                  'Standby Cost 403',
                  'Vacation Cost 404',
                  'Idle Cost',
                  'Divison OH Cost 406',
                  'Training Cost 407',
                ],
                legend: {
                  position: 'right',
                  fontSize: '12px',
                  fontWeight: 400,
                  offsetY: 60,
                },
                responsive: [
                  {
                    breakpoint: 480,
                    options: {
                      chart: {
                        width: 200,
                      },
                      legend: {
                        position: 'bottom',
                      },
                    },
                  },
                ],
              }}
            />
          </CardDashboard>
        </Grid>
        <Grid item md={3}>
          <CardDashboard title='Validity' action={<ViewButton name='View' />}>
            <StaticTableDashboard
              columns={[
                { field: 'name', headerName: 'Nationality' },
                { field: 'task', headerName: 'Count' },
              ]}
              data={[
                {
                  name: 'Validity & Expiring Above 4 Month',
                  task: '100',
                },
                {
                  name: 'Validity & Expiring Above 3 Month',
                  task: '100',
                },
                {
                  name: 'Validity & Expiring Above 2 Month',
                  task: '100',
                },
                {
                  name: 'Validity & Expiring Above 1 Month',
                  task: '100',
                },
                {
                  name: 'Expired in Running Month',
                  task: '100',
                },
                {
                  name: 'Expired in Last Month',
                  task: '100',
                },
                {
                  name: 'Expired in Before Two Month',
                  task: '100',
                },
                {
                  name: 'New Employee - Awaiting IQAMA',
                  task: '100',
                },
              ]}
            />
          </CardDashboard>
        </Grid>
        <Grid item md={4}>
          <CardDashboard
            title='Upcoming Interviews'
            color='bg-green-100'
            action={<ViewButton name='View' />}
          >
            <Box className='p-3'>
              <Stack spacing={2}>
                {EVENTSDATATWO.map((item) => {
                  return (
                    <Box
                      key={item}
                      className='flex justify-between items-center gap-3'
                    >
                      <Avatar
                        sx={{ bgcolor: 'background.light', color: 'text.main' }}
                      />
                      <Box className='flex-1'>
                        <h3 className='font-semibold'>Guy Hawkins</h3>
                        <p>UI/UX Designer</p>
                      </Box>
                      <Box
                        className='px-2  py-1 rounded-2xl font-bold text-center flex gap-3 items-center'
                        sx={{ bgcolor: 'background.light' }}
                      >
                        <span className='text-xl'>
                          <Icon icon='material-symbols:timelapse-outline-rounded' />
                        </span>
                        <span>10:00 - 11:00</span>
                      </Box>
                    </Box>
                  );
                })}
              </Stack>
            </Box>
          </CardDashboard>
        </Grid>
        <Grid item md={6}>
          <CardDashboard title='Leave Request'>
            <Box
              className='p-4'
              sx={{
                '& table': {
                  width: '100%',
                  '& thead': {
                    fontWeight: 600,
                  },
                  '& td': {
                    padding: '10px',
                    border: '1px solid',
                    borderColor: 'border.main',
                    textAlign: 'center',
                  },
                },
              }}
            >
              <table>
                <thead>
                  <tr>
                    <td>Leave Type</td>
                    <td>Approved</td>
                    <td>In Process</td>
                    <td>Rejected</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Annual Leave</td>
                    <td className='bg-green-100 dark:bg-green-800  font-bold'>
                      20
                    </td>
                    <td className='bg-yellow-100  dark:bg-yellow-800 font-bold'>
                      20
                    </td>
                    <td className='bg-red-100 dark:bg-red-800  font-bold'>
                      20
                    </td>
                  </tr>
                  <tr>
                    <td>Personal Leave</td>
                    <td className='bg-green-100 dark:bg-green-800  font-bold'>
                      20
                    </td>
                    <td className='bg-yellow-100 dark:bg-yellow-800 font-bold'>
                      20
                    </td>
                    <td className='bg-red-100 dark:bg-red-800  font-bold'>
                      20
                    </td>
                  </tr>
                  <tr>
                    <td>Emergency Leave</td>
                    <td className='bg-green-100 dark:bg-green-800  font-bold'>
                      20
                    </td>
                    <td className='bg-yellow-100 dark:bg-yellow-800 font-bold'>
                      20
                    </td>
                    <td className='bg-red-100 dark:bg-red-800  font-bold'>
                      20
                    </td>
                  </tr>
                  <tr>
                    <td>Vacation</td>
                    <td className='bg-green-100 dark:bg-green-800  font-bold'>
                      20
                    </td>
                    <td className='bg-yellow-100 dark:bg-yellow-800 font-bold'>
                      20
                    </td>
                    <td className='bg-red-100 dark:bg-red-800  font-bold'>
                      20
                    </td>
                  </tr>
                </tbody>
              </table>
            </Box>
          </CardDashboard>
        </Grid>

        <Grid item md={3}>
          <CardDashboard title='Employee On Leave' color='bg-red-100'>
            <Box className='p-3'>
              <Stack spacing={2}>
                {EMPLOYEEAPI.map((key) => {
                  return (
                    <Box className='flex items-center gap-3' key={key.name}>
                      <Avatar src={key.img} />
                      <Box className='flex-1'>
                        <h3 className='font-semibold'>{key.name}</h3>
                        <p className='opacity-70'>{key.leaveReason}</p>
                      </Box>
                      <Box className='font-semibold'>{key.leaveDate}</Box>
                    </Box>
                  );
                })}
              </Stack>
            </Box>
          </CardDashboard>
        </Grid>

        <Grid item md={3}>
          <CardDashboard title='Work Anniversary' color='bg-purple-100'>
            <Box className='p-3'>
              <Stack spacing={2}>
                {EMPLOYEEAPI.map((key) => {
                  return (
                    <Box className='flex items-center gap-3' key={key.name}>
                      <Avatar src={key.img} />
                      <Box className='flex-1'>
                        <h3 className='font-semibold'>{key.name}</h3>
                        <p className='opacity-70'>{key.position}</p>
                      </Box>
                      <Box className='font-semibold'>{key.anniversary}</Box>
                    </Box>
                  );
                })}
              </Stack>
            </Box>
          </CardDashboard>
        </Grid>
        <Grid item md={3}>
          <CardDashboard title='Upcoming Birthday' color='bg-teal-100'>
            <Box className='p-3'>
              <Stack spacing={2}>
                {EMPLOYEEAPI.map((key, index) => {
                  return (
                    <Box key={index + 1} className='flex items-center gap-3'>
                      <Avatar src={key.img} />
                      <Box className='flex-1'>
                        <h3 className='font-semibold'>{key.name}</h3>
                        <p className='opacity-70'>{key.position}</p>
                      </Box>
                      <Box className='font-semibold'>{key.birthday}</Box>
                    </Box>
                  );
                })}
              </Stack>
            </Box>
          </CardDashboard>
        </Grid>
        <Grid item md={3}>
          <CardDashboard
            title='Upcoming Holidays'
            action={<ViewButton name='View' />}
            color='bg-green-100'
          >
            <Box className='p-3'>
              <Stack spacing={2}>
                {UPCOMONGHOLIDAYS.map((key, inex) => {
                  return (
                    <Box key={inex + 1} className='flex items-center gap-3'>
                      <Box
                        className='flex justify-center items-center  text-2xl'
                        sx={{
                          minWidth: '40px',
                          height: '40px',
                          borderRadius: '50%',
                          bgcolor: 'background.light',
                          color: 'text.main',
                        }}
                      >
                        {key.icon}
                      </Box>
                      <Box>
                        <h3 className='font-semibold'>{key.name}</h3>
                        <p>{key.date}</p>
                      </Box>
                    </Box>
                  );
                })}
              </Stack>
            </Box>
          </CardDashboard>
        </Grid>

        <Grid item md={6}>
          <CardDashboard title='Exit Application Request'>
            <Box
              className='p-4'
              sx={{
                '& table': {
                  width: '100%',
                  '& thead': {
                    fontWeight: 600,
                  },
                  '& td': {
                    padding: '10px',
                    border: '1px solid',
                    borderColor: 'border.main',
                    textAlign: 'center',
                  },
                },
              }}
            >
              <table>
                <thead>
                  <tr>
                    <td>Type of Exit</td>
                    <td>Approved</td>
                    <td>In Process</td>
                    <td>Rejected</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Resignation</td>
                    <td className='bg-green-100 dark:bg-green-800 font-bold'>
                      20
                    </td>
                    <td className='bg-yellow-100 dark:bg-yellow-800 font-bold'>
                      20
                    </td>
                    <td className='bg-red-100 dark:bg-red-800 font-bold'>20</td>
                  </tr>
                  <tr>
                    <td>Termination</td>
                    <td className='bg-green-100 dark:bg-green-800 font-bold'>
                      20
                    </td>
                    <td className='bg-yellow-100 dark:bg-yellow-800 font-bold'>
                      20
                    </td>
                    <td className='bg-red-100 dark:bg-red-800 font-bold'>20</td>
                  </tr>
                  <tr>
                    <td>End of Contract</td>
                    <td className='bg-green-100 dark:bg-green-800 font-bold'>
                      20
                    </td>
                    <td className='bg-yellow-100 dark:bg-yellow-800 font-bold'>
                      20
                    </td>
                    <td className='bg-red-100 dark:bg-red-800 font-bold'>20</td>
                  </tr>
                </tbody>
              </table>
            </Box>
          </CardDashboard>
        </Grid>

        {/* <Grid item md={12}>
          <CardDashboard title={"Quick Links"}>
            <Box className="p-5">
              <Grid container spacing={2}>
                <Grid item md={3}>
                  {QUICKLINKS.map((key) => {
                    return (
                      <Button
                        startIcon={key.icon}
                        fullWidth
                        sx={{
                          fontWeight: 400,
                          textAlign: "left",
                          bgcolor: "none",
                          borderRadius: "10px",
                          gap: "10px",

                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          justifyContent: "flex-start",
                          lineHeight: "20px",
                          padding: "5px 20px",
                          "& .MuiButton-icon svg": {
                            fontSize: "30px",
                            color: "primary.main",
                          },
                          "&:hover": {
                            bgcolor: "background.light",
                          },
                        }}
                      >
                        {key.name}
                      </Button>
                    );
                  })}
                </Grid>
              </Grid>
            </Box>
          </CardDashboard>
        </Grid> */}
      </Grid>

      {/* grid charts */}
    </DashboardLayout>
  );
};

export default Dashboard;
