import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import { Box, Grid, TextField, Button, MenuItem } from '@mui/material';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { ActionButtons } from '../../../components/_form';
import { useNavigate } from 'react-router-dom';
import Crud_Service from '../../../apis/CrudService';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';

const CreateTenderEnquiry = () => {
  const navigate = useNavigate();
  const crud = new Crud_Service();
  const { register, handleSubmit, control, reset } = useForm();
  const [clients, setClients] = useState([]);
  const [tenderEnquiryTypes, setTenderEnquiryTypes] = useState([]);
  const [tenderProjectTypes, setTenderprojectTypes] = useState([]);
  const [tenderFormats, setTenderFormats] = useState([]);
  const [tenderStatuses, setTenderStatuses] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    getClients();
    getTenderEnquiryTypes();
    getTenderProjectTypes();
    getTenderFormats();
    getTenderStatuses();
  }, []);

  //get All Clients
  const getClients = async () => {
    await crud.getAll('clients', '', (err, res) => {
      if (err) {
        toast.error('Error fetching clients:', err);
        return;
      }
      if (res?.status === 200) {
        setClients(res?.data?.data);
      }
    });
  };

  //get All TenderEnquiry Types
  const getTenderEnquiryTypes = async () => {
    await crud.getAll('tenderenquirytypes', '', (err, res) => {
      if (err) {
        toast.error('Error fetching Tender Enquiry Types:', err);
        return;
      }
      if (res?.status === 200) {
        setTenderEnquiryTypes(res?.data?.data);
      }
    });
  };

  //get All TenderProject Types
  const getTenderProjectTypes = async () => {
    await crud.getAll('tenderprojecttypes', '', (err, res) => {
      if (err) {
        toast.error('Error fetching Tender project Types:', err);
        return;
      }
      if (res?.status === 200) {
        setTenderprojectTypes(res?.data?.data);
      }
    });
  };
  //get All TenderFormaatTypes
  const getTenderFormats = async () => {
    await crud.getAll('tenderformats', '', (err, res) => {
      if (err) {
        toast.error('Error fetching Tender project Types:', err);
        return;
      }
      if (res?.status === 200) {
        setTenderFormats(res?.data?.data);
      }
    });
  };
  //get All TenderFormaatTypes
  const getTenderStatuses = async () => {
    await crud.getAll('tenderstatuses', '', (err, res) => {
      if (err) {
        toast.error('Error fetching Tender project Types:', err);
        return;
      }
      if (res?.status === 200) {
        setTenderStatuses(res?.data?.data);
      }
    });
  };

  const handleSubmitTendors = async (values) => {
    const CombinedData = {
      ...values,
      bidBondValue: Number(values?.bidBondValue),
      startDate: dayjs(values?.startDate).format('YYYY-MM-DD'),
      lastDate: dayjs(values?.lastDate).format('YYYY-MM-DD'),
      tenderValue: Number(values?.tenderValue),
    };

    await crud.create('tenders', CombinedData, (err, res) => {
      if (res?.status === 201) {
        navigate(-1);
      } else {
      }
    });
  };

  return (
    <DashboardLayout title='Create New Tender Enquiry'>
      <Box className='mt-10 mx-auto' sx={{ maxWidth: '1200px' }}>
        <GlassCard className='p-5'>
          <h2 className='text-lg font-bold mb-5'>Tender/Lead Enquiry</h2>
          <form onSubmit={handleSubmit(handleSubmitTendors)}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid container spacing={2}>
                <Grid item md={3}>
                  <TextField
                    {...register('tenderName')}
                    fullWidth
                    label='Tender Name'
                    variant='filled'
                  />
                </Grid>
                <Grid item md={3}>
                  <TextField
                    label='client'
                    fullWidth
                    select
                    variant='filled'
                    {...register('clientId')}
                  >
                    {clients?.map((item) => (
                      <MenuItem key={item?.clientId} value={item?.clientId}>
                        {item?.clientName}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item md={3}>
                  <TextField
                    label='Tender Mode of Submission'
                    fullWidth
                    select
                    variant='filled'
                    {...register('tenderEnquiryTypeId')}
                  >
                    {tenderEnquiryTypes?.map((item) => (
                      <MenuItem
                        key={item?.tenderEnquiryTypeId}
                        value={item?.tenderEnquiryTypeId}
                      >
                        {item?.tenderEnquiryTypeName}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item md={3}>
                  <TextField
                    label='Tender Project Type'
                    fullWidth
                    select
                    variant='filled'
                    {...register('tenderProjectTypeId')}
                  >
                    {tenderProjectTypes?.map((item) => (
                      <MenuItem
                        key={item?.tenderProjectTypeId}
                        value={item?.tenderProjectTypeId}
                      >
                        {item?.tenderProjectTypeName}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item md={3}>
                  <TextField
                    label='Tender Formats'
                    fullWidth
                    select
                    variant='filled'
                    {...register('tenderFormatId')}
                  >
                    {tenderFormats?.map((item) => (
                      <MenuItem
                        key={item?.tenderFormatId}
                        value={item?.tenderFormatId}
                      >
                        {item?.tenderFormatName}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item md={3}>
                  <TextField
                    label='Auto Renewal'
                    fullWidth
                    select
                    variant='filled'
                    {...register('autoRenewal')}
                  >
                    <MenuItem value={true}>Yes</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                  </TextField>
                </Grid>

                <Grid item md={3}>
                  <TextField
                    {...register('bidBondValue')}
                    fullWidth
                    label='Bid Bond Value'
                    variant='filled'
                  />
                </Grid>
                <Grid item md={3}>
                  <TextField
                    label='Sub Contract Required'
                    fullWidth
                    select
                    variant='filled'
                    {...register('subContractRequired')}
                  >
                    <MenuItem value={true}>Yes</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                  </TextField>
                </Grid>
                <Grid item md={3}>
                  <Controller
                    name='startDate'
                    control={control}
                    defaultValue={null}
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        label='Start Date'
                        value={value}
                        format='DD/MM/YYYY'
                        onChange={(newValue) => {
                          onChange(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField fullWidth variant='filled' {...params} />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={3}>
                  <Controller
                    name='lastDate'
                    control={control}
                    defaultValue={null}
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        label='Last Date'
                        value={value}
                        format='DD/MM/YYYY'
                        onChange={(newValue) => {
                          onChange(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField fullWidth variant='filled' {...params} />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={4}>
                  <TextField
                    {...register('tenderValue')}
                    fullWidth
                    label='Tender Value'
                    variant='filled'
                  />
                </Grid>
                <Grid item md={3}>
                  <TextField
                    label='Bid Bond'
                    fullWidth
                    select
                    variant='filled'
                    {...register('bidBond')}
                  >
                    <MenuItem value={true}>Yes</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                  </TextField>
                </Grid>
                <Grid item md={3}>
                  <TextField
                    label='Express Of Interest'
                    fullWidth
                    select
                    variant='filled'
                    {...register('expressOfInterest')}
                  >
                    <MenuItem value={true}>Yes</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                  </TextField>
                </Grid>
                <Grid item md={3}>
                  <TextField
                    label='Buy Tender Document'
                    fullWidth
                    select
                    variant='filled'
                    {...register('buyTenderDocument')}
                  >
                    <MenuItem value={true}>Yes</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                  </TextField>
                </Grid>
                <Grid item md={3}>
                  <TextField
                    label='Tender Statuses'
                    fullWidth
                    select
                    variant='filled'
                    {...register('tenderStatusId')}
                  >
                    {tenderStatuses?.map((item) => (
                      <MenuItem
                        key={item?.tenderStatusId}
                        value={item?.tenderStatusId}
                      >
                        {item?.tenderStatusName}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            </LocalizationProvider>

            <ActionButtons onReset={reset}>
              <Button variant='text' onClick={() => navigate(-1)}>
                {t('Cancel')}
              </Button>
              <Button variant='contained' type='submit'>
                {t('Create')}
              </Button>
            </ActionButtons>
          </form>
        </GlassCard>
      </Box>
    </DashboardLayout>
  );
};

export default CreateTenderEnquiry;
