import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import { Grid, TextField, Autocomplete, MenuItem } from '@mui/material';
import { ActionButtons } from '../../../components/_form';
import { useNavigate, useParams } from 'react-router-dom';
import Crud_Service from '../../../apis/CrudService';
import CommonLoader from '../../../components/page/CommonLoader';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import apiInstance from '../../../apis/ApiService';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import FmSearchableSelect from '../../../components/_mui/FmSearchableSelect.tsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { EstimationMEPSchema } from '../../../utils/CommonvalidationSchemas.tsx';
import { toast } from 'react-toastify';
import useCommonFetchApi from '../../../components/helpers/useCommonFetchApi.tsx';
import FmAutoComplete from '../../../components/_mui/FmAutoComplete.tsx';

const GridValue = 3;

const EditEstimationSheet = () => {
  const navigate = useNavigate();
  const crud = new Crud_Service();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [editData, setEditData] = useState(null);
  const defaultValues = {
    employeeId: editData?.siteVisitDoneBy || null,
    firstName: editData?.sitVisitDoneByName || '',
    tenderId: editData?.tenderId || null,
    tenderName: editData?.tenderName || '',
  };

  const {
    setValue,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(EstimationMEPSchema),
    mode: 'onChange',
  });
  const { editLoading, startEditLoading, stopEditLoading } = useLoader();
  // const [errors, setErrors] = useState({});
  const [projects, setProjects] = useState([]);
  const [clients, setClients] = useState([]);
  const [tenders, setTenders] = useState([]);
  const [buildings, setBuildings] = useState([]);
  const [employees, setEmployees] = useState([]);
  const { t } = useTranslation();
  const [employeeSearchValue, setEmployeeSearchValue] = useState('');
  const [users, setUsers] = useState([]);
  const { data: estimationStatuses } = useCommonFetchApi(
    'estimationmepsheets/estimation-statuses'
  );
  useEffect(() => {
    employeeSearchValue.length > 1 && getEmployees(employeeSearchValue);
  }, [employeeSearchValue]);

  useEffect(() => {
    getEstimationAMC();
    getProjects();
    getClients();
    getTenders();
    getBuildings();
    getEmployees('');
  }, []);

  const getEstimationAMC = async () => {
    setLoading(true);
    await crud.getSingle('estimationamcsheets', id, (_err, res) => {
      if (res?.status === 200) {
        Object.entries({
          ...res?.data,
        }).forEach(([key, value]) => {
          setValue(key, value);
        });
        setEditData(res?.data);
        setLoading(false);
      } else {
      }
    });
  };

  //get All Projects
  const getProjects = async () => {
    await crud.getAll(`projects`, '', (err, res) => {
      if (err) {
        toast.error('Error fetching clients:', err);
        return;
      }
      if (res?.status === 200) {
        setProjects(res?.data?.data);
      }
    });
  };

  //get All Clients
  const getClients = async () => {
    await crud.getAll('clients', '', (err, res) => {
      if (err) {
        toast.error('Error fetching clients:', err);
        return;
      }
      if (res?.status === 200) {
        setClients(res?.data?.data);
      }
    });
  };

  //get All Tenders
  const getTenders = async () => {
    await crud.getAll('tenders', '', (err, res) => {
      if (err) {
        toast.error('Error fetching clients:', err);
        return;
      }
      if (res?.status === 200) {
        setTenders(res?.data?.data);
      }
    });
  };

  //get All Buildings
  const getBuildings = async () => {
    await crud.getAll('buildings', '', (err, res) => {
      if (err) {
        toast.error('Error fetching clients:', err);
        return;
      }
      if (res?.status === 200) {
        setBuildings(res?.data?.data);
      }
    });
  };

  //get All Employees
  const getEmployees = async (employeeSearchValue) => {
    await apiInstance.getAllEmployee(employeeSearchValue).then((res) => {
      if (res?.status === 200) {
        setEmployees(res?.data?.data);
      } else {
      }
    });
  };

  const handleEditTenders = async (values) => {
    startEditLoading();
    const combinedData = {
      ...values,
      dateOfEnquiry: dayjs(values?.dateOfEnquiry).format('YYYY-MM-DD'),
      contractPeriod: Number(values?.contractPeriod),
      otherPercent: Number(values?.otherPercent),
      status: 2,
    };

    await crud.update('estimationamcsheets', id, combinedData, (err, res) => {
      if (err) {
        stopEditLoading();

        return;
      }
      if (res?.status === 200) {
        toast.success('Estimation Sheet AMC Updated Successfully');
        stopEditLoading();
        navigate(-1);
      } else {
        stopEditLoading();
      }
    });
  };

  useEffect(() => {
    getEstimationAMC();
    getProjects();
    getClients();
    getTenders();
    getBuildings();
    getEmployees('');
    getLoginUsers();
  }, []);

  const getLoginUsers = async () => {
    await crud.getAll('loginusers', '', (err, res) => {
      if (err) {
        toast.error('Error fetching clients:', err);
        return;
      }
      if (res?.status === 200) {
        setUsers(res?.data?.data);
      }
    });
  };
  return (
    <DashboardLayout title='Edit Estimation Sheet AMC'>
      {loading ? (
        <CommonLoader />
      ) : (
        <GlassCard className='p-4'>
          <form onSubmit={handleSubmit(handleEditTenders)}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid container spacing={2}>
                <Grid item md={GridValue}>
                  <Controller
                    name='projectId'
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        options={projects}
                        getOptionLabel={(option) => option?.projectName || ''}
                        value={
                          projects?.find(
                            (emp) => emp?.projectId === field?.value
                          ) || null
                        }
                        onChange={(_event, value) =>
                          field.onChange(value?.projectId || null)
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label='Projects'
                            variant='filled'
                            fullWidth
                            required
                            error={Boolean(errors.projectId)} // Set error prop
                            helperText={
                              errors.projectId ? errors.projectId.message : ''
                            }
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <Controller
                    name='clientId'
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        options={clients}
                        getOptionLabel={(option) => option?.clientName || ''}
                        value={
                          clients?.find(
                            (emp) => emp?.clientId === field?.value
                          ) || null
                        }
                        onChange={(_event, value) =>
                          field.onChange(value?.clientId || null)
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label='Clients'
                            variant='filled'
                            fullWidth
                            required
                            error={Boolean(errors.clientId)} // Set error prop
                            helperText={
                              errors.clientId ? errors.clientId.message : ''
                            }
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <FmSearchableSelect
                    name='tenderId'
                    control={control}
                    apiUrl='tenders'
                    valueField='tenderId'
                    labelField={['tenderCode', 'tenderName']}
                    showField={['tenderCode', 'tenderName']}
                    label='Tenders'
                    defaultValue={defaultValues}
                    required
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <Controller
                    name='buildingId'
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        options={buildings}
                        getOptionLabel={(option) => option?.buildingName || ''}
                        value={
                          buildings?.find(
                            (emp) => emp?.buildingId === field?.value
                          ) || null
                        }
                        onChange={(_event, value) =>
                          field.onChange(value?.buildingId || null)
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label='Buildings'
                            variant='filled'
                            fullWidth
                            required
                            error={Boolean(errors.buildingId)} // Set error prop
                            helperText={
                              errors.buildingId ? errors.buildingId.message : ''
                            }
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <Controller
                    name='dateOfEnquiry'
                    control={control}
                    defaultValue={null}
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        label='Date Of Enquiry'
                        value={value ? dayjs(value) : null}
                        format='DD/MM/YYYY'
                        onChange={(newValue) => {
                          onChange(newValue);
                        }}
                        sx={{ width: '100%' }}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <FmTextField
                    name='contractPeriod'
                    control={control}
                    label='Contract Period'
                    required
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <FmTextField
                    name='otherPercent'
                    control={control}
                    label='Other Percent(%)'
                    required
                    pattern='Percentage'
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <FmTextField
                    name='discountOverAllPercent'
                    control={control}
                    label='Discount Percent(%)'
                    required
                    pattern='Percentage'
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <FmTextField
                    name='existContract'
                    control={control}
                    label='Exist Contract'
                    required
                  />
                </Grid>

                <Grid item md={GridValue}>
                  <FmSearchableSelect
                    name='siteVisitDoneBy'
                    control={control}
                    apiUrl='employees'
                    valueField='employeeId'
                    headerField={['Employee Code', 'Employee Name']}
                    labelField={['employeeCode', 'firstName']}
                    showField={['employeeCode', 'firstName']}
                    label='Site Visit Done By'
                    defaultValue={defaultValues}
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <Controller
                    name='preparedBy'
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        options={users}
                        getOptionLabel={(option) => option?.loginUserName || ''}
                        value={
                          users?.find(
                            (emp) => emp?.loginUserId === field?.value
                          ) || null
                        }
                        onChange={(_event, value) =>
                          field.onChange(value?.loginUserId || null)
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label='Prepared By'
                            variant='filled'
                            fullWidth
                            required
                            error={Boolean(errors.preparedBy)} // Set error prop
                            helperText={
                              errors.preparedBy ? errors.preparedBy.message : ''
                            }
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <FmAutoComplete
                    name='estimationStatusId'
                    control={control}
                    options={estimationStatuses}
                    label='Estimation Status'
                    displayField='estimationStatusName'
                    optionFields={['estimationStatusName']}
                    valueKey='estimationStatusId'
                    required
                  />
                </Grid>
              </Grid>
              <ActionButtons
                onSubmit={handleSubmit(handleEditTenders)}
                onCancel={() => navigate(-1)}
                onReset={reset}
                submitLoading={editLoading}
                cancelLoading={false}
                submitText='Update'
              />
            </LocalizationProvider>
          </form>
        </GlassCard>
      )}
    </DashboardLayout>
  );
};

export default EditEstimationSheet;
