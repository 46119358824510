import React, { useState } from 'react';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { Grid } from '@mui/material';
import Crud_Service from '../../../apis/CrudService.jsx';
import FmAutoComplete from '../../../components/_mui/FmAutoComplete.tsx';
import FmSearchableSelect from '../../../components/_mui/FmSearchableSelect.tsx';
import dayjs from 'dayjs';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import ActionButtons from '../../../components/_form/ActionButtons.tsx';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import { toast } from 'react-toastify';
import FmDatePicker from '../../../components/_mui/FmDatePicker.tsx';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { uniformsSchama } from '../../../utils/CommonvalidationSchemas.tsx';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import { useNavigate } from 'react-router-dom';
import useCommonFetchApi from '../../../components/helpers/useCommonFetchApi.tsx';

const GridValue = 2.4;

const AddUniforms = (props) => {
  const crud = new Crud_Service();
  const { handleSubmit, control, reset } = useForm({
    defaultValues: {
      requestDate: dayjs(),
    },
    resolver: yupResolver(uniformsSchama),
    mode: 'onChange',
  });
  const [uniformsizes, setuniformsizes] = useState([]);
  const { startCreateLoading, stopCreateLoading, createLoading } = useLoader();
  const navigate = useNavigate();

  //fetch All Apis
  const { data: uniformcategories } = useCommonFetchApi('uniformcategories');
  const { data: uniformtypes } = useCommonFetchApi('uniformtypes');

  const handleSubmituniforms = async (values) => {
    startCreateLoading();

    const combinedData = {
      ...values,
      requestDate: dayjs(values?.requestDate).format('YYYY-MM-DD'),
    };

    await crud.create('uniforms', combinedData, (err, res) => {
      if (res?.status === 201) {
        toast.success('Uniform Created Successfully');
        reset();
        stopCreateLoading();
        navigate(-1);
      } else {
        stopCreateLoading();
      }
    });
  };

  const handleChangeuniformType = async (e) => {
    if (e?.uniformTypeId) {
      await crud.getAll(
        'uniformsizes',
        { uniformTypeId: e?.uniformTypeId },
        (err, res) => {
          if (res?.status === 200) {
            setuniformsizes(res?.data?.data);
          } else {
            setuniformsizes([]);
          }
        }
      );
    } else {
      setuniformsizes([]);
    }
  };

  return (
    <DashboardLayout title='Create Uniform'>
      <GlassCard className='mb-3 p-3'>
        <form onSubmit={handleSubmit(handleSubmituniforms)}>
          <Grid container spacing={2}>
            <Grid item md={GridValue}>
              <FmSearchableSelect
                name='projectId'
                control={control}
                apiUrl='projects'
                valueField='projectId'
                headerField={['Project Code', 'Project Name']}
                labelField={['projectCode', 'projectName']}
                showField={['projectName']}
                label={'Projects'}
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmAutoComplete
                name='uniformTypeId'
                control={control}
                options={uniformtypes}
                label='Uniform Type'
                displayField='uniformTypeName'
                optionFields={['uniformTypeName']}
                valueKey='uniformTypeId'
                onChangeValue={true}
                onChange={handleChangeuniformType}
                required
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmAutoComplete
                name='uniformSizeId'
                control={control}
                options={uniformsizes}
                label='Uniform Size'
                displayField='uniformSizeName'
                optionFields={['uniformSizeName']}
                valueKey='uniformSizeId'
                required
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmAutoComplete
                name='uniformCategoryId'
                control={control}
                options={uniformcategories}
                label='Uniform Category'
                displayField='uniformCategoryName'
                optionFields={['uniformCategoryName']}
                valueKey='uniformCategoryId'
              />
            </Grid>

            <Grid item md={GridValue}>
              <FmDatePicker
                name='requestDate'
                control={control}
                label='Request Date'
                requestDate
              />
            </Grid>

            <Grid item md={GridValue}>
              <FmTextField name='quantity' control={control} label='Quantity' required />
            </Grid>
          </Grid>
          <ActionButtons
            onSubmit={handleSubmit(handleSubmituniforms)}
            onReset={reset}
            submitLoading={createLoading}
            submitText='Create'
            cancelText='Cancel'
            onCancel={() => navigate(-1)}
          />
        </form>
      </GlassCard>
    </DashboardLayout>
  );
};

export default AddUniforms;
