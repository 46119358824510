import React, { memo } from 'react';
import { Dialog, Slide, Box, IconButton } from '@mui/material';
import { Icon } from '@iconify/react/dist/iconify.js';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const MuiDialogFullscreen = memo(
  ({ title, open, onClose, children, lg, width = '600px', ...props }) => {
    return (
      <Dialog
        fullScreen
        onClose={onClose}
        open={open}
        TransitionComponent={Transition}
      >
        <Box className='flex h-full flex-col'>
          {title && (
            <Box
              className='px-3 py-1 flex justify-between items-center relative'
              sx={{
                borderBottom: '1px solid',
                borderColor: 'border.main',
                bgcolor: 'background.light',
              }}
            >
              <Box className='font-bold text-base'>{title}</Box>
              <IconButton aria-label='close' onClick={onClose}>
                <Icon icon='material-symbols:close' />
              </IconButton>
            </Box>
          )}
          <Box className='flex-1 min-h-0'>{children}</Box>
        </Box>
      </Dialog>
    );
  }
);

export default MuiDialogFullscreen;
