import React, { useRef, useState } from 'react'
import DashboardLayout from '../../../components/DashboardLayout.tsx'
import { Box, Grid } from '@mui/material'
import GlassCard from '../../../components/small/GlassCard.tsx'
import FmSearchableSelect from '../../../components/_mui/FmSearchableSelect.tsx'
import { Controller, useForm } from 'react-hook-form'
import FmDatePicker from '../../../components/_mui/FmDatePicker.tsx'
import FmTextField from '../../../components/_mui/FmTextField.tsx'
import MuiSignModal from '../../../components/_mui/MuiSignModal.tsx'
import { toast } from 'react-toastify'
import Crud_Service from '../../../apis/CrudService.jsx'
import useLoader from '../../../components/helpers/UseLoader.tsx'
import ActionButtons from '../../../components/_form/ActionButtons.tsx'
import { useLocation, useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import { yupResolver } from '@hookform/resolvers/yup'
import { passportRequestIssued } from '../../../utils/CommonvalidationSchemas.tsx'

function IssuePassportCreate() {
    const { control, setValue, trigger, reset, handleSubmit, formState: { errors }, } = useForm({
        mode: 'onChange',
        resolver: yupResolver(passportRequestIssued),
    });
    const { createLoading, stopEditLoading, startEditLoading } = useLoader();
    const location = useLocation();
    const { employeePassportRequestId } = location.state || {};
    const sigCanvas = useRef(null);
    const navigate = useNavigate();

    const [signatureImage, setSignatureImage] = useState(null);
    const [signatureId, setSignatureId] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const crud = new Crud_Service();
    const handleSignatureUpload = (fileId) => {
        setSignatureId(fileId);
    };
    const handleSaveSignature = async () => {
        if (sigCanvas.current && !sigCanvas.current.isEmpty()) {
            const signatureImage = sigCanvas.current.toDataURL("image/png");

            await uploadSignature(signatureImage);
        }
        setShowModal(false);
    };
    const uploadSignature = async (base64Image) => {
        const blob = await fetch(base64Image).then((res) => res.blob());
        const fileFormData = new FormData();
        fileFormData.append("file", blob, "signature.png");
        fileFormData.append("fileTypeId", 72);

        await crud.create(`/files/upload`, fileFormData, (_err, res) => {
            if (res?.status === 200) {
                setSignatureId(res?.data?.fileId);
                setSignatureImage(null);
                setValue("signatureImage", res?.data?.fileId);
                trigger("signatureImage");
                toast.success("signature upload successful");
            } else {

            }
        });
    };

    // const handleSubmitPassportRequestIssue = async (values) => {
    //     startCreateLoading();
    //     // const appliedOn = dayjs().format('YYYY-MM-DD');
    // const CombinedData = {
    //     // appliedOn: appliedOn
    //     passportIssuedOn: dayjs(values?.passportIssuedOn).format('YYYY-MM-DD'),
    //     issuedById: Number(values?.issuedById),
    //     receivedById: Number(values?.receivedById),
    //     signatureFileId: signatureId,
    //     issueRemarks: values?.issueRemarks,
    // };

    //     await crud.create(`employee-passport-requests/issue`, CombinedData, (_err, res) => {
    //         if (res?.status === 201) {
    //             toast.success('Issue Passport Created Successfully');
    //             navigate(-1);
    //             stopCreateLoading();
    //         } else {
    //             stopCreateLoading();
    //         }
    //     });
    // };
    const handleSubmitPassportRequestIssue = async (values) => {
        startEditLoading();
        const appliedOn = dayjs().format('YYYY-MM-DD');
        const CombinedData = {
            // appliedOn: appliedOn
            passportIssuedOn: dayjs(values?.passportIssuedOn).format('YYYY-MM-DD'),
            issuedById: Number(values?.issuedById),
            receivedById: Number(values?.receivedById),
            signatureFileId: signatureId,
            issueRemarks: values?.issueRemarks,
        };

        await crud.update(
            `employee-passport-requests/${employeePassportRequestId}/issue`,
            '',
            CombinedData,
            (err, res) => {
                if (res?.status === 200) {
                    toast.success('Passport Issue Updated Successfully');
                    navigate(-1);
                    stopEditLoading();
                } else {
                    stopEditLoading();
                }
            }
        );
    };
    return (
        <DashboardLayout title='Create New Issue Passport'>
            <Box className='my-5 mx-auto' sx={{ maxWidth: '500px' }}>
                <GlassCard className='p-4'>
                    <form onSubmit={handleSubmit(handleSubmitPassportRequestIssue)}>
                        <Grid container spacing={2}>
                            <Grid item md={12}>
                                <FmSearchableSelect
                                    name='issuedById'
                                    control={control}
                                    apiUrl='loginusers'
                                    valueField='loginUserId'
                                    labelField={['employeeCode', 'loginUserName']}
                                    showField={['employeeCode', 'loginUserName']}
                                    label='Issued By'
                                    required
                                />
                            </Grid>
                            <Grid item md={12}>
                                <FmDatePicker
                                    name='PassportIssuedOn'
                                    label='Passport Issued On'
                                    control={control}
                                />
                            </Grid>
                            <Grid item md={12}>
                                <FmSearchableSelect
                                    name='receivedById'
                                    control={control}
                                    apiUrl='loginusers'
                                    valueField='loginUserId'
                                    labelField={['employeeCode', 'loginUserName']}
                                    showField={['employeeCode', 'loginUserName']}
                                    label='Received By'
                                    required
                                />
                            </Grid>
                            <Grid item md={12}>
                                <Controller
                                    name="signatureImage"
                                    control={control}
                                    rules={{ required: "Signature is required" }}
                                    render={({ field }) => (
                                        <MuiSignModal
                                            onSignatureUpload={(image) => {
                                                handleSignatureUpload(image);
                                                field.onChange(image);
                                            }}
                                            signatureImage={signatureImage}
                                            sigCanvas={sigCanvas}
                                            handleSaveSignature={handleSaveSignature}
                                            showModal={showModal}
                                            setShowModal={setShowModal}
                                            handleClearSignature={() => {
                                                setSignatureImage(null);
                                                field.onChange(null);
                                            }}
                                        />
                                    )}
                                />
                                {errors?.signatureImage && (
                                    <p style={{ color: "red" }}>
                                        {errors?.signatureImage?.message}
                                    </p>
                                )}
                            </Grid>

                            <Grid item md={12}>
                                <FmTextField
                                    name='issueRemarks'
                                    control={control}
                                    label='Issue Remark'
                                    multiline
                                    rows={3}
                                    maxRows={4}
                                />
                            </Grid>
                        </Grid>
                        <ActionButtons
                            onSubmit={handleSubmit(handleSubmitPassportRequestIssue)}
                            onCancel={() => navigate(-1)}
                            onReset={reset}
                            submitLoading={createLoading}
                            cancelLoading={false}
                        />
                    </form>
                </GlassCard>
            </Box>
        </DashboardLayout>
    )
}

export default IssuePassportCreate
