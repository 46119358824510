import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import dayjs from 'dayjs';
import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import Crud_Service from '../../../../apis/CrudService.jsx';
import ActionButtons from '../../../../components/_form/ActionButtons.tsx';
import FmDatePicker from '../../../../components/_mui/FmDatePicker.tsx';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import { findNavByName } from '../../../../utils/navUtils.ts';

const GridSize = 2.4;
const schema = yup.object().shape({
  companyId: yup
    .number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Company is required'),

  workOrderName: yup.string().required('Work Order Name is required'),
  workOrderTypeId: yup
    .number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Work Order Type is required'),

  amount: yup
    .number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Amount is required'),
});
const CreateWorkOrder = () => {
  const crud = new Crud_Service();
  const navigate = useNavigate();

  const { control, handleSubmit, reset, setValue } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });
  const [loading, setLoading] = useState(false);
  const [selectedClient, setSelectedClient] = React.useState(null);
  const [selectedProject, setSelectedProject] = React.useState(null);
  const submitWorkOrder = async (values) => {
    setLoading(true);
    const data = {
      ...values,
      startDate: dayjs(values?.startDate).format('YYYY-MM-DD'),
    };
    await crud.create('workorders', data, (_err, res) => {
      if (res?.status === 201) {
        toast.success('Added Work Order Successfully');
        navigate(-1);
        reset();
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  const handleSelectedClient = (e) => {
    setSelectedClient(e);
  };
  const menu = useSelector((state) => state?.menu?.items);

  const SubMenu = useMemo(() => {
    return menu?.map((item) => item?.menus?.flat()).flat() || [];
  }, [menu]);

  const result = useMemo(
    () => findNavByName(SubMenu, 'CAFM', 'Work Order'),
    [SubMenu]
  );

  return (
    <DashboardLayout title='Create Work Order' hasSubmenu menu={result}>
      <GlassCard className='p-3'>
        <form onSubmit={handleSubmit(submitWorkOrder)}>
          <Grid container spacing={2}>
            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='companyId'
                label='Company'
                apiUrl={'companies'}
                valueField='companyId'
                labelField={['companyName', 'companyCode']}
                showField={['companyName', 'companyCode']}
                control={control}
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='clientId'
                label='Client'
                apiUrl='clients'
                valueField='clientId'
                onChangeProp={handleSelectedClient}
                labelField={['clientName', 'clientCode']}
                showField={['clientName', 'clientCode']}
                control={control}
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='projectId'
                label='Project'
                apiUrl={'projects'}
                queryparam='clientId'
                queryparamValue={selectedClient?.clientId}
                valueField='projectId'
                onChangeProp={setSelectedProject}
                labelField={['projectName', 'projectCode']}
                showField={['projectName', 'projectCode']}
                control={control}
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='contractId'
                label='Contract'
                apiUrl={'contracts'}
                queryparam='projectId'
                queryparamValue={selectedProject?.projectId}
                valueField='contractId'
                labelField={['contractName', 'contractCode']}
                showField={['contractName', 'contractCode']}
                onChangeProp={(ev) => setValue('amount', ev?.contractCost)}
                control={control}
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='previousContractId'
                label='Previous Contract'
                apiUrl={'contracts'}
                valueField='contractId'
                labelField={['contractName', 'contractCode']}
                showField={['contractName', 'contractCode']}
                control={control}
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmTextField
                name='workOrderName'
                required
                label='Name'
                control={control}
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='workOrderTypeId'
                label='Work Order Type'
                apiUrl={'workorders/types'}
                valueField='workOrderTypeId'
                required
                labelField={['workOrderTypeName']}
                showField={['workOrderTypeName']}
                control={control}
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmTextField
                name='workOrderCode'
                label='Work Order Code'
                control={control}
              />{' '}
            </Grid>
            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='divisionId'
                label='Division'
                apiUrl={'divisions'}
                valueField='divisionId'
                labelField={['divisionName', 'divisionCode']}
                showField={['divisionName', 'divisionCode']}
                control={control}
              />
            </Grid>

            <Grid item md={GridSize}>
              <FmDatePicker
                name='startDate'
                label='Start Date'
                control={control}
              />
            </Grid>

            <Grid item md={GridSize}>
              <FmTextField
                label='Amount'
                name='amount'
                required
                pattern='Number'
                control={control}
              />
            </Grid>
            <Grid item md={12}>
              <ActionButtons
                onSubmit={handleSubmit(submitWorkOrder)}
                onReset={reset}
                submitLoading={loading}
                cancelLoading={false}
                cancelText='Go Back'
                onCancel={() =>
                  navigate('/fm-division/work-order/work-order-list')
                }
                submitText='Create'
              />
            </Grid>
          </Grid>
        </form>
      </GlassCard>
    </DashboardLayout>
  );
};

export default CreateWorkOrder;
