import { Box } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useTableLogic from '../../../../components/helpers/MRTUseTableLogic.tsx';
import { CreateButton } from '../../../../components/small/Buttons.jsx';
import { findNavByName } from '../../../../utils/navUtils.ts';
import DashboardLayout from './../../../../components/DashboardLayout.tsx';
import CreateCheckInFormList from './CreateCheckInFormList.tsx';
import CreateCheckOutFormList from './CreateCheckOutFormList.tsx';

const CreateCheckInForm = ({ type }) => {
  const {
    rows,
    setRows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic(type);

  const navigate = useNavigate();
  useEffect(() => {
    document.title =
      type === 'checkinforms'
        ? 'Check In Form | FM Division '
        : 'Check Out Form | FM Division ';
    fetchData();
  }, [type]);
  const menu = useSelector((state) => state?.menu?.items);

  const SubMenu = useMemo(() => {
    return menu?.map((item) => item?.menus?.flat()).flat() || [];
  }, [menu]);

  const result = useMemo(
    () => findNavByName(SubMenu, 'FM Division', 'Work Order'),
    [SubMenu]
  );
  return (
    <DashboardLayout
      title={type === 'checkinforms' ? 'Check In Form' : 'Check Out Form'}
      hasSubmenu
      menu={result}
      actionButtons={
        <>
          <Box className='flex flex-row items-center whitespace-nowrap gap-3'>
            {type === 'checkinforms' ? (
              <CreateButton
                name='Create Check In Form'
                onClick={() => navigate('create')}
              />
            ) : (
              <CreateButton
                name='Create Check Out Form'
                onClick={() => navigate('create')}
              />
            )}
          </Box>
        </>
      }
    >
      {type === 'checkinforms' ? (
        <>
          <CreateCheckInFormList
            rows={rows}
            tableRecordCounts={tableRecordCounts}
            isLoading={isLoading}
            pagination={pagination}
            sorting={sorting}
            setPagination={setPagination}
            setSorting={setSorting}
            columnFilters={columnFilters}
            globalFilter={globalFilter}
            setColumnFilters={setColumnFilters}
            setGlobalFilter={setGlobalFilter}
            setSearchKeyword={setSearchKeyword}
            searchKeyword={searchKeyword}
            fetchData={fetchData}
          />
        </>
      ) : (
        <>
          <CreateCheckOutFormList
            rows={rows}
            tableRecordCounts={tableRecordCounts}
            isLoading={isLoading}
            pagination={pagination}
            sorting={sorting}
            setPagination={setPagination}
            setSorting={setSorting}
            columnFilters={columnFilters}
            globalFilter={globalFilter}
            setColumnFilters={setColumnFilters}
            setGlobalFilter={setGlobalFilter}
            setSearchKeyword={setSearchKeyword}
            searchKeyword={searchKeyword}
            fetchData={fetchData}
          />
        </>
      )}
    </DashboardLayout>
  );
};

export default CreateCheckInForm;
