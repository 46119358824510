import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import { Box, Grid } from '@mui/material';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import ActionButtons from '../../../../components/_form/ActionButtons.tsx';
import { useNavigate, useParams } from 'react-router-dom';
import Crud_Service from '../../../../apis/CrudService.jsx';
import { useForm } from 'react-hook-form';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import CommonLoader from '../../../../components/page/CommonLoader.jsx';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import { CreateButton } from '../../../../components/small/Buttons.jsx';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import { statusOptions } from '../../../../utils/CommonVariables.tsx';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import FmTimePicker from '../../../../components/_mui/FmTimePicker.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { HiredVechiclesSchema } from '../../../../utils/CommonvalidationSchemas.tsx';

const EditHiredVehicles = () => {
  const navigate = useNavigate();
  const crud = new Crud_Service();
  const [loading, setLoading] = useState(false);
  const [editData, setEditData] = useState(null);
  const [adRequired, setAdRequired] = React.useState(false);
  const { handleSubmit, control, setValue, reset } = useForm({
    resolver: yupResolver(HiredVechiclesSchema),
    mode: 'onChange',
  });
  const { startEditLoading, stopEditLoading, editLoading } = useLoader();
  const { id } = useParams();
  const GridValue = 2.4;

  useEffect(() => {
    getOwnVehicles();
  }, []);

  const getOwnVehicles = async () => {
    setLoading(true);
    await crud.getSingle('hiredvehicles', id, (_err, res) => {
      if (res?.status === 200) {
        const data = res?.data;
        Object.entries({
          ...data,
        }).forEach(([key, value]) => {
          setValue(key, value);
        });
        setEditData(res?.data);
        setLoading(false);
      } else {
      }
    });
  };

  const handleUpdateHiredVechicles = async (values) => {
    startEditLoading();
    const combinedData = {
      ...values,
      requistionDate: dayjs(values?.requistionDate).format('YYYY-MM-DD'),
    };
    await crud.update('hiredvehicles', id, combinedData, (err, res) => {
      if (res?.status === 200) {
        toast.success('Hired Vechicles Updated Successfully');
        navigate(-1);
        stopEditLoading();
      } else {
        stopEditLoading();
      }
    });
  };

  return (
    <DashboardLayout
      title='Edit Own Vehicles'
      actionButtons={
        <>
          <Box className='flex flex-row items-center whitespace-nowrap gap-3'>
            <CreateButton
              name='Create'
              onClick={() => navigate('/admin/vehicles/own-vehicles/create')}
            />
          </Box>
        </>
      }
    >
      <GlassCard className='p-5'>
        {loading ? (
          <CommonLoader />
        ) : (
          <form onSubmit={handleSubmit(handleUpdateHiredVechicles)}>
            <Grid container spacing={2}>
              <Grid item md={GridValue}>
                <FmTextField
                  name='vehicleRegNo'
                  control={control}
                  label='Vehicle Reg No'
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmTextField
                  name='vehiclePlateNo'
                  control={control}
                  label='Vehicle Plate No'
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmSearchableSelect
                  name='vehicleTypeId'
                  control={control}
                  apiUrl='vehicletypes'
                  valueField='vehicleTypeId'
                  labelField={['vehicleTypeCode', 'vehicleTypeName']}
                  showField={['vehicleTypeCode', 'vehicleTypeName']}
                  label={'Vehicle Type'}
                  defaultValue={{
                    vehicleTypeId: editData?.vehicleTypeId,
                    vehicleTypeName: editData?.vehicleTypeName,
                  }}
                  required
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmSearchableSelect
                  name='siteId'
                  control={control}
                  apiUrl='buildings'
                  valueField='buildingId'
                  labelField={['buildingName']}
                  showField={['buildingName']}
                  label={'Site'}
                  defaultValue={{
                    buildingId: editData?.siteId,
                    buildingName: editData?.siteName,
                  }}
                  required
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmSearchableSelect
                  name='locationId'
                  control={control}
                  apiUrl='locations'
                  valueField='locationId'
                  labelField={['locationCode', 'locationName']}
                  showField={['locationCode', 'locationName']}
                  label={'Location'}
                  defaultValue={{
                    locationId: editData?.locationId,
                    locationName: editData?.locationName,
                  }}
                  required
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmSearchableSelect
                  name='divisionId'
                  control={control}
                  apiUrl='divisions'
                  valueField='divisionId'
                  labelField={['divisionCode', 'divisionName']}
                  showField={['divisionCode', 'divisionName']}
                  label={'Division'}
                  defaultValue={{
                    divisionId: editData?.divisionId,
                    divisionName: editData?.divisionName,
                  }}
                  required
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmTimePicker
                  control={control}
                  name='pickUpTime'
                  label='Pick-up Time'
                  required={true}
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmTimePicker
                  control={control}
                  name='dropUpTime'
                  label='Drop-up Time'
                  required={true}
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmTextField
                  name='currentKilometer'
                  control={control}
                  label='Current Kilometer'
                  required
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmSearchableSelect
                  name='supplierId'
                  control={control}
                  apiUrl='suppliers'
                  valueField='supplierId'
                  labelField={['supplierCode', 'officialSupplierName']}
                  showField={['supplierCode', 'officialSupplierName']}
                  label={'Supplier'}
                  defaultValue={{
                    supplierId: editData?.supplierId,
                    officialSupplierName: editData?.supplierName,
                  }}
                  required
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmSearchableSelect
                  name='stationId'
                  control={control}
                  apiUrl='emirates'
                  valueField='emirateId'
                  labelField={['emirateName']}
                  showField={['emirateName']}
                  label={'Station'}
                  defaultValue={{
                    emirateId: editData?.stationId,
                    emirateName: editData?.stationName,
                  }}
                  required
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmSearchableSelect
                  name='driverId'
                  control={control}
                  apiUrl='drivers'
                  valueField='driverId'
                  labelField={['driverName', 'driverContactNumber']}
                  showField={['driverName', 'driverContactNumber']}
                  label={'Driver'}
                  defaultValue={{
                    driverId: editData?.driverId,
                    driverName: editData?.driverName,
                  }}
                  required
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmSearchableSelect
                  name='projectId'
                  control={control}
                  apiUrl='projects'
                  valueField='projectId'
                  labelField={['projectCode', 'projectName']}
                  showField={['projectCode', 'projectName']}
                  label={'Project'}
                  defaultValue={{
                    projectId: editData?.projectId,
                    projectName: editData?.projectName,
                  }}
                  required
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmSearchableSelect
                  name='contractId'
                  control={control}
                  apiUrl='contracts'
                  valueField='contractId'
                  labelField={['contractCode', 'contractName']}
                  showField={['contractCode', 'contractName']}
                  label={'Contract'}
                  defaultValue={{
                    contractId: editData?.contractId,
                    contractName: editData?.contractName,
                  }}
                  required
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmSearchableSelect
                  name='contactPersonId'
                  control={control}
                  apiUrl='supplierspocs'
                  valueField='supplierSPOCId'
                  labelField={['supplierSPOCName', 'phoneNumber']}
                  showField={['supplierSPOCName', 'phoneNumber']}
                  label={'Contact Person'}
                  defaultValue={{
                    supplierSPOCId: editData?.contactPersonId,
                    supplierSPOCName: editData?.contactPersonName,
                  }}
                  required
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmTextField
                  name='rentAmount'
                  control={control}
                  label='Rent Amount'
                  pattern='Decimal'
                  required
                />
              </Grid>
              <Grid item md={6}>
                <FmTextField
                  name='remarks'
                  control={control}
                  label='Remarks'
                  multiline={true}
                  rows={2}
                  maxRows={3}
                />
              </Grid>
            </Grid>
            <ActionButtons
              onSubmit={handleSubmit(handleUpdateHiredVechicles)}
              onReset={reset}
              submitLoading={editLoading}
              submitText='Update'
              cancelText='Cancel'
              onCancel={() => navigate(-1)}
            />
          </form>
        )}
      </GlassCard>
    </DashboardLayout>
  );
};

export default EditHiredVehicles;
