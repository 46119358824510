import React, { useState, useEffect } from 'react';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import DataTable from '../../../../components/DataTable.tsx';
import PageHeader from '../../../../components/PageHeader.jsx';
import { Icon } from '@iconify/react';
import { CreateButton } from '../../../../components/small/Buttons.jsx';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import { Grid } from '@mui/material';
import {
  StatusBadge,
  ViewField,
} from '../../../../components/_form/FormElements.jsx';
import Crud_Service from '../../../../apis/CrudService.jsx';
import useTableLogic from '../../../../components/helpers/UseTableLogic.tsx';
import TableSearch from '../../../../components/_form/TableSearch.jsx';
import CommonLoader from '../../../../components/page/CommonLoader.jsx';
import apiInstance from '../../../../apis/ApiService.jsx';
import { ToolbarBoxesFlex } from '../../../../components/boxes/Boxes.jsx';
import TableAvatar from '../../../../components/_form/TableAvatar.jsx';
import DataTableToolbarButtons from '../../../../components/helpers/DataTableToolbarButtons.tsx';
import MuiDialogOne from '../../../../components/_mui/MuiDialogOne.jsx';
import TableActionButtons from '../../../../components/_form/TableActionButtons.jsx';
import FmViewDateField from '../../../../components/_mui/FmViewDateField.tsx';
import { toast } from 'react-toastify';
import Icons from '../../../../utils/Icon.jsx';

const CompanyCertificate = () => {
  const navigate = useNavigate();
  const [selectedRow, setSelectedRow] = useState();
  const crud = new Crud_Service();
  const {
    rows,
    pageSize,
    pageNumber,
    setSearchKeyword,
    isLoading,
    tableRecordCounts,
    handlePaginationModelChange,
    handleSortModelChange,
    fetchData,
    toggleLoading,
    searchKeyword,
  } = useTableLogic('certificatecatalogpayments');

  useEffect(() => {
    document.title = `HRMS - Company Certificates`;
  }, []);

  //view
  const handleViewClick = async (view) => {
    navigate(`view/${view?.certificateCatalogPaymentId}`);
  };
  const downloadCertificates = async () => {
    toggleLoading(true);
    const downloadUrl = await apiInstance.getFiles(
      'companycertifications/exportexcel'
    );

    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', 'Company-Certifications.xlsx');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    toggleLoading(false);
  };

  const handleEditClick = (view) => {
    navigate(`edit/${view?.certificateCatalogPaymentId}`);
  };

  const handleDeleteClick = async (view) => {
    await crud.remove(
      'companycertifications',
      view?.certificateCatalogPaymentId,
      (_err, res) => {
        if (res?.status === 204) {
          toast.success('Company Certificate Deleted Successfully');
          fetchData();
        } else {
        }
      }
    );
  };

  const handleDownloadClick = async (props, type) => {
    let fileId, fileName;
    if (type === 'receipt') {
      fileId = props?.receiptFileId;
      fileName = props?.receiptFileName;
    } else if (type === 'certificate') {
      fileId = props?.certificateCatalog?.certificationFileId;
      fileName = props?.certificateCatalog?.certificationFileName;
    }

    if (!fileId) {
      toast.info(`No files available for this ${type}`);
      return;
    }

    try {
      const imageUrl = await apiInstance.getFiles(`files/download/${fileId}`);
      const link = document.createElement('a');
      link.href = imageUrl;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      toast.error('Failed to download the file. Please try again.');
    }
  };

  const Actions = [
    {
      name: 'View',
      icon: Icons.view,
      onClick: (props) => handleViewClick(props),
    },
    {
      name: 'Edit',
      icon: Icons.edit,
      onClick: (props) => handleEditClick(props),
    },
    {
      name: 'Download Receipt',
      icon: <Icon icon='material-symbols:receipt-outline' />,
      onClick: (props) => handleDownloadClick(props, 'receipt'),
    },
    {
      name: 'Download Certficate',
      icon: <Icon icon='mdi:certificate-outline' />,
      onClick: (props) => handleDownloadClick(props, 'certificate'),
    },
    {
      name: 'Delete',
      icon: Icons.delete,
      onClick: (props) => handleDeleteClick(props),
      danger: true,
    },
  ];

  const columns = [
    {
      field: 'column_sort',
      headerName: '',
      width: 150,
      sortable: false,
      disableClickEventBubbling: false,
      renderCell: (params) => {
        return (
          <TableActionButtons
            Actions={Actions?.map((action) => ({
              ...action,
              onClick: () => action.onClick(params?.row),
            }))}
            setSelectedRow={setSelectedRow}
          />
        );
      },
    },

    {
      field: 'certificateCatalogPaymentCode',
      headerName: 'Code',
      width: 200,
      renderCell: (params) => {
        return <TableAvatar name={params?.value} />;
      },
    },
    {
      field: 'expenseTypeName',
      headerName: 'Expense Name',
      flex: 5,
      hideable: false,
      minWidth: 220,
    },
    {
      field: 'paymentDate',
      headerName: 'Payment date',
      flex: 5,
      minWidth: 150,
      renderCell: (params) => {
        return <FmViewDateField value={params?.value} />;
      },
    },
    {
      field: 'cost',
      headerName: 'Cost',
      minWidth: 150,
    },
    {
      field: 'costCenterName',
      headerName: 'Cost Center',
      minWidth: 150,
    },
    {
      field: 'transactionNumber',
      headerName: 'Transaction Number',
      minWidth: 150,
    },

    {
      field: 'issuedDate',
      headerName: 'Issued date',
      flex: 5,
      minWidth: 150,
      valueGetter: (params) =>
        params?.row?.certificateCatalog?.issuedDate || '',
      renderCell: (params) => {
        return <FmViewDateField value={params?.value} />;
      },
    },
    {
      field: 'expiryDate',
      headerName: 'Expiry date',
      flex: 5,
      minWidth: 150,
      valueGetter: (params) =>
        params?.row?.certificateCatalog?.expiryDate || '',
      renderCell: (params) => {
        return FmViewDateField({ value: params?.value });
      },
    },
    {
      field: 'cancellationDate',
      headerName: 'Cancellation Date',
      flex: 5,
      minWidth: 180,
      valueGetter: (params) =>
        params?.row?.certificateCatalog?.cancellationDate || '',
      renderCell: (params) => {
        return FmViewDateField({ value: params?.value });
      },
    },
    {
      field: 'certificateStatusName',
      headerName: 'Certificate Status',
      flex: 5,
      minWidth: 150,
      valueGetter: (params) =>
        params?.row?.certificateCatalog?.certificateStatusName || '',
      renderCell: (params) => {
        return (
          <StatusBadge
            title={params?.value}
            type={params?.value === 'Submitted' ? 'green' : 'red'}
          />
        );
      },
    },
  ];

  const counters = [];

  //Row Double Click
  const onRowDoubleClick = (e) => {
    if (e?.row) {
      handleEditClick(e?.row);
    }
  };

  return (
    <DashboardLayout
      title='Company Certificates'
      actionButtons={
        <>
          <Box className='flex flex-row items-center whitespace-nowrap gap-3'>
            <PageHeader counters={counters} />
            <Box className='flex flex-row items-center gap-3'>
              <CreateButton
                name={'Create'}
                onClick={() => navigate('create')}
              />
            </Box>
          </Box>
        </>
      }
      hasSubmenu
    >
      <GlassCard className='h-full-css'>
        <Box className='h-full-css'>
          <DataTable
            rows={rows}
            columns={columns}
            loading={isLoading}
            onRowDoubleClick={(e) => onRowDoubleClick(e)}
            getRowClassName={(params) =>
              `${
                params.row.workingStatus === 'InActive' &&
                'bg-red-500 bg-opacity-15'
              }`
            }
            sortingMode='server'
            paginationMode='server'
            onPaginationModelChange={(model) =>
              handlePaginationModelChange(model)
            }
            onSortModelChange={(model) => handleSortModelChange(model)}
            page={pageNumber - 1}
            pageSize={pageSize}
            rowCount={tableRecordCounts?.total}
            slots={{
              toolbar: () => (
                <ToolbarBoxesFlex>
                  <TableSearch
                    placeholder='Search'
                    setSearchKeyword={setSearchKeyword}
                    searchValue={searchKeyword}
                  />
                  <DataTableToolbarButtons
                    handleExcelExport={downloadCertificates}
                  />
                </ToolbarBoxesFlex>
              ),
            }}
          />
        </Box>
      </GlassCard>

      {/* {viewData && (
        <>
          <MuiDialogOne
            open={viewData}
            title='View Details'
            onClose={() => setViewData(!viewData)}
          >
            {' '}
            {viewLoading ? (
              <CommonLoader />
            ) : (
              <Grid container spacing={3}>
                <Grid item md={6}>
                  <ViewField
                    label='Company Name'
                    title={selectedRow?.companyName}
                  />
                </Grid>
                <Grid item md={6}>
                  <ViewField
                    label='Certificate Name'
                    title={selectedRow?.certificationName}
                  />
                </Grid>{' '}
                <Grid item md={6}>
                  <FmViewDateField
                    title='Expiry Date'
                    value={selectedRow?.expiryDate}
                  />
                </Grid>
                <Grid item md={6}>
                  <FmViewDateField
                    title='Last Renewal Date'
                    value={selectedRow?.lastRenewDate}
                  />
                </Grid>
                <Grid item md={6}>
                  <ViewField
                    label='Location Name'
                    title={selectedRow?.locationName}
                  />
                </Grid>
                <Grid item md={6}>
                  <ViewField
                    label='Reminder Days'
                    title={selectedRow?.reminderDays}
                  />
                </Grid>
                <Grid item md={6}>
                  <ViewField
                    label='Certification Status'
                    title={selectedRow?.certificateStatusName}
                  />
                </Grid>
                <Grid item md={6}>
                  <FmViewDateField
                    title='Start Date'
                    value={selectedRow?.startDate}
                  />
                </Grid>
                <Grid item md={6}>
                  <ViewField label='Status' title={selectedRow?.statusName} />
                </Grid>
                <Grid item md={6}>
                  <ViewField label='Remarks' title={selectedRow?.remarks} />
                </Grid>
              </Grid>
            )}
          </MuiDialogOne>
        </>
      )} */}
    </DashboardLayout>
  );
};

export default CompanyCertificate;
