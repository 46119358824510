import { Icon } from '@iconify/react';
import { Box, Button, Grid, IconButton } from '@mui/material';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Crud_Service from '../../../apis/CrudService';
import { ActionButtons } from '../../../components/_form';
import FmAutoComplete from '../../../components/_mui/FmAutoComplete.tsx';
import FmDatePicker from '../../../components/_mui/FmDatePicker.tsx';
import FmFileInput from '../../../components/_mui/FmFileInput.tsx';
import FmSearchableSelect from '../../../components/_mui/FmSearchableSelect.tsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import useCommonFetchApi from '../../../components/helpers/useCommonFetchApi.tsx';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import CommonLoader from '../../../components/page/CommonLoader';
import GlassCard from '../../../components/small/GlassCard.tsx';
import apiInstance from '../../../apis/ApiService.jsx';

const GridValue = 2.4;

const FollowupEdit = () => {
  const navigate = useNavigate();
  const crud = new Crud_Service();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  // const [errors, setErrors] = useState({});
  const [editData, setEditData] = useState(null);
  const [uploadLoading, setUploadLoading] = useState({});
  const [uploadedFileData, setUploadedFileData] = useState({});
  const [followUpType, setFollowUpType] = useState(null);
  const { control, handleSubmit, setValue, watch } = useForm();
  const rescheduleDate = watch('scheduleDate');
  const remainderDate = watch('remainderDate');
  const { editLoading, startEditLoading, stopEditLoading } = useLoader();
  const { t } = useTranslation();


  useEffect(() => {
    getFollowUps();
  }, []);

  const getFollowUps = async () => {
    setLoading(true);
    await crud.getSingle('tenderfollowups', id, (err, res) => {
      if (res?.status === 200) {
        const data = res?.data || {};

        // Set form values for non-array fields
        Object.entries(data).forEach(([key, value]) => {
          setValue(key, value);
        });

        // Preprocess arrays for FieldArrays
        // const tenderFollowUpAssigns =
        //   data?.tenderFollowUpAssigns?.map((assign) => ({
        //     ...assign,
        //     tenderAssigns:
        //       assign?.tenderAssigns?.map((nested) => ({
        //         ...nested,
        //         email: nested?.assignEmail || '',
        //         contactNo: nested?.assignContact || '',
        //       })) || [],
        //     scheduleDate: assign?.scheduleDate || null,
        //     reminderDate: assign?.reminderDate || null,
        //   })) || [];

        // const tenderFollowupAttachments =
        //   data?.tenderFollowupAttachments?.map((attachment) => ({
        //     ...attachment,
        //     fileName: attachment?.fileName || '',
        //     remarks: attachment?.remarks || '',
        //   })) || [];

        replace(data?.tenderFollowUpSchedules);
        replaceAttachment(data?.tenderFollowupAttachments);
        setEditData(data);
      } else {
        console.error(err);
      }
      setLoading(false);
    });
  };

  //Multiple Append Fields
  const { fields, append, remove, replace, update } = useFieldArray({
    control,
    name: 'tenderFollowUpAssigns',
  });

  const handleAddNestedField = (index: number) => {
    const updatedFields = [...fields];
    updatedFields[index].tenderAssigns.push({
      assignId: '',
      name: '',
      email: '',
      contactNo: '',
    });
    update(index, updatedFields[index]);
  };

  const handleRemoveNestedField = (outerIndex: number, innerIndex: number) => {
    const updatedFields = [...fields];
    updatedFields[outerIndex].tenderAssigns.splice(innerIndex, 1);
    update(outerIndex, updatedFields[outerIndex]);
  };

  //for Multiple Attachments
  const {
    fields: attachmentFields,
    append: appendAttachment,
    remove: removeAttachment,
    replace: replaceAttachment,
  } = useFieldArray({
    control,
    name: 'tenderFollowupAttachments',
  });

  //gte All Apis
  const { data: tenderfollowuptypes } = useCommonFetchApi(
    'tenderfollowuptypes'
  );
  const { data: contactmodes } = useCommonFetchApi('contactmodes');

  // const getFollowUps = async () => {
  //   setLoading(true);
  //   await crud.getSingle('tenderfollowups', id, (err, res) => {
  //     if (res?.status === 200) {
  //       Object.entries({
  //         ...res?.data,
  //       }).forEach(([key, value]) => {
  //         setValue(key, value);
  //       });
  //       setEditData(res?.data);
  //       const { tenderFollowUpAssigns, tenderFollowupAttachments } = res?.data;
  //       replace(tenderFollowUpAssigns);
  //       replaceAttachment(tenderFollowupAttachments);
  //       setLoading(false);
  //     } else {
  //     }
  //   });
  // };

  useEffect(() => {
    if (editData) {
      setFollowUpType(editData);
      editData?.tenderFollowUpSchedules?.map((item, index) => {
        item?.tenderFollowUpAssigns?.map((nested, index2) => {
          handleChangeLoginUsers(nested, index, index2);
        });
      });
    }
  }, [editData]);

  const handleFileUpload = async (e, index) => {
    const file = e.target.files[0];
    if (!file) return;

    setUploadLoading((prev) => ({ ...prev, [index]: true }));

    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileTypeId', 64);

    await crud.create('/files/upload', formData, (err, res) => {
      setUploadLoading((prev) => ({ ...prev, [index]: false }));

      if (err || res?.status !== 200) {
        toast.error('File upload failed');
        return;
      }

      setUploadedFileData((prev) => ({
        ...prev,
        [index]: res?.data,
      }));
    });
  };

  const handleEditTenderFollowups = async (values) => {
    startEditLoading();
    const uploadedFilesArray = Object.values(uploadedFileData);

    const combinedData = {
      tenderId: values?.tenderId,
      primaryContactPerson: values?.primaryContactPerson,
      primaryContactMail: values?.primaryContactMail,
      primaryContactNumber: values?.primaryContactNumber,
      secondaryContactPerson: values?.secondaryContactPerson,
      secondaryContactMail: values?.secondaryContactMail,
      secondaryContactNumber: values?.secondaryContactNumber,
      tenderFollowupTypeId: values?.tenderFollowupTypeId,
      contactModeId: values?.contactModeId,
      comments: values?.comments,
      status: 2,
      scheduleDate: dayjs(values?.scheduleDate).format('YYYY-MM-DD'),
      reminderDate: dayjs(values?.reminderDate).format('YYYY-MM-DD'),
      updateTenderFollowUpAttachments:
        values?.tenderFollowupAttachments?.map((attachment, index) => ({
          tenderFollowupAttachmentId: attachment?.tenderFollowupAttachmentId,
          fileId: uploadedFilesArray[index]?.fileId || attachment?.fileId,
          remarks: attachment?.remarks,
        })) || [],
      updateTenderFollowUpSchedules:
        values?.tenderFollowUpSchedules?.map((schedule) => ({
          tenderFollowUpScheduleId: schedule?.tenderFollowUpScheduleId || 0,
          scheduleDate: dayjs(schedule?.scheduleDate).format('YYYY-MM-DD'),
          reminderDate: dayjs(schedule?.reminderDate).format('YYYY-MM-DD'),
          updateTenderFollowUpAssigns: [
            {
              tenderFollowUpScheduleId: schedule?.tenderFollowUpScheduleId || 0,
              updateTenderAssigns: schedule?.tenderFollowUpAssigns?.map(
                (assign) => ({
                  tenderFollowupAssignId: assign?.tenderFollowupAssignId || 0,
                  assignId: assign?.assignId || 0,
                })
              ),
            },
          ],
        })) || [],

    };

    await crud.update(
      `tenderfollowups`,
      values?.tenderFollowupId,
      combinedData,
      (err, res) => {
        if (res?.status === 200) {
          toast.success('Enquiry Followup Updated Successfully');
          stopEditLoading();
          navigate(-1);
        } else {
          stopEditLoading();
          return;
        }
      }
    );
  };

  const handleChangeFollowUpTypes = (e) => {
    setFollowUpType(e);
  };
  const downloadDocument = async ({ fileId, fileName }) => {
    try {
      const imageUrl = await apiInstance.getFiles(`files/download/${fileId}`);
      const link = document.createElement('a');
      link.href = imageUrl;
      link.setAttribute('download', `${fileName}`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      toast.error('Error fetching image:', err.message);
    }
  };

  const handleChangeLoginUsers = (e, index, index2) => {
    setValue(
      `tenderFollowUpSchedules[${index}].tenderFollowUpAssigns[${index2}].name`,
      e?.loginUserName
    );
    setValue(
      `tenderFollowUpSchedules[${index}].tenderFollowUpAssigns[${index2}].email`,
      e?.emailId
    );
    //TODO:if Contact No Comes from LoginUsers We Can Set
    // setValue(`tenderFollowUpAssigns[${index}].tenderAssigns[${index2}].contactNo`, e?.contactNo);
  };

  return (
    <DashboardLayout title='Edit Tender Followup'>
      {loading ? (
        <CommonLoader />
      ) : (
        <form onSubmit={handleSubmit(handleEditTenderFollowups)}>
          <Grid container spacing={2}>
            <Grid item md={GridValue}>
              <FmTextField
                name='tenderName'
                control={control}
                label='Tender'
                readOnly
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmTextField
                name='primaryContactPerson'
                control={control}
                label='Primary Contact Person'
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmTextField
                name='primaryContactMail'
                control={control}
                label='Primary Contact Mail'
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmTextField
                name='primaryContactNumber'
                control={control}
                label='Primary Contact Number'
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmTextField
                name='secondaryContactPerson'
                control={control}
                label='Secondary Contact Person'
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmTextField
                name='secondaryContactMail'
                control={control}
                label='Secondary Contact Mail'
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmTextField
                name='secondaryContactNumber'
                control={control}
                label='Secondary Contact Number'
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmDatePicker
                name='scheduleDate'
                label='schedule Date'
                control={control}
                required
                // minDate={remainderDate ? dayjs(remainderDate) : null}
                minDate={remainderDate ? dayjs(remainderDate) : null} //remainder

              />
            </Grid>
            <Grid item md={GridValue}>
              <FmAutoComplete
                name='tenderFollowupTypeId'
                control={control}
                options={tenderfollowuptypes}
                label='Enquiry Followup Type'
                displayField='tenderFollowupTypeName'
                optionFields={['tenderFollowupTypeName']}
                valueKey='tenderFollowupTypeId'
                required
                onChangeValue={true}
                onChange={(e) => handleChangeFollowUpTypes(e)}
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmAutoComplete
                name='contactModeId'
                control={control}
                options={contactmodes}
                label='Contact Modes'
                displayField='contactModeName'
                optionFields={['contactModeName']}
                valueKey='contactModeId'
                required
              />
            </Grid>

            <Grid item md={GridValue}>
              <FmDatePicker
                name='reminderDate'
                label='Remainder Date'
                control={control}
                required
                // maxDate={rescheduleDate ? dayjs(rescheduleDate) : null}
                minDate={rescheduleDate ? dayjs(rescheduleDate) : null} // sche

              />
            </Grid>

            <Grid item md={GridValue}>
              <FmTextField
                name='comments'
                control={control}
                label='Comments'
                multiline={true}
                rows={1}
                maxRows={3}
                required
              />
            </Grid>
          </Grid>
          {attachmentFields?.map((field, index) => {
            return (
              <Box className='p-4 mb-3 flex items-center gap-5'>
                <Grid item xs={12} container spacing={2}>
                  <Grid item md={3}>
                    <Box className="flex-1 min-w-0">
                      <FmFileInput
                        name={`tenderFollowupAttachments[${index}].fileId`}
                        documentName={
                          uploadedFileData[index]?.originalFileName ||
                          field?.fileName
                        }
                        onChange={(e) => handleFileUpload(e, index)}
                        loading={uploadLoading[index] || false}
                      />
                      <Box className="flex justify-between items-center flex-1">
                        <Button
                          variant="contained"
                          color="secondary"
                          className="py-1 px-3"
                          startIcon={<Icon icon="material-symbols:download" />}
                          onClick={() => downloadDocument({ fileId: field.fileId, fileName: field.fileName })} // Pass as object
                        >
                          Download
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item md={3}>
                    <FmDatePicker
                      name={`attachments[${index}].date`}
                      control={control}
                      label='Date'
                    />
                  </Grid>
                  <Grid item md={5}>
                    <FmTextField
                      name={`tenderFollowupAttachments[${index}].remarks`}
                      control={control}
                      label='Remarks'
                      required
                      multiline={true}
                      rows={1}
                      maxRows={3}
                    />
                  </Grid>
                  <Grid item md={1}>
                    <IconButton
                      onClick={() => removeAttachment(index)}
                      sx={{
                        padding: '1px',
                      }}
                      color='secondary'
                    >
                      <Icon icon='ic:baseline-clear' />
                    </IconButton>
                  </Grid>
                </Grid>
              </Box>
            );
          })}

          <Box className='mt-5 w-full flex justify-end'>
            <Button
              variant='text'
              onClick={() => appendAttachment({})}
              className='uppercase font-bold text-orange-500'
              startIcon={<Icon icon='ic:baseline-plus' />}
            >
              Attachments
            </Button>
          </Box>

          {followUpType && followUpType?.tenderFollowupTypeId === 11 && (
            <>
              {fields?.map((_field, index) => (
                <GlassCard className='p-4 mb-3 mt-3 ml-5 flex flex-col gap-5'>
                  {_field?.tenderFollowUpAssigns?.map((_field2, index2) => {
                    return (
                      <Grid container spacing={2} direction='row' key={index2}>
                        <Grid item md={6}>
                          <FmSearchableSelect
                            name={`tenderFollowUpSchedules[${index}].tenderFollowUpAssigns[${index2}].assignId`}
                            control={control}
                            apiUrl='loginUsers'
                            valueField='loginUserId'
                            labelField={['employeeCode', 'loginUserName']}
                            showField={['employeeCode', 'loginUserName']}
                            label='Assigned To'
                            onChangeProp={(e) =>
                              handleChangeLoginUsers(e, index, index2)
                            }
                            defaultValue={{
                              loginUserId: _field2?.assignId,
                              loginUserName: _field2?.name,
                            }}
                          />
                        </Grid>
                        {/* <Grid item md={GridValue}>
                        <FmTextField
                          name={`tenderFollowUpSchedules[${index}].tenderFollowUpAssigns[${index2}].name`}
                          control={control}
                          label='Name'
                          disabled={true}
                        />
                      </Grid> */}
                        <Grid item md={3}>
                          <FmTextField
                            name={`tenderFollowUpSchedules[${index}].tenderFollowUpAssigns[${index2}].email`}
                            control={control}
                            label='Email'
                            disabled={true}
                          />
                        </Grid>
                        <Grid item md={3}>
                          <FmTextField
                            name={`tenderFollowUpSchedules[${index}].tenderFollowUpAssigns[${index2}].contactNo`}
                            control={control}
                            label='Contact No'
                            disabled={true}
                          />
                        </Grid>
                        {/* <Grid item md={GridValue}>
                        <IconButton
                          onClick={() => handleRemoveNestedField(index, index2)}
                          sx={{
                            padding: '1px',
                          }}
                          color='secondary'
                        >
                          <Icon icon='ic:baseline-clear' />
                        </IconButton>
                      </Grid> */}
                      </Grid>
                    );
                  })}

                  {/* <IconButton
                    onClick={() => handleAddNestedField(index)}
                    sx={{
                      padding: '1px',
                    }}
                    color='secondary'
                  >
                    <Icon icon='ic:baseline-plus' />
                  </IconButton> */}

                  <Grid container spacing={2}>
                    <Grid item md={GridValue}>
                      <FmDatePicker
                        name={`tenderFollowUpSchedules[${index}].scheduleDate`}
                        label='Schedule Date'
                        control={control}
                        required
                        minDate={remainderDate ? dayjs(remainderDate) : null}
                      />
                    </Grid>
                    <Grid item md={GridValue}>
                      <FmDatePicker
                        name={`tenderFollowUpSchedules[${index}].reminderDate`}
                        label='Remainder Date'
                        control={control}
                        required
                        minDate={rescheduleDate ? dayjs(rescheduleDate) : null}
                      />
                    </Grid>
                  </Grid>

                  {/* <IconButton
                    onClick={() => remove(index)}
                    disabled={fields.length === 1}
                    sx={{
                      padding: '1px',
                    }}
                    color='secondary'
                  >
                    <Icon icon='ic:baseline-clear' />
                  </IconButton> */}
                </GlassCard>
              ))}
            </>
          )}

          {/* {followUpType && followUpType?.tenderFollowupTypeId === 11 && (
            <Box className='mt-5 w-full flex justify-end'>
              <Button
                variant='text'
                onClick={() =>
                  append({
                    tenderAssigns: [
                      { assignId: '', name: '', email: '', contactNo: '' },
                    ],
                    scheduleDate: null,
                    reminderDate: null,
                  })
                }
                className='uppercase font-bold text-orange-500'
                startIcon={<Icon icon='ic:baseline-plus' />}
              >
                Assigness
              </Button>
            </Box>
          )} */}
          <ActionButtons
            onSubmit={handleSubmit(handleEditTenderFollowups)}
            onCancel={() => navigate(-1)}
            submitLoading={editLoading}
            submitText='Update'
            cancelText='Go Back'
          />
        </form>
      )}
    </DashboardLayout>
  );
};

export default FollowupEdit;
