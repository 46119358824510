import React, { useEffect, useMemo, useState } from 'react';
import Crud_Service from '../../../apis/CrudService';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import { StatusBadge, ViewField } from '../../../components/_form/FormElements';
import DataTable from '../../../components/DataTable.tsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { Box, Grid } from '@mui/material';
import { CreateButton } from '../../../components/small/Buttons';
import TableSearch from '../../../components/_form/TableSearch.jsx';
import useTableLogic from '../../../components/helpers/UseTableLogic.tsx';
import { useForm } from 'react-hook-form';
import { Icon } from '@iconify/react';
import TableActionButtons from '../../../components/_form/TableActionButtons.jsx';
import MuiDialogOne from '../../../components/_mui/MuiDialogOne.jsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import ActionButtons from '../../../components/_form/ActionButtons.tsx';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import CommonLoader from '../../../components/page/CommonLoader.jsx';
import FmAutoComplete from '../../../components/_mui/FmAutoComplete.tsx';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { findNavByName } from '../../../utils/navUtils.ts';

const PassportStatuses = () => {
    const [openCreate, setOpenCreate] = useState(false);
    const [loader, setLoader] = useState(false);
    const { setValue, control, reset, handleSubmit } = useForm();
    const [loading, setLoading] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [openUpdate, setOpenUpdate] = useState(false);
    const {
        createLoading,
        startCreateLoading,
        stopCreateLoading,
        editLoading,
        startEditLoading,
        stopEditLoading,
    } = useLoader();
    const [openView, setOpenView] = useState(false);
    const {
        rows,
        pageSize,
        pageNumber,
        setSearchKeyword,
        isLoading,
        tableRecordCounts,
        handlePaginationModelChange,
        handleSortModelChange,
        searchKeyword,
        setRows,
        fetchData,
    } = useTableLogic('passportstatuses');
    const crud = new Crud_Service();
    const menu = useSelector((state) => state?.menu?.items);
    const statusOptions = [
        { statusId: 2, statusName: 'Active' },
        { statusId: 1, statusName: 'Inactive' },
    ];

    useEffect(() => {
        setLoader(true);
    }, []);

    const handleSubmitPassportStatuses = async (values) => {
        startCreateLoading();
        await crud.create('passportstatuses', values, (err, res) => {
            if (res?.status === 201) {
                toast.success('Passport Status Created Successfully');
                setOpenCreate(false);
                stopCreateLoading();
                reset();
                fetchData();
            } else {
                stopCreateLoading();
                setOpenCreate(true);
            }
        });
    };

    //Delete passportstatuses
    const handleDeleteExpense = async (view) => {
        await crud.remove(
            'passportstatuses',
            view?.passportStatusId,
            (err, res) => {
                if (res?.status === 204) {
                    toast.success('Passport Status Deleted Successfully');
                    setRows(
                        rows?.filter(
                            (row) => row.id !== view?.passportStatusId
                        )
                    );
                    fetchData();
                } else {
                }
            }
        );
    };

    const handleEditExpense = async (view) => {
        setOpenUpdate(true);
        setLoading(true);
        await crud.getSingle(
            'passportstatuses',
            view?.passportStatusId,
            (err, res) => {
                if (res?.status === 200) {
                    Object.entries({
                        ...res?.data,
                    }).forEach(([key, value]) => {
                        setValue(key, value);
                    });
                    setLoading(false);
                } else {
                }
            }
        );
    };

    const handleCreateClickOpen = () => {
        reset();
        setOpenCreate(true);
    };

    const handleViewClickOpen = async (view) => {
        setOpenView(true);
        setLoading(true);
        await crud.getSingle(
            'passportstatuses',
            view?.passportStatusId,
            (err, res) => {
                if (res?.status === 200) {
                    setSelectedRowData(res?.data);
                    setLoading(false);
                } else {
                    setLoading(true);
                }
            }
        );
    };

    const columns = [
        {
            field: 'action',
            headerName: '',
            width: 140,
            sortable: false,
            disableClickEventBubbling: false,

            renderCell: (params) => {
                return (
                    <TableActionButtons
                        Actions={ActionData?.map((action) => ({
                            ...action,
                            onClick: () => action.onClick(params.row),
                        }))}
                    />
                );
            },
        },
        {
            field: 'passportStatusName',
            headerName: 'Passport Status',
            flex: 1,
        },

        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            renderCell: (params) => {
                return (
                    <StatusBadge
                        title={params.value === 2 ? 'Active' : 'Inactive'}
                        type={params.value === 2 ? 'green' : 'red'}
                    />
                );
            },
        },
    ];

    const ActionData = [
        {
            name: 'View',
            icon: <Icon icon='solar:eye-linear' />,
            onClick: (props) => handleViewClickOpen(props),
        },
        {
            name: 'Edit',
            icon: <Icon icon='solar:pen-new-round-linear' />,
            onClick: (props) => handleEditExpense(props),
        },
        {
            name: 'Delete',
            icon: <Icon icon='solar:trash-bin-2-linear' />,
            onClick: (props) => handleDeleteExpense(props),
            danger: true,
        },
    ];
    const handleCreateClose = () => {
        reset();
        setOpenCreate(false);
    };
    const handleViewClose = () => {
        setOpenView(false);
    };
    const handleUpdatePassportStatuses = async (values) => {
        startEditLoading();
        await crud.update(
            'passportstatuses',
            values?.passportStatusId,
            values,
            (err, res) => {
                if (res?.status === 200) {
                    toast.success('Passport Status Updated Successfully');
                    setOpenUpdate(false);
                    stopEditLoading();
                    fetchData();
                } else {
                    stopEditLoading();
                    setOpenUpdate(true);
                }
            }
        );
    };

    const SubMenu = useMemo(() => {
        return menu?.map((item) => item?.menus?.flat()).flat() || [];
    }, [menu]);

    const resultPR = useMemo(
        () => findNavByName(SubMenu, 'PR', 'All master'),
        [SubMenu]
    );

    return (
        <DashboardLayout title='Passport Status' menu={resultPR} hasSubmenu>
            <GlassCard className='h-full'>
                <Box className='h-full'>
                    <DataTable
                        rows={rows}
                        columns={columns}
                        loading={isLoading}
                        getRowClassName={(params) =>
                            `${params.row.status === 0 && 'bg-red-50'}`
                        }
                        sortingMode='server'
                        paginationMode='server'
                        onPaginationModelChange={(model) =>
                            handlePaginationModelChange(model)
                        }
                        onSortModelChange={(model) => handleSortModelChange(model)}
                        page={pageNumber - 1}
                        pageSize={pageSize}
                        rowCount={tableRecordCounts?.total}
                        slots={{
                            toolbar: () => (
                                <Box
                                    className='p-2 w-full flex justify-between items-center'
                                    sx={{
                                        borderBottom: '1px solid',
                                        borderColor: 'border.main',
                                    }}
                                >
                                    <Box sx={{ maxWidth: '250px' }}>
                                        <TableSearch
                                            placeholder='Search'
                                            fullWidth
                                            setSearchKeyword={setSearchKeyword}
                                            searchValue={searchKeyword}
                                        />
                                    </Box>
                                    <CreateButton name='New' onClick={handleCreateClickOpen} />
                                </Box>
                            ),
                        }}
                    />
                </Box>
            </GlassCard>

            <MuiDialogOne
                title='Create New'
                open={openCreate}
                onClose={handleCreateClose}
            >
                <form onSubmit={handleSubmit(handleSubmitPassportStatuses)}>
                    <Grid container spacing={3}>
                        <Grid item md={12}>
                            <FmTextField
                                name='passportStatusName'
                                control={control}
                                label='Passport Status'
                                rules={{
                                    required: 'Passport Status is required',
                                }}
                            />

                        </Grid>
                        <Grid item md={12}>
                            <ActionButtons
                                onSubmit={handleSubmit(handleSubmitPassportStatuses)}
                                onCancel={handleCreateClose}
                                onReset={reset}
                                submitLoading={createLoading}
                                cancelLoading={false}
                                errorFields={false}
                            />
                        </Grid>
                    </Grid>
                </form>
            </MuiDialogOne>

            <MuiDialogOne
                title='View Details'
                open={openView}
                onClose={handleViewClose}
            >
                {loading ? (
                    <CommonLoader />
                ) : (
                    <Grid container spacing={3} className='mb-5'>
                        <Grid item md={6}>
                            <ViewField
                                label='Passport Status'
                                title={selectedRowData?.passportStatusName}
                            />
                        </Grid>

                        <Grid item md={6}>
                            <ViewField label='Status' title={selectedRowData?.statusName} />
                        </Grid>
                    </Grid>
                )}
            </MuiDialogOne>

            {/* Update */}
            <MuiDialogOne
                title='Update'
                open={openUpdate}
                onClose={() => setOpenUpdate(false)}
            >
                {loading ? (
                    <CommonLoader />
                ) : (
                    <form onSubmit={handleSubmit(handleUpdatePassportStatuses)}>
                        <Grid container spacing={3}>
                            <Grid item md={12}>
                                <FmTextField
                                    name='passportStatusName'
                                    control={control}
                                    label='Passport Status'
                                    rules={{
                                        required: 'Passport Status is required',
                                    }}
                                />
                            </Grid>
                            <Grid item md={12}>
                                <FmAutoComplete
                                    name='status'
                                    control={control}
                                    options={statusOptions}
                                    label='Status'
                                    displayField='statusName'
                                    optionFields={['statusName']}
                                    valueKey='statusId'
                                />
                            </Grid>

                            <Grid item md={12}>
                                <ActionButtons
                                    onSubmit={handleSubmit(handleUpdatePassportStatuses)}
                                    onCancel={() => setOpenUpdate(false)}
                                    onReset={reset}
                                    submitLoading={editLoading}
                                    cancelLoading={false}
                                //
                                />
                            </Grid>
                        </Grid>
                    </form>
                )}
            </MuiDialogOne>
        </DashboardLayout>
    );
};

export default PassportStatuses;
